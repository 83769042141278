import React from 'react';
import './App.css';

import { Row, Col } from 'react-bootstrap';

import {
  Route,
  useHistory,
  useLocation
} from 'react-router-dom'
import ApiService from "./services/api.service";

// import BreadcrumbComponent from './components/shared/BreadcrumbComponent/BreadcrumbComponent'
import LanguageService from './services/language.service';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import NavbarComponent from "./components/shared/NavbarComponent/NavbarComponent"
import ChangeLanguageComponent from "./components/shared/ChangeLanguageComponent/ChangeLanguageComponent"

import HomePage from "./containers/HomePage/HomePage"
// import TestPage from "./containers/ZZTesting/TestPage"

import DashboardPage from "./containers/Dashboard/DashboardPage"

import ArtworkListPage from "./containers/Artwork/ArtworkAdmin/ArtworkListPage"
import ArtworkEditPage from "./containers/Artwork/ArtworkAdmin/ArtworkEditPage"
import ArtworkViewPage from "./containers/Artwork/ArtworkAdmin/ArtworkViewPage"
import ArtworkLocationPage from "./containers/Artwork/ArtworkAdmin/ArtworkLocationPage"

import Gallery from "./containers/Gallery/Gallery"
import GalleryPage from "./containers/Gallery/GalleryPage/GalleryPage"
import GalleryAdminList from "./containers/Gallery/GalleryAdmin/GalleryAdminList"
import GalleryAdminEdit from "./containers/Gallery/GalleryAdmin/GalleryAdminEdit"
import GalleryAccessPage from "./containers/Gallery/GalleryAdmin/GalleryAdminAccess"
import GalleryAdminLocation from "./containers/Gallery/GalleryAdmin/GalleryAdminLocation"
import GalleryAdminArtworkList from "./containers/Gallery/GalleryAdmin/GalleryAdminArtworkList"
import GalleryAdminVirtualGalleryList from "./containers/Gallery/GalleryAdmin/GalleryAdminVirtualGalleryList"
import GalleryArtworkLocation from "./containers/Map/EntityLocation/GalleryArtworkManageLocation"
import GalleryVirtualGalleryLocation from "./containers/Map/EntityLocation/GalleryVirtualGalleryManageLocation"

import Exhibition from "./containers/Exhibition/Exhibition"
import ExhibitionPage from "./containers/Exhibition/ExhibitionPage/ExhibitionPage"
import ExhibitionArtwork from "./containers/Exhibition/ExhibitionPage/ExhibitionArtwork"
import ExhibitionAdminList from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminList"
import ExhibitionAdminEdit from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminEdit"
import ExhibitionAdminView from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminView"
import ExhibitionAccessPage from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminAccess"
import ExhibitionAdminLocation from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminLocation"
import ExhibitionAdminArtworkList from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminArtworkList"
import ExhibitionAdminArtworkView from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminArtworkView"
import ExhibitionAdminArtworkTrigger from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminArtworkTrigger"
import ExhibitionAdminMedia from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminMedia"
import ExhibitionAdminVirtualGalleryList from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminVirtualGalleryList"
import ExhibitionAdminArExperienceList from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminArExperienceList"
import ExhibitionAdminArExperienceLocation from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminArExperienceLocation"
import ExhibitionAdminArExperienceTrigger from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminArExperienceTrigger"
import ExhibitionAdminVirtualGalleryArtworkList from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminVirtualGalleryArtworkList"
import ExhibitionAdminVirtualGalleryArExperienceList from "./containers/Exhibition/ExhibitionAdmin/ExhibitionAdminVirtualGalleryArExperienceList"

import ArPortalList from "./containers/VirtualGallery/ArPortal/ArPortalListPage"
import ArPortalEdit from "./containers/VirtualGallery/ArPortal/ArPortalEditPage"

import VirtualGalleryListPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryListPage"
import VirtualGalleryEditPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryEditPage"
import VirtualGalleryMediaPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryMediaPage"
import VirtualGalleryPricePackage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryPricePackage"
import VirtualGalleryArtworksPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryArtworksPage"
import VirtualGalleryArExperiencesPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryArExperiencesPage"
import VirtualGalleryLocationPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryLocationPage"
import VirtualGalleryViewPage from "./containers/VirtualGallery/VirtualGallery/VirtualGalleryViewPage"

import UserVirtualGalleryPage from "./containers/UserVirtualGallery/UserVirtualGalleryPage"
import UserVirtualGalleryViewPage from "./containers/UserVirtualGallery/UserVirtualGalleryViewPage"
import UserVirtualGalleryArtworkPage from "./containers/UserVirtualGallery/UserVirtualGalleryArtworkPage"

import ArExperienceListPage from "./containers/ArExperience/ArExperienceListPage"
import ArExperienceEditPage from "./containers/ArExperience/ArExperienceEditPage"
import ArExperienceFilePage from "./containers/ArExperience/ArExperienceFilePage"
import ArExperienceViewPage from "./containers/ArExperience/ArExperienceViewPage"

import DemoArtListPage from "./containers/DemoArt/DemoArtListPage"
import DemoArtTrigger from "./containers/DemoArt/DemoArtTrigger"

import ProjectCreatePage from "./containers/ProjectCreatePage/ProjectCreatePage"
import ArArtProjectCreatePage from "./containers/ProjectCreatePage/ArArtProjectCreatePage"
import ObjectProjectCreatePage from "./containers/ProjectCreatePage/ObjectProjectCreatePage"
import ArPortalProjectCreatePage from "./containers/ProjectCreatePage/ArPortalProjectCreatePage"
import DigitalPictureProjectCreatePage from "./containers/ProjectCreatePage/DigitalPictureProjectCreatePage"
import ProjectViewPage from "./containers/ProjectCreatePage/ProjectViewPage/ProjectViewPage"
import ProjectEditPage from "./containers/ProjectCreatePage/ProjectEditPage/ProjectEditPage"

import ArArtPublishArtworkDetails from "./containers/ProjectCreatePage/ArArtProjectPublish/ArArtPublishArtworkDetails"
import ArArtPublishExperience from "./containers/ProjectCreatePage/ArArtProjectPublish/ArArtPublishExperience"
import ArArtPublishPricePackage from "./containers/ProjectCreatePage/ArArtProjectPublish/ArArtPublishPricePackage"
import ArArtPublishPreview from "./containers/ProjectCreatePage/ArArtProjectPublish/ArArtPublishPreview"

import ObjectPublishArtworkDetails from "./containers/ProjectCreatePage/ObjectProjectPublish/ObjectPublishArtworkDetails"
import ObjectPublishArtwork from "./containers/ProjectCreatePage/ObjectProjectPublish/ObjectPublishArtwork"
import ObjectPublishPricePackage from "./containers/ProjectCreatePage/ObjectProjectPublish/ObjectPublishPricePackage"
import ObjectPublishPreview from "./containers/ProjectCreatePage/ObjectProjectPublish/ObjectPublishPreview"

import ArPortalPublishDetails from "./containers/ProjectCreatePage/ArPortalProjectPublish/ArPortalPublishDetails"
import ArPortalPublishArt from "./containers/ProjectCreatePage/ArPortalProjectPublish/ArPortalPublishArt"
import ArPortalPublishPricePackage from "./containers/ProjectCreatePage/ArPortalProjectPublish/ArPortalPublishPricePackage"
import ArPortalPublishPreview from "./containers/ProjectCreatePage/ArPortalProjectPublish/ArPortalPublishPreview"

import DigitalPicturePublishArtworkDetails from "./containers/ProjectCreatePage/DigitalPictureProjectPublish/DigitalPicturePublishArtworkDetails"
import DigitalPicturePublishArtwork from "./containers/ProjectCreatePage/DigitalPictureProjectPublish/DigitalPicturePublishArtwork"
import DigitalPicturePublishPricePackage from "./containers/ProjectCreatePage/DigitalPictureProjectPublish/DigitalPicturePublishPricePackage"
import DigitalPicturePublishPreview from "./containers/ProjectCreatePage/DigitalPictureProjectPublish/DigitalPicturePublishPreview"

//import GalleryPage from "./containers/GalleryPage/GalleryPage"

import PortfolioPage from "./containers/Portfolio/PortfolioPage"
import ViewArtworkPage from './containers/ViewArtworkPage/ViewArtworkPage';
import MyProjectsPage from "./containers/MyProjectsPage/MyProjectsPage"
import MyCollectionPage from "./containers/Collection/CollectionPage"
import PortfolioArtworkViewPage from './containers/Portfolio/PortfolioArtworkViewPage';
import PortfolioArtworkEditPage from './containers/Portfolio/PortfolioArtworkEditPage';
import PortfolioVirtualGalleryViewPage from './containers/Portfolio/PortfolioVirtualGalleryViewPage';
import PortfolioVirtualGalleryEditPage from './containers/Portfolio/PortfolioVirtualGalleryEditPage';
import ViewCollectionArtworkPage from './containers/Collection/ViewCollectionArtworkPage/ViewCollectionArtworkPage';
import CollectionHomePage from "./containers/Collection/CollectionHomePage"
import PortfolioHomePage from "./containers/Portfolio/PortfolioHomePage"

import Company from "./containers/Company/MyCompany/CompanyPage"
import CompanyList from "./containers/Company/MyCompany/CompanyListPage"
import CompanyEdit from "./containers/Company/MyCompany/CompanyEditPage"
import CompanySpace from "./containers/Company/MyCompany/CompanySpacePage"
import CompanyMedia from "./containers/Company/MyCompany/CompanyMediaPage"
import CompanyUser from "./containers/Company/MyCompany/CompanyUserPage"
import CompanyArtist from "./containers/Company/MyCompany/CompanyArtistPage"
import CompanyAddress from "./containers/Company/MyCompany/CompanyAddressPage"
import CompanyPortfolioPage from "./containers/Company/MyCompany/Portfolio/PortfolioPage"
import CompanyPortfolioArtworkPage from "./containers/Company/MyCompany/Portfolio/PortfolioArtworkViewPage"
import CompanyPortfolioArtworkEditPage from "./containers/Company/MyCompany/Portfolio/PortfolioArtworkEditPage"
import CompanyPortfolioVirtualGalleryViewPage from "./containers/Company/MyCompany/Portfolio/PortfolioVirtualGalleryViewPage"
import CompanyPortfolioVirtualGalleryEditPage from "./containers/Company/MyCompany/Portfolio/PortfolioVirtualGalleryEditPage"
import CompanyPortfolioVirtualGalleryArtworkPage from "./containers/Company/MyCompany/Portfolio/PortfolioVirtualGalleryArtworkPage"
import CompanyGalleryPage from "./containers/Company/MyCompany/Gallery/GalleryPage"
import CompanyVirtualGalleryPage from "./containers/Company/MyCompany/VirtualGallery/VirtualGalleryListPage"
import CompanyVirtualGalleryViewPage from "./containers/Company/MyCompany/VirtualGallery/VirtualGalleryViewPage"
import CompanyVirtualGalleryEditPage from "./containers/Company/MyCompany/VirtualGallery/VirtualGalleryEditPage"
import CompanyVirtualGalleryArtworkPage from "./containers/Company/MyCompany/VirtualGallery/VirtualGalleryArtworkPage"
import CompanyExhibitionList from "./containers/Company/MyCompany/Exhibition/ExhibitionListPage"
import CompanyExhibition from "./containers/Company/MyCompany/Exhibition/ExhibitionPage"
import CompanyExhibitionEdit from "./containers/Company/MyCompany/Exhibition/ExhibitionEditPage"
import CompanyExhibitionMedia from "./containers/Company/MyCompany/Exhibition/ExhibitionMediaPage"
import CompanyExhibitionLocation from "./containers/Company/MyCompany/Exhibition/ExhibitionLocationPage"
import CompanyExhibitionArtwork from "./containers/Company/MyCompany/Exhibition/ExhibitionArtworkPage"
import CompanyExhibitionArtworkTrigger from "./containers/Company/MyCompany/Exhibition/ExhibitionArtworkTriggerPage"
import CompanyExhibitionArtworkView from "./containers/Company/MyCompany/Exhibition/ExhibitionArtworkViewPage"
import CompanyExhibitionVirtualGallery from "./containers/Company/MyCompany/Exhibition/ExhibitionVirtualGalleryPage"
import CompanyExhibitionVirtualGalleryArtwork from "./containers/Company/MyCompany/Exhibition/ExhibitionVirtualGalleryArtworkPage"
import CompanyChallengeList from "./containers/Company/MyCompany/Challenge/ChallengeListPage"
import CompanyChallengeEdit from "./containers/Company/MyCompany/Challenge/ChallengeEditPage"
import CompanyChallengeMedia from "./containers/Company/MyCompany/Challenge/ChallengeMediaPage"

import UsersPage from "./containers/UsersPage/UsersPage"
import SalesPage from "./containers/Report/Sales/SalesPage"
import MembershipPage from "./containers/MembershipPage/MembershipPage"

import CompanyListPage from "./containers/Company/CompanyAdmin/CompanyListPage"
import CompanyEditPage from "./containers/Company/CompanyAdmin/CompanyEditPage"
import CompanyUserPage from "./containers/Company/CompanyAdmin/CompanyUserPage"
import CompanyAddressPage from "./containers/Company/CompanyAdmin/CompanyAddressPage"
import CompanyExhibitionPage from "./containers/Company/CompanyAdmin/CompanyExhibitionPage"
import CompanyExhibitionViewPage from "./containers/Company/CompanyAdmin/CompanyExhibitionViewPage"
import CompanyExhibitionEditPage from "./containers/Company/CompanyAdmin/CompanyExhibitionEditPage"
import CompanyExhibitionMediaPage from "./containers/Company/CompanyAdmin/CompanyExhibitionMediaPage"
import CompanyExhibitionArtworkPage from "./containers/Company/CompanyAdmin/CompanyExhibitionArtworkPage"
import CompanyExhibitionArtworkViewPage from "./containers/Company/CompanyAdmin/CompanyExhibitionArtworkViewPage"
import CompanyExhibitionVirtualGalleryPage from "./containers/Company/CompanyAdmin/CompanyExhibitionVirtualGalleryPage"
import CompanyExhibitionVirtualGalleryArtworkPage from "./containers/Company/CompanyAdmin/CompanyExhibitionVirtualGalleryArtworkPage"
import CompanyExhibitionLocationPage from "./containers/Company/CompanyAdmin/CompanyExhibitionLocationPage"

import ArtistCompanyList from "./containers/Company/ArtistCompany/CompanyListPage"

import SpaceListPage from "./containers/Company/Space/SpaceListPage"
import SpaceViewPage from "./containers/Company/Space/SpaceViewPage"
import SpaceGalleryPage from "./containers/Company/Space/SpaceGalleryPage"
import SpaceExhibitionListPage from "./containers/Company/Space/SpaceExhibitionListPage"
import SpaceExhibitionPage from "./containers/Company/Space/SpaceExhibitionPage"
import SpaceExhibitionArtworkPage from "./containers/Company/Space/SpaceExhibitionArtworkPage"
import SpaceMediaPage from "./containers/Company/Space/SpaceMediaPage"
import SpaceVirtualGalleryListPage from "./containers/Company/Space/SpaceVirtualGalleryListPage"
import SpaceChallengeListPage from "./containers/Company/Space/SpaceChallengeListPage"

import MyProfilePage from "./containers/UserProfileSection/ProfilePage/MyProfilePage/ProfilePage"
import MyProfileMediaPage from "./containers/UserProfileSection/ProfilePage/MyProfilePage/ProfileMediaPage"
// import EditProfilePage from "./containers/UserProfileSection/ProfilePage/EditProfilePage/EditProfilePage"
// import WebResources from "./containers/UserProfileSection/WebResourcesPage/WebResourcesPage"
import ProfileViewPage from "./containers/Profile/ProfileViewPage"
import ProfilePortfolioPage from "./containers/Profile/ProfilePortfolioPage"
import ProfileMediaPage from "./containers/Profile/ProfileMediaPage"

import GiftPage from "./containers/UserProfileSection/GiftPage/GiftPage"
import BillingPage from "./containers/UserProfileSection/BillingPage/BillingPage"
import AccountPreferencesPage from "./containers/UserProfileSection/AccountPreferencesPage/AccountPreferencesPage"
import SocialMediaPage from "./containers/UserProfileSection/SocialMediaPage/SocialMediaPage"
import SecurityPage from "./containers/UserProfileSection/SecurityPage/SecurityPage"
import TermsConditionsPage from "./containers/TermsSection/TermsConditionsPage/TermsConditionsPage"
import TermsSalesPage from "./containers/TermsSection/TermsSalesPage/TermsSalesPage"
import PrivacyPolicyPage from "./containers/TermsSection/PrivacyPolicyPage/PrivacyPolicyPage"
// import CookiePolicyPage from "./containers/TermsSection/CookiePolicyPage/CookiePolicyPage"

import CartPage from './containers/CartPage/CartPage';
import PaymentCompletePage from './containers/PaymentCompletePage/PaymentCompletePage';
import PaymentErrorPage from './containers/PaymentErrorPage/PaymentErrorPage';

import Map from './containers/Map/Map';
import GeofenceList from './containers/Map/Geofence/GeofenceList';
import LocationPinList from './containers/Map/LocationPin/LocationPinList';
import ExhibitionArtworkLocation from "./containers/Map/EntityLocation/ExhibitionArtworkManageLocation"
import ExhibitionVirtualGalleryLocation from "./containers/Map/EntityLocation/ExhibitionVirtualGalleryManageLocation"
import ExhibitionMap from "./containers/Map/EntityLocation/ExhibitionMap"
import GalleryMap from './containers/Map/EntityLocation/GalleryMap';
import ParkApplyPage from './containers/Park/ParkApplyPage';
import ArtistListPage from './containers/Profile/ArtistListPage';

import ParkListPage from './containers/Park/ParkListPage';
import ParkViewPage from './containers/Park/ParkViewPage';
import ParkAdminListPage from './containers/Park/Admin/ParkAdminListPage';
import ParkAdminViewPage from './containers/Park/Admin/ParkAdminViewPage';
import ParkAdminArtworkPage from './containers/Park/Admin/ParkAdminArtworkPage';
import ParkAdminVirtualGalleryPage from './containers/Park/Admin/ParkAdminVirtualGalleryPage';
import ParkAdminVirtualGalleryArtworkPage from './containers/Park/Admin/ParkAdminVirtualGalleryArtworkPage';
import ParkAdminVirtualGalleryArExperiencePage from './containers/Park/Admin/ParkAdminVirtualGalleryArExperiencePage';
import ParkAdminArExperiencePage from './containers/Park/Admin/ParkAdminArExperiencePage';
import ParkAdminLocationPage from './containers/Park/Admin/ParkAdminLocationPage';
import ParkArtworkManageLocation from './containers/Map/EntityLocation/ParkArtworkManageLocation';
import ParkMap from './containers/Map/EntityLocation/ParkMap';
import ParkVirtualGalleryManageLocation from './containers/Map/EntityLocation/ParkVirtualGalleryManageLocation';
import ParkArExperienceManageLocation from './containers/Map/EntityLocation/ParkArExperienceManageLocation';
import ParkAdminArtTriggerPage from './containers/Park/Admin/ParkAdminArtTriggerPage';
import ThemeParkViewPage from './containers/Park/ThemeParkViewPage';
import ParkArtPage from './containers/Park/ParkArtPage';


function App() {
  let history = useHistory();

  library.add(fas, far, fab);

  ApiService.setRedirectUrl(useLocation().pathname);
  let authenticated = ApiService.initToken();
  if (!authenticated) {
    history.push('/login');
    return (<div></div>)
  } else {

    ApiService.getAuthenticatedInstance().get('/user/me')
      .then(result => {
        ApiService.setCurrentUser(result.data);
      })
      .catch(err => {
        history.push('/login')
      });

    return (
      <div className="d-flex flex-column h-100">
        <NavbarComponent history={history} />
        {/* <BreadcrumbComponent /> */}
        <div id="main-container" className="main-container">
          <Route exact path='/' component={HomePage}></Route>
          <Route exact path='/dashboard' component={DashboardPage}></Route>
          <Route exact path='/gallery' component={Gallery}></Route>
          <Route exact path='/gallery/:id' component={GalleryPage}></Route>
          <Route exact path='/gallery/:id/map' component={GalleryMap}></Route>
          <Route exact path='/exhibition' component={Exhibition}></Route>
          <Route exact path='/exhibition/:id' component={ExhibitionPage}></Route>
          <Route exact path='/exhibitionArtwork/:id' component={ExhibitionArtwork}></Route>
          <Route exact path='/exhibition/:id/map' component={ExhibitionMap}></Route>

          <Route exact path='/change-language/:lang' component={ChangeLanguageComponent}></Route>

          <Route exact path='/project/create' component={ProjectCreatePage}></Route>
          <Route exact path='/my-company/:companyId/project/create' component={ProjectCreatePage}></Route>
          
          <Route exact path='/project/create/ar-art' component={ArArtProjectCreatePage}></Route>
          <Route exact path='/my-company/:companyId/project/create/ar-art' component={ArArtProjectCreatePage}></Route>
          
          <Route exact path='/project/create/object' component={ObjectProjectCreatePage}></Route>
          <Route exact path='/my-company/:companyId/project/create/object' component={ObjectProjectCreatePage}></Route>
          
          <Route exact path='/project/create/ar-portal' component={ArPortalProjectCreatePage}></Route>
          <Route exact path='/my-company/:companyId/project/create/ar-portal' component={ArPortalProjectCreatePage}></Route>

          <Route exact path='/project/create/digital-picture' component={DigitalPictureProjectCreatePage}></Route>
          <Route exact path='/my-company/:companyId/project/create/digital-picture' component={DigitalPictureProjectCreatePage}></Route>
          
          <Route exact path='/project/view/:id' component={ProjectViewPage}></Route>
          <Route exact path='/project/edit/:id' component={ProjectEditPage}></Route>

          <Route exact path='/project/publish/ar-art/:id' component={ArArtPublishArtworkDetails}></Route>
          <Route exact path='/project/publish/ar-art-experience/:id' component={ArArtPublishExperience}></Route>
          <Route exact path='/project/publish/ar-art-price-package/:id' component={ArArtPublishPricePackage}></Route>
          <Route exact path='/project/publish/ar-art-preview/:id' component={ArArtPublishPreview}></Route>

          <Route exact path='/project/publish/digital-sculpture/:id' component={ObjectPublishArtworkDetails}></Route>
          <Route exact path='/project/publish/digital-sculpture-artwork/:id' component={ObjectPublishArtwork}></Route>
          <Route exact path='/project/publish/digital-sculpture-price-package/:id' component={ObjectPublishPricePackage}></Route>
          <Route exact path='/project/publish/digital-sculpture-preview/:id' component={ObjectPublishPreview}></Route>

          <Route exact path='/project/publish/ar-portal/:id' component={ArPortalPublishDetails}></Route>
          <Route exact path='/project/publish/ar-portal-art/:id' component={ArPortalPublishArt}></Route>
          <Route exact path='/project/publish/ar-portal-price-package/:id' component={ArPortalPublishPricePackage}></Route>
          <Route exact path='/project/publish/ar-portal-preview/:id' component={ArPortalPublishPreview}></Route>

          <Route exact path='/project/publish/digital-picture/:id' component={DigitalPicturePublishArtworkDetails}></Route>
          <Route exact path='/project/publish/digital-picture-art/:id' component={DigitalPicturePublishArtwork}></Route>
          <Route exact path='/project/publish/digital-picture-price-package/:id' component={DigitalPicturePublishPricePackage}></Route>
          <Route exact path='/project/publish/digital-picture-preview/:id' component={DigitalPicturePublishPreview}></Route>

          <Route exact path='/cart' component={CartPage}></Route>
          <Route exact path='/payment-complete' component={PaymentCompletePage}></Route>
          <Route exact path='/payment-error' component={PaymentErrorPage}></Route>

          {/*<Route exact path='/' component={GalleryPage}></Route>*/}

          {/* ARTWORKS DROPDOWN */}
          <Route exact path='/artworks' component={PortfolioPage}></Route>
          <Route exact path='/artwork/:id' component={ViewArtworkPage}></Route>
          <Route exact path='/projects' component={MyProjectsPage}></Route>
          <Route exact path='/collection' component={MyCollectionPage}></Route>
          <Route exact path='/portfolio-artwork/:id' component={PortfolioArtworkViewPage}></Route>
          <Route exact path='/portfolio-artwork-edit/:id' component={PortfolioArtworkEditPage}></Route>
          <Route exact path='/portfolio-virtual-gallery/:id' component={PortfolioVirtualGalleryViewPage}></Route>
          <Route exact path='/portfolio-virtual-gallery-edit/:id' component={PortfolioVirtualGalleryEditPage}></Route>
          <Route exact path='/collection-artwork/:id' component={ViewCollectionArtworkPage}></Route>
          <Route exact path='/collection-home' component={CollectionHomePage}></Route>
          <Route exact path='/portfolio-home' component={PortfolioHomePage}></Route>

          <Route exact path='/virtual-galleries' component={UserVirtualGalleryPage}></Route>
          <Route exact path='/virtual-gallery/:id' component={UserVirtualGalleryViewPage}></Route>
          <Route exact path='/virtual-gallery-artwork/:id' component={UserVirtualGalleryArtworkPage}></Route>

          {/* ADMINISTRATION DROPDOWN */}
          <Route exact path='/users' component={UsersPage}></Route>
          <Route exact path='/companies' component={CompanyListPage}></Route>
          <Route exact path='/company-admin/edit/' component={CompanyEditPage}></Route>
          <Route exact path='/company-admin/:id/edit' component={CompanyEditPage}></Route>
          <Route exact path='/company-admin/:id/user' component={CompanyUserPage}></Route>
          <Route exact path='/company-admin/:id/address' component={CompanyAddressPage}></Route>
          <Route exact path='/company-admin/:id/exhibition' component={CompanyExhibitionPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-view/:exhibitionId' component={CompanyExhibitionViewPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-edit' component={CompanyExhibitionEditPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-edit/:exhibitionId' component={CompanyExhibitionEditPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-media/:exhibitionId' component={CompanyExhibitionMediaPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-artwork/:exhibitionId' component={CompanyExhibitionArtworkPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-artwork-view/:exhibitionId' component={CompanyExhibitionArtworkViewPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-virtual-gallery/:exhibitionId' component={CompanyExhibitionVirtualGalleryPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-virtual-gallery/:exhibitionVirtualGalleryId/artworks' component={CompanyExhibitionVirtualGalleryArtworkPage}></Route>
          <Route exact path='/company-admin/:id/exhibition-location/:exhibitionId' component={CompanyExhibitionLocationPage}></Route>


          <Route exact path='/sales' component={SalesPage}></Route>

          <Route exact path='/map' component={Map}></Route>
          <Route exact path='/geofence' component={GeofenceList}></Route>
          <Route exact path='/locationPin' component={LocationPinList}></Route>

          <Route exact path='/artworkAdminList' component={ArtworkListPage}></Route>
          <Route exact path='/artworkAdminEdit/:id' component={ArtworkEditPage}></Route>
          <Route exact path='/artworkAdminView/:id' component={ArtworkViewPage}></Route>
          <Route exact path='/artworkAdminLocation/:id' component={ArtworkLocationPage}></Route>

          <Route exact path='/galleryAdminList' component={GalleryAdminList}></Route>
          <Route exact path='/galleryAdminEdit/' component={GalleryAdminEdit}></Route>
          <Route exact path='/galleryAdminEdit/:id' component={GalleryAdminEdit}></Route>
          <Route exact path='/galleryAdminAccess/:id' component={GalleryAccessPage}></Route>
          <Route exact path='/galleryAdminLocation/:id' component={GalleryAdminLocation}></Route>
          <Route exact path='/galleryAdminArtworks/:id' component={GalleryAdminArtworkList}></Route>
          <Route exact path='/galleryAdminVirtualGalleries/:id' component={GalleryAdminVirtualGalleryList}></Route>
          
          <Route exact path='/gallery/artwork/:id/location' component={GalleryArtworkLocation}></Route>
          <Route exact path='/gallery/virtual-gallery/:id/location' component={GalleryVirtualGalleryLocation}></Route>

          <Route exact path='/exhibitionAdminList' component={ExhibitionAdminList}></Route>
          <Route exact path='/exhibitionAdminEdit/' component={ExhibitionAdminEdit}></Route>
          <Route exact path='/exhibitionAdminEdit/:id' component={ExhibitionAdminEdit}></Route>
          <Route exact path='/exhibitionAdminView/:id' component={ExhibitionAdminView}></Route>
          <Route exact path='/exhibitionAdminAccess/:id' component={ExhibitionAccessPage}></Route>
          <Route exact path='/exhibitionAdminLocation/:id' component={ExhibitionAdminLocation}></Route>
          <Route exact path='/exhibitionAdminArtworks/:id' component={ExhibitionAdminArtworkList}></Route>
          <Route exact path='/exhibitionAdminArtworksView/:id' component={ExhibitionAdminArtworkView}></Route>          
          <Route exact path='/exhibitionAdminArtworkTrigger/:id' component={ExhibitionAdminArtworkTrigger}></Route>
          <Route exact path='/exhibitionAdminMedia/:id' component={ExhibitionAdminMedia}></Route>
          <Route exact path='/exhibitionAdminVirtualGalleries/:id' component={ExhibitionAdminVirtualGalleryList}></Route>          
          <Route exact path='/exhibitionAdminArExperiences/:id' component={ExhibitionAdminArExperienceList}></Route>
          <Route exact path='/exhibitionAdminArExperienceLocation/:id' component={ExhibitionAdminArExperienceLocation}></Route>
          <Route exact path='/exhibitionAdminArExperienceTrigger/:id' component={ExhibitionAdminArExperienceTrigger}></Route>

          <Route exact path='/exhibition/artwork/:id/location' component={ExhibitionArtworkLocation}></Route>
          <Route exact path='/exhibition/virtual-gallery/:id/location' component={ExhibitionVirtualGalleryLocation}></Route>
          
          <Route exact path='/exhibition-virtual-gallery/:id/artworks' component={ExhibitionAdminVirtualGalleryArtworkList}></Route>
          <Route exact path='/exhibition-virtual-gallery/:id/ar-experiences' component={ExhibitionAdminVirtualGalleryArExperienceList}></Route>

          <Route exact path='/arPortalList' component={ArPortalList}></Route>
          <Route exact path='/arPortalEdit' component={ArPortalEdit}></Route>
          <Route exact path='/arPortalEdit/:id' component={ArPortalEdit}></Route>

          <Route exact path='/virtualGalleryList' component={VirtualGalleryListPage}></Route>
          <Route exact path='/virtualGalleryEdit' component={VirtualGalleryEditPage}></Route>
          <Route exact path='/virtualGalleryEdit/:id' component={VirtualGalleryEditPage}></Route>
          <Route exact path='/virtualGalleryMedia/:id' component={VirtualGalleryMediaPage}></Route>
          <Route exact path='/virtualGalleryPricePackage/:id' component={VirtualGalleryPricePackage}></Route>
          <Route exact path='/virtualGalleryArtworks/:id' component={VirtualGalleryArtworksPage}></Route>
          <Route exact path='/virtualGalleryArExperiences/:id' component={VirtualGalleryArExperiencesPage}></Route>
          <Route exact path='/virtualGalleryLocation/:id' component={VirtualGalleryLocationPage}></Route>
          <Route exact path='/virtualGalleryDetails/:id' component={VirtualGalleryViewPage}></Route>

          <Route exact path='/arExperienceList' component={ArExperienceListPage}></Route>
          <Route exact path='/arExperienceEdit' component={ArExperienceEditPage}></Route>
          <Route exact path='/arExperienceEdit/:id' component={ArExperienceEditPage}></Route>
          <Route exact path='/arExperienceFile/:id' component={ArExperienceFilePage}></Route>
          <Route exact path='/arExperience/:id' component={ArExperienceViewPage}></Route>

          <Route exact path='/demoArt' component={DemoArtListPage}></Route>
          <Route exact path='/demoArTrigger/:id' component={DemoArtTrigger}></Route>

          <Route exact path='/parkAdmin' component={ParkAdminListPage}></Route>
          <Route exact path='/parkAdmin/:id/view' component={ParkAdminViewPage}></Route>
          <Route exact path='/parkAdmin/:id/artwork' component={ParkAdminArtworkPage}></Route>
          <Route exact path='/parkAdmin/:parkId/artTrigger/:id' component={ParkAdminArtTriggerPage}></Route>
          <Route exact path='/parkAdmin/:id/virtualGallery' component={ParkAdminVirtualGalleryPage}></Route>
          <Route exact path='/park-virtual-gallery/:id/artworks' component={ParkAdminVirtualGalleryArtworkPage}></Route>
          <Route exact path='/park-virtual-gallery/:id/ar-experiences' component={ParkAdminVirtualGalleryArExperiencePage}></Route>
          <Route exact path='/parkAdmin/:id/arExperience' component={ParkAdminArExperiencePage}></Route>
          <Route exact path='/parkAdmin/:id/location' component={ParkAdminLocationPage}></Route>
          <Route exact path='/parkAdmin/:parkId/artwork/:id/location' component={ParkArtworkManageLocation}></Route>          
          <Route exact path='/parkAdmin/:parkId/virtualGallery/:id/location' component={ParkVirtualGalleryManageLocation}></Route>
          <Route exact path='/parkAdmin/:parkId/arExperience/:id/location' component={ParkArExperienceManageLocation}></Route>

          {/* USER-PROFILE DROPDOWN */}
          <Route exact path='/profile/view' component={MyProfilePage}></Route>
          <Route exact path='/profile/media' component={MyProfileMediaPage}></Route>
          {/* <Route exact path='/profile/edit' component={EditProfilePage}></Route> */}
          {/* <Route exact path='/profile/resources' component={WebResources}></Route> */}
          <Route exact path='/profile/:id/view' component={ProfileViewPage}></Route>
          <Route exact path='/profile/:id/portfolio' component={ProfilePortfolioPage}></Route>
          <Route exact path='/profile/:id/media' component={ProfileMediaPage}></Route>

          <Route exact path='/account' component={AccountPreferencesPage}></Route>
          <Route exact path='/social-media' component={SocialMediaPage}></Route>
          <Route exact path='/security' component={SecurityPage}></Route>
          <Route exact path='/gift' component={GiftPage}></Route>
          <Route exact path='/billing' component={BillingPage}></Route>
          <Route exact path='/membership' component={MembershipPage}></Route>

          <Route exact path='/my-companies' component={CompanyList}></Route>                
          <Route exact path='/my-company/:id/home' component={Company}></Route>          
          <Route exact path='/my-company/:id?/edit' component={CompanyEdit}></Route>
          <Route exact path='/my-company/:id/space' component={CompanySpace}></Route>
          <Route exact path='/my-company/:id/media' component={CompanyMedia}></Route>
          <Route exact path='/my-company/:id/user' component={CompanyUser}></Route>
          <Route exact path='/my-company/:id/artist' component={CompanyArtist}></Route>
          <Route exact path='/my-company/:id/address' component={CompanyAddress}></Route>
          <Route exact path='/my-company/:id/portfolio' component={CompanyPortfolioPage}></Route>
          <Route exact path='/my-company/:id/portfolio-artwork/:artworkId' component={CompanyPortfolioArtworkPage}></Route>
          <Route exact path='/my-company/:id/portfolio-artwork-edit/:artworkId' component={CompanyPortfolioArtworkEditPage}></Route>
          <Route exact path='/my-company/:id/portfolio-virtual-gallery/:virtualGalleryId' component={CompanyPortfolioVirtualGalleryViewPage}></Route>
          <Route exact path='/my-company/:id/portfolio-virtual-gallery-edit/:virtualGalleryId' component={CompanyPortfolioVirtualGalleryEditPage}></Route>
          <Route exact path='/my-company/:id/portfolio-virtual-gallery/:virtualGalleryId/artworks' component={CompanyPortfolioVirtualGalleryArtworkPage}></Route>          
          <Route exact path='/my-company/:id/exhibition' component={CompanyExhibitionList}></Route>
          <Route exact path='/my-company/:id/gallery' component={CompanyGalleryPage}></Route>
          <Route exact path='/my-company/:id/virtual-galleries' component={CompanyVirtualGalleryPage}></Route>
          <Route exact path='/my-company/:id/virtual-gallery/:virtualGalleryId' component={CompanyVirtualGalleryViewPage}></Route>
          <Route exact path='/my-company/:id/virtual-gallery-edit/:virtualGalleryId' component={CompanyVirtualGalleryEditPage}></Route>
          <Route exact path='/my-company/:id/virtual-gallery/:virtualGalleryId/artworks' component={CompanyVirtualGalleryArtworkPage}></Route>          
          <Route exact path='/my-company/:id/exhibition/:exhibitionId' component={CompanyExhibition}></Route>
          <Route exact path='/my-company/:id/exhibition-edit' component={CompanyExhibitionEdit}></Route>
          <Route exact path='/my-company/:id/exhibition-edit/:exhibitionId' component={CompanyExhibitionEdit}></Route>
          <Route exact path='/my-company/:id/exhibition-media/:exhibitionId' component={CompanyExhibitionMedia}></Route>
          <Route exact path='/my-company/:id/exhibition-location/:exhibitionId' component={CompanyExhibitionLocation}></Route>
          <Route exact path='/my-company/:id/exhibition-artwork/:exhibitionId' component={CompanyExhibitionArtwork}></Route>
          <Route exact path='/my-company/:id/exhibition/:exhibitionId/artwork-trigger/:exhibitionArtworkId' component={CompanyExhibitionArtworkTrigger}></Route>
          <Route exact path='/my-company/:id/exhibition-artwork-view/:exhibitionId' component={CompanyExhibitionArtworkView}></Route>
          <Route exact path='/my-company/:id/exhibition-virtual-gallery/:exhibitionId' component={CompanyExhibitionVirtualGallery}></Route>
          <Route exact path='/my-company/:id/exhibition-virtual-gallery/:exhibitionVirtualGalleryId/artworks' component={CompanyExhibitionVirtualGalleryArtwork}></Route>
          <Route exact path='/my-company/:id/challenge' component={CompanyChallengeList}></Route>
          <Route exact path='/my-company/:id/challenge-edit' component={CompanyChallengeEdit}></Route>
          <Route exact path='/my-company/:id/challenge-edit/:challengeId' component={CompanyChallengeEdit}></Route>
          <Route exact path='/my-company/:id/challenge-media/:challengeId' component={CompanyChallengeMedia}></Route>


          <Route exact path='/collaboration/companies' component={ArtistCompanyList}></Route>

          <Route exact path='/company/spaces' component={SpaceListPage}></Route>
          <Route exact path='/company/:id/space/' component={SpaceViewPage}></Route>
          <Route exact path='/company/:id/gallery/' component={SpaceGalleryPage}></Route>
          <Route exact path='/company/:id/exhibitions/' component={SpaceExhibitionListPage}></Route>
          <Route exact path='/company/:id/exhibition/:exhibitionId' component={SpaceExhibitionPage}></Route>
          <Route exact path='/company/:id/exhibition/:exhibitionId/artwork' component={SpaceExhibitionArtworkPage}></Route>
          <Route exact path='/company/:id/media/' component={SpaceMediaPage}></Route>
          <Route exact path='/company/:id/virtual-galleries/' component={SpaceVirtualGalleryListPage}></Route>
          <Route exact path='/company/:id/challenge/' component={SpaceChallengeListPage}></Route>

          <Route exact path='/artists' component={ArtistListPage}></Route>

          <Route exact path='/park' component={ParkListPage}></Route>
          <Route exact path='/park/:id/view' component={ParkViewPage}></Route>          
          <Route exact path='/themePark/:id/view' component={ThemeParkViewPage}></Route>
          <Route exact path='/park/:id/art' component={ParkArtPage}></Route>
          <Route exact path='/park/:id/map' component={ParkMap}></Route>
          <Route exact path='/park/apply' component={ParkApplyPage}></Route>          

          {/* TERMS */}
          <Route exact path='/terms-of-use' component={TermsConditionsPage}></Route>
          <Route exact path='/terms-of-sales' component={TermsSalesPage}></Route>
          <Route exact path='/privacy' component={PrivacyPolicyPage}></Route>
          {/* <Route exact path='/cookie' component={CookiePolicyPage}></Route> */}

          {/* <Route exact path='/test' component={TestPage}></Route> */}

        </div>
        <div id="footer" className="d-flex flex-column ca-footer">
          <Row className="w-100 mb-0">
            <Col className="text-center">
              <a href="https://www.connected.art/tc/tou.php" target="_blank" rel="noopener noreferrer">{window.$translate(LanguageService.getCurrentLanguage(), "TERMS.TOU")}</a>
              <span> | </span>
              <a href="https://www.connected.art/tc/privacy.php" target="_blank" rel="noopener noreferrer">{window.$translate(LanguageService.getCurrentLanguage(), "TERMS.PRIVACY")}</a>
              <span> | </span>
              <a href="https://www.connected.art/tc/tos.php" target="_blank" rel="noopener noreferrer">{window.$translate(LanguageService.getCurrentLanguage(), "TERMS.TOS")}</a>
              {/* <span> | </span> */}
              {/* <Link to="/cookie">{window.$translate(LanguageService.getCurrentLanguage(), "TERMS.COOKIE")}</Link> */}
            </Col>
            <Col className="text-center">
              <i className="fa fa-copyright"></i> {window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.ALL_RIGHTS_RESERVED")}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default App;