import React from 'react';

import ApiService from '../../../services/api.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import VirtualGalleryArtworksComponent from '../../../components/shared/VirtualGallery/VirtualGalleryArtworksComponent';

class ExhibitionAdminVirtualGalleryArExperienceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      isLoaded: false,
      isError: false,
      canEdit: false,
      errorMessage: "",
      selectedId: 0
    };
  }

  componentDidMount = () => {
    ApiService.getAuthenticatedInstance().get(`gallery/exhibition/virtual-gallery/${this.props.match.params.id}`)
      .then(response => {
        this.setState({
          exhibitionVirtualGallery: response.data,
          canEdit: true,
          isLoaded: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          canEdit: false,
          isError: true,          
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <VirtualGalleryArtworksComponent
            history={this.props.history}
            canEdit={this.state.canEdit}
            isAdmin={true}
            companyId={null}
            entityId={this.state.exhibitionVirtualGallery.id}
            entityName={ApiService.getEntityName().EXHIBITION_VIRTUAL_GALLERY}
            exhibitionId={this.state.exhibitionVirtualGallery.exhibition.id}
            virtualGallery={this.state.exhibitionVirtualGallery.virtualGallery}
            artEntity={ApiService.getEntityName().AR_EXPERIENCE}
            backLink={`/exhibitionAdminVirtualGalleries/${this.state.exhibitionVirtualGallery.exhibition.id}`}
          />
        )
      }
  }

}

export default ExhibitionAdminVirtualGalleryArExperienceList;