import React from 'react';

import GalleryManageVirtualGalleryComponent from '../../../components/shared/Gallery/GalleryManageVirtualGalleryComponent';
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';

class GalleryAdminVirtualGalleryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: this.props.match.params.id,
      isLoaded: false,
      canEdit: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("GALLERY_EDIT"),
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <GalleryManageVirtualGalleryComponent
          history={this.props.history}
          canEdit={this.state.canEdit}
          isAdmin={true}
          icon={galleryIcon}
          galleryId={this.state.galleryId}
          backUrl={`/galleryAdminList`}
          mapUrl={`/gallery/${this.state.galleryId}/map`}
        />        
      )
    }
  }

}

export default GalleryAdminVirtualGalleryList;