import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap/';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';

import CardComponent from "./Components/ArtworkCardComponent";
import ArtworkSelectModal from "./Components/ArtworkSelectModal";
import calogo from '../../../components/shared/Resources/logo-dark-small-with-padding.png'
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import ArtworkService from '../../../services/artwork.service';
import { hasPermission } from '../../../services/api.service';

class VirtualGalleryArtworksPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      virtualGallery: null,
      modalData: {
        content: '',
        title: '',
        dataFor: ''
      },
      galleryArtworks: [],
      artworks: [],
      dropzoneEnabled: true,
      isLoaded: false,
      isError: false,
      showModal: false,
      showFieldModal: false,
      canEdit: false,
      errorMessage: "",
      selectedId: 0
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(response => {
      this.setState({
        canEdit: hasPermission("VIRTUAL_GALLERY_EDIT"),
      }, this.getData)
    });
  }

  async getData() {
    let isError = false;
    let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";
    let galleryArtworks = [];

    if (this.state.canEdit) {

      let virtualGallery = await VirtualGalleryService.getVirtualGalleryForManage(this.state.id)
        .then(response => {
          return response;
        })
        .catch(err => {
          isError = true;
          errorMessage = err;
        })

      galleryArtworks = await VirtualGalleryService.getVirtualGalleryArt(this.state.id)
        .then(response => {
          return response;
        })
        .catch(err => {
          isError = true;
          if (err && err.response && err.response.status && (404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
        })

      let allArtworks = await ArtworkService.getArtworksByCriteria(false, ArtworkService.artworkStatusEnum().PUBLISHED)
        .then(response => { return response });

      if (!isError) {
        this.setState({
          virtualGallery: virtualGallery,
          galleryArtworks: galleryArtworks,
          arArtArtwoks: galleryArtworks.filter(item => item.category === ArtworkService.artworkCategoryEnum().AR_ART),
          sculptureArtwoks: galleryArtworks.filter(item => item.category === "3D_OBJECT"),
          artworks: allArtworks,
          imageURL: virtualGallery.photo ? `${ApiService.getBaseUrl()}/storage/file/${virtualGallery.photo.uuid}` : null,
          isLoaded: true
        }, this.computeGalleryArtworks)
      } else {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  computeGalleryArtworks = () => {
    let virtualGallery = this.state.virtualGallery;
    let galleryArtworks = this.state.galleryArtworks;
    let nrArArt = virtualGallery.nrArArt;
    let nrSculpture = virtualGallery.nrSculpture;
    let artNo = galleryArtworks.length;

    if (artNo !== nrArArt + nrSculpture) {
      let arArtNo = artNo ? galleryArtworks.filter(item => item.category === ArtworkService.artworkCategoryEnum().AR_ART).length : 0;
      let sculptureNo = artNo ? galleryArtworks.filter(item => item.category === "3D_OBJECT").length : 0;

      let arArtPositions = []
      for (let i = 0; i < nrArArt; i++) {
        arArtPositions.push(i + 1)
      }

      let sculpturePositions = []
      for (let i = 0; i < nrSculpture; i++) {
        sculpturePositions.push(i + 1)
      }

      galleryArtworks.forEach(item => {
        if (item.category === ArtworkService.artworkCategoryEnum().AR_ART) {
          arArtPositions = arArtPositions.filter(value => value !== item.position)
        } else if (item.category === "3D_OBJECT") {
          sculpturePositions = sculpturePositions.filter(value => value !== item.position)
        }
      })

      let remainingArArtNo = nrArArt - arArtNo;
      let remainingSculpturetNo = nrSculpture - sculptureNo;

      for (let i = artNo; i < artNo + remainingArArtNo; i++) {
        let galleryArtwork = {};
        galleryArtwork.id = 0;
        galleryArtwork.imageURL = calogo;
        galleryArtwork.category = ArtworkService.artworkCategoryEnum().AR_ART;
        galleryArtwork.position = arArtPositions[0];
        arArtPositions = arArtPositions.filter(value => value !== galleryArtwork.position);

        galleryArtworks.push(galleryArtwork)
      }

      for (let i = artNo + remainingArArtNo; i < artNo + remainingArArtNo + remainingSculpturetNo; i++) {
        let galleryArtwork = {};
        galleryArtwork.id = 0;
        galleryArtwork.imageURL = calogo;
        galleryArtwork.category = "3D_OBJECT";
        galleryArtwork.position = sculpturePositions[0];
        sculpturePositions = sculpturePositions.filter(value => value !== galleryArtwork.position);

        galleryArtworks.push(galleryArtwork)
      }
    }

    this.setState({
      galleryArtworks: galleryArtworks,
      arArtArtwoks: galleryArtworks.filter(item => item.category === ArtworkService.artworkCategoryEnum().AR_ART),
      sculptureArtwoks: galleryArtworks.filter(item => item.category === "3D_OBJECT"),
    })
  }

  onShowModal = (id, categoryCode, position) => {
    this.setState({
      showModal: true,
      selectedId: id,
      categoryCode: categoryCode,
      position: position
    })
  }

  onHideModal = (mustFetch) => {
    if (mustFetch) {
      VirtualGalleryService.getVirtualGalleryArt(this.state.id)
        .then(response => {
          this.setState({
            galleryArtworks: response,
          }, this.computeGalleryArtworks)
        })
    }

    this.setState({
      showModal: false,
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
              <div className="pr-2 d-flex align-items-center">
                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>
                  {this.state.virtualGallery.name}
                  <br />
                  <span className="ca-page-subtitle ca-muted-text">{translatedMessage("VIRTUAL_GALLERY.MANAGE_ARTWORKS")}</span>
                </div>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push("/virtualGalleryList")}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            <Row>
              <Col className="col-12 mb-3">
                <span className="ca-page-subtitle">
                  {translatedMessage("AR_ART") + ": " + this.state.virtualGallery.nrArArt + " " + translatedMessage("ARTWORK.ARTWORKS").toLowerCase()}
                </span>
              </Col>

              {this.state.arArtArtwoks.map((element, index) => {
                return (
                  <Col key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <CardComponent
                      cardImage={element.imageURL}
                      entity={element}
                      id={0 === element.id ? 0 : element.parentEntityId}
                      entityListType={ApiService.getEntityName().ARTWORK}
                      onClick={this.onShowModal}
                    />
                  </Col>
                )
              })}
            </Row>

            <Row>
              <Col className="col-12 mb-3">
                <span className="ca-page-subtitle">
                  {translatedMessage("3D_OBJECT") + ": " + this.state.virtualGallery.nrSculpture + " " + translatedMessage("ARTWORK.ARTWORKS").toLowerCase()}
                </span>
              </Col>

              {this.state.sculptureArtwoks.map((element, index) => {
                return (
                  <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <CardComponent
                      cardImage={element.imageURL}
                      entity={element}
                      id={0 === element.id ? 0 : element.parentEntityId}
                      entityListType={ApiService.getEntityName().ARTWORK}
                      onClick={this.onShowModal}
                    />
                  </Col>
                )
              })}
            </Row>

            <ArtworkSelectModal
              show={this.state.showModal}
              onHide={this.onHideModal}
              vgId={this.state.id}
              vgaId={this.state.selectedId}
              artworks={this.state.artworks}
              categoryCode={this.state.categoryCode}
              position={this.state.position}
            />
          </div>
        )
      }
  }

}

export default VirtualGalleryArtworksPage;