import React from 'react';
import './CreateModalComponent.css';
import ApiService from '../../../../services/api.service'
import UserService from '../../../../services/user.service'
import { translatedMessage } from '../../../../services/language.service'

import { Modal, Button, Form, FormGroup } from 'react-bootstrap/'

import { toast } from "react-toastify";

class CreateModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            firstName: "",
            lastName: "",
            membership: 0,
            roles: [],
            membershipList: [],
            roleList: []
        }
    }

    componentDidMount() { this.getData() }

    async getData() {
        var membershipList = await UserService.getMembershipListForUserCreate()
            .then(result => { return result })

        var roleList = await UserService.getRoleList()
            .then(result => { return result })

        var defaultMembership = membershipList.filter(item => item.name === "Member");

        this.setState({
            membershipList: membershipList,
            roleList: roleList,
            membership: defaultMembership[0] ? defaultMembership[0].id : 0
        });

    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    roleChange = event => {
        var roles = [].slice.call(event.target.selectedOptions).map(item => parseInt(item.value));

        this.setState({
            roles: roles
        });
    }

    exitModal = (event) => {
        event.preventDefault();

        ApiService.getAuthenticatedInstance().post('/user/create', {
            username: event.target.elements.username.value,
            firstName: event.target.elements.firstName.value,
            lastName: event.target.elements.lastName.value,
            membership: parseInt(this.state.membership),
            roles: this.state.roles,
        })
            .then(() => {
                toast.success(translatedMessage("USER.SAVE.SUCCESS"));
                this.props.onHide();
            });
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {translatedMessage("USER.ADD_USER")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="user_form" className="ca-form" onSubmit={this.exitModal}>
                        <Form.Group controlId="formUsername">
                            <Form.Label>{translatedMessage("USER.USERNAME")}</Form.Label>
                            <Form.Control
                                name="username"
                                placeholder={translatedMessage("USER.USERNAME_PLACEHOLDER")}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>{translatedMessage("USER.FIRST_NAME")}</Form.Label>
                            <Form.Control
                                name="firstName"
                                placeholder={translatedMessage("USER.FIRST_NAME_PLACEHOLDER")}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label>{translatedMessage("USER.LAST_NAME")}</Form.Label>
                            <Form.Control
                                name="lastName"
                                placeholder={translatedMessage("USER.LAST_NAME_PLACEHOLDER")}
                                required
                            />
                        </Form.Group>

                        <FormGroup controlId="formMembership">
                            <Form.Label>{translatedMessage("MEMBERSHIP.MEMBERSHIP")}</Form.Label>
                            <Form.Control
                                name="membership"
                                as="select"
                                onChange={this.userInfoChange}
                                required
                            >
                                {this.state.membershipList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{translatedMessage("MEMBERSHIP." + item.name.toUpperCase())}</option>
                                    )
                                })}
                            </Form.Control>
                        </FormGroup>

                        <Form.Group controlId="">
                            <Form.Label>{translatedMessage("USER.ROLES")}</Form.Label>
                            <Form.Control
                                name="roles"
                                as="select"
                                multiple
                                onChange={this.roleChange}
                            >
                                {this.state.roleList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" type="submit" form="user_form">
                        {translatedMessage("GENERAL.SAVE_CHANGES")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default CreateModalComponent