import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Image } from 'react-bootstrap';
import { translatedMessage } from '../../../../services/language.service';

import editIcon from '../../../../components/shared/Resources/icons/edit.svg';
import mediaIcon from '../../../../components/shared/Resources/icons/media.svg';

class ChallengeCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let status = translatedMessage("CHALLENGE.STATUS.ACTIVE");
        if(!this.props.cardDetails.isActive) {
            status = this.props.cardDetails.notificationSent ? translatedMessage("CHALLENGE.NOTIFICATION.SENT") : translatedMessage("CHALLENGE.STATUS.INACTIVE");
        }

        return (
            <>
                <Card className="ca-gallery-card">
                    <Link className="ca-dark-link" to={`/my-company/${this.props.companyId}/challenge-edit/${this.props.id}`} title={translatedMessage("GENERAL.VIEW")}>
                        <Card.Img variant="top" src={this.props.cardImage} />
                    </Link>
                    <Card.Body>
                        <Card.Title>{this.props.cardTitle}</Card.Title>
                        <Card.Text className="mb-1" style={{minHeight: "45px"}}>
                            <b>{translatedMessage("GENERAL.STATUS")}: </b>
                            {status}
                        </Card.Text>
                        <footer className="ca-gallery-card-footer">
                            <Link className="ca-dark-link small" to={`/my-company/${this.props.companyId}/challenge-edit/${this.props.id}`} title={translatedMessage("CHALLENGE.EDIT")}>
                                <Image src={editIcon} alt={translatedMessage("CHALLENGE.EDIT")}/>
                            </Link>
                            <Link className="ca-dark-link small ml-1" to={`/my-company/${this.props.companyId}/challenge-media/${this.props.id}`} title={translatedMessage("CHALLENGE.MEDIA_FILES")}>
                                <Image src={mediaIcon} alt={translatedMessage("CHALLENGE.MEDIA_FILES")}/>
                            </Link>                                                        
                        </footer>
                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default ChallengeCardComponent;