import React from 'react';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

class GalleryArtworkManageLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryArtworkId: this.props.match.params.id,
      gallery: [],
      artwork: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      mapProps: null,
      map: null,
      marker: null,
      circle: null,
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      mapLocations: [],
      initialCenter: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      let canEdit = hasPermission("GALLERY_EDIT") || hasPermission("COMPANY_EDIT");
      if (canEdit) {
        this.setState({
          canEdit: canEdit,
        }, this.getData)
      } else {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        ApiService.initCurrentCompany()
        .then(response => {
          if (response === null || ApiService.getCompanyUserPendingApproval()) {
            this.setState({
              canEdit: false
            }, this.getData)
          } else {
            this.setState({
              company: ApiService.getCurrentCompany(),
              canEdit: true,
            }, this.getData)
          }
        })
        .catch(err => {
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        });        
      }
    });
  }

  getData = () => {
    let gallery = [];
    let artwork = [];
    let promise = [];
    let errorMessage = "GENERAL.GET_DATA_ERROR";    

    if (this.state.canEdit) {
      ApiService.getAuthenticatedInstance().get(`gallery/galleryArtwork/${this.state.galleryArtworkId}`)
        .then(response => {
          gallery = response.data.gallery;
          artwork = response.data.artwork
        })
        .then(() => {
          let initialCenter = {};
          promise.push(ApiService.getAuthenticatedInstance().get(`gallery/${gallery.id}/locations`)
            .then(response => {
              if (response.data[0]) {
                initialCenter = {
                  lat: response.data[0].locationLat,
                  lng: response.data[0].locationLng,
                }
              } else {
                initialCenter = null
              }
            })
          )

          let artLocations = [];
          let mapLocations = [];
          promise.push(
            ApiService.getAuthenticatedInstance().get(`map/galleryMapLocations/${gallery.id}`)
              .then(result => {
                mapLocations = result.data.filter(item => item.entity.entityId !== artwork.id)
                artLocations = result.data.filter(item => item.entity.entityId === artwork.id)
              })
          )

          let galleryPin = []
          promise.push(MapService.getLocationPinByType(gallery.galleryType)
            .then(response => {
              galleryPin = response;
            }))          

          let pins = [];
          promise.push(MapService.getLocationPinByType(artwork.category.code)
            .then(response => {
              pins = response;
            }))

          Promise.all(promise).then(() => {
            this.setState({
              gallery: gallery,
              artwork: artwork,
              location: artLocations[0] ? artLocations[0] : this.state.location,
              mapLocations: mapLocations,
              hasGeofence: artLocations[0] && artLocations[0].geofence ? true : false,
              pinIcon: pins[0] ? pins[0].imageUrl : null,
              galleryPinIcon: galleryPin[0] ? galleryPin[0].imageUrl : null,
              initialCenter: initialCenter,
              isLoaded: true
            })
          })       
        })
        .catch(err => {          
          if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          });
        });        
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.galleryArtworkId}
            entityName={ApiService.getEntityName().GALLERY_ARTWORK}
            mapLocations={this.state.mapLocations}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={this.state.galleryPinIcon}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.artwork.name}
            parentEntityTitle={this.state.gallery.name}
            backLink={`/galleryAdminArtworks/${this.state.gallery.id}`}
            history={this.props.history}
            zoomLevel={17}
          />                
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default GalleryArtworkManageLocation;