import React from 'react';

import { Modal, Button } from 'react-bootstrap'

import { translatedMessage } from '../../../services/language.service'

class SizeChangeModalPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    changeSize = () => {
        this.props.onHide(true);
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal ca-modal"
            >
                <Modal.Header className="empty-modal-header"></Modal.Header>
                <div id="contained-modal-title-vcenter" className="ca-modal-title text-center">
                    {translatedMessage("ARTWORK.SIZE_CHANGED.TITLE")}
                </div>
                <Modal.Body className="text-center ca-modal-delete-confirm mb-3">
                    {translatedMessage(this.props.message)}
                </Modal.Body>
                <Modal.Footer className="justify-content-around">
                    <Button variant="dark" className="ca-button ca-button-white" onClick={this.changeSize}>{translatedMessage("GENERAL.YES")}</Button>
                    <Button variant="dark" className="ca-button" onClick={this.props.onHide}>{translatedMessage("GENERAL.NO")}</Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

export default SizeChangeModalPage;