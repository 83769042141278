import ApiService from './api.service';
import moment from 'moment';

const PaymentService = {
  createCart: (user, addressId) => {
    return ApiService.getAuthenticatedInstance().post('/carts', {
      buyer: `/users/${user.id}`,
      billingAddress: addressId !== 0 ? `/address/${addressId}` : null,
      lastUpdatedBy: user.username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      email: user.username
    })
  },

  addToCart(user, cartId, artworkPackage, companyId) {
    return ApiService.getAuthenticatedInstance().post('/cartItems', {
      cart: `/carts/${cartId}`,
      pricePackage: `/artworkPricePackages/${artworkPackage.id}`,
      company: companyId ? `/companies/${companyId}` : null,
      lastUpdatedBy: user.username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    })
    .catch(err => console.log(err))
  },

  addTicketToCart: (user, cartId, entityId, entityName, price, durartion) => {
    return ApiService.getAuthenticatedInstance().post('/cartItems', {
      cart: `/carts/${cartId}`,
      entity: {
        entityId: entityId,
        entityName: entityName
      },
      price: price,
      duration: durartion,
      isTicket: true,
      lastUpdatedBy: user.username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    })
  },

  // checks if there is a valid card for the user, if not creates one
  getCart(user, addressId) {
    return new Promise((resolve, reject) => {      
      ApiService.getAuthenticatedInstance().get(`/user/userCart/${user.id}`)
        .then(response => resolve(response.data.id))
        .catch(err => {
          if (err && err.response && err.response.status && 404 === err.response.status) {
            this.createCart(user, addressId)
              .then(cart => {
                console.log(cart.data)
                const cartId = cart.data._links.self.href.split("/")[cart.data._links.self.href.split("/").length - 1];

                console.log(cartId);

                resolve(cartId)
              })
              .catch(e => {
                console.log(e);
                reject("CART.ADD_FAIL");
              });
          } else {
            console.log(err);
            reject("CART.ADD_FAIL");
          }
        });
    });
  },



};

export default PaymentService;