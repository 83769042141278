import React from 'react';

import { Row, Col, Form, Button, Dropdown, Image } from 'react-bootstrap';

import CardComponent from "../Components/ExhibitionCardComponent";
import addIcon from '../../../../components/shared/Resources/icons/add.svg';
import backIcon from '../../../../components/shared/Resources/left-arrow.svg';

import exhibition from '../../../../components/shared/Resources/homepage/exhibition.svg';
import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';

import ApiService from '../../../../services/api.service';
import GalleryService from '../../../../services/gallery.service';
import { translatedMessage } from '../../../../services/language.service';
import CompanyService from '../../../../services/company.service';

const ENTER_KEY = 13;

class CompanyExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      searchInput: '',
      allList: [],
      filteredList: [],
      statusList: [],
      selectedStatus: { label: "GENERAL_STATUS.ANY", name: "ANY" },
      isLoaded: false,
      isError: false,
      errorMessage: "",
      company: null
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
    .then(response => {
      this.setState({
        company: response.data.company,
      }, this.getExhibitions)
    })
    .catch(err => {
      console.log(err)
      if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
        errorMessage = err.response.data.message;
      }

      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    });
  }

  async getExhibitions() {
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    var statusList = await GalleryService.getExhibitionStatusList()
      .then(response => {
        let statuses = response;
        statuses.splice(0, 0, { label: "GENERAL_STATUS.ANY", name: "ANY" })

        return statuses;
      })

    var exhibitions = await CompanyService.getCompanyExhibitionsForManage(this.state.company.id)
      .then(response => { return response; })
      .catch(err => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })

    this.setState({
      allList: exhibitions,
      filteredList: exhibitions,
      statusList: statusList,
      isLoaded: true
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleStatusSelect = (eventKey) => {
    const selectedItem = this.state.statusList.filter(item => item.name === eventKey);

    this.setState({
      selectedStatus: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.allList;
    let searchInput = this.state.searchInput;
    let statusItem = this.state.selectedStatus.name;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.allList.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (statusItem !== 'ANY') {
      result = result.filter(elem => elem.status.name === statusItem);
    }

    this.setState({
      filteredList: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="pr-2 d-flex align-items-center">
              <Image src={exhibition} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
              <span className="ca-page-title">{translatedMessage("EXHIBITION.EXHIBITIONS")}</span>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              {this.state.company &&
                <Button onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/exhibition-edit`)} className="ca-dark-link ca-button-icon medium mr-1">
                  <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")}/>
                </Button>
              }

              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/space`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>
          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedStatus.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.statusList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            {this.state.filteredList.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pl-0 pr-0">
                  <CardComponent
                    cardImage={element.imageURL}
                    cardTitle={element.name}
                    cardDetails={element}
                    id={0 === element.id ? 0 : element.id}
                    companyId={this.state.company.id}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default CompanyExhibitionPage;