import React from 'react';

import ParkService from '../../services/park.service';
import GenericErrorComponents from '../../components/shared/ErrorComponents/GenericErrorComponents';
import ParkViewComponent from '../../components/shared/Park/ParkViewComponent';

class ThemeParkViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.match.params.id,
      park: {},
      isLoaded: false,
    };
  }

  componentDidMount = () => {
    // console.log("ParkViewPage: ", this.props.match.params.id)
    this.getData()
  }

  getData = () => {
    let park = {};

    ParkService.getPark(this.props.match.params.id)
      .then(async (response) => {
        park = response;

        this.setState({
          park: park,
          isLoaded: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }


  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <ParkViewComponent
            history={this.props.history}
            park={this.state.park}
            artUrl={`/park/${this.state.park?.id}/art`}
            backUrl={this.state.park?.parentPark?.id  ? `/park/${this.state.park?.parentPark?.id}/view` : `park`}
          />
        </>
      )
    } else {
      return (
        <GenericErrorComponents errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ThemeParkViewPage;