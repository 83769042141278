import React from 'react';

import { Carousel } from 'react-bootstrap';
import { toast } from "react-toastify/index";
import moment from 'moment';

import { translatedMessage } from "../../../services/language.service";
import FileService from "../../../services/file.service";
import ApiService from "../../../services/api.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DeleteConfirmModalComponent from '../DeleteConfirmModalComponent/DeleteConfirmModalComponent'
import ChangeYoutubeIdModalComponent from '../ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'
import LightboxComponent from '../LightboxComponent/LightboxComponent'
import ChangePassePartoutColorComponentModal from '../ChangePassePartoutColorComponentModal/ChangePassePartoutColorComponentModal';

import defaultVideo from '../Resources/videoDefault.jpg';
// import ModelViewer from "../ModelViewer/ModelViewer";

class MediaCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextIcon: <div className="ca-carousel-navigator-container ca-carousel-next"><span aria-hidden="true" className="carousel-control-next-icon" /></div>,
      prevIcon: <div className="ca-carousel-navigator-container ca-carousel-prev"><span aria-hidden="true" className="carousel-control-prev-icon" /></div>,
      index: 0,
      showConfirmModal: false,
      deletedFileUuid: null,
      videoUrlId: null,
      isDeleteFile: false,
      isDeleteUrl: false,
      showEditYoutubeModal: false,
      storageItemFileId: null,
      youtubeId: null,
      mediaFiles: this.props.items,
      lighboxImages: [],
      photoIndex: 0,
      showLightbox: false,
      selectedFile: {
        passepartoutColor: ""
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items !== this.props.items) {
      this.setState({ mediaFiles: this.props.items });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items !== prevState.mediaFiles) {
      return { mediaFiles: prevState.mediaFiles };
    }
    else return null;
  }

  stopVideo = (element) => {
    var iframe = element.querySelector('iframe');
    var video = element.querySelector('video');
    setTimeout(() => {
      if (iframe) {
        var iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
      if (video) {
        video.pause();
      }
    }, 1000)
  };

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  }

  handleSlide = (selectedIndex, direction) => {
    var prevIndex = 0;
    if (direction === "left") {
      prevIndex = selectedIndex === 0 ? this.state.mediaFiles.length - 1 : selectedIndex - 1;
    } else {
      prevIndex = selectedIndex === this.state.mediaFiles.length - 1 ? 0 : selectedIndex + 1;
    }
    var previousItem = this.state.mediaFiles[prevIndex]
    if (previousItem) {
      if (previousItem.youtubeId !== null) {
        var elementId = this.props.id + "_item_" + (prevIndex);
        this.stopVideo(document.getElementById(elementId));
      }
    }
  }

  handleDelete = (event) => {
    if (event.target.getAttribute('name') === 'file') {
      const fileUuid = event.target.id;
      let file = this.state.mediaFiles.filter(item => item.file && item.file.uuid === fileUuid);
      let confirmText = "";
      if (file[0]) {
        if (file[0].file.usage === FileService.getFileUsage().THUMBNAIL) {
          confirmText = translatedMessage("PROJECT.IMG.DELETE_CONFIRM")
        } else if (file[0].file.usage === FileService.getFileUsage().VIDEO) {
          confirmText = translatedMessage("PROJECT.VIDEO.DELETE_CONFIRM")
        } else if (file[0].file.usage === FileService.getFileUsage().PRINT_FILE) {
          confirmText = translatedMessage("PROJECT.PRINT.DELETE_CONFIRM")
        }
      }

      this.setState({
        showConfirmModal: true,
        deletedFileUuid: fileUuid,
        isDeleteFile: true,
        confirmText: confirmText
      })
    } else if (event.target.getAttribute('name') === 'url') {
      const videoUrlId = event.target.id;
      let confirmText = translatedMessage("VIDEO_URL.DELETE_CONFIRM");

      this.setState({
        showConfirmModal: true,
        videoUrlId: videoUrlId,
        isDeleteUrl: true,
        confirmText: confirmText
      })
    }
  }

  onHideConfirmation = (willDelete) => {
    if (willDelete && willDelete === true) {
      if (this.state.isDeleteFile) {
        this.deleteFileItem(this.state.deletedFileUuid)
      } else if (this.state.isDeleteUrl) {
        this.deleteVideoUrl(this.state.videoUrlId)
      }
    }
    this.setState({
      showConfirmModal: false,
    })
  }

  deleteFileItem = (fileUuid) => {
    let selectedIndex = this.state.index;
    var previousItem = this.state.mediaFiles[selectedIndex - 1]

    FileService.deleteFile(fileUuid)
      .then(() => {
        let mediaFiles = this.state.mediaFiles.filter(item => item.file && item.file.uuid !== fileUuid);
        this.setState({
          mediaFiles: mediaFiles,
          index: previousItem ? selectedIndex - 1 : selectedIndex
        }, this.props.onChange);
        toast.success(translatedMessage("FILE.DELETE_SUCCES"));
      })
      .catch(err => {
        toast.error(translatedMessage("FILE.DELETE_ERROR"));
        console.log("An error has ocurred: " + err);
      });
  }

  deleteVideoUrl = (id) => {
    let selectedIndex = this.state.index;
    var previousItem = this.state.mediaFiles[selectedIndex - 1]

    FileService.deleteVideoUrl(id)
      .then(() => {
        let mediaFiles = this.state.mediaFiles.filter(item => item.entity && item.id !== id);
        this.setState({
          mediaFiles: mediaFiles,
          index: previousItem ? selectedIndex - 1 : selectedIndex
        }, this.props.onChange);
        toast.success(translatedMessage("FILE.DELETE_SUCCES"));
      })
      .catch(err => {
        toast.error(translatedMessage("FILE.DELETE_ERROR"));
        console.log("An error has ocurred: " + err);
      });
  }

  handleYoutubeIdEdit = (event) => {
    if (event.target.getAttribute('name') === 'file') {
      const storageItemFileId = parseInt(event.target.id);
      let file = this.state.mediaFiles.filter(item => item.storageItemFileId === storageItemFileId);

      this.setState({
        showEditYoutubeModal: true,
        storageItemFileId: storageItemFileId,
        youtubeId: file[0] ? file[0].youtubeId : null
      })
    } else if (event.target.getAttribute('name') === 'url') {
      const videoUrlId = parseInt(event.target.id);
      let video = this.state.mediaFiles.filter(item => item.entity && item.id === videoUrlId);

      this.setState({
        showEditYoutubeModal: true,
        videoUrlId: videoUrlId,
        youtubeId: video[0] ? video[0].videoId : null
      })
    }
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, youtubeId) => {
    if (youtubeId) {
      if (storageItemFileId) {
        ApiService.getAuthenticatedInstance().patch(`/storageItemFiles/${storageItemFileId}`, {
          youtubeId: youtubeId,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then(() => {
            let mediaFiles = this.state.mediaFiles;
            mediaFiles.map(item => {
              if (item.storageItemFileId && item.storageItemFileId === storageItemFileId) {
                item.youtubeId = youtubeId
              }
              return item;
            })
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
            this.setState({
              mediaFiles: mediaFiles,
              showEditYoutubeModal: false
            })
          })
          .catch(err => {
            console.log("An error has ocurred: " + err);
            toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          })
      } else if (videoUrlId) {
        ApiService.getAuthenticatedInstance().patch(`/entityVideoUrls/${videoUrlId}`, {
          videoId: youtubeId,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then(() => {
            let mediaFiles = this.state.mediaFiles;
            mediaFiles.map(item => {
              if (item.entity && item.id === videoUrlId) {
                item.videoId = youtubeId
              }
              return item;
            })
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
            this.setState({
              mediaFiles: mediaFiles,
              showEditYoutubeModal: false
            })
          })
          .catch(err => {
            console.log("An error has ocurred: " + err);
            toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          })
      }
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  handleFullScreen = (index) => {
    let lighboxImages = [];      
    this.state.mediaFiles
      .filter(item => item.type === FileService.getResourceType().IMAGE)
      .forEach(item => {
        lighboxImages.push(item.original)
      });
    this.setState({
      lighboxImages: lighboxImages,
      photoIndex: index,
      showLightbox: true
    })
  }

  onHideLightbox = () => {
    this.setState({
      showLightbox: false
    })
  }

  onShowPassePModal = (event) => {
    let printFile = this.state.mediaFiles.filter(item => item.file.id === parseInt(event.currentTarget.id));

    this.setState({
      showPassePModal: true,
      selectedFile: printFile[0] ? printFile[0].file : ""
    })
  }

  onHidePassePChange = (color) => {    
    if (color && color !== this.state.selectedFile.passepartoutColor) {
      
      let apiEntityUrl = 'artwork'
      if(this.props.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
        apiEntityUrl = 'virtualGallery'
      }
      
      ApiService.getAuthenticatedInstance().post(`/${apiEntityUrl}/change-passepartout/${this.props.entityId}/${this.state.selectedFile.id}?color=${color}`)
        .then((response) => {
          if (response.data.status === "OK") {
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));

            this.setState({
              showPassePModal: false,
            }, this.props.onChange)
          } else {
            toast.error(translatedMessage(response.data.message));

            this.setState({
              showPassePModal: false,
            })
          }
        })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);

          this.setState({
            showPassePModal: false,
          })
        });
    } else {
      this.setState({
        showPassePModal: false,
      })
    }

  }

  render() {
    return (
      <div className="w-100">
        <Carousel
          className={"ca-carousel ".concat(this.props.class)}
          activeIndex={this.state.index}
          onSelect={this.handleSelect}
          onSlide={this.handleSlide}
          prevIcon={this.state.prevIcon}
          nextIcon={this.state.nextIcon}
          controls={this.props.items.length === 1 ? false : this.props.controls}
          indicators={this.props.indicators}
          interval={this.props.interval}
          slide={true}
        >
          {this.state.mediaFiles.map((element, index) => {
            let youtubeId = null;

            if (this.props.videoUrlOnly) {
              if (element.type.name === FileService.getVideoUrlType().YOUTUBE) {
                // the media is a video url
                youtubeId = element.videoId;
              }
            } else if (element.type === FileService.getResourceType().VIDEO) {
              // the media is a video file
              youtubeId = element.youtubeId;
            }

            return (
              <Carousel.Item key={index} id={this.props.id + "_item_" + index}>
                <div className="d-flex align-items-center justify-content-center h-100">
                  {element.type === FileService.getResourceType().IMAGE &&
                    // if the media is an image
                    <img
                      className="d-block w-100"
                      src={element.thumbnail}
                      alt="Slide media file"
                    />
                  }
                  {youtubeId !== null &&
                    // if the media is an YouTube video
                    <iframe title="iframe"
                      width="100%"
                      height="100%"
                      src={"https://www.youtube.com/embed/".concat(youtubeId).concat("?rel=0&start=0&modestbranding=1&enablejsapi=1&origin=https://app.connected.art/")}
                      frameBorder="0"
                      allowFullScreen>
                    </iframe>
                  }
                  {!this.props.videoUrlOnly && element.type === FileService.getResourceType().VIDEO && element.youtubeId === null &&
                    // if the media is a video file
                    <div className="w-100 ca-novideo-container">
                      <div className="h-100 ca-novideo d-flex align-items-center justify-content-center">
                        <div className="w-100" style={{ color: "#ffffff", position: "absolute", top: "5px" }}>
                          {element.file.usage !== 'VIDEO' && translatedMessage("PROJECT.UPLOAD_VID." + element.file.usage)}
                        </div>
                        <img src={defaultVideo} alt="No video available" width="60" />
                      </div>
                    </div>
                  }
                  {/* if the model file will be enabled at some point */}
                  {/* {element.type === FileService.getResourceType().OBJECT_MODEL &&
                    <ModelViewer modelFile={ApiService.getFileByUuid(element.file.uuid)} artworkName={element.artworkName} styleClass="ca-model-viewer-carousel" />
                  } */}
                  {this.props.hasToolbar &&
                    (this.props.toolbarDelete ||
                      (this.props.toolbarYoutubeId && element.type === FileService.getResourceType().VIDEO) ||
                      (this.props.toolbarFullScreen && element.type === FileService.getResourceType().IMAGE)) &&
                    <div className="ca-carousel-toolbar-container">
                      <span className="ca-carousel-toolbar-control-box">
                        {this.props.toolbarDelete && !this.props.videoUrlOnly &&
                          (this.state.mediaFiles.length > 1 || (this.state.mediaFiles.length === 1 && this.props.canDeleteLast)) &&
                          // the media is a file
                          <span id={element.file.uuid} name="file" onClick={this.handleDelete}
                            className="ca-carousel-toolbar-control ml-1 mr-1"
                            title={translatedMessage("FILE.DELETE_ITEM")}>&#xf014;</span>
                        }
                        {this.props.toolbarDelete && this.props.videoUrlOnly &&
                          // the media is a video url
                          <span id={element.id} name="url" onClick={this.handleDelete}
                            className="ca-carousel-toolbar-control ml-1 mr-1"
                            title={translatedMessage("FILE.DELETE_ITEM")}>&#xf014;</span>
                        }
                        {this.props.toolbarYoutubeId && !this.props.videoUrlOnly &&
                          // the media is a video file
                          <span id={element.storageItemFileId} name="file" onClick={this.handleYoutubeIdEdit}
                            className="ca-carousel-toolbar-control ml-1 mr-1"
                            title={translatedMessage("VIDEO.EDIT_YOUTUBE_ID")}>&#xf16a;</span>
                        }
                        {this.props.toolbarYoutubeId && this.props.videoUrlOnly &&
                          // the media is a video url
                          <span id={element.id} name="url" onClick={this.handleYoutubeIdEdit}
                            className="ca-carousel-toolbar-control ml-1 mr-1"
                            title={translatedMessage("VIDEO.EDIT_YOUTUBE_ID")}>&#xf16a;</span>
                        }
                        {this.props.toolbarFullScreen && element.type === FileService.getResourceType().IMAGE &&
                          <span id={element.file.uuid} onClick={() => this.handleFullScreen(index)}
                            className="ca-carousel-toolbar-control ml-1 mr-1"
                            title={translatedMessage("GENERAL.FULL_SCREEN")}>&#xf0b2;</span>
                        }
                        {this.props.toolbarPassepartout && element.type === FileService.getResourceType().IMAGE &&
                          <span id={element.file.id} onClick={this.onShowPassePModal}
                            className="ca-carousel-toolbar-control ml-1 mr-1"
                            title={translatedMessage("ARTWORK.PRINT.CHANGE_PASSEPARTOUT_COLOR")}>

                            <FontAwesomeIcon className="ml-2" icon={["far", "square"]} />
                          </span>
                        }
                      </span>
                    </div>
                  }
                </div>
              </Carousel.Item>
            )
          })
          }
        </Carousel>

        <DeleteConfirmModalComponent
          show={this.state.showConfirmModal}
          onHide={this.onHideConfirmation}
          message={this.state.confirmText}
        />

        <ChangeYoutubeIdModalComponent
          show={this.state.showEditYoutubeModal}
          onHide={this.onHideYoutubeModal}
          storageitemfileid={this.state.storageItemFileId}
          videourlid={this.state.videoUrlId}
          youtubeid={this.state.youtubeId}
        />

        <LightboxComponent
          images={this.state.lighboxImages}
          showLightbox={this.state.showLightbox}
          photoIndex={this.state.photoIndex}
          onHide={this.onHideLightbox}
        />

        <ChangePassePartoutColorComponentModal
          show={this.state.showPassePModal}
          onHide={this.onHidePassePChange}
          data={this.state.selectedFile.passepartoutColor}
        />
      </div>
    );
  }
}

export default MediaCarousel;