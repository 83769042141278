import React from 'react';

import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import ExhibitionEditComponent from '../../../components/shared/Exhibition/ExhibitionManageEditComponent';

class ExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.id ? this.props.match.params.id : null,
      isLoaded: false,
      canEdit: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("EXHIBITION_EDIT"),
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionEditComponent
          companyId = {null}
          exhibitionId = {this.state.exhibitionId}
          canEdit = {this.state.canEdit}
          history={this.props.history}
          backUrl={`/exhibitionAdminList`}
          redirectNewUrl={`/exhibitionAdminEdit/`}
        />        
      )
    } else {
      return (
        <ErrorComponent errorMessage={"USER.SECURITY.MISSING_RIGHTS"} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionPage;