import React from 'react';

import { Card, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translatedMessage } from '../../../services/language.service';

import mapPinIcon from '../../../components/shared/Resources/icons/map_pin.svg';
import triggerIcon from '../../../components/shared/Resources/icons/trigger.svg';

class EntityManageArtworkCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  componentDidMount = () => {
    this.setState({
      checked: this.props.existingArt
    })
  }

  handleChange = (event) => {
    const input = event.currentTarget.querySelector('input')
    this.setState({
      checked: !input.checked
    })
    this.props.onCheck(input.id, !input.checked);
  }

  render() {
    return (
      <Card className="ca-artwork-card">
        <Link to={this.props.cardLink}>
          <Card.Img variant="top" src={this.props.cardImage} />
        </Link>
        <Card.Body>
          <Link to={this.props.cardLink} className="ca-dark-link">
            <Card.Title>
              {this.props.cardTitle}
              <span className="font-weight-normal">
                &nbsp;{translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                {this.props.cardArtworkAuthor}
              </span>
            </Card.Title>
            <Card.Text>{translatedMessage("GENERAL.CATEGORY") + ": " +
              translatedMessage("PROJECT.CATEGORY." + this.props.cardCategory)}</Card.Text>
            {this.props.soldByCompany &&
              <Card.Text className='mt-3'>
                {translatedMessage("ARTWORK.COMPANY_COMM_PORTFOLIO")}
                <OverlayTrigger
                  key='ca-soldByCompany'
                  placement='bottom'
                  overlay={
                    <Tooltip id={`tooltip-ca-soldByCompany`} className="ca-tooltip">
                      {translatedMessage("ARTWORK.COMPANY_COMM_PORTFOLIO.INFO")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>

              </Card.Text>
            }
          </Link>
          {!this.props.isAddArtwork &&
            <div>
              {this.props.hasFreeGiftOption &&
                <div onClick={(event) => this.props.onAddFreeGift(this.props.entityArtworkId, !this.props.isFreeGift)} className="ca-check-container mt-2">
                  <input
                    checked={this.props.isFreeGift}
                    readOnly
                    type="checkbox"
                    className="d-flex align-items-center"
                  />
                  <span className="ca-check-label">{translatedMessage("ARTWORK.FREE_GIFT_WITH_ENTITY")}</span>
                  <span className="ca-checkmark"></span>
                </div>
              }
              {this.props.hasFreeForEntityOption &&
                <div onClick={(eventKey) => this.props.onAddFreeEntityArt(this.props.entityArtworkId, !this.props.isFreeForEntity)} className="ca-check-container mt-2">
                  <input
                    checked={this.props.isFreeForEntity}
                    readOnly
                    type="checkbox"
                    className="d-flex align-items-center"
                  />
                  <span className="ca-check-label">{translatedMessage("ARTWORK.FREE_FOR_EVENT")}</span>
                  <span className="ca-checkmark"></span>
                </div>
              }
            </div>
          }
          {!this.props.isAddArtwork &&
            <footer className="ca-gallery-card-footer">
              <div className='d-flex align-items-center justify-content-between mt-3'>
                <div className="more-info-button" onClick={() => this.props.onClick(this.props.id)}>
                  {translatedMessage("GENERAL.MORE_INFO")}
                </div>
                <div>
                  {this.props.artworkCanHaveLocation &&
                    <Link className="ca-dark-link small ml-1" to={this.props.locationRedirectUrl} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                      <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")}/>
                    </Link>
                  }
                  {this.props.cardCategory === "3D_OBJECT" &&
                    <Link className="ca-dark-link small ml-1" to={this.props.triggerRedirectUrl} title={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}>
                      <Image src={triggerIcon} alt={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}/>
                    </Link>
                  }
                </div>
              </div>
            </footer>
          }
        </Card.Body>
        {this.props.isAddArtwork &&
          <Card.Footer>
            <div className='d-flex align-items-center justify-content-between mt-2'>
              <div className="ca-check-container" onClick={this.handleChange}>
                <input
                  id={this.props.id}
                  checked={this.state.checked}
                  type="checkbox"
                  className="d-flex align-items-center"
                  onChange={this.handleChange}
                />
                <span className="ca-check-label">{translatedMessage("ARTWORK.SELECT")}</span>
                <span className="ca-checkmark"></span>
              </div>
              <div className="more-info-button" onClick={() => this.props.onClick(this.props.id)}>
                {translatedMessage("GENERAL.MORE_INFO")}
              </div>
            </div>
          </Card.Footer>
        }
      </Card>
    )
  }

}

export default EntityManageArtworkCardComponent;