import React from 'react';

import ArtworkViewComponent from '../../components/shared/Artwork/ArtworkViewComponent';

class PortfolioArtworkViewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount = () => {}

    render() {
        return (
            <ArtworkViewComponent
              artworkId={this.props.match.params.id}
              canView={true}
              isAuthor={true}
              backLink={`/artworks`}
              editLink={`/portfolio-artwork-edit/${this.props.match.params.id}`}
              history={this.props.history}
            />
          )        
    }
}

export default PortfolioArtworkViewPage