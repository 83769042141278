import React from "react";

import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom'

import { Image } from 'react-bootstrap';
import editIcon from '../../../../components/shared/Resources/icons/edit.svg';

import { translatedMessage } from '../../../../services/language.service';

import './TableComponent.css';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      pageLength: 10,
      itemsPerPage: 10,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: translatedMessage("TABLE.PAGE"),
      ofText: translatedMessage("TABLE.OF"),
      rowsText: translatedMessage("TABLE.PAGE"),
    };
  }

  render() {
    const data = this.state.data;
    return (
      <div className="ca-table-arPortal" style={{ marginBottom: "100px" }}>
        <ReactTable
          data={data}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBotton={true}
          showPageSizeOptions={false}
          pageSize={this.state.itemsPerPage}
          multiSort={false}
          sortable={true}
          resizable={true}
          filterable={true}
          defaultPageSize={this.state.itemsPerPage}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          minRows={1}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
          }}
          columns={[
            {
              Header: translatedMessage("GENERAL.NAME"),
              accessor: "name",
            },
            {
              Header: translatedMessage("ARTWORK.ARTWORKS"),
              accessor: "orderNumber",
              Cell: row => {
                return (
                  <div>
                    <b>{translatedMessage("AR_PORTAL.AR_ART_NO")}: </b>{row.original.nrArArt}
                    <br />
                    <b>{translatedMessage("AR_PORTAL.SCULPTURE_NO")}: </b>{row.original.nrSculpture}
                  </div>
                )
              },
              sortable: false,
              filterable: false
            },
            {
              Cell: row => {
                return (
                  <Link className="ca-dark-link small" to={`/arPortalEdit/${row.original.id}`} title={translatedMessage("EXHIBITION.EDIT")}>
                    <Image src={editIcon} alt={translatedMessage("EXHIBITION.EDIT")}/>
                  </Link>
                )
              },
              sortable: false,
              filterable: false
            },
          ]}
        />
      </div>
    );
  }
}

export default TableComponent
