import React from 'react';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import MarkerClusterer from "@googlemaps/markerclustererplus";

import { Row, Col } from 'react-bootstrap';

import MapService from "../../services/map.service";
import ApiService from '../../services/api.service';
import { hasPermission } from '../../services/api.service';
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoaded: false,
      mapProps: null,
      map: null,
      markers: [],
      fences: [],
      canViewMap: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      let userMembership = ApiService.getCurrentUser().activeUserMembership ? ApiService.getCurrentUser().activeUserMembership : null;
      let membership = userMembership ? userMembership.membership : 'Member';
      let canViewMap = false;

      if(membership.name.toLowerCase() !== 'member'){
          canViewMap = true;
      }

      this.setState({
        canViewMap: hasPermission("USER_LIST") || canViewMap
      }, this.getData())
    });
  }

  getData = () => {
    MapService.getMapLocations().then(result => {
      this.setState({
        data: result,
        isLoaded: true
      })
    })
  }

  onMapReady = (props, map) => {
    var initialCenter = MapService.getInitialCenter();

    this.setState({
      mapProps: props,
      map: map
    })

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        map.setCenter(pos);
      }, () => {
        //Geolocation service failed
        map.setCenter(initialCenter);
      });
    } else {
      // Browser doesn't support Geolocation
      map.setCenter(initialCenter);
    }

    this.setMarkers(props, map);
  }

  setMarkers = (props, map) => {
    const locations = this.state.data;
    var markers = [];
    var fences = [];


    locations.forEach((element, index) => {
      const position = { lat: element.locationLat, lng: element.locationLng };

      const icon = {
        url: `${ApiService.getBaseUrl()}/storage/file/${element.pinFile.uuid}`,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height),
      }

      var marker = new props.google.maps.Marker({
        position: position,
        map: map,
        title: element.name,
        icon: icon
      });

      markers.push(marker);
      
     
      if (element.hasGeofence && element.geofence) {
        let polygon = MapService.getInitFencePolygon(props, map, false, false, false);
        let geofenceCoordinates = JSON.parse(element.geofence)
        polygon.setPath(geofenceCoordinates)
        polygon.setVisible(true)

        fences.push(polygon);
      }
    });

    this.refresh(map, markers)

    map.addListener('zoom_changed', (e) => {
      this.refresh(map, markers);
    });

    this.setState({
      markers: markers,
      fences: fences
    })
  }

  refresh = (map, markers) => {
    let fences = this.state.fences;    
    if (map.getZoom() >= 14) {
      this.state.fences.map(element => {
        element.setVisible(true);
        return element;
      })
    } else {
      this.state.fences.map(element => {
        element.setVisible(false);
        return element;
      })
    }

    new MarkerClusterer(map, markers,
      {
        imagePath: "/map/markerclusterer/",
        ignoreHidden: true,
        minimumClusterSize: 2,
        maxZoom: 13,
      }
    );

    this.setState({
      fences: fences
    })
  }

  render() {
    if (!this.props.google || !this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canViewMap) {
      return (
        <div>
          <Row className="mb-0">
            <Col className="col-12 ca-map-full-page pl-0 pr-0">
              <Map style={{}} google={this.props.google} zoom={12} initialCenter={MapService.getInitialCenter()} streetViewControl={false}
                onReady={this.onMapReady}>
              </Map>
            </Col>
          </Row>
        </div>
      );
    }  else {
      return (
          <ErrorComponent errorMessage={"USER.SECURITY.MISSING_RIGHTS"} history={this.props.history} />
      )
    }
  }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(MapPage);