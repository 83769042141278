import React from 'react';

import CardComponent from '../EntityManageArtworkCardComponent/EntityManageArtworkCardComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import ArtworkViewModalComponent from '../Artwork/ArtworkViewModalComponent';

import { toast } from "react-toastify";

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import mapIcon from '../../../components/shared/Resources/icons/map.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../components/shared/Resources/icons/cancel.svg';
import artworkIcon from '../../../components/shared/Resources/icons/artwork.svg';
import parkIcon from '../../../components/shared/Resources/homepage/art_park.svg';

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';

import ParkService from '../../../services/park.service';

const ENTER_KEY = 13;

class ParkManageArtworkComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.parkId,
      park: {},
      searchInput: '',
      artworks: [],
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      artworkCategoryList: [],
      filteredArtworks: [],
      authors: [],
      existingArtworkList: [],
      isLoaded: false,
      isAddArtwork: false,
      artworkCanHaveLocation: false,
      isError: false,
      errorMessage: "",
      showArtworkModal: false,
      modalData: {
        art: {}
      },
    };

    this.addArtwork = this.addArtwork.bind(this);
  }

  componentDidMount = () => {
    // console.log("ParkManageArtworkComponent")
    if (this.props.canEdit || this.props.canViewList) {
      this.getArtworks();
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  getArtworks = () => {
    let parkId = parseInt(this.state.parkId);
    let artworkCategoryList = [];
    let artworks = [];
    let park = {};
    let promise = [];
    let artworkCanHaveLocation = false;

    let criteria = {};
    criteria.sortOrder = "ASC";
    criteria.sortField = "a.name";
    criteria.pageSize = -1;
    criteria.parkId = parkId
    criteria.entityName = ApiService.getEntityName().ARTWORK


    ParkService.getParkArtListForManage(criteria)
      .then(response => {
        artworks = response.artList;
        park = response.park
      })
      .then(() => {
        promise.push(ArtworkService.getArtworkCategoryList()
          .then(response => {
            artworkCategoryList = response;
            artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
          }))

        if (parkId) {
          promise.push(ApiService.getAuthenticatedInstance().get(`park/${parkId}/location/manage`)
            .then(response => {
              const location = response.data;
              if (location && location.locationLat !== 0 && location.locationLng !== 0) {
                artworkCanHaveLocation = true;
              }
            })
          )
        }

        Promise.all(promise).then(() => {
          this.setState({
            artworks: artworks,
            filteredArtworks: artworks,
            artworkCategoryList: artworkCategoryList,
            park: park,
            artworkCanHaveLocation: artworkCanHaveLocation,
            isLoaded: true
          }, this.filter)
        })
      })
      .catch(err => {
        console.log(err)
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.artwork.name.toLowerCase().includes(searchInput.toLowerCase())
        || elem.author.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.artwork.category.id.toString() === categoryItem.id.toString());
    }

    this.setState({
      filteredArtworks: result
    })
  }

  async addArtwork() {
    let artworks = [];
    if (this.props.isAdmin) {
      artworks = await ArtworkService.getArtworksByCriteria(false, ArtworkService.artworkStatusEnum().PUBLISHED)
        .then(response => { return response });
    }
    
    var art = []
    var existingArtworkList = [];
    artworks.map((element, index) => {
      const existingArt = this.state.artworks.filter(item => item.artwork.id === element.id);
      if (existingArt.length > 0) {
        existingArtworkList.push(existingArt[0].artwork.id.toString());
      }
      art.push({
        "artwork": element,
        "park": this.state.park,
        "author": element.author,
        "imageURL": element.image,
        "existingArt": existingArt.length > 0 ? true : false,
        "id": element.id
      })
      return art;
    })

    this.setState({
      artworks: art,
      filteredArtworks: art,
      isAddArtwork: true,
      existingArtworkList: existingArtworkList
    }, this.filter);
  }

  handleCheckChange = (id, isChecked) => {
    let existingArtworkList = this.state.existingArtworkList;
    if (isChecked) {
      existingArtworkList.push(id)
    } else {
      let index = existingArtworkList.indexOf(id);
      if (index !== -1) {
        existingArtworkList.splice(index, 1);
      }
    }

    this.setState({
      existingArtworkList: existingArtworkList
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    ParkService.addArtToPark(this.state.parkId, ApiService.getEntityName().ARTWORK, this.state.existingArtworkList)
      .then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          isAddArtwork: false,
        }, this.getArtworks)
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);

        let errorMessage = "GENERAL.SAVE_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        toast.error(translatedMessage(errorMessage));
        this.setState({
          isAddArtwork: false,
        }, this.getArtworks)
      })
  }

  cancelAdd = () => {
    this.setState({
      isAddArtwork: false,
    }, this.getArtworks)
  }

  onShowModal = (artId) => {
    ApiService.getAuthenticatedInstance().get(`/artwork/artworkDetail/${artId}`)
      .then(response => {
        this.setState({
          modalData: response.data.artwork,
          showArtworkModal: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }

        toast.error(translatedMessage(errorMessage));
      });
  }

  onHideModal = () => {
    this.setState({
      showArtworkModal: false
    })
  }

  render() {
    const artworks = this.state.filteredArtworks;
    const backUrl = this.state.park?.parentPark?.id ? `/${this.props.entityUrl}/${this.state.park.parentPark.id}/view` : `/${this.props.entityUrl}`

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={parkIcon} className="ca-page-title-icon" alt={translatedMessage("PARK.PARK")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.park.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("GENERAL.ARTWORKS.MANAGE")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={this.addArtwork} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={artworkIcon} alt={translatedMessage("ARTWORK.MANAGE")} title={translatedMessage("ARTWORK.MANAGE")} />
              </Button>
              <Button type="submit" form="artworkForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button onClick={this.cancelAdd} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
              </Button>
              <Button onClick={() => this.props.history.push(this.props.mapUrl)} className="ca-dark-link ca-button-icon medium mr-1">
                <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} title={translatedMessage("MAP.VIEW_ON_MAP")} />
              </Button>
              <Button onClick={() => this.props.history.push(backUrl)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} onChange={this.handleSearchInput} className="ca-form-control-search"
                type="text" placeholder="&#xF002;" />
            </Col>

            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>            
          </Row>

          <Form id="artworkForm" className="ca-form" onSubmit={this.handleSubmit}>
            <div className="card-columns">
              {artworks.map((element, index) => {
                return (
                  <CardComponent
                    key={element.id}
                    id={element.artwork.id}
                    entityArtworkId={element.id}
                    cardImage={element.imageURL}
                    cardTitle={element.artwork.name}
                    cardArtworkAuthor={element.author}
                    cardCategory={element.artwork.category.code}
                    cardId={element.artwork.id}
                    cardLink={`/artwork/${element.artwork.id}`}
                    isAddArtwork={this.state.isAddArtwork}
                    existingArt={!this.state.isAddArtwork ? true : element.existingArt}
                    onCheck={this.handleCheckChange}
                    artworkCanHaveLocation={this.state.artworkCanHaveLocation}
                    locationRedirectUrl={`/parkAdmin/${this.state.parkId}/artwork/${element.id}/location`}
                    triggerRedirectUrl={`${this.props.triggerUrl}/${element.id}`}
                    onClick={this.onShowModal}
                  />
                )
              })}
            </div>
          </Form>

          <ArtworkViewModalComponent
            show={this.state.showArtworkModal}
            onHide={this.onHideModal}
            art={this.state.modalData}
          />
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ParkManageArtworkComponent;