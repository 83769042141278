import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap/';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';

import addIcon from '../../../components/shared/Resources/icons/add.svg';

import { toast } from "react-toastify";
import moment from 'moment';

import Carousel from "../Carousel/MediaCarouselComponent";
import AddFileModalComponent from '../AddFileModalComponent/AddFileModalComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import ChangeYoutubeIdModalComponent from '../ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'

class EntityMediaPageComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      entity: {},
      storageFiles: [],
      images: [],
      videos: [],
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      dropzoneEnabled: true,
      isLoaded: false,
      showAddFileModal: false,
      showVideoTypeSelectModal: false,
      isError: false,
      errorMessage: "",
      fileModalData: {},
      displayObject: false,
      modelEnabled: false,
      showEditYoutubeModal: false
    };
  }

  async componentDidMount() {
    if (this.props.canEdit) {
      this.getData();
    } else {
      this.setState({
        isError: true,
        errorMessage: translatedMessage("USER.SECURITY.MISSING_RIGHTS"),
        isLoaded: true
      })
    }
  }

  async getData() {
    let entity = this.props.entity;
    let storageItemId = this.props.entity.storageItemId
    let images = [];
    let videos = [];

    if (storageItemId) {
      entity.storageItem = {
        id: storageItemId
      }

      const storageFiles = await FileService.getStorageItemFiles(entity.id, this.props.entityName)
        .then(response => { return response })

      if(this.props.useVideoUrl) {
        videos = await FileService.getVideoUrls(entity.id, this.props.entityName)
        .then(response => { return response })        
      }

      if (storageFiles.length > 0) {
        storageFiles.forEach((item) => {
          if (item.usage === FileService.getFileUsage().THUMBNAIL) {
            let mockImage = {};
            mockImage.file = item;
            mockImage.original = item.imageURL;
            mockImage.thumbnail = item.imageURL;
            mockImage.type = FileService.getResourceType().IMAGE;
            mockImage.storageItemFileId = item.storageItemFileId;
            mockImage.youtubeId = item.youtubeId;

            images.push(mockImage);
          } else if (item.usage === FileService.getFileUsage().VIDEO) {
            let mockImage = {};
            mockImage.file = item;
            mockImage.type = FileService.getResourceType().VIDEO;
            mockImage.storageItemFileId = item.storageItemFileId;
            mockImage.youtubeId = item.youtubeId;

            videos.push(mockImage);
          }
        });
      }
    }

    this.setState({
      entity: entity,
      images: images,
      videos: videos,
      isLoaded: true,
    });
  }

  onShowAddFile = (event) => {
    let modalData = {};

    if (event.currentTarget.id === 'btnPresImg') {
      modalData = {
        modalTitle: translatedMessage("UPLOAD.IMAGES"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.entity,
        storageitemlinked: true
      }
    } else if (event.currentTarget.id === 'btnVideo') {
      modalData = {
        modalTitle: translatedMessage("UPLOAD.VIDEOS"),
        fileUsage: FileService.getFileUsage().VIDEO,
        fileType: FileService.getResourceType().VIDEO,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.VIDEO",
        maxSize: 100,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_FILE",
        entity: this.state.entity,
        storageitemlinked: true
      }
    }

    this.setState({
      fileModalData: modalData,
      showAddFileModal: true
    })
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getData();
    }
    this.setState({
      showAddFileModal: false,
    })
  }

  handleMediaListChange = () => {
    this.getData();
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      ApiService.getAuthenticatedInstance().post(`/entityVideoUrls`, {
        entity: {
          entityId: this.props.entity.id,
          entityName: this.props.entityName
        },
        videoId: videoId,
        type: FileService.getVideoUrlType().YOUTUBE,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            showEditYoutubeModal: false
          }, this.handleMediaListChange)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="mb-1">
              <Col className="col-12 col-lg-6 ca-artwork-column-left">
                <div className="ca-paragraph-title w-100 mb-3">
                  <span>{translatedMessage("PROJECT.IMAGES")}</span>

                  <span className="d-inline-block">
                    <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                      <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                    </Button>
                  </span>
                </div>
                {this.state.images.length === 0 &&
                  translatedMessage("IMAGE.FIRST_UPLOAD")
                }
                {this.state.images.length > 0 &&
                  <Carousel
                    id="presImg"
                    items={this.state.images}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class="mb-5"
                    hasToolbar={true}
                    toolbarDelete={true}
                    toolbarFullScreen={true}
                    canDeleteLast={true}
                    onChange={this.handleMediaListChange}
                  />
                }
              </Col>
              <Col className="col-12 col-lg-6 ca-artwork-column-right">
                <div className="ca-paragraph-title w-100 mb-3">
                  <span>
                    {translatedMessage("PROJECT.VIDEOS")}
                  </span>

                  <span className="d-inline-block">
                    <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" 
                            onClick={this.props.useVideoUrl ? this.handleYoutubeIdEdit : this.onShowAddFile}
                            title={this.props.useVideoUrl ? translatedMessage("VIDEO.ADD_YOUTUBE_ID") : translatedMessage("GENERAL.ADD_VIDEO")}>
                      <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")}/>
                    </Button>
                  </span>
                </div>
                {this.state.videos.length === 0 &&
                  translatedMessage("VIDEO.FIRST_UPLOAD")
                }
                {this.state.videos.length > 0 &&
                  <Carousel
                    id="video"
                    items={this.state.videos}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class="mb-5"
                    hasToolbar={true}
                    toolbarDelete={true}
                    toolbarYoutubeId={true}
                    videoUrlOnly={this.props.useVideoUrl}
                    onChange={this.handleMediaListChange}
                  />
                }
              </Col>
            </Row>

            <AddFileModalComponent
              show={this.state.showAddFileModal}
              onHide={this.onHideAddFile}
              data={this.state.fileModalData}
            />

            <ChangeYoutubeIdModalComponent
              show={this.state.showEditYoutubeModal}
              onHide={this.onHideYoutubeModal}
            />
          </div>
        )
      }
    }
  }

}

export default EntityMediaPageComponent;