import React from 'react'
import ApiService from '../../../services/api.service'
import UserService from '../../../services/user.service'

import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

import './TableComponent.css';
import Dropdown from 'react-bootstrap/Dropdown'

import { translatedMessage } from '../../../services/language.service';


class TableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            searchValue: this.props.searchValue,
            filters: [],
            sorted: [],
            paging: {
                itemsPerPage: 10,
            },
            totalPages: -1,
            loading: false,
            lastSortId: null,
            lastSortDirection: null,
            signature: null,
            tableReloaded: this.props.tableReloaded,
            nextIcon: '\uf054',
            prevIcon: '\uf053',
            pageText: translatedMessage("TABLE.PAGE"),
            ofText: translatedMessage("TABLE.OF"),
            rowsText: translatedMessage("TABLE.PAGE")
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

    }

    componentDidMount() {
        this.fetchData({
            sorted: [],
            pageSize: this.state.paging.itemsPerPage,
            page: 0,
        }, {})
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.tableReloaded > this.state.tableReloaded) {

            var filterArray = [];

            for (let key in nextProps.filters) {
                if (nextProps.filters.hasOwnProperty(key)) {
                    filterArray.push({ property: key, value: nextProps.filters[key] });
                }
            }

            this.setState({ filters: filterArray, searchValue: nextProps.searchValue, tableReloaded: nextProps.tableReloaded },
                () => {
                    const fetchState = this.state.lastFetch;
                    fetchState.reload = true;
                    this.fetchData(fetchState, {});
                    //console.log('Received filters => ', this.state.filters);
                });
        }
    }

    fetchData(state, instance) {

        const localSignature = JSON.stringify({
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
        });

        if (localSignature === this.state.signature && !state.reload) {
            return;
        }

        this.setState({ signature: localSignature, lastFetch: state, loading: true });

        const params = {
            size: state.pageSize,
            page: state.page,
        }

        if (this.state.filters.length > 0) {
            this.state.filters.forEach((filter) => {
                if (typeof filter.value === "string") {
                    if (filter.value.indexOf("ANY") < 0) {
                        params[filter.property.replace(/_/g, ".")] = filter.value;
                    }
                } else {
                    params[filter.property.replace(/_/g, ".")] = filter.value;
                }

            })
        }

        if (this.state.searchValue) {
            params.username = this.state.searchValue;
        }

        if (state.sorted.length > 0) {
            let realLastSortId = state.sorted[0].id;
            if (realLastSortId === 'fullName') {
                realLastSortId = 'profile_firstName';
            }

            if (realLastSortId === 'type') {
                realLastSortId = 'membership';
            }

            params.sort = `${realLastSortId},${state.sorted[0].desc ? 'desc' : 'asc'}`;
        }

        let url = '/userDetails';

        ApiService.getAuthenticatedInstance().get(url, {
            params
        }).then(result => {
            let usersArray = [];
            result['data']['_embedded']['userDetails'].forEach(element => {
                let userDetail = {};
                let userDetailArray = element['_links']['self']['href'].split("/");

                userDetail['id'] = userDetailArray[userDetailArray.length - 1];
                userDetail['fullName'] = element['firstName'] + ' ' + element['lastName'];
                userDetail['username'] = element['username'];
                userDetail['status'] = element['status'];
                userDetail['type'] = UserService.getType(element);
                userDetail['membership'] = element['membership'];

                usersArray.push(userDetail);
            });
            this.setState({ data: usersArray, loading: false, totalPages: result.data.page.totalPages })
        })
    }

    handleSearch(searchValue) {
        if (searchValue.length >= 3) {
            this.setState({ searchValue })
            let currentState;
            try {
                currentState = JSON.parse(this.state.signature);
            } catch (e) {
                currentState = {}
            }
            this.fetchData(currentState, {});
        }
    }

    render() {
        // console.log(this.state.data)
        return (
            <div>
                <ReactTable
                    columns={[{
                        Header: 'ID',
                        accessor: 'id',
                    },
                    {
                        Header: translatedMessage("TABLE.HEADER.NAME"),
                        accessor: 'fullName',
                        sortable: false,
                    },
                    {
                        Header: translatedMessage("TABLE.HEADER.USERNAME"),
                        accessor: 'username',
                    },
                    {
                        Header: translatedMessage("TABLE.HEADER.TYPE"),
                        accessor: 'type',
                        sortable: false,
                    },
                    {
                        Header: translatedMessage("MEMBERSHIP.MEMBERSHIP"),
                        accessor: 'membership',
                        sortable: false,
                    },
                    {
                        Header: translatedMessage("TABLE.HEADER.STATUS"),
                        Cell: row => {
                            return (
                                translatedMessage("USER.STATUS." + row.original.status)
                            )
                        }
                    },
                    {
                        Header: '',
                        Cell: row => {
                            return (

                                <div className="flex-arrange">
                                    <Dropdown className="table-caret-absolute">
                                        <Dropdown.Toggle className="table-dropdown-button">
                                            <span style={{
                                                fontFamily: "FontAwesome"
                                            }}>
                                                &#xF107;
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="table-dropdown-menu">
                                            <Dropdown.Item onClick={() => this.props.showModal(row.original.id)}>{translatedMessage("TABLE.VIEW")}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => this.props.editModal(row.original.id)}>{translatedMessage("TABLE.EDIT")}</Dropdown.Item>
                                            {/* <Dropdown.Item>{translatedMessage("USER.RESET_PASSWORD")}</Dropdown.Item> */}
                                            <Dropdown.Item onClick={() => this.props.changeStatusModal(row.original)}>{translatedMessage("TABLE.CHANGE_STATUS")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            )
                        }
                    }
                    ]}
                    manual
                    data={this.state.data}
                    pages={this.state.totalPages}
                    minRows={0}
                    defaultPageSize={this.state.paging.itemsPerPage}
                    showPageJump={false}
                    loading={this.state.loading}
                    onFetchData={this.fetchData}
                    sorted={this.state.sorted}
                    filtered={this.state.filters}
                    onSortedChange={(newSort, column) => {
                        this.setState({ sorted: newSort });
                    }}
                    showPageSizeOptions={true}
                    nextText={this.state.nextIcon}
                    previousText={this.state.prevIcon}
                    pageText={this.state.pageText}
                    ofText={this.state.ofText}
                    rowsText={this.state.rowsText}
                />
            </div>
        )
    }

}

export default TableComponent