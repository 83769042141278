import React from "react";
import Modal from "react-bootstrap/Modal";

import { Button, Form, Row, Col } from "react-bootstrap/";

import { translatedMessage } from "../../../services/language.service";

import DropzoneComponent from "../DropzoneComponent/DropzoneComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AddFileModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        modalTitle: "",
        fileUsage: "",
        fileType: "",
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "",
        maxSize: 0,
        maxSizeDesc: "",
        multipleFiles: false,
        multipleFilesDesc: "",
        entity: null,
        storageitemlinked: false,
        dropzoneEnabled: true,
      },
      changeFile: {
        index: 0,
      },
      storageId: null,
      widthCm: 0,
      heightCm: 0,
      showDropzone: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: prevState.data };
    }
    else return null;
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          widthCm: 0,
          heightCm: 0,
          showDropzone: false
        }, this.props.onHide(true))
      }, 500)
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
      });
    }
  }

  handleInputChange = (event) => {
    if (!event.target) {
      return;
    }

    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    }, this.showHideDropzone);    
  }

  showHideDropzone = () => {
    let showDropzone = false;
    if(this.state.widthCm && this.state.widthCm > 0 && this.state.heightCm && this.state.heightCm > 0){
      showDropzone = true
    }

    this.setState({
      showDropzone: showDropzone
    })
  }

  render() {
    return (
      <Modal
        {...this.props}
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {this.state.data.modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="font-bold mb-1">
            {translatedMessage("ARTWORK.PRINT_FILE_SIZE.DESCRIPTION")}
          </div>
          <Row className="mb-0">
            <Col className="col-6">
              <Form.Group>
                <Form.Label>{translatedMessage("ARTWORK.WIDTH")} *</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  placeholder={translatedMessage("ARTWORK.WIDTH_PLACEHOLDER")}
                  name="widthCm"
                  defaultValue={this.state.widthCm}
                  required
                  isInvalid={this.state.widthCm !== null && this.state.widthCm.length === 0}
                  onChange={this.handleInputChange}
                />
                <Form.Control.Feedback type="invalid">{translatedMessage("ARTWORK.WIDTH.REQUIRED")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group>
                <Form.Label>{translatedMessage("ARTWORK.HEIGHT")} *</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  placeholder={translatedMessage("ARTWORK.HEIGHT_PLACEHOLDER")}
                  name="heightCm"
                  defaultValue={this.state.heightCm}
                  required
                  isInvalid={this.state.heightCm !== null && this.state.heightCm.length === 0}
                  onChange={this.handleInputChange}
                />
                <Form.Control.Feedback type="invalid">{translatedMessage("ARTWORK.HEIGHT.REQUIRED")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div style={!this.state.showDropzone ? { display: "none" } : { display: "block" }}>
            <DropzoneComponent
              componentId="printFile"
              fileUsage={this.state.data.fileUsage}
              fileType={this.state.data.fileType}
              deletePrevious={this.state.data.deletePrevious}
              deletedImage={this.state.data.deletedImage}
              deletePreviousDesc={this.state.data.deletePreviousDesc}
              fileFormatsDesc={this.state.data.fileFormatsDesc}
              maxSize={this.state.data.maxSize}
              maxSizeDesc={this.state.data.maxSizeDesc}
              multipleFiles={this.state.data.multipleFiles}
              multipleFilesDesc={this.state.data.multipleFilesDesc}
              entity={this.state.data.entity}
              storageitemlinked={this.state.data.storageitemlinked}
              onupload={this.onUploadFile}
              widthCm={this.state.widthCm}
              heightCm={this.state.heightCm}
            />
          </div>
          <div style={this.state.showDropzone ? { display: "none" } : { display: "block" }}>
            <div className="font-bold warning-color">
              <FontAwesomeIcon icon={["fas", "exclamation-circle"]} className="mr-1"/>
              {translatedMessage("ARTWORK.PRINT_FILE_UPLOAD.DESCRIPTION")}
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button" form="editmodal-form" onClick={this.props.onHide} type="submit">{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddFileModalComponent;