import React from 'react';

import myPortfolio from '../../../../components/shared/Resources/homepage/myPortfolio.svg';
import back from '../../../../components/shared/Resources/left-arrow.svg';
import calogo from '../../../../components/shared/Resources/logo-dark-small-with-padding.png'
import createArt from '../../../../components/shared/Resources/homepage/createArt.svg';

import CardComponent from "../../../../components/shared/Artwork/ArtworkCardEditComponent";

import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from '../../../../services/api.service';
import ArtworkService from '../../../../services/artwork.service';
import { translatedMessage } from '../../../../services/language.service';

const ENTER_KEY = 13;

class ArtworksPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      artworks: [],
      statusList: [],
      categoryList: [],
      selectedStatus: { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" },
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      filteredResults: [],
      searchInput: '',
      isLoaded: false,
      company: null
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        let company = response.data.company;
        if (company && company.status.name === 'ACTIVE') {
          this.setState({
            company: company,
          }, this.getArtworks)
        } else {
          this.setState({
            isError: true,
            errorMessage: "COMPANY.NOT_APPROVED",
            isLoaded: true
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  getArtworks = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";
    let promise = [];
    let artworks = [];
    let statusList = [];
    let categoryList = [];

    promise.push(
      ApiService.getAuthenticatedInstance().get(`company/${this.state.companyId}/portfolio`)
        .then(response => {
          artworks = response.data;
          artworks.map((elem, i) => {
            elem.image = elem.thumbUuid ? ApiService.getBaseUrl() + '/storage/file/' + elem.thumbUuid : calogo;
            if (elem.status.name === ArtworkService.artworkStatusEnum().PUBLISHED || elem.status.name === ArtworkService.artworkStatusEnum().UNPUBLISHED) {
              if (elem.artworkId) {
                elem.viewLink = `/my-company/${this.state.company.id}/portfolio-artwork/${elem.artworkId}`;
                elem.editLink = `/my-company/${this.state.company.id}/portfolio-artwork-edit/${elem.artworkId}`;
              } else if (elem.virtualGalleryId) {
                elem.viewLink = `/my-company/${this.state.company.id}/portfolio-virtual-gallery/${elem.virtualGalleryId}`;
                elem.editLink = `/my-company/${this.state.company.id}/portfolio-virtual-gallery-edit/${elem.virtualGalleryId}`;
                elem.virtualGalleryArtLink = `/my-company/${this.state.company.id}/portfolio-virtual-gallery/${elem.virtualGalleryId}/artworks`;
              }
            } else {
              if (elem.category === ArtworkService.artworkCategoryEnum().AR_ART) {
                elem.viewLink = `/project/publish/ar-art/${elem.projectId}`;
              } else if (elem.category === ArtworkService.artworkCategoryEnum().OBJECT) {
                elem.viewLink = `/project/publish/digital-sculpture/${elem.projectId}`;
              } else if (elem.category === ArtworkService.artworkCategoryEnum().AR_PORTAL) {
                elem.viewLink = `/project/publish/ar-portal/${elem.projectId}`;
              } else if (elem.category === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE) {
                elem.viewLink = `/project/publish/digital-picture/${elem.projectId}`;
              }
            }

            return elem;
          });
        })
        .catch(err => {
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }

          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })
    )

    promise.push(
      ArtworkService.getArtworkStatusList()
        .then(response => {
          statusList = response;
          // statusList = statusList.filter(item => item.name === 'PUBLISHED' || item.name === 'UNPUBLISHED');
          statusList.splice(0, 0, { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" })
        })
    )

    promise.push(
      ArtworkService.getArtworkCategoryList()
        .then(response => {
          categoryList = response;
          categoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })

        })
    )

    Promise.all(promise).then(() => {
      this.setState({
        artworks: artworks,
        filteredResults: artworks,
        statusList: statusList,
        categoryList: categoryList,
        isLoaded: true
      })
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnStatusSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
    this.setState({
      selectedStatus: selectedItem[0]
    }, this.filter)
  }

  handleOnCategorySelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.categoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let statusItem = this.state.selectedStatus.name;
    let categoryItem = this.state.selectedCategory;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.category === categoryItem.code);
    }

    if (statusItem !== 'ANY') {
      result = result.filter(elem => elem.status.name === statusItem);
    }

    this.setState({
      filteredResults: result
    })
  }

  render() {
    const artworks = this.state.filteredResults;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={myPortfolio} className="ca-page-title-icon" alt={translatedMessage("ARTWORK.PORTFOLIO")} />
              <span className="ca-page-title">{translatedMessage("ARTWORK.PORTFOLIO")}</span>
            </div>
            <div>
              <Link to={`/my-company/${this.state.company.id}/project/create`} className="ca-dark-link">
                <Image className="ca-page-title-icon mr-0" src={createArt} alt={translatedMessage("CREATE_ART")}
                  title={translatedMessage("CREATE_ART")} />
              </Link>

              <Link to={`/my-company/${this.state.company.id}/home`} className="ca-dark-link">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.categoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedStatus.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.statusList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="card-columns">
            {artworks.map((element, index) => {
              return (
                <CardComponent
                  key={index}
                  cardImage={element.image}
                  cardTitle={element.name}
                  cardCategory={translatedMessage("PROJECT.CATEGORY." + element.category)}
                  cardType={element.virtualGalleryType ? translatedMessage(element.virtualGalleryType) : null}
                  cardId={element.id}
                  cardLink={element.viewLink}
                  cardEditLink={element.editLink}
                  cardStatus={element.status}
                  isUnpublished={element.status.name === "UNPUBLISHED"}
                  hasPortfolioDisplayCheck={false}
                  hasSpaceDisplayCheck={false}
                  canEdit={true}
                  canManageLocation={false}
                  canChangeStatus={false}
                  virtualGalleryArtLink={element.virtualGalleryArtLink ? element.virtualGalleryArtLink : null}
                />
              )
            })}
          </div>

        </div >
      )
    }
  }

}

export default ArtworksPage;