import React from 'react';

import { Row, Col, Button, Image, OverlayTrigger, Tooltip, Form, Dropdown } from 'react-bootstrap/'

import saveIcon from '../../components/shared/Resources/icons/save.svg';
import backIcon from '../../components/shared/Resources/icons/back.svg';

import ApiService from '../../services/api.service';
import FileService from "../../services/file.service";
import { translatedMessage } from '../../services/language.service';
import { hasPermission } from '../../services/api.service';

import DropzoneComponent from "../../components/shared/DropzoneComponent/DropzoneComponent";
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import DisplayVideoComponent from '../../components/shared/DisplayVideoComponent/DisplayVideoComponent';
import ArtworkService from '../../services/artwork.service';

import { toast } from "react-toastify";
import moment from 'moment';

class ArExperienceFilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arExperience: {},
      arFile: {},
      arFile1: {},
      arImage: {},
      arImageUrl: '',
      resourceTypeList: [],
      isLoaded: false,
      dropzoneEnabled: true,
      displayPrezImg: false,
      canEdit: false,
      isError: false
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      const canEdit = hasPermission("AR_EXPERIENCE_EDIT") || hasPermission("AR_EXPERIENCE_CREATE");

      this.setState({
        canEdit: canEdit
      }, this.getData)
    });
  }

  getData() {
    if (this.state.canEdit) {
      ApiService.getAuthenticatedInstance().get(`arExperience/arExperience/${this.props.match.params.id}`)
        .then(response => {
          this.setState({
            arExperience: response.data
          }, async () => {
            if (this.state.arExperience) {
              let resourceTypeList = await FileService.getContentResourceTypeList()
                .then(response => {
                  let objectResourceTypes = response.filter(item => (item.name === FileService.getResourceType().OBJECT || item.name === FileService.getResourceType().UNITY_SCENE))
                  return objectResourceTypes
                })

              ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.arExperience.id}/${ApiService.getEntityName().AR_EXPERIENCE}`)
                .then(response => {
                  const arFiles = response.data.fileList.filter(file => file.usage === FileService.getFileUsage().AR);
                  const imageFile = response.data.fileList.filter(file => file.usage === FileService.getFileUsage().THUMBNAIL);

                  let arFile = null;
                  let arFile1 = null;

                  if (this.state.arExperience.category.name === ArtworkService.artworkCategoryEnum().AR_ART) {
                    arFile = arFiles[0];
                  } else {
                    if (arFiles.length === 1) {
                      if (!arFiles[0].mobilePlatform) {
                        arFile = arFiles[0];
                      } else if (arFiles[0].mobilePlatform === 'IOS') {
                        arFile = arFiles[0];
                      } else if (arFiles[0].mobilePlatform === 'Android') {
                        arFile1 = arFiles[0];
                      }
                    } else if (arFiles.length > 1) {
                      arFile = arFiles.filter(item => item.mobilePlatform === 'IOS')[0];
                      arFile1 = arFiles.filter(item => item.mobilePlatform === 'ANDROID')[0];
                    }
                  }

                  this.setState({
                    arFile: arFile,
                    arFile1: arFile1,
                    arImage: imageFile.length > 0 ? imageFile[0] : {},
                    arImageUrl: imageFile.length > 0 ? ApiService.getFileByUuid(imageFile[0].uuid) + '?' + Date.now() : '',
                    displayPrezImg: imageFile.length > 0 ? true : false,
                    resourceTypeList: resourceTypeList,
                    isLoaded: true
                  });
                });
            } else {
              this.setState({
                isError: true,
                errorMessage: "AR_EXPERIENCE.GENERAL_ERROR",
                isLoaded: true
              })
            }
          })
        })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    let arFile = this.state.arFile;
    let arFile1 = this.state.arFile1;

    if (uploadedFile) {
      if (!uploadedFile.mobilePlatform) {
        arFile = uploadedFile;
        arFile1 = null;
      } else if (uploadedFile.mobilePlatform === 'IOS') {
        arFile = uploadedFile;
      } else if (uploadedFile.mobilePlatform === 'ANDROID') {
        arFile1 = uploadedFile;
      }
    }

    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          arFile: arFile,
          arFile1: arFile1
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        arFile: arFile,
        arFile1: arFile1
      });
    }
  }

  onUploadPrezFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          displayPrezImg: !this.state.displayPrezImg,
          arImage: uploadedFile,
          arImageUrl: ApiService.getFileByUuid(uploadedFile.uuid) + '?' + Date.now(),
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        arImage: uploadedFile,
      });
    }
  }

  changePrezImgDropzone = () => {
    this.setState({ displayPrezImg: !this.state.displayPrezImg })
  }

  handleOnTypeSelect = (eventKey) => {
    const selectedItem = this.state.resourceTypeList.filter(item => item.name === eventKey);

    this.setState({
      arFile: {
        ...this.state.arFile,
        resourceType: selectedItem[0],
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let youtubeId = ApiService.getYouTubeID(event.target.elements.formYoutubeId.value);

    if (youtubeId) {
      ApiService.getAuthenticatedInstance().patch(`/arExperiences/${this.state.arExperience.id}`, {
        experienceUrl: youtubeId,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            arExperience: {
              ...this.state.arExperience,
              experienceUrl: youtubeId
            }
          })
        })
        .catch(error => {
          console.log("An error has ocurred: " + error);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        });
    }

  }

  render() {
    let prez_img_class = this.state.displayPrezImg ? "ca-form-image-container ca-left" : "ca-form-image-container-hide";
    let prez_dropzone_class = !this.state.displayPrezImg ? "ca-dropzone-show ca-left fixed-width" : "ca-dropzone-hide";


    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        let arFile = this.state.arFile;
        let isArArt = this.state.arExperience.category.name === ArtworkService.artworkCategoryEnum().AR_ART;

        let experienceFileType = FileService.getResourceType().AR_VIDEO;
        let experiencefileFormatsDesc = "DROPZONE.FILE_TYPES.ADD_ON";

        // setup params for the first ar file upload
        let resourceType = arFile ? arFile.resourceType : null;
        let isGlb = false;
        if (!isArArt) {
          resourceType = resourceType ? resourceType : { label: 'CONTENT_RESOURCE_TYPE.OBJECT', name: 'OBJECT' };
          experienceFileType = FileService.getResourceType().OBJECT;
          experiencefileFormatsDesc = "DROPZONE.FILE_TYPES.OBJECT";
          isGlb = true;

          if (resourceType && resourceType.name === FileService.getResourceType().UNITY_SCENE) {
            experienceFileType = FileService.getResourceType().UNITY_SCENE;
            experiencefileFormatsDesc = "DROPZONE.FILE_TYPES.ARCHIVE";
            isGlb = false;
          }
        } else {
          resourceType = resourceType ? resourceType : { label: 'CONTENT_RESOURCE_TYPE.AR_VIDEO', name: 'AR_VIDEO' };
        }

        let presentationFileUsage = isArArt ? FileService.getFileUsage().AR_EXPERIENCE_ART : FileService.getFileUsage().THUMBNAIL;

        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-3 pr-2">
              <span className="ca-page-title">{translatedMessage("AR_EXPERIENCE") + " - " + translatedMessage("AR_EXPERIENCE.MANAGE_FILES")}</span>

              <div>
                {this.state.arExperience.streamedExperience &&
                  <Button className="ca-dark-link ca-button-icon medium mr-1" type="submit" variant="link" form="stream-url-form">
                    <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
                  </Button>
                }

                <Button className={"ca-dark-link ca-button-icon ".concat(this.state.dropzoneEnabled ? "" : "disabled")} variant="link"
                  onClick={() => this.props.history.push(`/arExperienceEdit/${this.props.match.params.id}`)}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("AR_EXPERIENCE.MANAGE_DETAILS")} />
                </Button>
              </div>
            </Row>

            <Row>
              {!this.state.arExperience.streamedExperience &&
                <Col className={"col-12 ".concat(isArArt || isGlb ? "col-lg-6" : "")}>
                  <div className="ca-publish-section-container">
                    <div className="ca-publish-section-container">
                      <div className="ca-publish-section-title">
                        {translatedMessage("AR_EXPERIENCE.UPLOAD_EXPERIENCE")}
                      </div>
                      <div className="ca-publish-section-subtitle">
                        {this.state.arFile
                          ? translatedMessage("ARTWORK.PUBLISH.ARTWORK_UPLOADED") + " | " + translatedMessage("PROJECT.ARTWORK_FILE_NAME") + ": " +
                          this.state.arFile.name + (this.state.arFile.mobilePlatform ? " - iOS" : "") +
                          (this.state.arFile1 ? " & " + this.state.arFile1.name + " - Android" : "")
                          : translatedMessage("PROJECT.UPLOAD_ARTWORK")}
                      </div>
                      {!isArArt &&
                        <Form.Group controlId="formType">
                          <Form.Label>
                            {translatedMessage("ART_CONTENT.RESOURCE.TYPE")}
                            <OverlayTrigger
                              key='type'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-type`} className="ca-tooltip text-left">
                                  <p>{translatedMessage("ART_CONTENT.RESOURCE.UNITY_SCENE.INFO")}</p>
                                  <p>{translatedMessage("ART_CONTENT.RESOURCE.OBJECT.INFO")}</p>
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Dropdown className={"ca-dropdown ".concat(isGlb ? "" : "col-12 col-lg-6 pl-0 pr-0")} onSelect={this.handleOnTypeSelect}>
                            <Dropdown.Toggle>
                              {translatedMessage(resourceType.label)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {this.state.resourceTypeList.map((element, index) => {
                                return (
                                  <Dropdown.Item key={index} eventKey={element.name} disabled={this.state.onlyView}>
                                    {translatedMessage(element.label)}
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      }
                    </div>

                    <Row>
                      <Col className="col-12 col-lg-6 mb-3 pl-0 pr-0">
                        {!isArArt && !isGlb && <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.IOS")}</div>}
                        <div className="ca-dropzone-show ca-left fixed-width">
                          <DropzoneComponent
                            componentId="experience"
                            fileUsage={FileService.getFileUsage().AR}
                            fileType={experienceFileType}
                            resourceType={resourceType.name}
                            mobilePlatform={isGlb || isArArt ? null : 'IOS'}
                            deletePrevious={true}
                            deletedImage={this.state.arFile}
                            deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_AR_FILE"
                            fileFormatsDesc={experiencefileFormatsDesc}
                            maxSize={isArArt ? 150 : 100}
                            maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                            multipleFiles={false}
                            multipleFilesDesc=""
                            entity={this.state.arExperience}
                            storageitemlinked={true}
                            onupload={this.onUploadFile}
                          />
                        </div>
                      </Col>

                      {/* Sculpture unity scene for Android */}
                      {!isArArt && !isGlb &&
                        <Col className="col-12 col-lg-6 pl-0 pr-0">
                          <div className="ca-publish-section-container">
                            <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.ANDROID")}</div>
                            <div className="ca-dropzone-show ca-left fixed-width">
                              <DropzoneComponent
                                componentId="experience1"
                                fileUsage={FileService.getFileUsage().AR}
                                fileType={experienceFileType}
                                resourceType={resourceType.name}
                                mobilePlatform={'ANDROID'}
                                deletePrevious={true}
                                deletedImage={this.state.arFile1}
                                deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_AR_FILE"
                                fileFormatsDesc={experiencefileFormatsDesc}
                                maxSize={100}
                                maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                multipleFiles={false}
                                multipleFilesDesc=""
                                entity={this.state.arExperience}
                                storageitemlinked={true}
                                onupload={this.onUploadFile}
                              />
                            </div>
                          </div>
                        </Col>
                      }
                    </Row>
                  </div>
                </Col>
              }

              {/* Streamed content */}
              {this.state.arExperience.streamedExperience &&
                <Col className="col-12 col-lg-6 mb-3">
                  <div>
                    <Form id="stream-url-form" className="ca-form" onSubmit={this.handleSubmit}>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="formYoutubeId">
                          <div className='ca-publish-section-title'>
                            {translatedMessage("VIDEO.YOUTUBE_ID")}
                            <OverlayTrigger
                              key='yID'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-yID`} className="ca-tooltip">
                                  {translatedMessage("VIDEO.YOUTUBE_ID_DESC")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </div>
                          <Form.Control
                            required
                            type="text"
                            defaultValue={this.state.arExperience.experienceUrl}
                          />
                        </Form.Group>
                      </Form.Row>
                    </Form>
                  </div>

                  {this.state.arExperience.experienceUrl &&
                    <div className='mb-5'>
                      <DisplayVideoComponent youtubeId={this.state.arExperience.experienceUrl}
                        classimage=""
                        classvideo="ca-youtube-video-container" />
                    </div>
                  }
                </Col>
              }

              {/* Presentation images */}
              <Col className="col-12 col-lg-6">
                <div className="ca-publish-section-container">
                  <div className="ca-publish-section-title">
                    {isArArt ? translatedMessage("AR_EXPERIENCE.ADD_ART_IMAGE") : translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                    <OverlayTrigger
                      key='addImage'
                      placement='bottom'
                      overlay={
                        <Tooltip id={`tooltip-addImage`} className="ca-tooltip">
                          {isArArt ? translatedMessage("GENERAL.UPLOAD.FILE") : translatedMessage("AR_EXPERIENCE.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                        </Tooltip>
                      }
                    >
                      <span className="ca-question-icon"></span>
                    </OverlayTrigger>
                  </div>
                  <div className="ca-publish-section-subtitle">{translatedMessage("GENERAL.UPLOAD.FILE")}</div>

                  <div className={prez_img_class} onClick={this.changePrezImgDropzone.bind(this)}>
                    <Image src={this.state.arImageUrl} />
                    <div className="ca-form-image-change-details">
                      <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf030;</div>
                      <div>{translatedMessage("GENERAL.ARTWORK_IMAGE.TOOLTIP")}</div>
                    </div>
                  </div>
                  <div className={prez_dropzone_class}>
                    <DropzoneComponent
                      componentId="experienceImage"
                      fileUsage={presentationFileUsage}
                      fileType={FileService.getResourceType().IMAGE}
                      deletePrevious={true}
                      deletedImage={this.state.arImage}
                      deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                      fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                      maxSize={10}
                      maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                      multipleFiles={false}
                      multipleFilesDesc=""
                      entity={this.state.arExperience}
                      storageitemlinked={true}
                      onupload={this.onUploadPrezFile}
                    />
                    {this.state.arImageUrl !== '' &&
                      <div style={{ textAlign: "center" }}>
                        <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changePrezImgDropzone.bind(this)}>&#xf021;</div>
                      </div>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div >
        )
      }
  }
}

export default ArExperienceFilePage;