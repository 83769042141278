import React from 'react';

import { Row, Col, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import EditFieldModalComponent from '../../../components/shared/EditFieldModalComponent/EditFieldModalComponent';
import ChangeYoutubeIdModalComponent from '../../../components/shared/ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'
import HtmlEditorModalComponent from '../../../components/shared/HtmlEditorModalComponent/HtmlEditorModalComponent';
import SocialMediaAccountEditModalComponent from '../../../components/shared/SocialMediaAccountEditModalComponent/SocialMediaAccountEditModalComponent';
import DisplayVideoComponent from '../../../components/shared/DisplayVideoComponent/DisplayVideoComponent';

import backIcon from '../../../components/shared/Resources/left-arrow.svg';
import caLogo from '../../../components/shared/Resources/calogo-black.png';
import exhibitionIcon from '../../../components/shared/Resources/icons/exhibition.svg';
import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';
import virtualGallertIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import mediaIcon from '../../../components/shared/Resources/icons/media.svg';
import defaultVideo from '../../../components/shared/Resources/videoDefault.jpg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';
import editWhiteIcon from '../../../components/shared/Resources/icons/edit_white.svg';
import pictureIcon from '../../../components/shared/Resources/icons/picture.svg';
import videoIcon from '../../../components/shared/Resources/icons/video.svg';
import challengeIcon from '../../../components/shared/Resources/icons/challenge.svg';

import { toast } from "react-toastify";
import moment from 'moment';

class CompanySpacePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      showAddFileModal: false,
      fileModalData: {},
      images: [],
      videos: [],
      showEditYoutubeModal: false,
      logo: caLogo,
      logoUpload: false,
      showFieldModal: false,
      fieldModalData: {
        content: '',
        title: '',
        dataFor: '',
        required: false
      },
      showEditorModal: false,
      smaTypeList: [],
      showSMAModal: false,
      socialModalData: {
        title: '',
        icon: '',
        url: null
      },
      presImg: null,
      hasPresMedia: false,
      presImgUpload: false,
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        let company = response.data.company;

        if (company && company.status.name === 'ACTIVE') {
          ApiService.getSocialMediaAccountTypeList()
            .then(result => {
              this.setState({
                company: company,
                smaTypeList: result,
                logo: company.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${company.photoUuid}` : caLogo,
                hasPresMedia: company.presentationImageUuid || company.presentationVideoUrl ? true : false,
                presImg: company.presentationImageUuid ? `${ApiService.getBaseUrl()}/storage/file/${company.presentationImageUuid}` : null,
                isLoaded: true
              })
            })
        } else {
          this.setState({
            isError: true,
            errorMessage: "COMPANY.NOT_APPROVED",
            isLoaded: true
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  checkUrl = (url) => {
    let formatedUrl = url;
    if (url.startsWith("http://") || url.startsWith("https://")) {
      formatedUrl = url;
    } else {
      formatedUrl = "https://" + url;
    }

    try { return new URL(formatedUrl) }
    catch (e) { return false }
  }

  // compute the data sent to the add file modal based on the type of image added
  onShowAddFile = (event) => {
    let modalData = {};
    let logoUpload = false;
    let presImgUpload = false;

    if (event.currentTarget.id === 'btnLogoChange') {
      modalData = {
        modalTitle: translatedMessage("UPLOAD.IMAGES"),
        fileUsage: FileService.getFileUsage().LOGO,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: true,
        deletedImage: this.state.company.photoUuid ? { uuid: this.state.company.photoUuid } : null,
        deletePreviousDesc: "DROPZONE.DELETE_PREVIOUS_IMAGE",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: false,
        multipleFilesDesc: "",
        recommendedShape: "DROPZONE.RECOMMENDED_SHAPE.SQUARE",
        entity: this.state.company,
        entityUpdateAPILink: "companies",
        storageitemlinked: false
      }

      logoUpload = true;
    } else if (event.currentTarget.id === 'btnPresImgChange') {
      modalData = {
        modalTitle: translatedMessage("UPLOAD.IMAGES"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: true,
        deletedImage: this.state.company.presentationImageUuid ? { uuid: this.state.company.presentationImageUuid } : null,
        deletePreviousDesc: "DROPZONE.DELETE_PREVIOUS_IMAGE",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: false,
        multipleFilesDesc: "",
        entity: this.state.company,
        entityUpdateAPILink: "companies",
        storageitemlinked: false,
        fileFieldName: "presentationImage"
      }

      presImgUpload = true;
    }

    this.setState({
      fileModalData: modalData,
      logoUpload: logoUpload,
      presImgUpload: presImgUpload,
      showAddFileModal: true
    })
  }

  // update the state based on the changes to the image files
  onHideAddFile = (hasUpload, uploadedFile) => {
    if (hasUpload && hasUpload === true) {
      if (this.state.logoUpload) {
        this.setState({
          logo: uploadedFile ? `${ApiService.getBaseUrl()}/storage/file/${uploadedFile.uuid}` : caLogo,
          company: {
            ...this.state.company,
            photoUuid: uploadedFile.uuid
          }
        })
      } else if (this.state.presImgUpload) {
        this.setState({
          presImg: uploadedFile ? `${ApiService.getBaseUrl()}/storage/file/${uploadedFile.uuid}` : null,
          company: {
            ...this.state.company,
            presentationImageUuid: uploadedFile.uuid
          },
          hasPresMedia: true
        }, () => this.updatePresVideo(null, false))
      }
    }

    this.setState({
      showAddFileModal: false,
    })
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      this.updatePresVideo(videoId, true)
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  // update the presentation video and delete the presentation image if needed
  updatePresVideo = (videoId, detelePresImage) => {
    if (this.state.company.presentationVideoUrl || videoId) {
      ApiService.getAuthenticatedInstance().patch(`/companies/${this.state.company.id}`, {
        presentationVideoUrl: videoId,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          if (videoId) {
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          }

          this.setState({
            company: {
              ...this.state.company,
              presentationVideoUrl: videoId
            },
            hasPresMedia: true,
            showEditYoutubeModal: false
          }, () => {
            if (detelePresImage) {
              if (this.state.company.presentationImageUuid) {
                ApiService.getAuthenticatedInstance().patch(`/companies/${this.state.company.id}`, {
                  presentationImage: null,
                  lastUpdatedBy: ApiService.getCurrentUser().username,
                  lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                })
                  .then(() => {
                    FileService.deleteFile(this.state.company.presentationImageUuid)
                      .then(() => {
                        this.setState({
                          company: {
                            ...this.state.company,
                            presentationImageUuid: null
                          },
                          presImg: null,
                          showEditYoutubeModal: false
                        })
                      })
                      .catch(err => {
                        console.log("An error has ocurred: " + err);
                      })
                  })
                  .catch(err => {
                    console.log("An error has ocurred: " + err);
                    toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                  })
              }
            }
          })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    }
  }

  onShowEdit = (event) => {
    if (event.currentTarget.id === 'spaceName') {
      this.setState({
        fieldModalData: {
          content: this.state.company.spaceName,
          title: translatedMessage("COMPANY.SPACE.NAME"),
          dataFor: 'spaceName',
          required: true
        },
        showFieldModal: true,
      })
    } else if (event.currentTarget.id === 'spaceUrl') {
      this.setState({
        fieldModalData: {
          content: this.state.company.spaceUrl,
          title: translatedMessage("COMPANY.SPACE.URL"),
          dataFor: 'spaceUrl',
          required: false
        },
        showFieldModal: true,
      })
    } else if (event.currentTarget.id === 'spaceDescription') {
      this.setState({
        fieldModalData: {
          content: this.state.company.spaceDescription,
          title: translatedMessage("COMPANY.SPACE.DESCRIPTION"),
          dataFor: 'spaceDescription',
        },
        showEditorModal: true,
      })
    }
  }

  onHideEdit = (data) => {
    if (data && data.dataFor) {
      let spaceName = this.state.company.spaceName;
      let spaceUrl = this.state.company.spaceUrl;
      let spaceDescription = this.state.company.spaceDescription;

      data.content = data.content ? data.content : null;

      if (data.dataFor === "spaceName") {
        spaceName = data.content;
      } else if (data.dataFor === "spaceUrl") {
        spaceUrl = data.content;
      } else if (data.dataFor === "spaceDescription") {
        spaceDescription = data.content;
      }

      let checkUrl = { href: null };
      if (spaceUrl) {
        checkUrl = this.checkUrl(spaceUrl);
      }

      if (checkUrl) {
        const promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().patch(`/companies/${this.state.company.id}`, {
            spaceName: spaceName,
            spaceUrl: checkUrl.href,
            spaceDescription: spaceDescription,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
          resolve();
        });

        promise.then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            company: {
              ...this.state.company,
              spaceName: spaceName,
              spaceUrl: checkUrl.href,
              spaceDescription: spaceDescription
            }
          })
        })
          .catch(err => {
            toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
            console.log("An error has ocurred: " + err);
          });
      } else {
        toast.error(translatedMessage("COMPANY.SPACE.URL.BAD_FORMAT"));
      }
    }

    this.setState({
      showFieldModal: false,
      showEditorModal: false
    })
  }

  validate = (event) => {
    var response = {
      isError: false,
      message: "",
    };

    if (!this.state.company.photoUuid) {
      response.isError = true;
      response.message = "COMPANY.SPACE.ERROR.MISSING_LOGO";
    }

    if (!this.state.company.spaceName) {
      response.isError = true;
      response.message = "COMPANY.SPACE.ERROR.MISSING_NAME";
    }

    return response;
  }

  onSwitchChange = (event) => {
    const validation = this.validate();

    if (!validation.isError) {
      ApiService.getAuthenticatedInstance().patch(`/companies/${this.state.company.id}`, {
        spacePublished: event,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          this.setState({
            company: {
              ...this.state.company,
              spacePublished: event,
            }
          })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.SAVE_ERROR"));
        })
    } else {
      toast.error(translatedMessage(validation.message));
    }
  }

  setSocialMediaAccount = (type) => {
    let selectedType = this.state.smaTypeList.filter(item => item.name === type);
    let sma = this.state.company.socialMediaAccountList.filter(item => item.type.name === type);

    if (selectedType[0]) {
      let modalData = {
        title: translatedMessage(selectedType[0].label),
        icon: ApiService.getSocialMediaAccountIconByType(type),
        url: sma[0] ? sma[0].url : null,
        id: sma[0] ? sma[0].id : null,
        type: selectedType[0]
      }

      this.setState({
        socialModalData: modalData,
        showSMAModal: true
      })
    }
  }

  onHideSocial = (data) => {
    if (data && data.isSaved) {
      if (data.id) {
        if (data.url !== null) {
          ApiService.getAuthenticatedInstance().patch(`/socialMediaAccounts/${data.id}`, {
            url: data.url,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then(() => {
              toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));

              let smaList = this.state.company.socialMediaAccountList;
              smaList.map(item => {
                if (item.id === data.id) {
                  item.url = data.url;
                }

                return item;
              })

              this.setState({
                company: {
                  ...this.state.company,
                  socialMediaAccountList: smaList,
                },
                showSMAModal: false
              })
            })
            .catch(err => {
              console.log("An error has ocurred: " + err);
              toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
            })
        } else {
          ApiService.getAuthenticatedInstance().delete(`/socialMediaAccounts/${data.id}`)
            .then(() => {
              toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
              let smaList = this.state.company.socialMediaAccountList.filter(item => item.id !== data.id);

              this.setState({
                company: {
                  ...this.state.company,
                  socialMediaAccountList: smaList,
                },
                showSMAModal: false
              })
            })
            .catch(err => {
              console.log("An error has ocurred: " + err);
              toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
            })
        }
      } else {
        ApiService.getAuthenticatedInstance().post(`/socialMediaAccounts`, {
          company: `/company/${this.state.company.id}`,
          url: data.url,
          type: data.type.name,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then((response) => {
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));

            const parts = response.data._links.self.href.split('/');
            const id = parts[parts.length - 1];

            let sma = {
              id: id,
              companyId: this.state.company.id,
              type: data.type,
              url: data.url
            }

            let smaList = this.state.company.socialMediaAccountList;
            smaList.push(sma);

            this.setState({
              company: {
                ...this.state.company,
                socialMediaAccountList: smaList,
              },
              showSMAModal: false
            })
          })
          .catch(err => {
            console.log("An error has ocurred: " + err);
            toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          })
      }

      this.setState({
        showSMAModal: false
      })
    } else {
      this.setState({
        showSMAModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center justify-content-end">
            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <div>
                <OverlayTrigger
                  key='ca-portofolio'
                  placement='bottom'
                  overlay={
                    <Tooltip id={`tooltip-ca-portofolio`} className="ca-tooltip">
                      {this.state.company.spacePublished ? translatedMessage("COMPANY.SPACE.UNPUBLISH") : translatedMessage("COMPANY.SPACE.PUBLISH")}
                    </Tooltip>
                  }
                >
                  <div className="d-flex align-items-center">
                    <Switch
                      onChange={(event) => this.onSwitchChange(event)}
                      checked={this.state.company.spacePublished}
                      offColor="#F00"
                    />
                  </div>
                </OverlayTrigger>
              </div>

              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/home`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <div className="d-flex flex-sm-row flex-column" style={{ marginBottom: "2rem" }}>
            <div id="btnLogoChange" className={"ca-company-logo-container d-flex align-items-center justify-content-center"}
              onClick={this.onShowAddFile}>

              <img
                alt="company logo"
                src={this.state.logo}
                className="d-inline-block ca-company-logo"
              />

              <div className="ca-company-logo-change">
                <div style={{ fontFamily: "FontAwesome", fontSize: "32px" }}>&#xf030;</div>
                <div>{translatedMessage("GENERAL.IMAGE.TOOLTIP")}</div>
              </div>

            </div>

            <div className="w-100 d-flex align-content-center flex-wrap ca-company-space-detail-container">
              <div className='w-100 mb-2'>
                <span className="font-bold">
                  {translatedMessage("COMPANY.SPACE.NAME") + ": "}
                </span>
                <span>
                  {this.state.company.spaceName ? this.state.company.spaceName : translatedMessage("COMPANY.SPACE.NAME_PLACEHOLDER")}
                </span>
                <Button id="spaceName" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}
                  title={translatedMessage("GENERAL.EDIT")}>
                  <Image src={editWhiteIcon} alt={translatedMessage("GENERAL.EDIT")} />
                </Button>
              </div>
              <div className='w-100'>
                <span className="font-bold">
                  {translatedMessage("COMPANY.SPACE.URL") + ": "}
                </span>
                <span>
                  {this.state.company.spaceUrl ? this.state.company.spaceUrl : translatedMessage("COMPANY.SPACE.URL_PLACEHOLDER")}
                </span>
                <Button id="spaceUrl" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}
                  title={translatedMessage("GENERAL.EDIT")}>
                  <Image src={editWhiteIcon} alt={translatedMessage("GENERAL.EDIT")} />
                </Button>
              </div>
            </div>
          </div>

          <div className='ca-profile-social-container'>
            {
              this.state.smaTypeList.map((element, index) => {
                return (
                  <span key={index} className="mr-3" onClick={() => this.setSocialMediaAccount(element.name)} title={translatedMessage("GENERAL.EDIT")}>
                    <FontAwesomeIcon icon={["fab", element.icon]} />
                  </span>
                )
              })
            }
          </div>

          <Row className='align-items-top mb-5'>
            <Col className="col-12 col-md-6 col-lg-11 pl-0 pr-0">
              <div className='mb-1'>
                <span className='font-bold'>{translatedMessage("COMPANY.SPACE.DESCRIPTION")}</span>
                <Button id="spaceDescription" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}
                  title={translatedMessage("GENERAL.EDIT")}>
                  <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} />
                </Button>
              </div>
              {this.state.company.spaceDescription &&
                <span dangerouslySetInnerHTML={{ __html: this.state.company.spaceDescription }} className='ca-description-value' />
              }
              {!this.state.company.spaceDescription &&
                <span className='ca-description-value'>{translatedMessage("COMPANY.SPACE.DESCRIPTION_PLACEHOLDER")}</span>
              }
            </Col>

            <Col className="col-12 col-md-6 col-lg-1">
              <div className="ca-profile-media">
                {this.state.company.presentationVideoUrl &&
                  <DisplayVideoComponent youtubeId={this.state.company.presentationVideoUrl}
                    classimage=""
                    classvideo="ca-youtube-video-container" />
                }

                {this.state.presImg && !this.state.company.presentationVideoUrl &&
                  <Image src={this.state.presImg} className='presentation-image' />
                }

                {!this.state.hasPresMedia &&
                  <div className="h-100 no-content d-flex align-items-center justify-content-center">
                    <img src={defaultVideo} alt="CA logo" />
                  </div>
                }

                <div style={{ fontSize: "20px" }}>
                  <OverlayTrigger
                    key='addPresImage'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-addPresImage`} className="ca-tooltip">
                        {this.state.company.presentationVideoUrl
                          ? `${translatedMessage("PROFILE.PRES_IMG.CHANGE.TOOLTIP")} ${translatedMessage("PROFILE.PRESENTATION.DETAILS.TOOLTIP")}`
                          : translatedMessage("PROFILE.PRES_IMG.ADD.TOOLTIP")}
                      </Tooltip>
                    }
                  >
                    <Button id="btnPresImgChange" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                      <Image src={pictureIcon} alt={translatedMessage("GENERAL.IMAGE")}/>
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key='addPresVideo'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-addPresVideo`} className="ca-tooltip">
                        {this.state.company.presentationImageUuid
                          ? `${translatedMessage("PROFILE.PRES_VIDEO.CHANGE.TOOLTIP")} ${translatedMessage("PROFILE.PRESENTATION.DETAILS.TOOLTIP")}`
                          : translatedMessage("PROFILE.PRES_VIDEO.ADD.TOOLTIP")}
                      </Tooltip>
                    }
                  >
                    <Button id="btnPresVideoChange" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.handleYoutubeIdEdit}>
                      <Image src={videoIcon} alt={translatedMessage("GENERAL.VIDEO")}/>
                    </Button>                    
                  </OverlayTrigger>
                </div>
              </div>
            </Col >
          </Row>

          <Row className='mb-0'>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/my-company/${this.state.company.id}/gallery`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={galleryIcon} className="ca-homepage-icon" alt={translatedMessage("GALLERY.GALLERY")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("GALLERY.GALLERY")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/my-company/${this.state.company.id}/exhibition`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={exhibitionIcon} className="ca-homepage-icon" alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("EXHIBITION.EXHIBITIONS")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/my-company/${this.state.company.id}/virtual-galleries`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={virtualGallertIcon} className="ca-homepage-icon" alt={translatedMessage("VIRTUAL_GALLERY.VIRTUAL_ROOMS")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_ROOMS")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/my-company/${this.state.company.id}/media`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={mediaIcon} className="ca-homepage-icon" alt={translatedMessage("GENERAL.MEDIA")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/my-company/${this.state.company.id}/challenge`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={challengeIcon} className="ca-homepage-icon" alt={translatedMessage("CHALLENGE.CHALLENGES")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("CHALLENGE.CHALLENGES")}</div>
                </div>
              </Link>
            </Col>            
          </Row>

          <AddFileModalComponent
            show={this.state.showAddFileModal}
            onHide={this.onHideAddFile}
            data={this.state.fileModalData}
          />

          <ChangeYoutubeIdModalComponent
            show={this.state.showEditYoutubeModal}
            onHide={this.onHideYoutubeModal}
            youtubeid={this.state.company.presentationVideoUrl}
          />

          <EditFieldModalComponent
            show={this.state.showFieldModal}
            onHide={this.onHideEdit}
            data={this.state.fieldModalData}
          />

          <HtmlEditorModalComponent
            show={this.state.showEditorModal}
            onHide={this.onHideEdit}
            data={this.state.fieldModalData}
          />

          <SocialMediaAccountEditModalComponent
            show={this.state.showSMAModal}
            onHide={this.onHideSocial}
            data={this.state.socialModalData}
          />
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanySpacePage;