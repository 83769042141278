import React from 'react';

import { Card, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translatedMessage } from '../../../services/language.service';

import mapPinIcon from '../../../components/shared/Resources/icons/map_pin.svg';

class GalleryArtworkCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  componentDidMount = () => {
    this.setState({
      checked: this.props.existingArt
    })
  }

  handleChange = (event) => {
    const input = event.currentTarget.querySelector('input')
    this.setState({
      checked: !input.checked
    })
    this.props.onCheck(input.id, !input.checked);
  }

  render() {
    return (
      <Card className="ca-artwork-card">
        <Link to={this.props.isAdd ? "#" : this.props.link} style={{ cursor: this.props.isAdd ? "default" : "pointer" }}>
          <Card.Img variant="top" src={this.props.image} />
          <Card.Body>

            <Card.Title>
              {this.props.title}
              <span className="font-weight-normal" style={{ fontSize: "15px" }}>
                {this.props.author &&
                  " " + translatedMessage("GENERAL.BY").toLowerCase() + " " + this.props.author
                }
              </span>
            </Card.Title>
            {/* {this.props.entity &&
              <Card.Text>{translatedMessage(this.props.entity.entityName)}</Card.Text>
            } */}
            {this.props.category &&
              <Card.Text>{translatedMessage("GENERAL.CATEGORY") + ": " + translatedMessage(this.props.category)}</Card.Text>
            }
            {this.props.type &&
              <Card.Text>{translatedMessage("GENERAL.TYPE") + ": " + translatedMessage(this.props.type)}</Card.Text>
            }
            {this.props.soldByCompany &&
              <Card.Text className='mt-3'>
                {translatedMessage("ARTWORK.COMPANY_COMM_PORTFOLIO")}
                <OverlayTrigger
                  key='ca-soldByCompany'
                  placement='bottom'
                  overlay={
                    <Tooltip id={`tooltip-ca-soldByCompany`} className="ca-tooltip">
                      {translatedMessage("ARTWORK.COMPANY_COMM_PORTFOLIO.INFO")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>

              </Card.Text>
            }
          </Card.Body>
        </Link>

        {!this.props.isAdd &&
          <Card.Footer className="ca-gallery-card-footer pt-0">
            <div className='d-flex align-items-center justify-content-between mt-2'>
              <div className="more-info-button" onClick={() => this.props.onClick(this.props.id, this.props.entity.entityName)}>
                {translatedMessage("GENERAL.MORE_INFO")}
              </div>
              {this.props.artworkCanHaveLocation &&
                <Link className="ca-dark-link small" to={this.props.locationRedirectUrl} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                  <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")}/>
                </Link>
              }
            </div>
          </Card.Footer>
        }

        {this.props.isAdd &&
          <Card.Footer>
            <div className='d-flex align-items-center justify-content-between mt-2'>
              <div className="ca-check-container" onClick={this.handleChange}>
                <input
                  id={this.props.id}
                  checked={this.state.checked}
                  type="checkbox"
                  className="d-flex align-items-center"
                  onChange={this.handleChange}
                />
                <span className="ca-check-label">{translatedMessage(this.props.selectText)}</span>
                <span className="ca-checkmark"></span>
              </div>
              <div className="more-info-button" onClick={() => this.props.onClick(this.props.id, this.props.entity.entityName)}>
                {translatedMessage("GENERAL.MORE_INFO")}
              </div>
            </div>
          </Card.Footer>
        }
      </Card>
    )
  }

}

export default GalleryArtworkCardComponent;