import React from 'react';
import './ArtworkBuyModalComponent.css';

import { translatedMessage } from '../../../services/language.service';
import soldout from '../../../components/shared/Resources/sold_out.png';
import limitedEdition from '../../../components/shared/Resources/limited_edition.png';

import { Modal, Button, Image } from 'react-bootstrap';
import ArtworkService from '../../../services/artwork.service';

class ArtworkBuyModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: null,
        };
    }

    numberSeparatedByDots = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    buyAndClose = (element = null) => {
        this.props.buy(element);
        this.props.onHide();
        return;
    }

    addFreeArtAndClose = (element = null) => {
        this.props.addfreeart(element);
        this.props.onHide();
        return;
    }

    render() {
        let printDetails = "";
        if(this.props.category === ArtworkService.artworkCategoryEnum().AR_ART || this.props.category === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE){
            if(this.props.element.hasDigitalPrint) {
                printDetails = translatedMessage("PRINT.ART_PRINT_FILE") + (this.props.element.hasSignature ? "" : ".");
            }
            if(this.props.element.hasSignature) {
                printDetails += " " + translatedMessage("GENERAL.THAT") + " " + translatedMessage("PRINT.ART_UNIQUELY_SIGNED");
            }            
        } else if(this.props.category === ArtworkService.artworkCategoryEnum().OBJECT){
            if(this.props.element.hasDigitalPrint) {
                printDetails = translatedMessage("PRINT.ART_POSTER.INTRO") + " " + 
                    this.props.element.digitalPrintQuantity + " "  + translatedMessage("PRINT.ART_POSTER.PRINT") + (this.props.element.hasSignature ? "" : ".");
            }
            if(this.props.element.hasSignature) {
                printDetails += " " + translatedMessage("GENERAL.THAT") + " " + translatedMessage("PRINT.ART_POSTER.SIGNED");
            }    
        } else if(this.props.category === ArtworkService.artworkCategoryEnum().AR_PORTAL){
            if(this.props.element.hasDigitalPrint) {
                printDetails = translatedMessage("PRINT.VG_POSTER.INTRO") + " " + 
                    this.props.element.digitalPrintQuantity + " "  + translatedMessage("PRINT.VG_POSTER.PRINT") + (this.props.element.hasSignature ? "" : ".");
            }
            if(this.props.element.hasSignature) {
                printDetails += " " + translatedMessage("GENERAL.THAT") + " " + translatedMessage("PRINT.VG_POSTER.SIGNED");
            }    
        }

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                element={this.props.artworkBuyModalData}
                category={this.props.category}
                artworkname={this.props.artworkName}
                author={this.props.author}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="md"
                className="ca-artwork-buy-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.artworkname}, <span className="font-weight-normal">{this.props.author}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.element.availableCopies === 0
                        ? <Image src={soldout} className="ca-soldout-image" />
                        : this.props.element.quantity > 0
                            ? <Image src={limitedEdition} className="ca-limited-image ca-limited-modal-image" />
                            : ""
                    }
                    <Modal.Title>
                        <div className="w-100">{this.props.element.name} {translatedMessage("ARTWORK.PACKAGE").toLowerCase()}</div>
                        <div className="w-100 ca-modal-title-detail">
                            {this.props.element.quantity === -1
                                ? ""
                                : translatedMessage("ARTWORK.AVAILABLE") + ": " + this.props.element.availableCopies + " / " + this.props.element.nrCopies
                            }
                        </div>
                    </Modal.Title>
                    <div className="w-100 ca-buy-modal-price">
                        <div className="w-100 font-weight-bold">{this.numberSeparatedByDots(this.props.element.price)} USD</div>
                        <div className="w-100 ca-price-detail">{translatedMessage("ARTWORK.PRICE_EST_CURR")} {this.numberSeparatedByDots(this.props.element.price * this.props.exchangerate)} NOK</div>
                    </div>
                    <div className="ca-buy-modal-pkg">{translatedMessage(this.props.element.description)}</div>
                    <div className="ca-buy-modal-print mt-2">{printDetails}</div>
                    <div className="ca-buy-modal-print mt-2">{translatedMessage("ARTWORK.PACKAGES.READ_MORE")}</div>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.element.isFreePackage
                        ?
                        <Button variant="dark" className="ca-button" onClick={() => this.addFreeArtAndClose(this.props.element)}
                            disabled={this.props.element.availableCopies === 0 ? true : false}>
                            {translatedMessage("ARTWORK.ADD_TO_COLLECTION")}
                        </Button>
                        :
                        <Button variant="dark" className="ca-button" onClick={() => this.buyAndClose(this.props.element)}
                            disabled={this.props.element.availableCopies === 0 ? true : false}>
                            {translatedMessage("GENERAL.BUY")}
                        </Button>
                    }
                    <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide()}>
                        {translatedMessage("GENERAL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ArtworkBuyModalComponent;

