import React from 'react';
import './ProjectEditPage.css'

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import artIcon from '../../../components/shared/Resources/icons/art.svg';

import { Link } from 'react-router-dom';

import { Row, Col, Image, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap/';
// import Switch from "react-switch";
import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";

import ApiService from '../../../services/api.service';
import LanguageService from '../../../services/language.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';

import { toast } from "react-toastify";

class ProjectEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectId: this.props.match.params.id,
            projectInformation: {
                title: '',
                imageURL: '',
                artworkDescription: '',
                communityDescription: '',
                displayInCommunity: false,
            },
            projectPicture: null,
            editorButtns: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
            cummunityEditorEnabled: false,
            dropzoneEnabled: true,
            isLoaded: false,
            isError: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        ApiService.getAuthenticatedInstance().get(`/artwork/project/${this.props.match.params.id}`)
            .then(result => {
                let project = result.data.project;
                this.setState({
                    projectInformation: {
                        ...this.state.projectInformation,
                        id: project.id,
                        title: project.name,
                        artworkDescription: project.description,
                        communityDescription: project.communityDescription,
                        displayInCommunity: project.displayInCommunity,
                        storageItem: {
                            id: project.storageItemId
                        },
                        imageURL: ApiService.getFileByProjectUrl(project.id) + '?' + Date.now()
                    },
                    cummunityEditorEnabled: project.displayInCommunity
                })
            })
            .then(() => {
                ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.projectInformation.id}/${ApiService.getEntityName().PROJECT}`)
                    .then(results => {
                        const projectFiles = results.data.fileList.filter(file => file.usage === 'PROJECT');
                        this.setState({
                            projectPicture: projectFiles.length > 0 ? projectFiles[0] : {},
                            isLoaded: true,
                        });
                    });
            })
            .catch(err => {
                var errorMessage = "PROJECT.GENERAL_ERROR";
                if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
                    errorMessage = err.response.data.message;
                }
                this.setState({
                    isError: true,
                    errorMessage: errorMessage,
                    isLoaded: true
                })
            })
    }

    saveChanges = (event) => {
        event.preventDefault();
        const promise = new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().patch(`/projects/${this.props.match.params.id}`, {
                name: event.target.elements.formArtworkTitle.value,
                description: this.state.projectInformation.artworkDescription,
                communityDescription: this.state.projectInformation.displayInCommunity ? this.state.projectInformation.communityDescription : null,
                displayInCommunity: this.state.projectInformation.displayInCommunity,
            })
            resolve();
        });

        promise.then(() => {
            toast.success(translatedMessage("PROJECT.SAVE_SUCCESS"));
        })

    }

    goBack = () => { this.props.history.go(-1); }

    onChangeEditor = (e) => {
        this.setState({
            projectInformation: {
                ...this.state.projectInformation,
                artworkDescription: e,
            }
        })
    }

    onChangeCommunityEditor = (e) => {
        this.setState({
            projectInformation: {
                ...this.state.projectInformation,
                communityDescription: e
            }
        });
    };

    onChangeCommunityDisplay = (checked, event, id) => {
        this.setState({
            projectInformation: {
                ...this.state.projectInformation,
                displayInCommunity: checked,
            },
            cummunityEditorEnabled: checked
        });
    }

    onUploadFile = (dropzoneEnabled, uploadedFile) => {
        if (dropzoneEnabled) {
            setTimeout(() => {
                this.setState({
                    dropzoneEnabled: dropzoneEnabled,
                    projectPicture: uploadedFile,
                    projectInformation: {
                        ...this.state.projectInformation,
                        imageURL: `${ApiService.getBaseUrl()}/storage/file/${uploadedFile.uuid}`
                    }
                })
            }, 500);
        } else {
            this.setState({
                dropzoneEnabled: dropzoneEnabled,
                projectPicture: uploadedFile,
                projectInformation: {
                    ...this.state.projectInformation,
                    imageURL: `${ApiService.getBaseUrl()}/storage/file/${uploadedFile.uuid}`
                }
            });
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                return (
                    <div className="ca-page-padding ca-main-container mt-0">
                        <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
                            <div className="pr-2 d-flex align-items-center">
                                <Image src={artIcon} className="ca-page-title-icon" alt={translatedMessage("ARTWORK")} />
                                <div className="ca-page-title">{translatedMessage("PROJECT.EDIT")}</div>
                            </div>

                            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">

                                <Button className="ca-dark-link ca-button-icon medium mr-1" type="submit" variant="link" form="photo-edit-form">
                                    <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
                                </Button>

                                <Link to="#" className="ca-dark-link" onClick={this.goBack}>
                                    <Image className="ca-page-title-icon ml-1 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                                        title={translatedMessage("BUTTON.BACK")} />
                                </Link>
                            </div>
                        </Row>

                        <Row>
                            <Col className="col-12 col-lg-12 col-xl-12 pl-0 pr-0">
                                <Form id="photo-edit-form" className="ca-form" onSubmit={this.saveChanges}>
                                    <Form.Group controlId="formArtworkTitle">
                                        <Form.Label>{translatedMessage("PROJECT.ARTWORK_NAME")}
                                            <OverlayTrigger
                                                key='nT'
                                                placement='right'
                                                overlay={
                                                    <Tooltip id={`tooltip-nt`}>
                                                        {translatedMessage("PROJECT.ARTWORK_NAME.TOOLTIP")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ca-question-icon"></span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            placeholder={translatedMessage("PROJECT.ARTWORK_NAME_PLACEHOLDER")}
                                            defaultValue={this.state.projectInformation.title}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formProjectImage">
                                        <Form.Label>{translatedMessage("PROJECT.IMAGE")}
                                            <OverlayTrigger
                                                key='iT'
                                                placement='right'
                                                overlay={
                                                    <Tooltip id={`tooltip-it`}>
                                                        {translatedMessage("PROJECT.IMAGE.TOOLTIP")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ca-question-icon"></span>
                                            </OverlayTrigger>
                                        </Form.Label>

                                        <Row className="d-flex align-items-stretch">
                                            <Col className="col-12 col-sm-6 ca-project-photo-container">
                                                <Image style={{ width: "100%", borderRadius: "10px" }} src={this.state.projectInformation.imageURL} />
                                            </Col>

                                            <Col className="col-12 col-sm-6 ca-project-title-container pr-0">
                                                <DropzoneComponent
                                                    componentId="projectPicture"
                                                    fileUsage={FileService.getFileUsage().PROJECT}
                                                    fileType={FileService.getResourceType().IMAGE}
                                                    deletePrevious={true}
                                                    deletedImage={this.state.projectPicture}
                                                    deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                                                    fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                                                    maxSize={1}
                                                    maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                                    multipleFiles={false}
                                                    multipleFilesDesc=""
                                                    entity={this.state.projectInformation}
                                                    storageitemlinked={true}
                                                    onupload={this.onUploadFile}
                                                    cardheight="100%"
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group controlId="formArtworkDescription">
                                        <Form.Label>{translatedMessage("PROJECT.DESCRIBE_ARTWORK")}</Form.Label>
                                        <div className="ca-text-editor">
                                            <SunEditor
                                                name="project-editor"
                                                lang={LanguageService.getCurrentLanguage()}
                                                placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                                setContents={this.state.projectInformation.artworkDescription}
                                                setOptions={{
                                                    height: 150,
                                                    maxWidth: "100%",
                                                    buttonList: this.state.editorButtns,
                                                    font: this.state.editorFonts,
                                                    defaultStyle: this.state.editorDefaultStyle
                                                }}
                                                onChange={this.onChangeEditor}
                                            />
                                        </div>
                                    </Form.Group>

                                    {/* FIXME: Enable when community functionality is enabled */}
                                    {/* <Form.Group controlId="formDisplayCmmunity">
                                        <Form.Label>{translatedMessage("PROJECT.DISPLAY_IN_COMMUNITY_ASK")}</Form.Label>
                                        <div>
                                            <Switch
                                                onChange={this.onChangeCommunityDisplay}
                                                checked={this.state.projectInformation.displayInCommunity}
                                                offColor="#F00"
                                            />
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formArtworkCommunityDescription">
                                        {this.state.cummunityEditorEnabled &&
                                            <Form.Label>{translatedMessage("PROJECT.COMMUNITY_DESCRIPTION_DETAILS")}</Form.Label>
                                        }
                                        <div className="ca-text-editor">
                                            <SunEditor
                                                name="project-editor"
                                                lang={LanguageService.getCurrentLanguage()}
                                                placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                                setContents={this.state.projectInformation.communityDescription}
                                                hide={!this.state.cummunityEditorEnabled}
                                                setOptions={{
                                                    height: 150,
                                                    maxWidth: "100%",
                                                    buttonList: this.state.editorButtns,
                                                    font: this.state.editorFonts,
                                                    defaultStyle: this.state.editorDefaultStyle
                                                }}
                                                onChange={this.onChangeCommunityEditor}
                                            />
                                        </div>
                                    </Form.Group> */}
                                </Form>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }
    }

}

export default ProjectEditPage;