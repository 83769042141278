import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';
import FileService from '../../services/file.service';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import Carousel from "../../components/shared/Carousel/MediaCarouselComponent";

import backIcon from '../../components/shared/Resources/left-arrow.svg';
import profilepic from '../../components/shared/Resources/defaultProfilePicture.png';

class ProfileMediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.match.params.id ? this.props.match.params.id : null,
      isLoaded: false,
      isError: false,
      errorMessage: "",
      images: [],
      createAndDestroyVideos: [],
      artworkDescriptionVideos: [],
      creationProcessVideos: [],
      hasMedia: false
    };
  }

  componentDidMount = () => {
    ApiService.getAuthenticatedInstance().get(`/user/public-profile/user/${this.state.userId}?includeMedia=true`)
      .then(result => {
        let profile = result.data.profile;
        profile.profilePicture = profile.photoUuid !== '' ? `${ApiService.getBaseUrl()}/storage/file/${profile.photoUuid}` : profilepic;

        let videoCD = [];
        let videoAD = [];
        let videoCP = [];

        profile.fileList.forEach(video => {
          let mockFile = {};
          mockFile.file = video;
          mockFile.original = ApiService.getBaseUrl() + '/storage/file/' + video.uuid;
          mockFile.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + video.uuid;
          mockFile.type = FileService.getResourceType().VIDEO;
          mockFile.youtubeId = video.youtubeId;
          if (video.usage === 'CREATE_DESTROY') {
            videoCD.push(mockFile)
          } else if (video.usage === 'ART_DESC') {
            videoAD.push(mockFile)
          } else if (video.usage === 'CREATION_PROC') {
            videoCP.push(mockFile)
          }
        })

        this.setState({
          profile: profile,
          hasMedia: profile.fileList.length > 0 || profile.videoUrlList.length > 0 ? true : false,
          createAndDestroyVideos: videoCD,
          artworkDescriptionVideos: videoAD,
          creationProcessVideos: videoCP,
          isLoaded: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={this.state.profile.profilePicture} className="d-inline-block align-center profile-pic small ca-profile-photo mr-1"
                alt="profile pictire" />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.profile.displayName}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/profile/${this.state.userId}/view`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="mb-1">
            {!this.state.hasMedia &&
              <Col>
                <div className="font-bold w-100 mb-1">
                  <span>{translatedMessage("MEDIA.NO_UPLOADS")}</span>
                </div>
              </Col>
            }
            {this.state.images.length > 0 &&
              <Col className="col-12 col-lg-6">
                <Carousel
                  id="presImg"
                  items={this.state.images}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={true}
                  toolbarDelete={false}
                  toolbarFullScreen={true}
                  canDeleteLast={false}
                />
              </Col>
            }

            {this.state.createAndDestroyVideos.length > 0 &&
              <Col className="col-12 col-lg-6">
                <div className="ca-section-title w-100">
                  {translatedMessage("PROFILE.CREATE_AND_DESTROY")}
                </div>
                <Carousel
                  id="createAndDestroyVideos"
                  items={this.state.createAndDestroyVideos}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={false}
                  toolbarDelete={false}
                  toolbarYoutubeId={false}
                  videoUrlOnly={false}
                />
              </Col>
            }

            {this.state.artworkDescriptionVideos.length > 0 &&
              <Col className="col-12 col-lg-6">
                <div className="ca-section-title w-100">
                  {translatedMessage("PROFILE.ARTWORK_DESCRIPTION")}
                </div>
                <Carousel
                  id="createAndDestroyVideos"
                  items={this.state.artworkDescriptionVideos}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={false}
                  toolbarDelete={false}
                  toolbarYoutubeId={false}
                  videoUrlOnly={false}
                />
              </Col>
            }

            {this.state.creationProcessVideos.length > 0 &&
              <Col className="col-12 col-lg-6">
                <div className="ca-section-title w-100">
                  {translatedMessage("PROFILE.ARTWORK_PROCESS")}
                </div>
                <Carousel
                  id="createAndDestroyVideos"
                  items={this.state.creationProcessVideos}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={false}
                  toolbarDelete={false}
                  toolbarYoutubeId={false}
                  videoUrlOnly={false}
                />
              </Col>
            }

            {this.state.profile.videoUrlList.length > 0 &&
              <Col className="col-12 col-lg-6">
                <div className="ca-section-title w-100">
                  {translatedMessage("GENERAL.VIDEOS")}
                </div>
                <Carousel
                  id="createAndDestroyVideos"
                  items={this.state.profile.videoUrlList}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={false}
                  toolbarDelete={false}
                  toolbarYoutubeId={false}
                  videoUrlOnly={true}
                />
              </Col>
            }
          </Row>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ProfileMediaPage;