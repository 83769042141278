import ApiService from "./api.service";
import logoblack from '../components/shared/Resources/logo-dark-small-with-padding.png';

const GalleryService = {
  getGalleryStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/galleryStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getGalleryTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`galleryTypes`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getExhibitionStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/exhibitionStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getGalleries(caOnly) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/gallery/list/${caOnly}`)
        .then(response => {
          let galleries = response.data

          galleries.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });
          galleries.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(galleries)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getAllExhibitions() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/gallery/exhibition/all`)
        .then(response => {
          let exhibitions = response.data

          exhibitions.map((element, index) => {
            if (element.photo === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photo.uuid}`
            }
            return element;
          });
          exhibitions.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(exhibitions)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCAExhibitions() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/gallery/exhibition/ca`)
        .then(response => {
          let exhibitions = response.data

          exhibitions.map((element, index) => {
            if (element.photo === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photo.uuid}`
            }
            return element;
          });
          exhibitions.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(exhibitions)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getUserGalleries(caOnly) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/user-galleries/${caOnly}`)
        .then(response => {
          const userGalleries = response.data
          userGalleries.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });
          userGalleries.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(userGalleries)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getUserExhibitions() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/userExhibitionDetail`)
        .then(response => {
          const userExhibitions = response.data          
          userExhibitions.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });
          userExhibitions.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(userExhibitions)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getUserCAExhibitions() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/exhibition/ca/user-access`)
        .then(response => {
          const userExhibitions = response.data          
          userExhibitions.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });
          userExhibitions.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(userExhibitions)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getGalleryAccessTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/galleryAccessTypeList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },  

  getExhibitionAccessTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/exhibitionAccessTypeList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },  

  getGalleryVirtualGalleries(galleryId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/${galleryId}/virtual-galleries`)
        .then(response => {
          let galleries = response.data
          galleries.map((element, index) => {
            element.imageURL = element.virtualGallery.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${element.virtualGallery.photoUuid}` : "";
            
            return element
          });

          galleries.sort((a, b) => {
            var result;
            a.virtualGallery.name.toUpperCase() <= b.virtualGallery.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(galleries);
        })
        .catch(err => { reject(err) });
    })
  }, 
  
  getExhibitionVirtualGalleries(exhibitionId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${exhibitionId}/virtual-galleries/manage`)
        .then(response => {
          let exhibitionGalleries = response.data
          exhibitionGalleries.map((element, index) => {
            element.imageURL = element.virtualGallery.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${element.virtualGallery.photoUuid}` : "";
            
            return element
          });

          exhibitionGalleries.sort((a, b) => {
            var result;
            a.virtualGallery.name.toUpperCase() <= b.virtualGallery.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(exhibitionGalleries);
        })
        .catch(err => { reject(err) });
    })
  },  

  addVGToGallery(galleryId, virtualGalleries) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/gallery/${galleryId}/virtual-gallery/add?virtualGalleries=${virtualGalleries}`)
        .then(resolve)
        .catch(err => {
          reject(err);
        });
    });
  },

  addVGToExhibition(exhibitionId, virtualGalleries) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/gallery/exhibition/${exhibitionId}/virtual-gallery/add?virtualGalleries=${virtualGalleries}`)
        .then(resolve)
        .catch(err => {
          reject(err);
        });
    });
  },   
};

export default GalleryService;