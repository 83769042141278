import React from 'react';

import CardComponent from "./ExhibitionArtworkCardComponent";

import back from '../../../components/shared/Resources/left-arrow.svg';
// import map from '../../../components/shared/Resources/homepage/map.svg';
import extendedExperience from '../../../components/shared/Resources/homepage/extendedExperience.svg';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import { Link } from 'react-router-dom'

import { Row, Col, Dropdown, Form, InputGroup, Image } from 'react-bootstrap';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import ArtworkService from '../../../services/artwork.service';

const ENTER_KEY = 13;

class ExhibitionArtworkComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.exhibitionId,
      exhibitionName: '',
      searchInput: '',
      artworks: [],
      artworkCategoryList: [],
      filteredArtworks: [],
      selectedCategory: { id: "0", code: "GENERAL.CATEGORY.ANY" },
      selectedType: "GENERAL_TYPE.ANY",
      typeList: ["GENERAL_TYPE.ANY", "ARTWORK", "AR_EXPERIENCE", "VIRTUAL_GALLERY"],
      isLoaded: false,
      membership: {},
      errorMessage: '',
      isError: false
    };
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = () => {
    var exhibitionName;

    ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${this.state.exhibitionId}`)
      .then(response => {
        exhibitionName = response.data.name

        this.setState({
          exhibitionName: exhibitionName,
        }, this.getArtworks)        
      })      
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  getArtworks = () => {
    var exhibitionId = this.state.exhibitionId;
    var artworkCategoryList = [];
    var artworks = [];
    var promise = [];

    promise.push(ArtworkService.getArtworkCategoryList()
      .then(response => {
        artworkCategoryList = response;
        artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
      }))

    promise.push(ArtworkService.getExhibitionArt(exhibitionId)
      .then(response => artworks = response)
    )

    Promise.all(promise).then(() => {
      this.setState({
        artworks: artworks,
        filteredArtworks: artworks,
        artworkCategoryList: artworkCategoryList,
        isLoaded: true
      })
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  handleOnTypeSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.typeList.filter(item => item === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;
    let typeItem = this.state.selectedType;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase())
        || (elem.author && elem.author.toLowerCase().includes(searchInput.toLowerCase())));
    }

    if (typeItem !== "GENERAL_TYPE.ANY") {
      result = result.filter(elem => elem.entityName && elem.entityName === typeItem);
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.category && elem.category === categoryItem.code);
    }

    this.setState({
      filteredArtworks: result
    })
  }

  render() {
    const artworks = this.state.filteredArtworks;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container mt-0">
          <Row className="ca-page-title-button-row align-items-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={extendedExperience} className="ca-page-title-icon" alt={this.state.exhibitionName} />
              <span className="ca-page-title">
                {this.state.exhibitionName}
              </span>
            </div>

            <div className="ca-page-title-button-row-buttons">
              <Link to={this.props.backUrl} className="ca-dark-link">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder={translatedMessage("SEARCH.BY.NAME_AUTHOR")} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element}>
                        {translatedMessage(element)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="card-columns">
            {artworks.map((element, index) => {
              let link = "";
              let id = 0;

              if (element.entityName === ApiService.getEntityName().ARTWORK) {
                id = element.artworkId;
                link = `/artwork/${element.artworkId}`;
              } else if (element.entityName === ApiService.getEntityName().AR_EXPERIENCE) {
                id = element.arExperienceId
                link = `/arExperience/${element.arExperienceId}`;
              } else if (element.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                id = element.virtualGalleryId
                link = `/virtualGalleryDetails/${element.virtualGalleryId}`;
              }
              return (
                <CardComponent
                  key={index}
                  entity={element}
                  cardImage={element.image}
                  cardTitle={element.name}
                  cardAuthor={element.author}
                  cardCategory={element.category}
                  cardId={id}
                  cardLink={link}
                  companyId={this.props.companyId ? this.props.companyId : null}
                />
              )
            })}
          </div>

        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history}/>
      )
    }
  }

}

export default ExhibitionArtworkComponent;