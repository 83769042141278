import React from 'react';

import ApiService from '../../../../services/api.service';

import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';
import ExhibitionEditComponent from '../../../../components/shared/Exhibition/ExhibitionManageEditComponent';

class CompanyExhibitionEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      exhibitionId: this.props.match.params.exhibitionId ? this.props.match.params.exhibitionId : null,
      isLoaded: false,
      company: null,
      canEdit: false
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
    .then(response => {
      let company = response.data.company;

      if (company.status.name === 'ACTIVE') {
        this.setState({
          company: company,
          canEdit: true,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: "COMPANY.NOT_APPROVED",
          isLoaded: true
        })
      }
    })
    .catch(err => {
      console.log(err)
      if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
        errorMessage = err.response.data.message;
      }

      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canEdit) {
      return (
        <ExhibitionEditComponent
          companyId={this.state.company.id}
          exhibitionId={this.state.exhibitionId}
          canEdit={this.state.canEdit}
          history={this.props.history}
          backUrl={`/my-company/${this.state.company.id}/exhibition`}
          redirectNewUrl={`/my-company/${this.state.company.id}/exhibition-edit/`}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default CompanyExhibitionEditPage;