import React from 'react';

import { Modal, Button, Form, Dropdown } from 'react-bootstrap/'

import { translatedMessage } from '../../../../services/language.service';

import CompanyService from '../../../../services/company.service';

const defaultUser = {
    artist: null,
    status: { label: 'COMPANY.USER.STATUS.ACTIVE', name: 'ACTIVE' },
    isAdministrator: false
};

class EditArtistModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            statusList: [],
            selectedArtist: defaultUser
        }
    }

    async componentDidMount() {
        const statusList = await CompanyService.getArtistCompanyStatusList()
            .then(response => { 
                let statuses = response;
                return statuses.filter(item => item.name === "ACTIVE" || item.name === 'INACTIVE');
            })

        this.setState({
            statusList: statusList,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedArtist !== this.props.selectedArtist) {
            this.setState({ selectedArtist: this.props.selectedArtist });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedArtist !== prevState.selectedArtist) {
            return { selectedArtist: prevState.selectedArtist };
        }
        else return null;
    }

    handleOnStatusSelect = (eventKey) => {
        const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
        this.setState({
            selectedArtist: {
                ...this.state.selectedArtist,
                status: selectedItem[0]
            }
        });
    }

    saveChanges = (event) => {
        event.preventDefault();
        this.props.onHide(this.state.selectedArtist.artist.id, this.state.selectedArtist.status.name);
    };

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Group>
                            <Form.Label>
                                {translatedMessage("USER.USER")}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                disabled={true}
                                defaultValue={this.state.selectedArtist.artist ? this.state.selectedArtist.artist.fullName : ""}
                            />
                        </Form.Group>

                        <Form.Group controlId="formStatus">
                            <Form.Label>
                                {translatedMessage("GENERAL.STATUS")}
                            </Form.Label>
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedArtist.status.label)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.statusList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                {translatedMessage(element.label)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default EditArtistModalComponent