import React from 'react';

import ExhibitionManageArtworkComponent from '../../../../components/shared/Exhibition/ExhibitionManageArtworkComponent';
import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';
import exhibitionIcon from '../../../../components/shared/Resources/icons/exhibition.svg';

import ApiService from '../../../../services/api.service';

class ExhibitionAdminArtworkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      exhibitionId: this.props.match.params.exhibitionId,
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      company: null
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
    .then(response => {
      let company = response.data.company;

      if (company.status.name === 'ACTIVE') {
        this.setState({
          company: company,
          canEdit: true,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: "COMPANY.NOT_APPROVED",
          isLoaded: true
        })
      }
    })
    .catch(err => {
      console.log(err)
      if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
        errorMessage = err.response.data.message;
      }

      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    });    
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionManageArtworkComponent
          history={this.props.history}
          canEdit={this.state.canEdit}
          isAdmin={false}
          exhibitionId={this.state.exhibitionId}
          companyId={this.state.company.id}
          hasFreeGiftOption={false}
          hasFreeForEntityOption={false}
          backUrl={`/my-company/${this.state.company.id}/exhibition`}
          mapUrl={`/exhibition/${this.state.exhibitionId}/map`}
          triggerUrl={`/my-company/${this.state.company.id}/exhibition/${this.state.exhibitionId}/artwork-trigger`}
          icon={exhibitionIcon}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ExhibitionAdminArtworkList;