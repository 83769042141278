import React from 'react';

import { Modal, Button, Form } from 'react-bootstrap/'

import { translatedMessage } from '../../../../services/language.service';

import Select from 'react-select';
import { toast } from "react-toastify";

class JoinCompanyModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            companies: this.props.companies,
            isAdministrator: false,
            userStatusList: [],
            selectedCompany: null
        }
    }

    handleCompanyChange = (eventKey) => {
        this.setState({
            selectedCompany: eventKey
        })        
    }

    saveChanges = (event) => {
        event.preventDefault();
        var companyId = event.target.elements.companies.value;
        if (companyId) {
            this.props.onHide(companyId, this.state.selectedCompany.label);
        } else {
            toast.error(translatedMessage("COMPANY.SELECT.EMPTY_ERROR"));
        }
    }

    render() {
        var companies = [];
        this.props.companies.forEach(element => {
            var company = {};
            company.value = element.id;
            company.label = element.name

            companies.push(company);
        });

        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {translatedMessage("COMPANY.JOIN")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Group>
                            <Form.Label>
                                {translatedMessage("COMPANY.COMPANY")}
                            </Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="companies"
                                options={companies}
                                onChange={this.handleCompanyChange}
                            />
                        </Form.Group>                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{translatedMessage("COMPANY.JOIN.SEND_REQUEST")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default JoinCompanyModalComponent