import React from "react";

import { Modal,  Form, Button } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';
import ApiService from '../../../services/api.service';
import moment from 'moment';
import { toast } from "react-toastify";

class AddUserAddressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: {},
      isLoaded: false
    };
  }

  componentDidMount() {
    ApiService.getAuthenticatedInstance().get('/countries?size=1000')
      .then(result => {
        const countries = result.data._embedded.countries.map(element => {
          return {
            id: element._links.self.href.split('/')[element._links.self.href.split('/').length - 1],
            name: element.name
          };
        })
        this.setState({
          countries: countries,
          isLoaded: true
        });
      })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const address = event.target.elements.address.value;
    const zip = event.target.elements.zip.value;
    const city = event.target.elements.city.value;
    const countryId = event.target.elements.country.value;

    ApiService.getAuthenticatedInstance().post(`/addresses`, {
      address: address,
      city: city,
      zip: zip,
      country: `/countries/${countryId}`,
      lastUpdatedBy: ApiService.getCurrentUser().username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
      .then(response => {
        const parts = response.data._links.self.href.split('/');
        const addressId = parts[parts.length - 1];
        var promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().patch(`/profiles/${ApiService.getCurrentProfile().id}`, {
            address: `/addresses/${addressId}`,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then(() => resolve())
            .catch(err => reject(err))
        })

        promise.then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.props.onHide(true);
        })
          .catch(err => {
            console.log("An error has ocurred: " + err);
            toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          })
      })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <Modal
          {...this.props}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
              {translatedMessage("PROFILE.ADDRESS")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
            <Form className="ca-form" id="addressForm" onSubmit={this.handleSubmit}>
              <Form.Group controlId="address">
                <Form.Label>{translatedMessage("PROFILE.ADDRESS")} *</Form.Label>
                <Form.Control
                  required
                  placeholder={translatedMessage("PROFILE.ADDRESS_PLACEHOLDER")}
                />
              </Form.Group>
              <Form.Group controlId="zip">
                <Form.Label>{translatedMessage("PROFILE.ZIP")} *</Form.Label>
                <Form.Control
                  required
                  placeholder={translatedMessage("PROFILE.ZIP_PLACEHOLDER")}
                />
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label>{translatedMessage("PROFILE.CITY")} *</Form.Label>
                <Form.Control
                  required
                  placeholder={translatedMessage("PROFILE.CITY_PLACEHOLDER")}
                />
              </Form.Group>
              <Form.Group controlId="country">
                <Form.Label>{translatedMessage("PROFILE.COUNTRY")} *</Form.Label>
                <Form.Control
                  required
                  as="select"
                  defaultValue={
                    this.state.countries.length > 0 ? this.state.countries[163].id : 1
                  }
                >
                  {this.state.countries.map((country, index) => (
                    <option key={index} value={country.id}>{country.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="ca-button mr-1" type="submit" form="addressForm">
              {translatedMessage("GENERAL.SAVE_CHANGES")}
            </Button>
            <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide(false)}>
              {translatedMessage("GENERAL.CLOSE")}
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }
  }
}

export default AddUserAddressModal;