import React from 'react';

import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VirtualGalleryService from '../../../services/virtualGallery.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
        <Card className="ca-gallery-card">
          <Link to={`/virtual-gallery/${this.props.id}`} title={translatedMessage("GENERAL.VIEW")}>
            <Card.Img variant="top" src={this.props.cardImage} />
          </Link>
          <Card.Body>
            <Card.Title className="d-flex flex-column justify-content-center align-items-center mb-1">
              {this.props.cardTitle}
              <span className="font-weight-normal">{translatedMessage("GENERAL.BY").toLowerCase() + " " + (this.props.entity.author === null ? "Connected.ART" : this.props.entity.author.name)}</span>
            </Card.Title>
            <Card.Text>
              <b>{translatedMessage("GENERAL.TYPE")}: </b>
              {translatedMessage(this.props.entity.type.label)}
            </Card.Text>
            <footer className="ca-gallery-card-footer">
              {this.props.entity.type.name === VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY &&
                <Link className="ca-dark-link ml-2" to={`/virtual-gallery-artwork/${this.props.id}`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                  <FontAwesomeIcon icon={["far", "images"]} />
                </Link>
              }
              {this.props.entity.type.name !== VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY &&
                <div>&nbsp;</div>
              }
            </footer>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default CardComponent;