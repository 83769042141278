import React from 'react'

import LanguageService from '../../../services/language.service'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class PrivacyPolicyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="ca-page-padding ca-main-container ca-terms">
                <div className="ca-page-title w-100 mb-2">{window.$translate(LanguageService.getCurrentLanguage(), "TERMS.PRIVACY")}</div>
                <Row>
                    <Col className="pl-0">
                        <p className="ca-terms-paragraph text-justify">
                            connected.art and associated domains and pages is owned by Connected.ART AS, 
                            a Norwegian limited company. This privacy policy will explain how our organization uses 
                            the personal data we collect from you when you use our website, web and mobile applications.
                        </p>
                        <ol className="ca-terms-ol">
                            <li>
                                <h2>What data do we collect?</h2>
                                <p className="ca-terms-paragraph text-justify">
                                    Connected.ART collects the following data:
                                    <ul>
                                        <li>Personal identification information (Name, email address, phone number, etc.)</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <h2>How do we collect your data?</h2>
                                <p className="ca-terms-paragraph text-justify">
                                You directly provide Connected.ART with most of the data we collect. 
                                We collect data and process data when you:
                                <ul>
                                    <li>Register online or place an order for any of our products or services.</li>
                                    <li>Voluntarily complete a customer survey, register for our newsletter or provide feedback on any of our message boards or via email.</li>
                                    <li>Use or view our website via your browser's cookies.</li>
                                </ul>
                                </p>
                            </li>                            
                        </ol>                            
                    </Col>
                </Row>
            </div>
        )
    }

}

export default PrivacyPolicyPage;