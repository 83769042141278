import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import caMapPin from '../../../components/shared/Resources/map/ar_art.png';

import moment from 'moment';

import { Map, GoogleApiWrapper } from 'google-maps-react';

import ApiService from "../../../services/api.service";
import MapService from "../../../services/map.service";
import LanguageService from '../../../services/language.service';

class EditGeofenceNameModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            geofence: []
        }

    }

    onMapReady = (props, map) => {
        var position = this.props.geofence.markerPosition;
        var radius = this.props.geofence.circleRadius;
        var name = this.props.geofence.name;
        var geofenceId = this.props.geofence.id;

        var marker = new props.google.maps.Marker();
        var fenceCircle = MapService.getInitFenceCircle(props, map, true, true, false);
        var infoWindow = new props.google.maps.InfoWindow({
            content: window.$translate(LanguageService.getCurrentLanguage(), "MAP.GEOFENCE.INFO_EDIT")
        });

        const pinIcon = {
            url: caMapPin,
            scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height),
        };

        marker.setPosition(position);
        marker.setMap(map);
        marker.setTitle(name);
        geofenceId === 0 ? marker.setIcon(pinIcon) : marker.setIcon(pinIcon);

        fenceCircle.setCenter(position);
        fenceCircle.setRadius(radius);

        infoWindow.setPosition({ lat: position.lat + 0.005, lng: position.lng });
        infoWindow.open(map);

        map.setCenter(position);

        this.setState({
            geofence: {
                markerPosition: marker.position,
                circleRadius: radius,
                name: name,
                id: geofenceId
            }
        })

        map.addListener('click', (e) => {
            infoWindow.close();

            if (marker.map !== null) {
                marker.setMap(null);
            }

            marker.setMap(map);
            marker.setPosition(e.latLng);

            fenceCircle.setCenter(e.latLng);

            this.setState({
                geofence: {
                    ...this.state.geofence,
                    markerPosition: marker.position,
                }
            })
        });

        fenceCircle.addListener('radius_changed', () => {
            infoWindow.close();

            this.setState({
                geofence: {
                    ...this.state.geofence,
                    circleRadius: fenceCircle.radius,
                }
            })
        })
    }

    saveChanges = (event) => {
        event.preventDefault();
        const geofenceName = event.target.elements.formGeofenceName.value;
        const geofence = this.state.geofence;

        if (geofence.id === 0) {
            ApiService.getAuthenticatedInstance().post(`/geofences`, {
                name: geofenceName,
                locationLat: geofence.markerPosition.lat(),
                locationLng: geofence.markerPosition.lng(),
                radius: geofence.circleRadius,
                owner: `/users/${ApiService.getCurrentUser().id}`,
                lastUpdatedBy: ApiService.getCurrentUser().username,
                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            }).then(result => {
                this.props.onHide(true);
            });
        } else {
            ApiService.getAuthenticatedInstance().patch(`/geofences/${geofence.id}`, {
                name: geofenceName,
                locationLat: geofence.markerPosition.lat(),
                locationLng: geofence.markerPosition.lng(),
                radius: geofence.circleRadius,
                lastUpdatedBy: ApiService.getCurrentUser().username,
                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            }).then(result => {
                this.props.onHide(true);
            });
        }

    };

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="ca-map-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {window.$translate(LanguageService.getCurrentLanguage(), "MAP.GEOFENCE.EDIT")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Group controlId="formGeofenceName">
                            <Form.Label className="editmodal-userinfo-title">{window.$translate(LanguageService.getCurrentLanguage(), "MAP.GEOFENCE.NAME")} *</Form.Label>
                            <Form.Control
                                name="geofenceName"
                                placeholder={window.$translate(LanguageService.getCurrentLanguage(), "MAP.GEOFENCE.NAME_PLACEHOLDER")}
                                defaultValue={this.props.geofence.name}
                                required />
                        </Form.Group>
                    </Form>

                    <Map google={this.props.google} zoom={14} initialCenter={this.props.geofence.markerPosition} streetViewControl={false}
                        onReady={this.onMapReady} containerStyle={{ width: "calc(100% - 32px)", height: "calc(100% - 100px)" }} >
                    </Map>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.SAVE_CHANGES")}</Button>
                    <Button className="ca-button ca-button-white" variant="dark" onClick={() => this.props.onHide(false)}>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.CLOSE")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(EditGeofenceNameModal);