import React from 'react';

import calogo from '../../../../components/shared/Resources/defaultGalleryPicture.png';

import { Card, Form, Col, Dropdown, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Switch from "react-switch";

import { translatedMessage } from '../../../../services/language.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <Card className="ca-membership-card">
        <Card.Body>
          <Card.Header>
            <Card.Img variant="top" src={calogo} />
          </Card.Header>
          <Card.Title>
            {this.props.data.membership.name.toUpperCase()}
          </Card.Title>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="formHasAccess">
              <Form.Label>
                {translatedMessage("GALLERY.HAS_ACCESS")}
                <OverlayTrigger
                  key='hA'
                  placement='right'
                  overlay={
                    <Tooltip id={`tooltip-hA`}>
                      {translatedMessage("GALLERY.HAS_ACCESS.TOOLTIP")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>
              </Form.Label>
              <br />
              <Switch
                onChange={(eventKey) => { this.props.onSwitchEnable(this.props.data.id, eventKey) }}
                checked={this.props.data.hasAccess}
                offColor="#F00"
                id="hasAccessSwithc"
              />
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formAccessType">
              <Form.Label>
                {translatedMessage("GALLERY.ACCESS_TYPE")}
                <OverlayTrigger
                  key='hAT'
                  placement='right'
                  overlay={
                    <Tooltip id={`tooltip-hAT`}>
                      {translatedMessage("GALLERY.ACCESS_TYPE.TOOLTIP")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>
              </Form.Label>
              <Dropdown className="ca-dropdown"
                onSelect={(eventKey, event) => this.props.onTypeSelect(event.target.id, eventKey)}>
                <Dropdown.Toggle>
                  {translatedMessage(this.props.data.accessType.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.props.data.acessTypeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name} id={this.props.data.id}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formAccessQauntity">
              <Form.Label>
                {translatedMessage("GALLERY.ACCESS_QUANTITY")}
                <OverlayTrigger
                  key='hAQ'
                  placement='right'
                  overlay={
                    <Tooltip id={`tooltip-hAQ`}>
                      {translatedMessage("GALLERY.ACCESS_QUANTITY.TOOLTIP")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                step="1"
                value={this.props.data.accessQuantity}
                onChange={event => this.props.onQuantityInput(event.target.name, event.target.value)}
                disabled={this.props.data.qunatityDisabled}
                name={this.props.data.id}
              />
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="formAccessFee">
              <Form.Label>
                {translatedMessage("GALLERY.ACCESS_FEE")}
                <OverlayTrigger
                  key='hAF'
                  placement='right'
                  overlay={
                    <Tooltip id={`tooltip-hAF`}>
                      {translatedMessage("GALLERY.ACCESS_FEE.TOOLTIP")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>
              </Form.Label>
              <InputGroup className="ca-form-input-group">
                <Form.Control
                  type="number"
                  value={this.props.data.accessFee}
                  onChange={event => this.props.onFeeInput(event.target.name, event.target.value)}
                  name={this.props.data.id}
                />
                <InputGroup.Append style={{ marginBottom: "5px" }}>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </Card.Body>
      </Card>
    )
  }

}

export default CardComponent;