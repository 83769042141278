import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';

import caLogo from '../../components/shared/Resources/defaultGalleryPicture.png';
import backArrow from '../../components/shared/Resources/left-arrow.svg';
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

class ArExperienceViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id : null,
            arExperience: [],
            imageUrl: "",
            isLoaded: false,
            idError: false
        };
    }

    componentDidMount = () => { this.getData() }

    async getData() {
        let isError = false;
        let errorMessage = "AR_EXPERIENCE.GENERAL_ERROR";

        if (this.state.id && parseInt(this.state.id)) {
            let arExperience = await ApiService.getAuthenticatedInstance().get(`arExperience/arExperienceDetail/${this.state.id}`)
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    isError = true;
                    if (err && err.response && err.response.status && 404 === err.response.status) {
                        errorMessage = err.response.data.message;
                    }
                })

            if (arExperience && !isError) {
                this.setState({
                    arExperience: arExperience,
                    imageUrl: arExperience.photoUuid ? ApiService.getBaseUrl() + `/storage/file/${arExperience.photoUuid}` : caLogo,
                    isLoaded: true
                })
            } else {
                this.setState({
                    isError: true,
                    errorMessage: errorMessage,
                    isLoaded: true
                })
            }
        } else {
            this.setState({
                isError: true,
                errorMessage: errorMessage,
                isLoaded: true
            })
        }

    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                const arExperience = this.state.arExperience;
                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-5 pr-2">
                            <div>
                                <span className="ca-page-title font-bold">{arExperience.name}</span>
                                {this.state.arExperience.author &&
                                    <span className="ca-page-title">{" " + translatedMessage("GENERAL.BY").toLowerCase() + " " + arExperience.author}</span>
                                }
                                <br />
                                <span className="ca-page-subtitle">{translatedMessage("AR_EXPERIENCE")}</span>
                            </div>

                            <div>
                                <Image className="ca-page-title-icon ml-2 mr-0" src={backArrow} alt={translatedMessage("BUTTON.BACK")}
                                    title={translatedMessage("BUTTON.BACK")} onClick={() => this.props.history.go(-1)} style={{ cursor: "pointer" }} />
                            </div>
                        </Row>

                        <Row>
                            <Col className="col-12 col-md-6 ca-row-left-column mb-3">
                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("GENERAL.DESCRIPTION")}
                                    </div>
                                    <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: arExperience.description }} />
                                </div>

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("PROJECT.CATEGORY")}
                                    </div>
                                    <div className="ca-view-value">{translatedMessage(arExperience.category)}</div>
                                </div>

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                                    </div>
                                    <div className="ca-view-value">{arExperience.sizeWidth} W x {arExperience.sizeHeight} H cm</div>
                                </div>
                            </Col>
                            <Col className="col-12 col-md-6 ca-row-right-column">
                                <div className="ca-image-container">
                                    <Image src={this.state.imageUrl} />
                                </div>
                            </Col>
                        </Row>
                    </div >
                )
            }
    }

}

export default ArExperienceViewPage;