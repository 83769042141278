import React from 'react';

import { Row } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';
import ParkListComponent from '../../../components/shared/Park/ParkListComponent';

class ParkAdminListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <ParkListComponent
            history={this.props.history}
            entityUrl="parkAdmin"
            isAdmin={true}
          />
        </>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }
}

export default ParkAdminListPage;