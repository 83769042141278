import React from 'react';

import CardComponent from "./GalleryArtworkCardComponent";
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import ArtworkViewModalComponent from '../Artwork/ArtworkViewModalComponent';

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import mapIcon from '../../../components/shared/Resources/icons/map.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../components/shared/Resources/icons/cancel.svg';
import artworkIcon from '../../../components/shared/Resources/icons/artwork.svg';

import { toast } from "react-toastify";

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';

const ENTER_KEY = 13;

class GalleryManageArtworkComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: this.props.galleryId,
      gallery: null,
      searchInput: '',
      artworks: [],
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      artworkCategoryList: [],
      filteredArtworks: [],
      authors: [],
      existingArtworkList: [],
      isLoaded: false,
      isAddArtwork: false,
      artworkCanHaveLocation: false,
      isError: false,
      errorMessage: "",
      showArtworkModal: false,
      modalData: {
        art: {}
      }
    };

    this.addArtwork = this.addArtwork.bind(this);
  }

  componentDidMount = () => {
    if (this.props.canEdit) {
      ApiService.getAuthenticatedInstance().get(`gallery/getGalleryDetail/${this.props.galleryId}`)
        .then(response => {
          this.setState({
            gallery: response.data
          }, this.getArtworks)
        })
        .catch(err => {
          var errorMessage = "GENERAL.GET_DATA_ERROR";
          if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          });
        });
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  getArtworks = () => {
    let artworkCategoryList = [];
    let artworks = [];
    let promise = [];
    let artworkCanHaveLocation = false;
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ArtworkService.getGalleryArtwork(this.state.gallery.id)
      .then(response => {
        artworks = response
      })
      .then(() => {
        promise.push(ArtworkService.getArtworkCategoryList()
          .then(response => {
            artworkCategoryList = response;
            artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
          }))

        promise.push(ApiService.getAuthenticatedInstance().get(`gallery/${this.state.gallery.id}/locations`)
          .then(response => {
            const locations = response.data;
            if (locations.length === 1) {
              if (locations[0].locationLat !== 0 && locations[0].locationLng !== 0) {
                artworkCanHaveLocation = true;
              }
            }
          })
        )

        Promise.all(promise).then(() => {
          this.setState({
            artworks: artworks,
            filteredArtworks: artworks,
            artworkCategoryList: artworkCategoryList,
            artworkCanHaveLocation: artworkCanHaveLocation,
            isLoaded: true
          })
        })
          .catch(err => {
            if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
              errorMessage = err.response.data.message;
            }
            console.log("An error has ocurred: " + err);
            this.setState({
              isError: true,
              errorMessage: errorMessage,
              isLoaded: true
            });
          });
      }).catch(err => {
        if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.artwork.name.toLowerCase().includes(searchInput.toLowerCase())
        || elem.author.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.artwork.category.id.toString() === categoryItem.id.toString());
    }

    this.setState({
      filteredArtworks: result
    })
  }

  async addArtwork() {
    let artworks = [];
    if (this.props.isAdmin) {
      artworks = await ArtworkService.getArtworksByCriteria(false, ArtworkService.artworkStatusEnum().PUBLISHED)
        .then(response => { return response });
    } else {
      artworks = await ArtworkService.getArtworksForCompany(this.props.companyId)
        .then(response => { return response });
    }

    var art = []
    var existingArtworkList = [];
    artworks.map((element, index) => {
      const existingArt = this.state.artworks.filter(item => item.artwork.id === element.id);
      if (existingArt.length > 0) {
        existingArtworkList.push(existingArt[0].artwork.id.toString());
      }
      art.push({
        "artwork": element,
        "gallery": this.state.gallery,
        "author": element.author,
        "image": element.image,
        "existingArt": existingArt.length > 0 ? true : false,
        "id": element.id
      })
      return art;
    })

    this.setState({
      artworks: art,
      filteredArtworks: art,
      isAddArtwork: true,
      existingArtworkList: existingArtworkList
    });
  }

  handleCheckChange = (id, isChecked) => {
    let existingArtworkList = this.state.existingArtworkList;
    if (isChecked) {
      existingArtworkList.push(id)
    } else {
      let index = existingArtworkList.indexOf(id);
      if (index !== -1) {
        existingArtworkList.splice(index, 1);
      }
    }

    this.setState({
      existingArtworkList: existingArtworkList
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    ArtworkService.addArtworkToGallery(this.state.galleryId, this.state.existingArtworkList)
      .then(response => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          isAddArtwork: false,
        }, this.getArtworks)
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        this.setState({
          isAddArtwork: false,
        }, this.getArtworks)
      })

  }

  cancelAdd = () => {
    this.setState({
      isAddArtwork: false,
    }, this.getArtworks)
  }  

  onShowModal = (artId, entityName) => {
    if (entityName === ApiService.getEntityName().ARTWORK) {
      ApiService.getAuthenticatedInstance().get(`/artwork/artworkDetail/${artId}`)
        .then(response => {
          this.setState({
            modalData: response.data.artwork,
            showArtworkModal: true
          })
        })
        .catch(err => {
          let errorMessage = "GENERAL.GET_DATA_ERROR";

          console.log(err)
          if (err && err.response && err.response.status === 404) {
            errorMessage = err.response.data.message;
          }

          toast.error(translatedMessage(errorMessage));
        });
    }
  }

  onHideModal = () => {
    this.setState({
      showArtworkModal: false,
    })
  }  

  render() {
    const artworks = this.state.filteredArtworks;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={this.props.icon} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERY")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.gallery.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("GENERAL.ARTWORKS.MANAGE")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={this.addArtwork} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={artworkIcon} alt={translatedMessage("ARTWORK.MANAGE")} title={translatedMessage("ARTWORK.MANAGE")} />
              </Button>
              <Button type="submit" form="artworkForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button onClick={this.cancelAdd} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
              </Button>              
              {this.props.isAdmin &&
                <Button onClick={() => this.props.history.push(this.props.mapUrl)} className="ca-dark-link ca-button-icon medium mr-1">
                  <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} title={translatedMessage("MAP.VIEW_ON_MAP")} />
                </Button>
              }
              <Button onClick={() => this.props.history.push(this.props.backUrl)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>

            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Form id="artworkForm" className="ca-form" onSubmit={this.handleSubmit}>
            <div className="card-columns">
              {artworks.map((element, index) => {
                return (
                  <CardComponent
                    key={element.id}
                    id={element.artwork.id}
                    entity={ {entityName: ApiService.getEntityName().ARTWORK} }
                    galleryArtworkId={element.id}
                    image={element.image}
                    title={element.artwork.name}
                    author={element.author}
                    category={element.artwork.category.code}
                    link={`/artwork/${element.artworkId}`}
                    isAdd={this.state.isAddArtwork}
                    existingArt={!this.state.isAddArtwork ? true : element.existingArt}
                    onCheck={this.handleCheckChange}
                    artworkCanHaveLocation={this.state.artworkCanHaveLocation}
                    locationRedirectUrl={`/gallery/artwork/${element.id}/location`}
                    selectText={"ARTWORK.SELECT"}
                    onClick={this.onShowModal}
                  />
                )
              })}
            </div>
          </Form>

          <ArtworkViewModalComponent
            show={this.state.showArtworkModal}            
            onHide={this.onHideModal}
            art={this.state.modalData}
          />          
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default GalleryManageArtworkComponent;
