import React from "react";

import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap';

import { translatedMessage } from '../../../../../services/language.service';
import exhibitionIcon from '../../../../../components/shared/Resources/homepage/exhibition.svg';
import editIcon from '../../../../../components/shared/Resources/icons/edit.svg';
import mapPinIcon from '../../../../../components/shared/Resources/icons/map_pin.svg';
import teamIcon from '../../../../../components/shared/Resources/icons/company/team.svg';


import './TableComponent.css';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      pageLength: 10,
      itemsPerPage: 10,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: translatedMessage("TABLE.PAGE"),
      ofText: translatedMessage("TABLE.OF"),
      rowsText: translatedMessage("TABLE.PAGE"),
      filtered: this.props.data,
      detailsModalVisible: false,
      invoice: {}
    };
  }

  onFilteredChange = (value, accessor) => {
    let data = this.state.data;

    let filtered = data.filter(item => item[accessor].toLowerCase().includes(value.toLowerCase()))

    this.setState({ filtered: filtered });
  }

  onShowModal = (row) => {
    this.setState({
      invoice: row.original,
      detailsModalVisible: true
    })
  }

  onHideModal = () => {
    this.setState({
      detailsModalVisible: false
    });
  }

  render() {
    const data = this.state.filtered;
    return (
      <div className="ca-table-company" style={{ marginBottom: "100px" }}>
        <ReactTable
          data={data}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBotton={true}
          showPageSizeOptions={false}
          pageSize={this.state.itemsPerPage}
          multiSort={false}
          sortable={true}
          resizable={true}
          filterable={true}
          defaultPageSize={this.state.itemsPerPage}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          minRows={1}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
          }}
          columns={[
            {
              Header: translatedMessage("TABLE.HEADER.NAME"),
              accessor: "name",
              style: { 'whiteSpace': 'unset' },
            },
            {
              Header: translatedMessage("COMPANY.SPACE.NAME"),
              accessor: "spaceName",
              style: { 'whiteSpace': 'unset' },
            },            
            {
              Header: translatedMessage("COMPANY.WEBSITE"),
              accessor: "website"
            },
            {
              Header: translatedMessage("COMPANY.TYPE"),
              accessor: "type.label",
              style: { 'whiteSpace': 'unset' },
              Cell: row => {
                return (translatedMessage(row.original.type.label))
              },
            },
            {
              Header: translatedMessage("COMPANY.STATUS"),
              accessor: "status.label",
              style: { 'whiteSpace': 'unset' },
              Cell: row => {
                return (translatedMessage(row.original.status.label))
              },
            },
            {
              Cell: row => {
                return (
                  <>
                    <Link className="ca-dark-link small mr-1" to={`/company-admin/${row.original.id}/edit`} title={translatedMessage("GENERAL.EDIT")}>
                      <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")}/>
                    </Link>
                    <Link className="ca-dark-link small  mr-1" to={`/company-admin/${row.original.id}/user`} title={translatedMessage("USER.USERS")}>
                      <Image src={teamIcon} alt={translatedMessage("USER.USERS")}/>
                    </Link>
                    <Link className="ca-dark-link small mr-1" to={`/company-admin/${row.original.id}/exhibition`} title={translatedMessage("EXHIBITION.EXHIBITIONS")}>
                      <Image src={exhibitionIcon} alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
                    </Link>  
                    <Link className="ca-dark-link small" to={`/company-admin/${row.original.id}/address`} title={translatedMessage("ADDRESS.ADDRESSES")}>
                      <Image src={mapPinIcon} alt={translatedMessage("ADDRESS.ADDRESSES")}/>
                    </Link>                    
                  </>
                )
              },
              sortable: false,
              filterable: false
            },
          ]}
        />
      </div>
    );
  }
}

export default TableComponent
