import React from 'react';

import ParkListComponent from '../../components/shared/Park/ParkListComponent';

class ParkListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      allList: [],
      filteredList: [],
      isLoaded: false,
      canViewList: false,
    };
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <ParkListComponent history={this.props.history} entityUrl="park" isAdmin={false}/>
    )
  }
}

export default ParkListPage;