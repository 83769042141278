import React from 'react';

import WebResourcesTableComponent from './Components/WebResourcesTableComponent'
import CreateWebResourceComponent from './Components/CreateWebResourceComponent'
import EditWebResourceComponent from './Components/EditWebResourceComponent'
import DeleteWebResourceComponent from './Components/DeleteWebResourceComponent'

import { translatedMessage } from '../../../services/language.service'
import ApiService from '../../../services/api.service';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'
import UserService from '../../../services/user.service';

class WebResourcesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            createModalShow: false,
            editModalShow: false,
            deleteModalShow: false,
            profileLink: {
                id: '',
                name: '',
                uri: ''
            }
        };

        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() { this.getData() }

    async getData() {
        let profile = ApiService.getCurrentProfile();
        if (!profile) {
            profile = await UserService.getMyProfile().then(response => { return response.data.profile });
        }

        this.setState({
            resources: profile.profileLinks,
        })
    }

    async refreshData() {
        let profile = await UserService.getMyProfile().then(response => { return response.data.profile });
        this.setState({
            resources: profile.profileLinks,
        })
    }

    onShowOrHideCreate = () => {
        if (this.state.createModalShow === false) {
            this.setState({ createModalShow: true });
            return;
        }
        this.setState({ createModalShow: false }, this.refreshData);
    }

    onShowOrHideEdit = (id) => {
        if (this.state.editModalShow === false) {
            let profileLink = this.state.resources.filter(item => item.id === id);

            this.setState({ profileLink: profileLink[0] }, () => {
                this.setState({ editModalShow: true })
            });

            return;
        }
        this.setState({ editModalShow: false }, this.refreshData);
    }

    onShowOrHideDelete = (id) => {
        if (this.state.deleteModalShow === false) {
            let profileLink = this.state.resources.filter(item => item.id === id);

            this.setState({ profileLink: profileLink[0] }, () => {
                this.setState({ deleteModalShow: true }, () => {
                })
            });

            return;
        }
        this.setState({ deleteModalShow: false }, this.refreshData);
    }


    render() {
        return (
            <>
                <div className="ca-page-padding ca-main-container">
                    <div className="ca-page-subtitle w-100">{translatedMessage("WEB_RESOURCE.WEB_RESOURCES")}</div>
                    <Row className="mb-1">
                        <Col className="text-right pr-0">
                            <Button className="ca-button" variant="dark" onClick={this.onShowOrHideCreate}>
                                <span>{translatedMessage("WEB_RESOURCE.ADD_RESOURCE")}</span>
                            </Button>
                        </Col>
                    </Row>

                    <CreateWebResourceComponent
                        show={this.state.createModalShow}
                        onHide={this.onShowOrHideCreate}
                    />

                    <EditWebResourceComponent
                        show={this.state.editModalShow}
                        onHide={this.onShowOrHideEdit}
                        profilelink={this.state.profileLink}
                    />

                    <DeleteWebResourceComponent
                        show={this.state.deleteModalShow}
                        onHide={this.onShowOrHideDelete}
                        profilelink={this.state.profileLink}
                    />

                    <WebResourcesTableComponent
                        data={this.state.resources}
                        editModal={this.onShowOrHideEdit}
                        deleteModal={this.onShowOrHideDelete}
                    />
                </div>
            </>
        )
    }

}

export default WebResourcesPage;