import React from 'react'
import './TableComponent.css';

import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

import { translatedMessage } from '../../../../services/language.service';

class LocationTableComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      paging: {
        itemsPerPage: 5,
      },
      totalPages: -1,
      loading: this.props.loading,
      pages: this.props.pages,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: translatedMessage("TABLE.PAGE"),
      ofText: translatedMessage("TABLE.OF"),
      rowsText: translatedMessage("TABLE.PAGE")
    };

  }

  render() {    
    return (
      <div className="ca-table-geofences">
        <ReactTable          
          data={this.props.data}
          defaultPageSize={this.state.paging.itemsPerPage}
          showPageJump={false}
          filterable={true}
          showPageSizeOptions={false}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          minRows={1}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.rowselect(rowInfo.row._original);
                }
              }
            } else {
              return {}
            }
          }}
          columns={[
            {
              Header: translatedMessage("TABLE.HEADER.NAME"),
              accessor: 'name',
            },
            {
              id: 'location',
              Header: translatedMessage("MAP.DETAILS"),
              accessor: location =>
                <span>
                  <b>{translatedMessage("MAP.LATITUDE.SHORT")}</b> {': ' + location.locationLat}
                  <br />
                  <b>{translatedMessage("MAP.LONGITUDE.SHORT")}</b> {': ' + location.locationLng}
                  <br />
                  <b>{translatedMessage("MAP.GEOFENCE")}</b> {': ' + translatedMessage(location.geofence ? "GENERAL.YES" : "GENERAL.NO")}            
                </span>,
              sortable: false,
              filterable: false
            },
            {
              Header: '',
              Cell: row => (
                <span style={{ fontFamily: "FontAwesome", marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => this.props.show(row.original.id)}>
                  &#xF044;
                </span>
              ),
              sortable: false,
              filterable: false
            }
          ]}       
        />
      </div>
    )
  }

}

export default LocationTableComponent