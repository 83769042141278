import React from 'react';

import VirtualGalleryViewComponent from '../../components/shared/VirtualGallery/VirtualGalleryViewComponent';

class PortfolioVirtualGalleryViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {}

  render() {
      return (
        <VirtualGalleryViewComponent
          virtualGalleryId={this.props.match.params.id}
          canView={true}
          isAuthor={true}
          backLink={`/artworks`}
          editLink={`/portfolio-virtual-gallery-edit/${this.props.match.params.id}`}
          history={this.props.history}
        />
      )
  }

}

export default PortfolioVirtualGalleryViewPage;