import React from 'react';

import { Image } from 'react-bootstrap';

import { translatedMessage } from '../../../../services/language.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  onCardClick(id, category, position, entityName) {
    if(this.props.entityListType === entityName){
      this.props.onClick(id, category, position)
    }
  }

  render() {
    let entityName = this.props.id !== 0 ? this.props.entity.entity.entityName : this.props.entityListType;
    return (
      <>
          <div className="ca-image-card-container" onClick={() => this.onCardClick(this.props.id, this.props.entity.category, this.props.entity.position, entityName)}
            style={{width: "100%", textAlign: "center"}}>
            <Image src={this.props.cardImage}/>
            <div className="ca-image-card-container-hover">
              {this.props.id !== 0
                ? (this.props.entity.entity.entityName === this.props.entityListType 
                    ? 
                      <>
                      <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf040;</div>
                      <div>{translatedMessage("GENERAL.CHANGE") + " " + translatedMessage(this.props.entity.entity.entityName).toLowerCase()}</div>
                      </>
                    : <div>{translatedMessage("GENERAL.CHANGE") + " " + translatedMessage(this.props.entity.entity.entityName).toLowerCase()}</div>
                  )
                : 
                  <>
                    <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf067;</div>
                    <div>{translatedMessage("GENERAL.ADD") + " " + translatedMessage(this.props.entityListType).toLowerCase()}</div>
                  </>
              }
            </div>
            <div style={{ position: "absolute", bottom: "5px", width: "100%", textAlign: "center" }}>
              <span style={{ padding: "5px 5px", backgroundColor: "#333333", color: "#333333", opacity: "0.6" }}>
                <span style={{ fontSize: "16px" }}>
                  {this.props.id !== 0 
                    ? this.props.entity.name + " - " + translatedMessage(this.props.entity.entity.entityName).toLowerCase() 
                    : translatedMessage(this.props.entity.category)}
                </span>
                <br />
                {translatedMessage("VIRTUAL_GALLERY.ARTWORK_POSITION")}: {this.props.entity.position}
              </span>
              <div style={{ position: "absolute", color: "#ffffff", top: "0", width: "100%", textAlign: "center" }}>
                <span style={{ fontSize: "16px" }}>
                  {this.props.id !== 0 
                    ? this.props.entity.name + " - " + translatedMessage(this.props.entity.entity.entityName).toLowerCase() 
                    : translatedMessage(this.props.entity.category)}
                </span>
                <br />
                {translatedMessage("VIRTUAL_GALLERY.ARTWORK_POSITION")}: {this.props.entity.position}
              </div>
            </div>
          </div>      
      </>
    )
  }
}

export default CardComponent;