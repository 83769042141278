import React from 'react';

import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';
import ParkService from '../../../services/park.service';

class ParkAdminLocationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.match.params.id,
      park: {},
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      initialCenter: null,
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canViewList: hasPermission("PARK_LIST"),
        canEdit: hasPermission("PARK_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    var park = {};
    var location = {};
    var promise = [];
    var pins = [];

    if (this.state.canEdit || this.state.canViewList) {
      ParkService.getParkForManage(this.state.parkId)
        .then(response => {
          console.log(response)
          park = response;
        })
        .then(() => {
          var initialCenter = {};

          promise.push(ParkService.getParkLocation(this.state.parkId)
            .then(response => {
              location = response;
              if (location) {
                initialCenter = {
                  lat: location.locationLat,
                  lng: location.locationLng,
                }
              } else {
                initialCenter = null
              }              
            })
          )

          promise.push(MapService.getLocationPinByType("PARK")
            .then(response => {
              pins = response;
            }))

          Promise.all(promise).then(() => {
            console.log(park)
            this.setState({
              park: park,
              location: location ? location : this.state.location,
              hasGeofence: location && location.geofence ? true : false,
              pinIcon: pins[0] ? pins[0].imageUrl : null,
              initialCenter: initialCenter,
              isLoaded: true
            })
          })
        })
        .catch(err => {
          let errorMessage = "GENERAL.GET_DATA_ERROR";
          if (err && err.response && err.response.status === 404) {
            errorMessage = err.response.data.message;
          }
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      const backUrl = this.state.park?.parentPark?.id ? `/parkAdmin/${this.state.park.parentPark.id}/view` : `/parkAdmin`

      return (
        <>
          <EntityLocation
            entityId={this.state.park.id}
            entityName={ApiService.getEntityName().PARK}
            mapLocations={[]}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={null}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.park.name}
            parentEntityTitle={null}
            backLink={backUrl}
            history={this.props.history}
            zoomLevel={14}
          />         
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ParkAdminLocationPage;