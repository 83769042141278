import React from 'react';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';

import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import ParkService from '../../../services/park.service';

class ParkVirtualGalleryManageLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkArtId: this.props.match.params.id,
      parkArt: {},
      park: {},
      virtualGallery: {},
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      parkIcon: null,
      mapLocations: [],
      initialCenter: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => { this.getData(); }

  getData = () => {
    let promise = [];

    ParkService.getParkArt(this.state.parkArtId)
      .then(response => {
        this.setState({          
          parkArt: response,
          park: response.park,
          virtualGallery: response.virtualGallery
        }, () => {
          var initialCenter = {};
          promise.push(ParkService.getParkLocation(this.state.park.id)
            .then(response => {
              if (response) {
                initialCenter = {
                  lat: response.locationLat,
                  lng: response.locationLng,
                }
              } else {
                initialCenter = null
              }
            })
          )          

          let artLocation = [];
          let mapLocations = [];
          promise.push(
            ParkService.getParArtkLocationsForManage(this.state.park.id)
              .then(result => {
                mapLocations = result.filter(item => item.entity.entityId !== this.state.virtualGallery.id)
                artLocation = result.filter(item => item.entity.entityId === this.state.virtualGallery.id)
              })
          )

          var parkPin = []
          promise.push(MapService.getLocationPinByType('PARK')
            .then(response => {
              parkPin = response;
            }))

          var pins = [];
          promise.push(MapService.getLocationPinByType(ApiService.getEntityName().VIRTUAL_GALLERY)
            .then(response => {
              pins = response;
            }))

          Promise.all(promise).then(() => {
            this.setState({
              location: artLocation[0] ? artLocation[0] : this.state.location,
              hasGeofence: artLocation[0] && artLocation[0].geofence ? true : false,
              pinIcon: pins[0] ? pins[0].imageUrl : null,
              parkIcon: parkPin[0] ? parkPin[0].imageUrl : null,
              initialCenter: initialCenter,
              mapLocations: mapLocations,
              isLoaded: true
            })
          })
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.parkArtId}
            entityName={ApiService.getEntityName().PARK_ART}
            mapLocations={this.state.mapLocations}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={this.state.parkIcon}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.virtualGallery.name}
            parentEntityTitle={this.state.park.name}
            history={this.props.history}
            zoomLevel={17}
          />
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ParkVirtualGalleryManageLocation;