import React from 'react';

import { Link } from 'react-router-dom';
import { Card, Image } from 'react-bootstrap';

import editIcon from '../../../../components/shared/Resources/icons/edit.svg';
import pricePackageIcon from '../../../../components/shared/Resources/icons/price_package.svg';
import artIcon from '../../../../components/shared/Resources/icons/art.svg';
import mapPinIcon from '../../../../components/shared/Resources/icons/map_pin.svg';
import mediaIcon from '../../../../components/shared/Resources/icons/media.svg';
import arExperienceIcon from '../../../../components/shared/Resources/icons/ar_experience.svg';


import { translatedMessage } from '../../../../services/language.service';
import ApiService from '../../../../services/api.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const isUserGallery = this.props.entity.isUserGallery;
    const defaultUserGallery = this.props.entity.defaultUserGallery;
    return (
      <>
        <Card className="ca-gallery-card">
          <Card.Img variant="top" src={this.props.cardImage} />
          <Card.Body>
            <Card.Title>{this.props.cardTitle}</Card.Title>
            <Card.Text>
              <b>{translatedMessage("AR_PORTAL.AR_PORTAL")}: </b> {this.props.entity.portalName}
            </Card.Text>
            <Card.Text>
              <b>{translatedMessage("GENERAL.TYPE")}: </b>
              {translatedMessage(this.props.entity.type.label)}
              <br />
              <b>{translatedMessage("GENERAL.STATUS")}: </b>
              {translatedMessage(this.props.entity.status.label)}
              <br />
              <b>{translatedMessage("VIRTUAL_GALLERY.IS_USER_GALLERY")}: </b>
              {ApiService.getYesNoConverter(isUserGallery)}
              <br />
              <b>{translatedMessage("VIRTUAL_GALLERY.DEFAULT_USER_GALLERY")}: </b>
              {ApiService.getYesNoConverter(defaultUserGallery)}
            </Card.Text>
            <footer className="ca-gallery-card-footer">
              {this.props.canEdit &&
                <Link className="ca-dark-link small" to={`/virtualGalleryEdit/${this.props.id}`} title={translatedMessage("GENERAL.EDIT")}>
                  <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")}/>
                </Link>
              }
              {this.props.canEdit &&
                <>
                  <Link className="ca-dark-link small ml-1" to={`/virtualGalleryMedia/${this.props.id}`} title={translatedMessage("GENERAL.MEDIA_FILES")}>
                    <Image src={mediaIcon} alt={translatedMessage("GENERAL.MEDIA_FILES")}/>
                  </Link>
                  {this.props.entity.needsArtNo && !defaultUserGallery &&
                    <>
                      <Link className="ca-dark-link small ml-1" to={`/virtualGalleryArtworks/${this.props.id}`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                        <Image src={artIcon} alt={translatedMessage("GENERAL.ARTWORKS.MANAGE")}/>
                      </Link>
                      <Link className="ca-dark-link small ml-1" to={`/virtualGalleryArExperiences/${this.props.id}`} title={translatedMessage("AR_EXPERIENCE.MANAGE")}>
                        <Image src={arExperienceIcon} alt={translatedMessage("AR_EXPERIENCE.MANAGE")}/>
                      </Link>
                    </>
                  }
                  {!defaultUserGallery &&
                    <>
                      {isUserGallery &&
                        <Link className="ca-dark-link small ml-1" to={`/virtualGalleryPricePackage/${this.props.id}`} title={translatedMessage("ARTWORK.PRICING")}>
                          <Image src={pricePackageIcon} alt={translatedMessage("ARTWORK.PRICING")}/>
                        </Link>
                      }
                      <Link className="ca-dark-link small ml-1" to={`/virtualGalleryLocation/${this.props.id}`} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                        <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")}/>
                      </Link>
                    </>
                  }
                </>
              }
            </footer>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default CardComponent;