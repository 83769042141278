import React from 'react';

import myCollection from '../../components/shared/Resources/homepage/myCollection.svg';
import myVirtualGallery from '../../components/shared/Resources/homepage/myVirtualGallery.svg';
import back from '../../components/shared/Resources/left-arrow.svg';

import { Row, Col, Form, Image, InputGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CardComponent from "./Components/GalleryCardComponent";

import VirtualGalleryService from '../../services/virtualGallery.service';
import { translatedMessage } from '../../services/language.service';

const ENTER_KEY = 13;

class UserVirtualGalleryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      virtualGalleries: [],
      filteredVirtualGalleries: [],
      typeList: [],
      selectedType: { label: "GENERAL_TYPE.ANY", name: "ANY" },
      isLoaded: false,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => { this.getData(); }

  async getData() {
    let virtualGalleries = await VirtualGalleryService.getUserVirtualGalleries()
      .then(response => {
        return response;
      })

    let typeList = await VirtualGalleryService.getVirtualGalleryTypeList()
      .then(response => { return response });

    typeList.splice(0, 0, { label: "GENERAL_TYPE.ANY", name: "ANY" })

    this.setState({
      virtualGalleries: virtualGalleries,
      filteredVirtualGalleries: virtualGalleries,
      typeList: typeList,
      isLoaded: true
    })
  }

  handleTypeSelect = (eventKey) => {
    const selectedItem = this.state.typeList.filter(item => item.name === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  filter = () => {
    let result = this.state.virtualGalleries;
    let searchInput = this.state.searchInput;
    let typeItem = this.state.selectedType.name;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.virtualGalleries.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (typeItem !== 'ANY') {
      result = result.filter(elem => elem.type.name === typeItem);
    }    

    this.setState({
      filteredVirtualGalleries: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={myVirtualGallery} className="ca-page-title-icon" alt={translatedMessage("NVIRTUAL_GALLERY.VIRTUAL_GALLERIES")} />
              <span className="ca-page-title">
                {translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}
              </span>
            </div>
            <div>
              <Link to="/collection" className="ca-dark-link">
                <Image className="ca-page-title-icon" src={myCollection} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")}
                  title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")} />
              </Link>
              <Link to="/collection-home" className="ca-dark-link">
                <Image className="ca-page-title-icon mr-0 ml-2" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>


          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder={translatedMessage("SEARCH.BY.NAME")} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>            
          </Row>

          <Row>
            {this.state.filteredVirtualGalleries.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0 pr-0">
                  <CardComponent
                    cardImage={element.imageURL}
                    cardTitle={element.name}
                    entity={element}
                    id={0 === element.id ? 0 : element.id}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      )
    }
  }
}

export default UserVirtualGalleryPage;