import React from 'react'

import LanguageService from '../../../services/language.service'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Link } from 'react-router-dom'

class TermsSalesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="ca-page-padding ca-main-container ca-terms">
                <div className="ca-page-title w-100 mb-2">{window.$translate(LanguageService.getCurrentLanguage(),"TERMS.TOS")}</div>
                <Row>
                    <Col className="pl-0">
                        <h2>General</h2>            
                        <p className="ca-terms-paragraph text-justify">
                          The Terms of Sale together with our &nbsp;
                            <Link to="/terms-of-use" target="_blank">{window.$translate(LanguageService.getCurrentLanguage(),"TERMS.TOU")}</Link>, &nbsp;
                            <Link href="/privacy" target="_blank">{window.$translate(LanguageService.getCurrentLanguage(),"TERMS.PRIVACY")}</Link> &nbsp;
                          and your order, confirmed by an order confirmation, constitute the total contract basis 
                          for the purchase.
                        </p>
                        <p className="ca-terms-paragraph text-justify">  
                          Where these terms of sale require written requirements, this is fulfilled by the use of e-mail, SMS, letter, or the like.
                        </p>

                        <h2>Parties</h2>
                        <p className="ca-terms-paragraph text-justify">
                          The service provider and seller are Connected.ART (Connected.ART AS) directly or 
                          as an intermediary (sales agent) of art and art experiences as well as related products 
                          and services. All art sold at Connected.ART are sold by Connected.ART as a sales agent 
                          on behalf of artists.  
                        </p>
                        <p className="ca-terms-paragraph text-justify">
                          A second-hand art marketplace will open later and the parties will then both be 
                          consumers under the regulation of Connected.ART’s Terms of Use. 
                        </p>
                        <p className="ca-terms-paragraph text-justify">
                          Contact details about Connected.ART AS are always available on our web-site, 
                          while contact details for Artists can be found by following the link for each 
                          Artist that appears in connection with the item.
                        </p>
                        <p className="ca-terms-paragraph text-justify">  
                          "Seller" is hereinafter referred to as "we", "us", "Seller" or “Connected.ART”
                        </p>
                        <p className="ca-terms-paragraph text-justify">
                          “Buyer” is the person who is listed as the buyer in the order and is hereinafter r
                          eferred to as "you", "your" or "Buyer”.
                        </p>                        
                    </Col>
                </Row>
            </div>
        )
    }

}

export default TermsSalesPage;