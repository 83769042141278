import React from 'react';
import './ViewModalComponent.css';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { translatedMessage } from '../../../../services/language.service'

class ViewModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}

    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.userInfo.fullName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ca-form">
                        <div className="form-group">
                            <label className="form-label">{translatedMessage("USER.USERNAME")}</label>
                            <div className="ca-view-value">{this.props.userInfo.username}</div>
                        </div>

                        <div className="form-group">
                            <label className="form-label">{translatedMessage("MEMBERSHIP.MEMBERSHIP")}</label>
                            <div className="ca-view-value">{this.props.userInfo.membership}</div>
                        </div>

                        <div className="form-group">
                            <label className="form-label">{translatedMessage("USER.ROLES")}</label>
                            <div className="ca-view-value">
                                {this.props.userInfo.roles.length > 0
                                    ? this.props.userInfo.roles.map((item, index) => {
                                        if (index === 0) {
                                            return item;
                                        } else {
                                            return ", " + item;
                                        }
                                    })
                                    : "-"}
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label">{translatedMessage("USER.STATUS")}</label>
                            <div className="ca-view-value">{translatedMessage("USER.STATUS." + this.props.userInfo.status)}</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" className="ca-button" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
                </Modal.Footer>
            </Modal>
        )
    }


}

export default ViewModalComponent