import React from 'react';

import ParkListComponent from '../../../components/shared/Park/ParkListComponent';

class ParkAdminViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.match.params.id,
      isLoaded: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoaded: true })
  }


  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <ParkListComponent
          history={this.props.history}
          parent={{ id: this.state.parkId }}
          entityUrl="parkAdmin"
          isAdmin={true}
        />
      )
    }
  }
}

export default ParkAdminViewPage;