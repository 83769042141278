import React from 'react';

import { Row, Col, Image } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';
import VirtualGalleryService from '../../services/virtualGallery.service';

import CardComponent from "./Components/ArtworkCardComponent";
import ArtworkSelectModal from "./Components/ArtworkSelectModal";
import calogo from '../../components/shared/Resources/logo-dark-small-with-padding.png'
import back from '../../components/shared/Resources/icons/back.svg';
import virtualGalleryIcon from '../../components/shared/Resources/icons/virtualGallery.svg';

import ArtworkService from '../../services/artwork.service';

class UserVirtualGalleryArtworkPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      virtualGallery: null,
      galleryArtworks: [],
      artworks: [],
      isLoaded: false,
      isNew: false,
      isError: false,
      showModal: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => { this.getData() }

  async getData() {
    let isNew = false;
    let isError = false;
    let errorMessage = translatedMessage("VIRTUAL_GALLERY.GENERAL_ERROR");
    let galleryArtworks = [];
    let allArtworks = [];

    let virtualGallery = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/userVirtualGallery/${this.state.id}`)
      .then(response => {
        return response.data.userVirtualGallery;
      })
      .catch(err => {
        isError = true;
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = translatedMessage(err.response.data.message);
        }
      })

    if (!isError) {
      galleryArtworks = await VirtualGalleryService.getUserVirtualGalleryArtworks(this.state.id)
        .then(response => {
          return response;
        })
        .catch(err => {
          isError = true;
          if (err && err.response && err.response.status && (404 === err.response.status)) {
            errorMessage = translatedMessage(err.response.data.message);
          }
        })

      allArtworks = await ApiService.getAuthenticatedInstance().get(`/artwork/userArtworks`)
        .then(response => {
          return response.data.artworks;
        })
        .catch(err => {
          isError = true;
          if (err && err.response && err.response.status && (404 === err.response.status)) {
            errorMessage = translatedMessage(err.response.data.message);
          }
        })
    }

    if (!isError) {
      this.setState({
        virtualGallery: virtualGallery,
        artNo: virtualGallery.nrArArt + virtualGallery.nrSculpture,
        galleryArtworks: galleryArtworks,
        arArtArtwoks: galleryArtworks.filter(item => item.artwork.category.code === ArtworkService.artworkCategoryEnum().AR_ART),
        sculptureArtwoks: galleryArtworks.filter(item => item.artwork.category.code === "3D_OBJECT"),
        artworks: allArtworks,
        isNew: isNew,
        isLoaded: true
      }, this.computeGalleryArtworks)
    } else {
      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    }
  }

  computeGalleryArtworks = () => {
    let virtualGallery = this.state.virtualGallery;
    let galleryArtworks = this.state.galleryArtworks;
    let artNo = galleryArtworks.length;

    if (artNo !== virtualGallery.nrArArt + virtualGallery.nrSculpture) {
      let arArtNo = artNo ? galleryArtworks.filter(item => item.artwork.category.code === ArtworkService.artworkCategoryEnum().AR_ART).length : 0;
      let sculptureNo = artNo ? galleryArtworks.filter(item => item.artwork.category.code === "3D_OBJECT").length : 0;

      let arArtPositions = []
      for (let i = 0; i < virtualGallery.nrArArt; i++) {
        arArtPositions.push(i + 1)
      }

      let sculpturePositions = []
      for (let i = 0; i < virtualGallery.nrSculpture; i++) {
        sculpturePositions.push(i + 1)
      }

      galleryArtworks.forEach(item => {
        if (item.artwork.category.code === ArtworkService.artworkCategoryEnum().AR_ART) {
          arArtPositions = arArtPositions.filter(value => value !== item.position)
        } else if (item.artwork.category.code === "3D_OBJECT") {
          sculpturePositions.filter(value => value !== item.position)
        }
      })

      let remainingArArtNo = virtualGallery.nrArArt - arArtNo;
      let remainingSculpturetNo = virtualGallery.nrSculpture - sculptureNo;

      for (let i = artNo; i < artNo + remainingArArtNo; i++) {
        let galleryArtwork = {};
        galleryArtwork.id = 0;
        galleryArtwork.imageURL = calogo;
        galleryArtwork.artwork = { category: { code: ArtworkService.artworkCategoryEnum().AR_ART } };
        galleryArtwork.position = arArtPositions[0];
        arArtPositions = arArtPositions.filter(value => value !== galleryArtwork.position);

        galleryArtworks.push(galleryArtwork)
      }

      for (let i = artNo + remainingArArtNo; i < artNo + remainingArArtNo + remainingSculpturetNo; i++) {
        let galleryArtwork = {};
        galleryArtwork.id = 0;
        galleryArtwork.imageURL = calogo;
        galleryArtwork.artwork = { category: { code: "3D_OBJECT" } };
        galleryArtwork.position = sculpturePositions[0];
        sculpturePositions = sculpturePositions.filter(value => value !== galleryArtwork.position);

        galleryArtworks.push(galleryArtwork)
      }
    }
      this.setState({
        galleryArtworks: galleryArtworks,
        arArtArtwoks: galleryArtworks.filter(item => item.artwork.category.code === ArtworkService.artworkCategoryEnum().AR_ART),
        sculptureArtwoks: galleryArtworks.filter(item => item.artwork.category.code === "3D_OBJECT"),
      })
    
  }

  onShowModal = (id, categoryCode, position) => {
    this.setState({
      showModal: true,
      selectedId: id,
      categoryCode: categoryCode,
      position: position
    })
  }

  onHideModal = (mustFetch) => {
    if (mustFetch) {
      VirtualGalleryService.getUserVirtualGalleryArtworks(this.state.id)
        .then(response => {
          this.setState({
            galleryArtworks: response,
          }, this.computeGalleryArtworks)
        })
    }

    this.setState({
      showModal: false,
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-1 pr-2">
              <div className="ca-page-subtitle">
                <span className="font-weight-bold">{this.state.errorMessage}</span>&nbsp;
                  </div>
              <div>
                <Link to="/virtual-galleries" className="ca-dark-link">
                  <Image className="ca-page-title-icon mr-0 ml-2" src={back} alt={translatedMessage("BUTTON.BACK")}
                    title={translatedMessage("BUTTON.BACK")} />
                </Link>
              </div>
            </Row>
          </div>
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-3 pr-2">
              <div className="pr-2 d-flex align-items-center">
              <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
              <div className="ca-page-title" style={{ lineHeight: "32px" }}>
                {this.state.virtualGallery.name}
                <br />
                <span className="ca-page-subtitle ca-muted-text">{translatedMessage("VIRTUAL_GALLERY.MANAGE_ARTWORKS")}</span>
              </div>
              </div>


              <div>
                <Link to="/virtual-galleries" className="ca-dark-link">
                  <Image className="ca-page-title-icon mr-0 ml-2" src={back} alt={translatedMessage("BUTTON.BACK")}
                    title={translatedMessage("BUTTON.BACK")} />
                </Link>
              </div>
            </Row>

            <Row>
              <Col className={this.state.virtualGallery.portalMapUuid ? "col-12 col-md-6 ca-row-left-column" : "col-12 ca-row-left-column"}>
                <Row className="mb-5">
                  <div className="w-100 mb-3 text-center">
                    <span className="ca-page-subtitle">
                      {translatedMessage("AR_ART") + ": " + this.state.virtualGallery.nrArArt + " " + 
                        translatedMessage(this.state.virtualGallery.nrArArt === 1 ? "ARTWORK.ARTWORK" : "ARTWORK.ARTWORKS").toLowerCase()}
                    </span>
                  </div>

                  {this.state.arArtArtwoks.map((element, index) => {
                    return (
                      <Col key={index} className={this.state.virtualGallery.portalMapUuid ? "col-12 col-sm-12 col-lg-6" : "col-12 col-sm-6 col-lg-4 col-xl-3"}>
                        <CardComponent
                          cardImage={element.imageURL}
                          entity={element}
                          id={0 === element.id ? 0 : element.id}
                          onClick={this.onShowModal}
                        />
                      </Col>
                    )
                  })}
                </Row>

                <Row>
                  <div className="w-100 mb-3 text-center">
                    <span className="ca-page-subtitle">
                      {translatedMessage("3D_OBJECT") + ": " + this.state.virtualGallery.nrSculpture + " " + 
                        translatedMessage(this.state.virtualGallery.nrSculpture === 1 ? "ARTWORK.ARTWORK" : "ARTWORK.ARTWORKS").toLowerCase()}
                    </span>
                  </div>

                  {this.state.sculptureArtwoks.map((element, index) => {
                    return (
                      <Col key={index} className={this.state.virtualGallery.portalMapUuid ? "col-12 col-sm-12 col-lg-6" : "col-12 col-sm-6 col-lg-4 col-xl-3"}>
                        <CardComponent
                          cardImage={element.imageURL}
                          entity={element}
                          id={0 === element.id ? 0 : element.id}
                          onClick={this.onShowModal}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Col>
              {this.state.virtualGallery.portalMapUuid &&
                <Col className="col-12 col-md-6 ca-row-right-column text-center">
                  <div className="ca-page-subtitle w-100 text-center">
                    {translatedMessage("AR_PORTAL.AR_PORTAL_MAP_INFO")}
                  </div>
                  <Image src={ApiService.getBaseUrl() + '/storage/file/' + this.state.virtualGallery.portalMapUuid}
                    style={{ width: "90%", height: "auto" }} />
                </Col>
              }
            </Row>

            <ArtworkSelectModal
              show={this.state.showModal}
              onHide={this.onHideModal}
              uvgId={this.state.id}
              uvgaId={this.state.selectedId}
              artworks={this.state.artworks}
              categoryCode={this.state.categoryCode}
              position={this.state.position}
            />
          </div>
        )
      }
  }

}

export default UserVirtualGalleryArtworkPage;