import React from 'react';

import GalleryViewComponent from '../../../components/shared/Gallery/GalleryViewComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import CompanyService from '../../../services/company.service';

class SpaceGalleryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      isLoaded: false,
      isError: false,
      errorMessage: "",
      company: null
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    CompanyService.getCompanySpace(this.state.companyId)
      .then(response => {
        let company = response;

        this.setState({
          company: company,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <GalleryViewComponent
          history={this.props.history}
          companyId={this.state.company.id}
          galleryId={this.state.company.galleryId}
          galleryName={this.state.company.spaceName}
          backUrl={`/company/${this.state.company.id}/space`}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default SpaceGalleryPage;