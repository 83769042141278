import React from 'react';
import './Components.css';

import { Modal, Button } from 'react-bootstrap';

import ApiService from "../../../../services/api.service";
import LanguageService from "../../../../services/language.service";

import {toast} from "react-toastify";

class DeleteWebResourceComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profileLink: {
                id: '',
            }
        }
    }

    UNSAFE_componentWillReceiveProps(props, context) {
        // console.log(props);
        this.setState({
            profileLink: {
                id: props.profilelink.id,
            }
        })
    }

    deleteEntry = () => {
        ApiService.getAuthenticatedInstance().delete(`/profileLinks/${this.state.profileLink.id}`)
        .then(result => {
            toast.success(window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.DELETE_SUCCES"))
            this.props.onHide();
        });
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="web-resource-delete-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.DELETE.TITLE")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="ca-modal-delete-confirm">
                    {window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.DELETE_CONFIRM")}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" onClick={this.deleteEntry}>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.YES")}</Button>
                    <Button className="ca-button" variant="dark" onClick={this.props.onHide}>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.NO")}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DeleteWebResourceComponent