import React from 'react';

import { Row, Col, Dropdown, Image, InputGroup, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from "../../services/api.service";
import { translatedMessage } from '../../services/language.service';

import profilepic from '../../components/shared/Resources/defaultProfilePicture.png';
import back from '../../components/shared/Resources/left-arrow.svg';

import ArtworkCardComponent from '../../components/shared/Artwork/ArtworkCardComponent';
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import ArtworkService from '../../services/artwork.service';

const ENTER_KEY = 13;

class ProfilePortfolioPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.match.params.id,
      artworks: [],
      filteredArtworks: [],
      searchInput: '',
      artworkCategoryList: [],
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      selectedType: "GENERAL_TYPE.ANY",
      typeList: ["GENERAL_TYPE.ANY", "ARTWORK", "VIRTUAL_GALLERY"],
      isLoaded: false
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    const userId = Number(this.props.match.params.id);
    let artworkCategoryList = [];
    let artworks = [];
    let promise = [];


    ApiService.getAuthenticatedInstance().get(`/user/public-profile/user/${userId}`)
      .then(result => {
        let profile = result.data.profile;
        profile.profilePicture = profile.photoUuid !== '' ? `${ApiService.getBaseUrl()}/storage/file/${profile.photoUuid}` : profilepic;

        this.setState({
          profile: profile,
        })
      })
      .then(() => {
        promise.push(ArtworkService.getArtworkCategoryList()
          .then(response => {
            artworkCategoryList = response;
            artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
          }))

        promise.push(ApiService.getAuthenticatedInstance().get(`artwork/userPublicPortfolio/${userId}`)
          .then(response => {
            artworks = response.data.map((element) => {
              if(element.entityName === ApiService.getEntityName().ARTWORK){
                element = element.artwork;
                element.category = element.category.code;
                element.image = ApiService.getThumbnailByUrl(element.id);
                element.entityName = ApiService.getEntityName().ARTWORK;
                element.link = `/artwork/${element.id}`;
              } else if(element.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                element = element.virtualGallery;
                element.category = ArtworkService.artworkCategoryEnum().AR_PORTAL;
                element.image = ApiService.getFileByUuid(element.photoUuid);
                element.entityName = ApiService.getEntityName().VIRTUAL_GALLERY;
                element.link = `/virtualGalleryDetails/${element.id}`;
              } else {
                element = null;
              }

              return element;
            })
          })
        )

        Promise.all(promise).then(() => {
          this.setState({
            artworks: artworks,
            filteredArtworks: artworks,
            artworkCategoryList: artworkCategoryList,
            isLoaded: true
          })
        })

      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  handleOnTypeSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.typeList.filter(item => item === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;
    let typeItem = this.state.selectedType;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (typeItem !== "GENERAL_TYPE.ANY") {
      result = result.filter(elem => elem.entityName && elem.entityName === typeItem);
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.category && elem.category === categoryItem.code);
    }

    this.setState({
      filteredArtworks: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={this.state.profile.profilePicture} className="d-inline-block align-center profile-pic small ca-profile-photo mr-1"
                alt="profile pictire" />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.profile.displayName}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("ARTWORK.PORTFOLIO")}</div>
              </div>
            </div>

            <div>
              <Link to={`/profile/${this.state.userId}/view`} className="ca-dark-link">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  placeholder={translatedMessage("SEARCH.BY.NAME_AUTHOR")}
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element}>
                        {translatedMessage(element)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="card-columns mb-5">
            {this.state.filteredArtworks.map((element, index) => {
              return (
                <ArtworkCardComponent
                  key={index}
                  cardImage={element.image}
                  cardTitle={element.name}
                  cardCategory={element.category}
                  cardType={element.type ? element.type.label : null}
                  cardId={element.id}
                  cardLink={element.link}
                />
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ProfilePortfolioPage;