import React from "react";

import './Components.css';

// Import React Table
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import '../../../../styles/tableComponent.css'

import Dropdown from "react-bootstrap/Dropdown"
import { translatedMessage } from '../../../../services/language.service'

class WebResourcesTableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageLength: null,
            nextIcon: '\uf054',
            prevIcon: '\uf053',
            pageText: translatedMessage("TABLE.PAGE"),
            ofText: translatedMessage("TABLE.OF"),
            rowsText: translatedMessage("TABLE.PAGE")
        };
    }

    UNSAFE_componentWillReceiveProps(props, context) {
        this.setState({
            data: props.data
        })
        this.setState({
            pageLength: props.data.length
        })
    }

    render() {
        const { data } = this.state;
        // console.log(this.state.data, this.state.pageLength)
        return (
            <div>
                <ReactTable
                    showPagination={false}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageSizeOptions={true}
                    pageSize={this.state.pageLength}
                    data={data}
                    multiSort={false}
                    sortable={false}
                    nextText={this.state.nextIcon}
                    previousText={this.state.prevIcon}
                    pageText={this.state.pageText}
                    ofText={this.state.ofText}
                    rowsText={this.state.rowsText}
                    columns={[
                        {
                            Header: translatedMessage("TABLE.HEADER.NAME"),
                            accessor: "name"
                        },
                        {
                            Header: translatedMessage("TABLE.HEADER.LINK"),
                            accessor: "uri",
                        },
                        {
                            Header: '',
                            Cell: row => {
                                return (
                                    <div className="flex-arrange">
                                        <Dropdown className="table-caret-absolute">
                                            <Dropdown.Toggle className="table-dropdown-button">
                                                <span style={{
                                                    fontFamily: "FontAwesome"
                                                }}>
                                                    &#xF107;
                                            </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="table-dropdown-menu">
                                                <Dropdown.Item onClick={() => this.props.editModal(row.original.id)}>{translatedMessage("TABLE.EDIT")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.props.deleteModal(row.original.id)}>{translatedMessage("TABLE.DELETE")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )
                            }
                        },
                    ]}

                />
                <br />
            </div>
        );
    }
}

export default WebResourcesTableComponent