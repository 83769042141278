import React from 'react';

import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

import ParkManageVirtualGalleryComponent from '../../../components/shared/Park/ParkManageVirtualGalleryComponent';

class ParkAdminVirtualGalleryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.match.params.id,
      isLoaded: false,
      canEdit: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canViewList: hasPermission("PARK_LIST"),
        canEdit: hasPermission("PARK_EDIT"),
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <ParkManageVirtualGalleryComponent
          history={this.props.history}
          entityUrl="parkAdmin"
          canViewList={this.state?.canViewList}
          canEdit={this.state?.canEdit}
          parkId={this.state?.parkId}
          isAdmin={true}
          mapUrl={`/park/${this.state.parkId}/map`}
          artworkPrefixUrl={`/park-virtual-gallery`}
          triggerUrl={`artTrigger`}
        />        
      )
    }
  }

}

export default ParkAdminVirtualGalleryPage;