import React from 'react';

import { Row, Col, Card } from 'react-bootstrap/';
import { toast } from "react-toastify";
import moment from 'moment';

import visa from '../../../components/shared/Resources/payment/visa.png';
import mastercard from '../../../components/shared/Resources/payment/mastercard.png';
import genericCard from '../../../components/shared/Resources/payment/card.png';

import LanguageService from '../../../services/language.service';
import ApiService from '../../../services/api.service';

import { translatedMessage } from '../../../services/language.service';

import TableComponent from './Components/TableComponent/TableComponent';
import { Button } from '@material-ui/core';

class BillingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      paymentTransactions: [],
      paymentMethod: {},
      userMembership: {},
      isLoaded: false
    };
  }

  componentDidMount() {
    let user = ApiService.getCurrentUser();
    if (!user) {
      ApiService.initCurrentUser().then( this.getData() )
    } else {
      this.getData();
    }        
  }

  async getData() {
    const paymentTransactions = await ApiService.getAuthenticatedInstance().get(`payment/transactions`)
      .then(response => {
        const transactions = response.data;
        transactions.map(item => {
          if (item.entityName === ApiService.getEntityName().MEMBERSHIP) {
            item.transactionDetail = translatedMessage("MEMBERSHIP.PAYMENT." + item.paymentType) + " - " + item.paymentName;
          } else if (item.entityName === ApiService.getEntityName().CART && item.paymentType === 'ARTWORK') {
            item.transactionDetail = translatedMessage("ARTWORK.PURCHASE");
          } else if (item.entityName === ApiService.getEntityName().EXHIBITION || item.entityName === ApiService.getEntityName().GALLERY) {
            item.transactionDetail = translatedMessage(item.paymentType) + " - " + item.paymentName;
          } else {
            item.transactionDetail = translatedMessage("BILLING.ITEM_PURCHASE");
          }

          item.date = ApiService.parseDate(item.lastUpdatedOn).toLocaleDateString("en-EN", { year: 'numeric', month: 'short', day: 'numeric' })

          return item
        })
        return transactions;
      })

    const paymentMethod = await ApiService.getAuthenticatedInstance().get(`payment/paymentMethod`)
      .then(response => {
        const cardDetails = response.data.paymentMethod
        if (cardDetails.hasPaymentMethod) {
          if (cardDetails.expiryDate) {
            cardDetails.expiryDate = cardDetails.expiryDate.substr(2, 2) + "/" + moment(cardDetails.expiryDate.substr(0, 2), "YY").format("YYYY").toString();
          }
          if (cardDetails.paymentMethod.toLowerCase() === 'visa') {
            cardDetails.cardLogo = visa;
          } else if (cardDetails.paymentMethod.toLowerCase() === 'mastercard') {
            cardDetails.cardLogo = mastercard;
          } else {
            cardDetails.cardLogo = genericCard;
          }
        }
        return cardDetails
      })
      .catch(err => {
        console.log(err)
        console.log("An error has ocurred: " + err.response.data.message);
        toast.error(translatedMessage("BILLING.ERROR.PAYMENT_METHOD"));
      })

    let userMembership = await ApiService.getCurrentUser().activeUserMembership;
    if (userMembership.paymentInterval === "MONTHLY") {
      userMembership.amount = userMembership.membership.monthlyPrice
    } else {
      userMembership.amount = userMembership.membership.annualPrice
    }

    this.setState({
      paymentTransactions: paymentTransactions,
      paymentMethod: paymentMethod ? paymentMethod : null,
      userMembership: userMembership ? userMembership : null,
      isLoaded: true
    })
  }

  render() {

    let updatePaymentMethodClick = () => {
      ApiService.updatePaymentMethod(LanguageService.getCurrentLanguage()).then(result => {
        window.location.href = result.url;
      });
    }

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row>
            {this.state.paymentMethod.hasPaymentMethod &&
              <Col className="col-12 col-sm-6 ca-row-left-column">
                <Card className="ca-card">
                  <Card.Body>
                    <Card.Title className="mb-4">
                      <span className="ca-page-title">{translatedMessage("BILLING.PAYMENT_METHOD")}</span>
                    </Card.Title>
                    {this.state.paymentMethod.hasPaymentMethod &&
                      <Row className="align-items-center">
                        <Col className="pl-0" style={{ maxWidth: "115px" }}>
                          <img src={this.state.paymentMethod.cardLogo} alt={this.state.paymentMethod.paymentMethod} style={{ width: "100px" }} />
                        </Col>
                        <Col className="pl-0">
                          {"**** **** **** " + this.state.paymentMethod.maskedPAN}
                          <br />{translatedMessage("BILLING.CARD.EXPIRES") + ": " + this.state.paymentMethod.expiryDate}
                          <br/>
                          <br/>
                          <Button className="full-size-link ca-button ca-button-white"
                            onClick={() => updatePaymentMethodClick()}
                            >{translatedMessage("BILLING.UPDATE_PAYMENT_METHOD")}</Button>
                        </Col>
                      </Row>
                    }
                    {!this.state.paymentMethod.hasPaymentMethod &&
                      <Card.Text>
                        {translatedMessage("BILLING.NO_PAYMENT_METHOD")}
                        <br/>
                        <br/>
                        <Button className="full-size-link ca-button ca-button-white"
                            onClick={() => updatePaymentMethodClick()}
                            >{translatedMessage("BILLING.ADD_PAYMENT_METHOD")}</Button>
                      </Card.Text>
                    }
                  </Card.Body>
                  {/* <Card.Footer>
                </Card.Footer> */}
                </Card>
              </Col>
            }
            {this.state.userMembership && this.state.userMembership.paymentInterval &&
              <Col className="col-12 col-sm-6 ca-row-right-column">
                <Card className="ca-card">
                  <Card.Body>
                    <Card.Title className="mb-4">
                      <span className="ca-page-title">{translatedMessage("BILLING.BILLING_DETAILS")}</span>
                    </Card.Title>
                    {this.state.userMembership && this.state.userMembership.paymentInterval &&
                      <Card.Text className="mb-2">
                        <span className="font-bold">{translatedMessage("BILLING.PERIOD")}</span> 
                        {": " + translatedMessage("MEMBERSHIP." + this.state.userMembership.paymentInterval)}
                      </Card.Text>
                    }
                    {this.state.userMembership && this.state.userMembership.renewalDate &&
                      <Card.Text>
                        <span className="font-bold">{translatedMessage("BILLING.NEXT_CHARGE")}</span> 
                        {": " + ApiService.getNumberSeparatedByDots(this.state.userMembership.amount) + " USD / " +
                          ApiService.parseDate(this.state.userMembership.renewalDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long', day: 'numeric' })}

                        <br/><span className="ca-muted-text">{translatedMessage("BILLING.CARD_FUNDS_REMINDER")}</span>
                      </Card.Text>
                    }
                  </Card.Body>
                </Card>
              </Col>
            }
          </Row>

          <Row className="mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("BILLING.BILLING")}</span>
          </Row>

          {this.state.paymentTransactions.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("BILLING.NO_TRANSACTION")}
                  </span>
                </Col>
              </Row>
            </div>
          }

          {this.state.paymentTransactions.length > 0 &&
            <div>
              <TableComponent
                data={this.state.paymentTransactions}
                sent={true}
              />
            </div>
          }
        </div>
      )
    }
  }
}

export default BillingPage;