import React from 'react';

import { translatedMessage } from '../../../services/language.service';

import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ApiService from '../../../services/api.service';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class ProfileDetailsEditModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: this.props.profile,
      genders: []
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  componentDidMount = () => {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profile !== this.props.profile) {
      this.setState({ profile: this.props.profile }, this.getGenders);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.profile !== prevState.profile) {
      return { profile: prevState.profile };
    }
    else return null;
  }

  async getGenders() {
    ApiService.getGenders()
    .then(response => {
      this.setState({
        genders: response
      })
    })    
  }

  onProfileChange = (event) => {
    if (!isNaN(Date.parse(event))) {
      this.setState({
        profile: {
          ...this.state.profile,
          birthday: new Date(event)
        }
      });

      return;
    }

    if (!event.target) {
      return;
    }

    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      profile: {
        ...this.state.profile,
        [name]: value
      }
    });
  }

  checkValidity = (form) => {
    let isValid = false;

    var elements = form.getElementsByClassName('is-invalid');
    if (elements.length === 0) {
      isValid = true;
    }

    return isValid;
  }

  saveChanges = (event) => {
    event.preventDefault();

    if (this.checkValidity(event.currentTarget)) {
      let data = this.state.profile;
      data.dataFor = "details";
      this.props.onHide(data);
    }
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("PROFILE.EDIT_MY_PROFILE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Group controlId="formFirstName">
              <Form.Label>{translatedMessage("PROFILE.FIRST_NAME")} *</Form.Label>
              <Form.Control
                name="firstName"
                placeholder={translatedMessage("PROFILE.FIRST_NAME_PLACEHOLDER")}
                defaultValue={this.state.profile.firstName}
                onChange={this.onProfileChange}
                required
                isInvalid={this.state.profile.firstName.trim().length === 0 || this.state.profile.firstName.length < 2}
                minLength="2"
              />
              <Form.Control.Feedback type="invalid">{translatedMessage("PROFILE.FIRST_NAME.MIN_LENGTH")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formLastName">
              <Form.Label>{translatedMessage("PROFILE.LAST_NAME")} *</Form.Label>
              <Form.Control
                name="lastName"
                placeholder={translatedMessage("PROFILE.LAST_NAME_PLACEHOLDER")}
                defaultValue={this.state.profile.lastName}
                onChange={this.onProfileChange}
                required
                isInvalid={this.state.profile.lastName.trim().length === 0 || this.state.profile.lastName.length < 2}
                minLength="2"
              />
              <Form.Control.Feedback type="invalid">{translatedMessage("PROFILE.LAST_NAME.MIN_LENGTH")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formProfileName">
              <Form.Label>{translatedMessage("PROFILE.PROFILE_NAME")}
                <OverlayTrigger
                  key='pN'
                  placement='right'
                  overlay={
                    <Tooltip id={`tooltip-pN`}>
                      {translatedMessage("PROFILE.PROFILE_NAME.UNIQUE.DESC")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                name="profileName"
                placeholder={translatedMessage("PROFILE.PROFILE_NAME_PLACEHOLDER")}
                defaultValue={this.state.profile.profileName}
                onChange={this.onProfileChange}
                isInvalid={this.state.profile.profileName !== null && this.state.profile.profileName.length < 3 && this.state.profile.profileName.trim().length !== 0}
                minLength="3"
              />
              <Form.Control.Feedback type="invalid">{translatedMessage("PROFILE.PROFILE_NAME.MIN_LENGTH")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>{translatedMessage("PROFILE.PHONE")}</Form.Label>
              <Form.Control
                name="phone"
                placeholder={translatedMessage("PROFILE.PHONE_PLACEHOLDER")}
                defaultValue={this.state.profile.phone}
                onChange={this.onProfileChange}
              />
            </Form.Group>

            <Form.Group controlId="formBirthday" className="ca-profile-form-date">
              <Form.Label>{translatedMessage("PROFILE.BIRTHDAY")}</Form.Label>
              <DatePicker              
                name="birthday"
                showPopperArrow={false}
                className="form-control w-100"
                selected={this.state.profile.birthday}
                onChange={this.onProfileChange}
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>

            <Form.Group controlId="formGender">
              <Form.Label>{translatedMessage("PROFILE.GENDER")}</Form.Label>
              <Form.Control
                name="genderId"
                as="select"
                value={this.state.profile.genderId ? this.state.profile.genderId : -1}
                onChange={this.onProfileChange}
              >
                <option style={{ display: "none" }} value={-1}>{translatedMessage("GENERAL.SELECT_LIST")}</option>
                {this.state.genders.map(gender => (
                  <option value={gender.id} key={gender.id}>{translatedMessage("PROFILE.GENDERS." + gender.nameKey.toUpperCase())}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default ProfileDetailsEditModalComponent;