import React from 'react';

import { Card } from 'react-bootstrap';

import ChallengeDetailsModalComponent from './ChallengeDetailsModalComponent'

class ChallengeViewCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    showDetails = (details) => {
        this.setState({
            showModal: true,
            details: details
        })
    }

    onHideModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <>
                <Card className="ca-gallery-card" onClick={() => this.showDetails(this.props.details)} >
                    <Card.Img variant="top" src={this.props.image} />
                    <Card.Body>
                        <Card.Title className="d-flex justify-content-center align-items-center mb-0">{this.props.title}</Card.Title>
                    </Card.Body>
                </Card>

                <ChallengeDetailsModalComponent
                    show={this.state.showModal}
                    onHide={this.onHideModal}
                    title={this.props.title}
                    details={this.state.details}
                />
            </>
        )
    }
}

export default ChallengeViewCardComponent;