import React from 'react';

import { Row } from 'react-bootstrap';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';


import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';

class GalleryVirtualGalleryManageLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryVGId: this.props.match.params.id,
      galleryVirtualGallery: null,
      gallery: null,
      virtualGallery: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      galleryIcon: null,
      mapLocations: [],
      initialCenter: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      let canEdit = hasPermission("GALLERY_EDIT") || hasPermission("COMPANY_EDIT");
      if (canEdit) {
        this.setState({
          canEdit: canEdit,
        }, this.getData)
      } else {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        ApiService.initCurrentCompany()
          .then(response => {
            if (response === null || ApiService.getCompanyUserPendingApproval()) {
              this.setState({
                canEdit: false,
              }, this.getData)
            } else {
              this.setState({
                company: ApiService.getCurrentCompany(),
                canEdit: true,
              }, this.getData)
            }
          })
          .catch(err => {
            this.setState({
              isError: true,
              errorMessage: errorMessage,
              isLoaded: true
            })
          });
      }
    })
  }

  getData = () => {
    let promise = [];
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    if (this.state.canEdit) {
      ApiService.getAuthenticatedInstance().get(`gallery/virtual-gallery/${this.state.galleryVGId}`)
        .then(response => {
          this.setState({
            galleryVirtualGallery: response.data,
            gallery: response.data.gallery,
            virtualGallery: response.data.virtualGallery,
          }, () => {

            let initialCenter = {};
            promise.push(ApiService.getAuthenticatedInstance().get(`gallery/${this.state.gallery.id}/locations`)
              .then(response => {
                if (response.data[0]) {
                  initialCenter = {
                    lat: response.data[0].locationLat,
                    lng: response.data[0].locationLng,
                  }
                } else {
                  initialCenter = null
                }
              })
            )

            let artLocations = [];
            let mapLocations = [];
            promise.push(
              ApiService.getAuthenticatedInstance().get(`map/galleryMapLocations/${this.state.gallery.id}`)
                .then(result => {
                  mapLocations = result.data.filter(item => item.entity.entityId !== this.state.virtualGallery.id)
                  artLocations = result.data.filter(item => item.entity.entityId === this.state.virtualGallery.id)
                })
            )

            var galleryPin = []
            promise.push(MapService.getLocationPinByType(this.state.gallery.galleryType)
              .then(response => {
                galleryPin = response;
              }))

            var artPin = [];
            promise.push(MapService.getLocationPinByType(ApiService.getEntityName().VIRTUAL_GALLERY)
              .then(response => {
                artPin = response;
              }))

            Promise.all(promise).then(() => {
              this.setState({
                location: artLocations[0] ? artLocations[0] : this.state.location,
                hasGeofence: artLocations[0] && artLocations[0].geofence ? true : false,
                pinIcon: artPin[0] ? artPin[0].imageUrl : null,
                galleryIcon: galleryPin[0] ? galleryPin[0].imageUrl : null,
                initialCenter: initialCenter,
                mapLocations: mapLocations,
                isLoaded: true
              })
            })
          })
        })
        .catch(err => {
          if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          });
        });
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.galleryVGId}
            entityName={ApiService.getEntityName().GALLERY_VIRTUAL_GALLERY}
            mapLocations={this.state.mapLocations}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={this.state.galleryIcon}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.virtualGallery.name}
            parentEntityTitle={this.state.gallery.name}
            history={this.props.history}
            zoomLevel={17}
          />
        </>
      );
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }
}

export default GalleryVirtualGalleryManageLocation;