import React from 'react';

import { Row, Col, Form, Button, Dropdown, Image } from 'react-bootstrap';

import CardComponent from "./Components/GalleryAdminCardComponent";

import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import ApiService from '../../../services/api.service';
import GalleryService from '../../../services/gallery.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

const ENTER_KEY = 13;

class GalleryAdminList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      allGalleries: [{
        key: 0,
        name: "",
        imageUrl: "",
        id: 0
      }],
      filteredGalleries: [],
      galleryStatusList: [],
      galleryTypeList: [],
      selectedStatus: { label: "GENERAL_STATUS.ANY", name: "ANY" },
      selectedType: { id: "0", nameKey: "GENERAL_TYPE.ANY" },
      isLoaded: false,
      canViewList: false,
      isError: false,
      errorMessage: ""
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canViewList: hasPermission("GALLERY_LIST_ALL")
      }, this.getGallery)
    });
  }

  getGallery = () => {
    var galleries = [];
    var galleryStatusList = [];
    var galleryTypeList = [];
    var promise = [];

    if (this.state.canViewList) {

      promise.push(GalleryService.getGalleryStatusList()
        .then(response => {
          galleryStatusList = response;
          galleryStatusList.splice(0, 0, { label: "GENERAL_STATUS.ANY", name: "ANY" })
        }))

      promise.push(GalleryService.getGalleryTypeList()
        .then(response => {
          galleryTypeList = response._embedded.galleryTypes.filter(item => item.nameKey !== "GALLERY_TYPE_COMPANY_SPACE");
          galleryTypeList.map(item => {
            const parts = item._links.self.href.split('/');
            const id = parts[parts.length - 1];
            item.id = id;
            return item;
          })
          galleryTypeList.splice(0, 0, { id: "0", nameKey: "GENERAL_TYPE.ANY" })
        })
      )

      promise.push(GalleryService.getGalleries(true)
        .then(response => {
          galleries = response
        })
      )

      Promise.all(promise).then(() => {
        this.setState({
          allGalleries: galleries,
          filteredGalleries: galleries,
          galleryStatusList: galleryStatusList,
          galleryTypeList: galleryTypeList,
          isLoaded: true
        })
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleStatusSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.galleryStatusList.filter(item => item.name === eventKey);

    this.setState({
      selectedStatus: selectedItem[0]
    }, this.filter)
  }

  handleTypeSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.galleryTypeList.filter(item => item.id === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.allGalleries;
    let searchInput = this.state.searchInput;
    let statusItem = this.state.selectedStatus.name;
    let typeItem = this.state.selectedType.id;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.allGalleries.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (statusItem !== 'ANY') {
      result = result.filter(elem => elem.status.name === statusItem);
    }

    if (typeItem !== "0") {
      result = result.filter(elem => elem.galleryType.id.toString() === typeItem.toString());
    }

    this.setState({
      filteredGalleries: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={galleryIcon} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERY")} />
              <span className="ca-page-title">{translatedMessage("GALLERY.GALLERIES")}</span>
            </div>

            {!hasPermission("GALLERY_CREATE") ? "" :
              <Button onClick={() => this.props.history.push(`/galleryAdminEdit`)} className="ca-dark-link ca-button-icon medium">
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} title={translatedMessage("GENERAL.ADD_NEW")}/>
              </Button>
            }
          </Row>
          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 col-md-4 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedStatus.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.galleryStatusList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType.nameKey)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.galleryTypeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.nameKey)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            {this.state.filteredGalleries.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pl-0 pr-0">
                  <CardComponent
                    cardImage={element.imageURL}
                    cardTitle={element.name}
                    cardDetails={element}
                    id={0 === element.id ? 0 : element.id}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }
}

export default GalleryAdminList;
