import React from 'react';

import ExhibitionManageArtworkTrigger from '../../../components/shared/Exhibition/ExhibitionManageArtworkTriggerComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

class ExhibitionAdminArtworkTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionArtworkId: this.props.match.params.id,
      exhibitionArtwork: [],
      exhibition: [],
      artwork: [],
      storageItem: null,
      isLoaded: false,
      triggerFiles: [],
      showAddFileModal: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionManageArtworkTrigger
          id={this.state.exhibitionArtworkId}
          history={this.props.history}
          backUrl={`/exhibitionAdminArtworks`}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ExhibitionAdminArtworkTrigger;