import React from 'react';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

import { toast } from "react-toastify";
import moment from 'moment';

class GalleryAdminLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: this.props.match.params.id,
      gallery: [],
      locations: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        geofence: null,
        name: ''
      },
      mapProps: null,
      map: null,
      marker: null,
      polygon: null,
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("GALLERY_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    let gallery = [];
    let locations = [];
    let promise = [];
    let pins = [];
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    if (this.state.canEdit) {

      ApiService.getAuthenticatedInstance().get(`gallery/getGalleryDetail/${this.state.galleryId}`)
        .then(response => {
          gallery = response.data;
        })
        .then(() => {

          promise.push(ApiService.getAuthenticatedInstance().get(`gallery/${this.state.galleryId}/locations`)
            .then(response => {
              locations = response.data;
            })
          )

          promise.push(MapService.getLocationPinByType(gallery.galleryType)
            .then(response => {
              pins = response;
            }))

          Promise.all(promise).then(() => {
            this.setState({
              gallery: gallery,
              locations: locations,
              location: locations[0] ? locations[0] : this.state.location,
              hasGeofence: locations[0] && locations[0].geofence ? true : false,
              pinIcon: pins[0] ? pins[0] : null,
              isLoaded: true
            })
          })
        })
        .catch(err => {
          if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          });
        });
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  onMapReady = (props, map) => {
    var initialCenter = MapService.getInitialCenter();
    var marker = new props.google.maps.Marker();
    let polygon = MapService.getInitFencePolygon(props, map, false, true, true);
    var location = {
      lat: this.state.location.locationLat,
      lng: this.state.location.locationLng
    };

    var icon = null
    if (this.state.pinIcon !== null) {
      icon = {
        url: this.state.pinIcon.imageUrl,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height)
      }
    }

    this.setState({
      mapProps: props,
      map: map,
      marker: marker,
      polygon: polygon
    })

    if (!(location.lat === 0 && location.lng === 0)) {
      map.setCenter(location);
      marker.setMap(map);
      marker.setPosition(location);
      marker.setDraggable(true);
      if (icon !== null) {
        marker.setIcon(icon);
      }

      if (this.state.location.geofence) {
        let geofenceCoordinates = JSON.parse(this.state.location.geofence)
        polygon.setPath(geofenceCoordinates)
        polygon.setVisible(true)
      }
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          map.setCenter(pos);
        }, () => {
          //Geolocation service failed
          map.setCenter(initialCenter);
        });
      } else {
        // Browser doesn't support Geolocation
        map.setCenter(initialCenter);
      }
    }

    map.addListener('click', (e) => {
      marker.setMap(map);
      marker.setPosition(e.latLng);
      marker.setDraggable(true);
      if (icon !== null) {
        marker.setIcon(icon);
      }

      this.setState({
        location: {
          ...this.state.location,
          locationLat: marker.position.lat(),
          locationLng: marker.position.lng()
        },
        marker: marker
      })
    });

    marker.addListener('dragend', () => {
      this.setState({
        location: {
          ...this.state.location,
          locationLat: marker.position.lat(),
          locationLng: marker.position.lng()
        },
        marker: marker
      })
    });

    // OnMouseOver add listeners for changing the polygon shape
    polygon.addListener('mouseover', () => {
      props.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        this.setState({
          location: {
            ...this.state.location,
            geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
          },
          polygon: polygon,
        })
      });

      props.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        this.setState({
          location: {
            ...this.state.location,
            geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
          },
          polygon: polygon,
        })
      });
    })
  }

  addGeofence = () => {
    var location = {
      lat: this.state.location.locationLat,
      lng: this.state.location.locationLng
    };

    let initialCoordinates = MapService.getInitialPolygon(location);

    if (!(location.lat === 0 && location.lng === 0)) {
      let polygon = this.state.polygon;
      polygon.setPath(initialCoordinates)
      polygon.setVisible(true)

      this.setState({
        location: {
          ...this.state.location,
          geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
        },
        polygon: polygon,
        hasGeofence: true
      });
    }
  }

  deleteGeofence = () => {
    let polygon = this.state.polygon
    polygon.setVisible(false);

    this.setState({
      polygon: polygon,
      location: {
        ...this.state.location,
        geofence: null
      },
      hasGeofence: false
    })
  }


  validate = (event) => {
    var response = {
      isError: false,
      message: ""
    };

    if (event.target.elements.formLat) {
      const lat = event.target.elements.formLat.value;

      if (!isFinite(lat) || !(Math.abs(lat) <= 90)) {
        response.isError = true;
        response.message = translatedMessage("MAP.LATITUDE.VALUE_ERROR");
        return response;
      };
    }

    if (event.target.elements.formLng) {
      const lng = event.target.elements.formLng.value;

      if (!isFinite(lng) || !(Math.abs(lng) <= 180)) {
        response.isError = true;
        response.message = translatedMessage("MAP.LONGITUDE.VALUE_ERROR");
        return response;
      }
    }

    return response;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const validation = this.validate(event);
    if (!validation.isError) {
      const location = this.state.location;
      new Promise((resolve, reject) => {
        if (location.id === 0) {
          resolve(ApiService.getAuthenticatedInstance().post(`/locations`, {
            name: location.name,
            locationLat: location.locationLat,
            locationLng: location.locationLng,
            geofence: location.geofence,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          }));
        } else {
          resolve(ApiService.getAuthenticatedInstance().patch(`/locations/${location.id}`, {
            name: location.name,
            locationLat: location.locationLat,
            locationLng: location.locationLng,
            geofence: location.geofence,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          }));
        }
      })
        .then((result) => {
          const parts = result.data._links.self.href.split('/');
          const id = parts[parts.length - 1];
          this.setState({
            location: {
              ...this.state.location,
              id: id
            }
          })
          if (location.id === 0) {
            return ApiService.getAuthenticatedInstance().post(`/entityLocations`, {
              location: `/locations/${id}`,
              entity: {
                entityId: this.state.gallery.id,
                entityName: "GALLERY"
              },
              lastUpdatedBy: ApiService.getCurrentUser().username,
              lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            });
          }
        })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.getData();
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      toast.error(validation.message);
    }
  }

  render() {
    if (!this.props.google || !this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <Row className="mb-0">
          <Col className="col-12 col-md-6 ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2">
              <div className="d-flex align-items-center">
                <Image src={galleryIcon} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERY")} />
                <div>
                  <div className="ca-page-title" style={{ lineHeight: "32px" }}>{this.state.gallery.name}</div>
                  <div className="ca-page-subtitle ca-muted-text">{translatedMessage("GENERAL.LOCATION.MANAGE")}</div>
                </div>
              </div>
            </Row>

            <Form className="ca-form" onSubmit={this.handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="formName">
                      <Form.Label>
                        {translatedMessage("LOCATION.NAME")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder={translatedMessage("LOCATION.NAME_DESC")}
                        value={this.state.location.name}
                        onChange={e => this.setState({
                          location: {
                            ...this.state.location,
                            name: e.target.value
                          }
                        })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formLat">
                      <Form.Label>
                        {translatedMessage("MAP.LATITUDE")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={""}
                        value={this.state.location.locationLat}
                        onChange={e => this.setState({
                          location: {
                            ...this.state.location,
                            locationLat: e.target.value
                          }
                        })
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formLng">
                      <Form.Label>
                        {translatedMessage("MAP.LONGITUDE")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={""}
                        value={this.state.location.locationLng}
                        onChange={e => this.setState({
                          location: {
                            ...this.state.location,
                            locationLng: e.target.value
                          }
                        })
                        }
                      />
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form.Row>

              <Form.Row className="align-items-center">
                <Form.Group as={Col} md="12">
                  <Button className="ca-button mr-1 "
                    type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                  <Button className={"ca-button mr-1 ".concat(this.state.hasGeofence ? "ca-hide-button" : "")}
                    onClick={this.addGeofence}>{translatedMessage("MAP.GEOFENCE.ADD")}</Button>
                  <Button className={"ca-button mr-1 ".concat(!this.state.hasGeofence ? "ca-hide-button" : "")}
                    onClick={this.deleteGeofence}>{translatedMessage("MAP.GEOFENCE.DELETE")}</Button>
                  <Button className="ca-button ca-button-white"
                    onClick={() => this.props.history.push(`/galleryAdminList`)}>{translatedMessage("BUTTON.BACK")}</Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
          <Col className="col-12 col-md-6 ca-map-column pl-0 pr-0">
            <Map style={{}} google={this.props.google} zoom={13} initialCenter={MapService.getInitialCenter()} streetViewControl={false}
              onReady={this.onMapReady}>
            </Map>
          </Col>
        </Row>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(GalleryAdminLocation);