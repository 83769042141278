import React from 'react';

import { Modal, Button, Col, Image, Row } from 'react-bootstrap/'

import { translatedMessage } from '../../../services/language.service';
import parkIcon from '../Resources/homepage/art_park.svg';

import FileService from '../../../services/file.service';
import DropzoneComponent from '../DropzoneComponent/DropzoneComponent';

class ParkMediaModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            park: { ...this.props?.park, storageItem: { id: this.props?.park?.storageItemId } },
            dropzoneEnabled: true,
            parkImageFile: null
        }
    }

    async componentDidMount() {
        // console.log("componentDidMount: ", this.props.park)
        this.setState({
            parkImageFile: this.props?.park?.photoUuid ? { uuid: this.props?.park?.photoUuid } : null,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.park !== this.props.park) {
            this.setState({
                park: this.props.park,
                parkImageFile: this.props.parkImageFile
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.park !== prevState.park) {
            return { 
                park: prevState.park,
                parkImageFile: prevState.parkImageFile,
            };
        }
        else return null;
    }

    onUploadFile = (dropzoneEnabled, uploadedFile) => {        
        if (dropzoneEnabled) {
            // setTimeout(() => {
            //     this.setState({
            //         dropzoneEnabled: dropzoneEnabled,
            //         parkImageFile: uploadedFile,
            //     })
            // }, 500);
            this.props.onHide(null, true)
        } else {
            // this.setState({
            //     dropzoneEnabled: dropzoneEnabled,
            //     parkImageFile: uploadedFile,
            // });
        }                
    }

    render() {
        var park = this.state?.park;

        return (
            <Modal
                onHide={this.props?.onHide}
                show={this.props?.show}
                size="lg"
                // dialogClassName="ca-modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='d-flex align-items-center'>
                            <Image src={parkIcon} className="mr-1" alt={translatedMessage("PARK.PARK")} style={{ height: "40px" }} />
                            {park?.name}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <Row>
                        <Col className="col-12">
                            <label className="form-label">
                                {translatedMessage("PARK.IMAGE.UPLOAD")}
                            </label>
                            <DropzoneComponent
                                componentId="parkImage"
                                fileUsage={FileService.getFileUsage().PARK}
                                fileType={FileService.getResourceType().IMAGE}
                                deletePrevious={true}
                                deletedImage={this.state?.parkImageFile}
                                deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                                fileFormatsDesc="DROPZONE.FILE_TYPES.ARCHIVE"
                                maxSize={1}
                                maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                multipleFiles={false}
                                multipleFilesDesc=""
                                entity={park}
                                entityUpdateAPILink="parks"
                                storageitemlinked={false}                                
                                onupload={this.onUploadFile}
                                disabled={this.props?.onlyView}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" className={"ca-button mr-1 ".concat(this.state.dropzoneEnabled ? "" : "disabled")}
                        onClick={this.props.onHide}>
                        {translatedMessage("GENERAL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ParkMediaModalComponent