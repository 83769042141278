import React from 'react';
import calogo from '../Resources/calogo.png';
import './NavbarComponent.css';

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import { Image } from 'react-bootstrap';


import { translatedMessage } from '../../../services/language.service';

import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

import profilepic from '../Resources/defaultProfilePicture.png';
import cartIcon from '../Resources/cartWhite.png';


class NavbarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            photoUuid: '',
            navExpanded: false,
            canUpgrade: false,
            isMember: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
    }


    componentDidMount() {
        if (!ApiService.getCurrentUser()) {
            ApiService.getAuthenticatedInstance().get('/user/me')
                .then(result => {
                    ApiService.setCurrentUser(result.data);
                    ApiService.getAuthenticatedInstance().get(`/user/myProfile`)
                        .then(result => {
                            ApiService.setCurrentProfile({
                                ...result.data.profile,
                            });

                            this.populateData();
                        });
                })
        } else {
            this.populateData();
        }

        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        } else {
            if (this.state.navExpanded) {
                this.handleToggle();
            }
        }
    }

    handleProfileClick() {
        if (this.state.navExpanded) {
            this.handleToggle();
        }
    }

    handleToggle() {
        this.setState({ navExpanded: !this.state.navExpanded })
    }

    handleLogOut = () => {
        localStorage.removeItem('_t');
        localStorage.removeItem('_redirect');        
        this.props.history.push('/login');
    }

    populateData() {
        this.setState({
            photoUuid: ApiService.getCurrentProfile().photo ? ApiService.getCurrentProfile().photo.uuid : "",
            fullName: ApiService.getCurrentProfile().displayName,
            canUpgrade: ApiService.getCurrentUser().activeUserMembership
                ? ApiService.getCurrentUser().activeUserMembership.membership.canUpgrade
                : false,
            isMember: ApiService.getCurrentUser().activeUserMembership
                && ApiService.getCurrentUser().activeUserMembership.membership.name === "CA"
                && ApiService.getCurrentUser().activeUserMembership.membership.type === "CA_INTERNAL_MEMBERSHIP"
                ? false : true
        });
    }

    render() {

        let userTextAndIcon =
            <div className="navbar-dropdown-button-user">
                <span className="navbar-user hidden-sm">{this.state.fullName}</span>
                <img
                    alt=""
                    src={this.state.photoUuid !== '' ? ApiService.getBaseUrl() + '/storage/file/' + this.state.photoUuid : profilepic}
                    height="50"
                    width="50"
                    className={"profile-icon ca-profile-photo " + (this.state.photoUuid === '' ? "ca-bkg-white" : "")}
                />
            </div>;

        let administrationMenu;
        if (hasPermission("MENU_ADMIN_VIEW")) {
            administrationMenu = <NavDropdown title={translatedMessage("NAVBAR.MENU.ADMINISTRATION")} className="custom-nav-dropdown administration" id="collasible-nav-dropdown">
                {hasPermission("USER_LIST") ? <NavDropdown.Item eventKey="5.1" className="full-size-link routing-link" as={Link} to={'/users'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.USERS")}</NavDropdown.Item> : "" }
                {hasPermission("COMPANY_LIST") ? <NavDropdown.Item eventKey="5.2" className="full-size-link routing-link" as={Link} to={'/companies'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.COMPANIES")}</NavDropdown.Item> : "" }
                {hasPermission("SALES_LIST") ? <NavDropdown.Item eventKey="5.3" className="full-size-link routing-link" as={Link} to={'/sales'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.SALES")}</NavDropdown.Item> : "" }
                {hasPermission("USER_LIST") || hasPermission("COMPANY_LIST") || hasPermission("SALES_LIST") ? <NavDropdown.Divider /> : "" }
                {hasPermission("ARTWORK_LIST_ALL") ? <NavDropdown.Item eventKey="5.10" className="full-size-link routing-link" as={Link} to={'/artworkAdminList'}>{translatedMessage("NAVBAR.MENU.ARTWORKS")}</NavDropdown.Item> : "" }
                {hasPermission("GALLERY_LIST_ALL") ? <NavDropdown.Item eventKey="5.11" className="full-size-link routing-link" as={Link} to={'/galleryAdminList'}>{translatedMessage("GALLERY.GALLERIES")}</NavDropdown.Item> : "" }
                {hasPermission("EXHIBITION_LIST_ALL") ? <NavDropdown.Item eventKey="5.12" className="full-size-link routing-link" as={Link} to={'/exhibitionAdminList'}>{translatedMessage("EXHIBITION.EXHIBITIONS")}</NavDropdown.Item> : "" }
                {hasPermission("AR_PORTAL_LIST") ? <NavDropdown.Item eventKey="5.13" className="full-size-link routing-link" as={Link} to={'/arPortalList'}>{translatedMessage("AR_PORTAL.AR_PORTALS")}</NavDropdown.Item> : "" }
                {hasPermission("VIRTUAL_GALLERY_LIST") ? <NavDropdown.Item eventKey="5.14" className="full-size-link routing-link" as={Link} to={'/virtualGalleryList'}>{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}</NavDropdown.Item> : "" }
                {hasPermission("AR_EXPERIENCE_LIST") ? <NavDropdown.Item eventKey="5.15" className="full-size-link routing-link" as={Link} to={'/arExperienceList'}>{translatedMessage("AR_EXPERIENCE.AR_EXPERIENCES")}</NavDropdown.Item> : "" }
                {hasPermission("PARK_LIST") ? <NavDropdown.Item eventKey="5.15" className="full-size-link routing-link" as={Link} to={'/parkAdmin'}>{translatedMessage("PARK.PARKS")}</NavDropdown.Item> : "" }
                {hasPermission("ARTWORK_LIST_ALL") ? <NavDropdown.Item eventKey="5.16" className="full-size-link routing-link" as={Link} to={'/demoArt'}>{translatedMessage("DEMO_ART.INSTANT_MAGIC")}</NavDropdown.Item> : "" }
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="5.20" className="full-size-link routing-link" as={Link} to={'/map'}>{translatedMessage("NAVBAR.MENU.MAP")}</NavDropdown.Item>
                {hasPermission("GEOFENCE_VIEW") ? <NavDropdown.Item eventKey="5.21" className="full-size-link routing-link" as={Link} to={'/geofence'}>{translatedMessage("NAVBAR.MENU.GEOFENCES")}</NavDropdown.Item> : ""}
                {hasPermission("MAP_CAN_MANAGE_PINS") ? <NavDropdown.Item eventKey="5.22" className="full-size-link routing-link" as={Link} to={'/locationPin'}>{translatedMessage("NAVBAR.MENU.LOCATION_PIN")}</NavDropdown.Item> : ""}
                {/* <NavDropdown.Item className="dropdown-item full-size-link routing-link" as={Link} to={'/roles'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.ROLES")}</NavDropdown.Item>
                    <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/preferences'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.PREFERENCES")}</NavDropdown.Item>
                    <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/custom'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.CUSTOM_FIELDS")}</NavDropdown.Item>
                    <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/memberships'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.MEMBERSHIPS")}</NavDropdown.Item> */}
            </NavDropdown>;
        }

        let userName = <span className="ca-nav-username">{this.state.fullName}</span>;
        // let languageIcon = <span style={{ fontFamily: "FontAwesome", fontSize: "2rem" }}>&#xf0ac;</span>
        //let cartIcon = <span style={{ fontFamily: "FontAwesome", fontSize: "24px" }}>&#xf07a;</span>

        return (
            <>
                <Navbar id="ca-navbar" ref={node => this.node = node} expand="lg" collapseOnSelect="true" variant="dark" sticky="top" className="ca-navbar"
                    onToggle={this.handleToggle} expanded={this.state.navExpanded}>
                    <Navbar.Toggle id="toggle-button" aria-controls="ca-main-menu" />
                    <Navbar.Brand className="ca-navbar-brand align-center">
                        <Link to={'/'}>
                            <img
                                alt="CA logo"
                                src={calogo}
                                width="auto"
                                height="50"
                                className="d-inline-block align-center logo"
                            />
                        </Link>
                    </Navbar.Brand>
                    {/* <FormControl className="search-input hidden-sm" id="nav-middle" type="text" placeholder="&#xF002; search" /> */}
                    {/* <div id="ca-responsive-navbar">
                            <FormControl className="search-input" id="nav-middle" type="text" placeholder="&#xF002; search" />
                        </div> */}

                    <Nav id="nav-right" className="ca-nav-right">

                        <Link className="full-size-link ca-button ca-button-white ca-upgrade-button hidden-sm"
                            hidden={!this.state.canUpgrade}
                            to={'membership'}>{translatedMessage("NAVBAR.MENU.UPGRADE")}</Link>

                        <div className="navbar-custom-link ca-cart">
                            <Link className="full-size-link routing-link" to={'/cart'}>
                                <Image src={cartIcon} width="40" />
                            </Link>
                        </div>

                        <NavDropdown className="navbar-user-and-icon" alignRight title={userTextAndIcon} onClick={this.handleProfileClick}>
                            {userName}
                            {this.state.canUpgrade &&
                                <div className="ca-upgrade-menuitem">
                                    <div className="ca-upgrade-menuitem-container">
                                        <Link className="full-size-link" to={'membership'}>{translatedMessage("NAVBAR.MENU.UPGRADE")}</Link>
                                    </div>
                                </div>
                            }
                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/profile/view'}>{translatedMessage("NAVBAR.MENU.PROFILE.MY_PROFILE")}</NavDropdown.Item>
                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/my-companies'}>{translatedMessage("NAVBAR.MENU.COMPANY")}</NavDropdown.Item>                            
                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/account'}>{translatedMessage("NAVBAR.MENU.PROFILE.ACCOUNT_PREFERENCES")}</NavDropdown.Item>
                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/security'}>{translatedMessage("NAVBAR.MENU.PROFILE.CHANGE_PASSWORD")}</NavDropdown.Item>
                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/social-media'}>{translatedMessage("NAVBAR.MENU.PROFILE.SOCIAL_MEDIA_PROFILES")}</NavDropdown.Item>

                            {this.state.isMember &&
                                <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/membership'}>{translatedMessage("NAVBAR.MENU.ADMINISTRATION.MEMBERSHIP")}</NavDropdown.Item>
                            }

                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/billing'}>{translatedMessage("NAVBAR.MENU.PROFILE.BILLING")}</NavDropdown.Item>
                            <NavDropdown.Item className="full-size-link routing-link" as={Link} to={'/gift'}>{translatedMessage("NAVBAR.MENU.PROFILE.GIFTS")}</NavDropdown.Item>

                            <NavDropdown.Item className="full-size-link routing-link" onClick={this.handleLogOut}>{translatedMessage("LOGOUT")}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>

                    <Navbar.Collapse id="nav-left">
                        <Nav className="navbar-custom-nav mr-auto">
                            <Nav.Item>
                                <Nav.Link eventKey="1" className="full-size-link routing-link" href={'/gallery'}>{translatedMessage("NAVBAR.MENU.GALLERY")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Link eventKey="2" className="full-size-link routing-link" href={'/exhibition'}>{translatedMessage("EXHIBITION.EXHIBITIONS")}</Nav.Link>
                            <NavDropdown title={translatedMessage("MY_ART")} className="custom-nav-dropdown artworks" id="collasible-nav-dropdown">
                                {/* <NavDropdown.Item eventKey="3.1" className="full-size-link routing-link" as={Link} to={'/projects'}>{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}</NavDropdown.Item> */}
                                <NavDropdown.Item eventKey="3.1" className="full-size-link routing-link" as={Link} to={'/portfolio-home'}>{translatedMessage("MY_CREATIONS")}</NavDropdown.Item>
                                <NavDropdown.Item eventKey="3.2" className="full-size-link routing-link" as={Link} to={'/collection-home'}>{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")}</NavDropdown.Item>
                                {/* <NavDropdown.Item eventKey="3.4" className="full-size-link routing-link" as={Link} to={'/virtual-galleries'}>{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}</NavDropdown.Item> */}
                            </NavDropdown>
                            {administrationMenu}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        );
    }
}

export default NavbarComponent;
