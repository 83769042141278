import React from 'react';

import ExhibitionAdminViewComponent from '../../../components/shared/Exhibition/ExhibitionManageViewComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

class CompanyExhibitionViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id,
      exhibitionId: this.props.match.params.exhibitionId,
      isLoaded: false,
      canView: false,
      errorMessage: '',
      isError: false
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canView: hasPermission("COMPANY_VIEW") || hasPermission("COMPANY_EDIT"),
        isLoaded: true
      })
    });  
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
          <ExhibitionAdminViewComponent
            canView={this.state.canView}
            exhibitionId={this.state.exhibitionId}            
            backUrl={`/company-admin/${this.state.companyId}/exhibition`}
            artworksUrl={`/company-admin/${this.state.companyId}/exhibition-artwork-view/${this.state.exhibitionId}`}
            useVideoUrl={true}/>          
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyExhibitionViewPage;