import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, OverlayTrigger, Tooltip, Image } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import FileService from '../../../services/file.service';
import { translatedMessage } from '../../../services/language.service';

import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import sculptureIcon from '../../../components/shared/Resources/sculptureWhite.png';
import previewIcon from '../../../components/shared/Resources/icons/preview_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

import { toast } from "react-toastify";
import moment from 'moment';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import SimplePricePackageViewCardComponent from '../../../components/shared/PricePackage/CardComponents/SimplePricePackageViewCardComponent';
import HtmlEditorModalComponent from '../../../components/shared/HtmlEditorModalComponent/HtmlEditorModalComponent';
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import ChooseVideoTypeComponentModal from '../../../components/shared/ChooseVideoTypeComponentModal/ChooseVideoTypeComponentModal';
// import ModelViewer from "../../../components/shared/ModelViewer/ModelViewer";

import PublishModalComponent from '../Components/PublishModalComponent/PublishModalComponent';
import SubmittedForApprovalModalComponent from '../Components/SubmittedForApprovalModalComponent/SubmittedForApprovalModalComponent';
import EditFieldModalComponent from '../Components/EditFieldModalComponent';

class ObjectPublishPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      artwork: {},
      pricePackages: [],
      artworkFile: [],
      artworkTags: [],
      pricePackagesTableData: [],
      presentationImages: [],
      artworkVideos: [],
      artPrintFiles: [],
      addImageModal: false,
      addImageModalData: {},
      deleteVideoModal: false,
      deleteVideoModalData: {},
      changeVideoModal: false,
      changeVideoModalData: {},
      publishModalShow: false,
      submittedForApprovalModalShow: false,
      artworkStatusName: '',
      data: {},
      hasAllSignatures: false,
      showEditorModal: false,
      showFieldModal: false,
      modalData: {
        content: '',
        title: '',
        dataFor: ''
      },
      showAddFileModal: false,
      showVideoTypeSelectModal: false,
      isLoaded: false,
      isError: false,
      errorMessage: '',
      modelEnabled: false,
      onlyView: false,
      showPassePModal: false,
      modelFileObject: null,
      hasArtworkFile: false
    };

    this.onShowEdit = this.onShowEdit.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

  populateData() {
    ApiService.getAuthenticatedInstance().get(`/artwork/existing/${this.props.match.params.id}`)
      .then(result => {
        this.setState({
          artwork: {
            ...result.data,
            storageItem: {
              id: result.data.storageItemId
            }
          },
          artworkStatusName: result.data.status.name,
          onlyView: result.data.status.name === ArtworkService.artworkStatusEnum().DRAFT ||
            result.data.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
        }, () => {
          var promise = [];
          var artworkTags = [];
          var pricePackages = [];
          var hasAllSignatures = false;

          promise.push(ApiService.getAuthenticatedInstance().get(`/artworks/${this.state.artwork.id}/artworkTags`)
            .then(result => artworkTags = result.data._embedded.artworkTags.map(artworkTag => artworkTag.additional.tag))
          );

          promise.push(ApiService.getAuthenticatedInstance().get(`/artwork/${this.state.artwork.id}/artworkPricePackages`)
            .then(result => {
              pricePackages = result.data.pricePackages
                .map(artworkPricePackage => {
                  artworkPricePackage.description = translatedMessage(artworkPricePackage.description);

                  return {
                    ...artworkPricePackage
                  }
                })
                .filter(item => item.active)
                .sort((a, b) => a.order - b.order);
            })
          );

          promise.push(this.getStorageItems())

          promise.push(
            ApiService.getAuthenticatedInstance().get(`/artwork/hasAllSignatures/${this.state.artwork.id}`)
              .then(response => hasAllSignatures = response.data)
          );

          Promise.all(promise).then(() => {
            this.setState({
              artworkTags: artworkTags,
              pricePackagesTableData: pricePackages,
              hasAllSignatures: hasAllSignatures,
              isLoaded: true
            })
          })
        });
      })
      .catch(err => {
        var errorMessage = "PROJECT.GENERAL_ERROR";
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });

  }

  getStorageItems = () => {
    let promise = [];
    let presentationImages = [];
    let artworkVideos = [];
    let artworkFile = [];
    // let modelFiles = [];
    let artPrintFiles = [];

    let isPublished = this.state.artwork.status.name === ArtworkService.artworkStatusEnum().PUBLISHED ? true : false;

    promise.push(
      ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.artwork.id}/${ApiService.getEntityName().ARTWORK}`)
        .then(results => {
          const storageFiles = results.data.fileList;

          // modelFiles = storageFiles.filter(file => file.usage === FileService.getFileUsage().OBJECT_MODEL);

          artworkFile = storageFiles.filter(file => file.usage === FileService.getFileUsage().ARTWORK);

          storageFiles.filter(file => file.usage === 'THUMBNAIL').map((image, index) => {
            let mockImage = {};
            mockImage.file = image;
            mockImage.type = FileService.getResourceType().IMAGE;
            mockImage.original = ApiService.getBaseUrl() + '/storage/file/' + image.uuid;
            mockImage.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + image.uuid;
            mockImage.youtubeId = null;
            mockImage.storageItemFileId = image.storageItemFileId;
            mockImage.sortOrder = image.defaultThumbnail ? 0 : index + 1;
            presentationImages.push(mockImage);

            return image;
          });

          presentationImages.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

          storageFiles.filter(file => (file.usage === 'CREATE_DESTROY' || file.usage === 'CREATION_PROC' || file.usage === 'ART_DESC'))
            .map(video => {
              let mockImage = {};
              mockImage.file = video;
              mockImage.type = FileService.getResourceType().VIDEO;
              mockImage.original = ApiService.getBaseUrl() + '/storage/file/' + video.uuid;
              mockImage.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + video.uuid;
              mockImage.youtubeId = video.youtubeId !== null ? video.youtubeId : null;
              mockImage.storageItemFileId = video.storageItemFileId;
              artworkVideos.push(mockImage);

              return video;
            });


          if (!isPublished) {
            storageFiles.filter(file => file.usage === FileService.getFileUsage().ARTWORK_PRINT_PREVIEW).map((print, index) => {
              let mockImage = {};
              mockImage.file = print;
              mockImage.type = FileService.getResourceType().IMAGE;
              mockImage.original = ApiService.getBaseUrl() + '/storage/file/' + print.uuid;
              mockImage.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + print.uuid;
              mockImage.youtubeId = null;
              mockImage.storageItemFileId = print.storageItemFileId;
              artPrintFiles.push(mockImage);

              return print;
            });
          }
        })
    )

    Promise.all(promise).then(() => {
      this.setState({
        presentationImages: presentationImages,
        artworkVideos: artworkVideos,
        hasArtworkFile: artworkFile.length > 0 ? true : false,
        // modelFile: modelFiles.length > 0 ? modelFiles[0] : null,
        artPrintFiles: artPrintFiles,
        // modelFileObject: modelFiles.length > 0 ? ApiService.getFileByUuid(modelFiles[0].uuid) : null,
      })
    })
  }

  handleValidation = (forPreview) => {
    let response = {
      isValid: true,
      errorMessage: []
    }

    let i = 0;
    if (!this.state.hasArtworkFile) {
      response.isValid = false;
      response.errorMessage[i] = "ARTWORK.PUBLISH.MISSING.ARTWORK"
      i += 1;
    }

    if (!this.state.modelFileObject && this.state.presentationImages.length === 0) {
      response.isValid = false;
      response.errorMessage[i] = "ARTWORK.PUBLISH.MISSING.PRESENTATION"
    }

    if (!forPreview && !this.state.hasAllSignatures) {
      response.isValid = false;
      response.errorMessage[i] = "ARTWORK.SIGNATURES.MISSING.NOTE";
    }

    return response;
  }

  onShowOrHidePublishModal = (termsAndConditions = null) => {
    const validationResponse = this.handleValidation();
    if (validationResponse.isValid === true) {
      if (this.state.publishModalShow === false) {
        this.setState({
          publishModalShow: true,
        })
      } else if (!termsAndConditions) {
        this.setState({
          publishModalShow: false,
        })
      } else {
        this.setState({
          publishModalShow: false,
        }, () => {
          ApiService.getAuthenticatedInstance().post(`/artwork/submit-artwork/${this.props.match.params.id}`)
            .then((result) => {
              this.setState({
                artworkStatusName: ArtworkService.artworkStatusEnum().SUBMITTED_FOR_APPROVAL
              })
              this.onShowOrHideSubmittedForApprovalModal();
            });
        })
      }
    } else {
      validationResponse.errorMessage.forEach(message => {
        toast.error(translatedMessage(message), { autoClose: 7000 });
      })
    }
  };

  onShowOrHideSubmittedForApprovalModal = () => {
    if (this.state.submittedForApprovalModalShow === false) {
      this.setState({
        submittedForApprovalModalShow: true,
      })
    } else {
      this.setState({
        submittedForApprovalModalShow: false,
        onlyView: true
      });
    }
  };

  handlePreview = () => {
    const validationResponse = this.handleValidation(true);
    if (validationResponse.isValid === true) {
      Promise.all([
        ApiService.getAuthenticatedInstance().patch(`/projects/${this.props.match.params.id}`, {
          status: ArtworkService.artworkStatusEnum().PREVIEW,
        }),
        ApiService.getAuthenticatedInstance().patch(`/artworks/${this.state.artwork.id}`, {
          status: ArtworkService.artworkStatusEnum().PREVIEW,
        }),
      ]).then(() => {
        this.setState({
          artworkStatusName: ArtworkService.artworkStatusEnum().PREVIEW
        })
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      }).catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      });
    } else {
      validationResponse.errorMessage.forEach(message => {
        toast.error(translatedMessage(message), { autoClose: 7000 });
      })
    }
  }

  onShowEdit = (event) => {
    if (event.currentTarget.id === 'aboutArtwork') {
      this.setState({
        modalData: {
          content: this.state.artwork.description,
          title: translatedMessage("ARTWORK.ABOUT_ARTWORK"),
          dataFor: 'aboutArtwork',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'aboutAuthor') {
      this.setState({
        modalData: {
          content: this.state.artwork.aboutAuthor ? this.state.artwork.aboutAuthor : "",
          title: translatedMessage("ARTWORK.ABOUT_AUTHOR"),
          dataFor: 'aboutAuthor',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'artworkName') {
      this.setState({
        modalData: {
          content: this.state.artwork.name,
          title: translatedMessage("PROJECT.ARTWORK_NAME"),
          dataFor: 'artworkName',
        },
        showFieldModal: true,
      })
    }
  }

  onHideEdit = (data) => {
    if (data && data.content && data.dataFor) {
      let description = this.state.artwork.description;
      let aboutAuthor = this.state.artwork.aboutAuthor;
      let artworkName = this.state.artwork.name;

      if (data.dataFor === "aboutArtwork") {
        description = data.content;
      } else if (data.dataFor === "aboutAuthor") {
        aboutAuthor = data.content;
      } else if (data.dataFor === "artworkName") {
        artworkName = data.content;
      }

      const promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/artworks/${this.state.artwork.id}`, {
          name: artworkName,
          description: description,
          aboutAuthor: aboutAuthor,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
        resolve();
      });

      promise.then(() => {
        toast.success(translatedMessage("ARTWORK.SAVE_SUCCESS"));
        this.setState({
          artwork: {
            ...this.state.artwork,
            name: artworkName,
            description: description,
            aboutAuthor: aboutAuthor
          }
        })
      })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);
        });
    }
    this.setState({
      showEditorModal: false,
      showFieldModal: false
    })
  }

  onShowAddFile = (event) => {
    if (event.currentTarget.id === 'btnPresImg') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.artwork,
        storageitemlinked: true
      }
      this.setState({
        showAddFileModal: true,
        fileModalData: modalData
      })
    } else if (event.currentTarget.id === 'btnVideo') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_VIDEO"),
        fileType: FileService.getResourceType().VIDEO,
        deletePrevious: true,
        deletedImage: null,
        deletePreviousDesc: "DROPZONE.DELETE_PREVIOUS_VIDEO",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.VIDEO",
        maxSize: 100,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: false,
        multipleFilesDesc: "",
        entity: this.state.artwork,
        storageitemlinked: true
      }
      this.setState({
        showVideoTypeSelectModal: true,
        fileModalData: modalData
      })
    }
  }

  onHideVideoTypeSelect = (type) => {
    if (type) {
      let modalData = this.state.fileModalData;
      modalData.fileUsage = type;

      let selectedVideo = this.state.artworkVideos.filter(item => item.file.usage === type);
      if (selectedVideo[0] && selectedVideo[0].file && selectedVideo[0].file.uuid) {
        modalData.deletedImage = selectedVideo[0].file
      }

      this.setState({
        showAddFileModal: true,
        showVideoTypeSelectModal: false,
        fileModalData: modalData
      })
    } else {
      this.setState({
        showVideoTypeSelectModal: false,
      })
    }

  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getStorageItems();
    }
    this.setState({
      showAddFileModal: false,
      showAddVideoModal: false
    })
  }

  handleMediaListChange = () => {
    this.getStorageItems();
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        var isPreview = this.state.artworkStatusName === ArtworkService.artworkStatusEnum().PREVIEW;
        const homeUrl = this.state.artwork.companyId ? `/my-company/${this.state.artwork.companyId}/portfolio` : `/projects`;

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-md-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={sculptureIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-lg-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_3D_OBJECT")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("PROJECT.PUBLISH_REVIEW")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={homeUrl} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>
                </div>

                <div className="ca-page-padding ca-main-container">
                  <Row className="mb-1">
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("ARTWORK.DETAILS")}
                      </div>
                    </Col>

                    {/* {this.state.modelEnabled && this.state.modelFileObject &&
                      <Col className="col-12 col-lg-6 ca-artwork-column-left align-items-center mb-3">
                        <ModelViewer modelFile={this.state.modelFileObject} artworkName={this.state.artwork.name} styleClass="ca-model-viewer" />
                      </Col>
                    } */}
                    {/* <Col className={this.state.modelEnabled && this.state.modelFileObject ? "col-12 col-lg-6 ca-artwork-column-right" : "col-12 pl-0"}> */}
                    <Col className={"col-12 pl-0"}>
                      <Row className="mb-0">
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label d-flex align-items-end">
                              {translatedMessage("PROJECT.ARTWORK_NAME")}
                              {!this.state.onlyView &&
                                <Button id="artworkName" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                                  <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                                </Button>
                              }
                            </div>
                            <div className="ca-view-value">{this.state.artwork.name}</div>
                          </div>
                        </Col>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("PROJECT.CATEGORY")}
                            </div>
                            <div className="ca-view-value">{translatedMessage(this.state.artwork.category.code)}</div>
                          </div>
                        </Col>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                            </div>
                            <div className="ca-view-value">{this.state.artwork.sizeWidth} W x {this.state.artwork.sizeHeight} H cm</div>
                          </div>
                        </Col>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("GENERAL.TAGS")}
                            </div>
                            <div className="ca-view-value">
                              {this.state.artworkTags.map((element, index) => {
                                if (this.state.artworkTags.indexOf(element) !== this.state.artworkTags.length - 1) {
                                  return (
                                    <span key={index}>{element},&nbsp;</span>
                                  )
                                } else {
                                  return (
                                    <span key={index}>{element}</span>
                                  )
                                }
                              })
                              }
                              {this.state.artworkTags.length === 0 ? "-" : ""}
                            </div>
                          </div>
                        </Col>

                        <Col className="col-12 pl-0 mt-3">
                          <div className="ca-labe d-flex align-items-end">
                            {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                            <Button id="aboutArtwork" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                          </div>
                          {this.state.artwork.description
                            ? <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: this.state.artwork.description }} />
                            : <div className="ca-description-value">-</div>
                          }
                        </Col>

                        <Col className="col-12 pl-0 mt-3">
                          <div className="ca-label d-flex align-items-end">
                            {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                            <Button id="aboutAuthor" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                          </div>
                          {this.state.artwork.aboutAuthor
                            ? <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: this.state.artwork.aboutAuthor }} />
                            : <div className="ca-description-value">-</div>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("ARTWORK.PRICING")}
                      </div>
                    </Col>
                    {this.state.pricePackagesTableData.map((element, index) => {
                      return (
                        <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0">
                          <SimplePricePackageViewCardComponent
                            data={element}
                            background="dark"
                            category={ArtworkService.artworkCategoryEnum().OBJECT}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("GENERAL.MEDIA")}
                      </div>
                    </Col>
                    <Col className="col-12 col-md-6 pl-0">
                      <div className="w-100 mb-3 font-weight-bold d-flex align-items-end">
                        <span>
                          {translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                          <OverlayTrigger
                            key='addImage'
                            placement='bottom'
                            overlay={
                              <Tooltip id={`tooltip-addImage`} className="ca-tooltip">
                                {translatedMessage("ARTWORK.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                              </Tooltip>
                            }
                          >
                            <span className="ca-question-icon"></span>
                          </OverlayTrigger>
                        </span>

                        <span className="d-inline-block">
                          <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                            <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                          </Button>
                        </span>
                      </div>
                      {this.state.presentationImages.length > 0 &&
                        <Carousel
                          id="presImg"
                          items={this.state.presentationImages}
                          controls={true}
                          indicators={false}
                          interval={null}
                          class="mb-5"
                          hasToolbar={true}
                          toolbarDelete={true}
                          toolbarFullScreen={true}
                          canDeleteLast={true}
                          onChange={this.handleMediaListChange}
                        />
                      }
                    </Col>
                    <Col className="col-12 col-md-6 pl-0">
                      <div className="w-100 mb-3 font-weight-bold d-flex align-items-end">
                        <span>
                          {translatedMessage("PROJECT.VIDEOS")}
                        </span>

                        <span className="d-inline-block">
                          <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                            <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />
                            {this.state.artworkVideos.length > 0 &&
                              <Image src={editIcon} alt={translatedMessage("GENERAL.CHANGE")} title={translatedMessage("GENERAL.CHANGE")} />
                            }
                          </Button>
                        </span>
                      </div>
                      {this.state.artworkVideos.length > 0 &&
                        <Carousel
                          id="video"
                          items={this.state.artworkVideos}
                          controls={true}
                          indicators={false}
                          interval={null}
                          class="mb-5"
                          hasToolbar={true}
                          toolbarDelete={true}
                          toolbarYoutubeId={true}
                          canDeleteLast={true}
                          onChange={this.handleMediaListChange}
                        />
                      }
                    </Col>
                  </Row>

                  {this.state.artwork.hasPrintFile && this.state.artPrintFiles && this.state.artPrintFiles.length > 0 &&
                    <>
                      <hr></hr>
                      <Row>
                        <Col className="col-12 pl-0">
                          <div className="ca-paragraph-title w-100">
                            {translatedMessage("PROJECT.UPLOAD_PRINT_FILE")}
                          </div>                          
                        </Col>
                        <Col className="col-12 pl-0 ca-ar-art-publish-preview-print-container">
                          <Carousel
                            id="printFiles"
                            items={this.state.artPrintFiles}
                            controls={true}
                            indicators={false}
                            interval={null}
                            class="mb-5"
                            hasToolbar={true}
                            toolbarFullScreen={true}
                            toolbarDelete={false}
                            toolbarYoutubeId={false}
                            toolbarPassepartout={true}
                            canDeleteLast={false}
                            entityId={this.props.match.params.id}
                            entityName={ApiService.getEntityName().ARTWORK}
                            onChange={this.handleMediaListChange}
                          />
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className="ca-button-icon" variant="link"
                    onClick={() => this.props.history.push(`/project/publish/digital-sculpture-price-package/${this.props.match.params.id}`)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div>
                  <OverlayTrigger
                    key='pPrev'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-pPrev`} className="ca-tooltip">
                        {isPreview ? translatedMessage("PROJECT.STATUS.PREVIEW_MODE") : translatedMessage("PROJECT.MOBILE_PREVIEW.TOOLTIP")}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <span className={isPreview || this.state.onlyView ? "ca-text-disabled-dark" : ""}>{translatedMessage("PROJECT.MOBILE_PREVIEW")}</span>
                      <Button variant="link" className="ca-button-icon ml-2" onClick={this.handlePreview}
                        disabled={isPreview || this.state.onlyView}>
                        <Image src={previewIcon} alt={translatedMessage("PROJECT.MOBILE_PREVIEW")} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="d-flex align-items-center">
                  <OverlayTrigger
                    key='pPubB'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-pPubB`} className="ca-tooltip">
                        {!this.state.hasAllSignatures ? translatedMessage("ARTWORK.SIGNATURES.MISSING.NOTE") : translatedMessage("PROJECT.PUBLISH")}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <span className={!this.state.hasAllSignatures || this.state.onlyView ? "ca-text-disabled-dark" : ""}>{translatedMessage("PROJECT.PUBLISH")}</span>
                      <Button className="ca-button-icon" variant="link" onClick={this.onShowOrHidePublishModal} disabled={!this.state.hasAllSignatures || this.state.onlyView}>
                        <Image src={nextArrow} alt={translatedMessage("PROJECT.PUBLISH")} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <PublishModalComponent
              show={this.state.publishModalShow}
              onHide={this.onShowOrHidePublishModal}
              name={this.state.artwork.name}
              pagetitle="ARTWORK.SUBMIT_CONFIRM"
              pageinfo="ARTWORK.SUBMIT_CONFIRM_INFO"
            />

            <SubmittedForApprovalModalComponent
              show={this.state.submittedForApprovalModalShow}
              onHide={this.onShowOrHideSubmittedForApprovalModal}
              message={"ART.SUBMITTED.SUCCESS"}
            />

            <HtmlEditorModalComponent
              show={this.state.showEditorModal}
              onHide={this.onHideEdit}
              data={this.state.modalData}
            />

            <EditFieldModalComponent
              show={this.state.showFieldModal}
              onHide={this.onHideEdit}
              data={this.state.modalData}
            />

            <AddFileModalComponent
              show={this.state.showAddFileModal}
              onHide={this.onHideAddFile}
              data={this.state.fileModalData}
            />

            <ChooseVideoTypeComponentModal
              show={this.state.showVideoTypeSelectModal}
              onHide={this.onHideVideoTypeSelect}
              data={this.state.artworkVideos}
            />
          </>
        )
      }
    }
  }
}

export default ObjectPublishPreview;