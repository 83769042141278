import React from 'react'

import { translatedMessage } from '../../services/language.service';
import ApiService from '../../services/api.service';

import { Button } from 'react-bootstrap';

import { toast } from 'react-toastify';

import { Link } from 'react-router-dom'

class MembershipBlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedInterval: null,
      enabledForUpgrade: false
    };
  }

  render() {
    const activeMembershipBlock = {
      border: '1px solid black',
      borderRadius: '10px',
      backgroundColor: 'black',
      paddingBottom: '10px',
      color: 'white'
    }

    const membershipBlock = {
      border: '1px solid black',
      borderRadius: '10px',
      backgroundColor: 'white',
      paddingBottom: '10px',
      color: 'black'
    }

    const membershipBlockTitle = {
      paddingTop: '10px',
      textAlign: 'center'
    }

    const membershipBlockContent = {
      // backgroundColor: 'white',
      // color: 'black',
      minHeight: '400px',
      paddingTop: '15px'
    }

    const membershipFooterContent = {
      textAlign: 'center',
      lineHeight: '40px',
      paddingTop: '10px'
    }

    const downgradeWarning = {
      border: '1px solid black',
      borderRadius: '10px',
      margin: '20px 7px',
      color: '#ffffff',
      backgroundColor: '#000000',
      padding: '5px',
      fontSize: '13px'
    }

    let logo = this.props.selectedLogo;
    let block = activeMembershipBlock;
    let hr = { borderTopColor : '#ffffff' };
    if (!this.props.current) {
      logo = this.props.logo;
      block = membershipBlock;
      hr = { borderTopColor : '#000000' }
    }

    let upgradeClick = (membershipName, interval) => {
      ApiService.upgrade(membershipName, interval).then(result => {
        if (result.status === "OK") {
          toast.success(translatedMessage("MEMBERSHIP.UPGRADE.SUCCESS"));
        } else if (result.status === "NEEDS_PAYMENT") {
          toast.info(translatedMessage("MEMBERSHIP.UPGRADE.NEEDS_PAYMENT"));
        }
        window.location.href = result.url;
      });
    }

    let downgradeClick = (membershipName, interval) => {
      ApiService.downgrade(membershipName, interval).then(result => {
        if (result.status === "OK") {
          toast.success(translatedMessage("MEMBERSHIP.DOWNGRADE.SUCCESS"));
        } else {
          toast.error(translatedMessage("MEMBERSHIP.DOWNGRADE.ERROR"));
        }
        window.location.reload();
      });
    }

    const intervalSelectionChanged = (e) => {
      this.setState({
        selectedInterval: e.target.value,
        enabledForUpgrade: true
      })
    }

    const requestedDowngradeBlock = () => {
      return (
        <div style={downgradeWarning}>
          {translatedMessage("MEMBERSHIP.DOWNGRADE.REQUEST")} <b>{new Intl.DateTimeFormat().format(this.props.downgradeRequestDate)}</b>. <br /><br />
          {translatedMessage("MEMBERSHIP.DOWNGRADE.REQUEST_START")}  <b>{new Intl.DateTimeFormat().format(this.props.downgradeDate)}</b>
        </div>
      )
    }

    return (
      <div style={block}>
        <div style={membershipBlockTitle}>
          <img alt=""
            src={logo}
            width="auto"
            height="100"
            className="d-inline-block align-center mb-3"
          />
        </div>

        <div style={membershipBlockContent}>
          {this.props.children}

          <hr style={hr}/>

          {this.props.requestedDowngrade === this.props.name ? requestedDowngradeBlock() : ""}

          <div hidden={this.props.name === "Member"} >
            
            <div style={{ paddingLeft: "20px" }} >
              <div className="font-bold mb-2">{translatedMessage("MEMBERSHIP.RECCURENT_PAYMENT.OPTIONS")}</div>
              <label>
                <input type="radio"
                  name={"interval-" + this.props.name.replaceAll(" ", "_")}
                  onChange={intervalSelectionChanged}
                  checked={this.state.selectedInterval ? this.state.selectedInterval === "MONTHLY" : (this.props.current && this.props.currentInterval === "MONTHLY")}
                  value="MONTHLY" />
                <span className="ml-1">{translatedMessage("MEMBERSHIP." + this.props.name.replaceAll(" ", "_").toUpperCase() + ".UPGRADE_MONTHLY")}</span>
              </label> <br />
              <label>
                <input type="radio"
                  name={"interval-" + this.props.name.replaceAll(" ", "_")}
                  onChange={intervalSelectionChanged}
                  checked={this.state.selectedInterval ? this.state.selectedInterval === "YEARLY" : (this.props.current && this.props.currentInterval === "YEARLY")}
                  value="YEARLY" />
                <span className="ml-1">{translatedMessage("MEMBERSHIP." + this.props.name.replaceAll(" ", "_").toUpperCase() + ".UPGRADE_YEARLY")}</span>
              </label>
            </div>
          </div>
        </div>
        <div style={membershipFooterContent}>
          <Button className="full-size-link ca-button ca-button-white"
            hidden={!this.props.downgrade || this.props.requestedDowngrade === this.props.name}
            disabled={this.state.selectedInterval === null && this.props.name !== 'Member'}
            onClick={() => downgradeClick(this.props.name, this.state.selectedInterval)}
            to={'membership'}>{translatedMessage("MEMBERSHIP.DOWNGRADE")}</Button>

          <Button className="full-size-link ca-button"
            hidden={!this.props.upgrade}
            disabled={!this.state.enabledForUpgrade}
            onClick={() => upgradeClick(this.props.name, this.state.selectedInterval)}
            to={'membership'}>{translatedMessage("MEMBERSHIP.UPGRADE")}</Button>

          <Link className="full-size-link ca-button ca-button-white"
            hidden={!this.props.current}
            to={'membership'}>{translatedMessage("MEMBERSHIP.CURRENT")} {this.props.currentInterval ? " - " + this.props.currentInterval : ""}</Link>
        </div>
      </div>
    )
  }

}

export default MembershipBlock;