import React from 'react'
import "./SocialMediaPage.css"

import { Row, Col, Button, Form } from 'react-bootstrap';

import { toast } from "react-toastify";

import { translatedMessage } from '../../../services/language.service';
import ApiService from '../../../services/api.service';

import WebResourcesPage from "../WebResourcesPage/WebResourcesPage"

class SocialMediaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            socialMediaInfo: {
                patreon: "",
                facebook: "",
                linkedin: "",
                instagram: "",
                pinterest: "",
                twitter: "",
            },
        };

        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        let profile = ApiService.getCurrentProfile();
        if (!profile) {
            ApiService.initUser().then(() => this.getData());
        } else {
            this.getData()
        }
    }

    getData() {
        let profile = ApiService.getCurrentProfile();
        this.setState({
            socialMediaInfo: {
                ...this.state.socialMediaInfo,
                patreon: profile.patreonUri ? profile.patreonUri : "",
                facebook: profile.facebookUri ? profile.facebookUri : "",
                linkedin: profile.linkedinUri ? profile.linkedinUri : "",
                instagram: profile.instagramUri ? profile.instagramUri : "",
                pinterest: profile.pinterestUri ? profile.pinterestUri : "",
                twitter: profile.twitterUri ? profile.twitterUri : "",
            }
        })
    }

    saveChanges(event) {
        event.preventDefault();
        const promise = new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().patch(`/profiles/${ApiService.getCurrentProfile().id}`, {
                facebookUri: this.state.socialMediaInfo.facebook,
                instagramUri: this.state.socialMediaInfo.instagram,
                linkedinUri: this.state.socialMediaInfo.linkedin,
                patreonUri: this.state.socialMediaInfo.patreon,
                pinterestUri: this.state.socialMediaInfo.pinterest,
                twitterUri: this.state.socialMediaInfo.twitter,
            }).then(resolve)
            resolve();
        });

        promise.then(() => {
            toast.success(translatedMessage("PROFILE.SOCIAL_MEDIA.SAVE_SUCCESS"));
        })


    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            socialMediaInfo: {
                ...this.state.socialMediaInfo,
                [name]: value
            }
        });
    }

    render() {
        return (
            <>
                <div className="ca-page-padding ca-main-container mb-5">
                    <Row className="ca-page-title-button-row mb-3 pr-2">
                        <span className="ca-page-subtitle">{translatedMessage("PROFILE.SOCIAL_MEDIA_PROFILES")}</span>

                        <div>
                            <Button className="ca-button" variant="dark" type="submit" form="socialMediaForm">
                                <span>{translatedMessage("GENERAL.SAVE_CHANGES")}</span>
                            </Button>
                        </div>
                    </Row>
                    <Form id="socialMediaForm" onSubmit={this.saveChanges} className="ca-form">
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="formPatreon">
                                <Form.Label>Patreon</Form.Label>
                                <Form.Control
                                    name="patreon"
                                    value={this.state.socialMediaInfo.patreon}
                                    onChange={this.userInfoChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formFacebook">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control
                                    name="facebook"
                                    value={this.state.socialMediaInfo.facebook}
                                    onChange={this.userInfoChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formLinkedin">
                                <Form.Label>LinkedIn</Form.Label>
                                <Form.Control
                                    name="linkedin"
                                    value={this.state.socialMediaInfo.linkedin}
                                    onChange={this.userInfoChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formInstagram">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control
                                    name="instagram"
                                    value={this.state.socialMediaInfo.instagram}
                                    onChange={this.userInfoChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formPinterest">
                                <Form.Label>Pinterest</Form.Label>
                                <Form.Control
                                    name="pinterest"
                                    value={this.state.socialMediaInfo.pinterest}
                                    onChange={this.userInfoChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formTwitter">
                                <Form.Label>Twitter</Form.Label>
                                <Form.Control
                                    name="twitter"
                                    value={this.state.socialMediaInfo.twitter}
                                    onChange={this.userInfoChange}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>

                <WebResourcesPage />
            </>
        )
    }

}

export default SocialMediaPage;