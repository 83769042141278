import React from 'react';

import { Modal, Button } from 'react-bootstrap';

import { translatedMessage } from "../../../services/language.service";

class DeleteUserModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    deleteUser = () => {
        this.props.onHide(this.props.usercompanyid);
    }

    cancelDelete = () => {
        this.props.onHide(null);
    }    

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="web-resource-delete-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ca-modal-delete-confirm text-center">
                    {translatedMessage("COMPANY.DELETE_ADDRESS.CONFIRM")}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button ca-button-white" variant="dark" onClick={this.deleteUser}>{translatedMessage("GENERAL.YES")}</Button>
                    <Button className="ca-button" variant="dark" onClick={this.cancelDelete}>{translatedMessage("GENERAL.NO")}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DeleteUserModalComponent