import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Switch from "react-switch";
import { translatedMessage } from '../../../../services/language.service';
import Form from "react-bootstrap/Form";

class EditExperienceModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expereince: this.props.data,
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  // Replaced componentWillReceiveProps
  // UNSAFE_componentWillReceiveProps(nextProps){
  //   if(nextProps.data!==this.props.data){
  //     this.setState({expereince: nextProps.data });
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.data !== this.props.data){
      this.setState({expereince: this.props.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){ 
    if(nextProps.data !== prevState.expereince){
      return { expereince: prevState.expereince};
   }
   else return null;
 }

  saveChanges = (event) => {
    event.preventDefault();
    this.setState({
      expereince: {
        ...this.state.expereince,
        name: event.target.elements.formName.value,
        description: event.target.elements.formDescription.value,
      }
    }, this.onHide)
  }

  onHide = () => {
    this.props.onHide(this.state.expereince)
  }

  onChangeIsMain = (checked, event, id) => {
    this.setState({
      expereince: {
        ...this.state.expereince,
        isMain: checked
      }
    })
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("PROJECT.ADD_ON")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Group controlId="formName">
              <Form.Label>{translatedMessage("ADD_ON.NAME")}</Form.Label>
              <Form.Control
                placeholder={translatedMessage("ADD_ON.NAME_PLACEHOLDER")}
                defaultValue={this.state.expereince.name}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>{translatedMessage("ADD_ON.SHORT_DESCRIPTION") + " (" + translatedMessage("ADD_ON.SHORT_DESCRIPTION.MAX_LENGTH") + ")"}</Form.Label>
              <Form.Control
                as="textarea" rows={3} maxLength="150"
                placeholder={translatedMessage("ADD_ON.SHORT_DESCRIPTION_PLACEHOLDER")}
                defaultValue={this.state.expereince.description}
              />              
            </Form.Group>

            <Form.Group controlId="formIsMain">
              <Form.Label className="w-100">{translatedMessage("ADD_ON.IS_MAIN_EXPERIENCE")}</Form.Label>
              <Switch
                onChange={this.onChangeIsMain}
                checked={this.state.expereince.isMain}
                disabled={this.props.hasmain === "true" && !this.props.data.isMain}
                offColor="#F00"
                id="is-main-experience"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default EditExperienceModalComponent;