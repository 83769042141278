import React from 'react';
import {useHistory} from 'react-router-dom'
import LanguageService from '../../../services/language.service';


function ChangeLanguageComponent({match}) {
    const history = useHistory();

    LanguageService.setCurrentLanguage(match.params.lang);

    history.go(-1);

    return (
        <>
            <p>Changing language...</p>
        </>
    );
}

export default ChangeLanguageComponent;
