import React from 'react';

import { translatedMessage } from '../../../services/language.service';

import { Modal, Button, Form } from "react-bootstrap";

import { toast } from "react-toastify";


class ProfileRolesEditModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: this.props.profile,
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  componentDidMount = () => {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profile !== this.props.profile) {
      this.setState({ profile: this.props.profile });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.profile !== prevState.profile) {
      return { profile: prevState.profile };
    }
    else return null;
  }

  onProfileChange = (event) => {
    if (!event.target) {
      return;
    }

    const name = event.target.name;
    const value = event.target.checked;

    this.setState({
      profile: {
        ...this.state.profile,
        [name]: value
      }
    });
  }

  saveChanges = (event) => {
    event.preventDefault();

    let profile = this.state.profile;

    if(!profile.artist && !profile.buyer && !profile.expert && !profile.investor && !profile.institution){
      toast.error(translatedMessage("PROFILE.SAVE_ROLES.ERROR"), { autoClose: 7000, pauseOnHover: true });
      this.setState({
        profile: this.props.profile
      })
    } else {
      profile.dataFor = "roles";
      this.props.onHide(profile)  
    }    
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("PROFILE.YOUR_ROLES")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Check
              checked={!!this.state.profile.artist}
              type="checkbox"
              label={translatedMessage("PROFILE.ARTIST")}
              onChange={this.onProfileChange}
              name="artist"
            />

            <Form.Check
              checked={!!this.state.profile.buyer}
              type="checkbox"
              label={translatedMessage("PROFILE.ART_CONSUMER")}
              onChange={this.onProfileChange}
              name="buyer"
            />

            <Form.Check
              checked={!!this.state.profile.investor}
              type="checkbox"
              label={translatedMessage("PROFILE.ART_INVESTOR")}
              onChange={this.onProfileChange}
              name="investor"
            />

            <Form.Check
              checked={!!this.state.profile.expert}
              type="checkbox"
              label={translatedMessage("PROFILE.EXPERT_CO_WORKER")}
              onChange={this.onProfileChange}
              name="expert"
            />

            <Form.Check
              checked={!!this.state.profile.institution}
              type="checkbox"
              label={translatedMessage("PROFILE.ART_INSTITUTION")}
              onChange={this.onProfileChange}
              name="institution"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default ProfileRolesEditModalComponent;