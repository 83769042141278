import React from 'react';

import { Row, Col, Form, Button, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
// import Switch from "react-switch";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";

import ProjectCreateModalPage from './ProjectCreateModalComponent/ProjectCreateModalComponent'

import ApiService from '../../../services/api.service';
import moment from 'moment';

import LanguageService from '../../../services/language.service';
import { translatedMessage } from '../../../services/language.service';
import uuidv4 from 'uuid/v4';
import { toast } from "react-toastify";
import FileService from "../../../services/file.service";


class ArtProjectCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectCreateModalShow: false,
            projectId: '',
            description: "",
            projectPicture: null,
            editorButtns: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
            dropzoneEnabled: true,
            communityDisplay: false,
            cummunityDescription: "",
            cummunityEditorEnabled: false,
            projectImageUrl: "",
            displayImg: false,
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const artworkName = event.target.elements.formArtworkName.value;

        const describeArtwork = this.state.description;
        const communityDisplay = this.state.communityDisplay;
        const communityDescription = this.state.communityDescription;

        this.doSave(ApiService.getCurrentUser(), artworkName, describeArtwork, communityDisplay, communityDescription)
            .then(result => {
                this.setState({
                    projectId: result.data._links.self.href.split("/")[result.data._links.self.href.split("/").length - 1],
                }, () => this.setState({ projectCreateModalShow: true }))
            }).catch(err => {
                toast.error(translatedMessage("PROJECT.ERROR_PICTURE"));
            });
    };

    doSave = (user, name, description, communityDisplay, communityDescription) => {
        return new Promise((resolve, reject) => {
            if (!this.state.projectPicture.id) {
                reject();
                return;
            }

            ApiService.getAuthenticatedInstance().post('/storageItems', {
                name,
                type: 'PROJECT_FOLDER',
                uuid: uuidv4(),
                lastUpdatedBy: user.username,
                lastUpdatedOn: moment().unix(),
            }).then(projectStorageResult => {
                const parts = projectStorageResult.data._links.self.href.split('/');
                const storageItemId = parts[parts.length - 1];

                FileService.linkToStorageItem(
                    storageItemId,
                    this.state.projectPicture.id,
                    'PROJECT',
                ).then(() => {
                    ApiService.getAuthenticatedInstance().post('/projects', {
                        name,
                        description,
                        displayInCommunity: communityDisplay,
                        communityDescription: communityDisplay ? communityDescription : null,
                        owner: this.props.companyId ? null : `/users/${user.id}`,
                        company: this.props.companyId ? `/companies/${this.props.companyId}` : null,
                        lastUpdatedBy: user.username,
                        lastUpdatedOn: moment().unix(),
                        storageItem: `/storageItems/${storageItemId}`,
                        category: `/projectCategories/${this.props.projectCategory}`,
                        status: 'IN_PROGRESS',
                    }).then(resolve);
                });
            });
        });
    };

    onChangeEditor = (e) => { this.setState({ description: e }) };

    onChangeCommunityEditor = (e) => { this.setState({ communityDescription: e }) };

    onChangeCommunityDisplay = (checked, event, id) => {
        this.setState({
            communityDisplay: checked,
            cummunityEditorEnabled: checked
        });
    }

    hideModal = () => { this.setState({ projectCreateModalShow: false }) };

    onUploadFile = (dropzoneEnabled, uploadedFile) => {
        if (dropzoneEnabled) {
            setTimeout(() => {
                this.setState({
                    dropzoneEnabled: dropzoneEnabled,
                    projectPicture: uploadedFile,
                    projectImageUrl: ApiService.getFileByUuid(uploadedFile.uuid),
                    displayImg: !this.state.displayImg,
                })
            }, 500);
        } else {
            this.setState({
                dropzoneEnabled: dropzoneEnabled,
                projectPicture: uploadedFile,
            });
        }
    }

    changeImgDropzone = () => {
        this.setState({ displayImg: !this.state.displayImg })
    }

    render() {
        let img_class = this.state.displayImg ? "ca-form-image-container" : "ca-form-image-container-hide";
        let dropzone_class = !this.state.displayImg ? "ca-dropzone-show" : "ca-dropzone-hide";

        return (
            <>

                <div className="w-100 ca-publish-page-title d-flex align-items-center">
                    <Image src={this.props.icon} alt="AR Art icon" className="icon" />
                    <div>
                        <span className="font-weight-bold mr-1">{translatedMessage(this.props.pageTitle)}</span>
                        <span className="font-weight-bold mr-1">|</span>
                        <span>{translatedMessage("PROJECT.CREATE_PROJECT")}</span>
                    </div>
                </div>

                <div className="ca-page-padding ca-main-container">
                    <ProjectCreateModalPage
                        show={this.state.projectCreateModalShow}
                        onHide={this.hideModal}
                        projectid={this.state.projectId}
                        history={this.props.history}
                        companyid={this.props.companyId}
                        nexturl={`${this.props.nexturl}/${this.state.projectId}`}
                    />

                    <Form className="ca-form" onSubmit={this.handleSubmit}>
                        <Row className="mb-1">
                            <Col className="col-12 col-lg-8 ca-row-left-column">
                                <Form.Group controlId="formArtworkName">
                                    <Form.Label>
                                        {translatedMessage("PROJECT.ARTWORK_NAME")}
                                        <OverlayTrigger
                                            key='nT'
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id={`tooltip-nt`} className="ca-tooltip">
                                                    {translatedMessage("PROJECT.ARTWORK_NAME.TOOLTIP")}
                                                </Tooltip>
                                            }
                                        >
                                            <span className="ca-question-icon"></span>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder={translatedMessage("PROJECT.ARTWORK_NAME_PLACEHOLDER")}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formArtworkDescription">
                                    <Form.Label>{translatedMessage("PROJECT.DESCRIBE_ARTWORK")}</Form.Label>
                                    <div className="ca-text-editor">
                                        <SunEditor
                                            name="project-editor"
                                            lang={LanguageService.getCurrentLanguage()}
                                            placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                            setContents={this.state.description}
                                            setOptions={{
                                                height: 150,
                                                maxWidth: "100%",
                                                buttonList: this.state.editorButtns,
                                                font: this.state.editorFonts,
                                                defaultStyle: this.state.editorDefaultStyle
                                            }}
                                            onChange={this.onChangeEditor}
                                        />
                                    </div>
                                </Form.Group>

                                {/* FIXME: Enable when community functionality is enabled */}
                                {/* <Form.Group controlId="formDisplayCmmunity">
                                    <div className="d-flex align-items-center">
                                        <Form.Label className="mb-0 mr-1">{translatedMessage("PROJECT.DISPLAY_IN_COMMUNITY_ASK")}</Form.Label>
                                        <Switch
                                            onChange={this.onChangeCommunityDisplay}
                                            checked={this.state.communityDisplay}
                                            offColor="#F00"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="formArtworkCommunityDescription">
                                    {this.state.cummunityEditorEnabled &&
                                        <div className="mb-1">{translatedMessage("PROJECT.COMMUNITY_DESCRIPTION_DETAILS")}</div>
                                    }
                                    <div className="ca-text-editor">
                                        <SunEditor
                                            name="project-community-editor"
                                            lang={LanguageService.getCurrentLanguage()}
                                            placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                            setContents={this.state.communityDescription}
                                            hide={!this.state.cummunityEditorEnabled}
                                            setOptions={{
                                                height: 150,
                                                maxWidth: "100%",
                                                buttonList: this.state.editorButtns,
                                                font: this.state.editorFonts,
                                                defaultStyle: this.state.editorDefaultStyle
                                            }}
                                            onChange={this.onChangeCommunityEditor}
                                        />
                                    </div>
                                </Form.Group> */}
                            </Col>
                            <Col className="col-12 col-lg-4 ca-row-right-column">
                                <Form.Group controlId="formProjectImage">
                                    <div>
                                        <Form.Label>{translatedMessage("PROJECT.IMAGE")}
                                            <OverlayTrigger
                                                key='iT'
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip id={`tooltip-it`} className="ca-tooltip">
                                                        {translatedMessage("PROJECT.IMAGE.TOOLTIP")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ca-question-icon"></span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                    </div>

                                    <div className={img_class} onClick={this.changeImgDropzone.bind(this)} style={{ width: "100%", textAlign: "center" }}>
                                        <Image src={this.state.projectImageUrl} />
                                        <div className="ca-form-image-change-details">
                                            <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf030;</div>
                                            <div>{translatedMessage("GENERAL.IMAGE.TOOLTIP")}</div>
                                        </div>
                                    </div>
                                    <div className={dropzone_class + " ca-dropzone-tall"}>
                                        <DropzoneComponent
                                            componentId="projectImage"
                                            fileUsage={FileService.getFileUsage().PROJECT}
                                            fileType={FileService.getResourceType().IMAGE}
                                            deletePrevious={true}
                                            deletedImage={this.state.projectPicture}
                                            deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                                            fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                                            maxSize={1}
                                            maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                            multipleFiles={false}
                                            multipleFilesDesc=""
                                            entity={null}
                                            storageitemlinked={false}
                                            onupload={this.onUploadFile}
                                        />
                                        {this.state.projectImageUrl !== "" &&
                                            <div>
                                                <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeImgDropzone.bind(this)}>&#xf021;</div>
                                            </div>
                                        }
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 col-lg-8 pl-0">
                                <Form.Group controlId="formAcceptTerms" style={{ marginBottom: "20px" }}>
                                    <Form.Label></Form.Label>
                                    <Form.Check
                                        required
                                        type="checkbox"
                                        label={translatedMessage("PROJECT.TOU")}
                                    />
                                </Form.Group>
                                <Button className={this.state.dropzoneEnabled ? "ca-button" : "ca-button disabled"} type="submit">{translatedMessage("PROJECT.CREATE_BUTTON")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        )
    }
}

export default ArtProjectCreate;