import React from 'react';

import parkIcon from '../Resources/homepage/art_park.svg';
import backIcon from '../Resources/icons/back.svg';
import addIcon from '../Resources/icons/add.svg';

import { Link } from 'react-router-dom'
import { Row, Col, Form, InputGroup, Image, Dropdown, Button } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';
import ParkCardComponent from './ParkCardComponent';
import ParkService from '../../../services/park.service';
import GenericErrorComponents from '../ErrorComponents/GenericErrorComponents';
import ApiService, { hasPermission } from '../../../services/api.service';
import ParkEditModalComponent from './ParkEditModalComponent';
import ParkMediaModalComponent from './ParkMediaModalComponent';
import ParkAccessModalComponent from './ParkAccessModalComponent';

const ENTER_KEY = 13;

class ParkListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      parks: [],
      filteredList: [],
      statusList: [],
      selectedStatus: { label: "GENERAL_STATUS.ANY", name: "ANY" },
      isLoaded: false,
      canViewList: false,
      canEdit: false,
      canAdd: false,
      showEditModal: false,
      showMediaModal: false,
      showAccessModal: false,
      selectedPark: null
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(async () => {
      this.setState({
        canViewList: hasPermission("PARK_LIST"),
        canAdd: hasPermission("PARK_CREATE"),
        canEdit: hasPermission("PARK_EDIT")
      }, await this.getParks())
    });
  }

  async getParks() {
    let errorMessage = "GENERAL.GET_DATA_ERROR";
    let parks = [];
    var statusList = [];
    let promise = [];

    let criteria = {};
    criteria.sortOrder = "ASC";
    criteria.sortField = "name";    
    criteria.pageSize = -1;
    criteria.forAdmin = this.props?.isAdmin

    if (this.props.parent) {
      criteria.parentId = parseInt(this.props.parent.id)
    }

    promise.push(ParkService.getParkStatusList()
      .then(response => {
        statusList = response?.map(item => {
          return { label: "ParkStatus." + item, name: item }
        });

        statusList.splice(0, 0, { label: "GENERAL_STATUS.ANY", name: "ANY" })
      }))

    promise.push(ParkService.getParkList(criteria)
      .then(response => {
        parks = response;
      }))

    Promise.all(promise)
      .then(() => {
        this.setState({
          parks: parks.items,
          filteredList: parks.items,
          statusList: statusList,
          showEditModal: false,
          showMediaModal: false,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleStatusSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.statusList.filter(item => item.name === eventKey);

    this.setState({
      selectedStatus: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.parks;
    let searchInput = this.state.searchInput;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.parks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (this.state.canViewList) {
      let statusItem = this.state.selectedStatus.name;
      if (statusItem !== 'ANY') {
        result = result.filter(elem => elem.status === statusItem);
      }
    }

    this.setState({
      filteredList: result
    })
  }

  addPark = () => {
    this.setState({
      showEditModal: true,
      selectedPark: null
    })
  }

  editDetails = (_park, detailType) => {    
    this.setState({
      showEditModal: detailType === 'PARK' ? true : false,
      showMediaModal: detailType === 'MEDIA' ? true : false,
      showAccessModal: detailType === 'ACCESS' ? true : false,
      selectedPark: _park
    })
  }

  onModalHide = (park, isSave = false) => {
    if (isSave) {
      this.getParks()
    } else {
      this.setState({
        showEditModal: false,
        showMediaModal: false,
        showAccessModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-3 pr-2">
              <div className="ca-page-title-container d-flex align-items-center">
                <Image src={parkIcon} className="ca-page-title-icon" alt={translatedMessage("PARK.PARKS")} />
                <div>
                  <div className="ca-page-title" style={{ lineHeight: "30px" }}>{translatedMessage("PARK.PARKS")}</div>
                </div>
              </div>

              <div>
                {this.props.isAdmin && this.state.canAdd &&
                  <Button onClick={() => this.addPark()} className="ca-dark-link ca-button-icon medium">
                    <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} title={translatedMessage("GENERAL.ADD_NEW")} />
                  </Button>
                }
                {this.props.entityUrl &&
                  <Link to={`/${this.props.entityUrl}`} className="ca-dark-link">
                    <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                      title={translatedMessage("BUTTON.BACK")} />
                  </Link>
                }
              </div>
            </Row>

            <Row className="d-flex align-items-center">
              <Col className="col-12 col-xl-6 mb-1 pl-0 pr-2">
                <InputGroup className="ca-form-input-group">
                  <Form.Control value={this.state.searchInput} type="text"
                    onChange={this.handleSearchInput}
                    onKeyDown={this.handleKeyDown}
                    placeholder={translatedMessage("SEARCH.BY.NAME")} />
                  <InputGroup.Append>
                    <InputGroup.Text>&#xF002;</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              {this.state.canViewList &&
                <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
                  <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect}>
                    <Dropdown.Toggle>
                      {translatedMessage(this.state.selectedStatus.label)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {this.state.statusList.map((element, index) => {
                        return (
                          <Dropdown.Item key={index} eventKey={element.name}>
                            {translatedMessage(element.label)}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              }
            </Row>

            <Row>
              {this.state.filteredList?.map((element, index) => {
                return (
                  <Col key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pl-0 pr-0">
                    <ParkCardComponent
                      cardImage={element.imageURL}
                      cardTitle={element.name}
                      cardDetails={element}
                      id={0 === element.id ? 0 : element.id}
                      entityUrl={this.props.entityUrl}
                      canEdit={this.props.isAdmin && this.state.canEdit}
                      editDetails={this.editDetails}
                    />
                  </Col>
                )
              })}
            </Row>
          </div>

          {this.state.showEditModal &&
            <ParkEditModalComponent
              show={this.state.showEditModal}
              onHide={this.onModalHide}
              park={this.state.selectedPark}
              parentId={this.props?.parent ? this.props.parent.id : null}              
            />
          }

          {this.state.showMediaModal &&
            <ParkMediaModalComponent
              show={this.state.showMediaModal}
              onHide={this.onModalHide}
              park={this.state.selectedPark}
              onlyView={this.state.onlyView}
            />
          }

          {this.state.showAccessModal &&
            <ParkAccessModalComponent
              show={this.state.showAccessModal}
              onHide={this.onModalHide}
              park={this.state.selectedPark}
              onlyView={this.state.onlyView}
            />
          }
        </>
      )
    } else {
      return (
        <GenericErrorComponents errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ParkListComponent;