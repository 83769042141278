import React from 'react';

import VirtualGalleryViewComponent from '../../../../components/shared/VirtualGallery/VirtualGalleryViewComponent';

class VirtualGalleryViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {}

  render() {
      return (
        <VirtualGalleryViewComponent
          virtualGalleryId={this.props.match.params.virtualGalleryId}
          canView={true}
          isAuthor={true}
          backLink={`/my-company/${this.props.match.params.id}/virtual-galleries`}
          editLink={`/my-company/${this.props.match.params.id}/virtual-gallery-edit/${this.props.match.params.virtualGalleryId}`}
          history={this.props.history}
        />
      )
  }

}

export default VirtualGalleryViewPage;