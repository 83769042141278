import React from 'react';

import VirtualGalleryEditComponent from '../../../../components/shared/VirtualGallery/VirtualGalleryEditComponent';

class VirtualGalleryEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {}

  render() {
      return (
        <VirtualGalleryEditComponent
          virtualGalleryId={this.props.match.params.virtualGalleryId}
          canEdit={true}
          isAuthor={true}
          backLink={`/my-company/${this.props.match.params.id}/virtual-galleries`}
          viewLink={`/my-company/${this.props.match.params.id}/virtual-gallery/${this.props.match.params.virtualGalleryId}`}
          history={this.props.history}
        />
      )
  }

}

export default VirtualGalleryEditPage;