import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { translatedMessage } from '../../../services/language.service';

import addIcon from '../../../components/shared/Resources/icons/add.svg';

import { toast } from "react-toastify";

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import JoinCompanyModalComponent from './Components/JoinCompanyModalComponent';
import TableComponent from './Components/ArtistTableComponent/ArtistTableComponent';

class CompanyAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: [],
      companyArtistList: [],
      allUsers: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      showJoinModal: false,
      companyList: []
    };

    this.onHandleJoin = this.onHandleJoin.bind(this);
    this.onHideJoin = this.onHideJoin.bind(this);
  }

  componentDidMount = () => {
    ApiService.initUser().then(() => this.getData());
  }

  async getData() {
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    const companyArtistList = await CompanyService.getCompanyArtistsByArtist()
      .then(response => {
        return response;
      })
      .catch(err => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })    

    this.setState({
      companyArtistList: companyArtistList,
      isLoaded: true
    })
  }

  async onHandleJoin() {
    const companyList = await CompanyService.getCompaniesForJoin(false, true)
      .then(response => {
        return response
      })

    this.setState({
      companyList: companyList,
      showJoinModal: true
    })
  }

  async onHideJoin(companyId, companyName) {
    if (companyId) {
      ApiService.getAuthenticatedInstance().post(`/company/${companyId}/artist/request`)
        .then(() => {
          toast.success(translatedMessage("COMPANY.ARTIST.JOIN.SEND_REQUEST.SUCCESS"))
          this.setState({
            showJoinModal: false,
            companyName: companyName
          }, this.getData)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.ARTIST.JOIN.SEND_REQUEST.ERROR"));

          this.setState({
            showJoinModal: false
          })
        })
    } else {
      this.setState({
        showJoinModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <div>
              <div className="ca-page-title">{translatedMessage("COMPANY.COMPANIES")}</div>
              <div>{translatedMessage("COMPANY.COLLABORATION.DETAIL")}</div>
            </div>

            <div>
              <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={this.onHandleJoin}>
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />
              </Button>              
            </div>
          </Row>

          <JoinCompanyModalComponent
            show={this.state.showJoinModal}
            onHide={this.onHideJoin}
            companies={this.state.companyList}
          />

          {this.state.companyArtistList.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <div className="ca-page-subtitle">{translatedMessage("COMPANY.COLLABORATION.NO_COMPANY")}</div>
                  <div>{translatedMessage("COMPANY.COLLABORATION.NO_COMPANY.DETAIL")}</div>
                </Col>
              </Row>
            </div>
          }

          {this.state.companyArtistList.length > 0 &&
            <div>
              <TableComponent
                data={this.state.companyArtistList}
              />
            </div>
          }
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAdminEdit;