import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, Image, Form, OverlayTrigger, Dropdown, Tooltip } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';
import sculptureIcon from '../../../components/shared/Resources/sculptureWhite.png';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import FileService from "../../../services/file.service";
import { translatedMessage } from '../../../services/language.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";
// import ModelViewer from "../../../components/shared/ModelViewer/ModelViewer";
import ArtworkService from '../../../services/artwork.service';

class ObjectPublishArtwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      artwork: {},
      artworkFile: {},
      artworkFile1: {},
      artworkTags: [],
      artworkTagObjects: [],
      resourceTypeList: [],
      experiences: [],
      experienceIndex: 0,
      isLoaded: false,
      dropzoneEnabled: true,
      isError: false,
      errorMessage: '',
      displayObject: false,
      modelEnabled: false,
      onlyView: false
    };

    this._isMounted = false;

  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
    this._isMounted = true;
    this.populateData();
  }

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
    this._isMounted = false;
  }

  beforeunload = (e) => {
    if (!this.state.dropzoneEnabled) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  populateData() {
    ApiService.getAuthenticatedInstance().get(`/artwork/existing/${this.props.match.params.id}`)
      .then(result => {
        this.setState({
          artwork: {
            ...result.data,
            storageItem: {
              id: result.data.storageItemId
            }
          },
          onlyView: result.data.status.name === ArtworkService.artworkStatusEnum().DRAFT || result.data.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
        }, async () => {
          let resourceTypeList = await FileService.getContentResourceTypeList()
            .then(response => {
              let objectResourceTypes = response.filter(item => (item.name === FileService.getResourceType().OBJECT || item.name === FileService.getResourceType().UNITY_SCENE))
              return objectResourceTypes
            })

          ApiService.getAuthenticatedInstance()
            .get(`/storage/storageItem/files/${this.state.artwork.id}/${ApiService.getEntityName().ARTWORK}`)
            .then(results => {
              const artworkFiles = results.data.fileList.filter(file => file.usage === FileService.getFileUsage().ARTWORK);
              // const modelFiles = results.data.fileList.filter(file => file.usage === FileService.getFileUsage().OBJECT_MODEL);

              let artworkFile = null;
              let artworkFile1 = null;
              if (artworkFiles.length === 1) {
                if (!artworkFiles[0].mobilePlatform) {
                  artworkFile = artworkFiles[0];
                } else if (artworkFiles[0].mobilePlatform === 'IOS') {
                  artworkFile = artworkFiles[0];
                } else if (artworkFiles[0].mobilePlatform === 'Android') {
                  artworkFile1 = artworkFiles[0];
                }
              } else if (artworkFiles.length === 2) {
                artworkFile = artworkFiles.filter(item => item.mobilePlatform === 'IOS')[0];
                artworkFile1 = artworkFiles.filter(item => item.mobilePlatform === 'ANDROID')[0];
              }

              this.setState({
                artworkFile: artworkFile,
                artworkFile1: artworkFile1,
                // modelFile: modelFiles.length > 0 ? modelFiles[0] : null,
                // modelFileObject: modelFiles.length > 0 ? ApiService.getFileByUuid(modelFiles[0].uuid) : null,
                // displayObject: modelFiles.length > 0 ? true : false,
                resourceTypeList: resourceTypeList,
                isLoaded: true
              });
            });
        })
      })
      .catch(err => {
        var errorMessage = "PROJECT.GENERAL_ERROR";
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    let artworkFile = this.state.artworkFile;
    let artworkFile1 = this.state.artworkFile1;

    if (uploadedFile) {
      if (!uploadedFile.mobilePlatform) {
        artworkFile = uploadedFile;
        artworkFile1 = null
      } else if (uploadedFile.mobilePlatform === 'IOS') {
        artworkFile = uploadedFile;
      } else if (uploadedFile.mobilePlatform === 'ANDROID') {
        artworkFile1 = uploadedFile;
      }
    }

    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          artworkFile: artworkFile,
          artworkFile1: artworkFile1,
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        artworkFile: artworkFile,
        artworkFile1: artworkFile1,
      });
    }
  }

  // onUploadModelFile = (dropzoneEnabled, uploadedFile) => {
  //   if (dropzoneEnabled) {
  //     setTimeout(() => {
  //       this.setState({
  //         dropzoneEnabled: dropzoneEnabled,
  //         modelFile: uploadedFile,
  //         modelFileObject: ApiService.getFileByUuid(uploadedFile.uuid),
  //         displayObject: !this.state.displayObject,
  //       })
  //     }, 500);
  //   } else {
  //     this.setState({
  //       dropzoneEnabled: dropzoneEnabled,
  //       modelFile: uploadedFile,
  //     });
  //   }
  // }

  handleNextStep = () => {
    let isError = true;
    if (this.state.artworkFile) {
      if (this.state.artworkFile.resourceType && this.state.artworkFile.resourceType.name === FileService.getResourceType().OBJECT) {
        isError = false;
      } else if (this.state.artworkFile1) {
        isError = false;
      }
    }

    if (!isError) {
      this.props.history.push(`/project/publish/digital-sculpture-price-package/${this.props.match.params.id}`);
    } else {
      toast.error(translatedMessage("ARTWORK.PUBLISH.ARTWORK_NOT_UPLOADED"));
    }
  };

  // changeObjectDropzone = () => {
  //   this.setState({ displayObject: !this.state.displayObject })
  // }

  handleOnTypeSelect = (eventKey) => {
    const selectedItem = this.state.resourceTypeList.filter(item => item.name === eventKey);

    this.setState({
      artworkFile: {
        ...this.state.artworkFile,
        resourceType: selectedItem[0],
      }
    });
  }

  render() {
    // let object_class = this.state.displayObject ? "ca-form-object-container ca-left" : "ca-form-object-container-hide";
    // let dropzone_class = !this.state.displayObject ? "ca-dropzone-show ca-left" : "ca-dropzone-hide";

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {        
        const homeUrl = this.state.artwork.companyId ? `/my-company/${this.state.artwork.companyId}/portfolio` : `/projects`;

        let artworkFile = this.state.artworkFile;
        let artworkFile1 = this.state.artworkFile1;

        let resourceType = artworkFile ? artworkFile.resourceType : { label: 'CONTENT_RESOURCE_TYPE.UNITY_SCENE', name: 'UNITY_SCENE' };

        let fileType = FileService.getResourceType().UNITY_SCENE;
        let fileFormatsDesc = "DROPZONE.FILE_TYPES.ARCHIVE";
        let isGlb = false;
        if (resourceType && resourceType.name === FileService.getResourceType().OBJECT) {
          fileType = FileService.getResourceType().OBJECT;
          fileFormatsDesc = "DROPZONE.FILE_TYPES.OBJECT";
          isGlb = true;
        }

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={sculptureIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_3D_OBJECT")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("ARTWORK.FILES")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={homeUrl} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>
                </div>

                <div className="ca-page-padding ca-main-container">
                  <Row className='mb-0'>
                    <Col className="col-12 col-lg-6">
                      <div className="ca-publish-section-title">
                        {translatedMessage("ARTWORK.ARTWORK")}
                      </div>
                      <div className="ca-publish-section-subtitle">
                        {artworkFile
                          ? translatedMessage("ARTWORK.PUBLISH.ARTWORK_UPLOADED") + " | " + translatedMessage("PROJECT.ARTWORK_FILE_NAME") + ": " +
                            artworkFile.name + (artworkFile.mobilePlatform ? " - iOS" : "") + 
                            (artworkFile1 ?  " & " + artworkFile1.name + " - Android" : "")
                          : translatedMessage("PROJECT.UPLOAD_ARTWORK")}
                      </div>

                      <Form.Group controlId="formType">
                        <Form.Label>
                          {translatedMessage("ART_CONTENT.RESOURCE.TYPE")}
                          <OverlayTrigger
                            key='type'
                            placement='bottom'
                            overlay={
                              <Tooltip id={`tooltip-type`} className="ca-tooltip text-left">
                                <p>{translatedMessage("ART_CONTENT.RESOURCE.UNITY_SCENE.INFO")}</p>
                                <p>{translatedMessage("ART_CONTENT.RESOURCE.OBJECT.INFO")}</p>
                              </Tooltip>
                            }
                          >
                            <span className="ca-question-icon"></span>
                          </OverlayTrigger>
                        </Form.Label>
                        <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                          <Dropdown.Toggle>
                            {translatedMessage(resourceType.label)}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {this.state.resourceTypeList.map((element, index) => {
                              return (
                                <Dropdown.Item key={index} eventKey={element.name} disabled={this.state.onlyView}>
                                  {translatedMessage(element.label)}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Form.Group>
                    </Col>
                  </Row>
                  {!this.state.onlyView &&
                    <Row>
                      <Col className="col-12 col-lg-6">
                        {!isGlb && <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.IOS")}</div>}
                        <div className="ca-publish-section-container">
                          <div className="ca-dropzone-show ca-left mb-5">
                            <DropzoneComponent
                              componentId="artworkFile"
                              fileUsage={FileService.getFileUsage().ARTWORK}
                              fileType={fileType}
                              resourceType={resourceType.name}
                              mobilePlatform={isGlb ? null : 'IOS'}
                              deletePrevious={true}
                              deletedImage={this.state.artworkFile}
                              deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_ARTWORK"
                              fileFormatsDesc={fileFormatsDesc}
                              maxSize={100}
                              maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                              multipleFiles={false}
                              multipleFilesDesc=""
                              entity={this.state.artwork}
                              storageitemlinked={true}
                              onupload={this.onUploadFile}
                            />
                          </div>
                        </div>
                      </Col>

                      {!isGlb &&
                        <Col className="col-12 col-lg-6">
                          <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.ANDROID")}</div>
                          <div className="ca-publish-section-container">
                            <div className="ca-dropzone-show ca-left mb-5">
                              <DropzoneComponent
                                componentId="artworkFile1"
                                fileUsage={FileService.getFileUsage().ARTWORK}
                                fileType={fileType}
                                resourceType={resourceType.name}
                                mobilePlatform={'ANDROID'}
                                deletePrevious={true}
                                deletedImage={this.state.artworkFile1}
                                deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_ARTWORK"
                                fileFormatsDesc={fileFormatsDesc}
                                maxSize={100}
                                maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                multipleFiles={false}
                                multipleFilesDesc=""
                                entity={this.state.artwork}
                                storageitemlinked={true}
                                onupload={this.onUploadFile}
                              />
                            </div>
                          </div>
                        </Col>
                      }

                      {/* {this.state.modelEnabled &&
                        <Col className="col-12 col-lg-6">
                          <div className="ca-publish-section-container">
                            <span className="ca-publish-section-title">
                              {translatedMessage("ARTWORK.OBJECT_MODEL")}
                            </span>
                            <span className="ca-publish-section-subtitle">
                              {translatedMessage("ARTWORK.UPLOAD_OBJECT_MODEL")}
                              {this.state.modelFile ? " (" + translatedMessage("GENERAL.FILE") + ": " + this.state.modelFile.name + ")" : ""}
                            </span>

                            <div className="ca-model-viewer-panel">
                              <div className={object_class}>
                                <ModelViewer modelFile={this.state.modelFileObject} artworkName={this.state.artwork.name} styleClass="ca-model-viewer-small" />
                                <div className="ca-form-object-change-details" onClick={this.changeObjectDropzone.bind(this)}>
                                  <FontAwesomeIcon icon={["fas", "cloud-upload-alt"]} style={{ fontSize: "32px" }}
                                    title={translatedMessage("GENERAL.UPLOAD.FILE")} />
                                </div>
                              </div>
                            </div>
                            <div className={dropzone_class}>
                              <DropzoneComponent
                                componentId="modelFile"
                                fileUsage={FileService.getFileUsage().OBJECT_MODEL}
                                fileType={FileService.getResourceType().OBJECT_MODEL}
                                deletePrevious={true}
                                deletedImage={this.state.modelFile}
                                deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_FILE"
                                fileFormatsDesc="DROPZONE.FILE_TYPES.OBJECT"
                                maxSize={10}
                                maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                multipleFiles={false}
                                multipleFilesDesc=""
                                entity={this.state.artwork}
                                storageitemlinked={true}
                                onupload={this.onUploadModelFile}
                              />
                              {this.state.modelFileObject &&
                                <div style={{ textAlign: "center" }}>
                                  <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeObjectDropzone.bind(this)}>&#xf021;</div>
                                </div>
                              }
                            </div>
                          </div>
                        </Col>
                      } */}
                    </Row>
                  }
                </div>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className={this.state.dropzoneEnabled ? "ca-button-icon" : "ca-button-icon disabled"} variant="link"
                    onClick={() => this.props.history.push(`/project/publish/digital-sculpture/${this.props.match.params.id}`)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>{translatedMessage("BUTTON.NEXT")}</span>
                  <Button className={this.state.dropzoneEnabled ? "ca-button-icon" : "ca-button-icon disabled"} variant="link" onClick={this.handleNextStep}>
                    <Image src={nextArrow} alt={translatedMessage("BUTTON.NEXT")} />
                  </Button>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }
}

export default ObjectPublishArtwork;