import React from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'react-bootstrap';
import { translatedMessage } from '../../../../services/language.service';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Card className="ca-gallery-card">
                    <Link className="ca-dark-link" to={`/company-admin/${this.props.companyId}/exhibition-view/${this.props.id}`} title={translatedMessage("GENERAL.VIEW")}>
                        <Card.Img variant="top" src={this.props.cardImage} />
                    </Link>
                    <Card.Body>
                        <Card.Title>{this.props.cardTitle}</Card.Title>
                        <Card.Text>
                            <b>{translatedMessage("GENERAL.STATUS")}: </b>
                            {translatedMessage(this.props.cardDetails.status.label)}
                        </Card.Text>
                        <footer className="ca-gallery-card-footer">
                            <Link className="ca-dark-link" to={`/company-admin/${this.props.companyId}/exhibition-edit/${this.props.id}`} title={translatedMessage("EXHIBITION.EDIT")}>
                                <FontAwesomeIcon icon="pencil-alt"/>
                            </Link>
                            <Link className="ca-dark-link ml-2" to={`/company-admin/${this.props.companyId}/exhibition-media/${this.props.id}`} title={translatedMessage("EXHIBITION.MEDIA_FILES")}>
                                <FontAwesomeIcon icon="video"/>
                            </Link>                                                        
                            <Link className="ca-dark-link ml-2" to={`/company-admin/${this.props.companyId}/exhibition-artwork/${this.props.id}`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                                <FontAwesomeIcon icon={["far", "images"]}/>
                            </Link>
                            <Link className="ca-dark-link ml-2" to={`/company-admin/${this.props.companyId}/exhibition-virtual-gallery/${this.props.id}`} title={translatedMessage("VIRTUAL_GALLERY.MANAGE")}>
                                <FontAwesomeIcon icon="torii-gate"/>
                            </Link>
                            <Link className="ca-dark-link ml-2" to={`/company-admin/${this.props.companyId}/exhibition-location/${this.props.id}`} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                                <FontAwesomeIcon icon="map-marker-alt"/>
                            </Link>                                                        
                        </footer>
                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default CardComponent;