import React from 'react';

import { Row, Col, Form } from 'react-bootstrap/'

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import TableComponent from './TableComponent/TableComponent';

class GiftPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      gifts: [],
      sentGifts: [],
      receivedGifts: [],
      isLoaded: false
    };
  }

  componentDidMount() {
    ApiService.initCurrentUser()
      .then(response => {
        this.setState({
          user: response
        }, this.getData)
      })
  }

  getData() {
    ApiService.getAuthenticatedInstance().get(`artwork/artworkGift/${this.state.user.id}`)
      .then(response => {
        const sentGifts = response.data.filter(item => item.sender.id === this.state.user.id);
        const receivedGifts = response.data.filter(item => item.receiverEmail === this.state.user.username);
        this.setState({
          gifts: response.data,
          sentGifts: sentGifts,
          receivedGifts: receivedGifts,
          isLoaded: true
        })
      })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Form id="photoPublishStepperForm" onSubmit={this.onSubmit}></Form>

          <Row className="mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("GIFT.GIFTS")}</span>
          </Row>

          {this.state.gifts.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("GIFT.NO_GIFTS")}
                  </span>
                </Col>
              </Row>
            </div>
          }

          {this.state.sentGifts.length > 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("GIFT.SENT")}
                  </span>
                </Col>
              </Row>
              <TableComponent
                data={this.state.sentGifts}
                sent={true}
              />
            </div>
          }

          {this.state.receivedGifts.length > 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("GIFT.RECEIVED")}
                  </span>
                </Col>
              </Row>
              <TableComponent
                data={this.state.receivedGifts}
                sent={false}
              />
            </div>
          }
        </div>
      )
    }
  }
}

export default GiftPage;