import React from 'react';
import "./AccountPreferencesPage.css"

import Switch from "react-switch";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ApiService from '../../../services/api.service';
// import UserService from '../../../services/user.service';
// import DeleteAccountModal from '../Components/DeleteAccountModalComponent';

import { toast } from "react-toastify";

import { translatedMessage } from "../../../services/language.service"

class AccountPreferencesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountPreferences: {
                notifications: false,
                profileVisibility: false,
                language: "en",
                timeZone: ""
            },
            availableLanguages: [],
            //showDeleteModal: false,
            isLoaded: false
        };

        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        if (!ApiService.getCurrentUser() || !ApiService.getCurrentProfile()) {
            ApiService.getAuthenticatedInstance().get('/user/me')
                .then(result => {
                    ApiService.setCurrentUser(result.data);
                    this.populateData();
                });
        } else {
            this.populateData();
        }
    }

    populateData() {
        let profile = []

        ApiService.getAuthenticatedInstance().get(`/languages`)
            .then(result => {
                const languages = result.data._embedded.languages;

                languages.map((language) => {
                    const parts = language._links.self.href.split('/');
                    language.id = parts[parts.length - 1];
                    return language;
                });

                this.setState({
                    availableLanguages: languages
                })

                ApiService.getAuthenticatedInstance().get(`/user/myProfile`)
                    .then(result => {
                        ApiService.setCurrentProfile({
                            ...result.data.profile,
                        });
                    }).then(() => {
                        profile = ApiService.getCurrentProfile();
                        this.setState({
                            accountPreferences: {
                                ...this.state.accountPreferences,
                                notifications: profile.notifications,
                                profileVisibility: profile.visibility,
                                languageCode: profile.languageCode,
                            },
                            isLoaded: true
                        })
                    })
            })
    }

    changeLanguage = (event) => {
        this.setState({
            accountPreferences: {
                ...this.state.accountPreferences,
                languageCode: event.target.value,
            }
        })
    }

    notifAndVisibChange = (checked, event, id) => {
        let accPrefMock = this.state.accountPreferences;
        accPrefMock[id] = checked;
        this.setState({ accountPreferences: accPrefMock }, () => {
        });
    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            accountPreferences: {
                ...this.state.accountPreferences,
                [name]: value
            }
        }, () => {
        });
    };

    saveChanges(event) {
        event.preventDefault();
        const languageCode = this.state.accountPreferences.languageCode;
        const languageID = this.state.availableLanguages.find(x => x.code === this.state.accountPreferences.languageCode).id;

        this.props.history.push(`/change-language/${languageCode}`);

        const promise = new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().patch(`/profiles/${ApiService.getCurrentProfile().id}`, {
                notifications: this.state.accountPreferences.notifications,
                visibility: this.state.accountPreferences.profileVisibility,
                language: `/languages/${languageID}`,
            }).then(resolve)
        });

        promise.then(() => {
            toast.success(translatedMessage("ACCOUNT_PREFERENCES.SAVE_SUCCESS"));
        })
    }

    // deleteAccount = () => {
    //     this.setState({
    //         showDeleteModal: true
    //     })
    // }

    // onHideDelete = (deleted) => {
    //     if (deleted && deleted === true) {
    //     UserService.deleteAccount()
    //         .then((response) => {
    //             toast.success(translatedMessage(response));
    //             setTimeout(() => {
    //                 ApiService.handleLogOut(this.props.history)
    //             }, 3000);
    //         })
    //         .catch(err => {
    //             let errorMessage = "USER.DELETE.ERROR.GENERIC";
    //             if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
    //                 errorMessage = err.response.data.message;
    //             }
                
    //             toast.error(translatedMessage(errorMessage), { autoClose: 10000 });
    //             this.setState({
    //                 showDeleteModal: false
    //             })
    //         });
    //     } else {
    //         this.setState({
    //             showDeleteModal: false
    //         })
    //     }
    // }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <>
                    <div className="ca-page-padding ca-main-container">
                        <div className="ca-page-title w-100 mb-3">{translatedMessage("NAVBAR.MENU.PROFILE.ACCOUNT_PREFERENCES")}</div>
                        <Row className="mb-2">
                            <Col className="col-12 col-lg-6 pl-0">
                                <Form id="accountPreferencesForm" onSubmit={this.saveChanges} className="ca-form">
                                    <Form.Group controlId="formNotification">
                                        <Form.Label>{translatedMessage("ACCOUNT_PREFERENCES.NOTIFICATIONS")}</Form.Label>
                                        <div>
                                            <Switch
                                                onChange={this.notifAndVisibChange}
                                                checked={this.state.accountPreferences.notifications}
                                                offColor="#F00"
                                                id="notifications"
                                            />
                                            <div className="w-100">{translatedMessage("ACCOUNT_PREFERENCES.NOTIFICATIONS_TEXT1")}</div>
                                            <div className="w-100">{translatedMessage("ACCOUNT_PREFERENCES.NOTIFICATIONS_TEXT2")}</div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formNotification">
                                        <Form.Label>{translatedMessage("ACCOUNT_PREFERENCES.VISIBILITY")}</Form.Label>
                                        <div>
                                            <Switch
                                                onChange={this.notifAndVisibChange}
                                                checked={this.state.accountPreferences.profileVisibility}
                                                offColor="#F00"
                                                id="profileVisibility"
                                            />
                                            <div className="w-100">{translatedMessage("ACCOUNT_PREFERENCES.VISIBILITY_TEXT1")}</div>
                                            <div className="w-100">{translatedMessage("ACCOUNT_PREFERENCES.VISIBILITY_TEXT2")}</div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="formLanguage">
                                        <Form.Label>{translatedMessage("ACCOUNT_PREFERENCES.LANGUAGE")}</Form.Label>
                                        <Form.Control
                                            name="language"
                                            as="select"
                                            value={this.state.accountPreferences.languageCode}
                                            onChange={this.changeLanguage}
                                        >
                                            {this.state.availableLanguages.map(language => {
                                                return (
                                                    <option key={language.id} value={language.code}>{translatedMessage("ACCOUNT_PREFERENCES.LANGUAGES." + language.code)}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>

                        <Button className="ca-button" variant="dark" type="submit" form="accountPreferencesForm">
                            <span>{translatedMessage("GENERAL.SAVE_CHANGES")}</span>
                        </Button>
                    </div>                    

                    {/* <DeleteAccountModal
                        show={this.state.showDeleteModal}
                        onHide={this.onHideDelete}
                        usercompanyid={this.state.deletedUserCompanyId}
                    /> */}
                </>
            )
        }
    }

}

export default AccountPreferencesPage;