import React from 'react';

import { Row, Col, Image, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import ChangeYoutubeIdModalComponent from '../../../components/shared/ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'

import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import ApiService from '../../../services/api.service';
import LanguageService from '../../../services/language.service';
import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import FileService from '../../../services/file.service';

import { toast } from "react-toastify";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VirtualGalleryEditComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualGallery: null,
      dropzoneEnabled: true,
      isLoaded: false,
      isError: false,
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      presentationImages: [],
      videos: [],
      showAddFileModal: false,
      fileModalData: null,
      showEditYoutubeModal: false
    };
  }

  componentDidMount() {
    if (this.props.canEdit) {
      this.getData();
    } else {
      this.setState({
        isError: true,
        errorMessage: translatedMessage("USER.SECURITY.MISSING_RIGHTS"),
        isLoaded: true
      })
    }
  }

  async getData() {
    VirtualGalleryService.getVirtualGalleryForManage(this.props.virtualGalleryId)
      .then(response => {
        this.setState({
          virtualGallery: {
            ...response,
            storageItem: {
              id: response.storageItemId
            }
          },
          presentationImages: FileService.handleFilesForCarousel(response.fileList, FileService.getFileUsage().THUMBNAIL),
          videos: response.videoUrlList,     
          isLoaded: true         
        })
      })
      .catch(err => {
        this.setState({
          isError: true,
          errorMessage: err,
          isLoaded: true
        })

      })
  }

  getMedia = () => {
    let promise = [];
    let presentationImages = [];
    let videos = [];

    promise.push(
      ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.virtualGallery.id}/${ApiService.getEntityName().VIRTUAL_GALLERY}`)
        .then(results => {          
          presentationImages = FileService.handleFilesForCarousel(results.data.fileList, FileService.getFileUsage().THUMBNAIL);
          presentationImages.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
        })
    )

    promise.push(
      FileService.getVideoUrls(this.state.virtualGallery.id, ApiService.getEntityName().VIRTUAL_GALLERY).then(response => { videos = response })
    )

    Promise.all(promise).then(() => {
      this.setState({
        presentationImages: presentationImages,
        videos: videos
      })
    })
  }

  onChangeArtEditor = (e) => {
    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        description: e,
      }
    })
  }

  onChangeAuthorEditor = (e) => {
    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        aboutAuthor: e,
      }
    })
  }

  validate = (event) => {
    var response = {
      isError: false,
      message: ""
    };

    return response;
  }

  saveChanges = (event) => {
    event.preventDefault();

    const validation = this.validate(event);
    if (!validation.isError) {
      let promise = [];
      promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/virtualGalleries/${this.props.virtualGalleryId}`, {
          description: this.state.virtualGallery.description,
          aboutAuthor: this.state.virtualGallery.aboutAuthor,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then(() => resolve())
          .catch(err => reject(err))
      });

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      toast.error(validation.message);
    }
  }

  onShowAddFile = (event) => {
    if (event.currentTarget.id === 'btnPresImg') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.virtualGallery,
        storageitemlinked: true
      }
      this.setState({
        showAddFileModal: true,
        fileModalData: modalData
      })
    }
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getMedia();
    }
    this.setState({
      showAddFileModal: false,
      showAddVideoModal: false
    })
  }

  handleMediaListChange = () => {
    this.getMedia();
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      ApiService.getAuthenticatedInstance().post(`/entityVideoUrls`, {
        entity: {
          entityId: this.state.virtualGallery.id,
          entityName: ApiService.getEntityName().VIRTUAL_GALLERY
        },
        videoId: videoId,
        type: FileService.getVideoUrlType().YOUTUBE,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            showEditYoutubeModal: false
          }, this.handleMediaListChange)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
              <div className="pr-2 d-flex align-items-center">
                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                <div className="ca-page-title">{this.state.virtualGallery.name}</div>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" type="submit" form="arPortal-form">
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} />
                </Button>

                <Link to="#" className="ca-dark-link" onClick={() => this.props.history.push(this.props.viewLink)}>
                  <div className="d-flex align-items-center h-100">
                    <FontAwesomeIcon icon={["far", "eye"]}
                      className="ca-action-icon-30 mr-2" title={translatedMessage("GENERAL.VIEW")} />
                  </div>
                </Link>

                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(this.props.backLink)}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            <Form id="arPortal-form" className="ca-form" onSubmit={this.saveChanges}>
              <Row className="mb-0">
                <Col className="col-12 col-md-6">
                  <Form.Group controlId="formDescription">
                    <div className="ca-paragraph-title w-100">{translatedMessage("AR_PORTAL.DESCRIBE")}</div>
                    <div className="ca-text-editor">
                      <SunEditor
                        name="desc-editor"
                        lang={LanguageService.getCurrentLanguage()}
                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                        setContents={this.state.virtualGallery.description}
                        setOptions={{
                          height: 150,
                          maxWidth: "100%",
                          buttonList: this.state.editorButtns,
                          font: this.state.editorFonts,
                          defaultStyle: this.state.editorDefaultStyle
                        }}
                        onChange={this.onChangeArtEditor}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col className="col-12 col-md-6">
                  <Form.Group controlId="formDescription">
                    <div className="ca-paragraph-title w-100">{translatedMessage("ARTWORK.ABOUT_AUTHOR")}</div>
                    <div className="ca-text-editor">
                      <SunEditor
                        name="desc-editor"
                        lang={LanguageService.getCurrentLanguage()}
                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                        setContents={this.state.virtualGallery.aboutAuthor}
                        setOptions={{
                          height: 150,
                          maxWidth: "100%",
                          buttonList: this.state.editorButtns,
                          font: this.state.editorFonts,
                          defaultStyle: this.state.editorDefaultStyle
                        }}
                        onChange={this.onChangeAuthorEditor}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            <Row className='mt-4'>
              <Col className="col-12 col-md-6">
                <div className="w-100 mb-3 ca-paragraph-title">
                  <span className="ca-paragraph-title w-100">
                    {translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                    <OverlayTrigger
                      key='addImage'
                      placement='bottom'
                      overlay={
                        <Tooltip id={`tooltip-addImage`}>
                          {translatedMessage("ARTWORK.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                        </Tooltip>
                      }
                    >
                      <span className="ca-question-icon"></span>
                    </OverlayTrigger>
                  </span>

                  <span className="d-inline-block">
                    <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                      <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />
                    </Button>
                  </span>
                </div>
                {this.state.presentationImages.length > 0 &&
                  <Carousel
                    id="presImg"
                    items={this.state.presentationImages}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class="mb-5"
                    hasToolbar={true}
                    toolbarDelete={true}
                    toolbarFullScreen={true}
                    canDeleteLast={false}
                    onChange={this.handleMediaListChange}
                  />
                }
              </Col>
              <Col className="col-12 col-md-6">
                <div className="w-100 mb-3 ca-paragraph-title">
                  <span className="ca-paragraph-title w-100">{translatedMessage("PROJECT.VIDEOS")}</span>

                  <span className="d-inline-block">
                    <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.handleYoutubeIdEdit}
                      title={translatedMessage("VIDEO.ADD_YOUTUBE_ID")}>
                      <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                    </Button>

                  </span>
                </div>
                {this.state.videos.length > 0 &&
                  <Carousel
                    id="video"
                    items={this.state.videos}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class="mb-5"
                    hasToolbar={true}
                    toolbarDelete={true}
                    toolbarYoutubeId={true}
                    videoUrlOnly={true}
                    onChange={this.handleMediaListChange}
                  />
                }
              </Col>
            </Row>

            <AddFileModalComponent
              show={this.state.showAddFileModal}
              onHide={this.onHideAddFile}
              data={this.state.fileModalData}
            />

            <ChangeYoutubeIdModalComponent
              show={this.state.showEditYoutubeModal}
              onHide={this.onHideYoutubeModal}
            />
          </div>
        )
      }
  }

}

export default VirtualGalleryEditComponent;