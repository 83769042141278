import React from 'react';

import { Card, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import triggerIcon from '../../../components/shared/Resources/icons/trigger.svg';
import deleteIcon from '../../../components/shared/Resources/icons/delete.svg';

import { translatedMessage } from '../../../services/language.service';
import ApiService from '../../../services/api.service';
import { toast } from "react-toastify";

class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showStatusModal: false,
            status: this.props.cardStatus
        };
    }

    delete = () => {
        if (window.confirm(translatedMessage("DEMO_ART.DELETE.CONFIRM"))) {
            ApiService.getAuthenticatedInstance().post(`/artwork/demo_art/delete/${this.props.cardId}`)
                .then((response) => {
                    if (response.data.status === "OK") {
                        toast.success(translatedMessage("DEMO_ART.DELETE.SUCCESS"));

                        this.props.onchange();
                    } else {
                        toast.error(translatedMessage(response.data.message));
                    }
                })
                .catch(err => {
                    toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                    console.log("An error has ocurred: " + err);
                });
        }
    }

    render() {
        return (
            <>
                <Card className="ca-artwork-card">
                    <Card.Img variant="top" src={this.props.cardImage} />
                    <Card.Body>
                        <Card.Title>
                            {this.props.cardTitle}
                        </Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        <div className="ca-footer-controls">
                            {this.props.canHaveTrigger &&
                                <Link className="ca-dark-link small" to={`/demoArTrigger/${this.props.cardId}`} title={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}>
                                    <Image src={triggerIcon} alt={translatedMessage("TRIGGER.MANAGE_TRIGGERS")} />
                                </Link>
                            }
                            {this.props.canEdit &&
                                <Button onClick={this.delete} className="ca-dark-link ca-button-icon small ml-1">
                                    <Image src={deleteIcon} alt={translatedMessage("GENERAL.DELETE")} title={translatedMessage("GENERAL.DELETE")} />
                                </Button>
                            }
                        </div>
                    </Card.Footer>
                </Card>
            </>
        )
    }

}

export default CardComponent;
