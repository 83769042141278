import React from 'react';

import ArtProjectCreate from './Components/ArtProjectCreateComponent'

import ApiService from '../../services/api.service';

import digitalPicture from '../../components/shared/Resources/icons/digital_picture_white.svg';


class DigitalPictureProjectCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyId: this.props.match.params.companyId ? this.props.match.params.companyId : null,
        };
    }

    componentDidMount = () => {
        let user = ApiService.getCurrentUser();
        if (!user) {
            ApiService.initCurrentUser()
                .then(response => {
                    this.setState({ user: response })
                })
        } else {
            this.setState({ user: user })
        }
    }



    render() {
        return (
            <>
                <ArtProjectCreate
                    pageTitle={"PROJECT.CREATE_DIGITAL_PICTURE"}
                    icon={digitalPicture}
                    projectCategory={4}
                    history={this.props.history}
                    companyId={this.state.companyId}
                    nexturl={`/project/publish/digital-picture`}
                />
            </>
        )
    }
}

export default DigitalPictureProjectCreatePage;