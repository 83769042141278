import React from 'react';

import caMapPin from '../../../components/shared/Resources/map/ar_art.png';
import caMapCurrentPin from '../../../components/shared/Resources/map/current_location.png';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Row, Col, Button } from 'react-bootstrap';

import EditGeofenceNameModal from './EditGeofenceNameModal';
import GeofenceTableComponent from './GeofenceTableComponent';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';


import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

class GeofenceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableLoading: false,
      pages: null,
      geofence: {
        id: 0,
        markerPosition: {
          lat: 0,
          lng: 0
        },
        circleRadius: 0,
        name: ''
      },
      editModalVisible: false,
      isLoaded: false,
      mapProps: null,
      map: null,
      markers: [],
      fences: [],
      markerImage: [],
      canViewList: false,
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canViewList: hasPermission("GEOFENCE_VIEW")
      }, this.getData())
    });    
  }

  getData = () => {
    // return MapService.getGeofences();
    MapService.getGeofences().then(result => {
      this.setState({
        data: result,
        isLoaded: true
      })
    })    
  }

  onMapReady = (props, map) => {
    var initialCenter = MapService.getInitialCenter();
    var marker = new props.google.maps.Marker();

    this.setState({
      mapProps: props,
      pinIcon: {
        url: caMapPin,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height),
      },
      currentPinIcon: {
        url: caMapCurrentPin,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height),
      },      
      map: map
    })

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        map.setCenter(pos);
      }, () => {
        //Geolocation service failed
        map.setCenter(initialCenter);
      });
    } else {
      // Browser doesn't support Geolocation
      map.setCenter(initialCenter);
    }

    this.setMarkers(props, map);

    map.addListener('click', (e) => {
      marker.setMap(map);
      marker.setPosition(e.latLng);
      marker.setDraggable(true);

      const icon = {
        url: caMapPin,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height),
      }

      marker.setIcon(icon);

      marker.addListener('click', () => {
        this.setState({
          geofence: {
            markerPosition: { lat: marker.position.lat(), lng: marker.position.lng() },
            circleRadius: 300,
            id: 0
          }
        })
        marker.setMap(null);

        this.onShowModal(0);
      });
    });
  }

  setMarkers = (props, map) => {
    const geofences = this.state.data;
    var markers = [];
    var fences = [];


    geofences.forEach((element, index) => {
      const position = { lat: element.locationLat, lng: element.locationLng };

      const icon = {
        url: caMapPin,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height),
      }

      var marker = new props.google.maps.Marker({
        position: position,
        map: map,
        title: element.name,
        icon: icon
      });

      markers.push(marker);

      marker.addListener('click', () => {
        this.setState({
          geofence: {
            id: element.id,
            markerPosition: position,
            circleRadius: element.radius,
            name: element.name
          }
        })

        this.onShowModal(0);
      });

      var fenceCircle = MapService.getInitFenceCircle(props, map, false, false, false);
      fenceCircle.setCenter(position);
      fenceCircle.setRadius(element.radius);
      fenceCircle.setVisible(true);

      fences.push(fenceCircle);
    });

    this.setState({
      markers: markers,
      fences: fences
    })
  }

  setFenceCircle = (props, map, fence, position) => {
    var fenceCircle = fence;
    fenceCircle.setCenter(position);
    fenceCircle.setVisible(true);

    this.setState({
      geofence: {
        markerPosition: fenceCircle.center,
        circleRadius: fenceCircle.radius
      }
    });

    return fenceCircle;
  }

  clearMarkes = (markers, fences) => {
    markers.forEach((element) => {
      element.setMap(null);
    })

    fences.forEach((element) => {
      element.setMap(null);
    })

    this.setState({
      markers: [],
      fences: []
    })
  }

  addGeofenceMapCenter = () => {
    var mapCenter = this.state.map.getCenter();

    this.setState({
      geofence: {
        markerPosition: { lat: mapCenter.lat(), lng: mapCenter.lng() },
        circleRadius: 300,
        id: 0
      }
    });
      
    this.onShowModal(0);
  }

  onShowModal = (id) => {
    if (this.state.editModalVisible === false) {
      if (id !== 0) {
        ApiService.getAuthenticatedInstance().get(`/geofences/${id}`).then(result => {
          this.setState({
            geofence: {
              id: id,
              markerPosition: { lat: result.data.locationLat, lng: result.data.locationLng },
              circleRadius: result.data.radius,
              name: result.data.name
            }
          });
        })
      }

      this.setState({ editModalVisible: true })
      return;
    }
  }

  onHideModal = (mustFetch) => {
    if(mustFetch){
      this.fetchData(this.state);
    }

    this.setState({
      editModalVisible: false,
      geofence: []
    });
  }

  fetchData = (state) => {
    this.getData().then(result => {
      this.setState({
        data: result,
        loading: true
      });

      this.clearMarkes(this.state.markers, this.state.fences);

      this.setMarkers(this.state.mapProps, this.state.map);
    })
  }

  onRowClick = (geofence) => {
    this.state.markers.map((element, index) => {
      if(element.position.lat() === geofence.locationLat && element.position.lng() === geofence.locationLng){
        element.setIcon(this.state.currentPinIcon);
        element.map.setCenter(element.position);
      } else {
        element.setIcon(this.state.pinIcon);
      }

      return element;
    });

  }

  render() {
    if (!this.props.google || !this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canViewList) {
      return (
        <Row className="mb-0">
          <Col className="col-12 col-md-6 ca-main-container">
            <div className="ca-page-title-button-row mb-4 pr-2 align-item-center">
              <span className="ca-page-title">{translatedMessage("MAP.GEOFENCES")}</span>

              {!hasPermission("GEOFENCE_CREATE") ? "" :
                <Button onClick={this.addGeofenceMapCenter} className="ca-button">
                  {translatedMessage("GENERAL.ADD")}
                </Button>
              }
            </div>

            <GeofenceTableComponent
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              show={this.onShowModal}
              rowselect={this.onRowClick}
            />
          </Col>
          <Col className="col-12 col-md-6 ca-map-column pl-0 pr-0">
            <Map style={{}} google={this.props.google} zoom={13} initialCenter={MapService.getInitialCenter()} streetViewControl={false}
              onReady={this.onMapReady}>
            </Map>

            <EditGeofenceNameModal
              show={this.state.editModalVisible}
              onHide={this.onHideModal}
              geofence={this.state.geofence}
            />
          </Col>
        </Row>
      );
    }  else {
      return (
          <ErrorComponent errorMessage={"USER.SECURITY.MISSING_RIGHTS"} history={this.props.history} />
      )
    }
  }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(GeofenceList);