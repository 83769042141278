import React from 'react';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';

import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

class ExhibitionVirtualGalleryManageLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionVGId: this.props.match.params.id,
      exhibitionVirtualGallery: [],
      exhibition: [],
      virtualGallery: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      isLoaded: false,
      hasGeofence: false,
      artPin: null,
      exhibitionPin: null,
      mapLocations: [],
      initialCenter: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => { this.getData() }

  getData = () => {
    let promise = [];

    ApiService.getAuthenticatedInstance().get(`gallery/exhibition/virtual-gallery/${this.state.exhibitionVGId}`)
      .then(response => {
        this.setState({
          exhibitionVirtualGallery: response.data,
          exhibition: response.data.exhibition,
          virtualGallery: response.data.virtualGallery,
        }, () => {
          let initialCenter = {};
          promise.push(ApiService.getAuthenticatedInstance().get(`gallery/exhibitionLocation/${this.state.exhibition.id}`)
            .then(response => {
              if (response.data[0]) {
                initialCenter = {
                  lat: response.data[0].locationLat,
                  lng: response.data[0].locationLng,
                }
              } else {
                initialCenter = null
              }
            })
          )

          let artLocations = [];
          let mapLocations = [];
          promise.push(
            ApiService.getAuthenticatedInstance().get(`map/exhibitionMapLocations/${this.state.exhibition.id}`)
              .then(result => {
                mapLocations = result.data.filter(item => item.entity.entityId !== this.state.virtualGallery.id)
                artLocations = result.data.filter(item => item.entity.entityId === this.state.virtualGallery.id)
              })
          )

          let exhibitionPin = []
          promise.push(MapService.getLocationPinByType('EXHIBITION')
            .then(response => {
              exhibitionPin = response;
            }))

          let artPin = [];
          promise.push(MapService.getLocationPinByType(ApiService.getEntityName().VIRTUAL_GALLERY)
            .then(response => {
              artPin = response;
            }))

          Promise.all(promise).then(() => {
            this.setState({
              location: artLocations[0] ? artLocations[0] : this.state.location,
              hasGeofence: artLocations[0] && artLocations[0].geofence ? true : false,
              artPin: artPin[0] ? artPin[0].imageUrl : null,
              exhibitionPin: exhibitionPin[0] ? exhibitionPin[0].imageUrl : null,
              initialCenter: initialCenter,
              mapLocations: mapLocations,
              isLoaded: true
            })
          })
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.exhibitionVGId}
            entityName={ApiService.getEntityName().EXHIBITION_VIRTUAL_GALLERY}
            mapLocations={this.state.mapLocations}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.artPin}
            parentPinIcon={this.state.exhibitionPin}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.virtualGallery.name}
            parentEntityTitle={this.state.exhibition.name}
            history={this.props.history}
            zoomLevel={17}
          />
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ExhibitionVirtualGalleryManageLocation;