import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import backIcon from '../../components/shared/Resources/icons/back.svg';
import deleteIcon from '../../components/shared/Resources/icons/delete.svg';

import moment from 'moment';
import { toast } from "react-toastify";

import DropzoneComponent from "../../components/shared/DropzoneComponent/DropzoneComponent";

import ApiService from '../../services/api.service';
import FileService from '../../services/file.service';
import { translatedMessage } from '../../services/language.service';

class ExhibitionAdminArtworkTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      triggerURL: null,
      exhibitionArExperience: [],
      exhibition: [],
      arExperience: [],
      isLoaded: false,
      triggerFiles: [],
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => this.getData());
  }

  getData = () => {
    ApiService.getAuthenticatedInstance().get(`artwork/demo_art/${this.state.id}`)
      .then((response) => {
        if (response.data.status === "OK") {
          var triggerFile = response.data.art.triggerFile;
          this.setState({
            art: response.data.art,
            triggerURL: triggerFile ? ApiService.getFileByUuid(triggerFile.uuid) : null,
            isLoaded: true
          })
        } else {
          this.setState({
            isError: true,
            errorMessage: response.data.message,
            isLoaded: true
          })
        }
      })
      .catch(err => {
        this.setState({
          isError: true,
          errorMessage: "GENERAL.GENERIC_ERROR",
          isLoaded: true
        })
        console.log("An error has ocurred: " + err);
      });
  }

  onUploadTrigger = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          art: {
            ...this.state.art,
            triggerFile: uploadedFile
          },
          triggerURL: uploadedFile ? ApiService.getFileByUuid(uploadedFile.uuid) : null,
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        art: {
          ...this.state.art,
          triggerFile: uploadedFile
        },
        triggerURL: uploadedFile ? ApiService.getFileByUuid(uploadedFile.uuid) : null,
      });
    }
  }

  deleteTrigger = () => {
    var deleteUuid = this.state.art.triggerFile.uuid;

    if (window.confirm(translatedMessage("GENERAL.DELETE_CONFIRM"))) {
      ApiService.getAuthenticatedInstance().patch(`/demoArts/${this.state.art.id}`, {
        triggerFile: null,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          FileService.deleteFile(deleteUuid)
            .then(response => {
              toast.success(translatedMessage("FILE.DELETE_SUCCES"));
              this.setState({
                art: {
                  ...this.state.art,
                  triggerFile: null
                },
                triggerURL: null
              })
            })
            .catch(err => {
              console.log("An error has ocurred: " + err);
              toast.error(translatedMessage("FILE.DELETE_ERROR"));
            })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
        })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <div className="pr-2 d-flex align-items-center">
              <span className="ca-page-title">{translatedMessage("DEMO_ART.INSTANT_MAGIC") + " - " + this.state.art.artName}</span>
            </div>
            <Link to="/demoArt" className="ca-dark-link">
              <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                title={translatedMessage("BUTTON.BACK")} />
            </Link>
          </Row>
          <Row className="mt-3">
            <div className="mb-3 pr-2">
              <span className="ca-page-subtitle">{translatedMessage("TRIGGER.TRIGGER_IMAGES")}</span>
            </div>
          </Row>
          <Row>
            <Col className="col-12 col-md-6 pl-0 pr-0">
              <div>
                <DropzoneComponent
                  componentId="trigger"
                  fileUsage={FileService.getFileUsage().TRIGGER}
                  fileType={FileService.getResourceType().IMAGE}
                  deletePrevious={true}
                  deletedImage={this.state.art.triggerFile}
                  deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                  fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                  maxSize={10}
                  maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                  multipleFiles={false}
                  multipleFilesDesc=""
                  entity={this.state.art}
                  storageitemlinked={false}
                  entityUpdateAPILink="demoArts"
                  onupload={this.onUploadTrigger}
                />
              </div>
            </Col>
            <Col className="col-12 col-md-6 text-center">
              {this.state.art.triggerFile &&
                <>
                  <Image src={this.state.triggerURL} alt={translatedMessage("ARTWORK")} style={{ width: "95%", maxWidth: "480px" }} className="ca-box-shadow-image" />
                  <div className="mt-2">
                    <Button onClick={this.deleteTrigger} className="ca-dark-link ca-button-icon small">
                      <Image src={deleteIcon} alt={translatedMessage("GENERAL.DELETE")} title={translatedMessage("GENERAL.DELETE")} />
                    </Button>
                  </div>
                </>
              }
            </Col>
          </Row>
        </div >
      );
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <div className="pr-2 d-flex align-items-center">
              <span className="ca-page-title">{translatedMessage(this.state.errorMessage)}</span>
            </div>
            <Link to="/demoArt" className="ca-dark-link">
              <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                title={translatedMessage("BUTTON.BACK")} />
            </Link>
          </Row>
        </div>
      )
    }
  }
}

export default ExhibitionAdminArtworkTrigger;