import React from 'react';

import ApiService from "../../services/api.service";
import { translatedMessage } from '../../services/language.service';

import profilepic from '../../components/shared/Resources/defaultProfilePicture.png';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import ProfileViewComponent from '../../components/shared/Profile/ProfileViewComponent';

class ProfileViewPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.match.params.id,
      userRole:
        [
          { key: 'artist', label: translatedMessage("USER.TYPE.ARTIST") },
          { key: 'buyer', label: translatedMessage("USER.TYPE.ART_CONSUMER") },
          { key: 'institution', label: translatedMessage("USER.TYPE.ART_INSTITUTION") },
          { key: 'expert', label: translatedMessage("USER.TYPE.EXPERT_CO_WORKER") },
          { key: 'investor', label: translatedMessage("USER.TYPE.INVESTOR") },
        ],
      isLoaded: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const userId = Number(this.state.userId);

    ApiService.getAuthenticatedInstance().get(`/user/public-profile/user/${userId}`)
      .then(result => {
        let profile = result.data.profile;

        profile.profileCreatedOn = ApiService.parseDate(profile.profileCreatedOn).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' });
        profile.profilePicture = profile.photoUuid !== '' ? `${ApiService.getBaseUrl()}/storage/file/${profile.photoUuid}` : profilepic;

        const activities = ['artist', 'expert', 'buyer', 'investor', 'institution']
          .filter(key => profile[key] === true)
          .map(x => x);

        let userRoles = []
        activities.map((element, index) => {
          userRoles.push(this.state.userRole.find(role => role.key === element).label);
          return element;
        })

        this.setState({
          profile: profile,
          webResources: profile.profileLinks,
          activityFields: userRoles.join(', '),
          isLoaded: true
        })
      })      
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ProfileViewComponent
          userId={this.state.userId}
          profile={this.state.profile}
          webResources={this.state.webResources}
          activityFields={this.state.activityFields}
          portfolioLink={`/profile/${this.state.userId}/portfolio`}
          history={this.props.history}

        />        
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ProfileViewPage;