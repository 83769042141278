import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import { Link } from 'react-router-dom';
import { translatedMessage } from '../../../services/language.service';
import { toast } from "react-toastify";

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import JoinCompanyModalComponent from './Components/JoinCompanyModalComponent';

import addCompany from '../../../components/shared/Resources/icons/company/company.svg';
import joinCompany from '../../../components/shared/Resources/icons/company/team.svg';

import CompanyService from '../../../services/company.service';

class CompanyListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userCompanies: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      showJoinModal: false,
      companyList: [],
      companyName: "",
    };

    this.onHandleJoin = this.onHandleJoin.bind(this);
    this.onHideJoin = this.onHideJoin.bind(this);
  }

  componentDidMount = () => { this.getCompanies() }

  getCompanies = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    CompanyService.getCompaniesForConnectedUser()
      .then(response => {
        this.setState({
          userCompanies: response,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async onHandleJoin() {
    const companyList = await CompanyService.getCompaniesForJoin()
      .then(response => {
        return response
      })

    this.setState({
      companyList: companyList,
      showJoinModal: true
    })
  }

  async onHideJoin(companyId, companyName) {
    if (companyId) {
      ApiService.getAuthenticatedInstance().post(`/company/${companyId}/join`)
        .then(() => {
          toast.success(translatedMessage("COMPANY.JOIN.SEND_REQUEST.SUCCESS"))
          this.setState({
            showJoinModal: false,
          }, this.getCompanies)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.JOIN.SEND_REQUEST.ERROR"));

          this.setState({
            showJoinModal: false
          })
        })
    } else {
      this.setState({
        showJoinModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mt-5">
            {this.state.userCompanies.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  {element.status.name !== 'PENDING_APPROVAL' &&
                    <Link to={`/my-company/${element.company.id}/home`} className="ca-dark-link">
                      <div className="ca-company-logo-item-container h-100 d-flex align-items-center flex-column">
                        <div className='d-flex flex-grow-1 justify-content-center img-container'>
                          <Image src={element.image} className="my-auto" alt="Company logo" />
                        </div>
                        <div className="d-flex align-items-start title">{element.company.name}</div>
                      </div>
                    </Link>
                  }
                  {element.status.name === 'PENDING_APPROVAL' &&
                    <div className="ca-company-logo-item-container h-100 d-flex align-items-center flex-column">
                      <div className='d-flex flex-grow-1 justify-content-center img-container'>
                        <Image src={element.image} className="my-auto" alt="Company logo" />
                      </div>
                      <div className="d-flex align-items-start title ca-text-disabled">
                        {translatedMessage("COMPANY.JOIN.PENDING_APPROVAL") + ": " + element.company.name}
                      </div>
                    </div>
                  }
                </Col>
              )
            })}

            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
              <Link to={`/my-company/edit/`} className="ca-dark-link">
                <div className="ca-company-logo-item-container h-100 d-flex align-items-center flex-column">
                  <div className='d-flex flex-grow-1 justify-content-center img-container'>
                    <Image src={addCompany} className="my-auto" alt={translatedMessage("COMPANY.CREATE")} />
                  </div>
                  <div className="d-flex align-items-start title">{translatedMessage("COMPANY.CREATE")}</div>
                </div>
              </Link>
            </Col>

            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
              <Link to="#" className="ca-dark-link" onClick={this.onHandleJoin}>
                <div className="ca-company-logo-item-container h-100 d-flex align-items-center flex-column">
                  <div className='d-flex flex-grow-1 justify-content-center img-container'>
                    <Image src={joinCompany} className="my-auto" alt={translatedMessage("COMPANY.JOIN")} />
                  </div>
                  <div className="d-flex align-items-start title">{translatedMessage("COMPANY.JOIN")}</div>
                </div>
              </Link>
            </Col>
          </Row>

          <JoinCompanyModalComponent
            show={this.state.showJoinModal}
            onHide={this.onHideJoin}
            companies={this.state.companyList}
          />
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyListPage;