import React from 'react';

import { Card, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translatedMessage } from '../../../services/language.service';

import artIcon from '../../../components/shared/Resources/icons/art.svg';
import mapPinIcon from '../../../components/shared/Resources/icons/map_pin.svg';
import arExperienceIcon from '../../../components/shared/Resources/icons/ar_experience.svg';
import triggerIcon from '../../../components/shared/Resources/icons/trigger.svg';

class EntityManageVGCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  componentDidMount = () => {
    this.setState({
      checked: this.props.existingVG
    })
  }

  handleChange = (event) => {
    const input = event.currentTarget.querySelector('input')
    this.setState({
      checked: !input.checked
    })
    this.props.onCheck(input.id, !input.checked);
  }

  render() {
    return (
      <Card className="ca-gallery-card">
        <Card.Img variant="top" src={this.props.cardImage} />
        <Card.Body>
          <Card.Title style={{ textAlign: "left" }}>
            {this.props.virtualGallery.name}
            <span className="font-weight-normal" style={{ fontSize: "16px" }}>
              {this.props.virtualGallery.author &&
                " " + translatedMessage("GENERAL.BY").toLowerCase() + " " +
                (this.props.isAdd ? this.props.virtualGallery.author : this.props.virtualGallery.author.name)
              }
            </span>
          </Card.Title>
          {this.props.virtualGallery.type &&
            <Card.Text>
              {translatedMessage("GENERAL.TYPE") + ": " + translatedMessage(this.props.virtualGallery.type.label)}
              {this.props.soldByCompany &&
                <>
                  <br />
                  {translatedMessage("ARTWORK.COMPANY_COMM_PORTFOLIO")}
                  <OverlayTrigger
                    key='ca-soldByCompany'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-ca-soldByCompany`} className="ca-tooltip">
                        {translatedMessage("ARTWORK.COMPANY_COMM_PORTFOLIO.INFO")}
                      </Tooltip>
                    }
                  >
                    <span className="ca-question-icon"></span>
                  </OverlayTrigger>
                </>
              }
              {!this.props.soldByCompany &&
                <>
                  <br />
                  <span>&nbsp;</span>
                </>
              }
            </Card.Text>
          }
          {!this.props.isAdd && this.props.showFreeForEntity &&
            <div>
              <div className="ca-check-container mt-2">
                <input
                  checked={true}
                  readOnly
                  type="checkbox"
                  className="d-flex align-items-center"
                />
                <span className="ca-check-label">{translatedMessage("GENERAL.FREE_FOR_EVENT")}</span>
                <span className="ca-checkmark"></span>
              </div>
            </div>
          }
          {!this.props.isAdd &&
            <footer className="ca-gallery-card-footer">
              <div className='d-flex align-items-center justify-content-between mt-2'>
                <div className="more-info-button" onClick={() => this.props.onClick(this.props.virtualGallery.id)}>
                  {translatedMessage("GENERAL.MORE_INFO")}
                </div>
                <div>
                  {this.props.canDefineArt && this.props.artworkUrl &&
                    <Link className="ca-dark-link small mr-1" to={`${this.props.artworkUrl}`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                      <Image src={artIcon} alt={translatedMessage("GENERAL.ARTWORKS.MANAGE")}/>
                    </Link>
                  }
                  {this.props.canDefineArt && this.props.isAdmin && this.props.arExperienceUrl &&
                    <Link className="ca-dark-link small mr-1" to={`${this.props.arExperienceUrl}`} title={translatedMessage("AR_EXPERIENCE.MANAGE")}>
                      <Image src={arExperienceIcon} alt={translatedMessage("AR_EXPERIENCE.MANAGE")}/>
                    </Link>
                  }
                  {this.props.triggerRedirectUrl &&
                    <Link className="ca-dark-link small ml-1" to={this.props.triggerRedirectUrl} title={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}>
                      <Image src={triggerIcon} alt={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}/>
                    </Link>
                  }
                  {this.props.canHaveLocation && this.props.locationRedirectUrl &&
                    <Link className="ca-dark-link small" to={this.props.locationRedirectUrl} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                      <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")}/>
                    </Link>
                  }
                </div>
              </div>
            </footer>
          }
        </Card.Body>
        {this.props.isAdd &&
          <Card.Footer>
            <div className='d-flex align-items-center justify-content-between mt-2'>
              <div className="ca-check-container" onClick={this.handleChange}>
                <input
                  id={this.props.virtualGallery.id}
                  checked={this.state.checked}
                  type="checkbox"
                  className="d-flex align-items-center"
                  onChange={this.handleChange}
                />
                <span className="ca-check-label">{translatedMessage("VIRTUAL_GALLERY.SELECT")}</span>
                <span className="ca-checkmark"></span>
              </div>
              <div className="more-info-button" onClick={() => this.props.onClick(this.props.virtualGallery.id)}>
                {translatedMessage("GENERAL.MORE_INFO")}
              </div>
            </div>
          </Card.Footer>
        }
      </Card>
    )
  }
}

export default EntityManageVGCardComponent;