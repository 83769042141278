import React from 'react';

import CardComponent from "./Components/CardComponent";
import ArtworkSelectModal from "./Components/ArtworkSelectModal";
import ArExperienceSelectModal from "./Components/ArExperienceSelectModal";

import { Row, Col, Form, Button } from 'react-bootstrap';
import ApiService from '../../services/api.service';
import ArtworkService from '../../services/artwork.service';
import { translatedMessage } from '../../services/language.service';
import { hasPermission } from '../../services/api.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ENTER_KEY = 13;

class ArtworkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedUser: null,
      showDisplayInPortofolioModal: false,
      cardIdInModal: null,
      artworks: [],
      filteredResults: [],
      searchInput: '',
      canList: false,
      canEdit: false,
      showArtworkModal: false,
      showArExperienceModal: false,
      existingArtworks: [],
      existingARE: [],
      isLoaded: false
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getArtworks = this.getArtworks.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(response => {
      this.setState({
        canList: hasPermission("ARTWORK_LIST_ALL"),
        canEdit: hasPermission("ARTWORK_EDIT_ALL"),
      }, this.getArtworks)
    });
  }

  async getArtworks() {
    const artworks = await ArtworkService.getDemoArt();

    this.setState({
      artworks: artworks,
      filteredResults: artworks,
      isLoaded: true
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.artName.toLowerCase().includes(searchInput.toLowerCase()));
    }

    this.setState({
      filteredResults: result
    })
  }

  onShowArtworkModal = () => {
    var existing = [];
    this.state.artworks
      .filter(item => item.artwork !== null)
      .forEach(item => {
        existing.push(item.artwork.id)
      });


    this.setState({
      showArtworkModal: true,
      existingArtworks: existing
    })
  }

  onShowAREModal = () => {
    var existing = [];
    this.state.artworks
      .filter(item => item.arExperience !== null)
      .forEach(item => {
        existing.push(item.arExperience.id)
      });


    this.setState({
      showArExperienceModal: true,
      existingARE: existing
    })
  }

  onHideModal = (refresh) => {
    if (refresh) {
      this.setState({
        showArtworkModal: false,
        showArExperienceModal: false
      }, this.getArtworks);
    } else {
      this.setState({
        showArtworkModal: false,
        showArExperienceModal: false
      });
    }
  }

  render() {
    const artworks = this.state.filteredResults;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canList) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("DEMO_ART.INSTANT_MAGIC")}</span>

            {!this.state.canEdit ? "" :
              <div>
                <Button onClick={() => this.onShowArtworkModal()} className="ca-button mr-2">
                  <FontAwesomeIcon icon="plus" className="mr-2" />
                  {translatedMessage("ARTWORK")}
                </Button>

                <Button onClick={() => this.onShowAREModal()} className="ca-button">
                  <FontAwesomeIcon icon="plus" className="mr-2" />
                  {translatedMessage("AR_EXPERIENCE")}
                </Button>
              </div>
            }
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>
          </Row>

          <div className="card-columns">
            {artworks.map((element, index) => {
              return (
                <CardComponent
                  key={index}
                  cardImage={element.image}
                  cardTitle={element.artName}
                  cardId={element.id}
                  canEdit={this.state.canEdit}
                  canHaveTrigger={element.category === ArtworkService.artworkCategoryEnum().OBJECT}
                  onchange={this.getArtworks}
                />
              )
            })}
          </div>

          <ArtworkSelectModal
            show={this.state.showArtworkModal}
            onHide={this.onHideModal}
            existingArtworks={this.state.existingArtworks}
          />

          <ArExperienceSelectModal
            show={this.state.showArExperienceModal}
            onHide={this.onHideModal}
            existingARE={this.state.existingARE}
          />

        </div >
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage("USER.SECURITY.MISSING_RIGHTS")}</span>
          </Row>
        </div>
      )
    }
  }

}

export default ArtworkList;