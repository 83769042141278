import React from 'react';

import CardComponent from "./Components/ExhibitionMembershipCardComponent";

import { Row, Form, Button, Image } from 'react-bootstrap';

import exhibitionIcon from '../../../components/shared/Resources/icons/exhibition.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';

import ApiService from '../../../services/api.service';
import GalleryService from '../../../services/gallery.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

import { toast } from "react-toastify";

class ExhibitionAccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.id ? this.props.match.params.id : null,
      exhibition: [],
      accessTypes: [],
      memberships: [],
      exhibitionMemberships: [],
      isNew: false,
      isLoaded: false,
      quantityDisabled: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canEdit: hasPermission("EXHIBITION_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    var exhibition = [];
    var accessTypes = [];
    var exhibitionMemberships = [];
    var memberships = [];
    var promise = [];
    var isNew = false;

    if (this.state.canEdit) {
      const emPromise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().get(`gallery/exhibitionMembership/${this.state.exhibitionId}`)
          .then(response => {
            exhibitionMemberships = response.data.filter(item => item.membership.name.toUpperCase() !== "CA");
            resolve()
          })
          .catch(err => reject(err))
      })

      emPromise.then(() => {
        promise.push(
          ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${this.state.exhibitionId}`)
            .then(response => {              
              exhibition = response.data;
            })
        )

        promise.push(GalleryService.getExhibitionAccessTypeList()
          .then(response => {
            accessTypes = response;
            accessTypes.splice(0, 0, { label: "EXHIBITION_ACCESS_TYPE.NONE", name: "NONE" })
          })
        )

        if (exhibitionMemberships.length === 0) {
          promise.push(ApiService.getMembershipList()
            .then(response => {
              memberships = response;
              memberships.map(item => {
                const parts = item._links.self.href.split('/');
                const id = parts[parts.length - 1];
                item.id = id;
                return item;
              })
              memberships.sort((a, b) => {
                var result;
                a.id <= b.id ? result = -1 : result = 1
                return result;
              });
            })
          )
        }

        Promise.all(promise).then(() => {
          if (exhibitionMemberships.length === 0) {
            isNew = true;
            memberships.forEach((item, index) => {
              exhibitionMemberships[index] = {
                id: item.id,
                exhibition: exhibition,
                membership: item,
                hasAccess: false,
                accessFee: 0,
                accessType: { label: "EXHIBITION_ACCESS_TYPE.NONE", name: "NONE" },
                accessQuantity: '',
                acessTypeList: accessTypes,
                quantityDisabled: true
              }
            })
          } else {
            exhibitionMemberships.map((item, index) => {
              item.acessTypeList = accessTypes
              if (item.accessType === null) {
                item.quantityDisabled = true;
                item.accessType = { label: "EXHIBITION_ACCESS_TYPE.NONE", name: "NONE" };
              } else {
                item.quantityDisabled = false;
              }
              item.accessQuantity = item.accessQuantity === null ? '' : item.accessQuantity;
              return item;
            })
            exhibitionMemberships.sort((a, b) => {
              var result;
              a.membership.id <= b.membership.id ? result = -1 : result = 1
              return result;
            });
          }
          this.setState({
            accessTypes: accessTypes,
            exhibition: exhibition,
            memberships: memberships,
            exhibitionMemberships: exhibitionMemberships,
            isNew: isNew,
            isLoaded: true
          })
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })          
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  handleTypeSelect = (id, name) => {
    const selectedItem = this.state.accessTypes.filter(item => item.name === name);

    let exhibitionMemberships = this.state.exhibitionMemberships
    exhibitionMemberships.map((el, index) => {
      if (el.id.toString() === id.toString()) {
        el.accessType = selectedItem[0]
        if (selectedItem[0].name === 'NONE') {
          el.quantityDisabled = true;
          el.accessQuantity = 0;
        } else {
          el.quantityDisabled = false;
        }
      }
      return el;
    });

    this.setState({
      exhibitionMemberships: exhibitionMemberships,
    })
  }

  handleFeeInput = (id, value) => {
    let exhibitionMemberships = this.state.exhibitionMemberships
    exhibitionMemberships.map((el, index) => {
      if (el.id.toString() === id.toString()) {
        el.accessFee = value
      }
      return el;
    });

    this.setState({
      exhibitionMemberships: exhibitionMemberships
    })
  }

  handleQuantityInput = (id, value) => {
    let exhibitionMemberships = this.state.exhibitionMemberships
    exhibitionMemberships.map((el, index) => {
      if (el.id.toString() === id.toString()) {
        el.accessQuantity = value
      }
      return el;
    });

    this.setState({
      exhibitionMemberships: exhibitionMemberships
    })
  }

  handleSwithcEnable = (id, hasAccess) => {
    let exhibitionMemberships = this.state.exhibitionMemberships
    exhibitionMemberships.map((el, index) => {
      if (el.id.toString() === id.toString()) {
        el.hasAccess = hasAccess
      }
      return el;
    });

    this.setState({
      exhibitionMemberships: exhibitionMemberships
    })
  }

  validate = (event) => {
    var response = {
      isError: false,
      message: ""
    };

    return response;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const validation = this.validate(event);
    if (!validation.isError) {
      const exhibitionMemberships = this.state.exhibitionMemberships;
      var exhibitionPermission = [];

      exhibitionMemberships.forEach(item => {
        exhibitionPermission.push({
          id: this.state.isNew ? null : item.id,
          exhibitionId: item.exhibition.id,
          membershipId: parseInt(item.membership.id),
          hasAccess: item.hasAccess,
          accessType: item.accessType.name === 'NONE' ? null : item.accessType.name,
          accessFee: item.accessFee ? parseInt(item.accessFee) : 0,
          accessQuantity: parseInt(item.accessQuantity),
        })
      })

      ApiService.getAuthenticatedInstance().post(`/gallery/exhibition-membership-permissions/save`, exhibitionPermission)
        .then(response => {
          toast.success(translatedMessage(response.data.message));
          this.getData();
        })
        .catch(error => {
          console.log("An error has ocurred: " + error);
          toast.error(translatedMessage(error.response.data.message));
          this.getData();
        });
    } else {
      toast.error(validation.message);
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={exhibitionIcon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITION")} />
              <div>
                <div className="ca-page-title" style={{lineHeight: "32px"}}>{this.state.exhibition.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("EXHIBITION.ACCESS.PERMISSION")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button className="ca-dark-link ca-button-icon medium mr-1" type="submit" form="membershipForm">
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")}/>
              </Button>
              <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/exhibitionAdminList`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Form id="membershipForm" className="ca-form" onSubmit={this.handleSubmit}>
            <div className="ca-columns-3 card-columns">
              {this.state.exhibitionMemberships.map((element, index) => {
                return (
                  <CardComponent
                    key={element.membership.id}
                    data={element}
                    accessTypes={this.state.accessTypes}
                    onCheck={this.handleCheckChange}
                    onTypeSelect={this.handleTypeSelect}
                    onFeeInput={this.handleFeeInput}
                    onQuantityInput={this.handleQuantityInput}
                    onSwitchEnable={this.handleSwithcEnable}
                    quantityDisabled={this.state.quantityDisabled}
                  />
                )
              })}
            </div>
          </Form>

        </div >
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }

}

export default ExhibitionAccessPage;