import React from 'react';

import { Card, Button, Image } from 'react-bootstrap';
import { translatedMessage } from '../../../services/language.service';
import { Link } from 'react-router-dom';

import calogo from '../../../components/shared/Resources/logo-dark-small-with-padding.png'
import deleteIcon from '../../../components/shared/Resources/icons/delete.svg';

class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    deleteArExperience = (id) => {
        this.props.onDelete(id);
    }

    render() {
        return (
            <>
                <Card className="ca-artwork-card">
                    <Link to={`/arExperienceEdit/${this.props.id}`} className="ca-dark-link">
                        <Card.Img variant="top" src={this.props.image ? this.props.image : calogo} />
                        <Card.Body>
                            <Card.Title>{this.props.title}</Card.Title>
                            <Card.Text><b>{translatedMessage("GENERAL.TYPE")}: </b>
                                {this.props.category}</Card.Text>
                            <Card.Text><b>{translatedMessage("GENERAL.STATUS")}: </b>
                                {this.props.status}</Card.Text>
                        </Card.Body>
                    </Link>
                    {this.props.canBeDeleted &&
                        <Card.Footer>
                            <div className="ca-footer-controls">

                                <Button onClick={() => this.deleteArExperience(this.props.id)} className="ca-dark-link ca-button-icon small ml-1">
                                    <Image src={deleteIcon} alt={translatedMessage("GENERAL.DELETE")} title={translatedMessage("GENERAL.DELETE")} />
                                </Button>
                            </div>
                        </Card.Footer>
                    }
                </Card>
            </>
        )
    }

}

export default CardComponent;