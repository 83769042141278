import React from 'react';

import CardComponent from "../../../components/shared/Artwork/ArtworkCardEditComponent";

import { Row, Col, Dropdown, Form } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

const ENTER_KEY = 13;

class ArtworkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectedUser: null,
      showDisplayInPortofolioModal: false,
      cardIdInModal: null,
      artworks: [],
      statusList: [],
      categoryList: [],
      selectedStatus: { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" },
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      filteredResults: [],
      searchInput: '',
      canList: false,
      canEdit: false,
      canManageLocation: false,
      isLoaded: false
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(response => {
      this.setState({
        canList: hasPermission("ARTWORK_LIST_ALL"),
        canEdit: hasPermission("ARTWORK_EDIT_ALL"),
        canManageLocation: hasPermission("ARTWORK_MANAGE_LOCATION")
      }, this.getArtworks)
    });
  }

  async getArtworks() {
    const artworks = await ArtworkService.getArtworksByCriteria(true)
      .then(response => {
        let artworkList = response.filter(item => 
          item.status.name === ArtworkService.artworkStatusEnum().PUBLISHED || 
          item.status.name === ArtworkService.artworkStatusEnum().PREVIEW ||
          item.status.name === ArtworkService.artworkStatusEnum().SUBMITTED_FOR_APPROVAL);

        return artworkList
      })

    const categories = await ArtworkService.getArtworkCategoryList()
      .then(response => {
        let categoryList = response;
        categoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })

        return categoryList;
      })

    this.setState({
      artworks: artworks,
      filteredResults: artworks,
      categoryList: categories,
      isLoaded: true
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.categoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.category.id.toString() === categoryItem.id.toString());
    }

    this.setState({
      filteredResults: result
    })
  }

  render() {
    const artworks = this.state.filteredResults;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canList) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("ARTWORK.TITLE")}</span>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>

            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.categoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="card-columns">
            {artworks.map((element, index) => {
              return (
                <CardComponent
                  key={index}
                  cardImage={element.image}
                  cardTitle={element.name}
                  cardAuthor={element.author}
                  cardCategory={translatedMessage("PROJECT.CATEGORY." + element.category.code)}
                  cardId={element.id}
                  cardLink={`/artworkAdminView/${element.id}`}
                  cardEditLink={`/artworkAdminEdit/${element.id}`}
                  cardMapLink={`/artworkAdminLocation/${element.id}`}
                  cardStatus={element.status}
                  isUnpublished={element.status.name === "UNPUBLISHED"}
                  hasPortfolioDisplayCheck={false}
                  hasSpaceDisplayCheck={false}
                  canEdit={this.state.canEdit}
                  canManageLocation={this.state.canManageLocation}
                  canChangeStatus={true}
                />
              )
            })}
          </div>

        </div >
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage("USER.SECURITY.MISSING_RIGHTS")}</span>
          </Row>
        </div>
      )
    }
  }

}

export default ArtworkList;