import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

import backIcon from '../../../components/shared/Resources/left-arrow.svg';
import mediaIcon from '../../../components/shared/Resources/icons/media.svg';

import CompanyService from '../../../services/company.service';

class CompanyMediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      images: [],
      videos: [],
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    CompanyService.getCompanySpace(this.state.companyId)
      .then(response => {
        let company = response;

        let mediaFiles = [];
        if (company.spaceMediaFiles.length > 0) {
          company.spaceMediaFiles.forEach((item, index) => {
            if (item.usage === FileService.getFileUsage().THUMBNAIL) {
              let mockImage = {};
              mockImage.file = item;
              mockImage.original = ApiService.getFileByUuid(item.uuid);
              mockImage.thumbnail = ApiService.getFileByUuid(item.uuid);
              mockImage.type = FileService.getResourceType().IMAGE;
              mockImage.storageItemFileId = null;
              mockImage.youtubeId = item.youtubeId;

              mediaFiles.push(mockImage);
            }
          });
        }

        this.setState({
          company: company,
          videos: company.videoUrlList,
          images: mediaFiles,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={mediaIcon} className="ca-page-title-icon" alt={translatedMessage("GENERAL.IMAGES_VIDEOS")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
                <div className="ca-page-subtitle ca-muted-text">{this.state.company.spaceName}</div>
              </div>
            </div>
            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/company/${this.state.company.id}/space`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="mb-1">
            <Col className="col-12 col-lg-6 ca-artwork-column-left">
              {this.state.images.length === 0 && this.state.videos.length === 0 &&
                <div className="font-bold w-100 mb-1">
                  <span>{translatedMessage("MEDIA.NO_UPLOADS")}</span>
                </div>
              }
              {this.state.images.length > 0 &&
                <Carousel
                  id="presImg"
                  items={this.state.images}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={true}
                  toolbarDelete={false}
                  toolbarFullScreen={true}
                  canDeleteLast={false}
                />
              }
            </Col>
            <Col className="col-12 col-lg-6 ca-artwork-column-right">
              {this.state.videos.length > 0 &&
                <Carousel
                  id="video"
                  items={this.state.videos}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={false}
                  toolbarDelete={false}
                  toolbarYoutubeId={false}
                  videoUrlOnly={true}
                />
              }
            </Col>
          </Row>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyMediaPage;