import ApiService from './api.service';
import logoblack from '../components/shared/Resources/logo-dark-small-with-padding.png';
import ArtworkService from './artwork.service';

const ParkService = {

  async getParkList(criteria) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`park/list`, criteria)
        .then(response => {
          response.data.items.map(element => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });

          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async getParkForManage(parkId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`park/manage/${parkId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async getPark(parkId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`park/${parkId}/get`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async savePark(park) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`park/save`, park)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async saveParkApplication(application) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/park/apply`, application)
        .then(response => {
          resolve(response.data.application)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  async getParkApplication(parkId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`park/${parkId}/application`)
        .then(response => {
          resolve(response.data.application)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async getApplicationArtCategoryList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/parkApplicationArtCategoryList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  async saveParkApplicationArt(applicationArt) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`park/application/art/save`, applicationArt)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getParkStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/parkStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getParkArtListForManage(criteria) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`park/art/list/manage`, criteria)
        .then(response => {
          let arts = response.data.parkArt
          arts.map((element, index) => {
            if(element.artwork) {
              element.author = ArtworkService.getAuthorNameFromMap(element.artwork.authorList);
            }
            
            element.imageURL = element.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}` : "";          

            return element
          });

          arts.sort((a, b) => {
            var result;
            a.artName.toUpperCase() <= b.artName.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve({ park: response.data.park, artList: arts });
        })
        .catch(err => { reject(err) });
    })
  },

  getParkArtList(criteria) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`park/art/list`, criteria)
        .then(response => {
          let arts = response.data.parkArt
          arts.map((element, index) => {
            if(element.artwork) {
              element.author = ArtworkService.getAuthorNameFromMap(element.artwork.authorList);
            }
            
            element.imageURL = element.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}` : "";          

            return element
          });

          arts.sort((a, b) => {
            var result;
            a.artName.toUpperCase() <= b.artName.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve({ park: response.data.park, artList: arts });
        })
        .catch(err => { reject(err) });
    })
  },

  async getParkArt(parkArtId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`park/art/${parkArtId}/manage`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  addArtToPark(parkId, entityName, artList) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/park/${parkId}/addArtwork/${entityName}?artList=${artList}`)
        .then(resolve)
        .catch(err => {
          reject(err);
        });
    });
  },

  async getParkLocation(parkId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`park/${parkId}/location/manage`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async getParArtkLocationsForManage(parkId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`map/parkMapLocations/${parkId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async getParMembershipPermissions(parkId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`park/${parkId}/membershipPermission`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  async saveParMembershipPermissions(savedPermissions) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/park/membershipPermission/save`, savedPermissions)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default ParkService;