import React from 'react';

import { Row, Image } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import backArrow from '../../../components/shared/Resources/left-arrow.svg';

import { translatedMessage } from '../../../services/language.service';

class GenericErrorComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      translateMessage: this.props.translateMessage !== undefined ? this.props.translateMessage : true,
      editModal: false,
      deleteModal: false,
      editModalData: {},
      deleteModalData: {}
    };

  }

  render() {
    return (
      <div className="ca-page-padding ca-main-container">
        <Row className="ca-page-title-button-row mb-1">
          <div className="ca-page-subtitle d-flex align-items-center">
            <span className="font-weight-bold">
              {this.state.translateMessage ? translatedMessage(this.props.errorMessage) : this.props.errorMessage}
            </span>&nbsp;
          </div>
          <div>
            {(this.props.backUrl || this.props.history) &&
            <Link to={this.props.backUrl ? this.props.backUrl : "#"} className="ca-dark-link" onClick={() => this.props.backUrl ? "" : this.props.history.go(-1)}>
              <Image className="ca-page-title-icon ml-2 mr-0" src={backArrow} alt={translatedMessage("BUTTON.BACK")}
                title={translatedMessage("BUTTON.BACK")} />
            </Link>
            }
          </div>
        </Row>
      </div>
    )
  }
}

export default GenericErrorComponents;
