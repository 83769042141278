import React from 'react';

import { Image } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  render() {
    return (
      <>
        <div className="ca-image-card-container" onClick={() => this.props.onClick(this.props.id, this.props.entity.artwork.category.code, this.props.entity.position)}
          style={{ width: "100%", textAlign: "left" }}>
          <Image src={this.props.cardImage} />
          <div className="ca-image-card-container-hover">
            {this.props.id !== 0
              ? <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf040;</div>
              : <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf067;</div>
            }
            <div>{(this.props.id !== 0 ? translatedMessage("GENERAL.CHANGE") : translatedMessage("GENERAL.ADD")) +
              " " + translatedMessage("ARTWORK.ARTWORK").toLowerCase()}</div>
          </div>
          <div style={{ position: "absolute", bottom: "5px", width: "100%", textAlign: "center" }}>
            <span style={{ padding: "5px 5px", backgroundColor: "#333333", color: "#333333", opacity: "0.6" }}>
              {/* {this.props.id !== 0 &&
                <>
                  <span style={{ fontSize: "16px" }}>
                    {this.props.entity.artwork.name}
                  </span>
                  <br />
                </>
              } */}
              {translatedMessage("VIRTUAL_GALLERY.ARTWORK_POSITION")}: {this.props.entity.position}
            </span>
            <div style={{ position: "absolute", color: "#ffffff", top: "0", width: "100%", textAlign: "center" }}>
              {/* {this.props.id !== 0 &&
                <>
                  <span style={{ fontSize: "16px" }}>
                    {this.props.entity.artwork.name}
                  </span>
                  <br />
                </>
              } */}
              {translatedMessage("VIRTUAL_GALLERY.ARTWORK_POSITION")}: {this.props.entity.position}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CardComponent;