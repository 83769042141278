import React from 'react';

import ArtProjectCreate from './Components/ArtProjectCreateComponent'

import ApiService from '../../services/api.service';

import arPortalIcon from '../../components/shared/Resources/icons/arPortal_white.svg';

class ObjectProjectCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId ? this.props.match.params.companyId : null,
        };
    }

    componentDidMount() {
        let user = ApiService.getCurrentUser();
        if (!user) {
            ApiService.initCurrentUser()
                .then(response => {
                    this.setState({ user: response })
                })
        } else {
            this.setState({ user: user })
        }
    }

    render() {
        return (
            <>
                <ArtProjectCreate
                    pageTitle={"PROJECT.CREATE_AR_PORTAL"}
                    icon={arPortalIcon}
                    projectCategory={5}
                    history={this.props.history}
                    companyId={this.state.companyId}
                    nexturl={`/project/publish/ar-portal`}
                />
            </>
        )
    }

}

export default ObjectProjectCreatePage;
