import React from 'react';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import ParkArtComponent from '../../components/shared/Park/ParkArtComponent';

class ParkArtPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.match.params.id,
      isLoaded: false,
      isError: false
    };
  }

  componentDidMount = () => {
    this.setState({ isLoaded: true })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ParkArtComponent
          parkId={this.props.match.params.id}
          backUrl={`/themePark/${this.props.match.params.id}/view`}
          history={this.props.history}
        />        
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ParkArtPage;