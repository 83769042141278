import React from 'react';

import { Button, Modal, Card } from 'react-bootstrap';

import { toast } from "react-toastify";
import moment from 'moment';

import ApiService from "../../../services/api.service";
import { translatedMessage } from '../../../services/language.service';

class ArtworkSelectModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            artworks: this.props.artworks,
            evgId: this.props.evgId,
            evgName: this.props.evgName,
            evgaId: null,
            categoryCode: this.props.categoryCode,
            displayedArtworks: [],
            disableClick: false
        }
    }

    componentDidMount() {
        this.computeData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show) {
            this.setState({
                artworks: this.props.artworks,
                evgId: this.props.evgId,
                evgaId: this.props.evgaId,
                categoryCode: this.props.categoryCode,
                position: this.props.position
            }, this.computeData);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.show !== prevState.show) {
            return {
                artworks: prevState.artworks,
                evgId: prevState.evgId,
                evgaId: prevState.evgaId,
                categoryCode: prevState.categoryCode,
                position: prevState.position
            };
        }
        else return null;
    }

    computeData = () => {
        let displayedArtworks = this.state.artworks.filter(item => item.category.code === this.state.categoryCode);

        displayedArtworks.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
        });

        this.setState({
            displayedArtworks: displayedArtworks
        })
    }

    saveChanges = (artId) => {
        let artworkId = this.props.artentity === ApiService.getEntityName().ARTWORK ? artId : null;
        let arExperienceId = this.props.artentity === ApiService.getEntityName().AR_EXPERIENCE ? artId : null;

        if (!this.state.disableClick) {
            this.setState({
                disableClick: true
            }, () => {
                if (this.state.evgaId !== 0) {
                    var promise;

                    if (this.state.evgName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                        // art for virtual gallery
                        promise = new Promise((resolve, reject) => {
                            ApiService.getAuthenticatedInstance().patch(`/virtualGalleryArtworks/${this.state.evgaId}`, {
                                virtualGallery: `/virtualGalleries/${this.state.evgId}`,
                                position: this.state.position,
                                artwork: `/artworks/${artworkId}`,
                                lastUpdatedBy: ApiService.getCurrentUser().username,
                                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            })
                                .then(() => resolve())
                                .catch(err => reject(err))
                        });
                    } else {
                        // art for virtual gallery that is part of an entity (EXHIBITION | GALLERY)
                        promise = new Promise((resolve, reject) => {
                            ApiService.getAuthenticatedInstance().patch(`/entityVirtualGalleryArts/${this.state.evgaId}`, {
                                entity: {
                                    entityId: this.state.evgId,
                                    entityName: this.state.evgName
                                },
                                position: this.state.position,
                                artwork: artworkId ? `/artworks/${artworkId}` : null,
                                arExperience: arExperienceId ? `/arExperiences/${arExperienceId}` : null,
                                lastUpdatedBy: ApiService.getCurrentUser().username,
                                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            })
                                .then(() => resolve())
                                .catch(err => reject(err))
                        });
                    }
                }
                else {
                    if (this.state.evgName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                        // art for virtual gallery
                        promise = new Promise((resolve, reject) => {
                            ApiService.getAuthenticatedInstance().post(`/virtualGalleryArtworks`, {
                                virtualGallery: `/virtualGalleries/${this.state.evgId}`,
                                artwork: `/artworks/${artworkId}`,
                                position: this.state.position,
                                lastUpdatedBy: ApiService.getCurrentUser().username,
                                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            })
                                .then(() => resolve())
                                .catch(err => reject(err))
                        });
                    } else {
                        // art for virtual gallery that is part of an entity (EXHIBITION | GALLERY)                    
                        promise = new Promise((resolve, reject) => {
                            ApiService.getAuthenticatedInstance().post(`/entityVirtualGalleryArts`, {
                                entity: {
                                    entityId: this.state.evgId,
                                    entityName: this.state.evgName
                                },
                                artwork: artworkId ? `/artworks/${artworkId}` : null,
                                arExperience: arExperienceId ? `/arExperiences/${arExperienceId}` : null,
                                position: this.state.position,
                                lastUpdatedBy: ApiService.getCurrentUser().username,
                                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            })
                                .then(() => resolve())
                                .catch(err => reject(err))
                        });
                    }
                }

                promise.then(() => {
                    toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
                    this.props.onHide(true);
                    this.setState({
                        disableClick: false
                    });
                })
                    .catch(err => {
                        console.log("An error has ocurred: " + err);
                        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                    })
            })
        }
    }

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="ca-artworks-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {translatedMessage(this.state.categoryCode) + " " + translatedMessage("ARTWORK.ARTWORKS").toLowerCase()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-columns">
                        {this.state.displayedArtworks.map((element, index) => {
                            return (
                                <Card key={index} className="ca-card-image-only" onClick={() => this.saveChanges(element.id)}>
                                    <Card.Img variant="top" src={element.image} />
                                    <div style={{ position: "absolute", bottom: "10px", width: "100%", textAlign: "center" }}>
                                        <span style={{ padding: "5px 5px", backgroundColor: "#333333", color: "#333333", opacity: "0.6" }}>
                                            {element.name}
                                        </span>
                                        <div style={{ position: "absolute", color: "#ffffff", top: "0", width: "100%", textAlign: "center" }}>
                                            {element.name}
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button ca-button-white" variant="dark" onClick={() => this.props.onHide(false)}>
                        {translatedMessage("GENERAL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ArtworkSelectModal;