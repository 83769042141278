import React from 'react';

import EntityMediaPageComponent from '../../../components/shared/EntityMediaPageComponent/EntityMediaPageComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import exhibitionIcon from '../../../components/shared/Resources/icons/exhibition.svg';

import { Row, Image, Button } from 'react-bootstrap';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

class ExhibitionAdminMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.id,
      exhibition: {},
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("EXHIBITION_EDIT"),
      }, this.getData)
    });
  }

  async getData() {
    const exhibitionId = this.state.exhibitionId;
    let isError = false;
    let errorMessage = "GENERAL.GET_DATA_ERROR";


    if (this.state.canEdit) {
      var exhibition = await ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${exhibitionId}/manage`)
        .then(response => {
          return response.data.exhibition;
        })
        .catch(err => {
          isError = true;
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }
        })

      if (!isError) {
        this.setState({
          exhibition: exhibition,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={exhibitionIcon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITION")} />
              <span className="ca-page-title">{this.state.exhibition.name}</span>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/exhibitionAdminList`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")}/>
              </Button>
            </div>
          </Row>          

          <EntityMediaPageComponent
            canEdit={this.state.canEdit}
            entity={this.state.exhibition}
            entityName={ApiService.getEntityName().EXHIBITION}
            useVideoUrl={true}
          />          
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionAdminMedia;