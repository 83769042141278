import React from "react";

import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button } from 'react-bootstrap';

import { translatedMessage } from '../../../../services/language.service';
import ApiService from '../../../../services/api.service';
import DownloadService from '../../../../services/download.service';

import './TableComponent.css';
import EditGiftModal from '../Component/EditGiftModal'

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      pageLength: this.props.data.length,
      packageSettings: {},
      itemsPerPage: 10,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: translatedMessage("TABLE.PAGE"),
      ofText: translatedMessage("TABLE.OF"),
      rowsText: translatedMessage("TABLE.PAGE"),
      giftModalVisible: false,
      giftArtwork: {}
    };
  }

  handleEditClick = (row) => {
    this.setState({
      giftArtwork: row.original,
      giftSent: row.original.status.name !== 'PENDING' ? "true" : "false",
      giftModalVisible: true
    })
  }

  onHideModal = (giftId, giftReceiver) => {
    if (giftId) {
      ApiService.getAuthenticatedInstance().patch(`/artwork/saveArtworkAsGift/${giftId}`, {
        receiverName: giftReceiver.name,
        receiverEmail: giftReceiver.email,
        sendNow: giftReceiver.sendNow
      })
        .then((response) => {
          if (response.data.status === "OK") {
            toast.success(translatedMessage(response.data.message));
          } else {
            toast.error(translatedMessage(response.data.message));
          }
          var stateData = this.state.data;
          stateData.map(item => {
            if (giftId === item.id) {
              item.receiverName = response.data.data.receiverName;
              item.receiverEmail = response.data.data.receiverEmail;
              item.status = response.data.data.status;
            }
            return item;
          })

          this.setState({
            data: stateData,
            giftModalVisible: false
          })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          console.log(err.response)
          toast.error(translatedMessage(err.response.data.message));
          this.setState({
            giftModalVisible: false
          });
        })
    } else {
      this.setState({
        giftModalVisible: false
      });
    }
  }

  handleRedeemClick = (row) => {
    const giftId = row.original.id;
    ApiService.getAuthenticatedInstance().post(`/artwork/redeemGift/${giftId}`)
      .then((response) => {
        if (response.data.status === "OK") {
          toast.success(translatedMessage(response.data.message));
        } else {
          toast.error(translatedMessage(response.data.message));
        }
        var stateData = this.state.data;
        stateData.map(item => {
          if (giftId === item.id) {
            item.status = response.data.data.status;
          }
          return item;
        })

        this.setState({
          data: stateData,
        })
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        console.log(err.response)
        toast.error(translatedMessage(err.response.data.message));
      })
  }

  getGiftUrl = (event) => {
    let giftId = event.target.id;
    if (!this.state.downloading) {
      this.setState({
        downloading: true
      }, () => {
        DownloadService.httpRequest(
          ApiService.getBaseUrl() + '/storage/saveGiftCard/' + giftId, 'get')
          .then(response => response.arrayBuffer())
          .then((blob) => {
            //Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'gift_card.pdf');

            //Append to html page
            document.body.appendChild(link);

            //Force download
            link.click();

            //Clean up and remove the link                    
            setTimeout(function () {
              link.parentNode.removeChild(link);
              URL.revokeObjectURL(url);
            }, 3000);

            this.setState({
              downloading: false
            });
          })
          .catch((error) => {
            var errorMessage = translatedMessage("GENERAL.GENERIC_ERROR");
            if (error.status === 403) {
              errorMessage = translatedMessage("GENERAL.GENERIC_RIGHTS_ERROR")
            } else if (error.status === 404) {
              errorMessage = translatedMessage("GENERAL.FILE_NOT_FOUND_ERROR")
            }

            toast.error(errorMessage, { autoClose: 7000 });

            this.setState({
              downloading: false,
            });
          });
      })
    }
  }

  render() {
    const data = this.state.data;
    return (
      <div className="ca-table-gift" style={{ marginBottom: "100px" }}>
        <ReactTable
          data={data}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBotton={true}
          showPageSizeOptions={true}
          pageSize={this.state.pageLength}
          multiSort={false}
          sortable={true}
          resizable={true}
          filterable={false}
          defaultPageSize={this.state.itemsPerPage}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          minRows={1}
          columns={[
            {
              Header: translatedMessage("GIFT.SENDER"),
              accessor: "sender.fullName",
              show: !this.props.sent
            },
            {
              Header: translatedMessage("GIFT.RECEIVER"),
              accessor: "receiverName",
              Cell: row => {
                return (
                  <span>
                    {row.original.receiverName}
                    <br />
                    <span className="ca-table-smallText">{row.original.receiverEmail}</span>
                    <span className="ca-table-smallText">{row.original.id}</span>
                  </span>
                )
              },
              show: this.props.sent
            },
            {
              Header: translatedMessage("GIFT.ARTWORK"),
              accessor: "soldArtwork.artwork.name",
              Cell: row => {
                return (
                  <Link to={`/artwork/${row.original.soldArtwork.artwork.id}`}>{row.original.soldArtwork.artwork.name}</Link>
                )
              }
            },
            {
              Header: translatedMessage("GENERAL.STATUS"),
              accessor: "original.status.label",
              Cell: row => { return (translatedMessage(row.original.status.label)) },
            },
            {
              Header: translatedMessage("GIFT.CARD"),
              Cell: row => {
                return (
                  row.original.giftCardFile &&
                  <div>
                    <span onClick={this.getGiftUrl} id={row.original.id} className="ca-dark-link"
                      style={{ cursor: "pointer", width: "auto", fontFamily: "FontAwesome", fontSize: "18px" }}>&#xf1c1;</span>
                  </div>
                )
              },
              show: this.props.sent,
              sortable: false,
            },
            {
              Header: this.props.sent ? translatedMessage("GENERAL.EDIT") : translatedMessage("GIFT.REDEEM"),
              Cell: row => {
                return (
                  <span className="ca-table-link">
                    {this.props.sent &&
                      <Button variant="link" className="ca-dark-link"
                        onClick={() => { this.handleEditClick(row) }}
                        disabled={!(row.original.status.name === 'PENDING' || row.original.status.name === 'SENT')}
                        style={{ fontFamily: "FontAwesome", fontSize: "18px" }}>&#xf040;
                      </Button>
                    }
                    {!this.props.sent &&
                      <Button variant="link" className="ca-dark-link"
                        onClick={() => { this.handleRedeemClick(row) }}
                        disabled={!(row.original.status.name === 'SENT')}
                        style={{ fontFamily: "FontAwesome", fontSize: "18px" }}>&#xf019;
                      </Button>
                    }
                  </span>
                )
              },
              sortable: false,
            },
          ]}

        />

        <EditGiftModal
          show={this.state.giftModalVisible}
          onHide={this.onHideModal}
          data={this.state.giftArtwork}
          giftstatus={this.state.giftSent}
        />
      </div>


    );
  }
}

export default TableComponent
