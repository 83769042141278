import React from 'react';

import myCollection from '../../components/shared/Resources/homepage/myCollection.svg';
import myVirtualGallery from '../../components/shared/Resources/homepage/myVirtualGallery.svg';
import back from '../../components/shared/Resources/left-arrow.svg';

import CardComponent from "../../components/shared/Artwork/ArtworkCardComponent";
import GiftReceiverDetailsModal from "./Components/GiftReceiverDetailsModal"

import { Row, Col, Dropdown, Form, InputGroup, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';
import ArtworkService from '../../services/artwork.service';

import { toast } from "react-toastify";

const ENTER_KEY = 13;

class MyCollectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
            artworks: [],
            user: {},
            artworkCategoryList: [],
            filteredArtworks: [],
            selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
            giftReceiverModalVisible: false,
            giftSoldArtworkId: 0,
            isLoaded: false
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount = () => {
        let user  = ApiService.getCurrentUser();
        if(!user) {
            ApiService.initCurrentUser()
            .then(response => {
                this.setState({ user: response }, this.getArtworks)
            })
        } else {
            this.setState({ user: user }, this.getArtworks)
        }        
    }

    getArtworks = () => {
        var artworkCategoryList = [];
        var artworks = [];
        var promise = [];

        promise.push(ArtworkService.getArtworkCategoryList()
            .then(response => {
                artworkCategoryList = response;
                artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
            }))

        promise.push(ArtworkService.getCollection()
            .then(response => artworks = response)
        )

        Promise.all(promise).then(() => {
            this.setState({
                artworks: artworks,
                filteredArtworks: artworks,
                artworkCategoryList: artworkCategoryList,
                isLoaded: true
            })
        })
    }

    handleSearchInput = (e) => {
        this.setState({
            searchInput: e.target.value,
        }, this.filter);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.setState({
                searchInput: e.target.value,
            }, this.filter);
        }
    }

    handleOnCategorySelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

        this.setState({
            selectedCategory: selectedItem[0]
        }, this.filter)
    }

    filter = () => {
        let result = this.state.artworks;
        let searchInput = this.state.searchInput;
        let categoryItem = this.state.selectedCategory;

        if (0 !== searchInput.replace(/ /g, ";").length) {
            result = this.state.artworks.filter(elem => elem.artwork.name.toLowerCase().includes(searchInput.toLowerCase())
                || elem.author.toLowerCase().includes(searchInput.toLowerCase()));
        }

        if (categoryItem.id !== "0") {
            result = result.filter(elem => elem.artwork.category.id.toString() === categoryItem.id.toString());
        }

        this.setState({
            filteredArtworks: result
        })
    }

    handleCheckChange = (id) => {
        this.setState({
            giftSoldArtworkId: id,
            giftReceiverModalVisible: true
        })
    }

    onHideModal = (giftSoldArtworkId, giftReceiver) => {
        if (giftSoldArtworkId) {
            ApiService.getAuthenticatedInstance().post(`/artwork/saveArtworkAsGift/`, {
                sender: this.state.user.id,
                soldArtwork: parseInt(giftSoldArtworkId),
                receiverName: giftReceiver.name,
                receiverEmail: giftReceiver.email,
                sendNow: giftReceiver.sendNow
            })
                .then((response) => {
                    if (response.data.status === "OK") {
                        toast.success(translatedMessage(response.data.message));
                    } else {
                        toast.error(translatedMessage(response.data.message));
                    }
                    this.setState({
                        giftReceiverModalVisible: false
                    }, this.getArtworks)
                })
                .catch(err => {
                    console.log("An error has ocurred: " + err);
                    console.log(err.response)
                    toast.error(translatedMessage(err.response.data.message));
                    this.setState({
                        giftReceiverModalVisible: false
                    });
                })
        } else {
            this.setState({
                giftReceiverModalVisible: false
            });
        }
    }

    render() {
        const artworks = this.state.filteredArtworks;
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <div className="ca-page-padding ca-main-container">
                    <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
                        <div className="d-flex align-items-center">
                            <Image src={myCollection} className="ca-page-title-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")}/>
                            <span className="ca-page-title">
                                {translatedMessage("GALLERY.MY_COLLECTION")}
                            </span>                         
                        </div>
                        <div>
                            <Link to="/virtual-galleries" className="ca-dark-link w-100">
                                <Image className="ca-page-title-icon" src={myVirtualGallery} alt={translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}
                                        title={translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}/>
                            </Link>
                            <Link to="/collection-home" className="ca-dark-link">
                                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")} 
                                        title={translatedMessage("BUTTON.BACK")}/>
                            </Link>
                        </div>
                    </Row>                    
                    <Row className="d-flex align-items-center">
                        <Col className="col-12 col-md-6 mb-1 pl-0 pr-2">
                            <InputGroup className="ca-form-input-group">
                                <Form.Control value={this.state.searchInput} type="text"
                                    placeholder={translatedMessage("SEARCH.BY.NAME_AUTHOR")}
                                    onChange={this.handleSearchInput}
                                    onKeyDown={this.handleKeyDown} />
                                <InputGroup.Append>
                                    <InputGroup.Text>&#xF002;</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                        <Col className="col-12 col-md-6 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedCategory.code)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.artworkCategoryList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.id}>
                                                {translatedMessage(element.code)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <div className="card-columns">
                        {artworks.map((element, index) => {
                            return (
                                <CardComponent
                                    key={index}
                                    cardImage={element.image}
                                    cardTitle={element.artwork.name}
                                    cardArtworkAuthor={element.author}
                                    cardCategory={element.artwork.category.code}
                                    cardPackage={element.artworkPricePackage.name +
                                        (element.artworkPricePackage.quantity === -1
                                            ? ""
                                            : " - " + element.issueNumber + " / " + element.artworkPricePackage.nrCopies)}
                                    cardId={element.artwork.id}
                                    soldArtworkId={element.id}
                                    cardLink={`/collection-artwork/${element.id}`}
                                    canBeGift={element.canBeGift}
                                    status={element.status}
                                    onCheck={this.handleCheckChange}
                                />
                            )
                        })}
                    </div>

                    <GiftReceiverDetailsModal
                        show={this.state.giftReceiverModalVisible}
                        onHide={this.onHideModal}
                        giftsoldartworkid={this.state.giftSoldArtworkId}
                    />

                </div >
            )
        }
    }

}

export default MyCollectionPage;