import React from 'react';

import { Modal, Button, Form, FormGroup } from 'react-bootstrap/'

import { translatedMessage } from '../../../services/language.service'

class ChangeStatusModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            status: this.props.status
        }

    }

    statusChange = event => {
        const value = event.target.value;

        this.setState({
            status: value
        });
    }

    saveChanges = (event) => {
        event.preventDefault();
        
        this.props.onHide(this.state.id, this.state.status);
    };

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {translatedMessage("TABLE.CHANGE_STATUS")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <FormGroup controlId="formMembership">
                            <Form.Label className="editmodal-userinfo-title">{translatedMessage("GENERAL.STATUS")}</Form.Label>
                            <Form.Control
                                name="status"
                                as="select"
                                required
                                defaultValue={this.props.status}
                                onChange={this.statusChange}
                            >
                                <option value="PREVIEW">{translatedMessage("ARTWORK.STATUS.PREVIEW")}</option>
                                <option value="PUBLISHED">{translatedMessage("ARTWORK.STATUS.PUBLISHED")}</option>
                                <option value="SUBMITTED_FOR_APPROVAL">{translatedMessage("ARTWORK.STATUS.SUBMITTED_FOR_APPROVAL")}</option>
                            </Form.Control>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ChangeStatusModalComponent