import React from 'react';

import ExhibitionManageVirtualGalleryComponent from '../../../components/shared/Exhibition/ExhibitionManageVirtualGalleryComponent';
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

import exhibitionIcon from '../../../components/shared/Resources/icons/exhibition.svg';

class ExhibitionAdminVirtualGalleryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.exhibitionId,
      companyId: this.props.match.params.id,
      isLoaded: false,
      canEdit: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("COMPANY_EDIT"),
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <ExhibitionManageVirtualGalleryComponent
          history={this.props.history}
          canEdit={this.state.canEdit}
          isAdmin={true}
          hasFreeForEntityOption={true}
          exhibitionId={this.state.exhibitionId}
          backUrl={`/company-admin/${this.state.companyId}/exhibition`}
          mapUrl={`/exhibition/${this.state.exhibitionId}/map`}
          artworkPrefixUrl={`/company-admin/${this.state.companyId}/exhibition-virtual-gallery`}
          icon={exhibitionIcon}
        />        
      )
    }
  }

}

export default ExhibitionAdminVirtualGalleryList;