import React from 'react';

import { Modal, Button, Form } from 'react-bootstrap'
import moment from "moment";
import {toast} from "react-toastify";

import LanguageService from '../../../../services/language.service';
import ApiService from "../../../../services/api.service";

class EditWebResourceComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profileLink: {
                id: '',
                name: '',
                uri: '',
            }
        }
    }

    UNSAFE_componentWillReceiveProps(props, context) {
        // console.log(props)
        this.setState({
            profileLink: props.profilelink
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        ApiService.getAuthenticatedInstance().patch(`/profileLinks/${this.state.profileLink.id}`, {
            name: event.target.elements.formName.value,
            uri: event.target.elements.formLink.value,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().unix(),
        }).then(result => {
            toast.success(window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.CHANGE_SUCCES"));
            this.props.onHide();
        });
    };

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="web-resource-edit-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.EDIT_WEB_RESOURCE")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="ca-form" onSubmit={this.handleSubmit} id="editWebResourceForm">
                        <Form.Group controlId="formName">
                            <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.NAME")}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.NAME_PLACEHOLDER")}
                                defaultValue={this.props.profilelink.name}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLink">
                            <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.LINK")}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.LINK_PLACEHOLDER")}
                                defaultValue={this.props.profilelink.uri}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" type="submit" form="editWebResourceForm">{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.SAVE_CHANGES")}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default EditWebResourceComponent