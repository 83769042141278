import React from 'react';

import { Row, Col } from 'react-bootstrap/';
import { CSVLink } from 'react-csv';
import Big from 'big.js';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import TableComponent from './Components/TableComponent/TableComponent';

class SalesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      paymentTransactions: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      exportData: [],
      exportHeaders: []
    };
  }

  componentDidMount() {
    ApiService.initCurrentUser()
      .then(response => {
        this.setState({
          canViewList: hasPermission("SALES_LIST")
        }, this.getData)
      })
  }

  async getData() {
    if (this.state.canViewList) {
      const paymentTransactions = await ApiService.getAuthenticatedInstance().get(`payment/all-payments`)
        .then(response => {
          const transactions = response.data;
          transactions.map(item => {
            if (item.entityName === ApiService.getEntityName().MEMBERSHIP) {
              item.transactionDetail = translatedMessage("MEMBERSHIP.PAYMENT." + item.paymentType) + " - " + item.paymentName;
            } else if (item.entityName === ApiService.getEntityName().ARTWORK || item.entityName === ApiService.getEntityName().VIRTUAL_GALLERY ) {
              item.transactionDetail = item.paymentName;
            } else if (item.entityName === ApiService.getEntityName().EXHIBITION || item.entityName === ApiService.getEntityName().GALLERY) {
              item.transactionDetail = translatedMessage(item.paymentType) + " - " + item.paymentName;
            } else {
              item.transactionDetail = translatedMessage("BILLING.ITEM_PURCHASE");
            }

            item.date = ApiService.parseDate(item.lastUpdatedOn).toLocaleDateString("en-EN", { year: 'numeric', month: 'short', day: 'numeric' })

            return item
          })
          return transactions;
        })
        
      this.setState({
        paymentTransactions: paymentTransactions,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  exportData = () => {
    const paymentTransactions = this.state.paymentTransactions;
    const exportHeaders = [
      { label: translatedMessage("GENERAL.DATE"), key: 'date' },
      { label: translatedMessage("BILLING.ORDER"), key: 'orderNumber' },
      { label: translatedMessage("PROFILE.FIRST_NAME"), key: 'firstName' },
      { label: translatedMessage("PROFILE.LAST_NAME"), key: 'lastName' },
      { label: translatedMessage("BILLING.AMOUNT"), key: 'amount' },
      { label: translatedMessage("TAX.VAT"), key: 'vat' },
      { label: translatedMessage("TAX.ART_TAX"), key: 'artTax' },
      { label: translatedMessage("BILLING.AMOUNT_NET"), key: 'netAmount' },
      { label: translatedMessage("BILLING.AMOUNT_NET_CA"), key: 'caAmount' },
      { label: translatedMessage("BILLING.AMOUNT_NET_ARTIST"), key: 'artistAmount' },
      { label: translatedMessage("BILLING.AMOUNT_NET_INVESTOR"), key: 'investorAmount' },
      { label: translatedMessage("BILLING.AMOUNT_NET_COMPANY"), key: 'companyAmount' },
      { label: translatedMessage("GENERAL.CURRENCY"), key: 'currency' },
      { label: translatedMessage("BILLING.PURCHASE"), key: 'purchase' },
      { label: translatedMessage("TABLE.HEADER.TYPE"), key: 'type' },
      { label: translatedMessage("COMPANY.COMPANY"), key: 'companyName' },
    ];

    let exportData = [];
    paymentTransactions.forEach(item => {
      let vat = item.vat ? new Big(item.vat) : new Big(0);
      let artTax = item.artTax ? new Big(item.artTax) : new Big(0);
      let amount = new Big(item.amount);
      let netAmount = amount.minus(vat).minus(artTax);
      let caAmount = netAmount.times(new Big(item.caPercent)).div(100);
      let artistAmount = netAmount.times(new Big(item.artistPercent)).div(100);
      let investorAmount = netAmount.times(new Big(item.investorPercent)).div(100);
      let companyAmount = netAmount.times(new Big(item.companyPercent)).div(100);

      let exportItem = {};
      exportItem.date = item.date;
      exportItem.orderNumber = item.orderNumber;
      exportItem.firstName = item.user.firstName;
      exportItem.lastName = item.user.lastName;
      exportItem.amount = item.amount;      
      exportItem.vat = item.vat;
      exportItem.artTax = item.artTax;
      exportItem.netAmount = netAmount.toFixed(2);
      exportItem.caAmount = caAmount.toFixed(2);
      exportItem.artistAmount = artistAmount.toFixed(2);
      exportItem.investorAmount = investorAmount.toFixed(2);
      exportItem.companyAmount = companyAmount.toFixed(2);
      exportItem.currency = item.currency;
      exportItem.purchase = item.transactionDetail;
      exportItem.type = item.entityName;
      exportItem.companyName = item.company ? item.company.name : "";

      exportData.push(exportItem)
    });

    this.setState({
      exportData: exportData,
      exportHeaders: exportHeaders
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("REPORT.SALES")}</span>

            <CSVLink className="ca-button" data={this.state.exportData} headers={this.state.exportHeaders}
              enclosingCharacter={`"`} separator={";"} filename={"sales_report.csv"}
              onClick={this.exportData}>
              {translatedMessage("GENERAL.EXPORT")}
            </CSVLink>
          </Row>          

          {this.state.paymentTransactions.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("BILLING.NO_TRANSACTION")}
                  </span>
                </Col>
              </Row>
            </div>
          }

          {this.state.paymentTransactions.length > 0 &&
            <div>
              <TableComponent
                data={this.state.paymentTransactions}
                sent={true}
              />
            </div>
          }
        </div>
      )
    }  else {
      return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default SalesPage;