import React from 'react';
import './MyProjectsPage.css';

import myPortfolio from '../../components/shared/Resources/homepage/myPortfolio.svg';
import myProjects from '../../components/shared/Resources/homepage/myProjects.svg';
import back from '../../components/shared/Resources/left-arrow.svg';

import CardComponent from "./CardComponent/CardComponent";

import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';
import ArtworkService from '../../services/artwork.service';

const ENTER_KEY = 13;

class MyProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            artworks: null,
            statusList: [],
            categoryList: [],
            selectedStatus: { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" },
            selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
            filteredResults: [],
            searchInput: '',
            isLoaded: false
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount = () => {
        this.getArtworks();
    }

    getArtworks = () => {
        var statusList = [];
        var categoryList = [];

        ApiService.getAuthenticatedInstance().get(`/artwork/projects`)
            .then(response => {
                const promise = new Promise((resolve, reject) => {
                    ArtworkService.getProjectStatusList()
                        .then(statuses => {
                            statusList = statuses;
                            statusList.splice(0, 0, { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" })
                            ArtworkService.getArtworkCategoryList()
                                .then(categories => {
                                    categoryList = categories;
                                    categoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
                                    resolve();
                                })
                        })
                })
                promise.then(() => {
                    let projects = response.data;
                    projects.map((element, index) => {
                        element.imageURL = ApiService.getFileByProjectUrl(element.id) + '?' + Date.now();
                        return element;
                    })
                    this.setState({
                        artworks: projects,
                        filteredResults: projects,
                        statusList: statusList,
                        categoryList: categoryList,
                        isLoaded: true
                    })
                })
            })
    }

    handleSearchInput = (e) => {
        this.setState({
            searchInput: e.target.value,
        }, this.filter);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.setState({
                searchInput: e.target.value,
            }, this.filter);
        }
    }

    handleOnStatusSelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
        this.setState({
            selectedStatus: selectedItem[0]
        }, this.filter)
    }

    handleOnCategorySelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.categoryList.filter(item => item.id === eventKey);

        this.setState({
            selectedCategory: selectedItem[0]
        }, this.filter)
    }

    filter = () => {
        let result = this.state.artworks;
        let searchInput = this.state.searchInput;
        let statusItem = this.state.selectedStatus.name;
        let categoryItem = this.state.selectedCategory;

        if (0 !== searchInput.replace(/ /g, ";").length) {
            result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
        }

        if (categoryItem.id !== "0") {
            result = result.filter(elem => elem.categoryCode === categoryItem.code);
        }

        if (statusItem !== 'ANY') {
            result = result.filter(elem => elem.status.name === statusItem);
        }

        this.setState({
            filteredResults: result
        })
    }

    render() {
        const artworks = this.state.filteredResults;
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <div className="ca-page-padding ca-main-container">
                    <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
                        <div className="d-flex align-items-center">
                            <Image src={myProjects} className="ca-page-title-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                            <span className="ca-page-title">{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}</span>
                        </div>
                        <div>
                            <Link to="/artworks" className="ca-dark-link w-100">
                                <Image className="ca-page-title-icon" src={myPortfolio} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PORTFOLIO")}
                                    title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PORTFOLIO")} />
                            </Link>
                            <Link to="/portfolio-home" className="ca-dark-link">
                                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                                    title={translatedMessage("BUTTON.BACK")} />
                            </Link>
                        </div>
                    </Row>

                    <Row className="d-flex align-items-center">
                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                                onChange={this.handleSearchInput}
                                onKeyDown={this.handleKeyDown} />
                        </Col>

                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedCategory.code)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.categoryList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.id}>
                                                {translatedMessage(element.code)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedStatus.label)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.statusList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                {translatedMessage(element.label)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <div className="card-columns">
                        {artworks.map((element, index) => {
                            if (element.categoryCode === ArtworkService.artworkCategoryEnum().AR_ART) {
                                element.viewLink = `/project/publish/ar-art/${element.id}`;
                            } else if (element.categoryCode === ArtworkService.artworkCategoryEnum().OBJECT) {
                                element.viewLink = `/project/publish/digital-sculpture/${element.id}`;
                            } else if (element.categoryCode === ArtworkService.artworkCategoryEnum().AR_PORTAL) {
                                element.viewLink = `/project/publish/ar-portal/${element.id}`;
                            } else if (element.categoryCode === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE) {
                                element.viewLink = `/project/publish/digital-picture/${element.id}`;
                            }

                            return (
                                <CardComponent
                                    key={index}
                                    cardId={element.id}
                                    cardImage={element.imageURL}
                                    cardTitle={element.name}
                                    cardCategory={translatedMessage("PROJECT.CATEGORY." + element.categoryCode)}
                                    cardStatus={translatedMessage(element.status.label)}
                                    cardLink={element.viewLink}
                                />
                            )
                        })}
                    </div>

                </div >
            )
        }
    }
}

export default MyProjectsPage;