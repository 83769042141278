import React from 'react';

import back from '../../../components/shared/Resources/left-arrow.svg';
import map from '../../../components/shared/Resources/homepage/map.svg';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import { Map, GoogleApiWrapper } from 'google-maps-react';
import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

class ExhibitionMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.id,
      exhibition: {},
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        geofence: null,
        name: ''
      },
      artLocation: [],
      pin: {},
      hasLocation: false,
      isLoaded: false,
      errorMessage: '',
      isError: false,
      translatableErrorMessage: true,
      canEdit: false
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/gallery/exhibition/${this.state.exhibitionId}/can-manage`)
      .then(response => {
        this.setState({
          canEdit: response.data.canManage
        }, this.checkAccessRights)
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async checkAccessRights() {
    var exhibition;
    let hasTicket = false;

    ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${this.props.match.params.id}`)
      .then(async (response) => {
        exhibition = response.data;
        hasTicket = exhibition.ticket ? true : false;

        if (hasTicket || this.state.canEdit) {
          this.setState({
            exhibition: exhibition,
            location: exhibition.locationList[0] ? exhibition.locationList[0] : this.state.location,
            hasLocation: exhibition.locationList[0] ? true : false,
          }, this.loadData)
        } else {
          this.setState({
            isError: true,
            errorMessage: <span>{translatedMessage("TICKET.NO_TICKET") + " " + ApiService.getEntityName().EXHIBITION.toLowerCase() + " - "}
              <Link to={`/exhibition/${this.state.exhibitionId}`}>{translatedMessage("TICKET.GET_TICKET_CTA")}</Link></span>,
            translatableErrorMessage: false,
            isLoaded: true
          })
        }
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  async loadData() {
    var promise = [];
    var pins = [];

    const artLocation = await ApiService.getAuthenticatedInstance().get(`map/exhibitionArtLocation/${this.props.match.params.id}`)
      .then(response => { return response.data })

    promise.push(MapService.getLocationPins()
      .then(response => {
        pins = response;
      }))


    Promise.all(promise).then(() => {
      const pin = pins.filter(item => item.type.name === 'EXHIBITION');

      this.setState({
        artLocation: artLocation,
        pin: pin[0],
        isLoaded: true
      })
    })

  }

  onMapReady = (props, map) => {
    var marker = new props.google.maps.Marker();
    var locationCenter = {
      lat: this.state.location.locationLat,
      lng: this.state.location.locationLng
    };

    const icon = {
      url: this.state.pin.imageUrl,
      scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height)
    }

    if (this.state.hasLocation) {
      map.setCenter(locationCenter);
      marker.setMap(map);
      marker.setPosition(locationCenter);
      marker.setDraggable(false);
      marker.setTitle(this.state.exhibition.name);
      marker.setIcon(icon);

      if (this.state.location.geofence) {
        let polygon = MapService.getInitFencePolygon(props, map, false, false, false);
        let geofenceCoordinates = JSON.parse(this.state.location.geofence)
        polygon.setPath(geofenceCoordinates)
        polygon.setVisible(true)
      }
    }

    this.setState({
      mapProps: props,
      map: map
    })

    this.setMarkers(props, map);
  }

  setMarkers = (props, map) => {
    const locations = this.state.artLocation;
    var markers = [];
    var fences = [];

    locations.forEach((element, index) => {
      const position = { lat: element.locationLat, lng: element.locationLng };

      let icon = {
        url: `${ApiService.getBaseUrl()}/storage/file/${element.pinFileUuid}`,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height)
      }

      var marker = new props.google.maps.Marker({
        position: position,
        map: map,
        title: element.name + " - " + translatedMessage("MAP.GEOFENCE") + ": " + translatedMessage(element.hasGeofence ? "GENERAL.YES" : "GENERAL.NO"),
        icon: icon
      });

      markers.push(marker);

      if (element.hasGeofence && element.geofence) {
        let fillColor = "#3355" + ((index + 1) * 30).toString().substring(0, 2);

        let polygon = MapService.getInitFencePolygon(props, map, false, false, false);
        let geofenceCoordinates = JSON.parse(element.geofence)
        polygon.setPath(geofenceCoordinates)
        polygon.setOptions({ fillColor: fillColor });
        polygon.setVisible(true)

        fences.push(polygon);
      }
    });

    this.setState({
      markers: markers,
      fences: fences
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="h-100">
          <Row className="ca-page-title-button-row align-items-center ca-page-title-padding" style={{ marginTop: "0px", marginBottom: "0px" }}>
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={map} className="ca-page-title-icon" alt={this.state.exhibition.name} />
              <span className="ca-page-title">
                {this.state.exhibition.name}
              </span>
            </div>

            <div className="ca-page-title-button-row-buttons">
              <Button onClick={() => this.props.history.go(-1)} variant="link" className="ca-button-icon">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>
          {this.state.hasLocation &&
            <Row className="mb-0 h-100">
              <Col className="col-12 ca-map-full-page-with-header pl-0 pr-0">
                <div>
                  <Map style={{}} google={this.props.google} zoom={17} initialCenter={MapService.getInitialCenter()} streetViewControl={false}
                    onReady={this.onMapReady}>
                  </Map>
                </div>
              </Col>
            </Row>
          }
          {!this.state.hasLocation &&
            <div className="ca-page-padding ca-main-container">
              <span className="ca-page-subtitle font-weight-bold">
                {translatedMessage("EXHIBITION.LOCATION.NOT_FOUND")}
              </span>
            </div>
          }
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} translateMessage={this.state.translatableErrorMessage} />
      )
    }
  }

}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(ExhibitionMap);