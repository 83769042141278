import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import moment from 'moment';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import backIcon from '../../../components/shared/Resources/left-arrow.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import TableComponent from './Components/AddressTableComponent/AddressTableComponent';
import EditAddressModal from '../../../components/shared/Address/EditAddressModalComponent';
import DeleteAddressModal from '../../../components/shared/Address/DeleteAddressModalComponent';

const newAddress = {
  id: null,
  address: null,
  status: { label: 'COMPANY.ADDRESS.STATUS.ACTIVE', name: 'ACTIVE' },
};

class CompanyAddressPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      addressList: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      showEditModal: false,
      showDeleteModal: false,
      selectedAddressCompanyId: -1,
      deletedCompanyAddressId: -1,
      selectedAddress: newAddress,
    };

    this.editAddress = this.editAddress.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
    .then(response => {
      this.setState({
        company: response.data.company,
        canEdit: true
      }, this.getData)
    })
    .catch(err => {
      console.log(err)
      if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
        errorMessage = err.response.data.message;
      }

      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    });
  }

  async getData() {
    if (this.state.canEdit) {
      var errorMessage = "GENERAL.GET_DATA_ERROR";

      const addressList = await CompanyService.getCompanyAddresses(this.state.company.id)
        .then(response => {
          return response;
        })
        .catch(err => {
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }

          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })

      this.setState({
        addressList: addressList,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  async editAddress(selectedAddressCompanyId) {
    var selectedAddress = newAddress;

    if (selectedAddressCompanyId) {
      selectedAddress = this.state.addressList.filter(item => item.id === selectedAddressCompanyId)[0];
    }

    this.setState({
      selectedAddressCompanyId: selectedAddressCompanyId ? selectedAddressCompanyId : -1,
      selectedAddress: selectedAddress,
      showEditModal: true
    })
  }

  deleteAddress = (deletedCompanyAddressId) => {
    this.setState({
      deletedCompanyAddressId: deletedCompanyAddressId,
      showDeleteModal: true
    })
  }

  async refreshData() {
    let addressList = await CompanyService.getCompanyAddresses(this.state.company.id)
      .then(response => { return response });

    this.setState({
      addressList: addressList,
    })
  }

  validate = (companyAddress) => {
    var response = {
      isError: false,
      message: ""
    };

    return response;
  }

  doSaveAddress(savedAddress) {
    var address = savedAddress.address;
    var zip = savedAddress.zip;
    var city = savedAddress.city;
    var countryId = parseInt(savedAddress.countryId);
    var name = savedAddress.name;

    return new Promise((resolve, reject) => {
      if (name !== "" && address !== "" && city !== "" && countryId.toString() !== '0') {
        if (savedAddress.id === null) {
          ApiService.getAuthenticatedInstance().post(`/addresses`, {
            name: name,
            address: address.trim(),
            country: `/countries/${countryId}`,
            city: city.trim(),
            zip: zip.trim(),
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: Date.now(),
          }).then(resolve)
            .catch(err => reject(err))
        } else {
          ApiService.getAuthenticatedInstance().patch(`/addresses/${savedAddress.id}`, {
            name: name,
            address: address.trim(),
            country: `/countries/${countryId}`,
            city: city.trim(),
            zip: zip.trim(),
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: Date.now(),
          }).then(resolve)
            .catch(err => reject(err))
        }
      } else if (address === "" && city === "" && countryId.toString() === '0' && zip === "") {
        resolve(-1);
      } else {
        reject();
      }
    })
  }

  onHideEdit = (address, status) => {
    if (address) {
      let addressId = address.id ? address.id : null;

      const validation = this.validate(address);
      if (!validation.isError) {
        this.doSaveAddress(address)
          .then(result => {
            if (addressId === null && result && result !== -1) {
              const parts = result.data._links.self.href.split('/');
              addressId = parts[parts.length - 1];
            } else if (result === -1) {
              addressId = null;
            }

            if (addressId) {
              var promise;
              if (this.state.selectedAddress.id !== null) {
                promise = new Promise((resolve, reject) => {
                  ApiService.getAuthenticatedInstance().patch(`/companyAddresses/${this.state.selectedAddress.id}`, {
                    address: `/address/${addressId}`,
                    company: `/company/${this.state.company.id}`,
                    status: status,
                    lastUpdatedBy: ApiService.getCurrentUser().username,
                    lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  })
                    .then(() => resolve())
                    .catch(err => reject(err))
                });
              }
              else {
                promise = new Promise((resolve, reject) => {
                  ApiService.getAuthenticatedInstance().post(`/companyAddresses`, {
                    address: `/address/${addressId}`,
                    company: `/company/${this.state.company.id}`,
                    status: status,
                    lastUpdatedBy: ApiService.getCurrentUser().username,
                    lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  })
                    .then(() => resolve())
                    .catch(err => reject(err))
                });
              }

              promise.then(() => {
                toast.success(translatedMessage("COMPANY.ADDRESS.ADD_SUCCESS"));
                this.setState({
                  showEditModal: false,
                  selectedAddress: newAddress,
                }, () => this.refreshData())
              })
                .catch(err => {
                  console.log("An error has ocurred: " + err);
                  toast.error(translatedMessage("COMPANY.ADDRESS.ADD_ERROR"));
                })
            }
          }).catch(err => {
            if (err && err.response && err.response.status === 500) {
              toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
            } else {
              toast.error(translatedMessage("PROFILE.ERROR_ADDRESS"), { autoClose: 7000, pauseOnHover: true });
            }
          });
      } else {
        toast.error(validation.message);
      }
    } else {
      this.setState({
        showEditModal: false,
        selectedAddress: newAddress,
      })
    }
  }

  onHideDelete = (companyAddressId) => {
    if (companyAddressId && companyAddressId !== -1) {
      var deletedCompanyAddress = this.state.addressList.filter(item => item.id === companyAddressId)[0];
      var addressId = -1;
      
      if (deletedCompanyAddress) {
        addressId = deletedCompanyAddress.address.id;
      }

      ApiService.getAuthenticatedInstance().delete(`/companyAddresses/${companyAddressId}`)
        .then(() => {
          ApiService.getAuthenticatedInstance().delete(`/addresses/${addressId}`)
            .then(() => {
              toast.success(translatedMessage("COMPANY.ADDRESS.DELETE_SUCCESS"))
              this.setState({
                showDeleteModal: false
              }, () => this.refreshData())
            })
            .catch(err => {
              console.log("An error has ocurred: " + err);
              toast.error(translatedMessage("COMPANY.ADDRESS.DELETE_ERROR"));

              this.setState({
                showDeleteModal: false
              })
            })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.ADDRESS.DELETE_ERROR"));

          this.setState({
            showDeleteModal: false
          })
        })
    } else {
      this.setState({
        showDeleteModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <span className="ca-page-title">{translatedMessage("ADDRESS.ADDRESSES")}</span>

            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              {!this.state.canEdit ? "" :
                <Button onClick={() => this.editAddress()} className="ca-dark-link ca-button-icon medium mr-1" variant="link">
                  <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} />
                </Button>
              }

              <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/home`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          {this.state.addressList.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("COMPANY.NO_ADDRESSES")}
                  </span>
                </Col>
              </Row>
            </div>
          }

          {this.state.addressList.length > 0 &&
            <div>
              <TableComponent
                data={this.state.addressList}
                editAddress={this.editAddress}
                deleteAddress={this.deleteAddress}
              />
            </div>
          }

          <EditAddressModal
            show={this.state.showEditModal}
            onHide={this.onHideEdit}
            companyAddress={this.state.selectedAddress}
          />

          <DeleteAddressModal
            show={this.state.showDeleteModal}
            onHide={this.onHideDelete}
            usercompanyid={this.state.deletedCompanyAddressId}
          />
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAddressPage;