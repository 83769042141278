import React from 'react';

import { Card } from 'react-bootstrap';
import { translatedMessage } from '../../../../services/language.service';
import EditExperienceModalComponent from './EditExperienceModalComponent';
import DeleteExperienceModalComponent from './DeleteExperienceModalComponent';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      editModal: false,
      deleteModal: false,
      editModalData: {},
      deleteModalData: {}
    };

    this.onShowOrHideEdit = this.onShowOrHideEdit.bind(this);
    this.onShowOrHideDelete = this.onShowOrHideDelete.bind(this);
  }

  onShowOrHideEdit(experience) {
    if (!this.state.editModal) {
      this.setState({
        editModalData: this.props.data,
        editModal: true,
      });

      return;
    }

    if (this.state.editModal) {
      if (experience && experience.id) {
        this.props.onSave(experience);
      }
      this.setState({
        editModal: false,
      });
    }
  }

  onShowOrHideDelete(experience) {
    if (!this.state.deleteModal) {
      this.setState({
        deleteModalData: this.props.data,
        deleteModal: true,
      });

      return;
    }

    if (this.state.deleteModal) {
      if (experience && experience.id) {
        this.props.onDelete(experience);
      }
      this.setState({
        deleteModal: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Card className="ca-card ca-add-on-card" bg={this.props.variant} text={this.props.variant.toLowerCase() === 'light' ? 'dark' : 'white'}>
          {this.props.showThumbnail &&
            <Card.Img variant="top" src={this.props.thumbnail} />
          }
          <Card.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Card.Title>{this.props.data.name}</Card.Title>
            <Card.Subtitle>{this.props.artworkAuthor}</Card.Subtitle>
            <Card.Text className="ca-muted-text">{translatedMessage("ADD_ON.MAIN_EXPERIENCE")}:&nbsp;
                        {this.props.data.isMain ? translatedMessage("GENERAL.YES") : translatedMessage("GENERAL.NO")}
            </Card.Text>
            <Card.Text className="ca-text-justify">{this.props.data.description}</Card.Text>
            {/* <Card.Text>
              {translatedMessage("ADD_ON.INCLUDED_IN")}&nbsp;
                                      {this.state.data.includedIn.length === 6
                ? translatedMessage("GENERAL.ALL").toLowerCase()
                : this.state.data.includedIn.map((e, index) => {
                  if (index !== this.state.data.includedIn.length - 1) {
                    return e + ", "
                  } else {
                    return e
                  }
                })} {translatedMessage("ARTWORK.PACKAGES").toLowerCase()}
            </Card.Text> */}
          </Card.Body>
          {this.props.showControls &&
            <Card.Footer className="ca-controls-card-footer">
              <div>
                <span className="mr-2" style={{ fontFamily: "FontAwesome", cursor: "pointer" }} title={translatedMessage("GENERAL.EDIT")}
                  onClick={this.onShowOrHideEdit}>&#xf040;</span>
                <span style={{ fontFamily: "FontAwesome", cursor: "pointer" }} title={translatedMessage("GENERAL.DELETE")}
                  onClick={this.onShowOrHideDelete}>&#xf014;</span>
              </div>
            </Card.Footer>
          }
        </Card>

        {this.props.showControls &&
          <div>
            <EditExperienceModalComponent
              show={this.state.editModal}
              onHide={this.onShowOrHideEdit}
              data={this.props.data}
              hasmain={this.props.hasMain.toString()}
            />

            <DeleteExperienceModalComponent
              show={this.state.deleteModal}
              onHide={this.onShowOrHideDelete}
              data={this.state.deleteModalData}
            />
          </div>
        }
      </div>
    )
  }
}

export default CardComponent;
