import React from 'react';

import { Row, Col, Form, Button, InputGroup, Dropdown, Image } from 'react-bootstrap';

import CardComponent from "./Components/CardComponent";
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import addIcon from '../../../components/shared/Resources/icons/add.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';

import ApiService from '../../../services/api.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

const ENTER_KEY = 13;

class VirtualGalleryListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      virtualGalleries: [],
      filteredVirtualGalleries: [],
      statusList: [],
      selectedStatus: { label: "GENERAL_STATUS.ANY", name: "ANY" },
      typeList: [],
      selectedType: { label: "GENERAL_TYPE.ANY", name: "ANY" },
      isLoaded: false,
      canList: false,
      canEdit: false,
      canView: false,
      canCreate: false,
      isError: false,
      errorMessage: ""
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      const canList = hasPermission("VIRTUAL_GALLERY_LIST");
      const canEdit = hasPermission("VIRTUAL_GALLERY_EDIT");
      const canCreate = hasPermission("VIRTUAL_GALLERY_CREATE");

      this.setState({
        canList: canList,
        canEdit: canEdit,
        canCreate: canCreate
      }, () => canList ? this.getData() : this.noPermission())
    });
  }

  noPermission() {
    this.setState({
      isError: true,
      errorMessage: "USER.SECURITY.MISSING_RIGHTS",
      isLoaded: true
    });
  }

  async getData() {
    let statusList = await VirtualGalleryService.getVirtualGalleryStatusList()
      .then(response => { return response });

    statusList.splice(0, 0, { label: "GENERAL_STATUS.ANY", name: "ANY" })

    let typeList = await VirtualGalleryService.getVirtualGalleryTypeList()
      .then(response => { return response });

      typeList.splice(0, 0, { label: "GENERAL_TYPE.ANY", name: "ANY" })

    let virtualGalleries = await VirtualGalleryService.getAllVirtualGalleries()
      .then(response => {
        return response;
      })

    this.setState({
      virtualGalleries: virtualGalleries,
      filteredVirtualGalleries: virtualGalleries,
      statusList: statusList,
      typeList: typeList,
      isLoaded: true
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleStatusSelect = (eventKey) => {
    const selectedItem = this.state.statusList.filter(item => item.name === eventKey);

    this.setState({
      selectedStatus: selectedItem[0]
    }, this.filter)
  }

  handleTypeSelect = (eventKey) => {
    const selectedItem = this.state.typeList.filter(item => item.name === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.virtualGalleries;
    let searchInput = this.state.searchInput;
    let statusItem = this.state.selectedStatus.name;
    let typeItem = this.state.selectedType.name;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.virtualGalleries.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (statusItem !== 'ANY') {
      result = result.filter(elem => elem.status.name === statusItem);
    }  
    
    if (typeItem !== 'ANY') {
      result = result.filter(elem => elem.type.name === typeItem);
    }      

    this.setState({
      filteredVirtualGalleries: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">          
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div className="pr-2 d-flex align-items-center">
              <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
              <div className="ca-page-title">{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}</div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon medium" variant="link" onClick={() => this.props.history.push(`/virtualGalleryEdit`)}>
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 col-md-4 mb-1 pl-0 pr-2">
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder={translatedMessage("SEARCH.BY.NAME")} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedStatus.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.statusList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>            
            <Col className="col-12 col-sm-6 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            {this.state.filteredVirtualGalleries.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0 pr-0">
                  <CardComponent
                    cardImage={element.imageURL}
                    cardTitle={element.name}
                    entity={element}
                    id={0 === element.id ? 0 : element.id}
                    canEdit={this.state.canEdit}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default VirtualGalleryListPage;