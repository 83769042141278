import React, { useState } from "react";

import "./LoginPage.css";
import '../../index.css';

import ApiService from "../../services/api.service";
import { toast } from 'react-toastify';
import calogo from '../../components/shared/Resources/calogo.png'

import { Row, Col } from 'react-bootstrap'

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { translatedMessage } from "../../services/language.service"

function FormLogin(props) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {

        } else {
            const username = event.target.elements.formUsername.value;
            const password = event.target.elements.formPassword.value;

            ApiService.instance.post('/authentication/login', {
                username,
                password,
            }).then(result => {
                ApiService.persistToken(result.data.token);
                toast.success(translatedMessage("LOGIN.SUCCESS"));

                ApiService.getAuthenticatedInstance().get('/user/me')
                    .then(result => {
                        ApiService.setCurrentUser(result.data);
                        ApiService.setCurrentCompany(null);
                        ApiService.setCompanyUserPendingApproval(false);

                        ApiService.getAuthenticatedInstance().get(`/user/myProfile`)
                            .then(result => {
                                ApiService.setCurrentProfile({
                                    ...result.data.profile,
                                });

                                let path = ApiService.getRedirectUrl();
                                if(path) {
                                    props.history.push(path);
                                    localStorage.removeItem('_redirect');
                                } else {
                                    props.history.push('/');    
                                }                                
                            });
                    })
            }).catch(err => {
                // console.log('err', err);

                var errorMessage = "GENERAL.GENERIC_ERROR";
                if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
                    errorMessage = err.response.data.message;
                }                
                toast.error(translatedMessage(errorMessage));
            });
        }

        setValidated(true);
    };

    return (
        <Row className="w-100 justify-content-center">
            <Col className="col-12 col-md-6mx-auto text-center">
                <Card className="login-page-card">
                    <Card.Body className="login-page-card-body">
                        <Card.Title className="login-page-card-title">{translatedMessage("LOGIN.LOGIN")}</Card.Title>
                        <Form className="ca-form" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="formUsername">
                                <Form.Label>{translatedMessage("PROFILE.USERNAME")}</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder={translatedMessage("PROFILE.USERNAME")}
                                />
                                <Form.Control.Feedback type="invalid">{translatedMessage("LOGIN.USERNAME_REQ")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formPassword">
                                <Form.Label>{translatedMessage("GENERAL:PASSWORD")}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder={translatedMessage("GENERAL:PASSWORD")}
                                />
                                <Form.Control.Feedback type="invalid">{translatedMessage("LOGIN.PASSWORD_REQ")}</Form.Control.Feedback>
                            </Form.Group>
                            <div className="login-page-card-submit-button-section">
                                <Button variant="dark" className="ca-button" type="submit">{translatedMessage("LOGIN.LOGIN")}</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
                <a href="https://www.connected.art/club/club.php" target="_blank" rel="noopener noreferrer" className="ca-link-white">
                    {translatedMessage("GENERAL.REGISTER")}
                </a>
                <a className="ca-link-white" href="/reset">{translatedMessage("RESET.FORGOT_PASSWORD")}</a>
            </Col>
        </Row>
    );
}

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: {
                username: '',
                password: '',
            },
            validated: false,
        };

        this.login = this.login.bind(this);
    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [name]: value,
            }
        });
    };

    login(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({ validated: true });


    }

    render() {
        return (
            <div className="d-flex flex-column h-100">
                <div className="login-page-align">
                    <Image className="login-page-logo" src={calogo} />
                    <FormLogin history={this.props.history} />
                </div>
                <div id="footer" className="d-flex flex-column ca-footer">
                    <Row className="w-100 mb-0">
                        <Col className="text-center">
                            <a href="https://www.connected.art/tc/privacy.php" target="_blank" rel="noopener noreferrer">
                                {translatedMessage("TERMS.TOU")}
                            </a>
                            <span> | </span>
                            <a href="https://www.connected.art/tc/privacy.php" target="_blank" rel="noopener noreferrer">
                                {translatedMessage("TERMS.PRIVACY")}
                            </a>
                            <span> | </span>
                            <a href="https://www.connected.art/tc/tos.php" target="_blank" rel="noopener noreferrer">
                                {translatedMessage("TERMS.TOS")}
                            </a>
                        </Col>
                        <Col className="text-center">
                            <i className="fa fa-copyright"></i> {translatedMessage("GENERAL.ALL_RIGHTS_RESERVED")}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default LoginPage;
