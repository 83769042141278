import React from 'react';

import backArrow from '../../../components/shared/Resources/left-arrow.svg';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import moment from 'moment';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import TableComponent from './Components/ArtistTableComponent/ArtistTableComponent';
import EditModal from './Components/EditArtistModalComponent';
import DeleteModal from './Components/DeleteArtistModalComponent';

class CompanyAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      userList: [],
      allUsers: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      showEditModal: false,
      showDeleteModal: false,
      selectedArtistCompanyId: -1,
      deletedArtistCompanyId: -1,
      selectedArtist: null
    };

    this.editUser = this.editUser.bind(this);
    this.approveUser = this.approveUser.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        this.setState({
          company: response.data.company,
          isNew: false
        }, this.getData)
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async getData() {
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    const userList = await CompanyService.getCompanyArtists(this.state.company.id)
      .then(response => {
        return response;
      })
      .catch(err => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })

    this.setState({
      userList: userList,
      isLoaded: true
    })
  }

  async editUser(selectedArtistCompanyId) {
    var selectedArtist = this.state.userList.filter(item => item.id === selectedArtistCompanyId)[0];

    this.setState({
      selectedArtistCompanyId: selectedArtistCompanyId,
      selectedArtist: selectedArtist,
      showEditModal: true
    })
  }

  deleteUSer = (deletedArtistCompanyId) => {
    this.setState({
      deletedArtistCompanyId: deletedArtistCompanyId,
      showDeleteModal: true
    })
  }

  async approveUser(selectedArtistCompanyId, isApproved) {
    let errorMessage = isApproved ? "COMPANY.ARTIST.ADD_ERROR" : "COMPANY.ARTIST.JOIN.REFUSE.ERROR";
    var selectedArtist = this.state.userList.filter(item => item.id === selectedArtistCompanyId)[0];

    ApiService.getAuthenticatedInstance().post(`/company/${this.state.company.id}/artist/${selectedArtist.artist.id}/request-response/${isApproved}`)
      .then(() => {
        toast.success(translatedMessage(isApproved ? "COMPANY.ARTIST.ADD_SUCCESS" : "COMPANY.ARTIST.JOIN.REFUSE.SUCCESS"))
        this.refreshData();
      })
      .catch(err => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 406)) {
          errorMessage = err.response.data.message;
        }
        toast.error(translatedMessage(errorMessage))
      })
  }

  async refreshData() {
    let userList = await CompanyService.getCompanyArtists(this.state.company.id)
      .then(response => { return response });

    this.setState({
      userList: userList,
    })
  }

  onHideEdit = (userId, status) => {
    if (userId && status) {
      var promise;
      promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/companyArtists/${this.state.selectedArtistCompanyId}`, {
          status: status,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then(() => resolve())
          .catch(err => reject(err))
      });

      promise.then(() => {
        toast.success(translatedMessage("COMPANY.ARTIST.SAVE_SUCCESS"))
        this.setState({
          showEditModal: false
        }, () => this.refreshData())
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.ARTIST.SAVE_ERROR"));

          this.setState({
            showEditModal: false
          })
        })
    } else {
      this.setState({
        showEditModal: false
      })
    }
  }

  onHideDelete = (artistCompanyId) => {
    if (artistCompanyId && artistCompanyId !== -1) {
      ApiService.getAuthenticatedInstance().delete(`/companyArtists/${artistCompanyId}`)
        .then(result => {
          toast.success(translatedMessage("COMPANY.ARTIST.DELETE_SUCCESS"))
          this.setState({
            showDeleteModal: false
          }, () => this.refreshData())
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.ARTIST.DELETE_ERROR"));

          this.setState({
            showDeleteModal: false
          })
        })
    } else {
      this.setState({
        showDeleteModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <span className="ca-page-title">{translatedMessage("USER.ARTISTS")}</span>

            <div>
              <Button className="ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/home`)}>{translatedMessage("BUTTON.BACK")}
                <Image className="ca-page-title-icon ml-2 mr-0" src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          {this.state.userList.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <div className="ca-page-subtitle">{translatedMessage("COMPANY.NO_ARTISTS")}</div>
                  <div>{translatedMessage("COMPANY.NO_ARTISTS_DETAIL")}</div>
                </Col>
              </Row>
            </div>
          }

          {this.state.userList.length > 0 &&
            <div>
              <TableComponent
                data={this.state.userList}
                editUser={this.editUser}
                deleteUSer={this.deleteUSer}
                approveUser={this.approveUser}
              />
            </div>
          }

          <EditModal
            show={this.state.showEditModal}
            onHide={this.onHideEdit}
            selectedArtist={this.state.selectedArtist}
          />

          <DeleteModal
            show={this.state.showDeleteModal}
            onHide={this.onHideDelete}
            usercompanyid={this.state.deletedArtistCompanyId}
          />
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAdminEdit;