import request from "superagent";
import ApiService from "./api.service";
import LanguageService from "./language.service";

const FileService = {
    getImageContentType: () => {
        return [
            'image/jpeg',
            'image/png',
            'image/bmp',
            'image/x-sony-arw',
            'image/x-canon-cr2',
            'image/x-canon-crw',
            'image/x-kodak-dcr',
            'image/x-adobe-dng',
            'image/x-epson-erf',
            'image/x-kodak-k25',
            'image/x-kodak-kdc',
            'image/x-minolta-mrw',
            'image/x-nikon-nef',
            'image/x-olympus-orf',
            'image/x-pentax-pef',
            'image/x-fuji-raf',
            'image/x-panasonic-raw',
            'image/x-sony-sr2',
            'image/x-sony-srf',
            'image/x-sigma-x3f',
        ];
    },

    getImageContentTypeShort: () => {
        return [
            'image/jpeg',
            'image/png',
            'image/bmp',
        ];
    },

    getZipContentType: () => {
        return ['application/zip', 'application/x-compressed', 'application/x-zip-compressed', 'multipart/x-zip'];
    },

    getVideoContentTypes: () => {
        return ['video/mp4', 'video/avi', 'video/quicktime', 'video/3gpp', 'video/x-msvideo'];
    },

    getModelContentType: () => {
        return ['model/gltf-binary', 'application/octet-stream'];
    },

    getObjectContentType: () => {
        return ['model/gltf-binary'];
    },    

    getFileUsage: () => {
        return {
            PROFILE_PIC: "PROFILE_PIC",
            THUMBNAIL: "THUMBNAIL",
            TRIGGER: "TRIGGER",
            CREATE_DESTROY: "CREATE_DESTROY",
            CREATION_PROC: "CREATION_PROC",
            ART_DESC: "ART_DESC",
            PROJECT: "PROJECT",
            ARTWORK: "ARTWORK",
            AR: "AR",
            GALLERY: "GALLERY",
            EXHIBITION: "EXHIBITION",
            SIGNATURE: "SIGNATURE",
            VIDEO: "VIDEO",
            ARTWORK_PRINT: "ARTWORK_PRINT",
            ARTWORK_PRINT_PREVIEW: "ARTWORK_PRINT_PREVIEW",
            BUYER_PRINT: "BUYER_PRINT",
            AR_PORTAL: "AR_PORTAL",
            AR_PORTAL_MAP: "AR_PORTAL_MAP",
            AR_EXPERIENCE_ART: "AR_EXPERIENCE_ART",
            OBJECT_MODEL: "OBJECT_MODEL",
            PRINT_FILE: "PRINT_FILE",
            LOGO: "LOGO",
            CHALLENGE: "CHALLENGE",
            APPLICATION_ART: "APPLICATION_ART",
            PARK: "PARK",
        };
    },

    getResourceType: () => {
        return {
            IMAGE: "IMAGE",
            VIDEO: "VIDEO",
            AR_VIDEO: "AR_VIDEO",
            UNITY_SCENE: "UNITY_SCENE",
            OBJECT: "OBJECT",
            OBJECT_MODEL: "OBJECT_MODEL", // used for display purposes -> object files in the UI
            ARCHIVE: "ARCHIVE",
        };
    },

    getVideoUrlType: () => {
        return {
            YOUTUBE: "YOUTUBE",
            VIMEO: "VIMEO"
        };
    },

    getContentResourceTypeList: () => {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`enum/contentResourceTypeList`)
                .then(response => {
                    var typesList = response.data;
                    typesList.sort((a, b) => {
                        var result;
                        a.name <= b.name ? result = -1 : result = 1
                        return result;
                    });

                    resolve(typesList)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    checkFile(file, hasMultipleFiles, fileTypes, maxSize) {
        if (hasMultipleFiles) {
            if (!fileTypes.includes(file.type)) {
                return [false, window.$translate(LanguageService.getCurrentLanguage(), "DROPZONE.ERROR_FILE_TYPE")];
            }
            if (file.size > maxSize * 1024 * 1024) {
                return [false, window.$translate(LanguageService.getCurrentLanguage(), "DROPZONE.ERROR_FILE_SIZE")];
            }
        } else {
            if (!file.length) {
            }
            if (!fileTypes.includes(file[0].type)) {
                if (fileTypes.includes("model/gltf-binary")) {
                    if (!file[0].name.match(/\.(glb)$/)) {
                        return [false, window.$translate(LanguageService.getCurrentLanguage(), "DROPZONE.ERROR_FILE_TYPE")];
                    }
                } else {
                    return [false, window.$translate(LanguageService.getCurrentLanguage(), "DROPZONE.ERROR_FILE_TYPE")];
                }
            }
            if (file[0].size > maxSize * 1024 * 1024) {
                return [false, window.$translate(LanguageService.getCurrentLanguage(), "DROPZONE.ERROR_FILE_SIZE")];
            }
        }

        return [true, ""];
    },

    upload(files, acceptedFileTypes) {
        return new Promise((resolve, reject) => {
            const req = request.post(`${ApiService.getBaseUrl()}/storage/upload`)
                .set({
                    'Authorization': `Bearer ${ApiService.getToken()}`,
                })
                .accept('application/json');

            let isError = false;

            files.forEach(file => {
                if (!acceptedFileTypes.includes(file.type)) {
                    if (acceptedFileTypes.includes("model/gltf-binary")) {
                        if (!file.name.match(/\.(glb)$/)) {
                            isError = true;
                        }
                    } else {
                        isError = true;
                    }
                }

                if (!isError) {
                    req.attach("file", file);
                }
            });

            if (isError) {
                reject(window.$translate(LanguageService.getCurrentLanguage(), "FILE.WRONG_TYPE"));
                return;
            }

            req
                .then(resolve)
                .catch(err => reject(err));
        });
    },

    uploadArtworkFile(files, acceptedFileTypes, storageItemId) {
        return new Promise((resolve, reject) => {
            const req = request.post(`${ApiService.getBaseUrl()}/storage/uploadArtworkFile/${storageItemId}`)
                .set({
                    'Authorization': `Bearer ${ApiService.getToken()}`,
                })
                .accept('application/json');

            let isError = false;

            files.forEach(file => {
                if (!acceptedFileTypes.includes(file.type)) {
                    isError = true;
                } else {
                    req.attach("file", file);
                }
            });

            if (isError) {
                reject(window.$translate(LanguageService.getCurrentLanguage(), "FILE.WRONG_TYPE"));
                return;
            }

            req
                .then(resolve)
                .catch(err => reject(err));
        });
    },

    uploadArArtFile(files, acceptedFileTypes, storageItemId) {
        return new Promise((resolve, reject) => {
            const req = request.post(`${ApiService.getBaseUrl()}/storage/uploadArArtFile/${storageItemId}`)
                .set({
                    'Authorization': `Bearer ${ApiService.getToken()}`,
                })
                .accept('application/json');

            let isError = false;

            files.forEach(file => {
                if (!acceptedFileTypes.includes(file.type)) {
                    isError = true;
                } else {
                    req.attach("file", file);
                }
            });

            if (isError) {
                reject(window.$translate(LanguageService.getCurrentLanguage(), "FILE.WRONG_TYPE"));
                return;
            }

            req
                .then(resolve)
                .catch(err => reject(err));
        });
    },

    uploadObjectArtFile(files, acceptedFileTypes, fileUsage, storageItemId, resourceType, mobilePlatform) {
        let endPoint = 
            fileUsage === FileService.getFileUsage().ARTWORK 
                ? "uploadSculptureArtworkFile"
                : fileUsage === FileService.getFileUsage().AR
                    ? "uploadSculptureArExperienceFile"
                    : "uploadPortalFile";

        return new Promise((resolve, reject) => {
            const req = request.post(`${ApiService.getBaseUrl()}/storage/${endPoint}/${storageItemId}/${resourceType}/${mobilePlatform}`)
                .set({
                    'Authorization': `Bearer ${ApiService.getToken()}`,
                })
                .accept('application/json');

            let isError = false;

            files.forEach(file => {
                if (!acceptedFileTypes.includes(file.type)) {
                    if (acceptedFileTypes.includes("model/gltf-binary")) {
                        if (!file.name.match(/\.(glb)$/)) {
                            isError = true;
                        }
                    } else {
                        isError = true;
                    }
                }

                if (!isError) {
                    req.attach("file", file);
                }
            });

            if (isError) {
                reject(window.$translate(LanguageService.getCurrentLanguage(), "FILE.WRONG_TYPE"));
                return;
            }

            req
                .then(resolve)
                .catch(err => reject(err));
        });
    }, 
    
    uploadArPortalArtworkFile(files, acceptedFileTypes, storageItemId, resourceType, mobilePlatform) {
        return new Promise((resolve, reject) => {
            const req = request.post(`${ApiService.getBaseUrl()}/storage/uploadPortalFile/${storageItemId}/${resourceType}/${mobilePlatform}`)
                .set({
                    'Authorization': `Bearer ${ApiService.getToken()}`,
                })
                .accept('application/json');

            let isError = false;

            files.forEach(file => {
                if (!acceptedFileTypes.includes(file.type)) {
                    if (acceptedFileTypes.includes("model/gltf-binary")) {
                        if (!file.name.match(/\.(glb)$/)) {
                            isError = true;
                        }
                    } else {
                        isError = true;
                    }
                }

                if (!isError) {
                    req.attach("file", file);
                }
            });

            if (isError) {
                reject(window.$translate(LanguageService.getCurrentLanguage(), "FILE.WRONG_TYPE"));
                return;
            }

            req
                .then(resolve)
                .catch(err => reject(err));
        });
    },     

    uploadArtworkPrintFile(files, acceptedFileTypes, storageItemId, widthCm, heightCm) {
        let width = widthCm ? Number(widthCm) : null;
        let height = heightCm ? Number(heightCm) : null;

        return new Promise((resolve, reject) => {
            const req = request.post(`${ApiService.getBaseUrl()}/storage/uploadArtworkPrintFile/${storageItemId}/${width}/${height}`)
                .set({
                    'Authorization': `Bearer ${ApiService.getToken()}`,
                })
                .accept('application/json');

            let isError = false;

            files.forEach(file => {
                if (!acceptedFileTypes.includes(file.type)) {
                    isError = true;
                } else {
                    req.attach("file", file);
                    // req.attach("storageItemId", storageItemId);
                }
            });

            if (isError) {
                reject(window.$translate(LanguageService.getCurrentLanguage(), "FILE.WRONG_TYPE"));
                return;
            }

            req
                .then(resolve)
                .catch(err => reject(err));
        });
    },

    linkPhotoToEntity(entityId, entityUpdateAPILink, fileId, usage, uuid, fieldName) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().patch(`/${entityUpdateAPILink}/${entityId}`, {
                [fieldName]: `/files/${fileId}`,
                lastUpdatedBy: ApiService.getCurrentUser().username
            })
                .then(() => {
                    return ApiService.getAuthenticatedInstance().patch(`/files/${fileId}`, { usage })
                })
                .then(resolve)
                .catch(err => {
                    this.deleteFile(uuid)
                        .catch((deleteErr) => console.log("An error has ocurred: " + deleteErr + ": " + deleteErr.response.data));

                    reject(err);
                });
        });
    },

    linkTriggerToEntity(entityId, entityUpdateAPILink, fileId, usage, uuid) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().patch(`/${entityUpdateAPILink}/${entityId}`, {
                triggerFile: `/files/${fileId}`,
                lastUpdatedBy: ApiService.getCurrentUser().username
            })
                .then(() => {
                    return ApiService.getAuthenticatedInstance().patch(`/files/${fileId}`, { usage })
                })
                .then(resolve)
                .catch(err => {
                    this.deleteFile(uuid)
                        .catch((deleteErr) => console.log("An error has ocurred: " + deleteErr + ": " + deleteErr.response.data));

                    reject(err);
                });
        });
    },

    setFileUsage(fileId, usage, uuid) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().patch(`/files/${fileId}`, { usage })
                .then(resolve)
                .catch(err => {
                    this.deleteFile(uuid)
                        .catch((deleteErr) => console.log("An error has ocurred: " + deleteErr + ": " + deleteErr.response.data));

                    reject(err);
                });
        });
    },

    linkToStorageItem(storageId, fileId, usage, uuid, resourceType = null, mobilePlatform = null, widthCm, heightCm) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/storageItemFiles', {
                file: `/files/${fileId}`,
                storageItem: `/storageItems/${storageId}`,
                lastUpdatedBy: ApiService.getCurrentUser().username
            })
                .then(() => {
                    return ApiService.getAuthenticatedInstance().patch(`/storage/file/save-details/${fileId}`, {
                        usage: usage,
                        widthCm: widthCm ? Number(widthCm) : null,
                        heightCm: heightCm ? Number(heightCm) : null,
                        resourceType: resourceType,
                        mobilePlatform: mobilePlatform
                    })
                })
                .then(response => {
                    resolve(response.data.file);
                })
                .catch(err => {
                    this.deleteFile(uuid)
                        .catch((deleteErr) => console.log("An error has ocurred: " + deleteErr + ": " + deleteErr.response.data));

                    reject(err);
                });
        });
    },

    deleteFromStorageItem(storageId, fileId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get('/storageItemFiles/search/findByStorageItemAndFile', {
                    params: {
                        storageItem: `/storageItems/${storageId}`,
                        file: `/files/${fileId}`,
                    },
                })
                .then(response => {
                    const existingFile = response.data._embedded.storageItemFiles[0];
                    if (existingFile) {
                        const parts = existingFile._links.self.href.split('/');
                        const storageFileItemId = parts[parts.length - 1];

                        ApiService.getAuthenticatedInstance()
                            .delete(`/storageItemFiles/${storageFileItemId}`)
                            .then(resolve);
                    }
                })
        })
    },

    deleteFile(fileUuid) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/storage/deleteFile/${fileUuid}`)
                .then(resolve)
                .catch(err => { reject(err) });
        })
    },

    deleteArtworkDefaultFiles(storageItemId, newArtworkUuid) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/storage/deleteArtworkDefaultFiles/`, {
                    params: {
                        storageItemId: storageItemId,
                        newArtworkUuid: newArtworkUuid,
                    },
                })
                .then(resolve)
                .catch(err => { reject(err) });
        })
    },

    deletePreviousFile(deletedFile, currentFileUuid) {
        return new Promise((resolve, reject) => {
            this.deleteFile(deletedFile.uuid)
                .then(resolve)
                .catch(err => {
                    this.deleteFile(currentFileUuid)
                        .catch((deleteErr) => console.log("An error has ocurred: " + deleteErr + ": " + deleteErr.response.data));

                    reject(err)
                });
        })
    },

    getStorageItemFiles(storageItemId, entityName) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`storage/storageItem/files/${storageItemId}/${entityName}`)
                .then(response => {
                    let storageItemFiles = response.data.fileList

                    storageItemFiles.map((element) => {
                        if (element.uuid) {
                            return element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.uuid}`;
                        } else {
                            return element.imageURL = null;
                        }
                    });

                    storageItemFiles.sort((a, b) => {
                        let result = 0;
                        a.id <= b.id ? result = 1 : result = -1
                        return result;
                    });
                    resolve(storageItemFiles)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getVideoUrls(entityId, entityName) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`storage/entityVideoUrl/list?entityId=${entityId}&entityName=${entityName}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteVideoUrl(id) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/entityVideoUrls/${id}`)
                .then(resolve)
                .catch(err => { reject(err) });
        })
    },

    handleFileForCarousel(file) {
        let responseFile = {};
        responseFile.file = file;
        responseFile.original = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
        responseFile.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
        responseFile.storageItemFileId = file.storageItemFileId;
        responseFile.type = FileService.getResourceType().IMAGE;

        return responseFile;
    },

    handleFilesForCarousel(files, usage) {
        var responseFiles = [];

        files.filter(file => file.usage === usage)
            .map((file, index) => {
                let responseFile = {};
                responseFile.file = file;
                responseFile.original = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
                responseFile.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
                responseFile.storageItemFileId = file.storageItemFileId;
                responseFile.type = FileService.getResourceType().IMAGE;

                if (usage === FileService.getFileUsage().THUMBNAIL) {
                    responseFile.sortOrder = file.defaultThumbnail ? 0 : index + 1;
                }

                responseFiles.push(responseFile);
                return file;
            });

        return responseFiles;
    }
};

export default FileService;
