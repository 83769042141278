import React from 'react';
import '../Styles/ProjectPublishPage.css';

import card1 from '../../../components/shared/Resources/calogo-black.svg';
import { Row, Col, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap/'
import { Link } from 'react-router-dom';


import ApiService from '../../../services/api.service';
import FileService from "../../../services/file.service";
import { translatedMessage } from '../../../services/language.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import { toast } from "react-toastify";
import moment from 'moment';

import arArtIcon from '../../../components/shared/Resources/arArtWhite.png';
import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';

import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";
import ExperienceCardComponent from "./Components/ExperienceCardComponent";
import ArtworkService from '../../../services/artwork.service';

class ArArtPublishExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      artwork: {},
      artworkImage: [],
      artworkImageUrl: '',
      extendedExperiencesData: [],
      experienceIndex: 0,
      isLoaded: false,
      dropzoneEnabled: true,
      displayImg: false,
      experience: {},
      printImageUrl: '',
      isError: false,
      errorMessage: '',
      onlyView: false
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this.populateData();
  }

  populateData() {
    ApiService.getAuthenticatedInstance().get(`/artwork/existing/${this.props.match.params.id}`)
      .then(result => {
        this.setState({
          artwork: {
            ...result.data,
            storageItem: {
              id: result.data.storageItemId
            }
          },
        }, () => {
          ApiService.getAuthenticatedInstance()
            .get(`/storage/storageItem/files/${this.state.artwork.id}/${ApiService.getEntityName().ARTWORK}`)
            .then(results => {
              const artworkFiles = results.data.fileList.filter(file => file.usage === FileService.getFileUsage().ARTWORK);
              const imageFile = results.data.fileList.filter(file => file.usage === FileService.getFileUsage().THUMBNAIL && file.defaultThumbnail);
              this.setState({
                artworkImage: artworkFiles.length > 0 ? artworkFiles[0] : {},
                artworkImageUrl: imageFile.length > 0 ? ApiService.getFileByUuid(imageFile[0].uuid) + '?' + Date.now() : '',
                displayImg: imageFile.length > 0 ? true : false,
                onlyView: this.state.artwork.status.name === ArtworkService.artworkStatusEnum().DRAFT ||
                  this.state.artwork.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
              }, this.getAddOn);
            });
        })
      })
      .catch(err => {
        var errorMessage = "PROJECT.GENERAL_ERROR";
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  getAddOn = () => {
    ApiService.getAuthenticatedInstance().get(`/artwork/${this.state.artwork.id}/addOns`)
      .then(response => {
        let experiences = response.data.experiences
        if (experiences.length > 0) {
          var hasMain = false;
          experiences = experiences.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1;
            return result
          })
          experiences.forEach(item => {
            if (item.isMain) { hasMain = true }
          })
          this.setState({
            extendedExperiencesData: experiences,
            hasMain: hasMain,
            isLoaded: true
          })
        } else {
          this.setState({ isLoaded: true })
        }
      })
      .catch(err => {
        let errorMessage = "GENERAL.SAVE_ERROR";

        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });

        console.log("An error has ocurred: " + err);
      });
  }

  onUploadArtworkFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          displayImg: !this.state.displayImg,
          artworkImage: uploadedFile,
          artworkImageUrl: ApiService.getThumbnailByUrl(this.state.artwork.id) + '?' + Date.now(),
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        artworkImage: uploadedFile,
      });
    }
  }

  onUploadFile = (dropzoneEnabled) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
        }, this.getAddOn)
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
      });
    }
  }

  saveExperience = (experience) => {
    ApiService.getAuthenticatedInstance().patch(`/addOns/${experience.id}`, {
      isMain: experience.isMain,
      name: experience.name,
      description: experience.description,
      lastUpdatedBy: ApiService.getCurrentUser().username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
      .then(result => {
        let experienceList = this.state.extendedExperiencesData;
        var hasMain = false;
        experienceList.map((element, index) => {
          hasMain = element.isMain;
          if (experience.id === element.id) {
            if (element.isMain !== experience.isMain) {
              hasMain = experience.isMain
            }
            element.name = experience.name;
            element.description = experience.description;
            element.isMain = experience.isMain;
          }
          return element;
        })
        this.setState({
          extendedExperiencesData: experienceList,
          hasMain: hasMain
        });

        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      })
  }

  deleteExperience = (experience) => {
    let experienceList = this.state.extendedExperiencesData.filter(item => item.id !== experience.id);
    this.setState({
      extendedExperiencesData: experienceList
    })
    ApiService.getAuthenticatedInstance().post(`/artwork/deleteAddOn/${experience.id}`)
      .then(() => {
        toast.success(translatedMessage("ADD_ON.DELETE_SUCCESS"));
      })
      .catch(err => {
        let errorMessage = "GENERAL.SAVE_ERROR";

        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }

        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage(errorMessage));
      });
  }

  handlaNext = () => {
    if (this.state.hasMain) {
      this.props.history.push(`/project/publish/ar-art-price-package/${this.props.match.params.id}`)
    } else {
      toast.error(translatedMessage("ADD_ON.NO_MAIN_WARNING"), { autoClose: 7000 });
    }
  }

  changeImgDropzone = () => {
    this.setState({ displayImg: !this.state.displayImg })
  }

  render() {
    let thumbnail = card1;
    let img_class = this.state.displayImg || this.state.onlyView ? "ca-form-image-container ca-left" : "ca-form-image-container-hide";
    let dropzone_class = !this.state.displayImg && !this.state.onlyView ? "ca-dropzone-show ca-left w-100" : "ca-dropzone-hide";

    if (this.state.artworkImageUrl !== '') {
      thumbnail = this.state.artworkImageUrl;
    }

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        const homeUlr = this.state.artwork.companyId ? `/my-company/${this.state.artwork.companyId}/portfolio` : `/projects`;
        
        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={arArtIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_AR_ART")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("ARTWORK.FILES")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={homeUlr} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>                  
                </div>
                <div className="ca-page-padding ca-main-container">
                  <Row>
                    <Col className="col-12 col-md-6 col-lg-4 mb-5">
                      <div className="ca-publish-section-container">
                        <div className="ca-publish-section-title">
                          {translatedMessage("ARTWORK.ARTWORK")}
                        </div>
                        <div className="ca-publish-section-subtitle">
                          {translatedMessage("PROJECT.UPLOAD_ARTWORK")}
                          <OverlayTrigger
                            key='artInfo'
                            placement='bottom'
                            overlay={
                              <Tooltip id={`tooltip-artInfo`} className="ca-tooltip">
                                {translatedMessage("ARTWORK.UPLOAD_INFO")}
                              </Tooltip>
                            }
                          >
                            <span className="ca-question-icon"></span>
                          </OverlayTrigger>
                        </div>

                        <div className={img_class} onClick={this.changeImgDropzone.bind(this)}>
                          <Image src={this.state.artworkImageUrl} className="ml-0" />
                          {!this.state.onlyView &&
                            <div className="ca-form-image-change-details">
                              <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf030;</div>
                              <div>{translatedMessage("GENERAL.ARTWORK_IMAGE.TOOLTIP")}</div>
                            </div>
                          }
                        </div>
                        <div className={dropzone_class}>
                          <DropzoneComponent
                            componentId="artworkImage"
                            fileUsage={FileService.getFileUsage().ARTWORK}
                            fileType={FileService.getResourceType().IMAGE}
                            deletePrevious={true}
                            deletedImage={this.state.artworkImage}
                            deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_ARTWORK"
                            fileFormatsDesc="DROPZONE.FILE_TYPES.ARTWORK"
                            maxSize={100}
                            maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                            multipleFiles={false}
                            multipleFilesDesc=""
                            entity={this.state.artwork}
                            storageitemlinked={true}
                            onupload={this.onUploadArtworkFile}
                          />
                          {this.state.artworkImageUrl !== '' &&
                            <div style={{ textAlign: "center" }}>
                              <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeImgDropzone.bind(this)}>&#xf021;</div>
                            </div>
                          }
                        </div>
                      </div>
                    </Col>

                    {!this.state.onlyView &&
                      <Col className="col-12 col-md-6 col-lg-4 mb-5">
                        <div className="ca-publish-section-container">
                          <div className="ca-publish-section-title">
                            {translatedMessage("ADD_ON.EXPERIENCE")}
                          </div>
                          <div className="ca-publish-section-subtitle">
                            {translatedMessage("ADD_ON.UPLOAD_EXPERIENCES")}
                          </div>

                          <div className="ca-dropzone-show ca-left w-100">
                            <DropzoneComponent
                              componentId="experiences"
                              fileUsage={FileService.getFileUsage().AR}
                              fileType={FileService.getResourceType().AR_VIDEO}
                              resourceType={FileService.getResourceType().AR_VIDEO}
                              fileTypeCount={this.state.extendedExperiencesData.length}
                              deletePrevious={false}
                              deletedImage={null}
                              deletePreviousDesc=""
                              fileFormatsDesc="DROPZONE.FILE_TYPES.ADD_ON"
                              maxSize={100}
                              maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                              multipleFiles={true}
                              multipleFilesDesc="DROPZONE.MULTIPLE_ADD_ON"
                              entity={this.state.artwork}
                              storageitemlinked={true}
                              onupload={this.onUploadFile}
                            />
                          </div>
                        </div>
                      </Col>
                    }

                    <Col className="col-12 col-lg-4">
                      <div className="ca-publish-section-container">
                        <span className="ca-publish-section-title">{translatedMessage("PROJECT.ADD_ON")}</span>
                        <span className="ca-publish-section-subtitle">{translatedMessage("ADD_ON.MAIN_EXPERIENCE_TOOLTIP")}</span>
                      </div>
                      <Row>
                        {this.state.extendedExperiencesData.map((element, index) => {
                          return (
                            <Col key={index} className="col-12 col-md-6 col-lg-12 pl-0">
                              <ExperienceCardComponent
                                data={element}
                                thumbnail={thumbnail}
                                onSave={this.saveExperience}
                                onDelete={this.deleteExperience}
                                hasMain={this.state.hasMain}
                                showThumbnail={false}
                                showControls={this.state.onlyView ? false : true}
                                variant="light"
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className={this.state.dropzoneEnabled ? "ca-button-icon mr-1" : "ca-button-icon mr-1 disabled"} variant="link"
                    onClick={() => this.props.history.push(`/project/publish/ar-art/${this.props.match.params.id}`)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>{translatedMessage("BUTTON.NEXT")}</span>
                  <Button className={this.state.dropzoneEnabled ? "ca-button-icon" : "ca-button-icon disabled"} variant="link" onClick={this.handlaNext}>
                    <Image src={nextArrow} alt={translatedMessage("BUTTON.NEXT")} title={translatedMessage("BUTTON.NEXT")} />
                  </Button>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }
}

export default ArArtPublishExperience;