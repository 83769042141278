import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translatedMessage } from '../../../services/language.service';

import portfolioIcon from '../Resources/icons/portfolio.svg';
import mediaIcon from '../Resources/icons/media.svg';

import DisplayVideoComponent from '../DisplayVideoComponent/DisplayVideoComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

class ProfileViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      isError: false
    };
  }

  componentDidMount() { }


  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <div className="d-flex flex-sm-row flex-column" style={{ marginBottom: "2rem" }}>
            <div className="ca-profile-pic-container view d-flex align-items-center justify-content-center">
              <img
                alt="profile pictire"
                src={this.props.profile.profilePicture}
                className="d-inline-block align-center profile-pic ca-profile-photo"
              />
            </div>

            <div className="w-100 d-flex align-content-center flex-wrap ca-profile-detail-container">
              <div className='w-100'>
                <span className="ca-profile-name">{this.props.profile.displayName}</span>
              </div>
              {this.props.activityFields &&
                <div className="w-100">
                  {this.props.activityFields}
                </div>
              }
              <div className="ca-profile-membersince w-100">
                {translatedMessage("PROFILE.MEMBER_SINCE")} {this.props.profile.profileCreatedOn}
              </div>
            </div>
          </div>

          <div className='ca-profile-social-container'>
            {this.props.profile.patreonUri
              ? <a href={this.props.profile.patreonUri} target="_blank" rel="noopener noreferrer" className="ca-dark-link mr-3">
                <FontAwesomeIcon icon={["fab", "patreon"]} />
              </a>
              : ""
            }
            {this.props.profile.facebookUri
              ? <a href={this.props.profile.facebookUri} target="_blank" rel="noopener noreferrer" className="ca-dark-link mr-3">
                <FontAwesomeIcon icon={["fab", "facebook-square"]} />
              </a>
              : ""
            }
            {this.props.profile.linkedinUri
              ? <a href={this.props.profile.linkedinUri} target="_blank" rel="noopener noreferrer" className="ca-dark-link mr-3">
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </a>
              : ""
            }
            {this.props.profile.instagramUri
              ? <a href={this.props.profile.instagramUri} target="_blank" rel="noopener noreferrer" className="ca-dark-link mr-3">
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
              : ""
            }
            {this.props.profile.pinterestUri
              ? <a href={this.props.profile.pinterestUri} target="_blank" rel="noopener noreferrer" className="ca-dark-link mr-3">
                <FontAwesomeIcon icon={["fab", "pinterest-square"]} />
              </a>
              : ""
            }
            {this.props.profile.twitterUri
              ? <a href={this.props.profile.twitterUri} target="_blank" rel="noopener noreferrer" className="ca-dark-link">
                <FontAwesomeIcon icon={["fab", "twitter-square"]} />
              </a>
              : ""
            }
          </div>

          <Row className='align-items-top'>
            <Col className={"pl-0 pr-0 col-12".concat(this.props.profile.whoAmIVideoUri ? " col-md-6 col-lg-11" : "")}>
              {this.props.profile.about &&
                <span>
                  <div dangerouslySetInnerHTML={{ __html: this.props.profile.about }} className="ca-description-value" />
                </span>
              }
            </Col>

            {this.props.profile.whoAmIVideoUri &&
              <Col className="col-12 col-md-6 col-lg-1">
                <div className="ca-profile-video">
                  <DisplayVideoComponent youtubeId={this.props.profile.whoAmIVideoUri}
                    classimage=""
                    classvideo="ca-youtube-video-container" />
                </div>
              </Col>
            }
          </Row>          

          <Row>
            <Col className="col-12 col-sm-6" style={{ marginBottom: "2rem" }}>
              <Link to={this.props.portfolioLink} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={portfolioIcon} className="ca-homepage-icon" alt={translatedMessage("ARTWORK.PORTFOLIO")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("ARTWORK.PORTFOLIO")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6" style={{ marginBottom: "2rem" }}>
              <Link to={`/profile/${this.props.userId}/media`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={mediaIcon} className="ca-homepage-icon" alt={translatedMessage("GENERAL.MEDIA")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
                </div>
              </Link>
            </Col>
          </Row>

          {this.props.webResources.length > 0 &&
            <Row>
              <Col className='pl-0 pr-0'>
                {this.props.webResources.map(webResource => (
                  <div key={webResource.id} className='ca-profile-web-resources-container'>
                    <a href={webResource.uri} target="_blank" rel="noopener noreferrer">
                      {webResource.uri}
                    </a>
                  </div>
                ))}
              </Col>
            </Row>
          }                    
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ProfileViewComponent;