import React from 'react';

import backArrow from '../../../components/shared/Resources/left-arrow.svg';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import moment from 'moment';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import TableComponent from './Components/UserTableComponent/UserTableComponent';
import EditUserModal from './Components/EditUserModalComponent';
import DeleteUserModal from './Components/DeleteUserModalComponent';

class CompanyAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      userList: [],
      allUsers: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      showUserModal: false,
      showDeleteModal: false,
      selectedUserCompanyId: -1,
      deletedUserCompanyId: -1,
      selectedUser: null
    };

    this.editUser = this.editUser.bind(this);
    this.approveUser = this.approveUser.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        this.setState({
          company: response.data.company
        }, this.getData)
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async getData() {
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    const userList = await CompanyService.getCompanyUsers(this.state.company.id)
      .then(response => {
        return response;
      })
      .catch(err => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })

    this.setState({
      userList: userList,
      isLoaded: true
    })
  }

  async editUser(selectedUserCompanyId) {
    var selectedUser = this.state.userList.filter(item => item.id === selectedUserCompanyId)[0];

    this.setState({
      selectedUserCompanyId: selectedUserCompanyId,
      selectedUser: selectedUser,
      showUserModal: true
    })
  }

  deleteUSer = (deletedUserCompanyId) => {
    this.setState({
      deletedUserCompanyId: deletedUserCompanyId,
      showDeleteModal: true
    })
  }

  async approveUser(selectedUserCompanyId, isApproved) {
    let errorMessage = "COMPANY.USER.ADD_ERROR";
    var selectedUser = this.state.userList.filter(item => item.id === selectedUserCompanyId)[0];

    if (isApproved) {
      ApiService.getAuthenticatedInstance().post(`/company/${this.state.company.id}/user/${selectedUser.user.id}/approve`)
        .then(() => {
          toast.success(translatedMessage("COMPANY.USER.ADD_SUCCESS"))
          this.refreshData();
        })
        .catch(err => {
          if (err && err.response && (err.response.status === 404 || err.response.status === 406)) {
            errorMessage = err.response.data.message;
          }
          toast.error(translatedMessage(errorMessage))
        })
    } else {
      errorMessage = "COMPANY.JOIN.REFUSE.ERROR";

      ApiService.getAuthenticatedInstance().post(`/company/${this.state.company.id}/user/${selectedUser.user.id}/reject`)
        .then(() => {
          toast.success(translatedMessage("COMPANY.JOIN.REFUSE.SUCCESS"))
          this.refreshData();
        })
        .catch(err => {
          if (err && err.response && (err.response.status === 404 || err.response.status === 406)) {
            errorMessage = err.response.data.message;
          }
          toast.error(translatedMessage(errorMessage))
        })
    }
  }

  async refreshData() {
    let userList = await CompanyService.getCompanyUsers(this.state.company.id)
      .then(response => { return response });

    this.setState({
      userList: userList,
    })
  }

  onHideEdit = (userId, status, isAdministrator) => {
    if (userId && status) {
      var promise;
      promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/userCompanies/${this.state.selectedUserCompanyId}`, {
          status: status,
          isAdministrator: isAdministrator,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then(() => resolve())
          .catch(err => reject(err))
      });

      promise.then(() => {
        toast.success(translatedMessage("COMPANY.USER.ADD_SUCCESS"))
        this.setState({
          showUserModal: false
        }, () => this.refreshData())
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.USER.ADD_ERROR"));

          this.setState({
            showUserModal: false
          })
        })
    } else {
      this.setState({
        showUserModal: false
      })
    }
  }

  onHideDelete = (userCompanyId) => {
    if (userCompanyId && userCompanyId !== -1) {
      ApiService.getAuthenticatedInstance().delete(`/userCompanies/${userCompanyId}`)
        .then(result => {
          toast.success(translatedMessage("COMPANY.USER.DELETE_SUCCESS"))
          this.setState({
            showDeleteModal: false
          }, () => this.refreshData())
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.USER.DELETE_ERROR"));

          this.setState({
            showDeleteModal: false
          })
        })
    } else {
      this.setState({
        showDeleteModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <span className="ca-page-title">{translatedMessage("USER.USERS")}</span>

            <div>
              <Button className="ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/home`)}>{translatedMessage("BUTTON.BACK")}
                <Image className="ca-page-title-icon ml-2 mr-0" src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          {this.state.userList.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <div className="ca-page-subtitle">{translatedMessage("COMPANY.NO_USERS")}</div>
                  <div>{translatedMessage("COMPANY.NO_USERS_DETAIL")}</div>
                </Col>
              </Row>
            </div>
          }

          {this.state.userList.length > 0 &&
            <div>
              <TableComponent
                data={this.state.userList}
                editUser={this.editUser}
                deleteUSer={this.deleteUSer}
                approveUser={this.approveUser}
              />
            </div>
          }

          <EditUserModal
            show={this.state.showUserModal}
            onHide={this.onHideEdit}
            users={this.state.allUsers}
            selectedUser={this.state.selectedUser}
          />

          <DeleteUserModal
            show={this.state.showDeleteModal}
            onHide={this.onHideDelete}
            usercompanyid={this.state.deletedUserCompanyId}
          />
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAdminEdit;