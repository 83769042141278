import React from 'react';

import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';
import LightboxComponent from '../../../../components/shared/LightboxComponent/LightboxComponent'
import CardComponent from '../../../../components/shared/Challenge/ChallengeImageCardComponent'
import NotificationModal from '../../../../components/shared/Challenge/ChallengeNotificationModalComponent'

import backIcon from '../../../../components/shared/Resources/icons/back.svg';
import challengeIcon from '../../../../components/shared/Resources/icons/challenge.svg';
import saveIcon from '../../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../../components/shared/Resources/icons/cancel.svg';
import submitIcon from '../../../../components/shared/Resources/icons/submit.svg';

import { Row, Image, Button, Form } from 'react-bootstrap';

import ApiService from '../../../../services/api.service';
import { translatedMessage } from '../../../../services/language.service';
import CompanyService from '../../../../services/company.service';

import { toast } from "react-toastify";

class ChallengeMediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      challengeId: this.props.match.params.challengeId,
      challenge: null,
      imageModalVisible: false,
      videoModalVisible: false,
      videoYoutubeIdModalVisible: false,
      lighboxImages: [],
      photoIndex: 0,
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      company: null,
      isSelectWinner: false,
      showModal: false
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        let company = response.data.company;

        if (company.status.name === 'ACTIVE') {
          this.setState({
            company: company,
            canEdit: true
          }, this.getData)
        } else {
          this.setState({
            isError: true,
            errorMessage: "COMPANY.NOT_APPROVED",
            isLoaded: true
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async getData() {
    const challengeId = this.state.challengeId;
    let isError = false;
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    if (this.state.canEdit) {
      let challenge = await CompanyService.getCompanyChallengeFilesForManage(challengeId)
        .then(response => {
          return response;
        })
        .catch(err => {
          isError = true;
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }
        })

      if (!isError) {
        this.setState({
          challenge: challenge,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  handleFullScreen = (index) => {
    let lighboxImages = [];
    this.state.challenge.challengeItems
      .filter(item => item.photoUuid !== null)
      .forEach(item => {
        lighboxImages.push(item.imageURL)
      });

    this.setState({
      lighboxImages: lighboxImages,
      photoIndex: index,
      showLightbox: true
    })
  }

  onHideLightbox = () => {
    this.setState({ showLightbox: false })
  }

  selectWinner = () => {
    this.setState({ isSelectWinner: true });
  }

  cancelSelect = () => {
    this.setState({
      isSelectWinner: false,
    })
  }

  handleCheckChange = (id, isChecked) => {
    let challengeItems = this.state.challenge.challengeItems;

    challengeItems.map(item => {
      if (item.id === parseInt(id)) {
        console.log("this is the item")
        item.isWinner = isChecked
      }
      return item;
    })

    this.setState({
      challenge: {
        ...this.state.challenge,
        challengeItems: challengeItems
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let challengeItemIds = []
    this.state.challenge.challengeItems.forEach(item => {
      if (item.isWinner) {
        challengeItemIds.push(item.id.toString());
      }
    })


    ApiService.getAuthenticatedInstance().post(`/challenge/${this.state.challenge.id}/winners?challengeItemIds=${challengeItemIds}`)
      .then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          isSelectWinner: false,
        }, this.getData)
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);

        let errorMessage = "GENERAL.SAVE_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        toast.error(translatedMessage(errorMessage));
        this.setState({
          isSelectWinner: false,
        }, this.getData)
      })
  }

  sendNotification = () => {
    let winners = this.state.challenge.challengeItems.filter(item => item.isWinner)

    if (winners.length === 0) {
      toast.error(translatedMessage("CHALLENGE.WINNER.NOT_SELECTED"));
    } else {
      this.setState({
        showModal: true
      })
    }
  }

  onHideModal = (message) => {
    if (message) {
      var promise;
      promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().post(`/challenge/${this.state.challenge.id}/notify-winners`, {
          content: message        
        })
          .then(() => resolve())
          .catch(err => reject(err))
      });

      promise.then(() => {
        toast.success(translatedMessage("CHALLENGE.NOTIFICATION.SEND.SUCCESS"))
        this.setState({
          showModal: false,
          challenge: {
            ...this.state.challenge,
            notificationSent: true
          }
        })
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("CHALLENGE.NOTIFICATION.SEND.ERROR"));

          this.setState({
            showModal: false
          })
        })
    } else {
      this.setState({
        showModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2">
              <div className="d-flex align-items-center">
                <Image src={challengeIcon} className="ca-page-title-icon" alt={translatedMessage("CHALLENGE.CHALLENGE")} />
                <span className="ca-page-title">{this.state.challenge.name}</span>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
                <Button onClick={this.selectWinner} 
                  className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isSelectWinner || this.state.challenge.notificationSent ? "ca-hide-button" : "")}>
                  
                  <Image src={challengeIcon} alt={translatedMessage("CHALLENGE.PICK_WINNERS")} title={translatedMessage("CHALLENGE.PICK_WINNERS")} />
                </Button>
                <Button onClick={this.sendNotification} 
                  className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isSelectWinner || this.state.challenge.notificationSent ? "ca-hide-button" : "")}>
                  
                  <Image src={submitIcon} alt={translatedMessage("CHALLENGE.WINNER.SEND_NOTICATION")} title={translatedMessage("CHALLENGE.WINNER.SEND_NOTICATION")} />
                </Button>
                <Button type="submit" form="challengeForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isSelectWinner ? "ca-hide-button" : "")}>
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
                </Button>
                <Button onClick={this.cancelSelect} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isSelectWinner ? "ca-hide-button" : "")}>
                  <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
                </Button>

                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/challenge`)}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            { this.state.challenge.notificationSent &&
              <Row className='mt-0 mb-0'>
                {translatedMessage("CHALLENGE.NOTIFICATION.SENT").concat("!")}
              </Row>
            }

            <Form id="challengeForm" className="ca-form" onSubmit={this.handleSubmit}>
              <div className="card-columns">
                {this.state.challenge.challengeItems.map((element, index) => {
                  return (
                    <CardComponent
                      key={element.id}
                      id={element.id}
                      image={element.imageURL}
                      isSelectWinner={this.state.isSelectWinner}
                      isWinner={element.isWinner}
                      onClick={() => this.handleFullScreen(index)}
                      onCheck={this.handleCheckChange}
                    />
                  )
                })}
              </div>
            </Form>
          </div >

          <LightboxComponent
            images={this.state.lighboxImages}
            showLightbox={this.state.showLightbox}
            photoIndex={this.state.photoIndex}
            onHide={this.onHideLightbox}
          />

          <NotificationModal
            show={this.state.showModal}
            onHide={this.onHideModal}
            selectedArtist={this.state.selectedArtist}
          />

        </>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ChallengeMediaPage;