import React from "react";

import { Modal, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';
import ApiService from '../../../services/api.service';

class ChangeYoutubeIdModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let youtubeId = ApiService.getYouTubeID(event.target.elements.formYoutubeId.value);

    this.props.onHide(this.props.storageitemfileid, this.props.videourlid, youtubeId);
  }

  render() {
    return (
      <Modal
        {...this.props}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage(this.props.storageitemfileid || this.props.videourlid ? "VIDEO.EDIT_YOUTUBE_ID": "VIDEO.ADD_YOUTUBE_ID")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <Form id="youtubeForm" className="ca-form" onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="formYoutubeId">
                <Form.Label>
                  {translatedMessage("VIDEO.YOUTUBE_ID")}
                  <OverlayTrigger
                    key='yID'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-yID`} className="ca-tooltip">
                        {translatedMessage("VIDEO.YOUTUBE_ID_DESC")}
                      </Tooltip>
                    }
                  >
                    <span className="ca-question-icon"></span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  defaultValue={this.props.youtubeid}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="ca-button mr-1" type="submit" form="youtubeForm">
            {translatedMessage("GENERAL.SAVE_CHANGES")}
          </Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide(this.props.storageitemfileid, 0)}>
            {translatedMessage("GENERAL.CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ChangeYoutubeIdModal;