import React from 'react';
import { Link } from 'react-router-dom'

import { Card } from 'react-bootstrap'

class ExhibitionCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Card className="ca-gallery-card">
                    <Link to={`${this.props.exhibitionPrefixUrl}/${this.props.id}`}>
                        <Card.Img variant="top" src={this.props.cardImage} />
                    </Link>
                    <Card.Body>
                        <Card.Title className="d-flex justify-content-center align-items-center mb-0">{this.props.cardTitle}</Card.Title>
                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default ExhibitionCardComponent;