import React from 'react';

import { Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translatedMessage } from '../../../services/language.service';
import { toast } from "react-toastify";
import moment from 'moment';

import profilepic from '../Resources/defaultProfilePicture.png';
import portfolioIcon from '../Resources/icons/portfolio.svg';
import mediaIcon from '../Resources/icons/media.svg';
import defaultVideo from '../Resources/videoDefault.jpg';

import DisplayVideoComponent from '../DisplayVideoComponent/DisplayVideoComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import HtmlEditorModalComponent from '../HtmlEditorModalComponent/HtmlEditorModalComponent';
import ProfileDetailsEditModalComponent from './ProfileDetailsEditModalComponent';
import ProfileRolesEditModalComponent from './ProfileRolesEditModalComponent';
import ProfileAddressEditModalComponent from './ProfileAddressEditModalComponent';
import ProfileUsernameEditModalComponent from './ProfileUsernameEditModalComponent';
import AddFileModalComponent from '../AddFileModalComponent/AddFileModalComponent';
import ChangeYoutubeIdModalComponent from '../ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'
import SocialMediaAccountEditModalComponent from '../SocialMediaAccountEditModalComponent/SocialMediaAccountEditModalComponent';

import ApiService from '../../../services/api.service';
import FileService from '../../../services/file.service';
import UserService from '../../../services/user.service';

class ProfileEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      username: this.props.username,
      isLoaded: true,
      isError: false,
      userRole:
        [
          { key: 'artist', label: translatedMessage("USER.TYPE.ARTIST") },
          { key: 'buyer', label: translatedMessage("USER.TYPE.ART_CONSUMER") },
          { key: 'institution', label: translatedMessage("USER.TYPE.ART_INSTITUTION") },
          { key: 'expert', label: translatedMessage("USER.TYPE.EXPERT_CO_WORKER") },
          { key: 'investor', label: translatedMessage("USER.TYPE.INVESTOR") },
        ],
      userRolesString: "",
      showEditorModal: false,
      editorModalData: {
        content: '',
        title: '',
        dataFor: '',
        required: false
      },
      showDetailsModal: false,
      detailsModalData: {
        firstName: '',
        lastName: '',
        profileName: '',
        phone: '',
        birthday: new Date(),
        genderId: '',
      },
      showRolesModal: false,
      showAddFileModal: false,
      fileModalData: {},
      showEditYoutubeModal: false,
      showAddressModal: false,
      addressModalData: {
        address: '',
        city: '',
        zip: '',
        country: 0,
      },
      showUserModal: false,
      userModalData: {
        content: '',
        title: '',
        dataFor: '',
        required: false
      },
      showSMAModal: false,
      socialModalData: {
        title: '',
        icon: '',
        url: null
      },
    };
  }

  componentDidMount() {
    ApiService.getSocialMediaAccountTypeList()
      .then(result => {
        this.setState({
          smaTypeList: result,
        }, this.computeRoles)
      })
  }

  computeRoles = () => {
    let roles = ['artist', 'expert', 'buyer', 'investor', 'institution']
      .filter(key => this.state.profile[key])
      .map(x => x);

    let userRoles = []
    roles.map((element) => {
      userRoles.push(this.state.userRole.find(role => role.key === element).label);
      return element;
    })

    this.setState({
      userRolesString: userRoles.join(', ')
    })
  }

  onShowEdit = (event) => {
    if (event.currentTarget.id === 'details') {
      this.setState({
        detailsModalData: this.state.profile,
        showDetailsModal: true,
      })
    } else if (event.currentTarget.id === 'aboutMe') {
      this.setState({
        editorModalData: {
          content: this.state.profile.about,
          title: translatedMessage("PROFILE.ABOUT_ME"),
          dataFor: 'aboutMe',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'roles') {
      this.setState({
        detailsModalData: this.state.profile,
        showRolesModal: true,
      })
    } else if (event.currentTarget.id === 'address') {
      this.setState({
        addressModalData: this.state.profile.address ? this.state.profile.address : this.state.addressModalData,
        showAddressModal: true,
      })
    }
    if (event.currentTarget.id === 'username') {
      this.setState({
        userModalData: {
          content: this.state.username,
          dataFor: 'username',
          required: true
        },
        showUserModal: true,
      })
    }
  }

  onHideEdit = (data) => {
    if (data && data.dataFor) {
      let aboutMe = this.state.profile.about;
      let profile = this.state.profile;

      data.content = data.content ? data.content : null;

      if (data.dataFor === "aboutMe") {
        aboutMe = data.content;
      } else if (data.dataFor === "details" || data.dataFor === "roles") {
        profile = data;
      }

      const promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/profiles/${this.state.profile.id}`, {
          about: aboutMe,
          birthday: profile.birthday,
          firstName: profile.firstName,
          lastName: profile.lastName,
          profileName: profile.profileName ? profile.profileName.trim() : null,
          phone: ApiService.nullIfEmptyString(profile.phone),
          gender: profile.genderId ? `/genders/${profile.genderId}` : null,
          artist: profile.artist,
          buyer: profile.buyer,
          expert: profile.expert,
          investor: profile.investor,
          institution: profile.institution,

          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
          .then(() => resolve())
          .catch(err => reject(err));
      });

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          profile: {
            ...this.state.profile,
            about: aboutMe,
            displayName: profile.profileName ? profile.profileName : profile.firstName + " " + profile.lastName,
            birthday: profile.birthday,
            firstName: profile.firstName,
            lastName: profile.lastName,
            profileName: profile.profileName ? profile.profileName.trim() : null,
            phone: ApiService.nullIfEmptyString(profile.phone),
            genderId: profile.genderId,
            artist: profile.artist,
            buyer: profile.buyer,
            expert: profile.expert,
            investor: profile.investor,
            institution: profile.institution,
            timestamp: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          }
        }, this.computeRoles)
      })
        .catch(err => {
          if (err && err.response && err.response.data.message.includes('uk_profile_name')) {
            toast.error(translatedMessage("PROFILE.SAVE_ERROR.PROFILE_NAME"), { autoClose: 7000, pauseOnHover: true });
          } else {
            toast.error(translatedMessage("PROFILE.SAVE_ERROR"), { autoClose: 7000, pauseOnHover: true });
          }
        });
    }

    this.setState({
      showDetailsModal: false,
      showEditorModal: false,
      showRolesModal: false
    })
  }

  onHideEditUsername = (data) => {
    if (data && data.dataFor && data.content) {
      const promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/users/${this.props.userId}`, {
          username: data.content,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
        resolve();
      });

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        ApiService.handleLogOut(this.props.history)
      })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);
        });
    } else {
      this.setState({
        showUserModal: false
      })
    }
  }

  onHideEditAddress = (data) => {
    let address = this.state.profile.address ? this.state.profile.address : this.state.addressModalData;

    if (data && data.dataFor) {
      address = data;

      UserService.doSaveAddress(address)
        .then(result => {
          if (address.id && result && result !== -1) {
            toast.success(translatedMessage("PROFILE.SAVE_SUCCESS"));
            this.setState({
              profile: {
                ...this.state.profile,
                address: address
              },
              showAddressModal: false
            })
          } else if (!address.id && result && result !== -1) {
            const parts = result.data._links.self.href.split('/');
            address.id = parts[parts.length - 1];

            ApiService.getAuthenticatedInstance().patch(`/profiles/${this.state.profile.id}`, {
              address: address.id === null ? null : `/addresses/${address.id}`,
              lastUpdatedOn: Date.now(),
              lastUpdatedBy: ApiService.getCurrentUser().username,
            })
              .then(() => {
                toast.success(translatedMessage("PROFILE.SAVE_SUCCESS"));
                this.setState({
                  profile: {
                    ...this.state.profile,
                    address: address
                  },
                  showAddressModal: false
                })
              })
              .catch(() => {
                toast.error(translatedMessage("PROFILE.SAVE_ERROR"), { autoClose: 7000, pauseOnHover: true });
              });

          } else if (result === -1) {
            address.id = null;
          }
        }).catch(err => {
          toast.error(translatedMessage("PROFILE.ERROR_ADDRESS"), { autoClose: 7000, pauseOnHover: true });
        });

    } else {
      this.setState({
        profile: {
          ...this.state.profile,
          address: {
            ...address,
            dataFor: null
          }
        },
        showAddressModal: false
      })
    }
  }

  onShowAddFile = (event) => {
    let modalData = {};

    modalData = {
      modalTitle: translatedMessage("UPLOAD.IMAGES"),
      fileUsage: FileService.getFileUsage().LOGO,
      fileType: FileService.getResourceType().IMAGE,
      deletePrevious: true,
      deletedImage: this.state.profile.photo ? { uuid: this.state.profile.photo.uuid } : null,
      deletePreviousDesc: "DROPZONE.DELETE_PREVIOUS_IMAGE",
      fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
      maxSize: 1,
      maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
      multipleFiles: false,
      multipleFilesDesc: "",
      recommendedShape: "DROPZONE.RECOMMENDED_SHAPE.SQUARE",
      entity: this.state.profile,
      entityUpdateAPILink: "profiles",
      storageitemlinked: false
    }

    this.setState({
      fileModalData: modalData,
      showAddFileModal: true
    })
  }

  // update the state based on the changes to the image files
  onHideAddFile = (hasUpload, uploadedFile) => {
    if (hasUpload && hasUpload === true) {
      this.setState({
        profile: {
          ...this.state.profile,
          profilePicture: uploadedFile ? `${ApiService.getBaseUrl()}/storage/file/${uploadedFile.uuid}` : profilepic,
          photo: uploadedFile ? { uuid: uploadedFile.uuid } : null
        },
        imageModalVisible: false
      });
    }

    this.setState({
      showAddFileModal: false,
    })
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      ApiService.getAuthenticatedInstance().patch(`/profiles/${this.state.profile.id}`, {
        whoAmIVideoUri: videoId,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          if (videoId) {
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          }

          this.setState({
            profile: {
              ...this.state.profile,
              whoAmIVideoUri: videoId
            },
            showEditYoutubeModal: false
          })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  setSocialMediaAccount = (type, url) => {
    let selectedType = this.state.smaTypeList.filter(item => item.name === type);

    if (selectedType[0]) {
      let modalData = {
        title: translatedMessage(selectedType[0].label),
        icon: ApiService.getSocialMediaAccountIconByType(type),
        url: url ? url : null,
        type: selectedType[0],
        field: type.toLowerCase() + 'Uri'
      }

      this.setState({
        socialModalData: modalData,
        showSMAModal: true
      })
    }
  }

  onHideSocial = (data) => {
    if (data && data.isSaved && data.field) {
      ApiService.getAuthenticatedInstance().patch(`/profiles/${this.state.profile.id}`, {
        [data.field]: data.url,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));

          this.setState({
            profile: {
              ...this.state.profile,
              [data.field]: data.url
            },
            showSMAModal: false
          })
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showSMAModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <div className="d-flex flex-sm-row flex-column" style={{ marginBottom: "2rem" }}>
            <div id="profilePic" className="d-flex align-items-center justify-content-center ca-profile-pic-container" onClick={this.onShowAddFile}>

              <img
                alt="Profile pic"
                src={this.state.profile.profilePicture}
                className="d-inline-block align-center profile-pic ca-profile-photo"
              />

              <div className="ca-profile-photo-change">
                <div style={{ fontFamily: "FontAwesome", fontSize: "32px" }}>&#xf030;</div>
                <div>{translatedMessage("GENERAL.IMAGE.TOOLTIP")}</div>
              </div>
            </div>

            <div className="w-100 d-flex align-content-between flex-wrap ca-profile-detail-container">
              <div className="w-100 d-flex align-content-center flex-wrap">
                <div className='w-100'>
                  <span className="ca-profile-name">{this.state.profile.displayName}</span>
                </div>
                {this.state.userRolesString &&
                  <div className="w-100">
                    {this.state.userRolesString}
                  </div>
                }
                <div className="ca-profile-membersince w-100">
                  {translatedMessage("PROFILE.MEMBER_SINCE")} {this.state.profile.profileCreatedOn}
                </div>
              </div>
              <div className='w-100 actions'>
                <span id="details" className="mr-2"
                  title={`${translatedMessage("GENERAL.EDIT")} ${translatedMessage("PROFILE.PROFILE").toLowerCase()} ${translatedMessage("GENERAL.DETAILS").toLowerCase()}`}
                  onClick={this.onShowEdit}>
                  <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                </span>
                <span id="roles" className="mr-2"
                  title={`${translatedMessage("GENERAL.EDIT")} ${translatedMessage("PROFILE.YOUR_ROLES").toLowerCase()}`}
                  onClick={this.onShowEdit}>
                  <FontAwesomeIcon icon={["fas", "user-tag"]} />
                </span>
                <span id="address" className="mr-2"
                  title={`${translatedMessage("GENERAL.EDIT")} ${translatedMessage("PROFILE.ADDRESS").toLowerCase()}`}
                  onClick={this.onShowEdit}>
                  <FontAwesomeIcon icon={["far", "address-card"]} />
                </span>
                <span id="username" className="mr-2"
                  title={`${translatedMessage("GENERAL.EDIT")} ${translatedMessage("PROFILE.USERNAME").toLowerCase()}`}
                  onClick={this.onShowEdit}>
                  <FontAwesomeIcon icon={["fas", "at"]} />
                </span>
              </div>
            </div>
          </div>

          <div className='ca-profile-social-container'>
            <span className="mr-3"
              onClick={() => this.setSocialMediaAccount(ApiService.getSocialMediaAccountType().PATREON, this.state.profile.patreonUri)}
              title={translatedMessage("GENERAL.EDIT")}>

              <FontAwesomeIcon icon={["fab", "patreon"]} />
            </span>

            <span className="mr-3"
              onClick={() => this.setSocialMediaAccount(ApiService.getSocialMediaAccountType().FACEBOOK, this.state.profile.facebookUri)}
              title={translatedMessage("GENERAL.EDIT")}>

              <FontAwesomeIcon icon={["fab", "facebook-square"]} />
            </span>

            <span className="mr-3"
              onClick={() => this.setSocialMediaAccount(ApiService.getSocialMediaAccountType().LINKEDIN, this.state.profile.linkedinUri)}
              title={translatedMessage("GENERAL.EDIT")}>

              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </span>

            <span className="mr-3"
              onClick={() => this.setSocialMediaAccount(ApiService.getSocialMediaAccountType().INSTAGRAM, this.state.profile.instagramUri)}
              title={translatedMessage("GENERAL.EDIT")}>

              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </span>

            <span className="mr-3"
              onClick={() => this.setSocialMediaAccount(ApiService.getSocialMediaAccountType().PINTEREST, this.state.profile.pinterestUri)}
              title={translatedMessage("GENERAL.EDIT")}>

              <FontAwesomeIcon icon={["fab", "pinterest-square"]} />
            </span>

            <span className="mr-3"
              onClick={() => this.setSocialMediaAccount(ApiService.getSocialMediaAccountType().TWITTER, this.state.profile.twitterUri)}
              title={translatedMessage("GENERAL.EDIT")}>

              <FontAwesomeIcon icon={["fab", "twitter-square"]} />
            </span>                                                            
          </div>

          <Row className='align-items-top mb-5'>
            <Col className="pl-0 pr-0 col-12 col-md-6 col-lg-11">
              <div className="font-bold">
                {translatedMessage("PROFILE.ABOUT_ME")}
                <span id="aboutMe" className="ml-2" style={{ fontFamily: "FontAwesome", cursor: "pointer", fontWeight: "400", fontSize: "16px" }}
                  title={translatedMessage("GENERAL.EDIT")}
                  onClick={this.onShowEdit}>
                  <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
                </span>
              </div>
              {this.state.profile.about &&
                <span>
                  <div dangerouslySetInnerHTML={{ __html: this.state.profile.about }} className="ca-description-value" />
                </span>
              }
              {!this.state.profile.about &&
                <span className='ca-description-value'>{translatedMessage("PROFILE.WRITE_SOMETHING_ABOUT_YOURSELF")}</span>
              }
            </Col>


            <Col className="col-12 col-md-6 col-lg-1">
              <div className="ca-profile-video">
                {this.state.profile.whoAmIVideoUri &&
                  <DisplayVideoComponent youtubeId={this.state.profile.whoAmIVideoUri}
                    classimage=""
                    classvideo="ca-youtube-video-container" />
                }

                {!this.state.profile.whoAmIVideoUri &&
                  <div className="h-100 no-content d-flex align-items-center justify-content-center">
                    <img src={defaultVideo} alt="CA logo" />
                  </div>
                }

                <div style={{ fontSize: "20px" }}>
                  <OverlayTrigger
                    key='addPresVideo'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-addPresVideo`} className="ca-tooltip">
                        {translatedMessage("PROFILE.WHO_AM_I_URL.TOOLTIP")}
                      </Tooltip>
                    }
                  >
                    <span className="mr-3" onClick={this.handleYoutubeIdEdit} style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon icon={["fab", "youtube"]} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12 col-sm-6" style={{ marginBottom: "2rem" }}>
              <Link to={this.props.portfolioLink} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={portfolioIcon} className="ca-homepage-icon" alt={translatedMessage("ARTWORK.PORTFOLIO")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("ARTWORK.PORTFOLIO")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6" style={{ marginBottom: "2rem" }}>
              <Link to={`/profile/media`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={mediaIcon} className="ca-homepage-icon" alt={translatedMessage("GENERAL.MEDIA")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
                </div>
              </Link>
            </Col>
          </Row>

          {this.props.webResources.length > 0 &&
            <Row>
              <Col className='pl-0 pr-0'>
                {this.props.webResources.map(webResource => (
                  <div key={webResource.id} className='ca-profile-web-resources-container'>
                    <a href={webResource.uri} target="_blank" rel="noopener noreferrer">
                      {webResource.uri}
                    </a>
                  </div>
                ))}
              </Col>
            </Row>
          }

          <HtmlEditorModalComponent
            show={this.state.showEditorModal}
            onHide={this.onHideEdit}
            data={this.state.editorModalData}
          />

          <ProfileDetailsEditModalComponent
            show={this.state.showDetailsModal}
            onHide={this.onHideEdit}
            profile={this.state.detailsModalData}
          />

          <ProfileAddressEditModalComponent
            show={this.state.showAddressModal}
            onHide={this.onHideEditAddress}
            address={this.state.addressModalData}
          />

          <ProfileRolesEditModalComponent
            show={this.state.showRolesModal}
            onHide={this.onHideEdit}
            profile={this.state.detailsModalData}
          />

          <AddFileModalComponent
            show={this.state.showAddFileModal}
            onHide={this.onHideAddFile}
            data={this.state.fileModalData}
          />

          <ChangeYoutubeIdModalComponent
            show={this.state.showEditYoutubeModal}
            onHide={this.onHideYoutubeModal}
            youtubeid={this.state.profile.whoAmIVideoUri}
          />

          <ProfileUsernameEditModalComponent
            show={this.state.showUserModal}
            onHide={this.onHideEditUsername}
            data={this.state.userModalData}
          />

          <SocialMediaAccountEditModalComponent
            show={this.state.showSMAModal}
            onHide={this.onHideSocial}
            data={this.state.socialModalData}
          />
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ProfileEditComponent;