import React from 'react';

import { Row } from 'react-bootstrap';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';

class ExhibitionAdminArExperienceLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      exhibitionArExperience: [],
      exhibition: [],
      arExperience: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      exhibitionIcon: null,
      mapLocations: [],
      initialCenter: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canEdit: hasPermission("EXHIBITION_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    let promise = [];

    if (this.state.canEdit) {

      ApiService.getAuthenticatedInstance().get(`arExperience/exhibitionArExperience/${this.state.id}`)
        .then(response => {
          this.setState({
            exhibitionArExperience: response.data,
            exhibition: response.data.exhibition,
            arExperience: response.data.arExperience,
          }, () => {
            var initialCenter = {};
            promise.push(ApiService.getAuthenticatedInstance().get(`gallery/exhibitionLocation/${this.state.exhibition.id}`)
              .then(response => {
                if (response.data[0]) {
                  initialCenter = {
                    lat: response.data[0].locationLat,
                    lng: response.data[0].locationLng,
                  }
                } else {
                  initialCenter = null
                }
              })
            )

            let artLocations = [];
            let mapLocations = [];
            promise.push(
              ApiService.getAuthenticatedInstance().get(`map/exhibitionMapLocations/${this.state.exhibition.id}`)
                .then(result => {
                  mapLocations = result.data.filter(item => item.entity.entityId !== this.state.arExperience.id)
                  artLocations = result.data.filter(item => item.entity.entityId === this.state.arExperience.id)
                })
            )

            var exhibitionPin = []
            promise.push(MapService.getLocationPinByType('EXHIBITION')
              .then(response => {
                exhibitionPin = response;
              }))

            var pins = [];
            promise.push(MapService.getLocationPinByType(this.state.exhibitionArExperience.arExperience.category.label)
              .then(response => {
                pins = response;
              }))

            Promise.all(promise).then(() => {
              this.setState({
                location: artLocations[0] ? artLocations[0] : this.state.location,
                hasGeofence: artLocations[0] && artLocations[0].geofence ? true : false,
                pinIcon: pins[0] ? pins[0].imageUrl : null,
                exhibitionIcon: exhibitionPin[0] ? exhibitionPin[0].imageUrl : null,
                initialCenter: initialCenter,
                mapLocations: mapLocations,
                isLoaded: true
              })
            })
          })
        })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.id}
            entityName={ApiService.getEntityName().EXHIBITION_AR_EXPERIENCE}
            mapLocations={this.state.mapLocations}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={this.state.exhibitionIcon}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.arExperience.name}
            parentEntityTitle={this.state.exhibition.name}
            backLink={`/exhibitionAdminArExperiences/${this.state.exhibition.id}`}
            history={this.props.history}
            zoomLevel={17}
          />
        </>
      );
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }
}

export default ExhibitionAdminArExperienceLocation;