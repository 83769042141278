import React from 'react';

import back from '../../../components/shared/Resources/left-arrow.svg';
import gallery from '../../../components/shared/Resources/homepage/buyArt.svg';

import { Link } from 'react-router-dom'

import CardComponent from "./GalleryViewArtworkCardComponent";
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import { Row, Col, Dropdown, Form, InputGroup, Image } from 'react-bootstrap';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import ArtworkService from '../../../services/artwork.service';

const ENTER_KEY = 13;

class GalleryViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryId: this.props.galleryId,
            gallery: null,
            searchInput: '',
            artworks: [],
            artworkCategoryList: [],
            filteredArtworks: [],
            selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
            selectedType: "GENERAL_TYPE.ANY",
            typeList: ["GENERAL_TYPE.ANY", "ARTWORK", "VIRTUAL_GALLERY"],
            isLoaded: false,
            isError: false,
            errorMessage: '',
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount = () => {
        ApiService.getAuthenticatedInstance().get(`gallery/getGalleryDetail/${this.props.galleryId}`)
            .then(response => {
                this.setState({
                    gallery: response.data
                }, this.getArtworks)
            })
            .catch(err => {
                var errorMessage = "GENERAL.GET_DATA_ERROR";
                if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
                    errorMessage = err.response.data.message;
                }
                console.log("An error has ocurred: " + err);
                this.setState({
                    isError: true,
                    errorMessage: errorMessage,
                    isLoaded: true
                });
            });
    }

    getArtworks = () => {
        let galleryId = this.state.galleryId;
        let artworkCategoryList = [];
        let artworks = [];
        let promise = [];

        promise.push(ArtworkService.getArtworkCategoryList()
            .then(response => {
                artworkCategoryList = response;
                artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
            }))

        promise.push(ArtworkService.getGalleryArt(galleryId)
            .then(response => artworks = response)
        )

        Promise.all(promise).then(() => {
            this.setState({
                artworks: artworks,
                filteredArtworks: artworks,
                artworkCategoryList: artworkCategoryList,
                isLoaded: true
            })
        })
    }

    handleSearchInput = (e) => {
        this.setState({
            searchInput: e.target.value,
        }, this.filter);
    }

    handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            this.setState({
                searchInput: e.target.value,
            }, this.filter);
        }
    }

    handleOnCategorySelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

        this.setState({
            selectedCategory: selectedItem[0]
        }, this.filter)
    }

    handleOnTypeSelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.typeList.filter(item => item === eventKey);

        this.setState({
            selectedType: selectedItem[0]
        }, this.filter)
    }

    filter = () => {
        let result = this.state.artworks;
        let searchInput = this.state.searchInput;
        let categoryItem = this.state.selectedCategory;
        let typeItem = this.state.selectedType;

        if (0 !== searchInput.replace(/ /g, ";").length) {
            result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase())
                || elem.author.toLowerCase().includes(searchInput.toLowerCase()));
        }

        if (typeItem !== "GENERAL_TYPE.ANY") {
            result = result.filter(elem => elem.entityName && elem.entityName === typeItem);
        }

        if (categoryItem.id !== "0") {
            result = result.filter(elem => elem.category && elem.category === categoryItem.code);
        }

        this.setState({
            filteredArtworks: result
        })
    }

    render() {
        const artworks = this.state.filteredArtworks;
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-3 pr-2">
                            <div className="ca-page-title-container d-flex align-items-center">
                                <Image src={gallery} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERY")} />
                                <span className="ca-page-title">{this.props.galleryName ? this.props.galleryName : this.state.gallery.name}</span>
                            </div>

                            <div>
                                <Link to={`${this.props.backUrl}`} className="ca-dark-link">
                                    <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                                        title={translatedMessage("BUTTON.BACK")} />
                                </Link>
                            </div>
                        </Row>

                        <Row className="d-flex align-items-center">
                            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                                <InputGroup className="ca-form-input-group">
                                    <Form.Control value={this.state.searchInput} type="text"
                                        placeholder={translatedMessage("SEARCH.BY.NAME_AUTHOR")}
                                        onChange={this.handleSearchInput}
                                        onKeyDown={this.handleKeyDown} />
                                    <InputGroup.Append>
                                        <InputGroup.Text>&#xF002;</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>

                            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                                <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                                    <Dropdown.Toggle>
                                        {translatedMessage(this.state.selectedType)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {this.state.typeList.map((element, index) => {
                                            return (
                                                <Dropdown.Item key={index} eventKey={element}>
                                                    {translatedMessage(element)}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>

                            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                                <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                                    <Dropdown.Toggle>
                                        {translatedMessage(this.state.selectedCategory.code)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {this.state.artworkCategoryList.map((element, index) => {
                                            return (
                                                <Dropdown.Item key={index} eventKey={element.id}>
                                                    {translatedMessage(element.code)}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>

                        <div className="card-columns">
                            {artworks.map((element, index) => {
                                let link = "";
                                let id = 0;
                                let type = null;

                                if (element.entityName === ApiService.getEntityName().ARTWORK) {
                                    id = element.artworkId;
                                    link = `/artwork/${element.artworkId}`;
                                } else if (element.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                                    id = element.virtualGalleryId
                                    link = `/virtualGalleryDetails/${element.virtualGalleryId}`;
                                    type = element.virtualGalleryType;
                                }

                                return (
                                    <CardComponent
                                        key={index}
                                        entity={element}
                                        image={element.image}
                                        title={element.name}
                                        author={element.author}
                                        category={element.category}
                                        type={type}
                                        id={id}
                                        link={link}
                                        companyId={this.props.companyId ? this.props.companyId : null}
                                    />
                                )
                            })}
                        </div>

                    </div >
                )
            }
        }
    }

}

export default GalleryViewComponent;
