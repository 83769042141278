import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, Form, Image, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap/';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import LanguageService from '../../../services/language.service';
import arPortalIcon from '../../../components/shared/Resources/arPortalWhtite.png';

import { toast } from "react-toastify";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import VirtualGalleryService from '../../../services/virtualGallery.service';
import CompanyService from '../../../services/company.service';
import ArtworkService from '../../../services/artwork.service';

class ArPortalPublishDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualGallery: null,
      virtualGalleryTypes: [],
      isLoaded: false,
      isError: false,
      errorMessage: '',
      editorButtns: ApiService.getSunEditorButtons(),
      editorNoButtons: ApiService.getSunEditorNoButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      showArtNo: false,
      artistList: [],
      selectedArtist: null,
      onlyView: false
    };
  }

  componentDidMount() {
    this.populateData();
  }

  async populateData() {
    let isError = false;
    let errorMessage = "PROJECT.GENERAL_ERROR";

    let virtualGallery = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/project/${this.props.match.params.id}`)
      .then(result => { return result.data })
      .catch(err => {
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        isError = true;
      });

    if (!isError) {
      let vgTypes = await VirtualGalleryService.getVirtualGalleryTypesList()
        .then(response => { return response })

      let artistList = [];
      if (virtualGallery.companyId) {
        artistList = await CompanyService.getCompanyArtists(virtualGallery.companyId)
      }

      let artists = artistList.map(item => {
        let artist = {};
        artist.value = item.artist.id;
        artist.label = item.artist.fullName;

        return artist;
      })

      this.setState({
        virtualGallery: {
          ...virtualGallery
        },
        virtualGalleryTypes: vgTypes,
        showArtNo: virtualGallery.needsArtNo,
        artistList: artists,
        selectedArtist: virtualGallery.author && virtualGallery.author.userId
          ? {
            value: virtualGallery.author.userId,
            label: virtualGallery.author.name
          } : null,
        onlyView: virtualGallery.status.name === ArtworkService.artworkStatusEnum().DRAFT || virtualGallery.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      });
    }
  }

  handleInputChange = (event) => {
    if (!event.target) {
      return;
    }

    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        [name]: value
      }
    });
  }

  onChangeEditor = (e) => {
    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        description: e,
      }
    })
  }

  handleOnTypeSelect = (eventKey) => {
    const selectedItem = this.state.virtualGalleryTypes.filter(item => item.name === eventKey);
    let isTypeVirtualGallery = selectedItem[0] && selectedItem[0].name === VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY ? true : false;

    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        type: selectedItem[0],
        nrArArt: isTypeVirtualGallery ? this.state.virtualGallery.nrArArt : 0,
        nrSculpture: isTypeVirtualGallery ? this.state.virtualGallery.nrSculpture : 0
      },
      showArtNo: selectedItem[0] &&
        (selectedItem[0].name === VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY ||
          selectedItem[0].name === VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY) ? true : false,
    });
  }

  handleArtistChange = (eventKey) => {
    this.setState({
      selectedArtist: eventKey
    })
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.onlyView) {
      let isError = false;
      let errorMessage = "";

      // if (!this.state.selectedArtist) {
      //   isError = true;
      //   errorMessage = "ARTWORK.ARTIST.SELECT.EMPTY_ERROR";
      // }

      if (this.state.virtualGallery.type.name === VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY) {
        if (parseInt(this.state.virtualGallery.nrArArt) === 0 && parseInt(this.state.virtualGallery.nrSculpture) === 0) {
          isError = true;
          errorMessage = "AR_PORTAL.ART_NO.NOT_ZERO";
        }
      }

      if (isError) {
        toast.error(translatedMessage(errorMessage));
      } else {
        ApiService.getAuthenticatedInstance().post(`/virtualGallery/publish/save-details/${this.state.virtualGallery.id}`, {
          name: this.state.virtualGallery.name,
          description: this.state.virtualGallery.description,
          nrArArt: parseInt(this.state.virtualGallery.nrArArt),
          nrSculpture: parseInt(this.state.virtualGallery.nrSculpture),
          type: this.state.virtualGallery.type.name,
          authorId: this.state.selectedArtist ? this.state.selectedArtist.value : null
        })
          .then(response => {
            toast.success(translatedMessage(response.data.message));
            this.props.history.push(`/project/publish/ar-portal-art/${this.props.match.params.id}`)
          })
          .catch(err => {
            let errorMessage = "GENERAL.SAVE_ERROR"
            console.log("An error has ocurred: " + err);
            if (err && err.response && (err.response.status === 404 || err.response.status === 204)) {
              errorMessage = err.response.data.message;
            }
            toast.error(translatedMessage(errorMessage));
          });
      }
    } else {
      this.props.history.push(`/project/publish/ar-portal-art/${this.props.match.params.id}`)
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        const backUrl = this.state.virtualGallery.companyId ? `/my-company/${this.state.virtualGallery.companyId}/portfolio` : `/projects`;

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={arPortalIcon} alt="AR Portal icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_AR_PORTAL")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("GENERAL.DETAILS")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={backUrl} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>                      
                </div>

                <Form id="publishForm" onSubmit={this.onSubmit} className="ca-form">
                  <div className="ca-page-padding ca-main-container">
                    <Row>
                      <Col className="col-12 col-lg-6">
                        <div className="ca-publish-section-title">
                          {translatedMessage("AR_PORTAL.DETAILS")}
                        </div>
                        <div className="ca-publish-section-subtitle">
                          {translatedMessage("ARTWORK.DETAILS.DESCRIPTION")}
                        </div>

                        <Form.Group controlId="formName">
                          <Form.Label>{translatedMessage("GENERAL.NAME")} *</Form.Label>
                          <Form.Control
                            required
                            placeholder={translatedMessage("AR_PORTAL.NAME_PLACEHOLDER")}
                            name="name"
                            defaultValue={this.state.virtualGallery.name}
                            isInvalid={this.state.virtualGallery.name.trim().length === 0}
                            onChange={this.handleInputChange}
                            disabled={this.state.onlyView}
                          />
                          <Form.Control.Feedback type="invalid">{translatedMessage("GENERAL.NAME_REQUIRED")}</Form.Control.Feedback>
                        </Form.Group>

                        {this.state.virtualGallery.companyId &&
                          <Form.Group>
                            <Form.Label>{translatedMessage("GENERAL.ARTIST")}</Form.Label>
                            <Select
                              className="basic-single ca-autocomplete"
                              classNamePrefix="select"
                              isDisabled={this.state.onlyView}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="artists"
                              options={this.state.artistList}
                              value={this.state.selectedArtist}
                              onChange={this.handleArtistChange}
                            />
                          </Form.Group>
                        }

                        <Form.Group controlId="formDescription">
                          <Form.Label>{translatedMessage("AR_PORTAL.DESCRIBE")}</Form.Label>
                          <div className="ca-text-editor">
                            <SunEditor
                              name="description"
                              lang={LanguageService.getCurrentLanguage()}
                              placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                              setContents={this.state.virtualGallery.description}
                              setOptions={{
                                height: 150,
                                maxWidth: "100%",
                                buttonList: this.state.onlyView ? this.state.editorNoButtons : this.state.editorButtons,
                                font: this.state.editorFonts,
                                defaultStyle: this.state.editorDefaultStyle
                              }}
                              onChange={this.onChangeEditor}
                              disable={this.state.onlyView}
                              disableToolbar={this.state.onlyView}
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12 col-lg-6">
                        <div className="ca-publish-section-title">{translatedMessage("AR_PORTAL.TYPE")}</div>
                        <div className="ca-publish-section-subtitle">{translatedMessage("AR_PORTAL.TYPE_DESC")}</div>

                        <Form.Group controlId="formType">
                          <Form.Label>
                            {translatedMessage("GENERAL.TYPE")}
                            <OverlayTrigger
                              key='type'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-type`} className="ca-tooltip text-left">
                                  <p>{translatedMessage("AR_PORTAL.TYPE.ART_PORTAL.INFO")}</p>
                                  <p>{translatedMessage("AR_PORTAL.TYPE.ARTIST_PORTAL.INFO")}</p>
                                  <p>{translatedMessage("AR_PORTAL.TYPE.VIRTUAL_GALLERY.INFO")}</p>
                                  <p>{translatedMessage("AR_PORTAL.TYPE.OWN_VIRTUAL_GALLERY.INFO")}</p>
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                            <Dropdown.Toggle>
                              {translatedMessage(this.state.virtualGallery.type.label)}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {this.state.virtualGalleryTypes.map((element, index) => {
                                return (
                                  <Dropdown.Item key={index} eventKey={element.name} disabled={this.state.onlyView}>
                                    {translatedMessage(element.label)}
                                  </Dropdown.Item>
                                )
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>

                        {this.state.showArtNo &&
                          <Row>
                            <Col className="col-12 pl-0 pr-0">
                              <div className="ca-publish-section-title mt-5">
                                {translatedMessage("ARTWORK.ARTWORKS")}
                              </div>
                              <div className="ca-publish-section-subtitle">
                                {translatedMessage("AR_PORTAL.ART_PLACEHOLDERS")}
                              </div>
                            </Col>
                            <Col className="ca-col-left-nopadding col-12 col-sm-6">
                              <Form.Group controlId="formArArtNo">
                                <Form.Label>
                                  {translatedMessage("AR_PORTAL.AR_ART_NO")}
                                </Form.Label>
                                <Form.Control
                                  name="nrArArt"
                                  required
                                  type="number"
                                  step="1"
                                  min="0"
                                  placeholder={translatedMessage("AR_PORTAL.AR_ART_NO_PLACEHOLDER")}
                                  defaultValue={this.state.virtualGallery.nrArArt}
                                  isInvalid={this.state.virtualGallery.nrArArt !== null && this.state.virtualGallery.nrArArt.length === 0}
                                  onChange={this.handleInputChange}
                                  disabled={this.state.onlyView}
                                />
                                <Form.Control.Feedback type="invalid">{translatedMessage("AR_PORTAL.AR_ART_NO.REQUIRED")}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col className="ca-col-right-nopadding col-12 col-sm-6">
                              <Form.Group controlId="formSculptureNo">
                                <Form.Label>{translatedMessage("AR_PORTAL.SCULPTURE_NO")}</Form.Label>
                                <Form.Control
                                  name="nrSculpture"
                                  required
                                  type="number"
                                  step="1"
                                  min="0"
                                  placeholder={translatedMessage("AR_PORTAL.SCULPTURE_NO_PLACEHOLDER")}
                                  defaultValue={this.state.virtualGallery.nrSculpture}
                                  isInvalid={this.state.virtualGallery.nrSculpture !== null && this.state.virtualGallery.nrSculpture.length === 0}
                                  onChange={this.handleInputChange}
                                  disabled={this.state.onlyView}
                                />
                                <Form.Control.Feedback type="invalid">{translatedMessage("AR_PORTAL.AR_ART_NO.REQUIRED")}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        }
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className="ca-button-icon mr-1" variant="link"
                    onClick={() => this.props.history.push(backUrl)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>{translatedMessage("BUTTON.NEXT")}</span>
                  <Button className="ca-button-icon ml-1" variant="link" form="publishForm" type="submit">
                    <Image src={nextArrow} alt={translatedMessage("BUTTON.NEXT")} />
                  </Button>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }
}

export default ArPortalPublishDetails;