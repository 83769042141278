import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import myCollection from '../../components/shared/Resources/homepage/myCollection.svg';
import myVirtualGallery from '../../components/shared/Resources/homepage/myVirtualGallery.svg';
import myArPortal from '../../components/shared/Resources/homepage/arPortal_disabled.svg';

import { translatedMessage } from '../../services/language.service';

class CollectionHomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };
    }

    componentDidMount = () => {
        this.setState({ isLoaded: true })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <>
                    <div className="ca-page-padding ca-main-container h-100 mt-0 mb-0">
                        <Row className="h-100">
                            <Col className="col-12 col-sm-6 col-md-4 mb-5 d-flex align-items-center">
                                <Link to="/collection" className="ca-dark-link w-100">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={myCollection} className="ca-homepage-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 mb-5 d-flex align-items-center">
                                <Link to="/virtual-galleries" className="ca-dark-link w-100">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={myVirtualGallery} className="ca-homepage-icon" alt={translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_GALLERIES")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 mb-5 d-flex align-items-center">
                                {/* <Link to="#" className="ca-dark-link w-100"> */}
                                <div className="ca-homepage-icon-container disabled">
                                    <Image src={myArPortal} className="ca-homepage-icon" alt={translatedMessage("AR_PORTAL.AR_PORTALS")} />
                                    <div className="ca-homepage-icon-title">
                                        {translatedMessage("AR_PORTAL.AR_PORTALS")}<br />
                                        <span className="ca-homepage-icon-title-soon">{" (" + translatedMessage("GENERAL.COMMING_SOON") + ")"}</span>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </Col>
                        </Row>
                    </div>
                </>
            )
        }
    }
}

export default CollectionHomePage;