import React from 'react'
import '../Map.css';

import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

import LanguageService from '../../../services/language.service';

class GeofenceTableComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      paging: {
        itemsPerPage: 5,
      },
      totalPages: -1,
      loading: this.props.loading,
      pages: this.props.pages,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: window.$translate(LanguageService.getCurrentLanguage(), "TABLE.PAGE"),
      ofText: window.$translate(LanguageService.getCurrentLanguage(), "TABLE.OF"),
      rowsText: window.$translate(LanguageService.getCurrentLanguage(), "TABLE.PAGE")
    };

  }

  componentDidMount() {
  }

  render() {
    const columns = [
      {
        Header: window.$translate(LanguageService.getCurrentLanguage(), "TABLE.HEADER.NAME"),
        accessor: 'name',
      },
      {
        id: 'location',
        Header: window.$translate(LanguageService.getCurrentLanguage(), "MAP.DETAILS"),
        accessor: location =>
          <span>
            <b>{window.$translate(LanguageService.getCurrentLanguage(), "MAP.LATITUDE.SHORT")}</b> {': ' + location.locationLat}
            <br />
            <b>{window.$translate(LanguageService.getCurrentLanguage(), "MAP.LONGITUDE.SHORT")}</b> {': ' + location.locationLng}
            <br />
            <b>{window.$translate(LanguageService.getCurrentLanguage(), "MAP.CIRCLE.RADIUS")}</b> {': ' + location.radius}            
          </span>,
        sortable: false,
      },
      {
        Header: '',
        Cell: row => (
          <span style={{ fontFamily: "FontAwesome", marginRight: "0.5rem", cursor: "pointer" }}
            onClick={() => this.props.show(row.original.id)}>
            &#xF044;
          </span>
        ),
        sortable: false,
      }
    ]

    return (
      <div className="ca-table-geofences">
        <ReactTable
          columns={columns}
          data={this.props.data}
          minRows={0}
          defaultPageSize={this.state.paging.itemsPerPage}
          showPageJump={false}
          loading={this.state.loading}
          showPageSizeOptions={true}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.rowselect(rowInfo.row._original);
                }
              }
            } else {
              return {}
            }
          }}
        />
      </div>
    )
  }

}

export default GeofenceTableComponent