import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import { hasPermission } from '../../services/api.service';

import myCollection from '../../components/shared/Resources/homepage/myCollection.svg';
import myCreations from '../../components/shared/Resources/homepage/myCreations.svg';
import dashboard from '../../components/shared/Resources/homepage/dashboard.svg';
import profile from '../../components/shared/Resources/homepage/profile.svg';
import createArt from '../../components/shared/Resources/homepage/createArt.svg';
import buyArt from '../../components/shared/Resources/homepage/buyArt.svg';
import map from '../../components/shared/Resources/homepage/map.svg';
import exhibition from '../../components/shared/Resources/homepage/exhibition.svg';
import creativeCommunity from '../../components/shared/Resources/homepage/creativeCommunity.svg';
import stayTuned from '../../components/shared/Resources/homepage/filmCamera.svg';
import gift from '../../components/shared/Resources/homepage/gift.svg';
import company from '../../components/shared/Resources/icons/company/company.svg';
import collaborate from '../../components/shared/Resources/icons/company/collaborate.svg';
import space from '../../components/shared/Resources/icons/company/space.svg';
import artist from '../../components/shared/Resources/icons/artist.svg';
import parkOpenCallIcon from '../../components/shared/Resources/icons/artwork.svg';
import artPark from '../../components/shared/Resources/homepage/art_park.svg';
// import question from '../../components/shared/Resources/homepage/question.svg';

import { translatedMessage } from '../../services/language.service';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            canCreate: false,
            canViewMap: false,
            isLoaded: false
        };
    }

    componentDidMount = () => {
        ApiService.initCurrentUser().then(response => {
            let userMembership = ApiService.getCurrentUser().activeUserMembership ? ApiService.getCurrentUser().activeUserMembership : null;
            let membership = userMembership ? userMembership.membership : 'Member';
            let canViewMap = false;

            if (membership.name.toLowerCase() !== 'member') {
                canViewMap = true;
            }

            this.setState({
                canCreate: hasPermission("MENU_CREATE_VIEW"),
                canViewMap: canViewMap,
                isLoaded: true
            })
        });
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            const canCreate = this.state.canCreate;
            const canViewMap = this.state.canViewMap;
            return (
                <>
                    <div className="ca-page-padding ca-main-container">
                        <Row className="mt-5">
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/collection-home" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={myCollection} className="ca-homepage-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_COLLECTION")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/portfolio-home" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={myCreations} className="ca-homepage-icon" alt={translatedMessage("MY_CREATIONS")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("MY_CREATIONS")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to={canCreate ? "/project/create" : "#"} className="ca-dark-link" style={canCreate ? {} : { pointerEvents: "none" }}>
                                    <div className={"ca-homepage-icon-container".concat(canCreate ? "" : " disabled")}>
                                        <Image src={createArt} className={"ca-homepage-icon ".concat(canCreate ? "" : "disabled")} alt={translatedMessage("CREATE_ART")} />
                                        <div className="ca-homepage-icon-title">
                                            {translatedMessage("CREATE_ART")}
                                            {!canCreate &&
                                                <>
                                                    <br />
                                                    <span className="ca-homepage-icon-title-soon">{" (" + translatedMessage("GENERAL.COMMING_SOON") + ")"}</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/dashboard" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={dashboard} className="ca-homepage-icon" alt={translatedMessage("DASHBOARD.MY_DASHBOARD")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("DASHBOARD.MY_DASHBOARD")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/profile/view" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={profile} className="ca-homepage-icon" alt={translatedMessage("NAVBAR.MENU.PROFILE.MY_PROFILE")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("NAVBAR.MENU.PROFILE.MY_PROFILE")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/my-companies" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={company} className="ca-homepage-icon" alt={translatedMessage("NAVBAR.MENU.COMPANY")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("NAVBAR.MENU.COMPANY")}</div>
                                    </div>
                                </Link>
                            </Col>                            
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/gallery" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={buyArt} className="ca-homepage-icon" alt={translatedMessage("GALLERY.GALLERIES")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("BUY_ART")}
                                            <br /><span style={{ fontSize: "16px", fontWeight: "400" }}>({translatedMessage("GALLERY.GALLERIES")})</span>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/exhibition" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={exhibition} className="ca-homepage-icon" alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("EXHIBITION.EXHIBITIONS")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="#" className="ca-dark-link disabled">
                                    <div className="ca-homepage-icon-container disabled">
                                        <Image src={artPark} className="ca-homepage-icon disabled" alt={translatedMessage("PARK.PARKS")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("PARK.PARKS")}
                                            <br />
                                            <span className="ca-homepage-icon-title-soon">{" (" + translatedMessage("GENERAL.COMMING_SOON") + ")"}</span>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to={canViewMap ? "/map" : "#"} className={"ca-dark-link ".concat(canViewMap ? "" : "disabled")}>
                                    <div className={"ca-homepage-icon-container ".concat(canViewMap ? "" : "disabled")}>
                                        <Image src={map} className={"ca-homepage-icon ".concat(canViewMap ? "" : "disabled" )} alt={translatedMessage("MAP.ART_MAP")} />
                                        <div className="ca-homepage-icon-title">
                                            {translatedMessage("MAP.ART_MAP")}
                                            {!canViewMap &&
                                                <>
                                                    <br />
                                                    <span className="ca-homepage-icon-title-soon">{" (" + translatedMessage("GENERAL.COMMING_SOON") + ")"}</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                {/* <Link to="/#" className="ca-dark-link"> */}
                                <div className="ca-homepage-icon-container disabled">
                                    <Image src={creativeCommunity} className="ca-homepage-icon disabled" alt={translatedMessage("COMMUNITY.CREATIVE_COMMUNITY")} />
                                    <div className="ca-homepage-icon-title">
                                        {translatedMessage("COMMUNITY.CREATIVE_COMMUNITY")}<br />
                                        <span className="ca-homepage-icon-title-soon">{" (" + translatedMessage("GENERAL.COMMING_SOON") + ")"}</span>
                                    </div>
                                </div>
                                {/* </Link> */}
                            </Col>                                                  
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <a href="https://www.youtube.com/connectedart" target="_blank" rel="noopener noreferrer" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={stayTuned} className="ca-homepage-icon" alt={translatedMessage("STAY_TUNED")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("STAY_TUNED")}</div>
                                    </div>
                                </a>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/company/spaces" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={space} className="ca-homepage-icon" alt={translatedMessage("COMPANY.SPACES")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("COMPANY.SPACES")}</div>
                                    </div>
                                </Link>
                            </Col> 
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/artists" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={artist} className="ca-homepage-icon" alt={translatedMessage("USER.ARTISTS")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("USER.ARTISTS")}</div>
                                    </div>
                                </Link>
                            </Col>                                                        
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/collaboration/companies" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={collaborate} className="ca-homepage-icon" alt={translatedMessage("COMPANY.COLLABORATION")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("COMPANY.COLLABORATION")}</div>
                                    </div>
                                </Link>
                            </Col>                                                            
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/gift" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={gift} className="ca-homepage-icon" alt={translatedMessage("GIFT.GIFTS")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("GIFT.GIFTS")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                                <Link to="/park/apply" className="ca-dark-link">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={parkOpenCallIcon} className="ca-homepage-icon" alt={translatedMessage("PARK.APPLICATION.CALL.TITLE")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("PARK.APPLICATION.CALL.TITLE")}</div>
                                    </div>
                                </Link>
                            </Col>                          
                        </Row>
                    </div>
                    <div className="ca-page-padding" style={{ textAlign: "right", width: "100%" }}>
                        {/* <Link to="/gift" className="ca-dark-link">
                            <Image src={gift} style={{ width: "50px" }} alt={translatedMessage("GIFT.GIFTS")} />
                        </Link> */}
                        {/* <Link to="#" className="ca-dark-link ml-2">
                            <Image src={question} style={{ width: "50px" }} alt="FAQ" />
                        </Link> */}
                    </div>
                </>
            )
        }
    }
}

export default HomePage;
