import React from 'react';

import ParkManageArtworkComponent from '../../../components/shared/Park/ParkManageArtworkComponent';
import ApiService, { hasPermission } from '../../../services/api.service';

class ParkAdminArtworkPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.match.params.id,
      isLoaded: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(async () => {
      this.setState({
        canViewList: hasPermission("PARK_LIST"),
        canEdit: hasPermission("PARK_EDIT"),
        isLoaded: true
      })
    });
  }


  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <ParkManageArtworkComponent
          history={this.props.history}
          entityUrl="parkAdmin"
          canViewList={this.state?.canViewList}
          canEdit={this.state?.canEdit}
          parkId={this.state?.parkId}
          isAdmin={true}
          mapUrl={`/park/${this.state.parkId}/map`}
          triggerUrl={`artTrigger`}
        />
      )
    }
  }
}

export default ParkAdminArtworkPage;