import React from 'react';

import { Card } from 'react-bootstrap';
import { translatedMessage } from '../../../services/language.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      editModal: false,
      deleteModal: false,
      editModalData: {},
      deleteModalData: {}
    };

  }

  render() {
    return (
      <div>
        <Card className="ca-card ca-add-on-card" bg={this.props.variant} text={this.props.variant.toLowerCase() === 'light' ? 'dark' : 'white'}>
          {this.props.showThumbnail &&
            <Card.Img variant="top" src={this.props.thumbnail} />
          }
          <Card.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Card.Title>{this.props.data.name}</Card.Title>
            <Card.Subtitle>{this.props.artworkAuthor}</Card.Subtitle>
            {this.props.showIsMain &&
              <Card.Text className="ca-muted-text">{translatedMessage("ADD_ON.MAIN_EXPERIENCE")}:&nbsp;
                        {this.props.data.isMain ? translatedMessage("GENERAL.YES") : translatedMessage("GENERAL.NO")}
              </Card.Text>
            }
            <Card.Text className="ca-text-justify">{this.props.data.description}</Card.Text>
            {/* <Card.Text>
              {translatedMessage("ADD_ON.INCLUDED_IN")}&nbsp;
                                      {this.state.data.includedIn.length === 6
                ? translatedMessage("GENERAL.ALL").toLowerCase()
                : this.state.data.includedIn.map((e, index) => {
                  if (index !== this.state.data.includedIn.length - 1) {
                    return e + ", "
                  } else {
                    return e
                  }
                })} {translatedMessage("ARTWORK.PACKAGES").toLowerCase()}
            </Card.Text> */}
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default CardComponent;
