import React from 'react';

import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { translatedMessage } from '../../../services/language.service';

class ArtworkCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (event) => {
    if (this.props.status !== 'GIFT_PENDING') {
      const id = event.currentTarget.id;
      if (window.confirm(translatedMessage("ARTWORK.SEND_GIFT.CONFIRM"))) {
        this.props.onCheck(id);
      }
    }
  }

  render() {
    var isPending = this.props.status === 'GIFT_PENDING';
    return (
      <Card className="ca-artwork-card">
        <Link to={this.props.cardLink} className="ca-dark-link">
          <Card.Img variant="top" src={this.props.cardImage} />
          <Card.Body>
            <Card.Title>
              {this.props.cardTitle}
              {this.props.cardArtworkAuthor &&
                <span className="font-weight-normal">
                  &nbsp;{translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                  {this.props.cardArtworkAuthor}
                </span>
              }
            </Card.Title>
            <Card.Text>{translatedMessage("GENERAL.CATEGORY") + ": " + translatedMessage("PROJECT.CATEGORY." + this.props.cardCategory)}</Card.Text>
            {this.props.cardType &&
              <Card.Text>{translatedMessage("GENERAL.TYPE") + ": " + translatedMessage(this.props.cardType)}</Card.Text>
            }
            {this.props.cardPackage &&
              <Card.Text>{translatedMessage("ARTWORK.PACKAGE") + ": " + this.props.cardPackage}</Card.Text>
            }
          </Card.Body>
        </Link>
        {this.props.canBeGift &&
          <Card.Footer>
            {!isPending &&
              <div id={this.props.soldArtworkId} className="ca-footer-link" onClick={this.handleChange}>
                <span className="mr-1" style={{ fontFamily: "FontAwesome", fontSize: "18px" }}>&#xf06b;</span>
                <span>{translatedMessage("ARTWORK.SEND_GIFT")}</span>
                <OverlayTrigger
                  key='nT'
                  placement='right'
                  overlay={
                    <Tooltip id={`tooltip-nt`}>
                      {translatedMessage("ARTWORK.SEND_GIFT.TOOLTIP")}
                    </Tooltip>
                  }
                >
                  <span className="ca-question-icon"></span>
                </OverlayTrigger>
              </div>
            }
            {isPending &&
              <div className="ca-check-container">
                <input
                  id={this.props.soldArtworkId}
                  checked={isPending}
                  type="checkbox"
                  className="d-flex align-items-center"
                  onChange={this.handleChange}
                />
                <span className="ca-check-label">{translatedMessage("ARTWORK.SEND_GIFT.PENDING")}</span>
                <span className="ca-checkmark ca-checkmark-disabled"></span>
              </div>
            }
          </Card.Footer>
        }
      </Card>
    )
  }

}

export default ArtworkCardComponent;