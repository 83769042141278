import React from 'react';
import './PublishModalComponent.css';

import { translatedMessage } from '../../../../services/language.service';

import { Modal, Button, Form } from 'react-bootstrap';

class PublishModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            termsAccepted: false,
        };
    }

    render() {
        return (
            <>
                <Modal
                    {...this.props}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    className="ca-modal ca-artwork-publish-modal"
                >
                    <Modal.Header className="empty-modal-header"></Modal.Header>
                    <div id="contained-modal-title-vcenter" className="ca-modal-title text-center">
                        {translatedMessage(this.props.pagetitle)}
                    </div>
                    <Modal.Body>
                        <div className="w-100 description text-center mb-3">{translatedMessage(this.props.pageinfo)}</div>
                        <div className="w-100 text-center">
                            <div className="wrapper" onClick={() => this.setState({ termsAccepted: !this.state.termsAccepted })}>
                                <Form.Check
                                    readOnly
                                    checked={this.state.termsAccepted}
                                    type="checkbox"
                                    label={translatedMessage("PROJECT.TOU")}
                                />
                            </div>
                            <a href="https://www.connected.art/tc/tou.php" target="_blank" rel="noopener noreferrer">
                                {translatedMessage("TERMS.TOU")}
                            </a>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-around">
                        <Button disabled={!this.state.termsAccepted} className="ca-button ca-button-green mr-1" variant="dark" style={{ width: "8rem" }} onClick={() => this.props.onHide(this.state.termsAccepted)}>
                            {translatedMessage("ARTWORK.PUBLISH.YES")}
                        </Button>
                        <Button variant="dark" className="ca-button" onClick={() => this.props.onHide()}>
                            {translatedMessage("ARTWORK.PUBLISH.NO")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

export default PublishModalComponent;