import React from 'react';

import { Row, Col, Form, InputGroup, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import artistIcon from '../../components/shared/Resources/icons/artist.svg';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

import { translatedMessage } from '../../services/language.service';
import UserService from '../../services/user.service';

const ENTER_KEY = 13;

class ArtistListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceList: [],
      filteredList: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      searchInput: '',
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => { this.getArtists() }

  getArtists = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    UserService.getArtistList(1)
      .then(response => {
        this.setState({
          spaceList: response,
          filteredList: response,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  filter = () => {
    let result = this.state.spaceList;
    let searchInput = this.state.searchInput;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.spaceList.filter(elem =>
        elem.displayName.toLowerCase().includes(searchInput.toLowerCase()));
    }

    this.setState({
      filteredList: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-search-row mb-4 pr-2 align-items-center">
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={artistIcon} className="ca-page-title-icon" alt={translatedMessage("USER.ARTISTS")} />
              <div className="ca-page-title">{translatedMessage("USER.ARTISTS")}</div>
            </div>
            <div className='ca-page-title-search-row-search'>
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder={translatedMessage("SEARCH.BY.NAME")} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Row>

          <Row className="mt-5">
            {this.state.filteredList.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/profile/${element.userId}/view`} className="ca-dark-link">
                    <div className="ca-profile-img-item-container">
                      <Image src={element.profilePic} className="profile-pic ca-profile-photo" alt="Profile picture" />
                      <div className="title">{element.displayName}</div>
                    </div>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ArtistListPage;