import React from 'react';
import { Link } from 'react-router-dom';

import { Card, Image } from 'react-bootstrap';
import { translatedMessage } from '../../../../services/language.service';

import editIcon from '../../../../components/shared/Resources/icons/edit.svg';
import mediaIcon from '../../../../components/shared/Resources/icons/media.svg';
import artIcon from '../../../../components/shared/Resources/icons/art.svg';
import mapPinIcon from '../../../../components/shared/Resources/icons/map_pin.svg';
import virtualGalleryIcon from '../../../../components/shared/Resources/icons/virtualGallery.svg';

class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Card className="ca-gallery-card">
                    <Link className="ca-dark-link" to={`/my-company/${this.props.companyId}/exhibition/${this.props.id}`} title={translatedMessage("GENERAL.VIEW")}>
                        <Card.Img variant="top" src={this.props.cardImage} />
                    </Link>
                    <Card.Body>
                        <Card.Title>{this.props.cardTitle}</Card.Title>
                        <Card.Text>
                            <b>{translatedMessage("GENERAL.STATUS")}: </b>
                            {translatedMessage(this.props.cardDetails.status.label)}
                        </Card.Text>
                        <footer className="ca-gallery-card-footer">
                            <Link className="ca-dark-link small" to={`/my-company/${this.props.companyId}/exhibition-edit/${this.props.id}`} title={translatedMessage("EXHIBITION.EDIT")}>
                                <Image src={editIcon} alt={translatedMessage("EXHIBITION.EDIT")}/>
                            </Link>
                            <Link className="ca-dark-link small ml-1" to={`/my-company/${this.props.companyId}/exhibition-media/${this.props.id}`} title={translatedMessage("EXHIBITION.MEDIA_FILES")}>
                                <Image src={mediaIcon} alt={translatedMessage("EXHIBITION.MEDIA_FILES")}/>
                            </Link>                                                        
                            <Link className="ca-dark-link small ml-1" to={`/my-company/${this.props.companyId}/exhibition-artwork/${this.props.id}`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                                <Image src={artIcon} alt={translatedMessage("GENERAL.ARTWORKS.MANAGE")}/>
                            </Link>
                            <Link className="ca-dark-link small ml-1" to={`/my-company/${this.props.companyId}/exhibition-virtual-gallery/${this.props.id}`} title={translatedMessage("VIRTUAL_GALLERY.MANAGE")}>
                                <Image src={virtualGalleryIcon} alt={translatedMessage("VIRTUAL_GALLERY.MANAGE")}/>
                            </Link>
                            <Link className="ca-dark-link small ml-1" to={`/my-company/${this.props.companyId}/exhibition-location/${this.props.id}`} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                                <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")}/>
                            </Link>                                                        
                        </footer>
                    </Card.Body>
                </Card>
            </>
        )
    }
}

export default CardComponent;