import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, Form, Image } from 'react-bootstrap/'
import Select from 'react-select';
import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import LanguageService from '../../../services/language.service';

import { toast } from "react-toastify";

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import arArtIcon from '../../../components/shared/Resources/arArtWhite.png';
import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';

import SizeChangeModalPage from '../Components/SizeChangeModalPage';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import moment from 'moment';
import ChipInput from 'material-ui-chip-input'
import CompanyService from '../../../services/company.service';
import ArtworkService from '../../../services/artwork.service';

class ArArtPublishArtworkDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.companyId ? this.props.match.params.companyId : null,
      project: {},
      artwork: {},
      artworkImage: [],
      artworkImageUrl: '',
      artworkTags: [],
      artworkTagObjects: [],
      isLoaded: false,
      isError: false,
      errorMessage: '',
      editorButtons: ApiService.getSunEditorButtons(),
      editorNoButtons: ApiService.getSunEditorNoButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      prevWidth: 0,
      prevHeight: 0,
      sizeChangeModalShow: false,
      artistList: [],
      selectedArtist: null,
      onlyView: false
    };

    this._isMounted = false;

    this.onAddTag = this.onAddTag.bind(this);
    this.onRemoveTag = this.onRemoveTag.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

  async populateData() {
    let isError = false;
    let errorMessage = "PROJECT.GENERAL_ERROR";

    let artwork = await ApiService.getAuthenticatedInstance().get(`/artwork/existing/${this.props.match.params.id}`)
      .then(result => { return result.data })
      .catch(err => {
        isError = true
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
      });

    if (!isError) {
      let tags = await ApiService.getAuthenticatedInstance().get(`/artworks/${artwork.id}/artworkTags`)
        .then(result => { return result.data._embedded.artworkTags; });

      let artistList = [];
      if (artwork.companyId) {
        artistList = await CompanyService.getCompanyArtists(artwork.companyId)
      }

      let artists = artistList.map(item => {
        let artist = {};
        artist.value = item.artist.id;
        artist.label = item.artist.fullName;

        return artist;
      })

      this.setState({
        artwork: artwork,
        prevWidth: artwork.sizeWidth,
        prevHeight: artwork.sizeHeight,
        artworkTags: tags.map(artworkTag => artworkTag.additional.tag),
        artworkTagObjects: tags,
        artistList: artists,
        selectedArtist: artwork.authorList
          ? {
            value: artwork.authorList[0].userId,
            label: artwork.authorList[0].name
          } : null,
        onlyView: artwork.status.name === ArtworkService.artworkStatusEnum().DRAFT || artwork.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
        isLoaded: true
      });
    } else {
      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      });
    }
  }

  onAddTag(chip) {
    this.setState({
      artworkTags: this.state.artworkTags.concat([chip]),
    }, async () => await ArtworkService.artworkAddTag(chip, this.state.artwork.id, this.state.artworkTagObjects)
      .then(response => {
        this.setState({
          artworkTagObjects: response,
        });

        toast.success(translatedMessage("PROJECT.TAG_ADDED"));
      }));
  }

  onRemoveTag(chip) {
    let newArtworkTags = this.state.artworkTags;
    newArtworkTags.splice(newArtworkTags.indexOf(chip), 1);
    this.setState({
      artworkTags: newArtworkTags,
    }, async () => await ArtworkService.artworkRemoveTag(chip, this.state.artworkTagObjects)
      .then(response => {
        this.setState({
          artworkTagObjects: response,
        })
      }));
  }

  handleInputChange = (event) => {
    if (!event.target) {
      return;
    }

    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      artwork: {
        ...this.state.artwork,
        [name]: value
      }
    });
  }

  onChangeEditor = (e) => {
    this.setState({
      artwork: {
        ...this.state.artwork,
        description: e,
      }
    })
  }

  onHideSizeChangeModal = (confirm) => {
    if (confirm && confirm === true) {
      this.saveArtwork()
    }
    this.setState({
      sizeChangeModalShow: false,
    }, this.props.history.push(`/project/publish/ar-art-experience/${this.props.match.params.id}`))
  }

  handleArtistChange = (eventKey) => {
    this.setState({
      selectedArtist: eventKey
    })
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.onlyView) {
      let sizeWidth = Number(this.state.artwork.sizeWidth);
      let sizeHeight = Number(this.state.artwork.sizeHeight);

      let differentSize = false;
      let isError = false;
      let errorMessage = "";

      if (sizeWidth === 0 || sizeHeight === 0) {
        isError = true;
        errorMessage = "ARTWORK.SIZE_ZERO.ERROR";
      }

      // if (!this.state.selectedArtist) {
      //   isError = true;
      //   errorMessage = "ARTWORK.ARTIST.SELECT.EMPTY_ERROR";
      // }

      if (this.state.prevWidth && this.state.prevHeight && (this.state.prevWidth !== sizeWidth || this.state.prevHeight !== sizeHeight)) {
        differentSize = true;
      }

      if (isError) {
        toast.error(translatedMessage(errorMessage));
      } else {
        if (differentSize) {
          this.setState({
            sizeChangeModalShow: true
          })
        } else {
          this.saveArtwork();
        }
      }
    } else {
      this.handleNext();
    }
  }

  saveArtwork = () => {
    ApiService.getAuthenticatedInstance().patch(`/artworks/${this.state.artwork.id}`, {
      name: this.state.artwork.name,
      description: this.state.artwork.description,
      sizeWidth: this.state.artwork.sizeWidth,
      sizeHeight: this.state.artwork.sizeHeight,
      lastUpdatedBy: ApiService.getCurrentUser().username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    }).then(() => {
      if (this.state.artwork.companyId) {
        this.saveArtist()
      } else {
        this.handleNext();
      }
    });
  }

  async saveArtist() {
    if (this.state.selectedArtist) {
      await ArtworkService.saveArtist(this.state.artwork.id, this.state.selectedArtist.value)
        .then(() => this.handleNext())
        .catch(errMsg => { toast.error(translatedMessage(errMsg)) })
    } else if (this.state.artwork.authorList) {
      await ArtworkService.removeArtist(this.state.artwork.id)
        .then(() => this.handleNext())
        .catch(errMsg => { toast.error(translatedMessage(errMsg)) })
    } else {
      this.handleNext();
    }
  }

  handleNext = () => {
    this.props.history.push(`/project/publish/ar-art-experience/${this.props.match.params.id}`);
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        const backUrl = this.state.artwork.companyId ? `/my-company/${this.state.artwork.companyId}/portfolio` : `/projects`;

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={arArtIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_AR_ART")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("ARTWORK.DETAILS")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={backUrl} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>
                </div>

                <Form id="publishForm" onSubmit={this.onSubmit}>
                  <div className="ca-page-padding ca-main-container">
                    <Row>
                      <Col className="col-12 col-lg-6">
                        <div className="ca-publish-section-title">
                          {translatedMessage("ARTWORK.DETAILS")}
                        </div>
                        <div className="ca-publish-section-subtitle">
                          {translatedMessage("ARTWORK.DETAILS.DESCRIPTION")}
                        </div>

                        <Form.Group controlId="formArtworkName">
                          <Form.Label>{translatedMessage("PROJECT.ARTWORK_NAME")} *</Form.Label>
                          <Form.Control
                            required
                            placeholder={translatedMessage("PROJECT.ARTWORK_NAME_PLACEHOLDER")}
                            name="name"
                            defaultValue={this.state.artwork.name}
                            isInvalid={this.state.artwork.name.trim().length === 0}
                            onChange={this.handleInputChange}
                            disabled={this.state.onlyView}
                          />
                          <Form.Control.Feedback type="invalid">{translatedMessage("ARTWORK.NAME.REQUIRED")}</Form.Control.Feedback>
                        </Form.Group>

                        {this.state.artwork.companyId &&
                          <Form.Group>
                            <Form.Label>{translatedMessage("GENERAL.ARTIST")}</Form.Label>
                            <Select
                              className="basic-single ca-autocomplete"
                              classNamePrefix="select"
                              isDisabled={this.state.onlyView}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="artists"
                              options={this.state.artistList}
                              value={this.state.selectedArtist}
                              onChange={this.handleArtistChange}
                            />
                          </Form.Group>
                        }

                        <Form.Group controlId="formArtworkDescription">
                          <Form.Label>{translatedMessage("PROJECT.DESCRIBE_ARTWORK")}</Form.Label>
                          <div className="ca-text-editor">
                            <SunEditor
                              name="description"
                              lang={LanguageService.getCurrentLanguage()}
                              placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                              setContents={this.state.artwork.description}
                              setOptions={{
                                height: 150,
                                maxWidth: "100%",
                                buttonList: this.state.onlyView ? this.state.editorNoButtons : this.state.editorButtons,
                                font: this.state.editorFonts,
                                defaultStyle: this.state.editorDefaultStyle
                              }}
                              onChange={this.onChangeEditor}
                              disable={this.state.onlyView}
                              disableToolbar={this.state.onlyView}
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col className="col-12 col-lg-6">
                        <div className="ca-publish-section-container mb-4">
                          <span className="ca-publish-section-title">
                            {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                          </span>
                          <span className="ca-publish-section-subtitle">
                            {translatedMessage("ARTWORK.ARTWORK_SIZE.DESCRIPTION")}
                          </span>

                          <Form.Group>
                            <Form.Label>{translatedMessage("ARTWORK.WIDTH")} *</Form.Label>
                            <Form.Control
                              type="number"
                              step="0.01"
                              placeholder={translatedMessage("ARTWORK.WIDTH_PLACEHOLDER")}
                              name="sizeWidth"
                              defaultValue={this.state.artwork.sizeWidth}
                              required
                              isInvalid={this.state.artwork.sizeWidth !== null && this.state.artwork.sizeWidth.length === 0}
                              onChange={this.handleInputChange}
                              disabled={this.state.onlyView}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>{translatedMessage("ARTWORK.HEIGHT")} *</Form.Label>
                            <Form.Control
                              type="number"
                              step="0.01"
                              placeholder={translatedMessage("ARTWORK.HEIGHT_PLACEHOLDER")}
                              name="sizeHeight"
                              defaultValue={this.state.artwork.sizeHeight}
                              required
                              isInvalid={this.state.artwork.sizeHeight !== null && this.state.artwork.sizeHeight.length === 0}
                              onChange={this.handleInputChange}
                              disabled={this.state.onlyView}
                            />
                          </Form.Group>
                        </div>

                        <div className="ca-publish-section-container mb-5">
                          <span className="ca-publish-section-title">
                            {translatedMessage("ARTWORK.TAGS")}
                          </span>
                          <span className="ca-publish-section-subtitle">
                            {translatedMessage("ARTWORK.TAGS.DESCRIPTION")}
                          </span>
                          <ChipInput
                            value={this.state.artworkTags}
                            onAdd={this.onAddTag}
                            onDelete={this.onRemoveTag}
                            disabled={this.state.onlyView}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className="ca-button-icon mr-1" variant="link"
                    onClick={() => this.props.history.push(backUrl)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>{translatedMessage("BUTTON.NEXT")}</span>
                  <Button className="ca-button-icon ml-1" variant="link" form="publishForm" type="submit">
                    <Image src={nextArrow} alt={translatedMessage("BUTTON.NEXT")} />
                  </Button>
                </div>
              </div>
            </div>

            <SizeChangeModalPage
              show={this.state.sizeChangeModalShow}
              onHide={this.onHideSizeChangeModal}
              message={"ARTWORK.AR_ART.SIZE_CHANGED.DESCRIPTION"}
            />
          </>
        )
      }
    }
  }
}

export default ArArtPublishArtworkDetails;