import React from 'react';

import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translatedMessage } from '../../../../services/language.service';

import mapPinIcon from '../../../../components/shared/Resources/icons/map_pin.svg';
import triggerIcon from '../../../../components/shared/Resources/icons/trigger.svg';

class ExhibitionARECardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      checked: this.props.existingAR,
    })
  }

  handleChange = (event) => {
    const input = event.currentTarget.querySelector('input')
    this.setState({
      checked: !input.checked
    })
    this.props.onCheck(input.id, !input.checked);
  }

  render() {
    return (
      <Card className="ca-artwork-card">
        <Card.Img variant="top" src={this.props.imageUrl} />
        <Card.Body>
          <Card.Title>
            {this.props.entity.name}
          </Card.Title>
          <Card.Text>{translatedMessage("GENERAL.CATEGORY") + ": " +
            translatedMessage(this.props.entity.category)}</Card.Text>
          {!this.props.isAdd &&
            <div>
              <div className="ca-check-container mt-2">
                <input
                  checked={true}
                  readOnly
                  type="checkbox"
                  className="d-flex align-items-center"
                />
                <span className="ca-check-label">{translatedMessage("GENERAL.FREE_FOR_EVENT")}</span>
                <span className="ca-checkmark"></span>
              </div>
            </div>
          }
          {!this.props.isAdd &&
            <footer className="ca-gallery-card-footer">
              {this.props.canHaveLocation &&
                <Link className="ca-dark-link small" to={`/exhibitionAdminArExperienceLocation/${this.props.exhibitionARId}`} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                    <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")}/>
                </Link>
              }
              {this.props.entity.category === "3D_OBJECT" &&
                <Link className="ca-dark-link small ml-1" to={`/exhibitionAdminArExperienceTrigger/${this.props.exhibitionARId}`} title={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}>
                  <Image src={triggerIcon} alt={translatedMessage("TRIGGER.MANAGE_TRIGGERS")}/>
                </Link>
              }
            </footer>
          }
        </Card.Body>
        {this.props.isAdd &&
          <Card.Footer>
            <div className="ca-check-container mt-2" onClick={this.handleChange}>
              <input
                id={this.props.entity.id}
                checked={this.state.checked}
                type="checkbox"
                className="d-flex align-items-center"
                onChange={this.handleChange}
              />
              <span className="ca-check-label">{translatedMessage("AR_EXPERIENCE.SELECT")}</span>
              <span className="ca-checkmark"></span>
            </div>
          </Card.Footer>
        }
      </Card>
    )
  }
}

export default ExhibitionARECardComponent;