import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';
import parkIcon from '../../../components/shared/Resources/homepage/art_park.svg';

import Carousel from "../Carousel/MediaCarouselComponent";
import AddFileModalComponent from '../AddFileModalComponent/AddFileModalComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import ApiService from '../../../services/api.service';
import FileService from '../../../services/file.service';
import { translatedMessage } from '../../../services/language.service';
import ParkService from '../../../services/park.service';

class ParkManageArtTriggerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkArt: {},
      exhibition: {},
      artwork: {},
      storageItem: null,
      isLoaded: false,
      triggerFiles: [],
      showAddFileModal: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      backUrl: ""
    };
  }

  componentDidMount = () => { this.getData() }

  getData = () => {
    ParkService.getParkArt(this.props.id)
      .then(response => {
        // console.log("park art: ", response)

        let art = {}
        let backUrl = this.props.backUrl
        if(response.artwork) {
          art = response.artwork
          backUrl += "/artwork"
        } else if(response.arExperience) {
          art = response.arExperience
          backUrl += "/arExperience"
        } else if(response.virtualGallery) {
          art = response.virtualGallery
          backUrl += "/virtualGallery"
        }

        this.setState({
          parkArt: {
            ...response,
            storageItem: {
              id: response.storageItemId
            }
          },
          park: response.park,
          artwork: art,
          storageItem: {
            id: response.storageItemId
          },
          triggerFiles: FileService.handleFilesForCarousel(response.fileList ? response.fileList : [], FileService.getFileUsage().TRIGGER),
          backUrl: backUrl, 
          isLoaded: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async getStorageItems() {
    const storageFiles = await ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.parkArt.id}/${ApiService.getEntityName().PARK_ART}`)
      .then(results => { return results.data.fileList; })

    var triggerFiles = FileService.handleFilesForCarousel(storageFiles, FileService.getFileUsage().TRIGGER);

    this.setState({
      triggerFiles: triggerFiles,
      isLoaded: true
    });
  }

  handleMediaListChange = () => {
    this.getStorageItems();
  }

  onShowAddFile = () => {
    const modalData = {
      modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
      fileUsage: FileService.getFileUsage().TRIGGER,
      fileType: FileService.getResourceType().IMAGE,
      deletePrevious: false,
      deletedImage: null,
      deletePreviousDesc: "",
      fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
      maxSize: 1,
      maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
      multipleFiles: true,
      multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
      entity: this.state.parkArt,
      storageitemlinked: true
    }
    this.setState({
      showAddFileModal: true,
      fileModalData: modalData
    })
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getStorageItems();
    }
    this.setState({
      showAddFileModal: false,
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={parkIcon} className="ca-page-title-icon" alt={translatedMessage("PARK.PARK")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.park.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{this.state.artwork?.name} - {translatedMessage("TRIGGER.MANAGE_TRIGGERS")}</div>
              </div>
            </div>
            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={() => this.props.history.push(`${this.state.backUrl}`)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>
          <Row className='mb-3'>
            <div className="pr-2 d-flex align-items-center">
              <span className="ca-page-subtitle">{translatedMessage("TRIGGER.TRIGGER_IMAGES")}</span>
              <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />
              </Button>
            </div>
          </Row>
          <Row>
            <Col className="col-12 col-md-6 pl-0 pr-0">
              {this.state.triggerFiles?.length > 0 &&
                <Carousel
                  id="trigger"
                  items={this.state.triggerFiles}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={true}
                  toolbarDelete={true}
                  toolbarYoutubeId={false}
                  canDeleteLast={true}
                  onChange={this.handleMediaListChange}
                />
              }
            </Col>
          </Row>

          <AddFileModalComponent
            show={this.state.showAddFileModal}
            onHide={this.onHideAddFile}
            data={this.state.fileModalData}
          />
        </div >
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ParkManageArtTriggerComponent;