import React from 'react';
import '../Styles/ProjectPublishPage.css';

import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import arPortalIcon from '../../../components/shared/Resources/arPortalWhtite.png';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import ArtPublishPricePackage from '../Components/ArtPublishPricePackageComponent';

class ArPortalPublishPricePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project: {},
            artwork: null,
            pricePackages: [],
            defaultPackagePrice: 0,
            pricePackagesTableData: [],
            isLoaded: false,
            canChangePackagePrice: false,
            isError: false,
            errorMessage: '',
            showModal: false,
            modalData: {
                name: '',
                price: 0,
                nrCopies: 0,
                hasSignature: false,
                hasInvestorPackage: false,
                hasDigitalPrint: false,
                digitalPrintQuantity: 0
            },
            printImages: [],
            hasDigitalPrint: false,
            digitalPrintQuantity: 0,
            showAddPrintFileModal: false
        };
    }

    componentDidMount() {
        let user = ApiService.getCurrentUser();
        if (!user) {
            ApiService.initCurrentUser()
                .then(response => {
                    this.setState({
                        user: response,
                        isLoaded: true
                    })
                })
        } else {
            this.setState({
                user: user,
                isLoaded: true
            })
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                return (
                    <>
                        <ArtPublishPricePackage
                            artId={this.props.match.params.id}
                            artType={ApiService.getEntityName().VIRTUAL_GALLERY}
                            artCategory={ArtworkService.artworkCategoryEnum().AR_PORTAL}
                            forProject={true}
                            history={this.props.history}
                            backUrl={`/project/publish/ar-portal-art/${this.props.match.params.id}`}
                            nextUrl={`/project/publish/ar-portal-preview/${this.props.match.params.id}`}
                            pageTitle={"PROJECT.CREATE_AR_PORTAL"}
                            icon={arPortalIcon}
                        />
                    </>
                )
            }
        }
    }
}

export default ArPortalPublishPricePackage;