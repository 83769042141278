import React from 'react';
import './Styles/ProjectCreatePage.css';

import { Row, Col, Card, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { translatedMessage } from '../../services/language.service';
import ApiService from '../../services/api.service';
import { hasPermission } from '../../services/api.service';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

import arArtIcon from '../../components/shared/Resources/icons/ar_art_white.svg';
import sculptureIcon from '../../components/shared/Resources/icons/sculpture_white.svg';
import arPortalIcon from '../../components/shared/Resources/icons/arPortal_white.svg';
import holoIcon from '../../components/shared/Resources/icons/holographic_fan_white.svg';
import backIcon from '../../components/shared/Resources/icons/back.svg';
import createArtIcon from '../../components/shared/Resources/homepage/createArt.svg';
import digitalPicture from '../../components/shared/Resources/icons/digital_picture_white.svg';


class ProjectCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId ? this.props.match.params.companyId : null,
            pathPrefix: this.props.match.params.companyId ? `/my-company/${this.props.match.params.companyId}` : "",
            isLoaded: false,
            canCreateProject: false,
            isError: false,
            errorMessage: "",
            arArtLink: "/project/create/ar-art",
            objectLink: "/project/create/object",
            arPortalLink: "/project/create/ar-portal",
            digitalPictureLink: "/project/create/digital-picture",
        };
    }

    componentDidMount() {
        if (this.state.companyId) {
            let errorMessage = "GENERAL.GET_DATA_ERROR";

            ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
                .then(response => {
                    this.setState({
                        company: response.data.company,
                        arArtLink: `/my-company/${this.state.companyId}/project/create/ar-art`,
                        objectLink: `/my-company/${this.state.companyId}/project/create/object`,
                        arPortalLink: `/my-company/${this.state.companyId}/project/create/ar-portal`,
                        digitalPictureLink: `/my-company/${this.state.companyId}/project/create/digital-picture`,
                        isLoaded: true
                    })
                })
                .catch(err => {
                    console.log(err)
                    if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
                        errorMessage = err.response.data.message;
                    }

                    this.setState({
                        isError: true,
                        errorMessage: errorMessage,
                        isLoaded: true
                    })
                });
        } else {
            if (!ApiService.getCurrentUser()) {
                ApiService.getAuthenticatedInstance().get('/user/me')
                    .then(result => {
                        ApiService.setCurrentUser(result.data);
                        this.setState({
                            isError: !hasPermission("MENU_CREATE_VIEW"),
                            errorMessage: !hasPermission("MENU_CREATE_VIEW") ? "USER.SECURITY.MISSING_RIGHTS" : "",
                            isLoaded: true
                        })
                    })
            } else {
                this.setState({
                    isError: !hasPermission("MENU_CREATE_VIEW"),
                    errorMessage: !hasPermission("MENU_CREATE_VIEW") ? "USER.SECURITY.MISSING_RIGHTS" : "",
                    isLoaded: true
                })
            }
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else if (!this.state.isError) {
            return (
                <>
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
                            <div className="d-flex align-items-center">
                                <Image src={createArtIcon} className="ca-page-title-icon" alt={translatedMessage("CREATE_ART")} />
                                <span className="ca-page-title">{translatedMessage("PROJECT.CREATE.TITLE")}</span>
                            </div>
                            {this.state.companyId &&
                                <div>
                                    <Link to={`/my-company/${this.state.companyId}/home`} className="ca-dark-link">
                                        <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                                            title={translatedMessage("BUTTON.BACK")} />
                                    </Link>
                                </div>
                            }
                        </Row>

                        <Row>
                            <Col className="col-12 col-sm-6 col-lg-3 pl-2 pr-2">
                                <Link to={this.state.arArtLink}>
                                    <Card className="create-project-card">
                                        <Card.Img variant="top" src={arArtIcon} />
                                        <Card.Body>
                                            <Card.Title className="mb-1">{translatedMessage("AR_ART")}</Card.Title>
                                            <Card.Text>
                                                {translatedMessage("PROJECT.CREATE_AR_ART.DESCRIPTION")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Body style={{ padding: "5px 10px" }}>&nbsp;</Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Link>
                            </Col>

                            <Col className="col-12 col-sm-6 col-lg-3 pl-2 pr-2">
                                <Link to={this.state.objectLink}>
                                    <Card className="create-project-card">
                                        <Card.Img variant="top" src={sculptureIcon} />
                                        <Card.Body>
                                            <Card.Title className="mb-1">{translatedMessage("3D_OBJECT")}</Card.Title>
                                            <Card.Text>
                                                {translatedMessage("PROJECT.CREATE_3D_OBJECT.DESCRIPTION")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Body style={{ padding: "5px 10px" }}>&nbsp;</Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Link>
                            </Col>

                            <Col className="col-12 col-sm-6 col-lg-3 pl-2 pr-2">
                                <Link to={this.state.arPortalLink}>
                                    <Card className="create-project-card">
                                        <Card.Img variant="top" src={arPortalIcon} />
                                        <Card.Body>
                                            <Card.Title className="mb-1">{translatedMessage("AR_PORTAL")}</Card.Title>
                                            <Card.Text>
                                                {translatedMessage("PROJECT.CREATE_AR_PORTAL.DESCRIPTION")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Body style={{ padding: "5px 10px" }}>&nbsp;</Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Link>
                            </Col>

                            <Col className="col-12 col-sm-6 col-lg-3 pl-2 pr-2">
                                <Link to={this.state.digitalPictureLink}>
                                    <Card className="create-project-card">
                                        <Card.Img variant="top" src={digitalPicture} />
                                        <Card.Body>
                                            <Card.Title className="mb-1">{translatedMessage("DIGITAL_PICTURE")}</Card.Title>
                                            <Card.Text>
                                                {translatedMessage("PROJECT.CREATE_DIGITAL_PICTURE.DESCRIPTION")}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Body style={{ padding: "5px 10px" }}>&nbsp;</Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Link>
                            </Col>                            

                            <Col className="col-12 col-sm-6 col-lg-3  pl-2 pr-2">
                                <Card className="create-project-card disabled">
                                    <Card.Img variant="top" src={holoIcon} />
                                    <Card.Body>
                                        <Card.Title className="mb-1">{translatedMessage("HOLOGRAPHIC")}</Card.Title>
                                        <Card.Text>
                                            {translatedMessage("PROJECT.CREATE_HOLOGRAM.DESCRIPTION")}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Body style={{ backgroundColor: "#ffffff", padding: "5px 10px", maxHeight: "64px" }}>
                                        <Card.Text style={{ color: "#000000" }}>
                                            {translatedMessage("GENERAL.COMMING_SOON")}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer></Card.Footer>
                                </Card>
                            </Col>

                        </Row>
                    </div>
                </>
            )
        } else {
            return (
                <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
            )
        }
    }

}

export default ProjectCreatePage;