import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import FileService from '../../../services/file.service';
import { translatedMessage } from '../../../services/language.service';

import arArtIcon from '../../../components/shared/Resources/arArtWhite.png';
import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import previewIcon from '../../../components/shared/Resources/icons/preview_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import { toast } from "react-toastify";
import moment from 'moment';

import SimplePricePackageViewCardComponent from '../../../components/shared/PricePackage/CardComponents/SimplePricePackageViewCardComponent';
import HtmlEditorModalComponent from '../../../components/shared/HtmlEditorModalComponent/HtmlEditorModalComponent';
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import LightboxComponent from '../../../components/shared/LightboxComponent/LightboxComponent';
import ChooseVideoTypeComponentModal from '../../../components/shared/ChooseVideoTypeComponentModal/ChooseVideoTypeComponentModal';
import ChangePassePartoutColorComponentModal from '../../../components/shared/ChangePassePartoutColorComponentModal/ChangePassePartoutColorComponentModal';

import PublishModalComponent from '../Components/PublishModalComponent/PublishModalComponent';
import SubmittedForApprovalModalComponent from '../Components/SubmittedForApprovalModalComponent/SubmittedForApprovalModalComponent';
import EditFieldModalComponent from '../Components/EditFieldModalComponent';
import ExperienceCardComponent from "./Components/ExperienceCardComponent";

class ArArtPublishPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      artwork: {},
      pricePackages: [],
      artworkImage: [],
      artworkImageUrl: "",
      artworkPrintImage: null,
      artworkPrintImageUrl: "",
      artworkTags: [],
      experiences: [],
      pricePackagesTableData: [],
      presentationImages: [],
      artworkVideos: [],
      addImageModal: false,
      addImageModalData: {},
      deleteVideoModal: false,
      deleteVideoModalData: {},
      changeVideoModal: false,
      changeVideoModalData: {},
      publishModalShow: false,
      submittedForApprovalModalShow: false,
      artworkStatusName: '',
      data: {},
      hasAllSignatures: false,
      showEditorModal: false,
      showFieldModal: false,
      modalData: {
        content: '',
        title: '',
        dataFor: ''
      },
      showAddFileModal: false,
      showVideoTypeSelectModal: false,
      showLightbox: false,
      lightboxImages: [],
      showPassePModal: false,
      isLoaded: false,
      isError: false,
      errorMessage: '',
      onlyView: false
    };

    this.onShowEdit = this.onShowEdit.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

  populateData() {
    ApiService.getAuthenticatedInstance().get(`/artwork/existing/${this.props.match.params.id}`)
      .then(result => {
        this.setState({
          artwork: {
            ...result.data,
            storageItem: {
              id: result.data.storageItemId
            }
          },
          artworkStatusName: result.data.status.name,
          onlyView: result.data.status.name === ArtworkService.artworkStatusEnum().DRAFT ||
            result.data.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
        }, () => {
          var promise = [];
          var artworkTags = [];
          var pricePackages = [];
          var experiences = [];
          var hasAllSignatures = false;

          promise.push(ApiService.getAuthenticatedInstance().get(`/artworks/${this.state.artwork.id}/artworkTags`)
            .then(result => artworkTags = result.data._embedded.artworkTags.map(artworkTag => artworkTag.additional.tag))
          );

          promise.push(ApiService.getAuthenticatedInstance().get(`/artwork/${this.state.artwork.id}/artworkPricePackages`)
            .then(result => {
              pricePackages = result.data.pricePackages
                .map(artworkPricePackage => {
                  artworkPricePackage.description = translatedMessage(artworkPricePackage.description);

                  return {
                    ...artworkPricePackage
                  }
                })
                .filter(item => item.active)
                .sort((a, b) => a.order - b.order);
            })
          );

          promise.push(this.getStorageItems());

          promise.push(
            ApiService.getAuthenticatedInstance().get(`/artwork/${this.state.artwork.id}/addOns`)
              .then(response => {
                experiences = response.data.experiences
              })
          )

          promise.push(
            ApiService.getAuthenticatedInstance().get(`/artwork/hasAllSignatures/${this.state.artwork.id}`)
              .then(response => hasAllSignatures = response.data)
          );

          Promise.all(promise).then(() => {
            this.setState({
              artworkTags: artworkTags,
              pricePackagesTableData: pricePackages,
              experiences: experiences,
              hasAllSignatures: hasAllSignatures,
              isLoaded: true
            })
          })
        });
      })
      .catch(err => {
        var errorMessage = "PROJECT.GENERAL_ERROR";
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });

  }

  getStorageItems = () => {
    let promise = [];
    let presentationImages = [];
    let artworkVideos = [];
    let artworkImage = {};
    let artworkPrintImage = null;

    let isPublished = this.state.artwork.status.name === ArtworkService.artworkStatusEnum().PUBLISHED ? true : false;

    promise.push(
      ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.artwork.id}/${ApiService.getEntityName().ARTWORK}`)
        .then(results => {
          const storageFiles = results.data.fileList;
          storageFiles.map(file => {
            let mockFile = {};
            mockFile.file = file;
            mockFile.original = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
            mockFile.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
            mockFile.storageItemFileId = file.storageItemFileId;

            if (file.defaultThumbnail && file.usage === FileService.getFileUsage().THUMBNAIL) {
              artworkImage = file;
            } else if (file.caCreated && file.usage === FileService.getFileUsage().ARTWORK_PRINT_PREVIEW) {
              artworkPrintImage = file;
            } else if (!file.defaultThumbnail && file.usage === FileService.getFileUsage().THUMBNAIL) {
              mockFile.type = FileService.getResourceType().IMAGE;
              mockFile.youtubeId = null;
              presentationImages.push(mockFile);
            } else if (file.usage === FileService.getFileUsage().CREATE_DESTROY ||
              file.usage === FileService.getFileUsage().CREATION_PROC ||
              file.usage === FileService.getFileUsage().ART_DESC) {

              mockFile.type = FileService.getResourceType().VIDEO;
              mockFile.youtubeId = file.youtubeId !== null ? file.youtubeId : null;
              artworkVideos.push(mockFile);
            }
            return file;
          });
        })
    )
    
    Promise.all(promise).then(() => {
      this.setState({
        presentationImages: presentationImages,
        artworkImage: artworkImage,
        artworkImageUrl: ApiService.getFileByUuid(artworkImage.uuid),
        artworkPrintImage: artworkPrintImage,
        artworkPrintImageUrl: artworkPrintImage && !isPublished ? ApiService.getFileByUuid(artworkPrintImage.uuid) : '',
        artworkVideos: artworkVideos,
      })
    })
  }

  handleValidation = (forPreview) => {
    let response = {
      isValid: true,
      errorMessage: []
    }

    let i = 0;
    if (!this.state.artworkImage) {
      response.isValid = false;
      response.errorMessage[i] = "ARTWORK.PUBLISH.MISSING.ARTWORK";
      i += 1;
    }

    if (this.state.experiences.length === 0) {
      response.isValid = false;
      response.errorMessage[i] = "ARTWORK.PUBLISH.MISSING.AR";
      i += 1;
    }

    if (!forPreview && !this.state.hasAllSignatures) {
      response.isValid = false;
      response.errorMessage[i] = "ARTWORK.SIGNATURES.MISSING.NOTE";
    }

    return response;
  }

  onShowOrHidePublishModal = (termsAndConditions = null) => {
    const validationResponse = this.handleValidation(false);
    if (validationResponse.isValid === true) {
      if (this.state.publishModalShow === false) {
        this.setState({
          publishModalShow: true,
        })
      } else if (!termsAndConditions) {
        this.setState({
          publishModalShow: false,
        })
      } else {
        this.setState({
          publishModalShow: false,
        }, () => {
          ApiService.getAuthenticatedInstance().post(`/artwork/submit-artwork/${this.props.match.params.id}`)
            .then((result) => {
              this.setState({
                artworkStatusName: ArtworkService.artworkStatusEnum().SUBMITTED_FOR_APPROVAL
              })
              this.onShowOrHideSubmittedForApprovalModal();
            });
        })
      }
    } else {
      validationResponse.errorMessage.forEach(message => {
        toast.error(translatedMessage(message), { autoClose: 7000 });
      })
    }
  };

  onShowOrHideSubmittedForApprovalModal = () => {
    if (this.state.submittedForApprovalModalShow === false) {
      this.setState({
        submittedForApprovalModalShow: true,
      })
    } else {
      this.setState({
        submittedForApprovalModalShow: false,
        onlyView: true
      });

      // this.props.history.push(`/project/view/${this.props.match.params.id}`);
    }
  };

  handlePreview = () => {
    const validationResponse = this.handleValidation(true);
    if (validationResponse.isValid === true) {
      Promise.all([
        ApiService.getAuthenticatedInstance().patch(`/projects/${this.props.match.params.id}`, {
          status: ArtworkService.artworkStatusEnum().PREVIEW,
        }),
        ApiService.getAuthenticatedInstance().patch(`/artworks/${this.state.artwork.id}`, {
          status: ArtworkService.artworkStatusEnum().PREVIEW,
        }),
      ]).then(() => {
        this.setState({
          artworkStatusName: ArtworkService.artworkStatusEnum().PREVIEW
        })
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      }).catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      });
    } else {
      validationResponse.errorMessage.forEach(message => {
        toast.error(translatedMessage(message), { autoClose: 7000 });
      })
    }
  }

  onShowEdit = (event) => {
    if (event.currentTarget.id === 'aboutArtwork') {
      this.setState({
        modalData: {
          content: this.state.artwork.description,
          title: translatedMessage("ARTWORK.ABOUT_ARTWORK"),
          dataFor: 'aboutArtwork',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'aboutAuthor') {
      this.setState({
        modalData: {
          content: this.state.artwork.aboutAuthor ? this.state.artwork.aboutAuthor : "",
          title: translatedMessage("ARTWORK.ABOUT_AUTHOR"),
          dataFor: 'aboutAuthor',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'artworkName') {
      this.setState({
        modalData: {
          content: this.state.artwork.name,
          title: translatedMessage("PROJECT.ARTWORK_NAME"),
          dataFor: 'artworkName',
        },
        showFieldModal: true,
      })
    }
  }

  onHideEdit = (data) => {
    if (data && data.content && data.dataFor) {
      let description = this.state.artwork.description;
      let aboutAuthor = this.state.artwork.aboutAuthor;
      let artworkName = this.state.artwork.name;

      if (data.dataFor === "aboutArtwork") {
        description = data.content;
      } else if (data.dataFor === "aboutAuthor") {
        aboutAuthor = data.content;
      } else if (data.dataFor === "artworkName") {
        artworkName = data.content;
      }

      const promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/artworks/${this.state.artwork.id}`, {
          name: artworkName,
          description: description,
          aboutAuthor: aboutAuthor,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
        resolve();
      });

      promise.then(() => {
        toast.success(translatedMessage("ARTWORK.SAVE_SUCCESS"));
        this.setState({
          artwork: {
            ...this.state.artwork,
            name: artworkName,
            description: description,
            aboutAuthor: aboutAuthor
          }
        })
      })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);
        });
    }
    this.setState({
      showEditorModal: false,
      showFieldModal: false
    })
  }

  onShowAddFile = (event) => {
    if (event.currentTarget.id === 'btnPresImg') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.artwork,
        storageitemlinked: true
      }
      this.setState({
        showAddFileModal: true,
        fileModalData: modalData
      })
    } else if (event.currentTarget.id === 'btnVideo') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_VIDEO"),
        // fileUsage: FileService.getFileUsage().CREATE_DESTROY,
        fileType: FileService.getResourceType().VIDEO,
        deletePrevious: true,
        deletedImage: null,
        deletePreviousDesc: "DROPZONE.DELETE_PREVIOUS_VIDEO",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.VIDEO",
        maxSize: 100,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: false,
        multipleFilesDesc: "",
        entity: this.state.artwork,
        storageitemlinked: true
      }
      this.setState({
        showVideoTypeSelectModal: true,
        fileModalData: modalData
      })
    }
  }

  onHideVideoTypeSelect = (type) => {
    if (type) {
      let modalData = this.state.fileModalData;
      modalData.fileUsage = type;

      let selectedVideo = this.state.artworkVideos.filter(item => item.file.usage === type);
      if (selectedVideo[0] && selectedVideo[0].file && selectedVideo[0].file.uuid) {
        modalData.deletedImage = selectedVideo[0].file
      }

      this.setState({
        showAddFileModal: true,
        showVideoTypeSelectModal: false,
        fileModalData: modalData
      })
    } else {
      this.setState({
        showVideoTypeSelectModal: false,
      })
    }

  }

  onShowPassePModal = () => {
    this.setState({
      showPassePModal: true,
    })
  }

  onHidePassePChange = (color) => {
    if (color && color !== this.state.artworkPrintImage.passepartoutColor) {
      ApiService.getAuthenticatedInstance().post(`/artwork/change-passepartout/${this.props.match.params.id}/${this.state.artworkPrintImage.id}?color=${color}`)
        .then((response) => {
          if (response.data.status === "OK") {
            toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));

            this.setState({
              artworkPrintImage: {
                ...this.state.artworkPrintImage,
                passepartoutColor: color,
              },
              showPassePModal: false,
            }, this.getStorageItems)
          } else {
            toast.error(translatedMessage(response.data.message));

            this.setState({
              showPassePModal: false,
            })
          }
        })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);

          this.setState({
            showPassePModal: false,
          })
        });
    } else {
      this.setState({
        showPassePModal: false,
      })
    }

  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getStorageItems();
    }
    this.setState({
      showAddFileModal: false,
      showAddVideoModal: false
    })
  }

  handleMediaListChange = () => {
    this.getStorageItems();
  }

  onShowLightbox = (event) => {
    let images = [];
    if (event.target.id === 'artwork') {
      images.push(this.state.artworkImageUrl);
      images.push(this.state.artworkPrintImageUrl)
    } else if (event.target.id === 'artworkPrint') {
      images.push(this.state.artworkPrintImageUrl);
      images.push(this.state.artworkImageUrl);
    }

    this.setState({
      showLightbox: true,
      lightboxImages: images
    })
  }

  onHideLightbox = () => {
    this.setState({
      showLightbox: false
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        var isPreview = this.state.artworkStatusName === ArtworkService.artworkStatusEnum().PREVIEW;
        const homeUlr = this.state.artwork.companyId ? `/my-company/${this.state.artwork.companyId}/portfolio` : `/projects`;

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-md-between ">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={arArtIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-lg-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_AR_ART")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("PROJECT.PUBLISH_REVIEW")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={homeUlr} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>
                </div>

                <div className="ca-page-padding ca-main-container">
                  <Row>
                    <Col className="col-12 col-lg-6 ca-artwork-column-left mb-3 text-center">
                      <Image id="artwork" src={this.state.artworkImageUrl} className="ca-ar-art-publish-preview-image" onClick={this.onShowLightbox} />
                    </Col>
                    <Col className="col-12 col-lg-6 ca-artwork-column-right">
                      <Row>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label d-flex align-items-end">
                              {translatedMessage("PROJECT.ARTWORK_NAME")}
                              {!this.state.onlyView &&
                                <Button id="artworkName" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                                  <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                                </Button>
                              }
                            </div>
                            <div className="ca-view-value">{this.state.artwork.name}</div>
                          </div>
                        </Col>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("PROJECT.CATEGORY")}
                            </div>
                            <div className="ca-view-value">{translatedMessage(this.state.artwork.category.code)}</div>
                          </div>
                        </Col>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                            </div>
                            <div className="ca-view-value">{this.state.artwork.sizeWidth} W x {this.state.artwork.sizeHeight} H cm</div>
                          </div>
                        </Col>
                        {this.state.artworkPrintImage && this.state.artworkPrintImage.widthCm &&
                          <Col className="col-12 col-sm-6 pl-0">
                            <div className="ca-display-group">
                              <div className="ca-label">
                                {translatedMessage("ARTWORK.PRINT.SIZE")}
                              </div>
                              <div className="ca-view-value">{this.state.artworkPrintImage.widthCm} W x {this.state.artworkPrintImage.heightCm} H cm</div>
                            </div>
                          </Col>
                        }
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("GENERAL.TAGS")}
                            </div>
                            <div className="ca-view-value">
                              {this.state.artworkTags.map((element, index) => {
                                if (this.state.artworkTags.indexOf(element) !== this.state.artworkTags.length - 1) {
                                  return (
                                    <span key={index} className="step5-artwork-information-details-text">{element},&nbsp;</span>
                                  )
                                } else {
                                  return (
                                    <span key={index} className="step5-artwork-information-details-text">{element}</span>
                                  )
                                }
                              })
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-12 pl-0">
                          <div className="ca-label d-flex align-items-end">
                            {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                            <Button id="aboutArtwork" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                          </div>
                          {this.state.artwork.description
                            ? <div className="ca-description-value ca-display-group" dangerouslySetInnerHTML={{ __html: this.state.artwork.description }} />
                            : <div className="ca-description-value">-</div>
                          }
                        </Col>

                        <Col className="col-12 pl-0">
                          <div className="ca-label d-flex align-items-end">
                            {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                            <Button id="aboutAuthor" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                          </div>
                          {this.state.artwork.aboutAuthor
                            ? <div className="ca-description-value ca-display-group" dangerouslySetInnerHTML={{ __html: this.state.artwork.aboutAuthor }} />
                            : <div className="ca-description-value">-</div>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("ADD_ON.ADD_ON")}
                      </div>
                    </Col>
                    {this.state.experiences.map((element, index) => {
                      return (
                        <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0">
                          <ExperienceCardComponent
                            data={element}
                            thumbnail={this.state.artworkImageUrl}
                            showThumbnail={false}
                            showControls={false}
                            variant="dark"
                          />
                        </Col>
                      )
                    })}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("ARTWORK.PRICING")}
                      </div>
                    </Col>
                    {this.state.pricePackagesTableData.map((element, index) => {
                      return (
                        <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0">
                          <SimplePricePackageViewCardComponent
                            data={element}
                            background="dark"
                            category={ArtworkService.artworkCategoryEnum().AR_ART}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("GENERAL.MEDIA")}
                      </div>
                    </Col>
                    <Col className="col-12 col-md-6 pl-0">
                      <div className="mb-3 font-weight-bold d-flex align-items-end">
                        <span>
                          {translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                          <OverlayTrigger
                            key='addImage'
                            placement='bottom'
                            overlay={
                              <Tooltip id={`tooltip-addImage`} className="ca-tooltip">
                                {translatedMessage("ARTWORK.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                              </Tooltip>
                            }
                          >
                            <span className="ca-question-icon"></span>
                          </OverlayTrigger>
                        </span>

                        <span className="d-inline-block">
                          <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                            <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                          </Button>
                        </span>
                      </div>
                      {this.state.presentationImages.length > 0 &&
                        <Carousel
                          id="presImg"
                          items={this.state.presentationImages}
                          controls={true}
                          indicators={false}
                          interval={null}
                          class="mb-5"
                          hasToolbar={true}
                          toolbarDelete={true}
                          toolbarFullScreen={true}
                          canDeleteLast={true}
                          onChange={this.handleMediaListChange}
                        />
                      }
                    </Col>
                    <Col className="col-12 col-md-6 pl-0">
                      <div className="mb-3 font-weight-bold d-flex align-items-end">
                        <span>
                          {translatedMessage("PROJECT.VIDEOS")}
                        </span>

                        <span className="d-inline-block">
                          <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                            <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />
                            {this.state.artworkVideos.length > 0 &&
                              <Image src={editIcon} alt={translatedMessage("GENERAL.CHANGE")} title={translatedMessage("GENERAL.CHANGE")} />
                            }
                          </Button>
                        </span>
                      </div>
                      {this.state.artworkVideos.length > 0 &&
                        <Carousel
                          id="video"
                          items={this.state.artworkVideos}
                          controls={true}
                          indicators={false}
                          interval={null}
                          class="mb-5"
                          hasToolbar={true}
                          toolbarDelete={true}
                          toolbarYoutubeId={true}
                          onChange={this.handleMediaListChange}
                        />
                      }
                    </Col>
                  </Row>
                  {this.state.artworkPrintImageUrl !== '' &&
                    <>
                      <hr></hr>
                      <Row>
                        <Col className="col-12 pl-0">
                          <div className="ca-paragraph-title w-100">
                            {translatedMessage("PROJECT.UPLOAD_PRINT_FILE")}
                          </div>
                          <div className="ca-label d-flex align-items-end mb-2">
                            {translatedMessage("ARTWORK.PRINT.CHANGE_PASSEPARTOUT_COLOR")}
                            <Button id="btnPasseP" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowPassePModal}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.IMAGE")} />
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-12 pl-0 ca-ar-art-publish-preview-print-container">
                          <Image id="artworkPrint" src={this.state.artworkPrintImageUrl} onClick={this.onShowLightbox}
                            title={translatedMessage("ARTWORK.SEE_PRINT_FILE")}
                            className="ca-artwork-print-image" />
                        </Col>
                      </Row>
                    </>
                  }
                  <PublishModalComponent
                    show={this.state.publishModalShow}
                    onHide={this.onShowOrHidePublishModal}
                    name={this.state.artwork.name}
                    pagetitle="ARTWORK.SUBMIT_CONFIRM"
                    pageinfo="ARTWORK.SUBMIT_CONFIRM_INFO"
                  />

                  <SubmittedForApprovalModalComponent
                    show={this.state.submittedForApprovalModalShow}
                    onHide={this.onShowOrHideSubmittedForApprovalModal}
                    message={"ART.SUBMITTED.SUCCESS"}
                  />

                  <HtmlEditorModalComponent
                    show={this.state.showEditorModal}
                    onHide={this.onHideEdit}
                    data={this.state.modalData}
                  />

                  <EditFieldModalComponent
                    show={this.state.showFieldModal}
                    onHide={this.onHideEdit}
                    data={this.state.modalData}
                  />

                  <AddFileModalComponent
                    show={this.state.showAddFileModal}
                    onHide={this.onHideAddFile}
                    data={this.state.fileModalData}
                  />

                  <ChooseVideoTypeComponentModal
                    show={this.state.showVideoTypeSelectModal}
                    onHide={this.onHideVideoTypeSelect}
                    data={this.state.artworkVideos}
                  />

                  <LightboxComponent
                    images={this.state.lightboxImages}
                    showLightbox={this.state.showLightbox}
                    onHide={this.onHideLightbox}
                  />

                  <ChangePassePartoutColorComponentModal
                    show={this.state.showPassePModal}
                    onHide={this.onHidePassePChange}
                    data={this.state.artworkPrintImage ? this.state.artworkPrintImage.passepartoutColor : null}
                  />
                </div>
              </div>
              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className="ca-button-icon" variant="link"
                    onClick={() => this.props.history.push(`/project/publish/ar-art-price-package/${this.props.match.params.id}`)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div>
                  <OverlayTrigger
                    key='pPrev'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-pPrev`} className="ca-tooltip">
                        {isPreview ? translatedMessage("PROJECT.STATUS.PREVIEW_MODE") : translatedMessage("PROJECT.MOBILE_PREVIEW.TOOLTIP")}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <span className={isPreview || this.state.onlyView ? "ca-text-disabled-dark" : ""}>{translatedMessage("PROJECT.MOBILE_PREVIEW")}</span>
                      <Button variant="link" className="ca-button-icon ml-2" onClick={this.handlePreview}
                        disabled={isPreview || this.state.onlyView}>
                        <Image src={previewIcon} alt={translatedMessage("PROJECT.MOBILE_PREVIEW")} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="d-flex align-items-center">
                  <OverlayTrigger
                    key='pPubB'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-pPubB`} className="ca-tooltip">
                        {!this.state.hasAllSignatures ? translatedMessage("ARTWORK.SIGNATURES.MISSING.NOTE") : translatedMessage("PROJECT.PUBLISH")}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <span className={!this.state.hasAllSignatures || this.state.onlyView ? "ca-text-disabled-dark" : ""}>{translatedMessage("PROJECT.PUBLISH")}</span>
                      <Button className="ca-button-icon" variant="link" onClick={this.onShowOrHidePublishModal} disabled={!this.state.hasAllSignatures || this.state.onlyView}>
                        <Image src={nextArrow} alt={translatedMessage("PROJECT.PUBLISH")} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }
}

export default ArArtPublishPreview;