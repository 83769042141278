import React from 'react'

import LanguageService from '../../../services/language.service'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

class TermsConditionsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="ca-page-padding ca-main-container ca-terms">
                <div className="ca-page-title w-100 mb-2">{window.$translate(LanguageService.getCurrentLanguage(),"TERMS.TOU")}</div>
                <Row>
                    <Col className="pl-0">
                        <p className="ca-terms-paragraph text-justify">
                            These Terms of Use (hereinafter the "Terms") describe your rights and obligations as an 
                            user of www.connected.art and associated domains and pages. These Terms also govern the 
                            processing of personal data that occurs when you use your account for services linked to 
                            Connected.ART (the "Services", or separately a "Service"), as well as Connected.ART's 
                            processing of the personal data handled in www.connected.art and associated domains and pages.
                        </p>
                        <p className="ca-terms-paragraph text-justify font-weight-bold">
                            When you register as a user, regardless of type, and / or use one or more services and / or 
                            shops at Connected.ART, it also means that you have accepted and accepted the terms of use 
                            that apply at all times.
                        </p>
                        <p className="ca-terms-paragraph text-justify">
                            The service provider and seller is Connected.ART (Connected.ART AS) directly or as an 
                            intermediary (sales agent) of art and art experiences as well as related products and services.               
                        </p>
                        
                        <h2>Registration / Biographical</h2>            
                        <p className="ca-terms-paragraph text-justify">
                            In order to use services and shop at Connected.ART, you must register as a "User". This is to 
                            achieve the highest possible security and quality around rights, payments, deliveries and the 
                            use of our services in general. Connected.ART allows you, as a registered user, to create and 
                            publish content on www.connected.art and associated domains and pages, and gives you the ability 
                            to control and restrict access to such content. In light of the content of the service, carefully 
                            select the information you want to post on the pages and refrain from posting anything illegal, 
                            especially with respect to rights. We will not change or monitor User Listed Content, but we 
                            reserve the right to remove any User Listed Content that comes to our attention and which, in 
                            our sole discretion, violates this Agreement.
                        </p>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default TermsConditionsPage;