import React from 'react';

import { Row, Col, Form, Button, Dropdown, Image } from 'react-bootstrap';

import CardComponent from "../Components/ChallengeCardComponent";
import addIcon from '../../../../components/shared/Resources/icons/add.svg';
import backIcon from '../../../../components/shared/Resources/left-arrow.svg';

import challengeIcon from '../../../../components/shared/Resources/icons/challenge.svg';
import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';

import ApiService from '../../../../services/api.service';
import { translatedMessage } from '../../../../services/language.service';
import CompanyService from '../../../../services/company.service';

const ENTER_KEY = 13;

class CompanyChallengePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      searchInput: '',
      allList: [],
      filteredList: [],      
      isLoaded: false,
      isError: false,
      errorMessage: "",
      company: null,
      statusList: [
        { id: 0, label: "GENERAL_STATUS.ANY", isActive: null, selectedWinner: null },
        { id: 1, label: "CHALLENGE.STATUS.INACTIVE", isActive: false, selectedWinner: false },
        { id: 2, label: "CHALLENGE.STATUS.ACTIVE", isActive: true, selectedWinner: false },
        { id: 3, label: "CHALLENGE.NOTIFICATION.SENT", isActive: false, selectedWinner: true }
      ],
      selectedStatus: { id: 0, label: "GENERAL_STATUS.ANY" },
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        this.setState({
          company: response.data.company,
        }, this.getChallenges)
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async getChallenges() {
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    var challenges = await CompanyService.getCompanyChallengesForManage(this.state.company.id)
      .then(response => { return response; })
      .catch(err => {
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })

    this.setState({
      allList: challenges,
      filteredList: challenges,
      isLoaded: true
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleStatusSelect = (eventKey) => {
    let selectedStatus = this.state.statusList.filter(item => item.id === parseInt(eventKey))

    this.setState({
      selectedStatus: selectedStatus[0] ? selectedStatus[0] : this.state.selectedStatus
    }, this.filter)
  }

  filter = () => {
    let result = this.state.allList;
    let searchInput = this.state.searchInput;
    let statusItem = this.state.selectedStatus;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.allList.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (statusItem && statusItem.id !== 0 && statusItem.isActive != null) {
      result = result.filter(elem => statusItem.isActive ? elem.isActive : (statusItem.selectedWinner ? elem.notificationSent : !elem.isActive && !elem.notificationSent));
    }

    this.setState({
      filteredList: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="pr-2 d-flex align-items-center">
              <Image src={challengeIcon} className="ca-page-title-icon" alt={translatedMessage("CHALLENGE.CHALLENGES")} />
              <span className="ca-page-title">{translatedMessage("CHALLENGE.CHALLENGES")}</span>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              {this.state.company &&
                <Button onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/challenge-edit`)} className="ca-dark-link ca-button-icon medium mr-1">
                  <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} />
                </Button>
              }

              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/space`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>
          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedStatus.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    this.state.statusList.map(item => {
                      return (
                        <Dropdown.Item key={item.id} eventKey={item.id}>{translatedMessage(item.label)}</Dropdown.Item>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row>
            {this.state.filteredList.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pl-0 pr-0">
                  <CardComponent
                    cardImage={element.imageURL}
                    cardTitle={element.name}
                    cardDetails={element}
                    id={0 === element.id ? 0 : element.id}
                    companyId={this.state.company.id}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default CompanyChallengePage;