import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';

import addIcon from '../../../components/shared/Resources/icons/add.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';

import ApiService from '../../../services/api.service';
import FileService from '../../../services/file.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

class ExhibitionAdminArtworkTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      exhibitionArExperience: [],
      exhibition: [],
      arExperience: [],
      isLoaded: false,
      triggerFiles: [],
      showAddFileModal: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("EXHIBITION_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    if (this.state.canEdit) {
      ApiService.getAuthenticatedInstance().get(`arExperience/exhibitionArExperience/${this.state.id}`)
        .then(response => {
          this.setState({
            exhibitionArExperience: response.data,
            exhibition: response.data.exhibition,
            arExperience: response.data.arExperience,
            storageItem: response.data.storageItem
          }, this.getStorageItems)
        })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  async getStorageItems() {
    const storageFiles = await ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.exhibitionArExperience.id}/${ApiService.getEntityName().EXHIBITION_AR_EXPERIENCE}`)
      .then(results => { return results.data.fileList } )

    var triggerFiles = FileService.handleFilesForCarousel(storageFiles, FileService.getFileUsage().TRIGGER);
    
    this.setState({
      triggerFiles: triggerFiles,
      isLoaded: true
    });
  }

  handleMediaListChange = () => {
    this.getStorageItems();
  }

  onShowAddFile = () => {
    const modalData = {
      modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
      fileUsage: FileService.getFileUsage().TRIGGER,
      fileType: FileService.getResourceType().IMAGE,
      deletePrevious: false,
      deletedImage: null,
      deletePreviousDesc: "",
      fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
      maxSize: 1,
      maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
      multipleFiles: true,
      multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
      entity: this.state.exhibitionArExperience,
      storageitemlinked: true
    }
    this.setState({
      showAddFileModal: true,
      fileModalData: modalData
    })
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getStorageItems();
    }
    this.setState({
      showAddFileModal: false,
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <div className="mb-3 pr-2">
              <span className="ca-page-title">{this.state.arExperience.name}</span>
              <br />
              <span className="ca-page-subtitle">{this.state.exhibition.name}</span>
            </div>
            <Button className="ca-dark-link ca-button-icon" variant="link"
                onClick={() => this.props.history.push(`/exhibitionAdminArExperiences/${this.state.exhibition.id}`)} >
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>            
          </Row>
          <Row className="mt-3">
            <div className="mb-3 pr-2 d-flex align-items-end">
              <span className="ca-page-subtitle">{translatedMessage("TRIGGER.TRIGGER_IMAGES")}</span>
              <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
              </Button>
            </div>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              {this.state.triggerFiles.length > 0 &&
                <Carousel
                  id="trigger"
                  items={this.state.triggerFiles}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mx-auto mb-5"
                  hasToolbar={true}
                  toolbarDelete={true}
                  toolbarYoutubeId={false}
                  canDeleteLast={true}
                  onChange={this.handleMediaListChange}
                />
              }
            </Col>
          </Row>

          <AddFileModalComponent
            show={this.state.showAddFileModal}
            onHide={this.onHideAddFile}
            data={this.state.fileModalData}
          />
        </div >
      );
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }
}

export default ExhibitionAdminArtworkTrigger;