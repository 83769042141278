import React from 'react';
import { Link } from 'react-router-dom'

import { Button, Card, Image } from 'react-bootstrap'
import { translatedMessage } from '../../../services/language.service';

import editIcon from '../Resources/icons/edit.svg';
import mediaIcon from '../Resources/icons/media.svg';
import artIcon from '../Resources/icons/art.svg';
import mapPinIcon from '../Resources/icons/map_pin.svg';
import virtualGalleryIcon from '../Resources/icons/virtualGallery.svg';
import arExperienceIcon from '../Resources/icons/ar_experience.svg';
import userAccessIcon from '../Resources/icons/user_access.svg';
import mapIcon from '../Resources/icons/map.svg';

class ParkCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disableCardLink: !this.props.entityUrl || (this.props.canEdit && this.props.cardDetails?.parentPark),
            viewUrl: this.props.canEdit ? this.props.entityUrl : (this.props.cardDetails?.parentPark ? 'themePark' : this.props.entityUrl)
        };
    }

    render() {
        return (
            <>
                <Card className="ca-entity-card">
                    <Link to={!this.state?.disableCardLink ? `/${this.state?.viewUrl}/${this.props.id}/view` : '#'}
                        className={this.state?.disableCardLink ? "disabled" : ""}>

                        <div className='ca-card-image-container d-flex align-items-center justify-content-center'>
                            <Card.Img variant="top" src={this.props.cardImage}/>
                        </div>
                    </Link>
                    <Card.Body>
                        <Card.Title className="d-flex justify-content-center align-items-center mb-0">{this.props.cardTitle}</Card.Title>
                        {this.props.canEdit &&
                            <Card.Text>
                                <b>{translatedMessage("GENERAL.STATUS")}: </b>
                                {translatedMessage("ParkStatus." + this.props.cardDetails.status)}
                            </Card.Text>
                        }
                    </Card.Body>
                    {this.props.canEdit &&
                        <Card.Footer className="ca-entity-card-footer pt-0">
                            <Button onClick={() => this.props.editDetails(this.props.cardDetails, 'PARK')} className="ca-dark-link ca-button-icon small">
                                <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                            <Button onClick={() => this.props.editDetails(this.props.cardDetails, 'MEDIA')} className="ca-dark-link ca-button-icon small">
                                <Image src={mediaIcon} alt={translatedMessage("GENERAL.MEDIA_FILES")} title={translatedMessage("GENERAL.MEDIA_FILES")} />
                            </Button>
                            {this.props.cardDetails?.parentPark && <>
                                <Link className="ca-dark-link small mr-2" to={`/${this.props.entityUrl}/${this.props.id}/artwork`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                                    <Image src={artIcon} alt={translatedMessage("GENERAL.ARTWORKS.MANAGE")} />
                                </Link>
                                <Link className="ca-dark-link small mr-2" to={`/${this.props.entityUrl}/${this.props.id}/virtualGallery`} title={translatedMessage("VIRTUAL_GALLERY.MANAGE")}>
                                    <Image src={virtualGalleryIcon} alt={translatedMessage("VIRTUAL_GALLERY.MANAGE")} />
                                </Link>
                                <Link className="ca-dark-link small mr-2" to={`/${this.props.entityUrl}/${this.props.id}/arExperience`} title={translatedMessage("AR_EXPERIENCE.MANAGE")}>
                                    <Image src={arExperienceIcon} alt={translatedMessage("AR_EXPERIENCE.MANAGE")} />
                                </Link>
                            </>}       
                            <Button onClick={() => this.props.editDetails(this.props.cardDetails, 'ACCESS')} className="ca-dark-link ca-button-icon small">
                                <Image src={userAccessIcon} alt={translatedMessage("GENERAL.ACCESS.MANAGE")} title={translatedMessage("GENERAL.ACCESS.MANAGE")} />
                            </Button>                  
                            <Link className="ca-dark-link small mr-2" to={`/${this.props.entityUrl}/${this.props.id}/location`} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                                <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")} />
                            </Link>
                            <Link className="ca-dark-link small" to={`/park/${this.props.id}/map`} title={translatedMessage("MAP.VIEW_ON_MAP")}>
                                <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} />
                            </Link>
                        </Card.Footer>
                    }
                </Card>
            </>
        )
    }
}

export default ParkCardComponent;