import ApiService from "./api.service";
import ArtworkService from "./artwork.service";

const ArExperienceService = {
  statusEnum: () => {
    return {
      DRAFT: "DRAFT",
      PUBLISHED: "PUBLISHED",
      UNPUBLISHED: "UNPUBLISHED"
    };
  },
  

  getArExperienceStatusList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/arExperienceStatusList`)
        .then(response => {
          var statusList = response.data;
          statusList.sort((a, b) => {
            var result;
            parseInt(a.order, 10) <= parseInt(b.order, 10) ? result = -1 : result = 1
            return result;
          });

          resolve(statusList)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getArtCategoryList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/artCategoryList`)
        .then(response => {
          var list = response.data.filter(item => (item.label === ArtworkService.artworkCategoryEnum().AR_ART || item.label === ArtworkService.artworkCategoryEnum().OBJECT));

          list.sort((a, b) => {
            var result;
            a.label.toUpperCase() <= b.label.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(list)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getExhibitionArExpereinces(exhibitionId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`arExperience/exhibitionArExperiences/${exhibitionId}`)
        .then(response => {
          let list = response.data

          list.map((element) => {
            element.imageUrl = element.arExperience.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${element.arExperience.photoUuid}` : "";
            
            return element
          });          

          list.sort((a, b) => {
            var result;
            a.arExperience.name.toUpperCase() <= b.arExperience.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(list);
        })
        .catch(err => { console.log(err); reject(err) });
    })
  },

  addAREToExhibition(exhibitionId, arExperiences) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/arExperience/addArExperienceToExhibition/${exhibitionId}?arExperiences=${arExperiences}`)
        .then(resolve)
        .catch(err => {
          reject(err);
        });
    });
  },  

};

export default ArExperienceService;