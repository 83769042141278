import React from 'react';

import { Modal, Button, Form, Dropdown, Col } from 'react-bootstrap/'

import { translatedMessage } from '../../../../services/language.service';

import Select from 'react-select';
import { toast } from "react-toastify";
import CompanyService from '../../../../services/company.service';

const defaultUser = {
    user: null,
    status: { label: 'COMPANY.USER.STATUS.ACTIVE', name: 'ACTIVE' },
    isAdministrator: false
};

class EditUserModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            users: this.props.users,
            isAdministrator: false,
            userStatusList: [],
            selectedUser: defaultUser
        }
    }

    async componentDidMount() {
        const userStatusList = await CompanyService.getUserCompanyStatusList()
            .then(response => { return response })

        this.setState({
            userStatusList: userStatusList,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedUser !== this.props.selectedUser) {
            this.setState({ selectedUser: this.props.selectedUser });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedUser !== prevState.selectedUser) {
            return { selectedUser: prevState.selectedUser };
        }
        else return null;
    }

    handleIsAdminChange = (event) => {
        const input = event.currentTarget.querySelector('input')
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                isAdministrator: !input.checked
            }
        })
    }

    handleOnStatusSelect = (eventKey) => {
        const selectedItem = this.state.userStatusList.filter(item => item.name === eventKey);
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                status: selectedItem[0]
            }
        });
    }

    handleUserChange = (eventKey) => {
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                user: eventKey
            }
        })        
    }

    saveChanges = (event) => {
        event.preventDefault();
        var userId = event.target.elements.users.value;
        if (userId) {
            this.props.onHide(userId, this.state.selectedUser.status.name, this.state.selectedUser.isAdministrator);
        } else {
            toast.error(translatedMessage("COMPANY.USER.SELECT.EMPTY_ERROR"));
        }
    };

    render() {
        var users = [];
        this.props.users.forEach(element => {
            var user = {};
            user.value = element.id;
            user.label = element.fullName

            users.push(user);
        });

        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Group as={Col} md="12">
                            <Form.Label>
                                {translatedMessage("USER.USER")}
                            </Form.Label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={this.state.selectedUser.user}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="users"
                                options={users}
                                onChange={this.handleUserChange}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="12" controlId="formStatus">
                            <Form.Label>
                                {translatedMessage("GENERAL.STATUS")}
                            </Form.Label>
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedUser.status.label)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.userStatusList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                {translatedMessage(element.label)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>

                        <Form.Group as={Col} md="12">
                            <Form.Label></Form.Label>
                            <div className="ca-check-container" onClick={this.handleIsAdminChange}>
                                <input
                                    id={this.props.cardId}
                                    checked={this.state.selectedUser.isAdministrator}
                                    type="checkbox"
                                    onChange={this.handleIsAdminChange}
                                />
                                <span className="ca-check-label">{translatedMessage("COMPANY.USER.IS_ADMINISTRATOR")}</span>
                                <span className="ca-checkmark"></span>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default EditUserModalComponent