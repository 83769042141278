import React from 'react';

import { Row, Col, Image, Button, Form, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap/';
import Switch from "react-switch";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';

import ApiService from '../../../services/api.service';
import LanguageService from '../../../services/language.service';
import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import { hasPermission } from '../../../services/api.service';

import { toast } from "react-toastify";
import moment from 'moment';
import ArtworkService from '../../../services/artwork.service';

class VirtualGalleryEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : null,
      virtualGallery: {
        name: '',
        description: null,
        isUserGallery: false,
        defaultUserGallery: false,
        arPortal: null,
        type: null,
        status: null,
        photoUuid: null,
        portalName: null,
        caVirtualGallery: true
      },
      arPortals: [],
      statusList: [],
      typeList: [],
      canEdit: false,
      dropzoneEnabled: true,
      isLoaded: false,
      isNew: false,
      isError: false,
      initialStatus: null,
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
    };
  }

  componentDidMount() {
    ApiService.initCurrentUser().then(response => {
      const canEdit = this.props.match.params.id ? hasPermission("VIRTUAL_GALLERY_EDIT") : hasPermission("VIRTUAL_GALLERY_CREATE");

      this.setState({
        canEdit: canEdit,
      }, this.getData)
    });
  }

  async getData() {
    let isNew = false;
    let isError = false
    let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";

    if (this.state.canEdit) {
      let virtualGallery = this.state.virtualGallery

      if (this.state.id !== null) {
        virtualGallery = await VirtualGalleryService.getVirtualGalleryForManage(this.state.id)
          .then(response => {
            return response;
          })
          .catch(err => {
            isError = true;
            errorMessage = err;
          })
      } else {
        isNew = true;
      }

      if (!isError && virtualGallery) {
        let arPortals = await VirtualGalleryService.getAllArPortals()
          .then(response => {
            return response;
          })

        let statusList = await VirtualGalleryService.getVirtualGalleryStatusList()
          .then(response => { return response });

        if(!virtualGallery.caVirtualGallery){
          statusList = statusList.filter(item => item.name !== ArtworkService.artworkStatusEnum().DRAFT && 
            item.name !== ArtworkService.artworkStatusEnum().UNPUBLISHED)
        }

        let typeList = await VirtualGalleryService.getVirtualGalleryTypeList()
          .then(response => { return response });

        if (isNew) {
          virtualGallery.status = statusList.filter(item => item.name === VirtualGalleryService.virtualGalleryStatusEnum().DRAFT)[0];
          virtualGallery.type = typeList.filter(item => item.name === VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY)[0];
        }

        this.setState({
          virtualGallery: virtualGallery,
          arPortals: arPortals,
          statusList: statusList,
          typeList: typeList,
          imageURL: virtualGallery.photo ? `${ApiService.getBaseUrl()}/storage/file/${virtualGallery.photo.uuid}` : null,
          initialStatus: virtualGallery.status.name,
          isNew: isNew,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  onChangeEditor = (e) => {
    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        description: e,
      }
    })
  }

  handleUserGallerySwitchChange = (event) => {
    let defaultUserGallery = this.state.virtualGallery.defaultUserGallery
    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        isUserGallery: event,
        defaultUserGallery: !event ? event : defaultUserGallery,
      }
    })
  }

  handleDefaultSwitchChange = (event) => {
    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        defaultUserGallery: event,
      }
    })
  }

  handleArPortalSelect = (eventKey) => {
    const selectedItem = this.state.arPortals.filter(item => parseInt(item.id) === parseInt(eventKey));

    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        arPortal: selectedItem[0] ? selectedItem[0] : null,
        portalName: selectedItem[0] ? selectedItem[0].name : null,
      }
    })
  }

  handleTypeSelect = (eventKey) => {
    let isUserGallery = eventKey === VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY
      ? false
      : this.state.virtualGallery.isUserGallery;

    let defaultUserGallery = eventKey === VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY
      ? false
      : this.state.virtualGallery.defaultUserGallery;

    const selectedItem = this.state.typeList.filter(item => item.name === eventKey);

    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        type: selectedItem[0] ? selectedItem[0] : null,
        isUserGallery: isUserGallery,
        defaultUserGallery: defaultUserGallery,        
      }
    })
  }

  handleStatusSelect = (eventKey) => {
    const selectedItem = this.state.statusList.filter(item => item.name === eventKey);

    this.setState({
      virtualGallery: {
        ...this.state.virtualGallery,
        status: selectedItem[0] ? selectedItem[0] : null,
      }
    })
  }

  validate = (event) => {
    var response = {
      isError: false,
      message: ""
    };

    if (!this.state.virtualGallery.photoUuid && this.state.virtualGallery.status.name === VirtualGalleryService.virtualGalleryStatusEnum().PUBLISHED) {
      response = {
        isError: true,
        message: translatedMessage("VIRTUAL_GALLERY.THUMB_ERROR")
      }
      return response;
    }

    return response;
  }

  saveChanges = (event) => {
    event.preventDefault();

    const validation = this.validate(event);
    if (!validation.isError) {
      if (this.state.id !== null) {
        var promise;
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().patch(`/virtualGallery/${this.state.id}/save`, {
            name: event.target.elements.formName.value,
            description: this.state.virtualGallery.description,
            isUserGallery: this.state.virtualGallery.isUserGallery,
            defaultUserGallery: this.state.virtualGallery.defaultUserGallery,
            type: this.state.virtualGallery.type.name,
            status: this.state.virtualGallery.status.name
          })
            .then(() => {
              this.setState({
                initialStatus: this.state.virtualGallery.status.name,
              }, () => resolve())
            })
            .catch(err => reject(err))
        });        
      } else {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().post(`/virtualGalleries`, {
            name: event.target.elements.formName.value,
            description: this.state.virtualGallery.description,
            isUserGallery: this.state.virtualGallery.isUserGallery,
            defaultUserGallery: this.state.virtualGallery.defaultUserGallery,
            arPortal: `/arPortals/${this.state.virtualGallery.arPortal.id}`,
            type: this.state.virtualGallery.type.name,
            status: this.state.virtualGallery.status.name,
            caVirtualGallery: true,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          })
            .then((response) => {
              const parts = response.data._links.self.href.split('/');
              const id = parts[parts.length - 1];
              this.setState({
                id: id,
              })
              this.props.history.push(`/virtualGalleryEdit/${id}`)
              resolve()
            })
            .catch(err => reject(err))
        });
      }

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      })
        .catch(err => {
          var errorMessage = "GENERAL.SAVE_ERROR";
          if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }

          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage(errorMessage));
        })
    } else {
      toast.error(validation.message);
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
              <div className="pr-2 d-flex align-items-center">
                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                <div className="ca-page-title">{translatedMessage("VIRTUAL_GALLERY.EDIT")}</div>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" type="submit" form="arPortal-form">
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} />
                </Button>

                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push("/virtualGalleryList")}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            <Form id="arPortal-form" className="ca-form" onSubmit={this.saveChanges}>
              <Row className="mb-0">
                <Col className="col-12 col-md-6">
                  <Form.Group controlId="formName">
                    <Form.Label>{translatedMessage("GENERAL.NAME")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={translatedMessage("VIRTUAL_GALLERY.NAME_PLACEHOLDER")}
                      defaultValue={this.state.virtualGallery.name}
                    />
                  </Form.Group>

                  <Form.Group controlId="formArPortal">
                    <Form.Label>{translatedMessage("AR_PORTAL.AR_PORTAL")}</Form.Label>
                    <Dropdown className="ca-dropdown" onSelect={this.handleArPortalSelect} >
                      <Dropdown.Toggle disabled={this.state.virtualGallery.portalName !== null && !this.state.isNew}>
                        {this.state.virtualGallery.portalName ? this.state.virtualGallery.portalName : translatedMessage("GENERAL.SELECT_LIST")}
                      </Dropdown.Toggle>

                      {(!this.state.virtualGallery.portalName || this.state.isNew) &&
                        <Dropdown.Menu>
                          {this.state.arPortals.map((element, index) => {
                            return (
                              <Dropdown.Item key={index} eventKey={element.id}>
                                {element.name}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      }
                    </Dropdown>
                  </Form.Group>

                  <Form.Group controlId="formType">
                    <Form.Label>
                      {translatedMessage("GENERAL.TYPE")}
                      <OverlayTrigger
                        key='type'
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-type`} className="ca-tooltip text-left">
                            <p>{translatedMessage("AR_PORTAL.TYPE.ART_PORTAL.INFO")}</p>
                            <p>{translatedMessage("AR_PORTAL.TYPE.ARTIST_PORTAL.INFO")}</p>
                            <p>{translatedMessage("AR_PORTAL.TYPE.VIRTUAL_GALLERY.INFO")}</p>
                            <p>{translatedMessage("AR_PORTAL.TYPE.OWN_VIRTUAL_GALLERY.INFO")}</p>
                          </Tooltip>
                        }
                      >
                        <span className="ca-question-icon"></span>
                      </OverlayTrigger>
                    </Form.Label>
                    <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect} >
                      <Dropdown.Toggle>
                        {translatedMessage(this.state.virtualGallery.type.label)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {this.state.typeList.map((element, index) => {
                          return (
                            <Dropdown.Item key={index} eventKey={element.name}>
                              {translatedMessage(element.label)}
                            </Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group controlId="formStatus">
                    <Form.Label>{translatedMessage("GENERAL.STATUS")}</Form.Label>
                    <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect} >
                      <Dropdown.Toggle>
                        {translatedMessage(this.state.virtualGallery.status.label)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {this.state.statusList.map((element, index) => {
                          return (
                            <Dropdown.Item key={index} eventKey={element.name}>
                              {translatedMessage(element.label)}
                            </Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group controlId="formUserGallery">
                    <Form.Label>
                      {translatedMessage("VIRTUAL_GALLERY.IS_USER_GALLERY")}
                      <OverlayTrigger
                        key='hA'
                        placement='right'
                        overlay={
                          <Tooltip id={`tooltip-hA`}>
                            {translatedMessage("VIRTUAL_GALLERY.IS_USER_GALLERY_DESC")}
                          </Tooltip>
                        }
                      >
                        <span className="ca-question-icon"></span>
                      </OverlayTrigger>
                    </Form.Label>
                    <br />
                    <Switch
                      onChange={this.handleUserGallerySwitchChange}
                      checked={this.state.virtualGallery.isUserGallery}
                      offColor="#F00"
                    />
                  </Form.Group>
                  <Form.Group controlId="formDefaultGallery">
                    <Form.Label>
                      {translatedMessage("VIRTUAL_GALLERY.DEFAULT_USER_GALLERY")}
                      <OverlayTrigger
                        key='dG'
                        placement='right'
                        overlay={
                          <Tooltip id={`tooltip-dG`}>
                            {translatedMessage("VIRTUAL_GALLERY.DEFAULT_USER_GALLERY_DESC")}
                          </Tooltip>
                        }
                      >
                        <span className="ca-question-icon"></span>
                      </OverlayTrigger>
                    </Form.Label>
                    <br />
                    <Switch
                      onChange={this.handleDefaultSwitchChange}
                      checked={this.state.virtualGallery.defaultUserGallery}
                      disabled={!this.state.virtualGallery.isUserGallery}
                      offColor="#F00"
                    />
                  </Form.Group>
                </Col>

                <Col className="col-12 col-md-6">
                  <Form.Group controlId="formDescription">
                    <Form.Label>{translatedMessage("GENERAL.DESCRIPTION")}</Form.Label>
                    <div className="ca-text-editor">
                      <SunEditor
                        name="desc-editor"
                        lang={LanguageService.getCurrentLanguage()}
                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                        setContents={this.state.virtualGallery.description}
                        setOptions={{
                          height: 150,
                          maxWidth: "100%",
                          buttonList: this.state.editorButtns,
                          font: this.state.editorFonts,
                          defaultStyle: this.state.editorDefaultStyle
                        }}
                        onChange={this.onChangeEditor}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        )
      }
  }

}

export default VirtualGalleryEditPage;