import React from 'react';

import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/api.service';

import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';

class EntityArtCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Card className="ca-artwork-card">
        <Link to={this.props.cardLink} className="ca-dark-link">
          <Card.Img variant="top" src={this.props.cardImage} />
          <Card.Body>
            <Card.Title>
              {this.props.cardTitle}
              <span className="font-weight-normal" style={{ fontSize: "15px" }}>
                {this.props.cardAuthor &&
                  " " + translatedMessage("GENERAL.BY").toLowerCase() + " " + this.props.cardAuthor
                }
              </span>
            </Card.Title>
            {this.props.entity &&
              <Card.Text className="font-bold">{translatedMessage(this.props.entityName)}</Card.Text>
            }
            {this.props.cardCategory &&
              <Card.Text>{translatedMessage("GENERAL.CATEGORY") + ": " + translatedMessage(this.props.cardCategory)}</Card.Text>
            }
          </Card.Body>
        </Link >
        {this.props.entity && (
            this.props.entityName === ApiService.getEntityName().ARTWORK || 
              (this.props.entityName === ApiService.getEntityName().VIRTUAL_GALLERY && 
                this.props.entity?.type?.name !== VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY) ) &&
          < Card.Footer >
            <Link to={{pathname: this.props.cardLink, state: {companyId: this.props.companyId}}} className="ca-button ca-color-white">
              {translatedMessage("GENERAL.BUY")}
            </Link>
          </Card.Footer>
        }
      </Card>
    )
  }

}

export default EntityArtCardComponent;