import React from 'react';

import exhibitionIcon from '../Resources/homepage/exhibition.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';

import { Link } from 'react-router-dom'
import { Row, Col, Form, InputGroup, Image } from 'react-bootstrap';

import CardComponent from "./ExhibitionCardComponent";
import { translatedMessage } from '../../../services/language.service';

const ENTER_KEY = 13;

class ExhibitionListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      allList: this.props.exhibitions,
      filteredList: this.props.exhibitions,
      isLoaded: false,
      canViewList: false,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  filter = () => {
    let result = this.state.allList;
    let searchInput = this.state.searchInput;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.allList.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    this.setState({
      filteredList: result
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={exhibitionIcon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{translatedMessage("EXHIBITION.EXHIBITIONS")}</div>
                {this.props.spaceName &&
                  <div className="ca-page-subtitle ca-muted-text">{this.props.spaceName ? this.props.spaceName : ""}</div>
                }
              </div>
            </div>

            <div>
              {this.props.backUrl &&
                <Link to={`${this.props.backUrl}`} className="ca-dark-link">
                  <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                    title={translatedMessage("BUTTON.BACK")} />
                </Link>
              }
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-xl-6 mb-1 pl-0 pr-2">
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder={translatedMessage("SEARCH.BY.NAME")} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>

          <Row>
            {this.state.filteredList.map((element, index) => {
              return (
                <Col key={index} className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pl-0 pr-0">
                  <CardComponent
                    cardImage={element.imageURL}
                    cardTitle={element.name}
                    cardDetails={element}
                    id={0 === element.id ? 0 : element.id}
                    exhibitionPrefixUrl={this.props.exhibitionPrefixUrl}
                  />
                </Col>
              )
            })}
          </Row>
        </div >
      )
    }
  }
}

export default ExhibitionListComponent;