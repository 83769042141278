import React from 'react';

import { Modal, Button, Form } from 'react-bootstrap'

import moment from "moment";
import {toast} from "react-toastify";

import LanguageService from "../../../../services/language.service"
import ApiService from "../../../../services/api.service";

class CreateWebResourceComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const profile = `/profiles/${ApiService.getCurrentProfile().id}`;

        ApiService.getAuthenticatedInstance().post('/profileLinks', {
            name: event.target.elements.formName.value,
            uri: event.target.elements.formLink.value,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().unix(),
            profile
        }).then(result => {
            toast.success(window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.CREATE_SUCCES"));
            this.props.onHide();
        });
    };

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="web-resource-create-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.WEB_RESOURCE")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="ca-form" onSubmit={this.handleSubmit} id="createWebResourceForm">
                        <Form.Group controlId="formName">
                            <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.NAME")}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.NAME_PLACEHOLDER")}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLink">
                            <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.LINK")}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={window.$translate(LanguageService.getCurrentLanguage(), "WEB_RESOURCE.LINK_PLACEHOLDER")}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" type="submit" form="createWebResourceForm">
                        {window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.ADD")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default CreateWebResourceComponent