import React from "react";

import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';

import { translatedMessage } from '../../../../../services/language.service';
import ApiService from '../../../../../services/api.service';

import InvoiceDetailsModal from '../InvoiceDetailsModal';

import './TableComponent.css';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      pageLength: 10,
      itemsPerPage: 10,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: translatedMessage("TABLE.PAGE"),
      ofText: translatedMessage("TABLE.OF"),
      rowsText: translatedMessage("TABLE.PAGE"),
      filtered: this.props.data,
      detailsModalVisible: false,
      invoice: {}
    };
  }

  onFilteredChange = (value, accessor) => {
    let data = this.state.data;

    let filtered = data.filter(item => item[accessor].toLowerCase().includes(value.toLowerCase()))

    this.setState({ filtered: filtered });
  }

  onShowModal = (row) => {
    this.setState({
      invoice: row.original,
      detailsModalVisible: true
    })
  }

  onHideModal = () => {
    this.setState({
      detailsModalVisible: false
    });
  }

  render() {
    const data = this.state.filtered;
    return (
      <div className="ca-table-sales" style={{ marginBottom: "100px" }}>
        <ReactTable
          data={data}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBotton={true}
          showPageSizeOptions={false}
          pageSize={this.state.itemsPerPage}
          multiSort={false}
          sortable={true}
          resizable={true}
          filterable={true}
          defaultPageSize={this.state.itemsPerPage}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          minRows={1}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
          }}
          columns={[
            {
              Header: translatedMessage("GENERAL.DATE"),
              accessor: "lastUpdatedOn",
              Cell: row => {
                return (
                  <span>
                    {row.original.date}
                  </span>
                )
              },
              Filter: ({ filter }) => (
                <input type="text" onChange={event => this.onFilteredChange(event.target.value, "date")} style={{ width: "100%" }} />
              )
            },
            {
              Header: translatedMessage("BILLING.ORDER"),
              accessor: "orderNumber",
            },
            {
              Header: translatedMessage("BILLING.AMOUNT"),
              accessor: "amount",
              Cell: row => {
                return (
                  <span>
                    {ApiService.getNumberSeparatedByDots(row.original.amount)}
                    &nbsp;
                    <span>{row.original.currency}</span>
                  </span>
                )
              },
            },
            {
              Header: translatedMessage("TAX.VAT"),
              accessor: "vat",
              Cell: row => {
                if(row.original.vat !== null) {
                return (
                  <span>
                    {ApiService.getNumberSeparatedByDots(row.original.vat)}
                    &nbsp;
                    <span>{row.original.currency}</span>
                  </span>
                )
                } else { return ( <span>-</span> ) }
              },
            },
            {
              Header: translatedMessage("TAX.ART_TAX"),
              accessor: "artTax",
              Cell: row => {
                if(row.original.artTax !== null) {
                return (
                  <span>
                    {ApiService.getNumberSeparatedByDots(row.original.artTax)}
                    &nbsp;
                    <span>{row.original.currency}</span>
                  </span>
                )
                } else { return ( <span>-</span> ) }
              },
            },
            {
              Header: translatedMessage("BILLING.PURCHASE"),
              accessor: "transactionDetail",
            }
          ]}
        />
        <InvoiceDetailsModal
          show={this.state.detailsModalVisible}
          onHide={this.onHideModal}
          invoice={this.state.invoice}
        />
      </div>
    );
  }
}

export default TableComponent
