import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { translatedMessage } from "../../../services/language.service";

import DropzoneComponent from "../DropzoneComponent/DropzoneComponent";

class AddFileModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        modalTitle: "",
        fileUsage: "",
        fileType: "",
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "",
        maxSize: 0,
        maxSizeDesc: "",
        multipleFiles: false,
        multipleFilesDesc: "",
        entity: null,
        storageitemlinked: false,
        dropzoneEnabled: true
      },
      changeFile: {
        index: 0,
      },
      storageId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: prevState.data };
    }
    else return null;
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
        }, this.props.onHide(true, uploadedFile))
      }, 500)
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
      });
    }    
  }  

  render() {
    return (
      <Modal
        {...this.props}
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {this.state.data.modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <DropzoneComponent
            componentId="artworkFile"
            fileUsage={this.state.data.fileUsage}
            fileType={this.state.data.fileType}
            deletePrevious={this.state.data.deletePrevious}
            deletedImage={this.state.data.deletedImage}
            deletePreviousDesc={this.state.data.deletePreviousDesc}
            fileFormatsDesc={this.state.data.fileFormatsDesc}
            maxSize={this.state.data.maxSize}
            maxSizeDesc={this.state.data.maxSizeDesc}
            multipleFiles={this.state.data.multipleFiles}
            multipleFilesDesc={this.state.data.multipleFilesDesc}
            recommendedShape={this.state.data.recommendedShape}
            entity={this.state.data.entity}
            entityUpdateAPILink={this.state.data.entityUpdateAPILink}
            storageitemlinked={this.state.data.storageitemlinked}
            fileFieldName={this.state.data.fileFieldName}
            onupload={this.onUploadFile}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button" form="editmodal-form" onClick={this.props.onHide} type="submit">{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddFileModalComponent;