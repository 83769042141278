import React from 'react';

import GalleryManageArtworkComponent from '../../../components/shared/Gallery/GalleryManageArtworkComponent';
import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';

import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';

class GalleryAdminArtworksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: this.props.match.params.id,
      isLoaded: false,
      canEdit: false,
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("GALLERY_EDIT"),
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <GalleryManageArtworkComponent
          history={this.props.history}
          canEdit={this.state.canEdit}
          isAdmin={true}
          companyId={null}
          icon={galleryIcon}
          galleryId={this.state.galleryId}
          backUrl={`/galleryAdminList`}
          mapUrl={`/gallery/${this.state.galleryId}/map`}
        />
      )
    }
  }

}

export default GalleryAdminArtworksList;
