import React, { useState } from "react";

import "./ConfirmPage.css"

import ApiService from "../../services/api.service";
import { toast } from 'react-toastify';
import calogo from '../../components/shared/Resources/calogo.svg'

import { Row, Col } from 'react-bootstrap'

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { translatedMessage } from "../../services/language.service"

function FormConfirm(props) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {

        } else {
            const password = event.target.elements.formPassword.value;
            const confirmPassword = event.target.elements.formConfirmPassword.value;

            if (password !== confirmPassword) {
                toast.error(translatedMessage("MESSAGE.PASSWORD_NO_MATCH.ERROR"))
                return;
            }

            const id = props.match.params.id;
            const confirmEmailToken = props.match.params.confirmEmailToken;

            if (!id || !confirmEmailToken) {
                toast.error(translatedMessage("MESSAGE.EMAIL_TOKEN.EXPIRED"))
                return;
            }

            ApiService.instance.post('/registration/confirm', {
                id,
                password,
                confirmEmailToken,
            }).then(() => {
                toast.success(translatedMessage("MESSAGE.EMAIL_CONFIRMATION.SUCCESS"));
                props.history.push('/login');
            }).catch(err => {
                console.log('err', err);
                let errorMessage = "MESSAGE.EMAIL_CONFIRMATION.ERROR"
                if (err.response.data.message) {
                    errorMessage = err.response.data.message;
                }
                toast.error(translatedMessage(errorMessage));
            });
        }

        setValidated(true);
    };

    return (
        <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col className="col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4">
                <Card className="confirm-page-card">
                    <Card.Body className="confirm-page-card-body">
                        <Card.Title className="confirm-page-card-title">
                            {translatedMessage("USER.SECURITY.CONFIRM_EMAIL.TITLE")}
                        </Card.Title>
                        <Form className="confirm-page-card-form" validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="formPassword">
                                <Form.Label>{translatedMessage("GENERAL:PASSWORD")}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder={translatedMessage("GENERAL:PASSWORD")}
                                    defaultValue=""
                                    minLength="8"
                                />
                            </Form.Group>
                            <Form.Group controlId="formConfirmPassword">
                                <Form.Label>{translatedMessage("USER.SECURITY.CONFIRM_NEW_PASSWORD")}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder={translatedMessage("USER.SECURITY.CONFIRM_NEW_PASSWORD")}
                                    defaultValue=""
                                    minLength="8"
                                />
                            </Form.Group>
                            <div className="confirm-page-card-submit-button-section">
                                <Button variant="dark" className="ca-button" type="submit">{translatedMessage("GENERAL.SAVE")}</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

class ConfirmPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: {
                password: '',
                confirmPassword: '',
            },
            validated: false,
        };

        this.confirm = this.confirm.bind(this);
    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [name]: value,
            }
        });
    };

    confirm(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({ validated: true });


    }

    render() {
        return (
            <div className="confirm-page-align">
                <Image className="confirm-page-logo" src={calogo} />
                <FormConfirm history={this.props.history} match={this.props.match} />
            </div>
        );
    }
}

export default ConfirmPage;
