import React from 'react';
import './ChangeStatusModalComponent.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import ApiService from '../../../../services/api.service';

import LanguageService from '../../../../services/language.service';
import Switch from "react-switch";

import {toast} from "react-toastify";

class ChangeStatusModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            changeStatusInfo: {
                id: '',
                fullName: '',
                status: false
            }
        }

        this.saveChanges = this.saveChanges.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps, context){
        this.setState({
            changeStatusInfo:{
                id: newProps.changestatusinfo.id,
                fullName: newProps.changestatusinfo.fullName,
                status: newProps.changestatusinfo.status === 'ACTIVE'?true:false,
            }
        })
    }

    changeStatusInfoChange = event => {
        this.setState({
            changeStatusInfo: {
                ...this.state.changeStatusInfo,
                status: event
            }
        }, () => console.log());
    }

    saveChanges = (event) => {
        event.preventDefault();
        ApiService.getAuthenticatedInstance().patch(`/users/${this.state.changeStatusInfo.id}`, {
            status: this.state.changeStatusInfo.status?'ACTIVE':'INACTIVE',
        }).then( res =>{
            toast.success(window.$translate(LanguageService.getCurrentLanguage(), "USER.CHANGE_STATUS.SUCCESS"));
            this.setState({ userInfo: {} });
            this.props.onHide();
        }).catch( err =>{
            toast.error(window.$translate(LanguageService.getCurrentLanguage(), "USER.CHANGE_STATUS.FAIL"));

        })
    };

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.changeStatusInfo.fullName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ca-form">
                        <div className="form-group">
                            <div className="form-label">{window.$translate(LanguageService.getCurrentLanguage(),"GENERAL.STATUS")}</div>
                            <Switch
                                onChange={this.changeStatusInfoChange}
                                checked={this.state.changeStatusInfo.status}
                                offColor="#F00"
                                id="status"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" className="ca-button" form="editmodal-form" onClick={this.saveChanges} type="submit">
                        {window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.SAVE_CHANGES")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ChangeStatusModalComponent