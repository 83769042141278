import React from 'react';

import { Row, Button, Image } from 'react-bootstrap';

import addIcon from '../../../components/shared/Resources/icons/add.svg';

import TableComponent from "./Components/TableComponent";

import ApiService from '../../../services/api.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

class ArPortalListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      arPortals: [],
      isLoaded: false,
      canList: false,
      canEdit: false,
      canView: false,
      canCreate: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(response => {
      const canList = hasPermission("AR_PORTAL_LIST");
      const canEdit = hasPermission("AR_PORTAL_EDIT");
      const canCreate = hasPermission("AR_PORTAL_CREATE");

      this.setState({
        canList: canList,
        canEdit: canEdit,
        canCreate: canCreate
      }, () => canList ? this.getData() : this.noPermission())
    });
  }

  noPermission() {
    this.setState({
      isError: true,
      errorMessage: "USER.SECURITY.MISSING_RIGHTS",
      isLoaded: true
    });
  }

  async getData() {
    let arPortals = await VirtualGalleryService.getAllArPortals()
      .then(response => {
        return response;
      })

    this.setState({
      arPortals: arPortals,
      filteredArPortals: arPortals,
      isLoaded: true
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("AR_PORTAL.AR_PORTALS")}</span>

            {!this.state.canCreate ? "" :
              <Button onClick={() => this.props.history.push(`/arPortalEdit`)} className="ca-dark-link ca-button-icon medium">
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} title={translatedMessage("GENERAL.ADD_NEW")} />
              </Button>
            }
          </Row>

          <TableComponent
            data={this.state.arPortals}
            sent={true}
          />
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }
}

export default ArPortalListPage;