import React from 'react';

import EntityLocation from '../../../../components/shared/EntityLocationComponent/EntityLocationComponent';
import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';

import MapService from "../../../../services/map.service";
import ApiService from '../../../../services/api.service';

class ExhibitionAdminLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      exhibitionId: this.props.match.params.exhibitionId,
      exhibition: [],
      locations: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      initialCenter: null,
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      canEdit: false,
      isError: false,
      errorMessage: "",
      company: null
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
    .then(response => {
      let company = response.data.company;

      if (company.status.name === 'ACTIVE') {
        this.setState({
          company: company,
          canEdit: true
        }, this.getData)
      } else {
        this.setState({
          isError: true,
          errorMessage: "COMPANY.NOT_APPROVED",
          isLoaded: true
        })
      }
    })
    .catch(err => {
      console.log(err)
      if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
        errorMessage = err.response.data.message;
      }

      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    });  
  }

  getData = () => {
    var exhibition = [];
    var locations = [];
    var promise = [];
    var pins = [];

    if (this.state.canEdit) {
      ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${this.state.exhibitionId}/manage`)
        .then(response => {
          exhibition = response.data.exhibition;
        })
        .then(() => {
          var initialCenter = {};

          promise.push(ApiService.getAuthenticatedInstance().get(`gallery/exhibitionLocation/${this.state.exhibitionId}`)
            .then(response => {
              locations = response.data;
              if (locations[0]) {
                initialCenter = {
                  lat: locations[0].locationLat,
                  lng: locations[0].locationLng,
                }
              } else {
                initialCenter = null
              }              
            })
          )

          promise.push(MapService.getLocationPinByType("EXHIBITION")
            .then(response => {
              pins = response;
            }))

          Promise.all(promise).then(() => {
            this.setState({
              exhibition: exhibition,
              locations: locations,
              location: locations[0] ? locations[0] : this.state.location,
              hasGeofence: locations[0] && locations[0].geofence ? true : false,
              pinIcon: pins[0] ? pins[0].imageUrl : null,
              initialCenter: initialCenter,
              isLoaded: true
            })
          })
        })
        .catch(err => {
          let errorMessage = "GENERAL.GET_DATA_ERROR";
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.exhibition.id}
            entityName={ApiService.getEntityName().EXHIBITION}
            mapLocations={[]}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={null}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.exhibition.name}
            parentEntityTitle={null}
            backLink={`/my-company/${this.state.companyId}/exhibition`}
            history={this.props.history}
            zoomLevel={14}
          />         
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ExhibitionAdminLocation;