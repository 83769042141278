import React from 'react';

import { Row, Col, Image, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';

import ApiService from '../../services/api.service';

import dashboard from '../../components/shared/Resources/homepage/dashboard.svg';

import { translatedMessage } from '../../services/language.service';

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collection: [],
      portfolio: [],
      investment: [],
      valuable: {},
      isError: false,
      errorMessage: "",
      isLoaded: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              callback: function () { return '' }
            },
          },
          y: {
              beginAtZero: true,
              ticks: {
                callback: function () { return '' }
              }              
          },
        },
        plugins: {
          legend: {
            display: false
          }
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(this.getData());
  }

  async getData() {
    var endDate = new moment().format("yyyy/MM/DD");
    var startDate = new moment().subtract(30, "days").format("yyyy/MM/DD");

    var labels = this.initializeLabels(new Date(startDate), new Date(endDate));

    const collection = await ApiService.getAuthenticatedInstance().get(`dashboard/top_collection?rows=3&startDate=${startDate}&endDate=${endDate}&interval=DAY`)
      .then(response => {
        var data = response.data;
        //console.log(data);
        data.map(async item => {
          var collectionDataSet = this.initializeMonth();

          item.artworkViewCountList.sort((a, b) => {
            var result;
            if(a.month < b.month){
              result = -1;
             } else {
              if(a.month === b.month && a.day < b.day){
                result = -1;
              } else {
                result = 1
              }
             }
            return result;
          });

          item.artworkViewCountList.forEach((stat, index) => {
            var currentDate = new Date(stat.year, stat.month - 1, stat.day);
            var dayDiff = this.dateDiff(ApiService.parseDateShort(startDate), currentDate);
            collectionDataSet[dayDiff - 1] = stat.count;            
          });

          var collectionData = this.initializeGraphData(collectionDataSet.length, labels);
          collectionData.datasets[0].data = collectionDataSet;

          item.dataSet = collectionData;
          return item;
        })

        return data;
      })
      .catch(err => console.log(err.message))


    const investment = await ApiService.getAuthenticatedInstance().get(`dashboard/top_investment?rows=3&startDate=${startDate}&endDate=${endDate}&interval=DAY`)
      .then(response => {
        var data = response.data;
        //console.log(data);
        data.map(async item => {
          var investmentDataSet = this.initializeMonth();

          item.artworkViewCountList.forEach(stat => {
            var currentDate = new Date(stat.year, stat.month - 1, stat.day);
            var dayDiff = this.dateDiff(ApiService.parseDateShort(startDate), currentDate);
            investmentDataSet[dayDiff - 1] = stat.count;
          });

          var investmentData = this.initializeGraphData(29, labels);
          investmentData.datasets[0].data = investmentDataSet;

          item.dataSet = investmentData;
          return item;
        })

        return data;
      })
      .catch(err => console.log(err.message))


    const portfolio = await ApiService.getAuthenticatedInstance().get(`dashboard/top_portfolio?rows=3&startDate=${startDate}&endDate=${endDate}&interval=DAY`)
      .then(response => {
        var data = response.data;
        //console.log(data);
        data.map(async item => {
          var portfolioDataSet = this.initializeMonth();

          item.artworkViewCountList.forEach(stat => {
            var currentDate = new Date(stat.year, stat.month - 1, stat.day);
            var dayDiff = this.dateDiff(ApiService.parseDateShort(startDate), currentDate);
            portfolioDataSet[dayDiff - 1] = stat.count;
          });

          var portfolioData = this.initializeGraphData(29, labels);
          portfolioData.datasets[0].data = portfolioDataSet;

          item.dataSet = portfolioData;
          return item;
        })

        return data;
      })
      .catch(err => console.log(err.message))

    const valuable = await ApiService.getAuthenticatedInstance().get(`dashboard/valuables`)
      .then(response => {
        var data = response.data;
        // console.log(data);

        data.creationRemaining = data.potentialValue - data.salesValue;
        data.investmentRemaining = data.investmentPotential - data.investmentSales;

        data.totalValue = data.collectionValue + data.creationRemaining + data.investmentRemaining + data.salesValue;
        return data;
      })
      .catch(err => console.log(err.message))

    this.setState({
      isLoaded: true,
      collection: collection,
      investment: investment,
      portfolio: portfolio,
      valuable: valuable
    })
  }

  initializeMonth = () => {
    var month = [];
    for (var i = 0; i < 30; i++) {
      month[i] = 0;
    }

    return month;
  }

  initializeLabels = (startDate, endDate) => {
    var labels = [];

    const currentDate = new Date(startDate)
    while (currentDate <= endDate) {
      labels.push(new moment(currentDate).format("yyyy/MM/DD"));
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return labels;
  }  

  initializeGraphData = (length, labels) => {        
    const data = {
      labels: labels,
      datasets: [
        {
          label: translatedMessage("DASHBOARD.GRAPH_VALUE_LABEL"),
          data: [],
          fill: false,
          backgroundColor: '#388E3C',
          borderColor: '#388E3C',
        },
      ],
    };

    return data;
  }

  dateDiff = (from, to) => {
    return Math.round((to - from) / (1000 * 60 * 60 * 24));
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <>
          <div className="ca-page-padding ca-main-container">
            <Row className="mb-3 pr-2 align-items-center">
              <Image src={dashboard} className="ca-page-title-icon" alt={translatedMessage("DASHBOARD.MY_DASHBOARD")} />
              <span className="ca-page-title">{translatedMessage("DASHBOARD.MY_DASHBOARD")}</span>
            </Row>
            <Row>
              {/* Collection */}
              <Col className="col-12 col-lg-6 ca-row-left-column">
                <Card className="ca-dashboard-card">
                  <Card.Header>
                    <Card.Title>{translatedMessage("DASHBOARD.COLLECTION.TOP3")}</Card.Title>
                  </Card.Header>
                  {this.state.collection.length !== 0 &&
                    <Card.Body className="">
                      {this.state.collection.map((item, index) => {
                        return (
                          <Row key={index} className="mb-2">
                            <Col className="art-image-col pl-0">
                              <div className="d-flex align-items-center justify-content-center h-100">
                                <Image src={ApiService.getBaseUrl() + '/storage/file/' + item.photoUuid} alt={item.artworkName} className="art-image"></Image>
                              </div>
                            </Col>
                            <Col className="art-desc-col pl-0">
                              <div className="d-flex align-items-center h-100">
                                <div>
                                  <span>{item.artworkName}</span><br />
                                  <span className="art-author">{translatedMessage("GENERAL.BY").toLowerCase() + ": " + item.author}</span><br />
                                  <span>{item.issueNumber + " / " + item.nrCopies}</span><br />
                                  <span>{translatedMessage("DASHBOARD.VALUE") + ": $" + ApiService.getNumberSeparatedByDots(item.price)}</span>
                                </div>
                              </div>
                            </Col>
                            <Col className="art-graph-col pl-0" title={translatedMessage("DASHBOARD.GRAPH_TITLE")}>
                              <Line data={item.dataSet} options={this.state.options} className="ca-dashboard-graphs" />
                            </Col>
                          </Row>
                        )
                      })}
                    </Card.Body>
                  }

                  {this.state.collection.length === 0 &&
                    <Card.Body className="pl-0 pr-0 d-flex align-items-center">
                      <div className="dashboard-no-data-container d-flex align-items-center justify-content-center pl-3 pr-3">
                        <div className="text-align-center">
                          <h5 className="mb-3">{translatedMessage("DASHBOARD.NO_COLLECTION")}</h5>
                          <Link to="/gallery">{translatedMessage("DASHBOARD.START_COLLECTION")}</Link>
                        </div>
                      </div>
                    </Card.Body>
                  }
                </Card>
              </Col>

              {/* Investments */}
              <Col className="col-12 col-lg-6 ca-row-right-column">
                <Card className="ca-dashboard-card">
                  <Card.Header>
                    <Card.Title>{translatedMessage("DASHBOARD.INVESTMENTS.TOP3")}</Card.Title>
                  </Card.Header>
                  {this.state.investment.length !== 0 &&
                    <Card.Body className="">
                      {this.state.investment.map((item, index) => {
                        return (
                          <Row key={index} className="mb-2">
                            <Col className="art-image-col pl-0">
                              <div className="d-flex align-items-center justify-content-center h-100">
                                <Image src={ApiService.getBaseUrl() + '/storage/file/' + item.photoUuid} alt={item.artworkName} className="art-image"></Image>
                              </div>
                            </Col>
                            <Col className="art-desc-col pl-0">
                              <div className="d-flex align-items-center h-100">
                                <div>
                                  <span>{item.artworkName}</span><br />
                                  <span className="art-author">{translatedMessage("GENERAL.BY").toLowerCase() + ": " + item.author}</span><br />
                                  <span>{item.issueNumber + " / " + item.nrCopies}</span><br />
                                  <span>{translatedMessage("DASHBOARD.VALUE") + ": $" + ApiService.getNumberSeparatedByDots(item.price)}</span>
                                </div>
                              </div>
                            </Col>
                            <Col className="art-graph-col pl-0" title={translatedMessage("DASHBOARD.GRAPH_TITLE")}>
                              <Line data={item.dataSet} options={this.state.options} className="ca-dashboard-graphs" />
                            </Col>
                          </Row>
                        )
                      })}
                    </Card.Body>
                  }

                  {this.state.investment.length === 0 &&
                    <Card.Body className="pl-0 pr-0 d-flex align-items-center">
                      <div className="dashboard-no-data-container d-flex align-items-center justify-content-center pl-3 pr-3">
                        <div className="text-align-center">
                          <h5 className="mb-3">{translatedMessage("DASHBOARD.NO_INVESTMENT")}</h5>
                          <Link to="/gallery">{translatedMessage("DASHBOARD.START_INVESTING")}</Link>
                        </div>
                      </div>
                    </Card.Body>
                  }
                </Card>
              </Col>

              {/* Portfolio */}
              <Col className="col-12 col-lg-6 ca-row-left-column">
                <Card className="ca-dashboard-card">
                  <Card.Header>
                    <Card.Title>{translatedMessage("DASHBOARD.PORTFOLIO.TOP3")}</Card.Title>
                  </Card.Header>
                  {this.state.portfolio.length !== 0 &&
                    <Card.Body className="">
                      {this.state.portfolio.map((item, index) => {
                        return (
                          <Row key={index} className="mb-2">
                            <Col className="art-image-col pl-0">
                              <div className="d-flex align-items-center justify-content-center h-100">
                                <Image src={ApiService.getBaseUrl() + '/storage/file/' + item.photoUuid} alt={item.artworkName} className="art-image"></Image>
                              </div>
                            </Col>
                            <Col className="art-desc-col pl-0">
                              <div className="d-flex align-items-center h-100">
                                <div>
                                  <span>{item.artworkName}</span><br />
                                  <span>{translatedMessage("DASHBOARD.SOLD") + ": " + item.salesNo + " / " + item.nrCopies}</span><br />
                                  <span>{translatedMessage("DASHBOARD.INCOME") + ": $" + ApiService.getNumberSeparatedByDots(item.salesValue)}</span><br />
                                  <span>{translatedMessage("DASHBOARD.POTENTIAL") + ": $" + ApiService.getNumberSeparatedByDots(item.totalValue)}</span>
                                </div>
                              </div>
                            </Col>
                            <Col className="art-graph-col pl-0" title={translatedMessage("DASHBOARD.GRAPH_TITLE")}>
                              <Line data={item.dataSet} options={this.state.options} className="ca-dashboard-graphs"/>
                            </Col>
                          </Row>
                        )
                      })}
                    </Card.Body>
                  }

                  {this.state.portfolio.length === 0 &&
                    <Card.Body className="pl-0 pr-0 d-flex align-items-center">
                      <div className="dashboard-no-data-container d-flex align-items-center justify-content-center pl-3 pr-3">
                        <div className="text-align-center">
                          <h5 className="mb-3">{translatedMessage("DASHBOARD.NO_CREATION")}</h5>
                          <span>
                            {translatedMessage("DASHBOARD.SEND_EMAIL")} &nbsp;
                            <a href="mailTo:hello@connected.art">hello@connected.art</a>
                            &nbsp; {translatedMessage("DASHBOARD.BECOME_ARTIST")}
                          </span>
                        </div>
                      </div>
                    </Card.Body>
                  }
                </Card>
              </Col>

              {/* Valuables */}
              <Col className="col-12 col-lg-6 ca-row-right-column">
                <Card className="ca-dashboard-card">
                  <Card.Header>
                    <Card.Title>{translatedMessage("DASHBOARD.VALUABLES")}</Card.Title>
                  </Card.Header>
                  <Card.Body className="">
                    <table className="dashboard-table">
                      <tbody>
                        <tr>
                          <td>{translatedMessage("DASHBOARD.COLLECTION") + ":"}</td>
                          <td>
                            {"$" + ApiService.getNumberSeparatedByDots(this.state.valuable.collectionValue ? this.state.valuable.collectionValue : 0)}
                            <OverlayTrigger
                              key='coll'
                              placement='right'
                              overlay={
                                <Tooltip id={`tooltip-coll`}>
                                  {translatedMessage("DASHBOARD.COLLECTION.TOOLTIP")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>{translatedMessage("DASHBOARD.CREATION") + ":"}</td>
                          <td>
                            {"$" + ApiService.getNumberSeparatedByDots(this.state.valuable.creationRemaining ? this.state.valuable.creationRemaining : 0)}
                            <OverlayTrigger
                              key='port'
                              placement='right'
                              overlay={
                                <Tooltip id={`tooltip-port`}>
                                  {translatedMessage("DASHBOARD.CREATION.TOOLTIP")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                            </td>
                        </tr>
                        <tr>
                          <td>{translatedMessage("DASHBOARD.ART_INVESTMENT") + ":"}</td>
                          <td>
                            {"$" + ApiService.getNumberSeparatedByDots(this.state.valuable.investmentRemaining ? this.state.valuable.investmentRemaining : 0)}
                            <OverlayTrigger
                              key='inv'
                              placement='right'
                              overlay={
                                <Tooltip id={`tooltip-inv`}>
                                  {translatedMessage("DASHBOARD.ART_INVESTMENT.TOOLTIP")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                        <tr>
                          <td>{translatedMessage("DASHBOARD.ACCUMULATED_CASH") + ":"}</td>
                          <td>
                            {"$" + ApiService.getNumberSeparatedByDots(this.state.valuable.salesValue ? this.state.valuable.salesValue : 0)}
                            <OverlayTrigger
                              key='cash'
                              placement='right'
                              overlay={
                                <Tooltip id={`tooltip-cash`}>
                                  {translatedMessage("DASHBOARD.ACCUMULATED_CASH.TOOLTIP")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>                            
                            </td>
                        </tr>
                        <tr>
                          <td><b>{translatedMessage("DASHBOARD.TOTAL_VALUE") + ":"}</b></td>
                          <td><b>{"$" + ApiService.getNumberSeparatedByDots(this.state.valuable.totalValue ? this.state.valuable.totalValue : 0)}</b></td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )
    }
  }
}

export default DashboardPage;
