import React from 'react';

import ArtworkViewComponent from '../../../../components/shared/Artwork/ArtworkViewComponent';

class ViewPortfolioArtworkPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount = () => {}

    render() {
        return (
            <ArtworkViewComponent
              artworkId={this.props.match.params.artworkId}
              canView={true}
              isAuthor={true}
              backLink={`/my-company/${this.props.match.params.id}/portfolio`}
              editLink={`/my-company/${this.props.match.params.id}/portfolio-artwork-edit/${this.props.match.params.artworkId}`}
              history={this.props.history}
            />
          )        
    }
}

export default ViewPortfolioArtworkPage