import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';

import ApiService from '../../../services/api.service';
import MapService from '../../../services/map.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

class LocationPinList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allList: [],
      typeList: [],
      isLoaded: false,
      canViewList: false,
    };

  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canViewList: hasPermission("USER_LIST")
      }, this.getData())
    });
  }

  getData() {
    var pins = [];
    var typeList = [];
    var promise = [];

    promise.push(MapService.getLocationTypeList()
      .then(response => {
        typeList = response;
      }))

    promise.push(MapService.getLocationPins()
      .then(response => {
        pins = response;
      }))

    Promise.all(promise).then(() => {
      this.setState({
        allList: pins,
        typeList: typeList,
        isLoaded: true
      })
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canViewList) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2">
            <span className="ca-page-title">{translatedMessage("MAP.LOCATION_PINS")}</span>

            {/* {!hasPermission("USER_CREATE") ? "" :
              <Button onClick={this.handleAdd} className="ca-button">
                {translatedMessage("GENERAL.ADD_NEW")}
              </Button>
            } */}
          </Row>

          <Row>
              {this.state.allList.map((element, index) => {
                return (
                  <Col key={index} className="col-4 col-md-2" style={{textAlign: "center"}}>
                    <Image src={element.imageUrl} style={{marginBottom: "15px", height: "50px"}}/>
                    <br/>
                    <div>
                      {translatedMessage("LOCATION_PIN." + element.type.label)}
                    </div>
                  </Col>  
                )
              })}            
          </Row>
        </div>
      )
    }  else {
      return (
          <ErrorComponent errorMessage={"USER.SECURITY.MISSING_RIGHTS"} history={this.props.history} />
      )
    }
  }
}

export default LocationPinList;