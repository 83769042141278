import React from 'react';
import './UsersPage.css';

import { Col, Row, Button, Form, Dropdown } from 'react-bootstrap'

import ApiService from '../../services/api.service'
import { hasPermission } from '../../services/api.service';

import { translatedMessage } from '../../services/language.service';

import CreateModalComponent from './ModalComponents/CreateModalComponent/CreateModalComponent'
import EditModalComponent from './ModalComponents/EditModalComponent/EditModalComponent'
import ViewModalComponent from './ModalComponents/ViewModalComponent/ViewModalComponent'
import ChangeStatusModalComponent from './ModalComponents/ChangeStatusModalComponent/ChangeStatusModalComponent'
import TableComponent from './TableComponent/TableComponent'

class UsersPage extends React.Component {

    constructor(props) {
        super(props);

        this.onSearchValueChanged = this.onSearchValueChanged.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);

        this.state = {
            userId: '',
            searchValue: '',
            viewModalVisible: false,
            createModalVisible: false,
            editModalVisible: false,
            changeStatusModalVisible: false,
            userInfo: {
                id: '',
                username: '',
                firstName: '',
                lastName: '',
                fullName: '',
                membership: 1,
                roles: [],
                status: '',
            },
            changeStatusInfo: {
                id: '',
                status: '',
                fullName: ''
            },
            filterOptions: {
                type: 'ANY_TYPE',
                "membership": 'ANY_MEMBERSHIP',
                status: 'ANY_STATUS'
            },
            filters: {
                type: 'ANY_TYPE',
                "membership": 'ANY_MEMBERSHIP',
                status: 'ANY_STATUS'
            },
            tableReloaded: null,
            isLoaded: false,
            canViewList: false
        };
    }

    componentDidMount() {
        if (!ApiService.getCurrentUser()) {
            ApiService.getAuthenticatedInstance().get('/user/me')
                .then(result => {
                    ApiService.setCurrentUser(result.data);
                    this.setState({
                        canViewList: hasPermission("USER_LIST"),
                        isLoaded: true
                    })
                })
        } else {
            this.setState({
                canViewList: hasPermission("USER_LIST"),
                isLoaded: true
            })
        }
    }

    onHideUserDetails = () => {
        this.setState({ viewModalVisible: false });
    }

    onShowUserDetails = (id) => {
        this.setState({ viewModalVisible: false });
        if (this.state.viewModalVisible === false) {

            this.setState({ userId: id }, () => {
                ApiService.getAuthenticatedInstance().get("/user/get/" + id).then(result => {
                    var user = result.data.user
                    this.setState({
                        userInfo: {
                            username: user.username,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            fullName: user.firstName + ' ' + user.lastName,
                            membership: user.membershipName,
                            roles: user.roleNames ? user.roleNames : [],
                            status: user.status,
                        },
                        viewModalVisible: true
                    });
                })
            })

            return;
        }
    }

    onHideEditUser = () => {
        this.setState({ editModalVisible: false });
        this.reloadTable();
    }

    onShowEditUser = (id) => {
        if (this.state.editModalVisible === false) {
            this.setState({ userId: id }, () => {
                ApiService.getAuthenticatedInstance().get("/user/get/" + id)
                    .then(result => {
                        var user = result.data.user
                        this.setState({
                            userInfo: {
                                id: id,
                                username: user.username,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                fullName: user.firstName + ' ' + user.lastName,
                                membership: user.membership,
                                roles: user.roles ? user.roles : [],
                                status: user.status,
                            },
                            editModalVisible: true
                        });
                    })
            });

            return;
        }
    }

    onHideChangeUserStatus = () => {
        this.setState({ changeStatusModalVisible: false });
        this.reloadTable();
    }

    onShowChangeUserStatus = (object) => {
        if (this.state.changeStatusModalVisible === false) {
            this.setState({
                userId: object.id,
                changeStatusInfo: object,
                changeStatusModalVisible: true
            })

            return;
        }
    }

    onHideCreateUser = () => {
        this.setState({ createModalVisible: false });
        this.reloadTable();
    }

    onShowCreateUser = () => {
        if (this.state.createModalVisible === false) {
            this.setState({ createModalVisible: true });
            return;
        }
    }

    onSearchValueChanged(e) {
        let searchTerm = e.target.value;
        this.setState({ searchValue: searchTerm }, () => {
            this.reloadTable()
        });
    }

    onFilterChanged = (filterOptionName, filterOptionValue) => {
        let filters = this.state.filters;
        let filterOptions = this.state.filterOptions;

        let filterName = filterOptionName;
        let filterValue = filterOptionValue;

        if (filterName === "type") {
            const typeTranslate = {
                ARTIST: translatedMessage("PROFILE.ARTIST"),
                ART_CONSUMER: translatedMessage("PROFILE.ART_CONSUMER"),
                ART_INSTITUTION: translatedMessage("PROFILE.ART_INSTITUTION"),
                EXPERT_CO_WORKER: translatedMessage("PROFILE.EXPERT_CO_WORKER"),
                INVESTOR: translatedMessage("PROFILE.ART_INVESTOR"),
                ANY_TYPE: "Any"
            }

            for (let key in typeTranslate) {
                if (typeTranslate.hasOwnProperty(key)) {
                    filters[typeTranslate[key]] = "ANY";
                }
            }

            filterName = typeTranslate[filterValue];

            if (filterName === "any") {
                filterValue = "ANY";
            } else {
                filterValue = "true";
            }

        }

        filters[filterName] = filterValue;
        filterOptions[filterOptionName] = filterOptionValue;

        this.setState({ filters: filters, filterOptions: filterOptions }, () => {
            this.reloadTable()
        });
    }

    reloadTable() {
        this.setState({
            tableReloaded: new Date().getTime()
        });
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else if (this.state.canViewList) {
            return (
                <>
                    <div className="ca-page-padding ca-main-container">
                        <ViewModalComponent
                            show={this.state.viewModalVisible}
                            onHide={this.onHideUserDetails}
                            userInfo={this.state.userInfo}
                        />

                        <EditModalComponent
                            show={this.state.editModalVisible}
                            onHide={this.onHideEditUser}
                            userInfo={this.state.userInfo}
                        />

                        <ChangeStatusModalComponent
                            show={this.state.changeStatusModalVisible}
                            onHide={this.onHideChangeUserStatus}
                            changestatusinfo={this.state.changeStatusInfo}
                        />

                        <CreateModalComponent
                            show={this.state.createModalVisible}
                            onHide={this.onHideCreateUser}
                        />

                        <Row className="ca-page-title-button-row mb-3 pr-2">
                            <span className="ca-page-title">{translatedMessage("USER.USERS")}</span>

                            {!hasPermission("USER_CREATE") ? "" :
                                <Button onClick={this.onShowCreateUser} className="ca-button">
                                    {translatedMessage("USER.ADD_USER")}
                                </Button>
                            }
                        </Row>

                        <Row>
                            <Col className="p-0">
                                <Row>
                                    <Col className="col-12 col-sm-6 col-lg-3 mb-1 pl-0 pr-2">
                                        <Form.Control value={this.state.searchValue} onChange={this.onSearchValueChanged} className="ca-form-control-search" type="text" placeholder="&#xF002;" />
                                    </Col>
                                    <Col className="col-12 col-sm-6 col-lg-3 mb-1 pl-0 pr-2">
                                        <Dropdown className="ca-dropdown">
                                            <Dropdown.Toggle>
                                                {translatedMessage("USER.TYPE." + this.state.filterOptions.type)}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="users-dropdown-menu">
                                                <Dropdown.Item onClick={() => this.onFilterChanged('type', 'ANY_TYPE')}>{translatedMessage("USER.TYPE.ANY_TYPE")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('type', 'ARTIST')}>{translatedMessage("USER.TYPE.ARTIST")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('type', 'ART_CONSUMER')}>{translatedMessage("USER.TYPE.ART_CONSUMER")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('type', 'ART_INSTITUTION')}>{translatedMessage("USER.TYPE.ART_INSTITUTION")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('type', 'EXPERT_CO_WORKER')}>{translatedMessage("USER.TYPE.EXPERT_CO_WORKER")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('type', 'INVESTOR')}>{translatedMessage("USER.TYPE.INVESTOR")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col className="col-12 col-sm-6 col-lg-3 mb-1 pl-0 pr-2">
                                        <Dropdown className="ca-dropdown">
                                            <Dropdown.Toggle>
                                                {translatedMessage("MEMBERSHIP." + this.state.filterOptions.membership)}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="users-dropdown-menu">
                                                <Dropdown.Item onClick={() => this.onFilterChanged('membership', "ANY_MEMBERSHIP")}>{translatedMessage("MEMBERSHIP.ANY_MEMBERSHIP")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('membership', 'Member')}>{translatedMessage("MEMBERSHIP.MEMBER")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('membership', 'Club')}>{translatedMessage("MEMBERSHIP.CLUB")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('membership', 'Vip')}>{translatedMessage("MEMBERSHIP.VIP")}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col className="col-12 col-sm-6 col-lg-3 pl-0 pr-2">
                                        <Dropdown className="ca-dropdown">
                                            <Dropdown.Toggle>
                                                {translatedMessage("USER.STATUS." + this.state.filterOptions.status)}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="users-dropdown-menu">
                                                <Dropdown.Item onClick={() => this.onFilterChanged('status', 'ANY_STATUS')}>{translatedMessage("USER.STATUS.ANY_STATUS")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('status', 'ACTIVE')}>{translatedMessage("USER.STATUS.ACTIVE")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('status', 'INACTIVE')}>{translatedMessage("USER.STATUS.INACTIVE")}</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.onFilterChanged('status', 'PENDING_PAYMENT')}>{translatedMessage("USER.STATUS.PENDING_PAYMENT")}</Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <TableComponent
                            searchValue={this.state.searchValue}
                            filters={this.state.filters}

                            showModal={this.onShowUserDetails}
                            editModal={this.onShowEditUser}
                            changeStatusModal={this.onShowChangeUserStatus}
                            tableReloaded={this.state.tableReloaded}
                        />
                    </div>
                </>
            )
        } else {
            return (
                <div className="ca-page-padding ca-main-container">
                    <Row className="mb-3 pr-2">
                        <span className="ca-page-title">{translatedMessage("USER.SECURITY.MISSING_RIGHTS")}</span>
                    </Row>
                </div>
            )
        }
    }
}

export default UsersPage;