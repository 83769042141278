import React from "react";

import "./ConfirmPage.css"

import ApiService from "../../services/api.service";
import calogo from '../../components/shared/Resources/calogo.svg'

import Image from "react-bootstrap/Image";

import { translatedMessage } from "../../services/language.service"

class ConfirmEmailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isError: true,
            errorMessage: "",
            errorMessageDetails: ""
        };
    }

    componentDidMount = () => {
        const id = this.props.match.params.id;
        const confirmEmailToken = this.props.match.params.confirmEmailToken;

        ApiService.instance.post('/registration/confirm-email', {
            id,
            confirmEmailToken,
        }).then(() => {
            this.setState({
                isError: false
            })
        }).catch(err => {
            console.log('err', err);
            let errorMessage = "MESSAGE.EMAIL_CONFIRMATION.ERROR"
            console.log(err.response)
            if (err.response && err.response.status && (err.response.status === 400 || err.response.status === 404) && err.response.data ) {
                errorMessage = err.response.data.message;
            }
            
            this.setState({
                isError: true,
                errorMessage: translatedMessage(errorMessage),
                errorMessageDetails: err.response.data.message === "EMAIL_TOKEN_EXPIRED" ? "" : translatedMessage("EMAIL.CONFIRM.ERROR.TRY_AGAIN")
            })
        });        
    }

    render() {
        return (
            <div className="confirm-page-align">
                <Image className="confirm-page-logo" src={calogo} />
                {!this.state.isError &&
                    <div className="w-100 ca-color-white text-align-center" style={{ fontSize: "20px" }}>
                        <div className="mb-3">{translatedMessage("EMAIL.CONFIRM.SUCCESS")}</div>
                        <div>{translatedMessage("EMAIL.CONFIRM.THANKS")}</div>
                    </div>
                }
                {this.state.isError &&
                    <div className="w-100 ca-color-white text-align-center" style={{ fontSize: "20px" }}>
                        <div className="mb-3">{this.state.errorMessage}</div>
                        <div>{this.state.errorMessageDetails}</div>
                    </div>
                }                
            </div>
        );
    }
}

export default ConfirmEmailPage;
