import React from 'react';

import calogo from '../../../components/shared/Resources/defaultGalleryPicture.png';
import exhibitionIcon from '../../../components/shared/Resources/icons/exhibition.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';

import { Row, Col, Dropdown, Form, Button, Image, Card } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ApiService from '../../../services/api.service';
import GalleryService from '../../../services/gallery.service';
import FileService from '../../../services/file.service';
import LanguageService from '../../../services/language.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import DropzoneComponent from "../DropzoneComponent/DropzoneComponent";
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import moment from 'moment';

class ExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibition: {},
      statusList: [],
      isLoaded: false,
      dropzoneEnabled: true,
      displayImg: true,
      changeEnabled: false,
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      canEdit: false,
      isError: false,
      errorMessage: "",
      isNew: false
    };
  }

  componentDidMount = () => {
    if (this.props.canEdit) {
      this.getData();
    } else {
      this.setState({
        isError: true,
        errorMessage: translatedMessage("USER.SECURITY.MISSING_RIGHTS"),
        isLoaded: true
      })
    }
  }

  async getData() {
    var exhibition = {};
    var isNew = false;
    let isError = false;
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    var statusList = await GalleryService.getExhibitionStatusList()
      .then(response => {
        return response;
      });

    if (this.props.exhibitionId !== null) {
      exhibition = await ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${this.props.exhibitionId}/manage`)
        .then(response => {
          return response.data.exhibition;
        })
        .catch(err => {
          isError = true;
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }
        })
    } else {
      isNew = true;
    }

    if (!isError) {
      if (isNew) {
        const statusItem = statusList.filter(item => item.name === 'UNPUBLISHED');
        exhibition.status = statusItem[0];
      }

      this.setState({
        statusList: statusList,
        exhibition: exhibition,
        galleryImgUrl: isNew ? "" : (exhibition.photoUuid === null ? calogo : ApiService.getFileByUuid(exhibition.photoUuid) + '?' + Date.now()),
        isNew: isNew,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    }
  }

  handleOnStatusSelect = (eventKey) => {
    const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
    this.setState({
      exhibition: {
        ...this.state.exhibition,
        status: selectedItem[0]
      }
    });
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          displayImg: !this.state.displayImg,
          exhibition: {
            ...this.state.exhibition,
            photo: uploadedFile,
            photoUuid: uploadedFile.uuid
          },
          galleryImgUrl: ApiService.getFileByUuid(uploadedFile.uuid) + '?' + Date.now(),
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        exhibition: {
          ...this.state.exhibition,
          photo: uploadedFile
        },
      });
    }
  }

  handleStartDate = (event) => {
    this.setState({
      exhibition: {
        ...this.state.exhibition,
        startTime: isNaN(Date.parse(event)) ? null : new Date(event)
      },
    });
  }

  handleEndDate = (event) => {
    this.setState({
      exhibition: {
        ...this.state.exhibition,
        endTime: isNaN(Date.parse(event)) ? null : new Date(event)
      },
    });
  }

  validate = () => {
    var response = {
      isError: false,
      message: ""
    };

    let startDate = this.state.exhibition.startTime;
    let endDate = this.state.exhibition.endTime;
    if (startDate === null && endDate !== null) {
      response.isError = true;
      response.message = translatedMessage("DATE.START_EMPTY.ERROR");
      return response;
    }
    if (startDate !== null && endDate !== null && endDate < startDate) {
      response.isError = true;
      response.message = translatedMessage("DATE.START_AFTER_END.ERROR");
      return response;
    }

    let description = this.state.exhibition.description
      ? this.state.exhibition.description.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '').replace(/<br>/g, '')
      : null;

    if (this.state.exhibition.status.name === "PUBLISHED" && !description) {
      response.isError = true;
      response.message = translatedMessage("EXHIBITION.DETAILS.MISSING");
      return response;
    }

    if (this.state.exhibition.status.name === "PUBLISHED" && !this.state.exhibition.photoUuid) {
      response.isError = true;
      response.message = translatedMessage("EXHIBITION.PHOTO.MISSING");
      return response;
    }

    return response;
  }

  changeImgDropzone = () => {
    this.setState({ displayImg: !this.state.displayImg })
  }

  onChangeEditor = (e) => {
    this.setState({
      exhibition: {
        ...this.state.exhibition,
        description: e,
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const validation = this.validate(event);
    if (!validation.isError) {
      var promise;
      if (this.props.exhibitionId !== null) {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().patch(`/exhibitions/${this.props.exhibitionId}`, {
            name: event.target.elements.formName.value,
            description: this.state.exhibition.description,
            status: this.state.exhibition.status.name,
            startTime: this.state.exhibition.startTime,
            endTime: this.state.exhibition.endTime,
            caExhibition: this.props.companyId ? false : true,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then(() => resolve())
            .catch(err => reject(err))
        });
      }
      else {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().post(`/exhibitions`, {
            name: event.target.elements.formName.value,
            description: this.state.exhibition.description,
            status: this.state.exhibition.status.name,
            startTime: this.state.exhibition.startTime,
            endTime: this.state.exhibition.endTime,
            caExhibition: this.props.companyId ? false : true,
            ownerCompany: this.props.companyId ? `/company/${this.props.companyId}` : null,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then((response) => {
              const parts = response.data._links.self.href.split('/');
              const id = parts[parts.length - 1];
              this.setState({
                galleryImgUrl: calogo,
                exhibitionId: id,
              })

              if (this.props.companyId) {
                ApiService.getAuthenticatedInstance().post(`/gallery/company-exhibition/${id}/permissions/add`)
                  .catch(err => console.log("An error has ocurred: " + err))
              } else {
                ApiService.getAuthenticatedInstance().post(`/gallery/exhibition/${id}/CA-permissions/add`)
                  .catch(err => console.log("An error has ocurred: " + err))
              }

              this.props.history.push(`${this.props.redirectNewUrl}${id}`)
              resolve()
            })
            .catch(err => reject(err))
        });
      }

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      toast.error(validation.message);
    }
  }

  render() {
    let img_class = this.state.displayImg ? "ca-form-image-container" : "ca-form-image-container-hide";
    let dropzone_class = !this.state.displayImg ? "ca-dropzone-show" : "ca-dropzone-hide";

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={exhibitionIcon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITION")} />
              <span className="ca-page-title">{translatedMessage("EXHIBITION.EDIT")}</span>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.dropzoneEnabled ? "" : "disabled")}
                type="submit" form="exhibition-form">
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button className={"ca-dark-link ca-button-icon ".concat(this.state.dropzoneEnabled ? "" : "disabled")} variant="link"
                onClick={() => this.props.history.push(this.props.backUrl)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>
          <Form id="exhibition-form" className="ca-form" onSubmit={this.handleSubmit}>
            <Form.Row className="mb-0">
              <Form.Group as={Col} lg="8" className="mb-0">
                <Form.Row>
                  <Form.Group as={Col} lg="6" controlId="formName">
                    <Form.Label>
                      {translatedMessage("EXHIBITION.NAME")}
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder={translatedMessage("EXHIBITION.NAME_PLACEHOLDER")}
                      defaultValue={this.state.exhibition.name}
                    />
                  </Form.Group>

                  {!this.state.isNew
                    ?
                    <Form.Group as={Col} lg="6" controlId="formStatus">
                      <Form.Label>
                        {translatedMessage("GENERAL.STATUS")}
                      </Form.Label>
                      <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                        <Dropdown.Toggle>
                          {translatedMessage(this.state.exhibition.status.label)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {this.state.statusList.map((element, index) => {
                            return (
                              <Dropdown.Item key={index} eventKey={element.name} disabled={this.state.isNew}>
                                {translatedMessage(element.label)}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                    :
                    <Form.Group as={Col} lg="6">
                      <Form.Label>{translatedMessage("GENERAL.STATUS")} </Form.Label>
                      <Form.Control type="text" disabled = {true} defaultValue={translatedMessage("EXHIBITION_STATUS.UNPUBLISHED")} />
                    </Form.Group>
                  }

                  <Form.Group as={Col} md="6" controlId="formStartDate" className="ca-profile-form-date">
                    <Form.Label>{translatedMessage("GENERAL.START_DATE")}</Form.Label>
                    <DatePicker
                      name="startDate"
                      showPopperArrow={false}
                      className="form-control"
                      selected={this.state.exhibition.startTime}
                      onChange={this.handleStartDate}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="formEndDate" className="ca-profile-form-date">
                    <Form.Label>{translatedMessage("GENERAL.END_DATE")}</Form.Label>
                    <DatePicker
                      name="endDate"
                      showPopperArrow={false}
                      className="form-control"
                      selected={this.state.exhibition.endTime}
                      onChange={this.handleEndDate}
                      dateFormat="dd/MM/yyyy"
                      minDate={this.state.exhibition.startTime}
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="12" controlId="formDescription">
                    <Form.Label>{translatedMessage("GENERAL.DETAILS")}</Form.Label>
                    <div className="ca-text-editor">
                      <SunEditor
                        name="project-editor"
                        lang={LanguageService.getCurrentLanguage()}
                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                        setContents={this.state.exhibition.description}
                        setOptions={{
                          height: 150,
                          maxWidth: "100%",
                          buttonList: this.state.editorButtns,
                          font: this.state.editorFonts,
                          defaultStyle: this.state.editorDefaultStyle
                        }}
                        onChange={this.onChangeEditor}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form.Group>

              {!this.state.isNew &&
                <Form.Group as={Col} lg="4" controlId="formGalleryImage" style={{ textAlign: "center" }}>
                  <div className={img_class} onClick={this.changeImgDropzone.bind(this)}>
                    <Card className="ca-gallery-card ml-0 mr-0">
                      <Card.Img variant="top" src={this.state.galleryImgUrl} />
                    </Card>

                    <div className="ca-form-image-change-details">
                      <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf030;</div>
                      <div>{translatedMessage("GENERAL.IMAGE.TOOLTIP")}</div>
                    </div>
                  </div>
                  <div className={dropzone_class}>
                    <DropzoneComponent
                      componentId="exhibitionImage"
                      fileUsage={FileService.getFileUsage().EXHIBITION}
                      fileType={FileService.getResourceType().IMAGE}
                      deletePrevious={true}
                      deletedImage={this.state.exhibition.photo}
                      deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                      fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                      maxSize={1}
                      maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                      multipleFiles={false}
                      multipleFilesDesc=""
                      entity={this.state.exhibition}
                      entityUpdateAPILink="exhibitions"
                      storageitemlinked={false}
                      onupload={this.onUploadFile}
                    />
                    <div>
                      <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeImgDropzone.bind(this)}>&#xf021;</div>
                    </div>
                  </div>
                </Form.Group>
              }
            </Form.Row>
          </Form>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionPage;