import React from 'react';
import { Row, Col, Button, Card, Image } from 'react-bootstrap/';

import './ViewArtworkPage.css';

import card1 from '../../components/shared/Resources/calogo-black.svg';
import soldout from '../../components/shared/Resources/sold_out.png';
import limitedEdition from '../../components/shared/Resources/limited_edition.png';
import back from '../../components/shared/Resources/left-arrow.svg';
import artIcon from '../../components/shared/Resources/icons/art.svg';

import ArtworkBuyModalComponent from './ArtworkBuyModalComponent/ArtworkBuyModalComponent';
import ApiService from '../../services/api.service';
import FileService from '../../services/file.service';
import ArtworkService from '../../services/artwork.service';
import { translatedMessage } from '../../services/language.service';

import DisplayVideoComponent from '../../components/shared/DisplayVideoComponent/DisplayVideoComponent';
import Carousel from "../../components/shared/Carousel/MediaCarouselComponent";
import ExperienceCardComponent from '../../components/shared/ExperienceCardComponent/ExperienceCardComponent';

import moment from 'moment';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


class ViewArtworkPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            artwork: {},
            storageItemId: null,
            authorName: "",
            author: [],
            artworkImages: [
                {
                    original: card1,
                    thumbnail: card1,
                }
            ],
            artworkCategory: "",
            artworkVideos: [],
            artworkBuyModalShow: false,
            artworkBuyModalData: {
                name: "",
                quantity: "",
                price: "",
                exclusive: "",
                description: "",
                nrCopies: "",
                availableCopies: ""
            },
            pricePackageData: [],
            extendedExperiencesData: [],
            uploadedVideos: 0,
            isLoaded: false,
            isError: false,
            errorMessage: "",
            exchangeRate: 8.6
        };

        this.back = props.backLink;
    }

    componentDidMount = () => {
        this.populateData();
    }

    async populateData() {
        let isError = false
        // const countryCode = await ApiService.getUserCountryCode();        
        const artwork = await ApiService.getAuthenticatedInstance()
            // .get(`/artwork/artworkDetail/${this.props.match.params.id}${countryCode ? "?countryCode=" + countryCode : ""}`)
            .get(`/artwork/artworkDetail/${this.props.match.params.id}`)
            .then(response => { return response.data.artwork })
            .catch(() => isError = true);

        if (!isError) {
            const storageItemId = artwork.storageItemId;

            let storageFiles = await ApiService.getAuthenticatedInstance().get(`/artwork/publicFiles/${artwork.id}`)
                .then(results => { return results.data.files })

            const presentationImagesArray = [];

            let hasModel = false
            // let hasModel = storageFiles[FileService.getFileUsage().OBJECT_MODEL] ? true : false
            // if (hasModel) {
            //     storageFiles[FileService.getFileUsage().OBJECT_MODEL]
            //         .map((storageImage, index) => {
            //             let modelObject = {};
            //             modelObject.file = storageImage;
            //             modelObject.type = FileService.getResourceType().OBJECT_MODEL;
            //             modelObject.sortOrder = 0;
            //             modelObject.artworkName = artwork.name;
            //             presentationImagesArray.push(modelObject);
            //             return storageImage;
            //         });
            // }

            storageFiles[FileService.getFileUsage().THUMBNAIL]
                .map((storageImage, index) => {
                    let image = {};
                    let startIndex = hasModel ? 1 : 0;
                    image.file = storageImage;
                    image.original = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                    image.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                    image.type = FileService.getResourceType().IMAGE;
                    image.youtubeId = storageImage.youtubeId;
                    image.sortOrder = storageImage.defaultThumbnail ? startIndex : index + startIndex + 1;
                    presentationImagesArray.push(image);
                    return storageImage;
                });

            presentationImagesArray.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

            const artworkVideos = storageFiles[FileService.getFileUsage().CREATE_DESTROY]
                .concat(storageFiles[FileService.getFileUsage().CREATION_PROC])
                .concat(storageFiles[FileService.getFileUsage().ART_DESC]);

            let uploadedVideos = artworkVideos.length;

            let pricePackages = [];
            if (artwork.pricePackages) {
                pricePackages = artwork.pricePackages.sort((a, b) => a.price - b.price);
            }

            let extendedExperiences = await ApiService.getAuthenticatedInstance().get(`/artwork/${this.props.match.params.id}/availableAddOn`)
                .then(result => { return result.data.experiences })
                .catch(err => console.log(err));

            let extendedExperiencesData = [];

            if (extendedExperiences.length > 0) {
                extendedExperiencesData = extendedExperiences.map(result => ({
                    name: result.name,
                    description: result.description,
                    image: this.state.artworkImages[0].original
                }))
            } else {
                extendedExperiencesData = [{
                    name: translatedMessage("ADD_ON.NOT_AVAILABLE.NAME"),
                    description: translatedMessage("ADD_ON.NOT_AVAILABLE.DESCRIPTION"),
                    image: card1
                }]
            }

            let exchangeRate = await ApiService.getAuthenticatedInstance().get(`/exchangeRate`)
                .then(result => { return result.data })
                .catch(err => console.log(err));

            this.setState({
                storageItemId: storageItemId,
                artwork: artwork,
                artworkCategory: translatedMessage(artwork.category.code),
                artworkImages: presentationImagesArray,
                artworkVideos: artworkVideos,
                uploadedVideos: uploadedVideos,

                author: artwork.authorList,
                authorName: ArtworkService.getAuthorNameFromMap(artwork.authorList),

                pricePackageData: pricePackages,

                extendedExperiencesData: extendedExperiencesData,

                exchangeRate: exchangeRate === 0 ? 8.6 : exchangeRate,
                isLoaded: true
            });
        } else {
            this.setState({
                isError: true,
                errorMessage: translatedMessage("ARTWORK.NOT_EXISTS"),
                isLoaded: true
            });
        }
    }

    onShowOrHideArtworkBuyModal = (element = null) => {
        if (this.state.artworkBuyModalShow === false) {
            this.setState({
                artworkBuyModalData: element,
                artworkBuyModalShow: true,
            })
        } else {
            this.setState({
                artworkBuyModalShow: false,
            })
        }
    }

    buy = (element) => {
        this.getMe().then(me => {
            this.getProfile().then(myProfile => {
                this.getCart(me.data.id).then(cart => {
                    this.addToCart(me.data, cart.data.id, element)
                        .then(response => {
                            toast.success(translatedMessage("CART.ADD_SUCCESS"));
                        }, err => {
                            toast.error(translatedMessage("CART.ADD_FAIL"));
                        });
                }, err => {
                    if (err && err.response && err.response.status && 404 === err.response.status) {
                        this.createCart(me.data, myProfile.data.profile.address).then(cart => {
                            const cartId = cart.data._links.self.href.split("/")[cart.data._links.self.href.split("/").length - 1]
                            this.addToCart(me.data, cartId, element)
                                .then(response => {
                                    toast.success(translatedMessage("CART.ADD_SUCCESS"));
                                }, err => {
                                    console.log(err);
                                    toast.error(translatedMessage("CART.ADD_FAIL"));
                                });
                        }, err => {
                            console.log(err);
                            toast.error(translatedMessage("CART.ADD_FAIL"));
                        });
                    }
                    else {
                        console.log(err);
                        toast.error(translatedMessage("CART.ADD_FAIL"));
                    }
                })
            }, err => {
                console.log(err);
                toast.error(translatedMessage("CART.ADD_FAIL"));
            })

        })
            .catch(err => {
                console.log(err);
            });
    }

    getMe = () => {
        return ApiService.getAuthenticatedInstance().get('/user/me');
    }

    getProfile = () => {
        return ApiService.getAuthenticatedInstance().get(`/user/myProfile`);
    }

    createCart = (user, address) => {
        return ApiService.getAuthenticatedInstance().post('/carts', {
            buyer: `/users/${user.id}`,
            billingAddress: address ? `/address/${address.id}` : null,
            lastUpdatedBy: user.username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            email: user.username
        })
    }

    getCart = (userId) => {
        return ApiService.getAuthenticatedInstance().get(`/user/userCart/${userId}`);
    }

    addToCart = (user, cartId, artworkPackage) => {
        return ApiService.getAuthenticatedInstance().post('/cartItems', {
            cart: `/carts/${cartId}`,
            pricePackage: `/artworkPricePackages/${artworkPackage.id}`,
            company: this.props.location.state && this.props.location.state.companyId ? `/companies/${this.props.location.state.companyId}` : null,
            lastUpdatedBy: user.username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        })
    }

    addFreeArt = (element) => {
        const packageId = element.id
        ApiService.getAuthenticatedInstance().post(`/artwork/addFreeArt/${packageId}`)
            .then(response => {
                if (response.data.status === "OK") {
                    toast.success(translatedMessage("ARTWORK.ADDED_COLLECTION.SUCCESS"));
                } else {
                    toast.error(translatedMessage(response.data.message));
                }
            })
    }

    render() {
        let thumbnail = card1;
        if (this.state.artworkImages[0] && this.state.artworkImages[0].original !== card1) {
            thumbnail = this.state.artworkImages[0].original;
        }
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-1 pr-2">
                            <div className="ca-page-subtitle">
                                <span className="font-weight-bold">{this.state.errorMessage}</span>
                            </div>
                            <div>
                                <Link to={`/gallery`} className="ca-dark-link">
                                    <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                                        title={translatedMessage("BUTTON.BACK")} />
                                </Link>
                            </div>
                        </Row>
                    </div>
                )
            } else {
                let artwork = this.state.artwork;

                return (
                    <div className="ca-page-padding ca-main-container">
                        <ArtworkBuyModalComponent
                            show={this.state.artworkBuyModalShow}
                            onHide={this.onShowOrHideArtworkBuyModal}
                            element={this.state.artworkBuyModalData}
                            category={this.state.artwork.category.code}
                            exchangerate={this.state.exchangeRate}
                            artworkname={artwork.name}
                            author={this.state.authorName}
                            buy={this.buy}
                            addfreeart={this.addFreeArt}
                        />

                        <Row className="ca-page-title-button-row mb-3 pr-2">
                            <div className="pr-2 d-flex align-items-center">
                                <Image src={artIcon} className="ca-page-title-icon" alt={translatedMessage("ARTWORK.ARTWORKS")} />

                                <div>
                                    <div className="ca-page-title" style={{ lineHeight: "30px" }}>{artwork.name}</div>
                                    <div className="ca-page-subtitle ca-muted-text">
                                        {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                                        {this.state.author.map((element, index) => {
                                            if (index === 0) {
                                                let link = '#'
                                                if(element.userId) {
                                                    link = `/profile/${element.userId}/view`
                                                } else if(element.companyId) {
                                                    link = `/company/${element.companyId}/space`
                                                }
                                                return (
                                                    <Link key={index} to={link}>
                                                        {element.name}
                                                    </Link>
                                                )
                                            } else {
                                                return (
                                                    <Link key={index} to={`/profile/${element.userId}/view`}>
                                                        ,&nbsp;{element.name}
                                                    </Link>
                                                )
                                            }
                                        })}
                                        {" | " + ApiService.parseDate(artwork.publishDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' })}
                                    </div>
                                </div>
                            </div>
                            <div className="ca-page-title-button-row-buttons d-flex justify-content-end">
                                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                                    title={translatedMessage("BUTTON.BACK")} onClick={() => this.props.history.go(-1)} style={{ cursor: "pointer" }} />
                            </div>
                        </Row>

                        <Row>
                            <Col className="col-12 col-lg-6 ca-artwork-column-left ca-artwork-image">
                                <Row>
                                    <Carousel
                                        id="image"
                                        items={this.state.artworkImages}
                                        controls={true}
                                        indicators={false}
                                        interval={null}
                                        class="ca-carousel-big mx-auto"
                                        hasToolbar={true}
                                        toolbarDelete={false}
                                        toolbarYoutubeId={false}
                                        toolbarFullScreen={true}
                                    />
                                </Row>
                            </Col>

                            <Col className="col-12 col-lg-6 ca-artwork-column-right">
                                <Row>
                                    {this.state.pricePackageData.map((element, index) => {
                                        return (
                                            <Col key={index} className="col-12 col-sm-6">

                                                <Card className={element.investorPackage ? "ca-artwork-pricing-card black-card" : "ca-artwork-pricing-card"}>
                                                    {element.availableCopies === 0
                                                        ? <Image src={soldout} className="ca-soldout-image" />
                                                        : element.quantity > 0
                                                            ? <Image src={limitedEdition} className="ca-limited-image" />
                                                            : ""
                                                    }
                                                    <Card.Header>
                                                        <Card.Title>{element.name}</Card.Title>
                                                        <div className="w-100 ca-card-detail-text">
                                                            <span>
                                                                {element.quantity === -1
                                                                    ? ""
                                                                    : translatedMessage("ARTWORK.AVAILABLE") + ": " + element.availableCopies + " / " + element.nrCopies}
                                                                &nbsp;
                                                            </span>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body className="">
                                                        <div>
                                                            <div className="w-100">{ApiService.getNumberSeparatedByDots(element.price)} USD</div>
                                                            <div className="ca-card-detail-text w-100">
                                                                {translatedMessage("ARTWORK.PRICE_EST_CURR")} {ApiService.getNumberSeparatedByDots(element.price * this.state.exchangeRate)} NOK</div>
                                                        </div>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        {element.isFreePackage
                                                            ?
                                                            <Button className={element.investorPackage ? "ca-button ca-button-white" : "ca-button"}
                                                                onClick={() => this.addFreeArt(element)}
                                                                disabled={element.availableCopies === 0 ? true : false}>
                                                                {translatedMessage("ARTWORK.ADD_TO_COLLECTION")}
                                                            </Button>
                                                            :
                                                            <Button className={element.investorPackage ? "ca-button ca-button-white" : "ca-button"}
                                                                onClick={() => this.buy(element)}
                                                                disabled={element.availableCopies === 0 ? true : false}>
                                                                {translatedMessage("GENERAL.BUY")}
                                                            </Button>
                                                        }
                                                        <div className="more-info-button" onClick={() => this.onShowOrHideArtworkBuyModal(element)}>
                                                            {translatedMessage("GENERAL.MORE_INFO")}
                                                        </div>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>

                        <Row className="view-description-about">
                            <Col className="col-12 col-lg-6 ca-artwork-column-left">
                                <div className="ca-paragraph-title">
                                    {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                                </div>

                                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: artwork.description }} />

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("PROJECT.CATEGORY")}
                                    </div>
                                    <div className="ca-view-value">{this.state.artworkCategory}</div>
                                </div>

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                                    </div>
                                    <div className="ca-view-value">{artwork.sizeWidth} W x {artwork.sizeHeight} H cm</div>
                                </div>

                                {artwork.printSizeList && artwork.printSizeList.length > 0 &&
                                    <div className="ca-display-group">
                                        <div className="ca-label">
                                            {translatedMessage("ARTWORK.PRINT.SIZE")}
                                        </div>
                                        {artwork.printSizeList.map((element, index) => {
                                            return (
                                                <div key={index} className="ca-view-value">{element}</div>
                                            )
                                        })}
                                    </div>
                                }
                            </Col>
                            <Col className="col-12 col-lg-6 ca-artwork-column-right">
                                <div className="ca-paragraph-title">
                                    {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                                </div>
                                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: artwork.aboutAuthor }} />
                            </Col>
                        </Row>

                        {this.state.uploadedVideos === 0 ? "" :
                            <Row>
                                <div className="ca-paragraph-title w-100">
                                    {translatedMessage("PROJECT.VIDEOS")}
                                </div>
                                {this.state.artworkVideos.map((element, index) => {
                                    return (
                                        <Col key={index} className="col-12 col-md-6 col-lg-4 pl-0">
                                            <DisplayVideoComponent youtubeId={element.youtubeId} width="640" height="400"
                                                classvideo="ca-youtube-video-container"
                                                classimage="ca-novideo-container-md-hide"
                                                title={translatedMessage(element.usage)} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        }

                        {this.state.artwork.category.code !== ArtworkService.artworkCategoryEnum().AR_ART ? "" :
                            <Row className="view-extended-experiences-row">
                                <div className="ca-paragraph-title w-100">
                                    {translatedMessage("ADD_ON.ADD_ON")}
                                </div>
                                {this.state.extendedExperiencesData.map((element, index) => {
                                    return (
                                        <Col key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3 pl-0">
                                            <ExperienceCardComponent
                                                data={element}
                                                artworkAuthor={this.state.authorName}
                                                thumbnail={thumbnail}
                                                showThumbnail={false}
                                                showIsMain={false}
                                                variant="dark"
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                        }
                    </div>
                );
            }
        }
    }
}

export default ViewArtworkPage
