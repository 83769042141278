import React from 'react';

import { Modal, Button } from 'react-bootstrap'

import { translatedMessage } from '../../../../services/language.service'

class DisplayInCompanySpaceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            // <>
            <Modal
                {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="ca-display-portfolio-modal"
            >
                <Modal.Body>
                    <div className="ca-modal-confirm-title w-100">
                        {this.props.displaystatus === true.toString() ?
                            translatedMessage("VIRTUAL_GALLERY.DISPLAYED_IN_COMPANY_SPACE.CONFIRM_NO_DISPLAY") :
                            translatedMessage("VIRTUAL_GALLERY.DISPLAYED_IN_COMPANY_SPACE.CONFIRM_DISPLAY")}
                    </div>
                    <div className="ca-modal-confirm-subtitle w-100">
                        {this.props.displaystatus === true.toString() ?
                            translatedMessage("VIRTUAL_GALLERY.DISPLAYED_IN_COMPANY_SPACE.CONFIRM_NO_DISPLAY_DETAIL") :
                            translatedMessage("VIRTUAL_GALLERY.DISPLAYED_IN_COMPANY_SPACE.CONFIRM_DISPLAY_DETAIL")}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" className="ca-button" onClick={() => this.props.onHide(this.props.cardid)}>
                        {translatedMessage("GENERAL.YES")}
                    </Button>
                    <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide()}>
                        {translatedMessage("GENERAL.NO")}
                    </Button>
                </Modal.Footer>
            </Modal>
            // </>
        )
    }

}

export default DisplayInCompanySpaceModal;