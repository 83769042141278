import React from 'react';

import GalleryService from '../../services/gallery.service';

import ExhibitionListComponent from '../../components/shared/Exhibition/ExhibitionListComponent';
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

class Exhibition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      allList: [],
      filteredList: [],
      isLoaded: false,
      canViewList: false,
    };
  }

  componentDidMount = () => {
    this.getExhibition();
  }

  getExhibition() {
    let errorMessage = "GENERAL.GET_DATA_ERROR";
    let exhibitions = [];
    let promise = [];

    promise.push(GalleryService.getUserCAExhibitions()
      .then(response => {
        exhibitions = response;
      }))

    Promise.all(promise)
      .then(() => {
        this.setState({
          exhibitions: exhibitions,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionListComponent
          history={this.props.history}
          exhibitions={this.state.exhibitions}
          exhibitionPrefixUrl={`/exhibition`}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default Exhibition;