import ApiService from "./api.service";

import logoblack from '../components/shared/Resources/logo-dark-small-with-padding.png';
import company from '../components/shared/Resources/icons/company/company.svg';

const CompanyService = {
  getCompanyStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/companyStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getCompanyTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/companyTypeList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },  

  getUserCompanyStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/userCompanyStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  
  getCompanyAddressStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/companyAddressStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },
  
  getArtistCompanyStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/companyArtistStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },  

  getAllCompanies() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/all`)
        .then(response => {
          let companies = response.data

          companies.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(companies)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCompanySpaceList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/spaces`)
        .then(response => {
          let spaces = response.data

          spaces.map((element, index) => {
            element.logo = element.photoUuid === null ? company : ApiService.getFileByUuid(element.photoUuid)

            return element
          });          

          spaces.sort((a, b) => {
            var result;
            a.spaceName.toUpperCase() <= b.spaceName.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(spaces)
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  
  getCompanySpace(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/space`)
        .then(response => {
          let space = response.data
          space.logo = space.photoUuid === null ? company : ApiService.getFileByUuid(space.photoUuid)

          resolve(space)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getCompaniesForJoin(fetchAll, forArtist) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/join-list/${fetchAll ? fetchAll : false}/${forArtist ? forArtist : false}`)
        .then(response => {
          let companies = response.data;

          companies.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(companies)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getCompaniesForConnectedUser() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/user/list`)
        .then(response => {
          let companies = response.data.userCompanies;
          companies.map((element, index) => {
            element.image = element.company.photoUuid === null ? company : ApiService.getFileByUuid(element.company.photoUuid)

            return element
          });          

          companies.sort((a, b) => {
            var result;

            // sort by name and put the ones that the user has pending approval at the end
            a.company.name.toUpperCase() <= b.company.name.toUpperCase() 
              ? (a.status.name === b.status.name ? result = -1 : (a.status.name === "ACTIVE" ? result = -1 : result = 1))
              : (a.status.name === b.status.name ? result = 1 : (b.status.name === "ACTIVE" ? result = 1 : result = -1))

            return result;
          });
          resolve(companies)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCompanyUsers(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/users`)
        .then(response => {
          let users = response.data.users

          users = users.filter(item => item.status.name !== "NOT_APPROVED");

          users.sort((a, b) => {
            var result;
            a.user.fullName.toUpperCase() <= b.user.fullName.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(users)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCompanyArtists(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/artists`)
        .then(response => {
          let users = response.data.artists

          users = users.filter(item => item.status.name !== "NOT_APPROVED");

          users.sort((a, b) => {
            var result;
            a.artist.fullName.toUpperCase() <= b.artist.fullName.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(users)
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  
  getCompanyArtistsByArtist() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/artist/company-list`)
        .then(response => {
          let companyArtists = response.data.companyArtists

          companyArtists = companyArtists.filter(item => item.status.name !== "NOT_APPROVED");

          companyArtists.sort((a, b) => {
            var result;
            a.company.name.toUpperCase() <= b.company.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(companyArtists)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  
  
  getCompanyAddresses(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/addresses`)
        .then(response => {
          let addresses = response.data.addresses

          resolve(addresses)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getCompanyExhibitionsForManage(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/exhibitions/manage`)
        .then(response => {
          const exhibitions = response.data.exhibitions
          exhibitions.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });
          exhibitions.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(exhibitions)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getCompanyExhibitions(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/exhibitions`)
        .then(response => {
          let exhibitions = response.data.exhibitions

          exhibitions.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });
          exhibitions.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(exhibitions)
        })
        .catch(err => {
          reject(err);
        });
    });
  },    

  getCompanyChallengesForManage(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/challenges/manage`)
        .then(response => {
          let challenges = response.data.challenges
          challenges.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });

          challenges.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          
          resolve(challenges)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getCompanyChallengeFilesForManage(challengeId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/challenge/${challengeId}/manage/files`)
        .then(response => {
          let challenge = response.data.challenge

          challenge.challengeItems.map((element, index) => {
            if (element.photoUuid !== null) {              
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            } else {
              element.imageURL = null
            }

            return element;
          });

          challenge.challengeItems.sort((a, b) => {
            var result;
            a.id <= b.id ? result = -1 : result = 1
            return result;
          });
          
          resolve(challenge)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  

  getCompanyChallenges(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/company/${companyId}/challenges`)
        .then(response => {
          let challenges = response.data.challenges
          challenges.map((element, index) => {
            if (element.photoUuid === null) {
              element.imageURL = logoblack;
            }
            else {
              element.imageURL = `${ApiService.getBaseUrl()}/storage/file/${element.photoUuid}`
            }
            return element;
          });

          challenges.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          
          resolve(challenges)
        })
        .catch(err => {
          reject(err);
        });
    });
  },
   
};

export default CompanyService;