import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import ChangeYoutubeIdModalComponent from '../../../components/shared/ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'

import backIcon from '../../../components/shared/Resources/left-arrow.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import { toast } from "react-toastify";
import moment from 'moment';

class CompanyMediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      showAddFileModal: false,
      fileModalData: {},
      images: [],
      videos: [],
      entity: null,
      showEditYoutubeModal: false,
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        this.setState({
          company: response.data.company,
        }, this.getData)
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  async getData() {
    let entity = this.state.company;
    let storageItemId = this.state.company.storageItemId
    let images = [];
    let videos = [];

    if (this.state.company.status.name === 'ACTIVE') {
      if (storageItemId) {
        entity.storageItem = {
          id: storageItemId
        }

        const storageFiles = await FileService.getStorageItemFiles(this.state.company.id, ApiService.getEntityName().COMPANY)
          .then(response => { return response })

        videos = await FileService.getVideoUrls(this.state.company.id, ApiService.getEntityName().COMPANY)
          .then(response => { return response })

        if (storageFiles.length > 0) {
          storageFiles.forEach((item) => {
            if (item.usage === FileService.getFileUsage().THUMBNAIL) {
              let mockImage = {};
              mockImage.file = item;
              mockImage.original = item.imageURL;
              mockImage.thumbnail = item.imageURL;
              mockImage.type = FileService.getResourceType().IMAGE;
              mockImage.storageItemFileId = item.storageItemFileId;
              mockImage.youtubeId = item.youtubeId;

              images.push(mockImage);
            }
          });
        }
      }

      this.setState({
        entity: entity,
        images: images,
        videos: videos,
        isLoaded: true,
      });
    } else {
      this.setState({
        isError: true,
        errorMessage: "COMPANY.NOT_APPROVED",
        isLoaded: true
      })
    }
  }



  onShowAddFile = (event) => {
    let modalData = {};

    if (event.currentTarget.id === 'btnPresImg') {
      modalData = {
        modalTitle: translatedMessage("UPLOAD.IMAGES"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.entity,
        storageitemlinked: true
      }
    }

    this.setState({
      fileModalData: modalData,
      showAddFileModal: true
    })
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getData();
    }
    this.setState({
      showAddFileModal: false,
    })
  }

  handleMediaListChange = () => {
    this.getData();
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      ApiService.getAuthenticatedInstance().post(`/entityVideoUrls`, {
        entity: {
          entityId: this.state.company.id,
          entityName: ApiService.getEntityName().COMPANY
        },
        videoId: videoId,
        type: FileService.getVideoUrlType().YOUTUBE,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            showEditYoutubeModal: false
          }, this.handleMediaListChange)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div>
              <div className="ca-page-title">{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
              <div className="ca-page-subtitle">{translatedMessage("COMPANY.SPACE.MEDIA.INFO")}</div>
            </div>
            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/space`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="mb-1">
            <Col className="col-12 col-lg-6 ca-artwork-column-left">
              <div className="font-bold w-100 mb-3">
                <span>{translatedMessage("PROJECT.IMAGES")}</span>

                <span className="d-inline-block">
                  <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                    <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")}/>
                  </Button>
                </span>
              </div>
              {this.state.images.length === 0 &&
                translatedMessage("IMAGE.FIRST_UPLOAD")
              }
              {this.state.images.length > 0 &&
                <Carousel
                  id="presImg"
                  items={this.state.images}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={true}
                  toolbarDelete={true}
                  toolbarFullScreen={true}
                  canDeleteLast={true}
                  onChange={this.handleMediaListChange}
                />
              }
            </Col>
            <Col className="col-12 col-lg-6 ca-artwork-column-right">
              <div className="font-bold w-100 mb-3">
                <span>
                  {translatedMessage("PROJECT.VIDEOS")}
                </span>

                <span className="d-inline-block">
                  <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.handleYoutubeIdEdit} 
                    title={translatedMessage("VIDEO.ADD_YOUTUBE_ID")}>
                    
                    <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")}/>
                  </Button>
                </span>
              </div>
              {this.state.videos.length === 0 &&
                translatedMessage("VIDEO.FIRST_UPLOAD")
              }
              {this.state.videos.length > 0 &&
                <Carousel
                  id="video"
                  items={this.state.videos}
                  controls={true}
                  indicators={false}
                  interval={null}
                  class="mb-5"
                  hasToolbar={true}
                  toolbarDelete={true}
                  toolbarYoutubeId={true}
                  videoUrlOnly={true}
                  onChange={this.handleMediaListChange}
                />
              }
            </Col>
          </Row>

          <AddFileModalComponent
            show={this.state.showAddFileModal}
            onHide={this.onHideAddFile}
            data={this.state.fileModalData}
          />

          <ChangeYoutubeIdModalComponent
            show={this.state.showEditYoutubeModal}
            onHide={this.onHideYoutubeModal}
          />
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyMediaPage;