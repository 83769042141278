import React from 'react';

import calogo from '../../../components/shared/Resources/defaultGalleryPicture.png';
import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import GalleryService from '../../../services/gallery.service';
import FileService from '../../../services/file.service';
import { hasPermission } from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";
import moment from 'moment';

class GalleryAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: this.props.match.params.id ? this.props.match.params.id : null,
      gallery: [],
      galleryStatusList: [],
      galleryTypeList: [],
      isLoaded: false,
      dropzoneEnabled: true,
      displayImg: true,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canEdit: hasPermission("GALLERY_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    var gallery = [];
    var galleryStatusList = []
    var galleryTypes = [];
    var isNew = false;
    var promise = [];

    if (this.state.canEdit) {

      promise.push(GalleryService.getGalleryStatusList()
        .then(response => {
          galleryStatusList = response;
        }))

      promise.push(GalleryService.getGalleryTypeList()
        .then(response => {
          galleryTypes = response._embedded.galleryTypes;
          galleryTypes.map(item => {
            const parts = item._links.self.href.split('/');
            const id = parts[parts.length - 1];
            item.id = id;
            return item;
          })
        })
      )

      if (this.state.galleryId !== null) {
        promise.push(ApiService.getAuthenticatedInstance().get(`gallery/getGallery/${this.state.galleryId}`)
          .then(response => {
            gallery = response.data;
          })
        )
      } else {
        isNew = true;
      }

      Promise.all(promise).then(() => {
        if (isNew) {
          const statusItem = galleryStatusList.filter(item => item.name === 'UNPUBLISHED');
          gallery.status = statusItem[0];
          const typeItem = galleryTypes.filter(item => item.id === '1');
          gallery.galleryType = typeItem[0];
        }
        this.setState({
          galleryStatusList: galleryStatusList,
          galleryTypeList: galleryTypes,
          gallery: gallery,
          galleryImgUrl: isNew ? "" : (gallery.photo === null ? calogo : ApiService.getFileByUuid(gallery.photo.uuid) + '?' + Date.now()),
          isLoaded: true
        })
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  handleOnStatusSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.galleryStatusList.filter(item => item.name === eventKey);
    this.setState({
      gallery: {
        ...this.state.gallery,
        status: selectedItem[0]
      }
    });
  }

  handleOnTypeSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.galleryTypeList.filter(item => item.id === eventKey);
    this.setState({
      gallery: {
        ...this.state.gallery,
        galleryType: selectedItem[0]
      }
    });
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          displayImg: !this.state.displayImg,
          gallery: {
            ...this.state.gallery,
            photo: uploadedFile
          },
          galleryImgUrl: ApiService.getFileByUuid(uploadedFile.uuid) + '?' + Date.now(),
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        gallery: {
          ...this.state.gallery,
          photo: uploadedFile
        },
      });
    }
  }

  validate = (event) => {
    var response = {
      isError: false,
      message: ""
    };

    return response;
  }

  changeImgDropzone = () => {
    this.setState({ displayImg: !this.state.displayImg })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const validation = this.validate(event);
    if (!validation.isError) {
      var promise;
      if (this.state.galleryId !== null) {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().patch(`/galleries/${this.state.galleryId}`, {
            name: event.target.elements.formGalleryName.value,
            status: this.state.gallery.status.name,
            galleryType: `/galleryTypes/${this.state.gallery.galleryType.id}`,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then(() => resolve())
            .catch(err => reject(err))
        });
      }
      else {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().post(`/galleries`, {
            name: event.target.elements.formGalleryName.value,
            status: this.state.gallery.status.name,
            galleryType: `/galleryTypes/${this.state.gallery.galleryType.id}`,
            curator: `/users/${ApiService.getCurrentUser().id}`,
            caGallery: true,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then((response) => {
              const parts = response.data._links.self.href.split('/');
              const id = parts[parts.length - 1];
              this.setState({
                galleryImgUrl: calogo,
                galleryId: id,
              })

              ApiService.getAuthenticatedInstance().post(`/gallery/${id}/CA-permissions/add`)
                .catch(err => console.log("An error has ocurred: " + err))

              this.props.history.push(`/galleryAdminEdit/${id}`)
              resolve()
            })
            .catch(err => reject(err))
        });
      }

      promise.then(() => {
        toast.success(translatedMessage("GALLERY.SAVE_SUCCESS"));
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GALLERY.SAVE_ERROR"));
        })
    } else {
      toast.error(validation.message);
    }
  }

  render() {
    let img_class = this.state.displayImg ? "ca-form-image-container" : "ca-form-image-container-hide";
    let dropzone_class = !this.state.displayImg ? "ca-dropzone-show" : "ca-dropzone-hide";

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={galleryIcon} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERY")} />
              <span className="ca-page-title">{translatedMessage("GALLERY.EDIT")}</span>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.dropzoneEnabled ? "" : "disabled")}
                type="submit" form="gallery-form">
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")}/>
              </Button>
              <Button className={"ca-dark-link ca-button-icon ".concat(this.state.dropzoneEnabled ? "" : "disabled")} variant="link"
                onClick={() => this.props.history.push(`/galleryAdminList`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Form id="gallery-form" className="ca-form" onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="formGalleryName">
                <Form.Group controlId="formGalleryName">
                  <Form.Label>
                    {translatedMessage("GALLERY.NAME")}
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={translatedMessage("GALLERY.NAME_PLACEHOLDER")}
                    defaultValue={this.state.gallery.name}
                  />
                </Form.Group>

                <Form.Group controlId="formGalleryStatus">
                  <Form.Label>
                    {translatedMessage("GENERAL.STATUS")}
                  </Form.Label>
                  <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                    <Dropdown.Toggle>
                      {translatedMessage(this.state.gallery.status.label)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {this.state.galleryStatusList.map((element, index) => {
                        return (
                          <Dropdown.Item key={index} eventKey={element.name}>
                            {translatedMessage(element.label)}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>

                <Form.Group controlId="formGalleryType">
                  <Form.Label>
                    {translatedMessage("GALLERY.TYPE")}
                  </Form.Label>
                  <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                    <Dropdown.Toggle>
                      {translatedMessage(this.state.gallery.galleryType.nameKey)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {this.state.galleryTypeList.map((element, index) => {
                        return (
                          <Dropdown.Item key={index} eventKey={element.id}>
                            {translatedMessage(element.nameKey)}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formGalleryImage" style={{ textAlign: "center" }}>
                <div className={img_class} onClick={this.changeImgDropzone.bind(this)}>
                  <Image src={this.state.galleryImgUrl} />
                  <div className="ca-form-image-change-details">
                    <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf030;</div>
                    <div>{translatedMessage("GENERAL.IMAGE.TOOLTIP")}</div>
                  </div>
                </div>
                <div className={dropzone_class}>
                  <DropzoneComponent
                    componentId="galleryImage"
                    fileUsage={FileService.getFileUsage().GALLERY}
                    fileType={FileService.getResourceType().IMAGE}
                    deletePrevious={true}
                    deletedImage={this.state.gallery.photo}
                    deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                    fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                    maxSize={1}
                    maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                    multipleFiles={false}
                    multipleFilesDesc=""
                    entity={this.state.gallery}
                    entityUpdateAPILink="galleries"
                    storageitemlinked={false}
                    onupload={this.onUploadFile}
                  />
                  <div>
                    <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeImgDropzone.bind(this)}>&#xf021;</div>
                  </div>
                </div>
              </Form.Group>
            </Form.Row>
          </Form>
        </div >
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }

}

export default GalleryAdminEdit;