import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, OverlayTrigger, Tooltip, Image } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import FileService from '../../../services/file.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import { translatedMessage } from '../../../services/language.service';

import arPortalIcon from '../../../components/shared/Resources/arPortalWhtite.png';
import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import previewIcon from '../../../components/shared/Resources/icons/preview_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

import { toast } from "react-toastify";
import moment from 'moment';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import SimplePricePackageViewCardComponent from '../../../components/shared/PricePackage/CardComponents/SimplePricePackageViewCardComponent';
import HtmlEditorModalComponent from '../../../components/shared/HtmlEditorModalComponent/HtmlEditorModalComponent';
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import ChangeYoutubeIdModalComponent from '../../../components/shared/ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'

import PublishModalComponent from '../Components/PublishModalComponent/PublishModalComponent';
import SubmittedForApprovalModalComponent from '../Components/SubmittedForApprovalModalComponent/SubmittedForApprovalModalComponent';
import EditFieldModalComponent from '../Components/EditFieldModalComponent';

class ArPortalPublishPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualGallery: null,
      pricePackages: [],
      artFile: [],
      pricePackagesTableData: [],
      presentationImages: [],
      videos: [],
      addImageModal: false,
      addImageModalData: {},
      publishModalShow: false,
      submittedForApprovalModalShow: false,
      statusName: '',
      data: {},
      hasAllSignatures: false,
      showEditorModal: false,
      showFieldModal: false,
      modalData: {
        content: '',
        title: '',
        dataFor: ''
      },
      showAddFileModal: false,
      isLoaded: false,
      isError: false,
      errorMessage: '',
      fileModalData: null,
      showEditYoutubeModal: false,
      onlyView: false,
      showPassePModal: false,
    };

    this.onShowEdit = this.onShowEdit.bind(this);
  }

  componentDidMount() {
    this.populateData();
  }

  populateData() {
    ApiService.getAuthenticatedInstance().get(`/virtualGallery/project/${this.props.match.params.id}`)
      .then(result => {
        this.setState({
          virtualGallery: {
            ...result.data,
            storageItem: {
              id: result.data.storageItemId
            }
          },
          statusName: result.data.status.name,
          onlyView: result.data.status.name === ArtworkService.artworkStatusEnum().DRAFT ||
            result.data.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
        }, () => {
          var promise = [];
          var pricePackages = [];
          var hasAllSignatures = false;

          promise.push(ApiService.getAuthenticatedInstance().get(`/virtualGallery/${this.state.virtualGallery.id}/artworkPricePackages`)
            .then(result => {
              pricePackages = result.data.pricePackages
                .map(artworkPricePackage => {
                  artworkPricePackage.description = translatedMessage(artworkPricePackage.description);

                  return {
                    ...artworkPricePackage
                  }
                })
                .filter(item => item.active)
                .sort((a, b) => a.order - b.order);
            })
          );

          promise.push(this.getMedia());

          promise.push(
            ApiService.getAuthenticatedInstance().get(`/artwork/hasAllSignatures/${this.state.virtualGallery.id}?entity=${ApiService.getEntityName().VIRTUAL_GALLERY}`)
              .then(response => hasAllSignatures = response.data)
          );

          Promise.all(promise).then(() => {
            this.setState({
              pricePackagesTableData: pricePackages,
              hasAllSignatures: hasAllSignatures,
              isLoaded: true
            })
          })
        });
      })
      .catch(err => {
        var errorMessage = "PROJECT.GENERAL_ERROR";
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });

  }

  getMedia = () => {
    let promise = [];
    let presentationImages = [];
    let videos = [];
    let artPrintFiles = [];

    let isPublished = this.state.virtualGallery.status.name === ArtworkService.artworkStatusEnum().PUBLISHED ? true : false;

    promise.push(
      ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.virtualGallery.id}/${ApiService.getEntityName().VIRTUAL_GALLERY}`)
        .then(results => {
          let storageFiles = results.data.fileList;

          presentationImages = FileService.handleFilesForCarousel(storageFiles, FileService.getFileUsage().THUMBNAIL);
          presentationImages.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

          if (!isPublished) {
            storageFiles.filter(file => file.usage === FileService.getFileUsage().ARTWORK_PRINT_PREVIEW).map((print, index) => {
              let mockImage = {};
              mockImage.file = print;
              mockImage.type = FileService.getResourceType().IMAGE;
              mockImage.original = ApiService.getBaseUrl() + '/storage/file/' + print.uuid;
              mockImage.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + print.uuid;
              mockImage.youtubeId = null;
              mockImage.storageItemFileId = print.storageItemFileId;
              artPrintFiles.push(mockImage);

              return print;
            });
          }
        })
    )

    promise.push(
      FileService.getVideoUrls(this.state.virtualGallery.id, ApiService.getEntityName().VIRTUAL_GALLERY).then(response => { videos = response })
    )

    Promise.all(promise).then(() => {


      this.setState({
        presentationImages: presentationImages,
        videos: videos,
        artPrintFiles: artPrintFiles
      })
    })
  }

  handleValidation = (forPreview) => {
    let response = {
      isValid: true,
      errorMessage: []
    }

    let i = 0;
    if (!this.state.virtualGallery.arPortalFileUploaded) {
      response.isValid = false;
      response.errorMessage[i] = "VIRTUAL_GALLERY.PUBLISH.MISSING.ART"
      i += 1;
    }

    if (this.state.presentationImages.length === 0) {
      response.isValid = false;
      response.errorMessage[i] = "VIRTUAL_GALLERY.PUBLISH.MISSING.PRESENTATION"
    }

    if (!forPreview && !this.state.hasAllSignatures) {
      response.isValid = false;
      response.errorMessage[i] = "VIRTUAL_GALLERY.SIGNATURES.MISSING.NOTE";
    }

    return response;
  }

  onShowOrHidePublishModal = (termsAndConditions = null) => {
    const validationResponse = this.handleValidation();
    if (validationResponse.isValid === true) {
      if (this.state.publishModalShow === false) {
        this.setState({
          publishModalShow: true,
        })
      } else if (!termsAndConditions) {
        this.setState({
          publishModalShow: false,
        })
      } else {
        this.setState({
          publishModalShow: false,
        }, () => {
          ApiService.getAuthenticatedInstance().post(`/virtualGallery/project-submit/${this.props.match.params.id}`)
            .then((result) => {
              this.setState({
                statusName: VirtualGalleryService.virtualGalleryStatusEnum().SUBMITTED_FOR_APPROVAL
              })
              this.onShowOrHideSubmittedForApprovalModal();
            });
        })
      }
    } else {
      validationResponse.errorMessage.forEach(message => {
        toast.error(translatedMessage(message), { autoClose: 7000 });
      })
    }
  };

  onShowOrHideSubmittedForApprovalModal = () => {
    if (this.state.submittedForApprovalModalShow === false) {
      this.setState({
        submittedForApprovalModalShow: true,
      })
    } else {
      this.setState({
        submittedForApprovalModalShow: false,
        onlyView: true
      });

      // this.props.history.push(`/project/view/${this.props.match.params.id}`);
    }
  };

  handlePreview = () => {
    const validationResponse = this.handleValidation(true);
    if (validationResponse.isValid === true) {
      Promise.all([
        ApiService.getAuthenticatedInstance().patch(`/projects/${this.props.match.params.id}`, {
          status: ArtworkService.artworkStatusEnum().PREVIEW,
        }),
        ApiService.getAuthenticatedInstance().patch(`/virtualGalleries/${this.state.virtualGallery.id}`, {
          status: VirtualGalleryService.virtualGalleryStatusEnum().PREVIEW,
        }),
      ]).then(() => {
        this.setState({
          statusName: VirtualGalleryService.virtualGalleryStatusEnum().PREVIEW
        })
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
      }).catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      });
    } else {
      validationResponse.errorMessage.forEach(message => {
        toast.error(translatedMessage(message), { autoClose: 7000 });
      })
    }
  }

  onShowEdit = (event) => {
    if (event.currentTarget.id === 'description') {
      this.setState({
        modalData: {
          content: this.state.virtualGallery.description,
          title: translatedMessage("GENERAL.DESCRIPTION"),
          dataFor: 'description',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'aboutAuthor') {
      this.setState({
        modalData: {
          content: this.state.virtualGallery.aboutAuthor ? this.state.virtualGallery.aboutAuthor : "",
          title: translatedMessage("ARTWORK.ABOUT_AUTHOR"),
          dataFor: 'aboutAuthor',
        },
        showEditorModal: true,
      })
    } else if (event.currentTarget.id === 'artName') {
      this.setState({
        modalData: {
          content: this.state.virtualGallery.name,
          title: translatedMessage("GENERAL.NAME"),
          dataFor: 'artName',
        },
        showFieldModal: true,
      })
    }
  }

  onHideEdit = (data) => {
    if (data && data.content && data.dataFor) {
      let description = this.state.virtualGallery.description;
      let aboutAuthor = this.state.virtualGallery.aboutAuthor;
      let artName = this.state.virtualGallery.name;

      if (data.dataFor === "description") {
        description = data.content;
      } else if (data.dataFor === "aboutAuthor") {
        aboutAuthor = data.content;
      } else if (data.dataFor === "artName") {
        artName = data.content;
      }

      const promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/virtualGalleries/${this.state.virtualGallery.id}`, {
          name: artName,
          description: description,
          aboutAuthor: aboutAuthor,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
        resolve();
      });

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          virtualGallery: {
            ...this.state.virtualGallery,
            name: artName,
            description: description,
            aboutAuthor: aboutAuthor
          }
        })
      })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);
        });
    }
    this.setState({
      showEditorModal: false,
      showFieldModal: false
    })
  }

  onShowAddFile = (event) => {
    if (event.currentTarget.id === 'btnPresImg') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.virtualGallery,
        storageitemlinked: true
      }
      this.setState({
        showAddFileModal: true,
        fileModalData: modalData
      })
    }
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getMedia();
    }
    this.setState({
      showAddFileModal: false,
      showAddVideoModal: false
    })
  }

  handleMediaListChange = () => {
    this.getMedia();
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      ApiService.getAuthenticatedInstance().post(`/entityVideoUrls`, {
        entity: {
          entityId: this.state.virtualGallery.id,
          entityName: ApiService.getEntityName().VIRTUAL_GALLERY
        },
        videoId: videoId,
        type: FileService.getVideoUrlType().YOUTUBE,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            showEditYoutubeModal: false
          }, this.handleMediaListChange)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  render() {
    var isPreview = this.state.statusName === VirtualGalleryService.virtualGalleryStatusEnum().PREVIEW;

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        const homeUlr = this.state.virtualGallery.companyId ? `/my-company/${this.state.virtualGallery.companyId}/portfolio` : `/projects`;

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-md-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={arPortalIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-lg-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_AR_PORTAL")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("PROJECT.PUBLISH_REVIEW")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={homeUlr} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>
                </div>

                <div className="ca-page-padding ca-main-container">
                  <Row className="mb-1">
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("AR_PORTAL.DETAILS")}
                      </div>
                    </Col>

                    <Col className="col-12 pl-0">
                      <Row className="mb-0">
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label d-flex align-items-end">
                              {translatedMessage("GENERAL.NAME")}
                              {!this.state.onlyView &&
                                <Button id="artName" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                                  <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                                </Button>
                              }
                            </div>
                            <div className="ca-view-value">{this.state.virtualGallery.name}</div>
                          </div>
                        </Col>
                        <Col className="col-12 col-sm-6 pl-0">
                          <div className="ca-display-group">
                            <div className="ca-label">
                              {translatedMessage("AR_PORTAL.TYPE")}
                            </div>
                            <div className="ca-view-value">{translatedMessage(this.state.virtualGallery.type.label)}</div>
                          </div>
                        </Col>
                        {this.state.virtualGallery.type.name === VirtualGalleryService.virtualGalleryTypeEnum().VIRTUAL_GALLERY &&
                          <>
                            <Col className="col-12 pl-0">
                              <div className="ca-label">
                                {translatedMessage("AR_PORTAL.ART_PLACEHOLDERS")}
                              </div>
                            </Col>
                            <Col className="col-12 col-sm-6 pl-0">
                              <div className="ca-display-group">
                                <div className="ca-view-value">
                                  {translatedMessage("AR_PORTAL.AR_ART_NO") + ": " + this.state.virtualGallery.nrArArt}</div>
                              </div>
                            </Col>
                            <Col className="col-12 col-sm-6 pl-0">
                              <div className="ca-display-group">
                                <div className="ca-view-value">
                                  {translatedMessage("AR_PORTAL.SCULPTURE_NO") + ": " + this.state.virtualGallery.nrSculpture}</div>
                              </div>
                            </Col>
                          </>
                        }

                        <Col className="col-12 pl-0 mt-3">
                          <div className="ca-label d-flex align-items-end">
                            {translatedMessage("GENERAL.DESCRIPTION")}
                            <Button id="description" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                          </div>
                          <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: this.state.virtualGallery.description === null ? "-" : this.state.virtualGallery.description }} />
                        </Col>

                        <Col className="col-12 pl-0 mt-3">
                          <div className="ca-label d-flex align-items-end">
                            {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                            <Button id="aboutAuthor" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowEdit}>
                              <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")} title={translatedMessage("GENERAL.EDIT")} />
                            </Button>
                          </div>
                          <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: this.state.virtualGallery.aboutAuthor === null ? "-" : this.state.virtualGallery.aboutAuthor }} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("ARTWORK.PRICING")}
                      </div>
                    </Col>
                    {this.state.pricePackagesTableData.map((element, index) => {
                      return (
                        <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0">
                          <SimplePricePackageViewCardComponent
                            data={element}
                            background="dark"
                            category={ArtworkService.artworkCategoryEnum().AR_PORTAL}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="col-12 pl-0">
                      <div className="ca-paragraph-title w-100 mb-3">
                        {translatedMessage("GENERAL.MEDIA")}
                      </div>
                    </Col>
                    <Col className="col-12 col-md-6 pl-0">
                      <div className="w-100 mb-3 font-weight-bold d-flex align-items-end">
                        <span>
                          {translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                          <OverlayTrigger
                            key='addImage'
                            placement='bottom'
                            overlay={
                              <Tooltip id={`tooltip-addImage`} className="ca-tooltip">
                                {translatedMessage("ARTWORK.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                              </Tooltip>
                            }
                          >
                            <span className="ca-question-icon"></span>
                          </OverlayTrigger>
                        </span>

                        <span className="d-inline-block">
                          <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                            <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                          </Button>
                        </span>
                      </div>
                      {this.state.presentationImages.length > 0 &&
                        <Carousel
                          id="presImg"
                          items={this.state.presentationImages}
                          controls={true}
                          indicators={false}
                          interval={null}
                          class="mb-5"
                          hasToolbar={true}
                          toolbarDelete={true}
                          toolbarFullScreen={true}
                          canDeleteLast={true}
                          onChange={this.handleMediaListChange}
                        />
                      }
                    </Col>
                    <Col className="col-12 col-md-6 pl-0">
                      <div className="w-100 mb-3 font-weight-bold d-flex align-items-end">
                        <span>
                          {translatedMessage("PROJECT.VIDEOS")}
                        </span>

                        <span className="d-inline-block">
                          <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.handleYoutubeIdEdit}>
                            <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("VIDEO.ADD_YOUTUBE_ID")} />
                          </Button>
                        </span>
                      </div>
                      {this.state.videos.length > 0 &&
                        <Carousel
                          id="video"
                          items={this.state.videos}
                          controls={true}
                          indicators={false}
                          interval={null}
                          class="mb-5"
                          hasToolbar={true}
                          toolbarDelete={true}
                          toolbarYoutubeId={true}
                          videoUrlOnly={true}
                          onChange={this.handleMediaListChange}
                        />
                      }
                    </Col>
                  </Row>

                  {this.state.virtualGallery.hasPrintFile && this.state.artPrintFiles && this.state.artPrintFiles.length > 0 &&
                    <>
                      <hr></hr>
                      <Row>
                        <Col className="col-12 pl-0">
                          <div className="ca-paragraph-title w-100">
                            {translatedMessage("PROJECT.UPLOAD_PRINT_FILE")}
                          </div>                          
                        </Col>
                        <Col className="col-12 pl-0 ca-ar-art-publish-preview-print-container">
                          <Carousel
                            id="printFiles"
                            items={this.state.artPrintFiles}
                            controls={true}
                            indicators={false}
                            interval={null}
                            class="mb-5"
                            hasToolbar={true}
                            toolbarFullScreen={true}
                            toolbarDelete={false}
                            toolbarYoutubeId={false}
                            toolbarPassepartout={true}
                            canDeleteLast={false}
                            entityId={this.props.match.params.id}
                            entityName={ApiService.getEntityName().VIRTUAL_GALLERY}
                            onChange={this.handleMediaListChange}                            
                          />
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className="ca-button-icon" variant="link"
                    onClick={() => this.props.history.push(`/project/publish/ar-portal-price-package/${this.props.match.params.id}`)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div>
                  <OverlayTrigger
                    key='pPrev'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-pPrev`} className="ca-tooltip">
                        {isPreview ? translatedMessage("PROJECT.STATUS.PREVIEW_MODE") : translatedMessage("PROJECT.MOBILE_PREVIEW.TOOLTIP")}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <span className={isPreview || this.state.onlyView ? "ca-text-disabled-dark" : ""}>{translatedMessage("PROJECT.MOBILE_PREVIEW")}</span>
                      <Button variant="link" className="ca-button-icon ml-2" onClick={this.handlePreview}
                        disabled={isPreview || this.state.onlyView}>
                        <Image src={previewIcon} alt={translatedMessage("PROJECT.MOBILE_PREVIEW")} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="d-flex align-items-center">
                  <OverlayTrigger
                    key='pPubB'
                    placement='top'
                    overlay={
                      <Tooltip id={`tooltip-pPubB`} className="ca-tooltip">
                        {!this.state.hasAllSignatures ? translatedMessage("VIRTUAL_GALLERY.SIGNATURES.MISSING.NOTE") : translatedMessage("PROJECT.PUBLISH")}
                      </Tooltip>
                    }
                  >
                    <span className="d-inline-block">
                      <span className={isPreview || this.state.onlyView ? "ca-text-disabled-dark" : ""}>{translatedMessage("PROJECT.PUBLISH")}</span>
                      <Button className="ca-button-icon" variant="link" onClick={this.onShowOrHidePublishModal} disabled={!this.state.hasAllSignatures || this.state.onlyView}>
                        <Image src={nextArrow} alt={translatedMessage("PROJECT.PUBLISH")} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <PublishModalComponent
              show={this.state.publishModalShow}
              onHide={this.onShowOrHidePublishModal}
              name={this.state.virtualGallery.name}
              pagetitle="VIRTUAL_GALLERY.SUBMIT_CONFIRM"
              pageinfo="VIRTUAL_GALLERY.SUBMIT_CONFIRM_INFO"
            />

            <SubmittedForApprovalModalComponent
              show={this.state.submittedForApprovalModalShow}
              onHide={this.onShowOrHideSubmittedForApprovalModal}
              message={"ART.SUBMITTED.SUCCESS"}
            />

            <HtmlEditorModalComponent
              show={this.state.showEditorModal}
              onHide={this.onHideEdit}
              data={this.state.modalData}
            />

            <EditFieldModalComponent
              show={this.state.showFieldModal}
              onHide={this.onHideEdit}
              data={this.state.modalData}
            />

            <AddFileModalComponent
              show={this.state.showAddFileModal}
              onHide={this.onHideAddFile}
              data={this.state.fileModalData}
            />

            <ChangeYoutubeIdModalComponent
              show={this.state.showEditYoutubeModal}
              onHide={this.onHideYoutubeModal}
            />
          </>
        )
      }
    }
  }
}

export default ArPortalPublishPreview;