import React from 'react';

import EntityLocationListComponent from '../../../components/shared/EntityLocationListComponent/EntityLocationListComponent';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { hasPermission } from '../../../services/api.service';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';

class VirtualGalleryLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualGallery: null,
      locations: [],
      pin: {},
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canEdit: hasPermission("VIRTUAL_GALLERY_EDIT")
      }, this.getData)
    });
  }

  async getData() {
    if (this.state.canEdit) {
      const virtualGalleryId = this.props.match.params.id;
      let isError = false;
      let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";

      const virtualGallery = await ApiService.getAuthenticatedInstance().get(`virtualGallery/virtualGalleryDetail/${virtualGalleryId}`)
        .then(response => { return response.data })
        .catch(err => {
          isError = true;
          if (err && err.response && err.response.status && 404 === err.response.status) {
            errorMessage = err.response.data.message;
          }
        })

      if (!isError) {
        const locations = await ApiService.getAuthenticatedInstance().get(`virtualGallery/virtualGalleryLocation/${virtualGalleryId}`)
          .then(response => { return response.data })

        const pin = await MapService.getLocationPinByType(ApiService.getEntityName().VIRTUAL_GALLERY);

        this.setState({
          virtualGallery: virtualGallery,
          locations: locations,
          pin: pin[0] ? pin[0] : null,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocationListComponent
            entityId={this.state.virtualGallery.id}
            entityName={ApiService.getEntityName().VIRTUAL_GALLERY}
            entityTitle={this.state.virtualGallery.name}
            locations={this.state.locations}
            pin={this.state.pin}
            icon={virtualGalleryIcon}
            endPoint={`virtualGallery/virtualGalleryLocation/`}
            history={this.props.history}
          />
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default VirtualGalleryLocation;