import React from 'react'
import './MembershipPage.css';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import MembershipBlock from './MembershipBlock'

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service'

import caMember from '../../components/shared/Resources/membership/ca_member.png';
import caMemberWhite from '../../components/shared/Resources/membership/ca_member_white.png';
import caClub from '../../components/shared/Resources/membership/ca_club.png';
import caClubWhite from '../../components/shared/Resources/membership/ca_club_white.png';
import caVip from '../../components/shared/Resources/membership/ca_vip.png';
import caVipWhite from '../../components/shared/Resources/membership/ca_vip_white.png';


class MembershipPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMembership: 'Member',
      currentInterval: null,
      canUpgrade: true,
      requestedDowngrade: null,
      downgradeDate: null,
      downgradeRequestDate: null,
      isLoaded: false
    };

  }

  componentDidMount() {
    ApiService.initUser().then(() => { this.populateData() });

    ApiService.getAuthenticatedInstance().get(`/membership/downgrade-in-progress`)
      .then(result => {
        this.setState({
          requestedDowngrade: result.data.nextMembership,
          downgradeDate: result.data.downgradeDate,
          downgradeRequestDate: result.data.requestDate
        });
      });

  }

  populateData() {
    let userMembership = ApiService.getCurrentUser().activeUserMembership ? ApiService.getCurrentUser().activeUserMembership : null;
    let membership = userMembership ? userMembership.membership : null;

    this.setState({
      currentMembership: membership ? membership.name : 'Member',
      currentInterval: userMembership ? userMembership.paymentInterval ? userMembership.paymentInterval : null : null,
      canUpgrade: membership ? membership.canUpgrade : false,
      isLoaded: true
    });
  }

  render() {

    const handleUpgradeButtonDisable = () => {
      this.setState({ upgradeButtonsEnabled: false });
    }

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container ca-membership">
          {/* <div className="ca-page-title w-100 mb-2">{translatedMessage("MEMBERSHIP.MEMBERSHIP")}</div> */}
          <Row>
            <Col className="col-12 col-md-6 col-lg-4 pl-2 pr-2 pb-2">
              <MembershipBlock
                name='Member'
                current={this.state.currentMembership === 'Member'}
                upgrade={false}
                disableUpgradeButton={this.state.upgradeButtonsEnabled}
                downgrade={this.state.currentMembership !== 'Member'}
                requestedDowngrade={this.state.requestedDowngrade}
                downgradeDate={this.state.downgradeDate}
                downgradeRequestDate={this.state.downgradeRequestDate}
                currentInterval={this.state.currentInterval}
                handleUpgradeButtonDisable={handleUpgradeButtonDisable.bind(this)}
                logo={caMember}
                selectedLogo={caMemberWhite}
              >
                <ol className={this.state.currentMembership === 'Member' ? "membership-benefits current" : "membership-benefits"}>
                  <li><b>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT1.TITLE")}</b>
                    <div style={{ paddingLeft: "34px" }}>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT1.DESC")}</div></li>
                  <li><b>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT2.TITLE")}</b>
                    <div style={{ paddingLeft: "34px" }}>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT2.DESC")}</div></li>
                  <li><b>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT3.TITLE")}</b>
                    <div style={{ paddingLeft: "34px" }}>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT3.DESC")}</div></li>
                  <li><b>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT4.TITLE")}</b>
                    <div style={{ paddingLeft: "34px" }}>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT4.DESC")}</div></li>
                  <li><b>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT5.TITLE")}</b>
                    <div style={{ paddingLeft: "34px" }}>{translatedMessage("MEMBERSHIP.MEMBER.BENEFIT5.DESC")}</div></li>
                </ol>
              </MembershipBlock>
            </Col>
            <Col className="col-12 col-md-6 col-lg-4 pl-2 pr-2 pb-2">
              <MembershipBlock
                name='Club'
                current={this.state.currentMembership === 'Club'}
                upgrade={this.state.currentMembership !== 'Club' && this.state.canUpgrade}
                disableUpgradeButton={this.state.upgradeButtonsEnabled}
                downgrade={this.state.currentMembership !== 'Club' && !this.state.canUpgrade}
                requestedDowngrade={this.state.requestedDowngrade}
                downgradeDate={this.state.downgradeDate}
                downgradeRequestDate={this.state.downgradeRequestDate}
                currentInterval={this.state.currentInterval}
                handleUpgradeButtonDisable={handleUpgradeButtonDisable.bind(this)}
                logo={caClub}
                selectedLogo={caClubWhite}
              >
                <ol className={this.state.currentMembership === 'Club' ? "membership-benefits title-only current" : "membership-benefits title-only"}>
                  <li>{translatedMessage("MEMBERSHIP.CLUB.BENEFIT1.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.CLUB.BENEFIT2.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.CLUB.BENEFIT3.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.CLUB.BENEFIT4.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.CLUB.BENEFIT5.TITLE")}</li>
                  <li><b>{translatedMessage("MEMBERSHIP.CLUB.BENEFIT6.TITLE")}</b></li>
                </ol>
              </MembershipBlock>
            </Col>
            <Col className="col-12 col-md-6 col-lg-4 pl-2 pr-2 pb-2">
              <MembershipBlock
                name='Club VIP'
                upgrade={this.state.currentMembership !== 'Club VIP' && this.state.canUpgrade}
                disableUpgradeButton={this.state.upgradeButtonsEnabled}
                downgrade={false}
                requestedDowngrade={this.state.requestedDowngrade}
                downgradeDate={this.state.downgradeDate}
                downgradeRequestDate={this.state.downgradeRequestDate}
                current={this.state.currentMembership === 'Club VIP'}
                currentInterval={this.state.currentInterval}
                handleUpgradeButtonDisable={handleUpgradeButtonDisable.bind(this)}
                logo={caVip}
                selectedLogo={caVipWhite}
              >
                <ol className={this.state.currentMembership === 'Club VIP' ? "membership-benefits title-only current" : "membership-benefits title-only"}>
                  <li>{translatedMessage("MEMBERSHIP.VIP.BENEFIT1.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.VIP.BENEFIT2.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.VIP.BENEFIT3.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.VIP.BENEFIT4.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.VIP.BENEFIT5.TITLE")}</li>
                  <li>{translatedMessage("MEMBERSHIP.VIP.BENEFIT6.TITLE")}</li>
                  <li><b>{translatedMessage("MEMBERSHIP.VIP.BENEFIT7.TITLE")}</b></li>
                </ol>
              </MembershipBlock>
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default MembershipPage;