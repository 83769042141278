import React from 'react';

import caMapPin from '../Resources/map/ar_art.png';
import addIcon from '../../../components/shared/Resources/icons/add.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Row, Col, Button, Image } from 'react-bootstrap';

import EditLocationModal from './Components/EditLocationModal';
import TableComponent from './Components/TableComponent';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

class EntityLocationListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artwork: {},
      locations: this.props.locations,
      pin: this.props.pin,
      data: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        geofence: null,
        name: ''
      },
      editModalVisible: false,
      isLoaded: true,
      mapProps: null,
      map: null,
      markers: [],
      fences: [],
      canViewList: false,
      icon: {},
      iconBig: {}
    };

    this.fetchData = this.fetchData.bind(this);
  }

  onMapReady = (props, map) => {
    var initialCenter = MapService.getInitialCenter();
    var marker = new props.google.maps.Marker();

    var icon = caMapPin;
    var iconBig = caMapPin;
    if (this.state.pin !== null) {
      icon = {
        url: this.state.pin.imageUrl,
        scaledSize: new props.google.maps.Size(MapService.getPinSize().width, MapService.getPinSize().height)
      };
      iconBig = {
        url: this.state.pin.imageUrl,
        scaledSize: new props.google.maps.Size(MapService.getBigPinSize().width, MapService.getBigPinSize().height)
      }
    }

    this.setState({
      mapProps: props,
      map: map,
      icon: icon,
      iconBig: iconBig
    })

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        map.setCenter(pos);
      }, () => {
        //Geolocation service failed
        map.setCenter(initialCenter);
      });
    } else {
      // Browser doesn't support Geolocation
      map.setCenter(initialCenter);
    }

    this.setMarkers(props, map);

    map.addListener('click', (e) => {
      marker.setMap(map);
      marker.setPosition(e.latLng);
      marker.setDraggable(true);
      marker.setIcon(icon);

      marker.addListener('click', () => {
        this.setState({
          location: {
            locationLat: marker.position.lat(),
            locationLng: marker.position.lng(),
            geofence: null,
            id: 0
          }
        })
        marker.setMap(null);

        this.onShowModal(0);
      });
    });
  }

  setMarkers = (props, map) => {
    const locations = this.state.locations;
    var markers = [];
    var fences = [];


    locations.forEach((element, index) => {
      const position = { lat: element.locationLat, lng: element.locationLng };

      var marker = new props.google.maps.Marker({
        position: position,
        map: map,
        title: element.name,
        icon: this.state.icon
      });

      markers.push(marker);

      marker.addListener('click', () => {
        this.setState({
          location: {
            id: element.id,
            locationLat: element.locationLat,
            locationLng: element.locationLng,
            geofence: element.geofence,
            name: element.name
          }
        })

        this.onShowModal(0);
      });

      let polygon = MapService.getInitFencePolygon(props, map, false, false, false);
      if (element.geofence) {
        let geofenceCoordinates = JSON.parse(element.geofence)
        polygon.setPath(geofenceCoordinates)
        polygon.setVisible(true)
      }

      fences.push(polygon);
    });

    this.setState({
      markers: markers,
      fences: fences
    })
  }

  clearMarkes = (markers, fences) => {
    markers.forEach((element) => {
      element.setMap(null);
    })

    fences.forEach((element) => {
      element.setMap(null);
    })

    this.setState({
      markers: [],
      fences: []
    })
  }

  addLocationMapCenter = () => {
    var mapCenter = this.state.map.getCenter();

    this.setState({
      location: {
        locationLat: mapCenter.lat(),
        locationLng: mapCenter.lng(),
        geofence: null,
        id: 0
      }
    });

    this.onShowModal(0);
  }

  onShowModal = async (id) => {
    if (this.state.editModalVisible === false) {
      if (id !== 0) {
        await ApiService.getAuthenticatedInstance().get(`/locations/${id}`).then(result => {
          this.setState({
            location: {
              id: id,
              locationLat: result.data.locationLat,
              locationLng: result.data.locationLng,
              geofence: result.data.geofence,
              name: result.data.name
            }
          });
        })
      }

      this.setState({ editModalVisible: true })
      return;
    }
  }

  onHideModal = (mustFetch) => {
    if (mustFetch) {
      this.fetchData(this.state);
    }

    this.setState({
      editModalVisible: false,
      location: []
    });
  }

  async fetchData(state) {
    await ApiService.getAuthenticatedInstance().get(this.props.endPoint + this.props.entityId)
      .then((response) => {
        this.setState({
          locations: response.data,
        }, () => {
          this.clearMarkes(this.state.markers, this.state.fences);
          this.setMarkers(this.state.mapProps, this.state.map);
        })

      })
  }

  onRowClick = (location) => {
    this.state.markers.map((element, index) => {
      if (element.position.lat() === location.locationLat && element.position.lng() === location.locationLng) {
        element.setIcon(this.state.iconBig);
        element.map.setCenter(element.position);
      } else {
        element.setIcon(this.state.icon);
      }

      return element;
    });

  }

  render() {
    if (!this.props.google || !this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <Row className="mb-0">
          <Col className="col-12 col-md-6 ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
              <div className="pr-2 d-flex align-items-center">
                {this.props.icon &&
                  <Image src={this.props.icon} className="ca-page-title-icon" alt="Entity icon"/>
                }
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>
                  {translatedMessage("MAP.LOCATIONS")}
                  <br />
                  <span className="ca-page-subtitle ca-muted-text">{this.props.entityTitle}</span>
                </div>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={this.addLocationMapCenter}>
                  <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} />
                </Button>

                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.go(-1)}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>                
              </div>
            </Row>

            <TableComponent
              data={this.state.locations}
              show={this.onShowModal}
              rowselect={this.onRowClick}
            />
          </Col>
          <Col className="col-12 col-md-6 ca-map-column pl-0 pr-0">
            <Map style={{}} google={this.props.google} zoom={13} initialCenter={MapService.getInitialCenter()} streetViewControl={false}
              onReady={this.onMapReady}>
            </Map>

            <EditLocationModal
              show={this.state.editModalVisible}
              onHide={this.onHideModal}
              location={this.state.location}
              entityId={this.props.entityId}
              entityName={this.props.entityName}
              icon={this.state.icon}
            />
          </Col>
        </Row>
      );
    }
  }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(EntityLocationListComponent);