import React from 'react';

import { translatedMessage } from '../../../services/language.service';

import { Modal, Button, Form } from "react-bootstrap";


class EditFieldModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  saveChanges = (event) => {
    event.preventDefault();
    let data = this.props.data;
    data.content = event.target.elements.fieldContent.value;
    this.props.onHide(data)
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {this.props.data.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Control
              required
              name="fieldContent"
              placeholder={translatedMessage("PROJECT.ARTWORK_NAME_PLACEHOLDER")}
              defaultValue={this.props.data.content}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default EditFieldModalComponent;