import React from 'react';

import { Card } from 'react-bootstrap';
import { translatedMessage } from '../../../../services/language.service';
import ApiService from '../../../../services/api.service';
import ArtworkService from '../../../../services/artwork.service';

class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  render() {
    var isPicture = this.props.category === ArtworkService.artworkCategoryEnum().AR_ART || this.props.category === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE;
    var isObject = this.props.category === ArtworkService.artworkCategoryEnum().OBJECT || this.props.category === ArtworkService.artworkCategoryEnum().AR_PORTAL;
    return (
      <div>
        <Card className="ca-card"
          bg={this.props.background}
          text={this.props.background === "dark" ? 'white' : 'dark'}>

          <Card.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis', minHeight: "200px" }} className="d-flex align-items-center text-center">
            <div className="w-100" style={{ fontSize: "24px" }}>
              <div className="mb-3">{this.state.data.name}</div>
              <div style={{ fontSize: "16px" }}>
                <div className="mb-1">
                  <span className="font-weight-bold mr-1">{translatedMessage("GENERAL.QUANTITY")}:</span>
                  {this.state.data.isFreePackage ? "N/A" : this.state.data.quantity}
                </div>
                <div className="mb-1">
                  <span className="font-weight-bold mr-1">{translatedMessage("GENERAL.PRICE")}:</span>
                  {ApiService.getNumberSeparatedByDots(this.state.data.price) + " USD"}
                </div>
                {isObject && this.state.data.hasDigitalPrint &&
                  <div className="mb-1">
                    <span className="font-weight-bold mr-1">{translatedMessage("ARTWORK.DIGITAL_PRINT")}:</span>
                    {this.state.data.digitalPrintQuantity}
                  </div>
                }                
                {this.state.data.hasSignature && (isPicture || (isObject && this.state.data.hasDigitalPrint)) &&
                  <div className="mb-1">
                    <span className="font-weight-bold mr-1">{translatedMessage("ARTWORK.SIGNATURES")}:</span>
                    {
                      isObject
                        ? (this.state.data.quantity * this.state.data.digitalPrintQuantity)
                        : this.state.data.quantity
                    }
                  </div>
                }
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default CardComponent;