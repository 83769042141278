import React from "react";

import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css';

import { Button, Image } from 'react-bootstrap';

import editIcon from '../../../../../components/shared/Resources/icons/edit.svg';
import deleteIcon from '../../../../../components/shared/Resources/icons/delete.svg';


import { translatedMessage } from '../../../../../services/language.service';

import './UserTableComponent.css';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      pageLength: null,
      itemsPerPage: 10,
      nextIcon: '\uf054',
      prevIcon: '\uf053',
      pageText: translatedMessage("TABLE.PAGE"),
      ofText: translatedMessage("TABLE.OF"),
      rowsText: translatedMessage("TABLE.PAGE"),
      filtered: this.props.data,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ 
        data: this.props.data,
        filtered: this.props.data,
        pageLength: this.props.data.length,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { 
        data: prevState.data,
        filtered: prevState.data,
        pageLength: prevState.data.length,
      };
    }
    else return null;
  }

  onFilteredChange = (value, accessor) => {
    let data = this.state.data;

    let filtered = data.filter(item => item[accessor].toLowerCase().includes(value.toLowerCase()))

    this.setState({ filtered: filtered });
  }

  render() {
    const data = this.state.filtered;
    return (
      <div className="ca-table-user-company" style={{ marginBottom: "100px" }}>
        <ReactTable
          data={data}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBotton={true}
          showPageSizeOptions={false}
          pageSize={this.state.itemsPerPage}
          multiSort={false}
          sortable={true}
          resizable={true}
          filterable={true}
          defaultPageSize={this.state.itemsPerPage}
          nextText={this.state.nextIcon}
          previousText={this.state.prevIcon}
          pageText={this.state.pageText}
          ofText={this.state.ofText}
          rowsText={this.state.rowsText}
          minRows={1}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id
            return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
          }}
          columns={[
            {
              Header: translatedMessage("TABLE.HEADER.NAME"),
              accessor: "user.fullName"
            },
            {
              Header: translatedMessage("GENERAL.STATUS"),
              accessor: "status.label",
              Cell: row => {
                return (translatedMessage(row.original.status.label))
              },
            },
            {
              Header: translatedMessage("COMPANY.USER.IS_ADMINISTRATOR"),
              accessor: "isAdministrator",
              Cell: row => {
                return (row.original.isAdministrator ? translatedMessage("GENERAL.YES") : translatedMessage("GENERAL.NO"))
              },
            },
            {
              Cell: row => {
                return (
                  <>
                    <Button variant="dark" className="ca-dark-link ca-button-icon small mr-1" 
                      onClick={() => this.props.editUser(row.original.id)} title={translatedMessage("GENERAL.EDIT")}>
                      
                      <Image src={editIcon} alt={translatedMessage("GENERAL.EDIT")}/>
                    </Button>
                    <Button variant="dark" className="ca-dark-link ca-button-icon small"   
                      onClick={() => this.props.deleteUSer(row.original.id)} title={translatedMessage("GENERAL.DELETE")}>
                      
                      <Image src={deleteIcon} alt={translatedMessage("GENERAL.DELETE")}/>
                    </Button>                    
                  </>
                )
              },
              sortable: false,
              filterable: false
            },
          ]}
        />
      </div>
    );
  }
}

export default TableComponent
