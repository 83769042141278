import React from 'react';
import { Row, Col, Card, Image, Button } from 'react-bootstrap/';

import './Styles/VirtualGalleryViewComponent.css'

import card1 from '../../../components/shared/Resources/calogo-black.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';

import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';

import Carousel from "../Carousel/MediaCarouselComponent";
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import VirtualGalleryService from '../../../services/virtualGallery.service';
import DownloadService from '../../../services/download.service';

class VirtualGalleryViewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            virtualGallery: null,
            storageItemId: null,
            carouselMedia: [
                {
                    original: card1,
                    thumbnail: card1,
                }
            ],
            pricePackageData: [],
            isLoaded: false,
            isError: false,
            errorMessage: "",
            downloading: false,
            printFileCreated: false,
        };

        this.downloadPrintFile = this.downloadPrintFile.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        if (this.props.canView) {
            VirtualGalleryService.getVirtualGalleryForManage(this.props.virtualGalleryId)
                .then(response => {
                    this.setState({
                        virtualGallery: {
                            ...response,
                            storageItem: {
                                id: response.storageItemId
                            }
                        },
                    }, () => {
                        ApiService.getAuthenticatedInstance().get(`/virtualGallery/${this.props.virtualGalleryId}/activeArtworkPricePackages`)
                            .then(response => {
                                let pricePackages = response.data.pricePackages;
                                let virtualGallery = this.state.virtualGallery;

                                const sorted = pricePackages.sort((a, b) => a.price - b.price);
                                this.setState({
                                    pricePackageData: sorted
                                }, () => {
                                    ApiService.getAuthenticatedInstance().get(`/virtualGallery/${virtualGallery.id}/manage/files`)
                                        .then(results => {
                                            const storageFiles = results.data.files;

                                            let carouselMedia = [];

                                            storageFiles[FileService.getFileUsage().THUMBNAIL]
                                                .map((storageImage, index) => {
                                                    let image = {};
                                                    let startIndex = 0;

                                                    image.file = storageImage;
                                                    image.original = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                                                    image.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                                                    image.type = FileService.getResourceType().IMAGE;
                                                    image.youtubeId = storageImage.youtubeId;
                                                    image.sortOrder = storageImage.defaultThumbnail ? startIndex : index + startIndex + 1;

                                                    carouselMedia.push(image);
                                                    return storageImage;
                                                });

                                            carouselMedia.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)

                                            if (virtualGallery.videoUrlList) {
                                                virtualGallery.videoUrlList
                                                    .map(item => carouselMedia.push({ original: null, thumbnail: null, videoId: item.videoId, type: item.type, file: null }));
                                            }

                                            if (virtualGallery.portalMapUuid) {
                                                let image = {};

                                                image.file = { uuid: virtualGallery.portalMapUuid };
                                                image.original = ApiService.getBaseUrl() + '/storage/file/' + virtualGallery.portalMapUuid;
                                                image.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + virtualGallery.portalMapUuid;
                                                image.type = FileService.getResourceType().IMAGE;

                                                carouselMedia.push(image);
                                            }

                                            // let printFile = storageFiles[FileService.getFileUsage().PRINT_FILE];

                                            this.setState({
                                                carouselMedia: carouselMedia,
                                                isLoaded: true
                                            });

                                        });
                                })
                            })
                    });
                })
                .catch(err => {
                    var errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";
                    if (err && err.response && err.response.status && (400 === err.response.status || 403 === err.response.status || 404 === err.response.status)) {
                        errorMessage = err.response.data.message;
                    }
                    this.setState({
                        isError: true,
                        errorMessage: errorMessage,
                        isLoaded: true
                    })
                })
        } else {
            this.setState({
                isError: true,
                errorMessage: "USER.SECURITY.MISSING_RIGHTS",
                isLoaded: true
            })
        }
    }

    downloadPrintFile() {
        if (!this.state.downloading) {
            this.setState({
                downloading: true
            }, () => {
                DownloadService.httpRequest(
                    ApiService.getBaseUrl() + '/storage/saveVGPrintFile/' + this.state.virtualGallery.id, 'get')
                    .then(response => response.arrayBuffer())
                    .then((blob) => {
                        //Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'print_files.zip');

                        //Append to html page
                        document.body.appendChild(link);

                        //Force download
                        link.click();

                        //Clean up and remove the link                    
                        setTimeout(function () {
                            link.parentNode.removeChild(link);
                            URL.revokeObjectURL(url);
                        }, 3000);

                        this.setState({
                            downloading: false,
                            printFileCreated: true
                        });
                    })
                    .catch((error) => {
                        var errorMessage = translatedMessage("GENERAL.GENERIC_ERROR");
                        if (error.status === 403) {
                            errorMessage = translatedMessage("USER.SECURITY.MISSING_RIGHTS")
                        } else if (error.status === 404) {
                            errorMessage = translatedMessage("VIRTUAL_GALLERY.ERROR.NOT_FOUND")
                        }

                        toast.error(errorMessage, { autoClose: 7000 });

                        this.setState({
                            downloading: false,
                        });
                    });
            })
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                let virtualGallery = this.state.virtualGallery;

                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
                            <div className="pr-2 d-flex align-items-center">
                                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                                <div>
                                    <div className="ca-page-title" style={{ lineHeight: "30px" }}>{virtualGallery.name}</div>
                                    {this.state.virtualGallery.author &&
                                        <div className="ca-page-subtitle ca-muted-text">
                                            {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                                            {this.state.virtualGallery.author.name}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={() => this.props.history.push(this.props.editLink)}>
                                    <Image className="ca-page-title-icon ml-2 mr-0" src={editIcon} alt={translatedMessage("GENERAL.EDIT")}
                                        title={translatedMessage("GENERAL.EDIT")} />
                                </Button>

                                <Button className="ca-dark-link ca-button-icon mr-1" variant="link" onClick={() => this.props.history.push(this.props.backLink)}>
                                    <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                                        title={translatedMessage("BUTTON.BACK")} />
                                </Button>
                            </div>
                        </Row>

                        <Row>
                            <Col className="col-12 col-lg-6 ca-artwork-column-left ca-artwork-image">
                                <Row>
                                    <Carousel
                                        id="image"
                                        items={this.state.carouselMedia}
                                        controls={true}
                                        indicators={false}
                                        interval={null}
                                        class={"ca-carousel-big mx-auto ".concat(this.state.downloading ? "disabled" : "")}
                                        hasToolbar={true}
                                        toolbarDelete={false}
                                        toolbarYoutubeId={false}
                                        toolbarFullScreen={true}
                                        videoUrlOnly={true}
                                    />
                                    {this.state.virtualGallery.hasPrintFile &&
                                        <div className="text-center w-100 mt-2">
                                            <span className="ca-dark-link font-bold" onClick={this.downloadPrintFile} style={{ cursor: this.state.downloading ? "auto" : "pointer", width: "auto" }}>
                                                <span style={{ fontFamily: "FontAwesome", fontSize: "15px" }} className="mr-1">&#xf019;</span>
                                                {translatedMessage("ARTWORK.DOWNLOAD_PRINT_FILE")}
                                            </span>
                                        </div>
                                    }
                                    {this.state.downloading &&
                                        <div className="w-100 text-center" style={{ position: "absolute", top: "6.5rem" }}>
                                            <div className="fa-5x">
                                                <i className="fa fa-spinner fa-spin" ></i>
                                            </div>
                                            <div >{this.state.printFileCreated ? translatedMessage("GENERAL.DOWNLOADING") : translatedMessage("ARTWORK.PRINT_FILE.CREATE_DOWNLOAD")}</div>
                                        </div>
                                    }
                                </Row>
                            </Col>

                            <Col className="col-12 col-lg-6 ca-artwork-column-right">
                                <Row>
                                    {this.state.pricePackageData.map((element, index) => {
                                        return (
                                            <Col key={index} className="col-12 col-sm-6">
                                                <Card className={element.investorPackage ? "ca-artwork-pricing-card black-card details-card" : "ca-artwork-pricing-card details-card"}>
                                                    <Card.Header>
                                                        <Card.Title>{element.name}</Card.Title>
                                                        <div className="w-100 ca-card-detail-text">
                                                            {element.quantity === -1
                                                                ? translatedMessage("ARTWORK.IN_COLLECTION") + ": " + (element.nrCopies - element.availableCopies)
                                                                : translatedMessage("ARTWORK.AVAILABLE") + ": " + element.availableCopies + " / " + element.nrCopies
                                                            }
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body className="">
                                                        <div className="w-100">{ApiService.getNumberSeparatedByDots(element.price)} USD</div>
                                                    </Card.Body>
                                                    <Card.Footer style={{ fontSize: 13 + 'px' }}>
                                                        {translatedMessage(element.description)}
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>

                        <Row className="view-description-about">
                            <Col className="col-12 col-lg-6 ca-artwork-column-left">
                                <div className="ca-paragraph-title">
                                    {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                                </div>

                                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: virtualGallery.description }} />

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("PROJECT.CATEGORY")}
                                    </div>
                                    <div className="ca-view-value">{translatedMessage(virtualGallery.type.label)}</div>
                                </div>
                            </Col>
                            <Col className="col-12 col-lg-6 ca-artwork-column-right">
                                <div className="ca-paragraph-title">
                                    {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                                </div>
                                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: virtualGallery.aboutAuthor }} />
                            </Col>
                        </Row>
                    </div>
                );
            }
        }
    }
}

export default VirtualGalleryViewComponent;