import React from 'react';
import './ViewCollectionArtworkPage.css'
import card1 from '../../../components/shared/Resources/calogo-black.svg';
import back from '../../../components/shared/Resources/left-arrow.svg';

import { Row, Col, Image } from 'react-bootstrap/';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import FileService from '../../../services/file.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';
import DownloadService from '../../../services/download.service';

import DisplayVideoComponent from '../../../components/shared/DisplayVideoComponent/DisplayVideoComponent';
import ExperienceCardComponent from '../../../components/shared/ExperienceCardComponent/ExperienceCardComponent';
import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

class ViewCollectionArtworkPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      soldArtwork: null,
      storageItemId: null,
      authorName: "",
      author: [],
      artworkImages: [
        {
          original: card1,
          thumbnail: card1,
        }
      ],
      artworkVideos: [],
      extendedExperiencesData: [],
      soldArtworkId: 0,
      artworkId: 0,
      pricePackage: "",
      issueNumber: 0,
      nrCopies: 0,
      packageQuantity: 0,
      uploadedVideos: 0,
      isLoaded: false,
      isError: false,
      errorMessage: "",
      downloading: false,
      printFileCreated: false,
    };

  }

  componentDidMount() { this.populateData() }

  populateData = () => {
    ApiService.getAuthenticatedInstance()
      .get(`artwork/soldArtworkDetail/${this.props.match.params.id}`)
      .then(response => {
        const soldArtwork = response.data.soldArtwork;

        let addOnList = soldArtwork.addOnList;
        if (addOnList.length === 0) {
          addOnList.push({
            name: translatedMessage("ADD_ON.NOT_AVAILABLE.NAME"),
            description: translatedMessage("ADD_ON.NOT_AVAILABLE.DESCRIPTION"),
            isMain: false
          })
        }

        this.setState({
          soldArtwork: soldArtwork,
          printFileCreated: soldArtwork.printFileCreated,
          addOnList: addOnList
        }, () => {
          ApiService.getAuthenticatedInstance()
            .get(`/artwork/collectionFiles/${soldArtwork.id}`)
            .then(results => {
              const storageFiles = results.data.files;

              const presentationImagesArray = [];
              storageFiles[FileService.getFileUsage().THUMBNAIL]
                .map((storageImage, index) => {
                  let mockImage = {};
                  mockImage.file = storageImage;
                  mockImage.original = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                  mockImage.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                  mockImage.type = FileService.getResourceType().IMAGE;
                  mockImage.youtubeId = null;
                  mockImage.sortOrder = storageImage.defaultThumbnail ? 0 : index + 1;
                  presentationImagesArray.push(mockImage);
                  return storageImage.file;
                });

              presentationImagesArray.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

              const artworkVideos = storageFiles[FileService.getFileUsage().CREATE_DESTROY]
                .concat(storageFiles[FileService.getFileUsage().CREATION_PROC])
                .concat(storageFiles[FileService.getFileUsage().ART_DESC]);

              let uploadedVideos = artworkVideos.length;

              this.setState({
                artworkImages: presentationImagesArray,
                artworkVideos: artworkVideos,
                uploadedVideos: uploadedVideos,
                isLoaded: true
              })
            });
        });
      })
      .catch(err => {
        var errorMessage = translatedMessage("ARTWORK.GENERAL_ERROR");
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = translatedMessage(err.response.data.message);
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  downloadPrintFile = () => {
    if (!this.state.downloading) {
      this.setState({
        downloading: true
      }, () => {
        DownloadService.httpRequest(
          ApiService.getBaseUrl() + '/storage/saveCollectionPrintFile/' + this.state.soldArtwork.id, 'get')
          .then(response => response.arrayBuffer())
          .then((blob) => {
            //Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'print_files.zip');

            //Append to html page
            document.body.appendChild(link);

            //Force download
            link.click();

            //Clean up and remove the link                    
            setTimeout(function () {
              link.parentNode.removeChild(link);
              URL.revokeObjectURL(url);
            }, 3000);

            this.setState({
              downloading: false,
              // printFileCreated: true
            }, this.getPrintFileDetails);
          })
          .catch((error) => {
            var errorMessage = translatedMessage("GENERAL.GENERIC_ERROR");
            if (error.status === 403) {
              errorMessage = translatedMessage("GENERAL.GENERIC_RIGHTS_ERROR")
            } else if (error.status === 404) {
              errorMessage = translatedMessage("ARTWORK.NOT_FOUND")
            }

            toast.error(errorMessage, { autoClose: 7000 });
            
            this.setState({
              downloading: false
            });
          });
      })
    }
  }

  getPrintFileDetails = () => {
    if (!this.state.printFileCreated) {
      ApiService.getAuthenticatedInstance()
        .get(`/artwork/collection/${this.state.soldArtwork.id}/print-size`)
        .then(results => {
          const printSizes = results.data.files;

          this.setState({
            soldArtwork: {
              ...this.state.soldArtwork,
              printSizeList: printSizes,
            },
            printFileCreated: true
          })
        });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-1 pr-2">
              <div className="ca-page-subtitle d-flex align-items-center">
                <span className="font-weight-bold">{this.state.errorMessage}</span>&nbsp;
              </div>
              <div>
                <Link to="/collection" className="ca-dark-link">
                  <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                    title={translatedMessage("BUTTON.BACK")} />
                </Link>
              </div>
            </Row>
          </div>
        )
      } else {
        let experienceThumbnail = card1;
        let soldArtwork = this.state.soldArtwork;
        let artwork = this.state.soldArtwork.artwork;
        let addOnList = this.state.addOnList;
        let artworkPricePackage = this.state.soldArtwork.artworkPricePackage;
        let isARArt = artwork.category.code === ArtworkService.artworkCategoryEnum().AR_ART ? true : false;

        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-1 pr-2">
              <div className="ca-page-title">
                <span className="font-weight-bold">{artwork.name}</span>&nbsp;
                {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                {artwork.authorList.map((element, index) => {
                  if (index === 0) {
                    return (
                      <Link key={index} to={"/profile/" + element.userId + "/view"}>
                        {element.name}
                      </Link>
                    )
                  } else {
                    return (
                      <Link key={index} to={"/profile/" + element.userId + "/view"}>
                        ,&nbsp;{element.name}
                      </Link>
                    )
                  }
                })}
                <div className="ca-art-date">{ApiService.parseDate(artwork.publishDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' })}</div>
                <div className="ca-art-package">
                  {translatedMessage("ARTWORK.PACKAGE")}:&nbsp;
                  <span className="font-weight-bold">
                    {artworkPricePackage.quantity === -1
                      ? artworkPricePackage.name
                      : artworkPricePackage.name + " - " + soldArtwork.issueNumber + " / " + artworkPricePackage.nrCopies
                    }
                  </span>
                </div>
              </div>
              <div>
                <Link to="/collection" className="ca-dark-link">
                  <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                    title={translatedMessage("BUTTON.BACK")} />
                </Link>
              </div>
            </Row>

            <Row>
              <Col className="col-12 col-lg-6 ca-artwork-column-left ca-artwork-image">
                <Row>
                  <Carousel
                    id="image"
                    items={this.state.artworkImages}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class={"ca-carousel-big mx-auto ".concat(this.state.downloading ? "disabled" : "")}
                    hasToolbar={true}
                    toolbarDelete={false}
                    toolbarYoutubeId={false}
                    toolbarFullScreen={true}
                  />
                  {this.state.soldArtwork.hasPrintFile &&
                    <div className="text-center w-100 mt-2">
                      <span className="ca-dark-link font-bold" onClick={this.downloadPrintFile} style={{ cursor: this.state.downloading ? "auto" : "pointer", width: "auto" }}>
                        <span style={{ fontFamily: "FontAwesome", fontSize: "15px" }} className="mr-1">&#xf019;</span>
                        {translatedMessage("ARTWORK.DOWNLOAD_PRINT_FILE")}
                      </span>
                    </div>
                  }
                  {this.state.downloading &&
                    <div className="w-100 text-center" style={{ position: "absolute", top: "6.5rem" }}>
                      <div className="fa-5x">
                        <i className="fa fa-spinner fa-spin" ></i>
                      </div>
                      <div >{this.state.printFileCreated ? translatedMessage("GENERAL.DOWNLOADING") : translatedMessage("ARTWORK.PRINT_FILE.CREATE_DOWNLOAD")}</div>
                    </div>
                  }
                </Row>
              </Col>

              {isARArt &&
                <Col className="col-12 col-lg-6 ca-artwork-column-right">
                  <Row>
                    {addOnList.map((element, index) => {
                      return (
                        <Col key={index} className="col-12 col-sm-6 pl-0">
                          <ExperienceCardComponent
                            data={element}
                            thumbnail={experienceThumbnail}
                            showThumbnail={false}
                            showIsMain={false}
                            variant="dark"
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              }

              <Col className={"col-12 col-lg-6 ".concat(isARArt ? "ca-artwork-column-left" : "ca-artwork-column-right")}>
                <div className="ca-paragraph-title">
                  {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                </div>

                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: artwork.description }} />

                <div className="ca-display-group">
                  <div className="ca-label">
                    {translatedMessage("PROJECT.CATEGORY")}
                  </div>
                  <div className="ca-view-value">{translatedMessage(artwork.category.code)}</div>
                </div>

                <div className="ca-display-group">
                  <div className="ca-label">
                    {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                  </div>
                  <div className="ca-view-value">{artwork.sizeWidth} W x {artwork.sizeHeight} H cm</div>
                </div>

                {this.state.soldArtwork.printSizeList && this.state.soldArtwork.printSizeList.length > 0 &&
                  <div className="ca-display-group">
                    <div className="ca-label">
                      {translatedMessage("ARTWORK.PRINT.SIZE")}
                    </div>
                    {this.state.soldArtwork.printSizeList.map((element, index) => {
                      return (
                        <div key={index} className="ca-view-value">{element}</div>
                      )
                    })}
                  </div>
                }
              </Col>

              <Col className={isARArt ? "col-12 col-lg-6 ca-artwork-column-right" : "col-12"}>
                <div className="ca-paragraph-title">
                  {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                </div>
                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: artwork.aboutAuthor }} />
              </Col>
            </Row>

            {this.state.uploadedVideos === 0 ? "" :
              <Row>
                <div className="ca-paragraph-title w-100">
                  {translatedMessage("PROJECT.VIDEOS")}
                </div>
                {this.state.artworkVideos.map((element, index) => {
                  return (
                    <Col key={index} className="col-12 col-md-6 col-lg-4 pl-0">
                      <DisplayVideoComponent youtubeId={element.youtubeId} width="640" height="400"
                        classvideo="ca-youtube-video-container"
                        classimage="ca-novideo-container-md-hide"
                        title={translatedMessage(element.usage)} />
                    </Col>
                  )
                })}
              </Row>
            }
          </div>
        );
      }
    }
  }
}

export default ViewCollectionArtworkPage