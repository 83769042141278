import React from 'react';

import CardComponent from '../EntityManageVGCardComponent/EntityManageVGCardComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import VirtualGalleyViewModalComponent from '../VirtualGallery/VirtualGalleyViewModalComponent';
import { toast } from "react-toastify";

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import mapIcon from '../../../components/shared/Resources/icons/map.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../components/shared/Resources/icons/cancel.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import parkIcon from '../../../components/shared/Resources/homepage/art_park.svg';

import { Row, Col, Form, Button, Dropdown, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';

import { translatedMessage } from '../../../services/language.service';
import ParkService from '../../../services/park.service';

const ENTER_KEY = 13;

class ParkManageVirtualGalleryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.parkId,
      park: {},
      searchInput: '',
      parkVGList: [],
      typeList: [],
      selectedType: { label: "GENERAL_TYPE.ANY", name: "ANY" },
      filteredList: [],
      authors: [],
      existingVGList: [],
      isLoaded: false,
      isAdd: false,
      canHaveLocation: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      showVirtualGalleryModal: false,
      modalData: {
        art: {}
      },
    };

    this.addVG = this.addVG.bind(this);
  }

  componentDidMount = () => {
    if (this.props.canEdit) {
      this.getData();
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  getData = () => {
    let parkId = this.state.parkId;
    let parkVGList = [];
    let park = {};
    let promise = [];
    let canHaveLocation = false;
    let typeList = [];

    let criteria = {};
    criteria.sortOrder = "ASC";
    criteria.sortField = "vg.name";
    criteria.pageSize = -1;
    criteria.parkId = parkId
    criteria.entityName = ApiService.getEntityName().VIRTUAL_GALLERY

    console.log(parkId)


    ParkService.getParkArtListForManage(criteria)
      .then(response => {
        parkVGList = response.artList;
        park = response.park
      })
      .then(() => {
        if (parkId) {
          promise.push(ApiService.getAuthenticatedInstance().get(`park/${parkId}/location/manage`)
            .then(response => {
              const location = response.data;
              if (location && location.locationLat !== 0 && location.locationLng !== 0) {
                canHaveLocation = true;
              }
            })
          )

          promise.push(VirtualGalleryService.getVirtualGalleryTypeList()
            .then(response => {
              typeList = response;
            })
          )
        }

        Promise.all(promise).then(() => {
          typeList.splice(0, 0, { label: "GENERAL_TYPE.ANY", name: "ANY" })

          this.setState({
            parkVGList: parkVGList,
            filteredList: parkVGList,
            park: park,
            canHaveLocation: canHaveLocation,
            typeList: typeList,
            isLoaded: true
          }, this.filter)
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleTypeSelect = (eventKey) => {
    const selectedItem = this.state.typeList.filter(item => item.name === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.parkVGList;
    let searchInput = this.state.searchInput;
    let typeItem = this.state.selectedType.name;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.parkVGList.filter(elem => elem.virtualGallery.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (typeItem !== 'ANY') {
      result = result.filter(elem => elem.virtualGallery.type.name === typeItem);
    }

    this.setState({
      filteredList: result
    })
  }

  async addVG() {
    var virtualGalleries = [];

    if (this.props.isAdmin) {
      virtualGalleries = await VirtualGalleryService.getVirtualGalleriesByStatus(VirtualGalleryService.virtualGalleryStatusEnum().PUBLISHED, false)
        .then(response => { return response });
    } else {
      virtualGalleries = await VirtualGalleryService.getVirtualGalleriesForCompany()
        .then(response => { return response });
    }

    var vg = []
    var existingVGList = [];
    virtualGalleries.map((element, index) => {
      const existingVG = this.state.parkVGList.filter(item => item.virtualGallery.id === element.id);
      if (existingVG.length > 0) {
        existingVGList.push(existingVG[0].virtualGallery.id.toString());
      }
      vg.push({
        "virtualGallery": element,
        "park": this.state.park,
        "imageURL": element.imageURL,
        "existingVG": existingVG.length > 0 ? true : false,
        "id": element.id
      })
      return element;
    })

    this.setState({
      parkVGList: vg,
      filteredList: vg,
      isAdd: true,
      existingVGList: existingVGList
    }, this.filter);
  }

  handleCheckChange = (id, isChecked) => {
    let existingVGList = this.state.existingVGList;
    if (isChecked) {
      existingVGList.push(id)
    } else {
      let index = existingVGList.indexOf(id);
      if (index !== -1) {
        existingVGList.splice(index, 1);
      }
    }

    this.setState({
      existingVGList: existingVGList
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    ParkService.addArtToPark(this.state.parkId, ApiService.getEntityName().VIRTUAL_GALLERY, this.state.existingVGList)
      .then(response => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          isAdd: false,
        }, this.getData)
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        this.setState({
          isAdd: false,
        }, this.getData)
      })
  }

  cancelAdd = () => {
    this.setState({
      isAdd: false,
    }, this.getData)
  }

  onShowModal = (artId) => {
    VirtualGalleryService.getVirtualGallery(artId)
      .then(response => {
        this.setState({
          modalData: response,
          showVirtualGalleryModal: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";

        console.log(err)
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }

        toast.error(translatedMessage(errorMessage));
      });
  }

  onHideModal = () => {
    this.setState({
      showVirtualGalleryModal: false
    })
  }

  render() {
    const parkVirtualGalleries = this.state.filteredList;
    const backUrl = this.state.park?.parentPark?.id ? `/${this.props.entityUrl}/${this.state.park.parentPark.id}/view` : `/${this.props.entityUrl}`

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={parkIcon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITION")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.park.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("VIRTUAL_GALLERY.MANAGE")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={this.addVG} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={virtualGalleryIcon} alt={translatedMessage("ARTWORK.MANAGE")} title={translatedMessage("ARTWORK.MANAGE")} />
              </Button>
              <Button type="submit" form="vgForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button onClick={this.cancelAdd} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
              </Button>
              <Button onClick={() => this.props.history.push(this.props.mapUrl)} className="ca-dark-link ca-button-icon medium mr-1">
                <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} title={translatedMessage("MAP.VIEW_ON_MAP")} />
              </Button>
              <Button onClick={() => this.props.history.push(backUrl)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} onChange={this.handleSearchInput} className="ca-form-control-search"
                type="text" placeholder="&#xF002;" />
            </Col>

            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>                   
          </Row>


          <Form id="vgForm" className="ca-form" onSubmit={this.handleSubmit}>
            <Row>
              {parkVirtualGalleries.map((element, index) => {
                return (
                  <Col key={index} className="col-12 col-sm-6 col-md-4 col-xl-3 pl-0 pr-0">
                    <CardComponent
                      key={element.id}
                      id={element.id}
                      cardImage={element.imageURL}
                      virtualGallery={element.virtualGallery}
                      soldByCompany={element.virtualGallery.soldByCompany}    
                      isAdd={this.state.isAdd}
                      existingVG={!this.state.isAdd ? true : element.existingVG}
                      onCheck={this.handleCheckChange}
                      canHaveLocation={this.state.canHaveLocation}
                      canDefineArt={element.virtualGallery.needsArtNo && element.virtualGallery.type.name !== VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY}
                      locationRedirectUrl={`/parkAdmin/${this.state.parkId}/virtualGallery/${element.id}/location`}
                      artworkUrl={`${this.props.artworkPrefixUrl}/${element.id}/artworks`}
                      arExperienceUrl={this.props.isAdmin ? `${this.props.artworkPrefixUrl}/${element.id}/ar-experiences` : null}
                      triggerRedirectUrl={`${this.props.triggerUrl}/${element.id}`}
                      isAdmin={this.props.isAdmin}
                      onClick={this.onShowModal}
                    />
                  </Col>
                )
              })}
            </Row>
          </Form>

          <VirtualGalleyViewModalComponent
            show={this.state.showVirtualGalleryModal}
            onHide={this.onHideModal}
            art={this.state.modalData}
          />
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ParkManageVirtualGalleryComponent;