import ApiService from './api.service';

const MapService = {
  getGoogleMapKey: () => {
    //local
    let apiKey = "AIzaSyB2R-O-AG2RUJuF5-mMSbZ59nqYiolUvmk";
    //staging and production
    //let apiKey = "AIzaSyDFQ0MkbNugR6j88y3gPCvga5IdKQz1fk4";

    return apiKey;
  },

  getInitialCenter: () => {
    var initialCenter = {
      lat: 59.916969,
      lng: 10.728098
    }

    return initialCenter
  },

  getPinSize: () => {
    return {
      width: 36, 
      height: 50
    }
  },
  
  getSmallPinSize: () => {
    return {
      width: 28.8, 
      height: 40
    }
  },

  getBigPinSize: () => {
    return {
      width: 43.2, 
      height: 60
    }
  },  

  getNewCoordinates: (location , dx, dy) => {
    let lat = location.lat  + (dx / 6378) * (180 / 3.1415);
    let lng = location.lng  + (dy / 6378) * (180 / 3.1415) / Math.cos(location.lat * 3.1415/180);

    return {
      lat: lat,
      lng: lng
    };
  },

  getInitFenceCircle: (props, map, visible, editable, draggable) => {
    var fenceCircle = new props.google.maps.Circle({
      strokeColor: '#000000',
      strokeOpacity: 0.4,
      strokeWeight: 0.7,
      fillColor: '#000000',
      fillOpacity: 0.3,
      map: map,
      editable: editable,
      draggable: draggable,
      geodesic: true,
      visible: visible
    });

    return fenceCircle;
  },

  getInitFencePolygon: (props, map, visible, editable, draggable) => {
    let fencePolygon = new props.google.maps.Polygon({
      strokeColor: '#000000',
      strokeOpacity: 0.4,
      strokeWeight: 0.7,
      fillColor: '#000000',
      fillOpacity: 0.2,
      map: map,
      editable: editable,
      draggable: draggable,
      geodesic: true,
      visible: visible
    });

    return fencePolygon;
  },

  getInitialPolygon: (_location) => {    
    let location1 = MapService.getNewCoordinates(_location, 0.2, 0);
    let location2 = MapService.getNewCoordinates(location1, -0.2, 0.2);
    let location3 = MapService.getNewCoordinates(location2, -0.2, -0.2);
    let location4 = MapService.getNewCoordinates(location3, 0.2, -0.2);

    return [
      {lat: location1.lat, lng: location1.lng},
      {lat: location2.lat, lng: location2.lng},
      {lat: location3.lat, lng: location3.lng},
      {lat: location4.lat, lng: location4.lng},
    ];
  },

  getCoordinatesFromPolygon: (_polygon) => {
    let polygonPath = _polygon.getPath();
    let coordinates = []
    polygonPath.forEach(item => {
      let coordinate = { lat: item.lat(), lng: item.lng() }
      coordinates.push(coordinate)
    })
    
    return coordinates;
  },

  async getGeofences() {
    let response = await ApiService.getAuthenticatedInstance().get(ApiService.getBaseUrl() + "/map/geofences");
    let geofences = ApiService.getSafe(() => response.data, []);

    return geofences;
  },

  updateGeofence(id, geofence) {
    ApiService.getAuthenticatedInstance().patch(`/geofences/${id}`, geofence);
  },
  
  getLocationPins() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/map/locationPins`)
        .then(response => {
          let data = response.data;

          data.map((element, index) => {            
            element.imageUrl = `${ApiService.getBaseUrl()}/storage/file/${element.pinFile.uuid}`
            return element;
          });

          data.sort((a, b) => {
            var result;
            a.type.name.toUpperCase() <= b.type.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(data)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getLocationPinByType(type) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/map/locationPins`)
        .then(response => {
          let data = response.data;

          data.map((element, index) => {            
            element.imageUrl = `${ApiService.getBaseUrl()}/storage/file/${element.pinFile.uuid}`
            return element;
          });

          const filteredData = data.filter(item => item.type.label === type);
          resolve(filteredData)
        })
        .catch(err => {
          reject(err);
        });
    });
  },  
   
  getLocationTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/locationTypeList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  }, 
  
  async getMapLocations() {
    let response = await ApiService.getAuthenticatedInstance().get(ApiService.getBaseUrl() + "/map/userMapLocations");
    let locations = ApiService.getSafe(() => response.data, []);

    return locations;
  },  
};

export default MapService;