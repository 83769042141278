import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { translatedMessage } from "../../../services/language.service";

class DeleteConfirmModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    deleteEntry = () => {
        this.props.onHide(true);
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="web-resource-delete-modal"
            >
                <Modal.Header></Modal.Header>
                <Modal.Body className="text-center ca-modal-delete-confirm">
                    {this.props.message !== "" ? this.props.message : translatedMessage("GENERAL.DELETE_CONFIRM")}
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="dark" className="ca-button ca-button-white" onClick={this.deleteEntry}>{translatedMessage("GENERAL.YES")}</Button>
                        <Button variant="dark" className="ca-button" onClick={this.props.onHide}>{translatedMessage("GENERAL.NO")}</Button>
                </Modal.Footer>                
            </Modal>
        )
    }
}

export default DeleteConfirmModalComponent;