import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class LightboxComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: this.props.images,
      photoIndex: 0,
      showLightbox: this.props.showLightbox,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showLightbox !== this.props.showLightbox) {
      this.setState({ 
        images: this.props.images,
        showLightbox: this.props.showLightbox,
        photoIndex: this.props.photoIndex ? this.props.photoIndex : 0,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLightbox !== prevState.showLightbox) {
      return { 
        images: prevState.images,
        showLightbox: prevState.showLightbox,
        photoIndex: prevState.photoIndex ? prevState.photoIndex : 0,
      };
    }
    else return null;
  }

  render() {
    const { images, photoIndex, showLightbox } = this.state;

    return (
      <div className="ca-lightbox">
        {showLightbox && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={this.props.onHide}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default LightboxComponent;