import React from 'react';

import { translatedMessage } from '../../../services/language.service';

import { Modal, Button, Form } from "react-bootstrap";

import ApiService from '../../../services/api.service';

class ProfileDetailsEditModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: this.props.address,
      countries: []
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  componentDidMount = () => {
    ApiService.getCountries()
      .then(response => {
        this.setState({
          countries: response
        })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.address !== this.props.address) {
      this.setState({ address: this.props.address });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.address !== prevState.address) {
      return { address: prevState.address };
    }
    else return null;
  }

  onProfileChange = (event) => {
    if (!event.target) {
      return;
    }

    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      address: {
        ...this.state.address,
        [name]: value
      }
    });
  }

  saveChanges = (event) => {
    event.preventDefault();

    let data = this.state.address;
    data.dataFor = "address";
    this.props.onHide(data);
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("ADDRESS.CHANGE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Group controlId="formAddress">
              <Form.Label>{translatedMessage("PROFILE.ADDRESS")}</Form.Label>
              <Form.Control
                name="address"
                placeholder={translatedMessage("PROFILE.ADDRESS_PLACEHOLDER")}
                value={this.state.address.address}
                onChange={this.onProfileChange}
              />
            </Form.Group>

            <Form.Group controlId="formZip">
              <Form.Label>{translatedMessage("PROFILE.ZIP")}</Form.Label>
              <Form.Control
                name="zip"
                placeholder={translatedMessage("PROFILE.ZIP_PLACEHOLDER")}
                value={this.state.address.zip}
                onChange={this.onProfileChange}
              />
            </Form.Group>

            <Form.Group controlId="formCity">
              <Form.Label>{translatedMessage("PROFILE.CITY")}</Form.Label>
              <Form.Control
                name="city"
                placeholder={translatedMessage("PROFILE.CITY_PLACEHOLDER")}
                value={this.state.address.city}
                onChange={this.onProfileChange}
              />
            </Form.Group>

            <Form.Group controlId="formCountry">
              <Form.Label>{translatedMessage("PROFILE.COUNTRY")}</Form.Label>
              <Form.Control
                name="countryId"
                as="select"
                value={this.state.address.countryId}
                onChange={this.onProfileChange}
              >
                <option value={0} key={0}>{translatedMessage("PROFILE.SELECT_COUNTRY")}</option>
                {this.state.countries.map(country => (
                  <option value={country.id} key={country.id}>{country.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default ProfileDetailsEditModalComponent;