import ApiService from "./api.service";

const DownloadService = {
  
  // Main wrapper for Fetch API
  httpRequest: async (url, method, headers) => {
  
    // Configuration to accept json as a default
    const config = {
      method,
      headers: {
        "Content-Type": "application/octet-stream", 
        'Authorization': `Bearer ${ApiService.getToken()}`,        
      },
      responseType: "arraybuffer"
    };
  
    // if custom headers need to be set for the specific request
    // override them here
    if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
      config.headers = headers;
    }

    const response = await fetch(url, config);
    // Check if the request is 200
    if (response.ok) {
      let data = response;

      // if the type is json return, interpret it as json
      if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
        data = response.json();
      }

      return data;
    }
    return Promise.reject(response);
  },
};
export default DownloadService;