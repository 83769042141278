import React from 'react';

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';

import saveIcon from '../../components/shared/Resources/icons/save.svg';
import backIcon from '../../components/shared/Resources/icons/back.svg';
import sculptureIcon from '../../components/shared/Resources/icons/sculpture.svg';
import arArtIcon from '../../components/shared/Resources/icons/ar_art.svg';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ApiService from '../../services/api.service';
import ArExperienceService from '../../services/arExperience.service';

import LanguageService from '../../services/language.service';
import { translatedMessage } from '../../services/language.service';
import { hasPermission } from '../../services/api.service';
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

import { toast } from "react-toastify";
import moment from 'moment';
import ArtworkService from '../../services/artwork.service';

class ArExperienceEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id : null,
            arExperience: [],
            statusList: [],
            categoryList: [],
            isLoaded: false,
            displayImg: true,
            editorButtns: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
            canEdit: false,
            isError: false
        };
    }

    componentDidMount = () => {
        ApiService.initCurrentUser().then(() => {
            const canEdit = this.props.match.params.id ? hasPermission("AR_EXPERIENCE_EDIT") : hasPermission("AR_EXPERIENCE_CREATE");

            this.setState({
                canEdit: canEdit
            }, this.getData)
        });
    }

    getData = () => {
        let arExperience = [];
        let isNew = false;
        let promise = [];

        if (this.state.canEdit) {
            var statusList = []
            promise.push(ArExperienceService.getArExperienceStatusList()
                .then(response => {
                    statusList = response;
                }))

            var categoryList = []
            promise.push(ArExperienceService.getArtCategoryList()
                .then(response => {
                    categoryList = response;
                }))

            if (this.state.id !== null) {
                promise.push(ApiService.getAuthenticatedInstance().get(`arExperience/arExperience/${this.state.id}`)
                    .then(response => {
                        arExperience = response.data;
                    })
                )
            } else {
                isNew = true;
            }


            Promise.all(promise).then(() => {
                if (isNew) {
                    const statusItem = statusList.filter(item => item.name === 'DRAFT');
                    const categoryItem = categoryList.filter(item => item.name === 'AR_ART');
                    arExperience.status = statusItem[0];
                    arExperience.category = categoryItem[0];
                    arExperience.description = null;
                    arExperience.streamedExperience = false;
                }
                if (arExperience) {
                    this.setState({
                        statusList: statusList,
                        categoryList: categoryList,
                        arExperience: arExperience,
                        isLoaded: true
                    })
                } else {
                    this.setState({
                        isError: true,
                        errorMessage: "AR_EXPERIENCE.GENERAL_ERROR",
                        isLoaded: true
                    })
                }
            })
        } else {
            this.setState({
                isError: true,
                errorMessage: "USER.SECURITY.MISSING_RIGHTS",
                isLoaded: true
            })
        }
    }

    handleOnStatusSelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
        this.setState({
            arExperience: {
                ...this.state.arExperience,
                status: selectedItem[0]
            }
        });
    }

    handleOnCategorySelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.categoryList.filter(item => item.name === eventKey);
        this.setState({
            arExperience: {
                ...this.state.arExperience,
                category: selectedItem[0]
            }
        });
    }

    handleIsStremedSelect = (eventKey) => {
        this.setState({
            arExperience: {
                ...this.state.arExperience,
                streamedExperience: eventKey === "true" ? true : false
            }
        });
    }

    onChangeEditor = (e) => {
        this.setState({
            arExperience: {
                ...this.state.arExperience,
                description: e,
            }
        })
    }

    validate = (event) => {
        var response = {
            isError: false,
            message: ""
        };

        return response;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const validation = this.validate(event);
        if (!validation.isError) {
            const id = this.state.id === null ? 0 : this.state.id;

            ApiService.getAuthenticatedInstance().post(`/arExperience/saveArExperience`, {
                id: parseInt(id),
                name: event.target.elements.formName.value.toString(),
                description: this.state.arExperience.description,
                author: event.target.elements.formAuthor.value,
                sizeWidth: Number(event.target.elements.formWidth.value),
                sizeHeight: Number(event.target.elements.formHeight.value),
                status: this.state.arExperience.status.name,
                category: this.state.arExperience.category.name,
                streamedExperience: this.state.arExperience.streamedExperience,
                lastUpdatedBy: ApiService.getCurrentUser().username,
                lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),                   
            })
                .then(response => {
                    toast.success(translatedMessage(response.data.message));
                    if (id === 0) {
                        const id = response.data.arExperience.id;
                        this.setState({
                            id: response.data.arExperience.id,
                        })
                        this.props.history.push(`/arExperienceEdit/${id}`)
                    }
                })
                .catch(error => {
                    console.log("An error has ocurred: " + error);
                    if (error.response && error.response.data && error.response.data.message) {
                        toast.error(translatedMessage(error.response.data.message));
                    }
                });

        } else {
            toast.error(validation.message);
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                let isArArt = this.state.arExperience.category.name === ArtworkService.artworkCategoryEnum().AR_ART;

                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-3 pr-2">
                            <span className="ca-page-title">{translatedMessage("AR_EXPERIENCE") + " - " + translatedMessage("AR_EXPERIENCE.MANAGE_DETAILS")}</span>

                            <div>
                                <Button className="ca-dark-link ca-button-icon medium mr-1" type="submit" variant="link" form="ar-experience-form">
                                    <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
                                </Button>

                                {this.state.id &&
                                    <Button className={"ca-dark-link ca-button-icon mr-1 ".concat(isArArt ? "" : "medium")} variant="link" 
                                        onClick={() => this.props.history.push(`/arExperienceFile/${this.state.id}`)}>
                                        <Image src={isArArt ? arArtIcon : sculptureIcon} alt={translatedMessage("AR_ART")} title={translatedMessage("AR_EXPERIENCE.MANAGE_ART_CONTENT")} />
                                    </Button>
                                }

                                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/arExperienceList`)}>
                                    <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                                </Button>
                            </div>
                        </Row>
                        <Form id="ar-experience-form" className="ca-form" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} lg="6">
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="formName">
                                            <Form.Label>
                                                {translatedMessage("GENERAL.NAME")}
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder={translatedMessage("AR_EXPERIENCE.NAME_PLACEHOLDER")}
                                                defaultValue={this.state.arExperience.name}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="formAuthor">
                                            <Form.Label>
                                                {translatedMessage("GENERAL.ARTIST_NAME")}
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={translatedMessage("GENERAL.ARTIST_NAME_PLACEHOLDER")}
                                                defaultValue={this.state.arExperience.author}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="formCategory">
                                            <Form.Label>
                                                {translatedMessage("GENERAL.CATEGORY")}
                                            </Form.Label>
                                            <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                                                <Dropdown.Toggle disabled={this.state.id ? true : false}>
                                                    {translatedMessage(this.state.arExperience.category.label)}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {this.state.categoryList.map((element, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                                {translatedMessage(element.label)}
                                                            </Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="formIsStremed">
                                            <Form.Label>
                                                {translatedMessage("ARTWORK.EXPERIENCE.IS_STREAMED")}
                                            </Form.Label>
                                            <Dropdown className="ca-dropdown" onSelect={this.handleIsStremedSelect}>
                                                <Dropdown.Toggle>
                                                    {ApiService.getYesNoConverter(this.state.arExperience.streamedExperience)}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item key={0} eventKey={true}>{translatedMessage("GENERAL.YES")}</Dropdown.Item>
                                                    <Dropdown.Item key={1} eventKey={false}>{translatedMessage("GENERAL.NO")}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="formWidth">
                                            <Form.Label>{translatedMessage("GENERAL.WIDTH")} (cm)</Form.Label>
                                            <Form.Control
                                                placeholder={translatedMessage("GENERAL.WIDTH_PLACEHOLDER")}
                                                defaultValue={this.state.arExperience.sizeWidth}
                                                type="number"
                                                step="0.01"
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="formHeight">
                                            <Form.Label>{translatedMessage("GENERAL.HEIGHT")} (cm)</Form.Label>
                                            <Form.Control
                                                placeholder={translatedMessage("GENERAL.HEIGHT_PLACEHOLDER")}
                                                defaultValue={this.state.arExperience.sizeHeight}
                                                type="number"
                                                step="0.01"
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="formStatus">
                                            <Form.Label>
                                                {translatedMessage("GENERAL.STATUS")}
                                            </Form.Label>
                                            <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                                <Dropdown.Toggle>
                                                    {translatedMessage(this.state.arExperience.status.label)}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {this.state.statusList.map((element, index) => {
                                                        return (
                                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                                {translatedMessage(element.label)}
                                                            </Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group as={Col} lg="6">
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>{translatedMessage("GENERAL.DESCRIPTION")}</Form.Label>
                                        <div className="ca-text-editor">
                                            <SunEditor
                                                name="project-editor"
                                                lang={LanguageService.getCurrentLanguage()}
                                                placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                                setContents={this.state.arExperience.description}
                                                setOptions={{
                                                    height: 150,
                                                    maxWidth: "100%",
                                                    buttonList: this.state.editorButtns,
                                                    font: this.state.editorFonts,
                                                    defaultStyle: this.state.editorDefaultStyle
                                                }}
                                                onChange={this.onChangeEditor}
                                            />
                                        </div>
                                    </Form.Group>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </div >
                )
            }
    }

}

export default ArExperienceEditPage;