import React from 'react';

import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { hasPermission } from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import { toast } from "react-toastify";
import ValidationService from '../../../services/validation.service';

class CompanyAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      companyStatusList: [],
      companyTypeList: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canEdit: hasPermission("COMPANY_EDIT"),
      }, this.getData)
    });
  }

  getData = () => {
    var company = [];
    var companyStatusList = []
    var companyTypeList = []
    var isNew = false;
    var promise = [];
    var isError = false;
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    if (this.state.canEdit) {
      if (this.state.companyId !== null) {
        promise.push(ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
          .then(response => {
            company = response.data.company;
          })
          .catch(err => {
            isError = true;
            if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
              errorMessage = err.response.data.message;
            }
          })
        )
      } else {
        isNew = true;
      }

      if (!isError) {
        promise.push(CompanyService.getCompanyStatusList()
          .then(response => {
            companyStatusList = response;
          }))

        promise.push(CompanyService.getCompanyTypeList()
          .then(response => {
            companyTypeList = response;
          }))
      }

      Promise.all(promise).then(() => {
        if (!isError) {
          if (isNew) {
            const statusItem = companyStatusList.filter(item => item.name === 'DRAFT');
            const typeItem = companyTypeList.filter(item => item.name === 'GALLERY');
            company.status = statusItem[0];
            company.type = typeItem[0];
          }
          this.setState({
            companyStatusList: companyStatusList,
            companyTypeList: companyTypeList,
            company: company,
            isLoaded: true
          })
        }
        else {
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        }
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  handleOnStatusSelect = (eventKey) => {
    const selectedItem = this.state.companyStatusList.filter(item => item.name === eventKey);
    this.setState({
      company: {
        ...this.state.company,
        status: selectedItem[0]
      }
    });
  }

  handleOnTypeSelect = (eventKey) => {
    const selectedItem = this.state.companyTypeList.filter(item => item.name === eventKey);
    this.setState({
      company: {
        ...this.state.company,
        type: selectedItem[0]
      }
    });
  }

  validate = (event, websiteUrl) => {
    var response = {
      isError: false,
      message: ""
    };

    if (!websiteUrl) {    
      response.isError = true;
      response.message = "COMPANY.WEBSITE.BAD_FORMAT";

      return response;
    }

    return response;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.persist();

    let website = event.target.elements.formWebsite.value;
    let websiteUrl = { href: null };
    if (website) {

      websiteUrl = ValidationService.checkUrl(website);    
    }

    const validation = this.validate(event, websiteUrl);
    if (!validation.isError) {
      var promise;
      if (this.state.companyId !== null) {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().post(`/company/${this.state.companyId}/save`, {
            name: event.target.elements.formName.value,
            website: websiteUrl.href,
            phone: event.target.elements.formPhone.value,
            email: event.target.elements.formEmail.value,
            status: this.state.company.status.name,
            type: this.state.company.type.name
          })
            .then(() => resolve())
            .catch(err => reject(err))
        });
      }
      else {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().post(`/company/save`, {
            name: event.target.elements.formName.value,
            website: websiteUrl.href,
            phone: event.target.elements.formPhone.value,
            email: event.target.elements.formEmail.value,
            status: this.state.company.status.name,
            type: this.state.company.type.name
          })
            .then((response) => {              
              const id = response.data.company.id; //parts[parts.length - 1];
              this.setState({
                companyId: id
              })

              this.props.history.push(`/company-admin/${id}/edit`)
              resolve()
            })
            .catch(err => reject(err))
        });
      }

      promise.then(() => {
        toast.success(translatedMessage("COMPANY.SAVE_SUCCESS"));
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.SAVE_ERROR"));
        })

    } else {
      toast.error(validation.message);
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      var company = this.state.company;
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <span className="ca-page-title">{translatedMessage("COMPANY.EDIT")}</span>
          </Row>

          <Form className="ca-form" onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group controlId="formName" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("COMPANY.NAME")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={translatedMessage("COMPANY.NAME_PLACEHOLDER")}
                  defaultValue={company.name}
                />
              </Form.Group>

              <Form.Group controlId="formWebsite" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("COMPANY.WEBSITE")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={translatedMessage("COMPANY.WEBSITE_PLACEHOLDER")}
                  defaultValue={company.website}
                />
              </Form.Group>

              <Form.Group controlId="formPhone" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("GENERAL.PHONE")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translatedMessage("GENERAL.PHONE_PLACEHOLDER")}
                  defaultValue={company.phone}
                />
              </Form.Group>

              <Form.Group controlId="formEmail" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("GENERAL.EMAIL")}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={translatedMessage("GENERAL.EMAIL_PLACEHOLDER")}
                  defaultValue={company.email}
                />
              </Form.Group>

              <Form.Group controlId="formType" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("COMPANY.TYPE")}
                </Form.Label>
                <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                  <Dropdown.Toggle>
                    {translatedMessage(company.type.label)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.companyTypeList.map((element, index) => {
                      return (
                        <Dropdown.Item key={index} eventKey={element.name}>
                          {translatedMessage(element.label)}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>

              <Form.Group controlId="formStatus" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("GENERAL.STATUS")}
                </Form.Label>
                <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                  <Dropdown.Toggle>
                    {translatedMessage(company.status.label)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.companyStatusList.map((element, index) => {
                      return (
                        <Dropdown.Item key={index} eventKey={element.name}>
                          {translatedMessage(element.label)}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Form.Row>

            <Form.Row className="align-items-center">
              <Form.Group as={Col} md="12">
                <Button className={"ca-button mr-1"}
                  type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                <Button className="ca-button ca-button-white"
                  onClick={() => this.props.history.push(`/companies`)}>{translatedMessage("BUTTON.BACK")}</Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAdminEdit;