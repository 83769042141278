import React from 'react';

import { Modal, Button, Form, Dropdown, Col, Image } from 'react-bootstrap/'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import LanguageService, { translatedMessage } from '../../../services/language.service';
import parkIcon from '../Resources/homepage/art_park.svg';

import ParkService from '../../../services/park.service';

const newPark = {
    id: null,
    name: null,
    status: 'DRAFT',
    description: null
};

class ParkEditModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            park: this.props?.park?.id ? this.props.park : { ...newPark,  parentPark: { id: this.props?.parentId }},
            statusList: [],
            editorButtns: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
        }
    }

    async componentDidMount() {
        var statusList = [];
        let promise = [];

        promise.push(ParkService.getParkStatusList().then(response => statusList = response))

        Promise.all(promise)
            .then(() => {
                this.setState({
                    statusList: statusList,
                })
            })
            .catch(err => {
                console.log("An error has ocurred: " + err);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.park !== this.props.park) {
            this.setState({
                park: this.props.park,
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.park !== prevState.park) {
            return { park: prevState.park };
        }
        else return null;
    }

    validate = () => {
        var response = {
            isError: false,
            message: ""
        };

        let description = this.state.park.description
            ? this.state.park.description.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '').replace(/<br>/g, '')
            : null;

        if (!description) {
            response.isError = true;
            response.message = translatedMessage("PARK.DESCRIPTION.REQUIRED");
            return response;
        }

        return response;
    }

    saveChanges = (event) => {
        event.preventDefault();
        const validation = this.validate(event);
        if (!validation.isError) {
            let _park = { ...this.state.park };

            _park.name = event.target.elements.name.value;
            _park.entityMembership = null

            ParkService.savePark(_park)
                .then((response) => {
                    toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
                    this.props.onHide(response.park, true);
                })
                .catch(err => {
                    console.log("An error has ocurred: " + err);
                    toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                })
        } else {
            toast.error(validation.message);
        }
    };

    onChangeEditor = (e, key) => {
        this.setState({
            park: {
                ...this.state.park,
                [key]: e,
            }
        })
    }

    handleStatusSelect = (eventKey) => {
        const selectedItem = this.state.statusList.filter(item => item === eventKey);
        this.setState({
            park: {
                ...this.state.park,
                status: selectedItem[0]
            }
        });
    }

    render() {
        var park = this.state.park;

        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                dialogClassName="ca-modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal"
                scrollable>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='d-flex align-items-center'>
                            <Image src={parkIcon} className="mr-1" alt={translatedMessage("PARK.PARK")} style={{ height: "40px" }} />
                            {park?.name}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="name">
                                <Form.Label>{translatedMessage("PARK.NAME")}</Form.Label>
                                <Form.Control name="name" required defaultValue={park.name} disabled={this.props.onlyView} />
                                <Form.Control.Feedback type="invalid">{translatedMessage("PARK.NAME.REQUIRED")}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="status">
                                <Form.Label>
                                    {translatedMessage("GENERAL.STATUS")}
                                </Form.Label>
                                <Dropdown className="ca-dropdown" onSelect={this.handleStatusSelect} required>
                                    <Dropdown.Toggle disabled={this.props.onlyView || !park.id}>
                                        {translatedMessage("ParkStatus." + park.status)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {this.state.statusList.map((element, index) => {
                                            return (
                                                <Dropdown.Item key={index} eventKey={element}>
                                                    {translatedMessage("ParkStatus." + element)}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>

                            <Form.Group as={Col} lg="12" controlId="description">
                                <Form.Label>{translatedMessage("PARK.DESCRIPTION")}</Form.Label>
                                <div className="ca-text-editor">
                                    <SunEditor
                                        name="description-editor"
                                        lang={LanguageService.getCurrentLanguage()}
                                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                        setContents={park.description}
                                        setOptions={{
                                            height: 100,
                                            maxWidth: "100%",
                                            buttonList: this.state.editorButtns,
                                            font: this.state.editorFonts,
                                            defaultStyle: this.state.editorDefaultStyle
                                        }}
                                        onChange={event => this.onChangeEditor(event, "description")}
                                        required
                                        disable={this.props.onlyView}
                                    />
                                </div>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit" disabled={this.props.onlyView}>
                        {translatedMessage("GENERAL.SAVE_CHANGES")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ParkEditModalComponent