import React from 'react';

import ExhibitionViewComponent from '../../../components/shared/Exhibition/ExhibitionViewComponent';

class ExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.id,
      isLoaded: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoaded: true })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <ExhibitionViewComponent
          history={this.props.history}
          exhibitionId={this.state.exhibitionId}
          artwrokUrl={`/exhibitionArtwork/${this.state.exhibitionId}`}
          backUrl={`/exhibition`} />
      )
    }
  }

}

export default ExhibitionPage;