import React from 'react';

import { Row, Col, Image, Button } from 'react-bootstrap/';
import { Link } from "react-router-dom";

import ApiService from '../../services/api.service';
import FileService from '../../services/file.service';
import { translatedMessage } from '../../services/language.service';

import caLogo from '../../components/shared/Resources/defaultGalleryPicture.png';
import backIcon from '../../components/shared/Resources/icons/back.svg';
import editIcon from '../../components/shared/Resources/icons/edit.svg';
import virtualGalleryIcon from '../../components/shared/Resources/icons/virtualGallery.svg';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import Carousel from "../../components/shared/Carousel/MediaCarouselComponent";
import EditFieldModalComponent from '../../components/shared/EditFieldModalComponent/EditFieldModalComponent';

import { toast } from "react-toastify";
import moment from 'moment';
import DownloadService from '../../services/download.service';

class UserVirtualGalleryViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : null,
      virtualGallery: null,
      carouselMedia: [],
      imageUrl: "",
      isLoaded: false,
      isError: false,
      errorMessage: "",
      downloading: false,
      modalData: {
        content: '',
        title: '',
        dataFor: '',
        required: false
      },
      showFieldModal: false,
      printFileCreated: false,
    };
  }

  componentDidMount() { this.getData() }

  async getData() {
    let isError = false;
    let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";

    let virtualGallery = this.state.virtualGallery

    virtualGallery = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/userVirtualGallery/${this.state.id}`)
      .then(response => {
        return response.data.userVirtualGallery;
      })
      .catch(err => {
        isError = true;
        if (err && err.response && err.response.status && 404 === err.response.status) {
          errorMessage = err.response.data.message;
        }
      })

    var carouselMedia = [];
    if (!isError && virtualGallery) {
      if (virtualGallery.fileList) {
        virtualGallery.fileList
          .filter(item => item.usage === FileService.getFileUsage().VIDEO)
          .map(item => carouselMedia.push({ original: null, thumbnail: null, youtubeId: item.youtubeId, type: FileService.getResourceType().VIDEO, file: item }));

        virtualGallery.fileList
          .filter(item => item.usage === FileService.getFileUsage().THUMBNAIL)
          .map(item => carouselMedia.push({ original: `${ApiService.getBaseUrl()}/storage/file/${item.uuid}`, thumbnail: `${ApiService.getBaseUrl()}/storage/file/${item.uuid}`, youtubeId: null, type: FileService.getResourceType().IMAGE, file: item }));
      }

      if (virtualGallery.videoUrlList) {
        virtualGallery.videoUrlList
          .map(item => carouselMedia.push({ original: null, thumbnail: null, videoId: item.videoId, type: item.type, file: null }));
      }

      this.setState({
        virtualGallery: virtualGallery,
        imageUrl: virtualGallery.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${virtualGallery.photoUuid}` : caLogo,
        carouselMedia: carouselMedia,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    }
  }

  onShowEdit = () => {
    this.setState({
      modalData: {
        content: this.state.virtualGallery.name,
        title: translatedMessage("GENERAL.NAME"),
        dataFor: 'name',
        required: true
      },
      showFieldModal: true,
    })
  }

  onHideEdit = (data) => {
    if (data && data.content && data.dataFor) {
      let name = this.state.virtualGallery.name;

      if (data.dataFor === "name") {
        name = data.content;
      }

      const promise = new Promise((resolve, reject) => {
        ApiService.getAuthenticatedInstance().patch(`/soldVirtualGalleries/${this.state.virtualGallery.id}`, {
          name: name,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
        resolve();
      });

      promise.then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          virtualGallery: {
            ...this.state.virtualGallery,
            name: name,
          }
        })
      })
        .catch(err => {
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          console.log("An error has ocurred: " + err);
        });
    }
    this.setState({
      showFieldModal: false
    })
  }

  downloadPrintFile = () => {
    if (!this.state.downloading) {
      this.setState({
        downloading: true
      }, () => {
        DownloadService.httpRequest(
          ApiService.getBaseUrl() + '/storage/saveCollectionVGPrintFile/' + this.state.virtualGallery.id, 'get')
          .then(response => response.arrayBuffer())
          .then((blob) => {
            //Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'print_files.zip');

            //Append to html page
            document.body.appendChild(link);

            //Force download
            link.click();

            //Clean up and remove the link                    
            setTimeout(function () {
              link.parentNode.removeChild(link);
              URL.revokeObjectURL(url);
            }, 3000);

            this.setState({
              downloading: false,
            }, this.getPrintFileDetails);
          })
          .catch((error) => {
            var errorMessage = translatedMessage("GENERAL.GENERIC_ERROR");
            if (error.status === 403) {
              errorMessage = translatedMessage("GENERAL.GENERIC_RIGHTS_ERROR")
            } else if (error.status === 404) {
              errorMessage = translatedMessage("VIRTUAL_GALLERY.ERROR.NOT_FOUND")
            }

            toast.error(errorMessage, { autoClose: 7000 });

            this.setState({
              downloading: false,
            });
          });
      })
    }
  }

  getPrintFileDetails = () => {
    ApiService.getAuthenticatedInstance()
      .get(`/virtualGallery/collection/${this.state.virtualGallery.id}/print-size`)
      .then(results => {
        const printSizes = results.data.files;


        this.setState({
          virtualGallery: {
            ...this.state.virtualGallery,
            printSizeList: printSizes
          }
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        let virtualGallery = this.state.virtualGallery;
        let artworkPricePackage = this.state.virtualGallery.artworkPricePackage;
        let profileLink = '#';
        if (virtualGallery.author) {
          if (virtualGallery.author.userId) {
            profileLink = `/profile/${virtualGallery.author.userId}/view`
          } else if (virtualGallery.author.companyId) {
            profileLink = `/company/${virtualGallery.author.companyId}/space`
          }
        }

        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-1 pr-2">
              <div className="pr-2 d-flex align-items-center">
                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                <div>
                  <div className="ca-page-title">
                    <span className="font-weight-bold">{virtualGallery.name}</span>&nbsp;
                    {virtualGallery.author &&
                      <>
                        {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                        <Link to={profileLink}>
                          {virtualGallery.author.name}
                        </Link>
                      </>
                    }
                    {!virtualGallery.author &&
                      <>
                        {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;Connected.ART
                      </>
                    }
                  </div>
                  <div className="ca-art-type">{translatedMessage(virtualGallery.type.label)}</div>
                </div>
              </div>
              <div>
                {!artworkPricePackage &&
                  <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={this.onShowEdit} title={translatedMessage("VIRTUAL_GALLERY.CHANGE_NAME")}>
                    <Image src={editIcon} alt={translatedMessage("VIRTUAL_GALLERY.CHANGE_NAME")} />
                  </Button>
                }

                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push("/virtual-galleries")}
                  title={translatedMessage("BUTTON.BACK")}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            <Row className="mb-4">
              {artworkPricePackage &&
                <div className="ca-art-package">
                  {translatedMessage("ARTWORK.PACKAGE")}:&nbsp;
                  <span className="font-weight-bold">
                    {artworkPricePackage.isFreePackage
                      ? artworkPricePackage.name
                      : artworkPricePackage.name + virtualGallery.issueNumber + " / " + artworkPricePackage.nrCopies}
                  </span>
                </div>
              }
            </Row>

            <Row>
              <Col className="col-12 col-md-6 ca-row-left-column">
                {this.state.carouselMedia && this.state.carouselMedia.length > 0 &&
                  <Carousel
                    id="media"
                    items={this.state.carouselMedia}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class={"ca-carousel-big mx-auto ".concat(this.state.downloading ? "disabled" : "")}
                    hasToolbar={true}
                    toolbarDelete={false}
                    toolbarYoutubeId={false}
                    toolbarFullScreen={true}
                    videoUrlOnly={true}
                  />
                }
                {this.state.virtualGallery.hasPrintFile &&
                  <div className="text-center w-100 mt-2">
                    <span className="ca-dark-link font-bold" onClick={this.downloadPrintFile} style={{ cursor: this.state.downloading ? "auto" : "pointer", width: "auto" }}>
                      <span style={{ fontFamily: "FontAwesome", fontSize: "15px" }} className="mr-1">&#xf019;</span>
                      {translatedMessage("ARTWORK.DOWNLOAD_PRINT_FILE")}
                    </span>
                  </div>
                }
                {this.state.downloading &&
                  <div className="w-100 text-center" style={{ position: "absolute", top: "6.5rem" }}>
                    <div className="fa-5x">
                      <i className="fa fa-spinner fa-spin" ></i>
                    </div>
                    <div >{this.state.printFileCreated ? translatedMessage("GENERAL.DOWNLOADING") : translatedMessage("ARTWORK.PRINT_FILE.CREATE_DOWNLOAD")}</div>
                  </div>
                }
              </Col>

              <Col className="col-12 col-md-6 ca-row-right-column mb-3">
                <div className="ca-paragraph-title">
                  {translatedMessage("VIRTUAL_GALLERY.ABOUT")}
                </div>

                {virtualGallery.description &&
                  <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: virtualGallery.description }} />
                }

                <div className="ca-display-group">
                  <div className="ca-label">
                    {translatedMessage("VIRTUAL_GALLERY.DISPLAYED_ARTWORKS")}
                  </div>
                  <div className="ca-view-value">
                    <ul>
                      <li>
                        {translatedMessage("AR_PORTAL.AR_ART_NO") + ":  " + virtualGallery.nrArArt}
                      </li>
                      <li>
                        {translatedMessage("AR_PORTAL.SCULPTURE_NO") + ":  " + virtualGallery.nrSculpture}
                      </li>
                    </ul>
                  </div>
                </div>

                {this.state.virtualGallery.printSizeList && this.state.virtualGallery.printSizeList.length > 0 &&
                  <div className="ca-display-group">
                    <div className="ca-label">
                      {translatedMessage("ARTWORK.PRINT.SIZE")}
                    </div>
                    {this.state.virtualGallery.printSizeList.map((element, index) => {
                      return (
                        <div key={index} className="ca-view-value">{element}</div>
                      )
                    })}
                  </div>
                }

                {virtualGallery.aboutAuthor &&
                  <>
                    <div className="ca-paragraph-title">
                      {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                    </div>
                    <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: virtualGallery.aboutAuthor }} />
                  </>
                }
              </Col>
            </Row>

            <EditFieldModalComponent
              show={this.state.showFieldModal}
              onHide={this.onHideEdit}
              data={this.state.modalData}
            />
          </div>
        )
      }
  }

}

export default UserVirtualGalleryViewPage;