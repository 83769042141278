import React from 'react';
import Dropzone from 'react-dropzone';
import { Card } from 'react-bootstrap/';
import { toast } from "react-toastify";

import { translatedMessage } from '../../../services/language.service';
import FileService from "../../../services/file.service";
import ArtworkService from "../../../services/artwork.service";

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.entity,
      dropzoneEnabled: true,
      fileId: 0,
      uuid: 0,
      uplodedFile: null
    };
  }

  imageContentTypes = FileService.getImageContentType();
  imageContentTypesShort = FileService.getImageContentTypeShort();
  zipContentTypes = FileService.getZipContentType();
  videoContentTypes = FileService.getVideoContentTypes();
  modelContentTypes = FileService.getModelContentType();
  objectContentTypes = FileService.getObjectContentType();

  //////////////////////////
  // Upload a single file without linking it to an entity
  singleFileUpload = (file, event, fileType, shortListImageTypes, fileUsage) => {
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // When the upload allows only images from the short list of image content types
    if (fileType === FileService.getResourceType().IMAGE && shortListImageTypes) { contentTypes = this.imageContentTypesShort; }

    // If the file uploaded is a video
    if (fileType === FileService.getResourceType().VIDEO) {
      contentTypes = this.videoContentTypes;
      successMessage = translatedMessage("DROPZONE.VIDEO_UPLOADED_SUCCESFULLY");
    }

    if (fileType === FileService.getResourceType().ARCHIVE) {
      contentTypes = this.zipContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");
    }

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The file that will be replaced (if this is the case)
    const deletedFile = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedFile);

    // Upload the file
    FileService.upload(file, contentTypes)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(result => {
        if (fileUsage) {
          return FileService.setFileUsage(
            result.body.id,
            fileUsage,
            result.body.uuid
          )
        }
      })
      .then(() => {
        // If the previous file must be deleted
        var deletePrevious;
        if (this.props.deletePrevious && deletedFile && deletedFile.uuid) {
          deletePrevious = FileService.deletePreviousFile(deletedFile, this.state.uuid);
        }
        return deletePrevious;
      })
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedFile);
        console.log("An error has ocurred: " + err);
      });
  }

  //////////////////////////
  // Upload a single file and link it to an entity
  singleEntityFileUpload = (file, event, fileType, shortListImageTypes) => {
    // console.log("singleEntityFileUpload PROPS: ", this.props)
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // When the upload allows only images from the short list of image content types
    if (fileType === FileService.getResourceType().IMAGE && shortListImageTypes) { contentTypes = this.imageContentTypesShort; }

    // If the file uploaded is a video
    if (fileType === FileService.getResourceType().VIDEO) {
      contentTypes = this.videoContentTypes;
      successMessage = translatedMessage("DROPZONE.VIDEO_UPLOADED_SUCCESFULLY");
    }

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedFile = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedFile);

    // Upload the file
    FileService.upload(file, contentTypes)
      .then(result => {
        // console.log("FileService.upload: ", result)
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(result => {
        // Link the uploaded file to the entity
        return FileService.linkPhotoToEntity(
          entity.id,
          this.props.entityUpdateAPILink,
          result.body.id,
          this.props.fileUsage,
          result.body.uuid,
          this.props.fileFieldName ? this.props.fileFieldName : "photo"
        )
      })
      .then(() => {
        // If the previous file must be deleted
        var deletePrevious;
        if (this.props.deletePrevious && deletedFile && deletedFile.uuid) {
          deletePrevious = FileService.deletePreviousFile(deletedFile, this.state.uuid);
        }
        return deletePrevious;
      })
      .then(() => {
        // SUCCESS
        // console.log("SUCCESS: ", this.state.uplodedFile)
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedFile);
        console.log("An error has ocurred: " + err);
      });
  }

  //////////////////////////
  // Upload a single file and storage item file
  singleStorageItemFileUpload = (file, event, fileType) => {
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // If the file uploaded is a video
    if (fileType === FileService.getResourceType().VIDEO) {
      contentTypes = this.videoContentTypes;
      successMessage = translatedMessage("DROPZONE.VIDEO_UPLOADED_SUCCESFULLY");
    }

    // If the file uploaded is an AR file
    if (fileType === FileService.getResourceType().AR_VIDEO) {
      contentTypes = this.zipContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");
    }

    // If the file uploaded is an OBJECT
    if (fileType === FileService.getResourceType().UNITY_SCENE) {
      contentTypes = this.zipContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");
    }

    // If the file uploaded is an object - sculpture / ar portal
    if (fileType === FileService.getResourceType().OBJECT) {
      contentTypes = this.objectContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");
    }        

    // If the file uploaded is an archive - sculpture / ar portal
    if (fileType === FileService.getResourceType().ARCHIVE) {
      contentTypes = this.zipContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");
    }

    // If the file uploaded is an OBJECT model
    // if (fileType === FileService.getResourceType().OBJECT_MODEL) {
    //   contentTypes = this.modelContentTypes;
    //   successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");
    // }

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedImage = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedImage);

    // Upload the file
    FileService.upload(file, contentTypes)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(result => {
        // Link the uploaded file to the entity
        return FileService.linkToStorageItem(
          entity.storageItem.id,
          result.body.id,
          this.props.fileUsage,
          result.body.uuid,
          this.props.resourceType ? this.props.resourceType : null,
          this.props.mobilePlatform ? this.props.mobilePlatform : null,
          this.props.widthCm,
          this.props.heightCm
        )
      })
      .then(response => {
        this.setState({
          uplodedFile: response ? response : this.state.uplodedFile
        })
      })
      .then(() => {
        // If the previous file must be deleted
        var deletePrevious;
        if (this.props.deletePrevious && deletedImage && deletedImage.uuid) {
          deletePrevious = FileService.deletePreviousFile(deletedImage, this.state.uuid);
        }
        return deletePrevious;
      })
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedImage);
        console.log("An error has ocurred: " + err);
      });
  }

  //////////////////////////
  // Upload ARTWORK file
  artworkFileUpload = (file, event) => {
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedImage = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedImage);

    // Upload the file
    FileService.uploadArtworkFile(file, contentTypes, entity.storageItem.id)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedImage);
        console.log("An error has ocurred: " + err);
      });
  }

  //////////////////////////
  // Upload AR Expereince - AR art file
  arArtFileUpload = (file, event) => {
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedImage = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedImage);

    // Upload the file
    FileService.uploadArArtFile(file, contentTypes, entity.storageItem.id)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedImage);
        console.log("An error has ocurred: " + err);
      });
  }

  //////////////////////////
  // Upload UNITY_SCENE/GLB file - sculpture
  objectFileUpload = (file, event, fileType) => {    
    // If the file uploaded is an UNITY_SCENE
    var contentTypes = this.zipContentTypes;
    var successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY");

    // If the file uploaded is an OBJECT
    if (fileType === FileService.getResourceType().OBJECT) {
      contentTypes = this.objectContentTypes;
    }        

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedImage = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedImage);

    // Upload the file
    FileService.uploadObjectArtFile(file, contentTypes, this.props.fileUsage, entity.storageItem.id, this.props.fileType, this.props.mobilePlatform)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })      
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedImage);
        console.log("An error has ocurred: " + err);
      });
  }  

  //////////////////////////
  // Upload ARTWORK print file
  artworkPrintFileUpload = (file, event) => {
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedImage = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedImage);

    // Upload the file
    FileService.uploadArtworkPrintFile(file, contentTypes, entity.storageItem.id, this.props.widthCm, this.props.heightCm)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedImage);
        console.log("An error has ocurred: " + err);
      });
  }

  //////////////////////////
  // Upload multiple files and storage item file
  multipleStorageItemFileUpload = (files, event, fileType) => {
    let isError = false;
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");
    var isAR = false;

    // If the file uploaded is an AR file (zip) 
    if (fileType === FileService.getResourceType().AR_VIDEO) {
      contentTypes = this.zipContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY")
      isAR = true;
    }

    // If the file uploaded a VIDEO 
    if (fileType === FileService.getResourceType().VIDEO) {
      contentTypes = this.videoContentTypes;
      successMessage = translatedMessage("DROPZONE.FILES_UPLOADED_SUCCESFULLY")
      isAR = false;
    }

    // Check the number of files uploaded and the size and type of each
    files.forEach(file => {
      var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
      if (!fileCheck[0]) {
        toast.error(fileCheck[1])
        isError = true;
      }
    });
    if (isError) {
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false);

    var fileName = [];

    files.map((file, index) => {
      // Upload the file
      FileService.upload([file], contentTypes)
        .then(result => {
          this.setState({
            [event.target.id]: result.body,
            fileId: result.body.id,
            uuid: result.body.uuid,
            dropzoneEnabled: false
          })
          return result;
        })
        .then(result => {
          // Link the uploaded file to the entity
          fileName[index] = result.body.name;
          return FileService.linkToStorageItem(
            entity.storageItem.id,
            result.body.id,
            this.props.fileUsage,
            result.body.uuid,
            this.props.resourceType,
            this.props.mobilePlatform ? this.props.mobilePlatform : null,
            this.props.widthCm ? this.props.widthCm : null,
            this.props.heightCm ? this.props.heightCm : null
          )
        })
        .then(result => {
          // if is AR file the Extended experience (add-on) must be created
          if (isAR) {
            let experiencesCount = index;
            let experiencesNrCrt = index;

            if (this.props.fileTypeCount || this.props.fileTypeCount === 0) {
              if(index ===0) {
                experiencesCount = this.props.fileTypeCount;
              }

              experiencesNrCrt += this.props.fileTypeCount;
            }

            return ArtworkService.addAddOnAtFileUpload(result.id, experiencesNrCrt, this.props.entity.id, result.uuid, experiencesCount)
          } else {
            return;
          }
        })
        .then(() => {
          // SUCCESS
          toast.success(successMessage + " (" + fileName[index] + ")");
          this.setState({ dropzoneEnabled: true });
          this.props.onupload(true);
        })
        .catch((err) => {
          // ERROR and roll back
          toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
          this.setState({ dropzoneEnabled: true });
          this.props.onupload(true);
          console.log("An error has ocurred: " + err);
        })

        return true;
    })
  };

  //////////////////////////
  // Upload a single trigger file and link it to an entity
  singleEntityTriggerUpload = (file, event, fileType, shortListImageTypes) => {
    var contentTypes = this.imageContentTypes;
    var successMessage = translatedMessage("DROPZONE.IMAGES_UPLOADED_SUCCESFULLY");

    // When the upload allows only images from the short list of image content types
    if (fileType === FileService.getResourceType().IMAGE && shortListImageTypes) { contentTypes = this.imageContentTypesShort; }

    // Check the number of files uploaded, size and type
    var fileCheck = FileService.checkFile(file, this.props.multipleFiles, contentTypes, this.props.maxSize);
    if (!fileCheck[0]) {
      toast.error(fileCheck[1])
      return;
    }

    // The entity to which the file will be linked
    const entity = this.props.entity;
    // The file that will be replaced (if this is the case)
    const deletedFile = this.props.deletedImage;

    // Disable the dropzone while the upload is in progress
    this.setState({ dropzoneEnabled: false });
    this.props.onupload(false, deletedFile);

    // Upload the file
    FileService.upload(file, contentTypes)
      .then(result => {
        this.setState({
          [event.target.id]: result.body,
          uuid: result.body.uuid,
          uplodedFile: result.body
        })
        return result
      })
      .then(result => {
        // Link the uploaded trigger to the entity
        return FileService.linkTriggerToEntity(
          entity.id,
          this.props.entityUpdateAPILink,
          result.body.id,
          this.props.fileUsage,
          result.body.uuid
        )
      })
      .then(() => {
        // If the previous file must be deleted
        var deletePrevious;
        if (this.props.deletePrevious && deletedFile && deletedFile.uuid) {
          deletePrevious = FileService.deletePreviousFile(deletedFile, this.state.uuid);
        }
        return deletePrevious;
      })
      .then(() => {
        // SUCCESS
        toast.success(successMessage);
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, this.state.uplodedFile);
      })
      .catch((err) => {
        // ERROR and roll back
        toast.error(translatedMessage("DROPZONE.ERROR_UPLOAD"))
        this.setState({ dropzoneEnabled: true });
        this.props.onupload(true, deletedFile);
        console.log("An error has ocurred: " + err);
      });
  }

  render() {
    return (
      <Dropzone
        onDrop={(acceptedFile, rejectedFile, event) => {
          if (this.props.fileUsage === FileService.getFileUsage().ARTWORK && this.props.fileType === FileService.getResourceType().IMAGE) {
            // AR art upload
            this.artworkFileUpload(acceptedFile, event)
          } else if (this.props.fileUsage === FileService.getFileUsage().AR_EXPERIENCE_ART && this.props.fileType === FileService.getResourceType().IMAGE) {
            // AR art experience upload
            this.arArtFileUpload(acceptedFile, event)
          } else if ((this.props.fileUsage === FileService.getFileUsage().ARTWORK || this.props.fileUsage === FileService.getFileUsage().AR || this.props.fileUsage === FileService.getFileUsage().AR_PORTAL) && (this.props.fileType === FileService.getResourceType().UNITY_SCENE || this.props.fileType === FileService.getResourceType().OBJECT)) {
            // Digital sculpture upload
            this.objectFileUpload(acceptedFile, event, this.props.fileType)
          } else if (this.props.fileUsage === FileService.getFileUsage().PRINT_FILE && this.props.fileType === FileService.getResourceType().IMAGE) {
            // Print file upload
            this.artworkPrintFileUpload(acceptedFile, event)
          } else if (!this.props.multipleFiles && this.props.entity !== null && this.props.storageitemlinked) {
            // Single file upload and link to the storage item
            this.singleStorageItemFileUpload(acceptedFile, event, this.props.fileType)
          } else if (this.props.multipleFiles && this.props.entity !== null && this.props.storageitemlinked) {
            // Multiple file upload and link to the storage item
            this.multipleStorageItemFileUpload(acceptedFile, event, this.props.fileType)
          } else if (!this.props.multipleFiles && this.props.entity !== null && !this.props.storageitemlinked && this.props.fileUsage !== FileService.getFileUsage().TRIGGER) {
            // Single entity file upload
            this.singleEntityFileUpload(acceptedFile, event, this.props.fileType, true)
          } else if (!this.props.multipleFiles && this.props.entity !== null && !this.props.storageitemlinked && this.props.fileUsage === FileService.getFileUsage().TRIGGER) {
            // Single entity trigger upload
            this.singleEntityTriggerUpload(acceptedFile, event, this.props.fileType, true)
          } else if (this.props.entity === null) {
            // Single file upload -> file not related to any antity at this point
            this.singleFileUpload(acceptedFile, event, this.props.fileType, true, this.props.fileUsage)
          } else {
            console.log("No file type was specified when calling the DropzoneComponent!");
          }
        }
        }
        multiple={this.props.multipleFiles}>
        {({ getRootProps, getInputProps }) => (
          <section className=" h-100">
            <div {...getRootProps()} className={this.state.dropzoneEnabled && !this.props.disabled ? "h-100 ca-dropzone-enabled" : "h-100 ca-dropzone-disabled"}>
              <input id={this.props.componentId} {...getInputProps()} />
              <div id={this.props.componentId} style={{ zIndex: "100" }} className="h-100">
                {!this.state.dropzoneEnabled &&
                  <div className="fa-5x w-100 text-center" style={{ position: "absolute", top: "6.5rem" }}>
                    <i className="fa fa-spinner fa-spin" ></i>
                  </div>
                }
                <Card style={{ zIndex: "101", height: this.props.cardheight }} className="stepper-dropzone">
                  <Card.Body className="stepper-dropzone-body">
                    <Card.Title>{translatedMessage("DROPZONE.DROP_FILE")}</Card.Title>

                    {this.props.deletePrevious &&
                      <Card.Title>{translatedMessage(this.props.deletePreviousDesc)}</Card.Title>
                    }

                    {this.props.multipleFiles &&
                      <Card.Text>
                        {translatedMessage(this.props.multipleFilesDesc)}
                      </Card.Text>
                    }

                    {this.props.recommendedShape &&
                      <Card.Text className="mb-2">
                        {translatedMessage(this.props.recommendedShape)}
                      </Card.Text>
                    }

                    <Card.Text>
                      {translatedMessage(this.props.fileFormatsDesc)}
                    </Card.Text>

                    <Card.Text>
                      {translatedMessage(this.props.maxSizeDesc) + this.props.maxSize + "Mb."}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }
}

export default DropzoneComponent;