import React from 'react';

import { translatedMessage } from '../../../services/language.service';

import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SocialMediaAccountEditModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  checkUrl = (url) => {
    let formatedUrl = url;
    if (url.startsWith("http://") || url.startsWith("https://")) {
      formatedUrl = url;
    } else {
      formatedUrl = "https://" + url;
    }

    try { return new URL(formatedUrl) }
    catch (e) { return false }
  }

  saveChanges = (event) => {
    event.preventDefault();
    let data = this.props.data;
    let url = event.target.elements.url.value;
    
    let checkUrl = { href: null };
    if (url) {
      checkUrl = this.checkUrl(url);
    }    

    if (checkUrl) {
      data.url = checkUrl.href;
      data.isSaved = true;
      
      this.props.onHide(data)
    }            
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            <FontAwesomeIcon icon={["fab", this.props.data.icon]} />
            <span className='ml-1'>{this.props.data.title}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Control
              name="url"
              placeholder={`${translatedMessage("GENERAL.ENTER_THE")} ${this.props.data.title.toLowerCase()} ${translatedMessage("WEB_RESOURCE.LINK").toLowerCase()}`}              
              defaultValue={this.props.data.url}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default SocialMediaAccountEditModalComponent;