import React from 'react';
import './EditModalComponent.css';

import { Modal, Button, Form, FormGroup } from 'react-bootstrap/'

import ApiService from '../../../../services/api.service'
import UserService from '../../../../services/user.service'
import { translatedMessage } from '../../../../services/language.service'

import { toast } from "react-toastify";

class EditModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userInfo: this.props.userInfo,
            membershipList: [],
            roleList: []
        }

    }

    componentDidMount() { this.getData() }

    async getData() {
        var membershipList = await UserService.getMembershipListForUserCreate()
            .then(result => { return result })

        var membership = this.props.userInfo.membership;
        if (!membership) {
            membership = membershipList.filter(item => item.name === "Member")[0].id;
        }

        var roleList = await UserService.getRoleList()
            .then(result => { return result })

        this.setState({
            membershipList: membershipList,
            roleList: roleList,
            userInfo: {
                ...this.state.userInfo,
                membership: membership,
            }
        });

    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [name]: value
            }
        });
    }

    roleChange = event => {
        var roles = [].slice.call(event.target.selectedOptions).map(item => parseInt(item.value));

        this.setState({
            userInfo: {
                ...this.state.userInfo,
                roles: roles
            }
        });
    }

    saveChanges = (event) => {
        event.preventDefault();
        const userData = event.target.elements;

        var roles = this.state.userInfo.roles;
        if (roles.length === 1 && roles[0] === 0) {
            roles = null
        }

        ApiService.getAuthenticatedInstance().post('/user/edit', {
            id: this.props.userInfo.id,
            username: userData.formUsername.value,
            firstName: userData.formFirstName.value,
            lastName: userData.formLastName.value,
            membership: parseInt(this.state.userInfo.membership),
            roles: roles
        })
            .then(() => {
                toast.success(translatedMessage("USER.SAVE.SUCCESS"));
                this.setState({
                    userInfo: {
                        ...this.state.userInfo,
                        membership: 0,
                        roles: []
                    }
                }, this.props.onHide());
            });
    };

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {this.props.userInfo.fullName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Group controlId="formUsername">
                            <Form.Label className="editmodal-userinfo-title">{translatedMessage("USER.USERNAME")}</Form.Label>
                            <Form.Control
                                name="username"
                                placeholder={translatedMessage("USER.USERNAME_PLACEHOLDER")}
                                defaultValue={this.props.userInfo.username}
                                disabled />
                        </Form.Group>
                        <Form.Group controlId="formFirstName">
                            <Form.Label className="editmodal-userinfo-title">{translatedMessage("USER.FIRST_NAME")}</Form.Label>
                            <Form.Control
                                name="firstName"
                                placeholder={translatedMessage("USER.FIRST_NAME_PLACEHOLDER")}
                                defaultValue={this.props.userInfo.firstName}
                                required />
                        </Form.Group>
                        <Form.Group controlId="formLastName">
                            <Form.Label className="editmodal-userinfo-title">{translatedMessage("USER.LAST_NAME")}</Form.Label>
                            <Form.Control
                                name="lastName"
                                placeholder={translatedMessage("USER.LAST_NAME_PLACEHOLDER")}
                                defaultValue={this.props.userInfo.lastName}
                                required />
                        </Form.Group>

                        <FormGroup controlId="formMembership">
                            <Form.Label className="editmodal-userinfo-title">{translatedMessage("MEMBERSHIP.MEMBERSHIP")}</Form.Label>
                            <Form.Control
                                name="membership"
                                as="select"
                                required
                                defaultValue={this.props.userInfo.membership}
                                onChange={this.userInfoChange}
                            >
                                {this.state.membershipList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{translatedMessage("MEMBERSHIP." + item.name.toUpperCase())}</option>
                                    )
                                })}
                            </Form.Control>
                        </FormGroup>

                        <Form.Group controlId="formRoles">
                            <Form.Label className="editmodal-userinfo-title">{translatedMessage("USER.ROLES")}</Form.Label>
                            <Form.Control
                                name="roles"
                                as="select"
                                defaultValue={this.props.userInfo.roles}
                                multiple
                                onChange={this.roleChange}
                            >
                                <option value="0">-- {translatedMessage("GENERAL.NONE")} --</option>
                                {this.state.roleList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default EditModalComponent