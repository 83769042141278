import React from 'react';

import CardComponent from "./ExhibitionArtworkCardComponent";
import Carousel from "../Carousel/MediaCarouselComponent";

import back from '../Resources/left-arrow.svg';
import map from '../Resources/homepage/map.svg';
import exhibition from '../Resources/homepage/exhibition.svg';
import extendedExperience from '../Resources/homepage/extendedExperience.svg';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import moment from 'moment';

import FileService from "../../../services/file.service";
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import ArtworkService from '../../../services/artwork.service';

class ExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.exhibitionId,
      exhibition: null,
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      hasLocation: false,
      user: {},
      profile: {},
      isLoaded: false,
      addressDialogVisible: false,
      carouselMedia: null,
      freeArtworks: null,
      errorMessage: '',
      isError: false
    };
  }

  componentDidMount = () => {
    if (this.props.canView) {
      this.loadData();
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  loadData = () => {
    var exhibition = null;
    let hasLocation = false;

    ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${this.state.exhibitionId}`)
      .then(response => {
        exhibition = response.data
      })
      .then(() => {
        if (exhibition.locationList[0]) {
          hasLocation = true;
        }
        var carouselMedia = [];

        if (this.props.useVideoUrl && exhibition.videoUrlList) {
          exhibition.videoUrlList
            .map(item => carouselMedia.push({ original: null, thumbnail: null, videoId: item.videoId, type: item.type, file: null }));
        }

        if (exhibition.fileList) {
          exhibition.fileList
            .filter(item => item.usage === FileService.getFileUsage().VIDEO)
            .map(item => carouselMedia.push({ original: null, thumbnail: null, youtubeId: item.youtubeId, type: FileService.getResourceType().VIDEO, file: item }));

          exhibition.fileList
            .filter(item => item.usage === FileService.getFileUsage().THUMBNAIL)
            .map(item => carouselMedia.push({ original: `${ApiService.getBaseUrl()}/storage/file/${item.uuid}`, thumbnail: `${ApiService.getBaseUrl()}/storage/file/${item.uuid}`, youtubeId: null, type: FileService.getResourceType().IMAGE, file: item }));
        }

        this.setState({
          exhibition: exhibition,
          location: exhibition.locationList[0] ? exhibition.locationList[0] : this.state.location,
          freeArtworks: exhibition.freeArtworkList,
          carouselMedia: carouselMedia,
          hasLocation: hasLocation,
          isLoaded: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 403 || err.response.status === 404)) {
          errorMessage = err.response.data.message
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-5">
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={exhibition} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
              <span className="ca-page-title">{this.state.exhibition.name}</span>
            </div>

            <div className="ca-page-title-button-row-buttons">
              <Link to={this.props.artworksUrl} className="ca-dark-link">
                <Image className="ca-page-title-icon" src={extendedExperience} alt={translatedMessage("ARTWORK.ARTWORKS")}
                  title={translatedMessage("ARTWORK.ARTWORKS")} />
              </Link>

              {this.state.hasLocation &&
                <Link to={`/exhibition/${this.state.exhibitionId}/map`} className="ca-dark-link">
                  <Image className="ca-page-title-icon" src={map} alt={translatedMessage("MAP.MAP")}
                    title={translatedMessage("MAP.MAP")} />
                </Link>
              }

              <Link to={this.props.backUrl} className="ca-dark-link">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>

          <Row>
            <Col className="col-12 col-lg-6">
              {this.state.location.name &&
                <div className="ca-form-view-field">
                  <div>
                    <span className="ca-label">{translatedMessage("MAP.LOCATION")} </span>
                    <span> - {this.state.location.name}. </span>
                    {this.state.location?.geofence &&
                      <span className="ca-infotext-small"><br />{translatedMessage("EXHIBITION.GEOFENCE.INFO") + " " +
                        translatedMessage("EXHIBITION.GEOFENCE.INFO_DETAILS")} </span>
                    }
                  </div>
                </div>
              }

              {this.state.exhibition.startTime &&
                <div className="ca-form-view-field">
                  <div>
                    <span className="ca-label">{translatedMessage("EXHIBITION.AVAILABLE_INTERVAL")} </span>
                    <span>{translatedMessage("GENERAL.INTERVAL_START").toLowerCase()} </span>
                    <i>{moment(this.state.exhibition.startTime).format("MMMM D, YYYY")}</i>
                    {this.state.exhibition.endTime &&
                      <>
                        <span> {translatedMessage("GENERAL.INTERVAL_END").toLowerCase()} </span>
                        <i>{moment(this.state.exhibition.endTime).format("MMMM D, YYYY")}</i>
                      </>
                    }
                  </div>
                </div>
              }

              <div className="mb-3">
                {this.state.freeArtworks.length > 0 &&
                  <div className="ca-form-view-field mt-5">
                    <div>
                      <span className="ca-label">{translatedMessage("EXHIBITION.FREE_ARTWORK")} </span>
                      <span className="ca-infotext-small"><br />{translatedMessage("EXHIBITION.FREE_ARTWORK_INFO")}</span>
                      <div className="col-12 col-sm-6 pl-0 pr-0">
                        {this.state.freeArtworks.map((element, index) => {
                          return (
                            <CardComponent
                              key={index}
                              entity={null}
                              cardImage={ApiService.getThumbnailByUrl(element.id)}
                              cardTitle={element.name}
                              cardAuthor={ArtworkService.getAuthorNameFromMap(element.authorList)}
                              cardCategory={element.category.code}
                              cardId={element.id}
                              cardLink={"#"}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Col>
            <Col className="col-12 col-lg-6">
              <Row className="mb-0">
                {this.state.carouselMedia && this.state.carouselMedia.length > 0 &&
                  <Col className="col-12 pl-0 pr-0">
                    <Carousel
                      id="media"
                      items={this.state.carouselMedia}
                      controls={true}
                      indicators={false}
                      interval={null}
                      class="mb-4"
                      hasToolbar={true}
                      toolbarDelete={false}
                      toolbarYoutubeId={false}
                      toolbarFullScreen={true}
                      videoUrlOnly={this.props.useVideoUrl}
                    />
                  </Col>
                }

                {this.state.exhibition.description &&
                  <Col className="col-12 pl-0 pr-0">
                    <div className="ca-form-view-field mb-0">
                      <div className="ca-label">{translatedMessage("GENERAL.DESCRIPTION")}</div>
                      <div className="ca-text-justify mb-0" dangerouslySetInnerHTML={{ __html: this.state.exhibition.description }} />
                    </div>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionPage;