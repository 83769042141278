import React from 'react';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';

import EntityLocation from '../../../components/shared/EntityLocationComponent/EntityLocationComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

class ExhibitionArtworkManageLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionArtworkId: this.props.match.params.id,
      exhibitionArtwork: [],
      exhibition: [],
      artwork: [],
      location: {
        id: 0,
        locationLat: 0,
        locationLng: 0,
        radius: 0,
        name: ''
      },
      isLoaded: false,
      hasGeofence: false,
      pinIcon: null,
      exhibitionIcon: null,
      mapLocations: [],
      initialCenter: null,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => { this.getData(); }

  getData = () => {
    let promise = [];

    ApiService.getAuthenticatedInstance().get(`gallery/exhibitionArtwork/${this.state.exhibitionArtworkId}`)
      .then(response => {
        this.setState({
          exhibitionArtwork: response.data,
          exhibition: response.data.exhibition,
          artwork: response.data.artwork
        }, () => {
          var initialCenter = {};
          promise.push(ApiService.getAuthenticatedInstance().get(`gallery/exhibitionLocation/${this.state.exhibition.id}`)
            .then(response => {
              if (response.data[0]) {
                initialCenter = {
                  lat: response.data[0].locationLat,
                  lng: response.data[0].locationLng,
                }
              } else {
                initialCenter = null
              }
            })
          )

          let artLocation = [];
          let mapLocations = [];
          promise.push(
            ApiService.getAuthenticatedInstance().get(`map/exhibitionMapLocations/${this.state.exhibition.id}`)
              .then(result => {
                mapLocations = result.data.filter(item => item.entity.entityId !== this.state.artwork.id)
                artLocation = result.data.filter(item => item.entity.entityId === this.state.artwork.id)
              })
          )

          var exhibitionPin = []
          promise.push(MapService.getLocationPinByType('EXHIBITION')
            .then(response => {
              exhibitionPin = response;
            }))

          var pins = [];
          promise.push(MapService.getLocationPinByType(this.state.artwork.category.code)
            .then(response => {
              pins = response;
            }))

          Promise.all(promise).then(() => {
            this.setState({
              location: artLocation[0] ? artLocation[0] : this.state.location,
              hasGeofence: artLocation[0] && artLocation[0].geofence ? true : false,
              pinIcon: pins[0] ? pins[0].imageUrl : null,
              exhibitionIcon: exhibitionPin[0] ? exhibitionPin[0].imageUrl : null,
              initialCenter: initialCenter,
              mapLocations: mapLocations,
              isLoaded: true
            })
          })
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <EntityLocation
            entityId={this.state.exhibitionArtworkId}
            entityName={ApiService.getEntityName().EXHIBITION_ARTWORK}
            mapLocations={this.state.mapLocations}
            location={this.state.location}
            hasGeofence={this.state.hasGeofence}
            pinIcon={this.state.pinIcon}
            parentPinIcon={this.state.exhibitionIcon}
            initialCenter={this.state.initialCenter}
            entityTitle={this.state.artwork.name}
            parentEntityTitle={this.state.exhibition.name}
            history={this.props.history}
            zoomLevel={17}
          />
        </>
      );
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default ExhibitionArtworkManageLocation;