import React from 'react';

import { Row } from 'react-bootstrap'

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';
import { Link } from 'react-router-dom';

class PaymentErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionId: 0,
      errorSource: "-",
      errorCode: 0,
      errorMessage: "",
      isCanceled: false,
      isLoaded: false
    }
  }

  componentDidMount() {
    const promise = new Promise((resolve, reject) => {
      if (!ApiService.getCurrentUser()) {
        ApiService.getAuthenticatedInstance().get('/user/me')
          .then(result => {
            ApiService.setCurrentUser(result.data);
            resolve();
          })
      } else {
        resolve();
      }
    })
    promise.then(() => {
      this.populateData();
    })
  }

  populateData() {

    const params = new URLSearchParams(window.location.search);
    const transId = params.get('transactionId');
    const source = params.get('source');
    const code = params.get('code');
    const status = params.get('status');

    let message = "CART.PAID_ERROR";
    let isCanceled = false;

    if(source === "Netaxept" && code === "98"){
      message ="CART.PAID_ERROR_TRANSACTION_PROCESSED";
    }
    if(source === "Issuer"){
      message = "CART.PAID_ERROR_ISSUER";
    }
    if(status === "Cancel"){
      message = "CART.PAID_ERROR_CANCELED";
      isCanceled = true;
    }

    this.setState({
      transactionId: transId, 
      errorSource: source, 
      errorCode: code, 
      errorMessage: message,
      isCanceled: isCanceled,
      isLoaded: true
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3">
            <div className="ca-page-title w-100">
              {translatedMessage(this.state.errorMessage)}
            </div>
            <div className="ca-page-subtitle w-100 ca-link_underline">
              <Link to="/cart">{translatedMessage("CART.PAID_ERROR_CART_LINK")}</Link>
            </div>            
          </Row>
        </div>
      )
    }
  }

}

export default PaymentErrorPage;