import React from 'react';
import './PaymentCompletePage.css';

import { Row, Col } from 'react-bootstrap'

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';

import calogo from '../../components/shared/Resources/logo-dark-small-with-padding.png'

class PaymentCompletePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artworks: [],
      cartId: 0,
      transactionValue: 0,
      currency: "",
      orderNumber: "",
      phone: "",
      email: "",
      author: '',
      billingAddress: {
        id: "",
        address: "street",
        city: "city",
        country: "country",
      },
      paymentType: "NETS",
      exchangeRate: 1,
      isLoaded: false,
    }
  }

  async componentDidMount() {
    const user = await ApiService.initUser();
    if (user) {
      const countries = await ApiService.getAuthenticatedInstance().get('/countries?size=1000')
        .then(result => {
          let res = result.data._embedded.countries.map(element => {
            return {
              id: element._links.self.href.split('/')[element._links.self.href.split('/').length - 1],
              name: element.name
            };
          })
          return res;
        })
      if (!countries && countries.length === 0) {
        throw Error("Could not get countries list");
      }

      let address = null
      if (ApiService.getCurrentProfile().address) {
        address = ApiService.getCurrentProfile().address;
      }
      const billingAddress = this.buildBillingAddress(address, countries);

      this.setState({
        countries: countries,
        billingAddress: billingAddress,
        showAddressForm: billingAddress.id === 0
      }, this.populateData)
    }
  }

  buildBillingAddress = (address, countries) => {
    var billingAddress = {}

    billingAddress.id = address !== null ? address.id : 0;
    billingAddress.address = address !== null ? address.address : '';
    billingAddress.city = address !== null ? address.city : '';
    billingAddress.zip = address !== null ? address.zip : '';
    billingAddress.country = address !== null ? address.country.id : '';
    billingAddress.countryName = address !== null ? countries.filter(item => item.id.toString() === address.country.id.toString())[0].name : '';
    billingAddress.email = ApiService.getCurrentUser().username;
    billingAddress.phone = ApiService.getCurrentProfile().phone;

    return billingAddress;
  }

  async populateData() {
    const params = new URLSearchParams(window.location.search);
    const transactionId = params.get('transactionId');

    var paymentTransaction = await ApiService.getAuthenticatedInstance().get(`/payment/transaction/${transactionId}`)
      .then(result => {
        return result.data;
      });

    var artworks = await ApiService.getAuthenticatedInstance().get(`/payment/cartItems/${paymentTransaction.cartId}`)
      .then(result => {
        return result.data
      })

    this.setState({
      cartId: paymentTransaction.cartId,
      transactionValue: paymentTransaction.amount / 100,
      currency: paymentTransaction.currency,
      orderNumber: paymentTransaction.orderNumber,
      paymentType: paymentTransaction.type,
      exchangeRate: paymentTransaction.exchangeRate,
      artworks: artworks,
      isLoaded: true
    });
  }

  render() {
    let subtotal = this.state.transactionValue;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3">
            <div className="ca-page-title w-100">{translatedMessage("CART.PAID_THANK_YOU")}</div>
            <div className="ca-page-subtitle w-100">{translatedMessage("CART.ORDER_NO")}:&nbsp;{this.state.orderNumber}</div>
          </Row>

          {
            this.state.artworks.map((element, index) => {
              var price = this.state.paymentType === "VIPS" ? element.price * this.state.exchangeRate : element.price;
              return (
                <Row key={index} className="ca-cart-review-item">
                  <Col className="col-6 col-sm-6 col-md-8 pl-0">
                    <Row className="d-flex align-items-center">
                      <Col className="col-12 col-sm-6 ca-cart-review-photo-container">
                        <img
                          alt="Artwork poster"
                          src={element.thumbUuid ? ApiService.getFileByUuid(element.thumbUuid) : calogo}
                          className="d-inline-block align-center ca-cart-review-photo"
                        />
                      </Col>
                      <Col className="col-12 col-sm-6 ca-cart-item-review-details-container">
                        <div className="ca-cart-item-review-title">
                          <span className="font-weight-bold">{element.artworkName}</span>
                          &nbsp;{translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                          {element.authorName}
                        </div>
                        {element.artworkId &&
                          <>
                            <div className="ca-cart-item-review-date">
                              {ApiService.parseDate(element.publishDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' })}
                            </div>

                            <div className="ca-cart-item-review-details">
                              <div>{translatedMessage(element.artworkCategory)}</div>
                              <div>{element.sizeWidth} W x {element.sizeHeight} H cm</div>
                            </div>
                          </>
                        }
                        {element.virtualGalleryId &&
                          <div className="ca-cart-item-review-details">
                            <div>{translatedMessage(element.virtualGalleryType)}</div>
                          </div>
                        }
                        <div className="ca-cart-item-review-package">
                          {element.pricePackageName}
                          &nbsp;{translatedMessage("ARTWORK.PACKAGE").toLowerCase()}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-6 col-sm-6 col-md-4 pr-0">
                    <div className="ca-cart-review-quantity">
                      {translatedMessage("GENERAL.QUANTITY")}: {element.quantity}
                    </div>

                    <div className="ca-cart-item-review-price">
                      {ApiService.getNumberSeparatedByDots(price) + " " + this.state.currency}
                    </div>
                  </Col>
                </Row>
              )
            })
          }
          <div className="cart-review-subtotal mb-5">
            <span className="cart-review-price">
              {translatedMessage("CART.TOTAL")}
            </span>
            <span className="cart-review-price">
              {ApiService.getNumberSeparatedByDots(subtotal) + " " + this.state.currency}
            </span>
          </div>

          <Row>
            <Col className="col-12 pl-0 mb-3">
              <div className="ca-page-subtitle font-bold mb-2">
                {translatedMessage("CART.ADDRESS_BILLING")}
              </div>

              <div>
                <span style={{ fontWeight: 700 }}>{translatedMessage("PROFILE.ADDRESS")}: </span>
                {this.state.billingAddress.address}, {this.state.billingAddress.city}, {this.state.billingAddress.countryName}
              </div>

              <div>
                <span style={{ fontWeight: 700 }}>{translatedMessage("PROFILE.PHONE")}: </span>
                {this.state.billingAddress.phone}
              </div>

              <div>
                <span style={{ fontWeight: 700 }}>{translatedMessage("PROFILE.EMAIL")}: </span>
                {this.state.billingAddress.email}
              </div>
            </Col>
          </Row>
        </div>
      )
    }
  }

}

export default PaymentCompletePage;