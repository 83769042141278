import React from 'react';

import ExhibitionAdminViewComponent from '../../../../components/shared/Exhibition/ExhibitionManageViewComponent';
import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';

import ApiService from '../../../../services/api.service';

class ExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      exhibitionId: this.props.match.params.exhibitionId,
      company: null,
      isLoaded: false,
      canView: false,
      errorMessage: '',
      isError: false
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        let company = response.data.company;

        if (company.status.name === 'ACTIVE') {
          this.setState({
            company: company,
            canView: true,
            isLoaded: true
          })
        } else {
          this.setState({
            isError: true,
            errorMessage: "COMPANY.NOT_APPROVED",
            isLoaded: true
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionAdminViewComponent
          canView={this.state.canView}
          exhibitionId={this.state.exhibitionId}
          backUrl={`/my-company/${this.state.company.id}/exhibition`}
          artworksUrl={`/my-company/${this.state.company.id}/exhibition-artwork-view/${this.state.exhibitionId}`}
          useVideoUrl={true} />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionPage;