import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';
import arPortalIcon from '../../../components/shared/Resources/arPortalWhtite.png';

import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import FileService from "../../../services/file.service";
import { translatedMessage } from '../../../services/language.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";
import ArtworkService from '../../../services/artwork.service';

class ArPortalPublishArt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      virtualGallery: null,
      artFile: {},
      artFile1: {},
      mapFile: null,
      isLoaded: false,
      dropzoneEnabled: true,
      isError: false,
      errorMessage: '',
      displayMap: false,
      mapImageUrl: '',
      onlyView: false
    };

    this._isMounted = false;

  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
    this._isMounted = true;
    this.populateData();
  }

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
    this._isMounted = false;
  }

  beforeunload = (e) => {
    if (!this.state.dropzoneEnabled) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  async populateData() {
    ApiService.getAuthenticatedInstance().get(`/virtualGallery/project/${this.props.match.params.id}`)
      .then(result => {
        const artFiles = result.data.fileList.filter(file => file.usage === FileService.getFileUsage().AR_PORTAL);
        const mapFile = result.data.fileList.filter(file => file.usage === FileService.getFileUsage().AR_PORTAL_MAP);

        let artFile = artFiles.filter(item => item.mobilePlatform === 'IOS')[0];
        let artFile1 = artFiles.filter(item => item.mobilePlatform === 'ANDROID')[0];

        this.setState({
          virtualGallery: {
            ...result.data,
            storageItem: {
              id: result.data.portalStorageItemId
            },
          },
          artFile: artFile,
          artFile1: artFile1,
          mapFile: mapFile.length > 0 ? mapFile[0] : null,
          mapImageUrl: result.data.portalMapUuid ? ApiService.getFileByUuid(mapFile[0].uuid) + '?' + Date.now() : '',
          displayMap: result.data.portalMapUuid ? true : false,
          onlyView: result.data.status.name === ArtworkService.artworkStatusEnum().DRAFT ||
            result.data.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
          isLoaded: true
        })
      })
      .catch(err => {
        var errorMessage = "PROJECT.GENERAL_ERROR";
        if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  onUploadFile = (dropzoneEnabled, uploadedFile) => {
    let artFile = this.state.artFile;
    let artFile1 = this.state.artFile1;

    if (uploadedFile) {
      if (uploadedFile.mobilePlatform === 'IOS') {
        artFile = uploadedFile;
      } else if (uploadedFile.mobilePlatform === 'ANDROID') {
        artFile1 = uploadedFile;
      }
    }

    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          artFile: artFile,
          artFile1: artFile1
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        artFile: artFile,
        artFile1: artFile1
      });
    }
  }

  onUploadMapFile = (dropzoneEnabled, uploadedFile) => {
    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          mapFile: uploadedFile,
          mapImageUrl: ApiService.getFileByUuid(uploadedFile.uuid),
          displayMap: !this.state.displayMap,
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        modelFile: uploadedFile,
      });
    }
  }

  handleNextStep = () => {
    let errorMessage = "";
    let isError = false;

    if (!this.state.artFile) {
      errorMessage = "AR_PORTAL.PUBLISH.PORTAL_NOT_UPLOADED";
      isError = true;
    } else if (this.state.artFile.resourceType && this.state.artFile.resourceType.name === FileService.getResourceType().UNITY_SCENE && !this.state.artFile1) {
      errorMessage = "AR_PORTAL.PUBLISH.PORTAL_NOT_UPLOADED";
      isError = true;
    } else if (!this.state.mapFile && this.state.virtualGallery.needsMap) {
      errorMessage = "AR_PORTAL.PUBLISH.MAP_NOT_UPLOADED";
      isError = true;
    }

    if (!isError) {
      this.props.history.push(`/project/publish/ar-portal-price-package/${this.props.match.params.id}`);
    } else {
      toast.error(translatedMessage(errorMessage));
    }
  };

  changeImgDropzone = () => {
    this.setState({ displayMap: !this.state.displayMap })
  }

  render() {
    let img_class = this.state.displayMap ? "ca-form-image-container ca-left" : "ca-form-image-container-hide";
    let dropzone_class = !this.state.displayMap ? "ca-dropzone-show ca-left fixed-width mb-5" : "ca-dropzone-hide";

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        const homeUlr = this.state.virtualGallery.companyId ? `/my-company/${this.state.virtualGallery.companyId}/portfolio` : `/projects`;

        let resourceType = this.state.artFile ? this.state.artFile.resourceType : { label: 'CONTENT_RESOURCE_TYPE.UNITY_SCENE', name: 'UNITY_SCENE' };

        return (
          <>
            <div className="d-flex flex-column h-100">
              <div className="ca-publish-page">
                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1 d-flex align-items-center">
                    <Image src={arPortalIcon} alt="AR Art icon" className="icon" />
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <span className="font-weight-bold mr-1">{translatedMessage("PROJECT.CREATE_AR_PORTAL")}</span>
                      <span className="font-weight-bold mr-1">|</span>
                      <span>{translatedMessage("ARTWORK.FILES")}</span>
                    </div>
                  </div>
                  <div>
                    <Link to={homeUlr} className="ca-dark-link w-100">
                      <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                        title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                    </Link>
                  </div>
                </div>

                <div className="ca-page-padding ca-main-container">
                  <Row className='mb-0'>
                    <Col className="col-12 col-lg-6">
                      <div className="ca-publish-section-container">
                        <div className="ca-publish-section-title">
                          {translatedMessage("AR_PORTAL.AR_PORTAL")}
                        </div>
                        <div className="ca-publish-section-subtitle">
                          {this.state.artFile
                            ? translatedMessage("ARTWORK.PUBLISH.ARTWORK_UPLOADED") + " | " + translatedMessage("PROJECT.ARTWORK_FILE_NAME") + ": " +
                            this.state.artFile.name + (this.state.artFile.mobilePlatform ? " - iOS" : "") +
                            (this.state.artFile1 ? " & " + this.state.artFile1.name + " - Android" : "")
                            : translatedMessage("PROJECT.UPLOAD_ARTWORK")}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {!this.state.onlyView &&
                      <>
                        <Col className="col-12 col-lg-6">
                          <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.IOS")}</div>
                          <div className="ca-publish-section-container">
                            <div className="ca-dropzone-show ca-left mb-5">
                              <DropzoneComponent
                                componentId="artFile"
                                fileUsage={FileService.getFileUsage().AR_PORTAL}
                                fileType={FileService.getResourceType().UNITY_SCENE}
                                resourceType={resourceType && resourceType.name}
                                mobilePlatform={'IOS'}
                                deletePrevious={true}
                                deletedImage={this.state.artFile}
                                deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_AR_PORTAL"
                                fileFormatsDesc="DROPZONE.FILE_TYPES.ARCHIVE"
                                maxSize={100}
                                maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                multipleFiles={false}
                                multipleFilesDesc=""
                                entity={this.state.virtualGallery}
                                storageitemlinked={true}
                                onupload={this.onUploadFile}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col className="col-12 col-lg-6">
                          <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.ANDROID")}</div>
                          <div className="ca-publish-section-container">
                            <div className="ca-dropzone-show ca-left mb-5">
                              <DropzoneComponent
                                componentId="artFile1"
                                fileUsage={FileService.getFileUsage().AR_PORTAL}
                                fileType={FileService.getResourceType().UNITY_SCENE}
                                resourceType={resourceType && resourceType.name}
                                mobilePlatform={'ANDROID'}
                                deletePrevious={true}
                                deletedImage={this.state.artFile1}
                                deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_AR_PORTAL"
                                fileFormatsDesc="DROPZONE.FILE_TYPES.ARCHIVE"
                                maxSize={100}
                                maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                multipleFiles={false}
                                multipleFilesDesc=""
                                entity={this.state.virtualGallery}
                                storageitemlinked={true}
                                onupload={this.onUploadFile}
                              />
                            </div>
                          </div>
                        </Col>
                      </>
                    }

                    {this.state.virtualGallery.canHaveMap &&
                      <Col className="col-12 col-lg-6">
                        <div className="ca-publish-section-container">
                          <div className="ca-publish-section-title">
                            {translatedMessage("AR_PORTAL.AR_PORTAL_MAP")}
                            <OverlayTrigger
                              key='map'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-map`} className="ca-tooltip">
                                  {translatedMessage("AR_PORTAL.AR_PORTAL_MAP_INFO")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </div>
                          <div className="ca-publish-section-subtitle">
                            {this.state.mapFile ? translatedMessage("AR_PORTAL.AR_PORTAL_MAP_UPLOADED") + " | " + translatedMessage("GENERAL.FILE") + ": " + this.state.mapFile.name : translatedMessage("AR_PORTAL.AR_PORTAL_MAP_DESC")}
                          </div>

                          <div className={img_class} onClick={this.changeImgDropzone.bind(this)}>
                            <Image src={this.state.mapImageUrl} className="ml-0" />
                            <div className="ca-form-image-change-details">
                              <div style={{ fontFamily: "FontAwesome", fontSize: "48px" }}>&#xf030;</div>
                              <div>{translatedMessage("GENERAL.IMAGE.TOOLTIP")}</div>
                            </div>
                          </div>
                          <div className={dropzone_class}>
                            <DropzoneComponent
                              componentId="modelFile"
                              fileUsage={FileService.getFileUsage().AR_PORTAL_MAP}
                              fileType={FileService.getResourceType().IMAGE}
                              deletePrevious={true}
                              deletedImage={this.state.mapFile}
                              deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                              fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                              maxSize={10}
                              maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                              multipleFiles={false}
                              multipleFilesDesc=""
                              entity={this.state.virtualGallery}
                              storageitemlinked={true}
                              onupload={this.onUploadMapFile}
                            />
                            {this.state.mapFile &&
                              <div style={{ textAlign: "center" }}>
                                <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeImgDropzone.bind(this)}>&#xf021;</div>
                              </div>
                            }
                          </div>
                        </div>
                      </Col>
                    }
                  </Row>
                </div>
              </div>

              <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button className={this.state.dropzoneEnabled ? "ca-button-icon" : "ca-button-icon disabled"} variant="link"
                    onClick={() => this.props.history.push(`/project/publish/ar-portal/${this.props.match.params.id}`)}>
                    <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} />
                  </Button>
                  <span>{translatedMessage("BUTTON.BACK")}</span>
                </div>
                <div className="d-flex align-items-center">
                  <span>{translatedMessage("BUTTON.NEXT")}</span>
                  <Button className={this.state.dropzoneEnabled ? "ca-button-icon" : "ca-button-icon disabled"} variant="link" onClick={this.handleNextStep}>
                    <Image src={nextArrow} alt={translatedMessage("BUTTON.NEXT")} />
                  </Button>
                </div>
              </div>
            </div>
          </>
        )
      }
    }
  }
}

export default ArPortalPublishArt;