import React from 'react';

import defaultVideo from '../Resources/videoDefault.jpg';


class DisplayVideoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

  }

  render() {
    if (this.props.youtubeId !== null) {
      return (
        <div>
          <div className="ca-paragraph-subtitle w-100">{this.props.title}</div>
          <div className={this.props.classvideo}>
            <iframe title="iframe"
              width={this.props.width}
              height={this.props.height}
              src={"https://www.youtube.com/embed/".concat(this.props.youtubeId).concat("?rel=0&start=0&modestbranding=1&enablejsapi=1&origin=https://app.connected.art/")}
              frameBorder={0}
              allowFullScreen>
            </iframe>

            {/* <video className="video-container video-container-overlay ca-video" 
                              style={{ width: "100%", height: "auto" }}
                              autoPlay={false} muted={false}>
                              <source src={element.videoLink} type="video/mp4" />
                      </video> */}
          </div>
        </div>
      )
    } else {
      return (
        <div className={"w-100 ca-novideo-container " + this.props.classimage}>
          <div className="ca-paragraph-subtitle w-100">{this.props.title}</div>
          <div className={"h-100 ca-novideo d-flex align-items-center justify-content-center"}>
            <img src={defaultVideo} alt="No video available" />
          </div>
        </div>
      )
    }
  }
}

export default DisplayVideoComponent;