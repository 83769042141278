import React from 'react';

import { translatedMessage } from '../../../services/language.service';
import soldout from '../../../components/shared/Resources/sold_out.png';
import limitedEdition from '../../../components/shared/Resources/limited_edition.png';

import { Modal, Button, Image, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/api.service';

class ArtworkViewModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let art = this.props.art;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                art={art}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="xl"
                className="ca-art-modal"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className="font-weight-bold">{art.name}</span>&nbsp;
                        <span className="font-weight-normal">
                            {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                            {art.author}
                            {/* {art.publishDate &&
                                <div className="ca-art-date">{ApiService.parseDate(art.publishDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' })}</div>
                            } */}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="view-description-about">
                        {art.description &&
                            <Col className="col-12 col-lg-6 mb-4">
                                <div className="ca-label">
                                    {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                                </div>

                                <div className="ca-description-value-scroll" dangerouslySetInnerHTML={{ __html: art.description }} />
                            </Col>
                        }
                        {art.aboutAuthor &&
                            <Col className="col-12 col-lg-6 mb-4">
                                <div className="ca-label">
                                    {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                                </div>
                                <div className="ca-description-value-scroll" dangerouslySetInnerHTML={{ __html: art.aboutAuthor }} />
                            </Col>
                        }
                        {art.type &&
                            <Col className="col-12 col-lg-6 mb-4">
                                <div className="ca-label">
                                    {translatedMessage("GENERAL.TYPE")}
                                </div>

                                <div className="ca-view-value">
                                    {translatedMessage(art.type.label)}
                                </div>

                                {(!art.description || !art.aboutAuthor) && (art.nrArArt !== 0 || art.nrSculpture !==0) &&
                                    <div className="ca-display-group mt-3">
                                        <div className="ca-label">
                                            {translatedMessage("VIRTUAL_GALLERY.DISPLAYED_ARTWORKS")}
                                        </div>
                                        <div className="ca-view-value">
                                            <ul>
                                                <li>
                                                    {translatedMessage("AR_PORTAL.AR_ART_NO") + ":  " + art.nrArArt}
                                                </li>
                                                <li>
                                                    {translatedMessage("AR_PORTAL.SCULPTURE_NO") + ":  " + art.nrSculpture}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </Col>
                        }
                        {art.description && art.aboutAuthor && (art.nrArArt !== 0 || art.nrSculpture !==0) &&
                            <Col className="col-12 col-lg-6 mb-4">
                                <div className="ca-label">
                                    {translatedMessage("VIRTUAL_GALLERY.DISPLAYED_ARTWORKS")}
                                </div>
                                <div className="ca-view-value">
                                    <ul>
                                        <li>
                                            {translatedMessage("AR_PORTAL.AR_ART_NO") + ":  " + art.nrArArt}
                                        </li>
                                        <li>
                                            {translatedMessage("AR_PORTAL.SCULPTURE_NO") + ":  " + art.nrSculpture}
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        }
                    </Row>
                    {art.pricePackages && art.pricePackages.length > 0 &&
                        <Row>
                            <Col className="col-12">
                                <div className="ca-label mb-3">
                                    {translatedMessage("ARTWORK.PRICING")}
                                </div>
                            </Col>
                            {art.pricePackages.map((element, index) => {
                                return (
                                    <Col key={index} className="col-12 col-sm-6">

                                        <Card className={element.investorPackage ? "ca-artwork-pricing-card black-card" : "ca-artwork-pricing-card"}>
                                            {element.availableCopies === 0
                                                ? <Image src={soldout} className="ca-soldout-image small" />
                                                : element.quantity > 0
                                                    ? <Image src={limitedEdition} className="ca-limited-image" />
                                                    : ""
                                            }
                                            <Card.Header>
                                                <Card.Title>{element.name}</Card.Title>
                                                <div className="w-100 ca-card-detail-text">
                                                    <span>
                                                        {element.quantity === -1
                                                            ? ""
                                                            : translatedMessage("ARTWORK.AVAILABLE") + ": " + element.availableCopies + " / " + element.nrCopies}
                                                        &nbsp;
                                                    </span>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className="">
                                                <div>
                                                    <div className="w-100 mb-1">{ApiService.getNumberSeparatedByDots(element.price)} USD</div>
                                                    {element.hasDigitalPrint &&
                                                        <div>
                                                            <span className="font-bold">{translatedMessage("ARTWORK.DIGITAL_PRINT") + ": "}</span> {element.digitalPrintQuantity}
                                                            {element.hasSignature && " (" + translatedMessage("ARTWORK.ARTIST_SIGNATURE") + ")"}
                                                        </div>
                                                    }
                                                </div>
                                            </Card.Body>
                                            <Card.Footer>
                                                <div>&nbsp;</div>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Link to={`/virtualGalleryDetails/${art.id}`} target="_blank" className="ca-button ca-color-white">
                        {translatedMessage("VIRTUAL_GALLERY.VIEW_PAGE")}
                    </Link>
                    <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide()}>
                        {translatedMessage("GENERAL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ArtworkViewModalComponent;

