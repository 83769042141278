import React from 'react';

import CardComponent from "../EntityManageArExperienceCardComponent/EntityManageArExperienceCardComponent";
import { toast } from "react-toastify";

import parkIcon from '../../../components/shared/Resources/homepage/art_park.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import mapIcon from '../../../components/shared/Resources/icons/map.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../components/shared/Resources/icons/cancel.svg';
import artIcon from '../../../components/shared/Resources/icons/art.svg';

import { Row, Col, Form, Button, Dropdown, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import ArExperienceService from '../../../services/arExperience.service';
import { translatedMessage } from '../../../services/language.service';
import ParkService from '../../../services/park.service';

const ENTER_KEY = 13;

class ParkManageArExpereinceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkId: this.props.parkId,
      parkName: '',
      park: {},
      searchInput: '',
      parkARList: [],
      selectedCategory: { label: "GENERAL.CATEGORY.ANY", name: "ANY" },
      categoryList: [],
      filteredList: [],
      existingARList: [],
      isLoaded: false,
      isAdd: false,
      canHaveLocation: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };

    this.addVG = this.addVG.bind(this);
  }

  componentDidMount = () => {
    // console.log("ParkManageArExpereinceComponent")
    if (this.props.canEdit || this.props.canViewList) {
      this.getData();
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  getData() {
    let parkId = this.state.parkId;
    let parkARList = [];
    let park = {};
    let promise = [];
    let categoryList = [];
    let canHaveLocation = false;

    let criteria = {};
    criteria.sortOrder = "ASC";
    criteria.sortField = "ae.name";
    criteria.pageSize = -1;
    criteria.parkId = parkId
    criteria.entityName = ApiService.getEntityName().AR_EXPERIENCE


    ParkService.getParkArtListForManage(criteria)
      .then(response => {
        parkARList = response.artList;
        park = response.park
      })
      .then(async () => {
        categoryList = await ArExperienceService.getArtCategoryList()
          .then(result => {
            result.splice(0, 0, { label: "GENERAL.CATEGORY.ANY", name: "ANY" })
            return result;
          })
      })
      .then(() => {
        if (park.id) {
          promise.push(ApiService.getAuthenticatedInstance().get(`park/${parkId}/location/manage`)
            .then(response => {
              const location = response.data;
              if (location && location.locationLat !== 0 && location.locationLng !== 0) {
                canHaveLocation = true;
              }
            })
          )
        }

        Promise.all(promise).then(() => {
          this.setState({
            parkARList: parkARList,
            filteredList: parkARList,
            park: park,
            canHaveLocation: canHaveLocation,
            categoryList: categoryList,
            isLoaded: true
          })
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.categoryList.filter(item => item.name === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.parkARList;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory.name;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.parkARList.filter(elem => elem.arExperience.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem !== "ANY") {
      result = result.filter(elem => elem.arExperience.category.name === categoryItem);
    }

    this.setState({
      filteredList: result
    })
  }

  async addVG() {
    let arExperienceList = await ApiService.getAuthenticatedInstance().get(`/arExperience/arExperienceList`)
      .then(response => { return response.data.filter(item => item.status.name === 'PUBLISHED') })

    var arE = []
    var existingARList = [];

    arExperienceList.map((element, index) => {
      const existingAR = this.state.parkARList.filter(item => item.arExperience.id === element.id);
      if (existingAR.length > 0) {
        existingARList.push(existingAR[0].arExperience.id.toString());
      }

      element.category = element.category.label;

      arE.push({
        "arExperience": element,
        "park": this.state.park,
        "existingAR": existingAR.length > 0 ? true : false,
        "imageURL": element.thumbnailUuid ? `${ApiService.getBaseUrl()}/storage/file/${element.thumbnailUuid}` : "",
        "id": element.id
      })
      return element;
    })

    this.setState({
      parkARList: arE,
      filteredList: arE,
      isAdd: true,
      existingARList: existingARList
    });
  }

  handleCheckChange = (id, isChecked) => {
    let existingARList = this.state.existingARList;
    if (isChecked) {
      existingARList.push(id)
    } else {
      let index = existingARList.indexOf(id);
      if (index !== -1) {
        existingARList.splice(index, 1);
      }
    }

    this.setState({
      existingARList: existingARList
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    ParkService.addArtToPark(this.state.parkId, ApiService.getEntityName().AR_EXPERIENCE, this.state.existingARList)
      .then(response => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          isAdd: false,
        }, this.getData)
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        this.setState({
          isAdd: false,
        }, this.getData)
      })
  }

  cancelAdd = () => {
    this.setState({
      isAdd: false,
    }, this.getData)
  }

  render() {
    const parkArExperiences = this.state.filteredList;
    const backUrl = this.state.park?.parentPark?.id ? `/${this.props.entityUrl}/${this.state.park.parentPark.id}/view` : `/${this.props.entityUrl}`

    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={parkIcon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITION")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.park.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("AR_EXPERIENCE.MANAGE")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={this.addVG} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={artIcon} alt={translatedMessage("ARTWORK.MANAGE")} title={translatedMessage("ARTWORK.MANAGE")} />
              </Button>
              <Button type="submit" form="vgForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button onClick={this.cancelAdd} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
              </Button>
              <Button onClick={() => this.props.history.push(this.props.mapUrl)} className="ca-dark-link ca-button-icon medium mr-1">
                <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} title={translatedMessage("MAP.VIEW_ON_MAP")} />
              </Button>
              <Button onClick={() => this.props.history.push(backUrl)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} onChange={this.handleSearchInput} className="ca-form-control-search"
                type="text" placeholder="&#xF002;" />
            </Col>

            <Col className="col-12 col-md-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.label)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {this.state.categoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>


          <Form id="vgForm" className="ca-form" onSubmit={this.handleSubmit}>
            <div className="card-columns">
              {parkArExperiences.map((element, index) => {
                return (
                  <CardComponent
                    key={element.id}
                    id={element.id}
                    entity={element.arExperience}
                    imageUrl={element.imageURL}
                    isAdd={this.state.isAdd}
                    existingAR={!this.state.isAdd ? true : element.existingAR}
                    onCheck={this.handleCheckChange}
                    canHaveLocation={this.state.canHaveLocation}
                    locationRedirectUrl={`/parkAdmin/${this.state.parkId}/arExperience/${element.id}/location`}
                    triggerRedirectUrl={`${this.props.triggerUrl}/${element.id}`}
                  />
                )
              })}
            </div>
          </Form>

        </div >
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage(this.state.errorMessage)}</span>
          </Row>
        </div>
      )
    }
  }

}

export default ParkManageArExpereinceComponent;