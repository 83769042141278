import React from 'react';
import './ProjectCreateModalComponent.css';

import { Modal, Button } from 'react-bootstrap'

import LanguageService from '../../../../services/language.service'

class ProjectCreateModalPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    exitModal = () => {
        this.props.history.push(`${this.props.nexturl}`);
    }

    render() {
        return (
            <>
                <Modal
                    {...this.props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="project-create-modal"
                >
                    <Modal.Body>
                        <div className="project-create-modal-arrange-text">
                            <Modal.Title id="contained-modal-title-vcenter">
                            {window.$translate(LanguageService.getCurrentLanguage(), "PROJECT.CREATE_SUCCESS.TITLE")}
                            </Modal.Title>
                            <p>{window.$translate(LanguageService.getCurrentLanguage(), "PROJECT.CREATE_SUCCESS.DESCRIPTION")}</p>
                        </div>

                        <Button variant="dark" className="ca-button" onClick={this.exitModal}>{window.$translate(LanguageService.getCurrentLanguage(), "PROJECT.CREATE_SUCCESS.BUTTON")}</Button>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

}

export default ProjectCreateModalPage;