import React from 'react';

import CardComponent from "./GalleryArtworkCardComponent";
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import ArtworkViewModalComponent from '../Artwork/ArtworkViewModalComponent';
import VirtualGalleyViewModalComponent from '../VirtualGallery/VirtualGalleyViewModalComponent';

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import mapIcon from '../../../components/shared/Resources/icons/map.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../components/shared/Resources/icons/cancel.svg';
import artworkIcon from '../../../components/shared/Resources/icons/artwork.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';

import { toast } from "react-toastify";

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import GalleryService from '../../../services/gallery.service';

const ENTER_KEY = 13;

class GalleryManageArtComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryId: this.props.galleryId,
      gallery: null,
      searchInput: '',
      artworks: [],
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      artworkCategoryList: [],
      filteredArtworks: [],
      authors: [],
      existingArtworkList: [],
      existingVGList: [],
      isLoaded: false,
      isAdd: false,
      isAddArtwork: false,
      isAddVG: false,
      artworkCanHaveLocation: false,
      isError: false,
      errorMessage: "",
      showArtworkModal: false,
      showVirtualGalleryModal: false,
      modalData: {
        art: {}
      },
      sellOption: [
        { id: "0", code: "GENERAL.SEE_ALL", value: null },
        { id: "1", code: "GENERAL.YES", value: true },
        { id: "2", code: "GENERAL.NO", value: false }
      ],
      selectedSellOption: { id: "0", code: "GENERAL.SEE_ALL", value: null },
    };

    this.addArtwork = this.addArtwork.bind(this);
    this.addVG = this.addVG.bind(this);
  }

  componentDidMount = () => {
    if (this.props.canEdit) {
      ApiService.getAuthenticatedInstance().get(`gallery/getGalleryDetail/${this.props.galleryId}`)
        .then(response => {
          this.setState({
            gallery: response.data
          }, this.getArt)
        })
        .catch(err => {
          var errorMessage = "GENERAL.GET_DATA_ERROR";
          if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          });
        });
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  getArt = () => {
    let artworkCategoryList = [];
    let artworks = [];
    let promise = [];
    let artworkCanHaveLocation = false;
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ArtworkService.getGalleryArt(this.state.gallery.id)
      .then(response => {
        artworks = response
      })
      .then(() => {
        promise.push(ArtworkService.getArtworkCategoryList()
          .then(response => {
            artworkCategoryList = response;
            artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
          }))

        promise.push(ApiService.getAuthenticatedInstance().get(`gallery/${this.state.gallery.id}/locations`)
          .then(response => {
            const locations = response.data;
            if (locations.length === 1) {
              if (locations[0].locationLat !== 0 && locations[0].locationLng !== 0) {
                artworkCanHaveLocation = true;
              }
            }
          })
        )

        Promise.all(promise).then(() => {
          this.setState({
            artworks: artworks,
            filteredArtworks: artworks,
            artworkCategoryList: artworkCategoryList,
            artworkCanHaveLocation: artworkCanHaveLocation,
            isLoaded: true
          }, this.filter)
        })
          .catch(err => {
            if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
              errorMessage = err.response.data.message;
            }
            console.log("An error has ocurred: " + err);
            this.setState({
              isError: true,
              errorMessage: errorMessage,
              isLoaded: true
            });
          });
      }).catch(err => {
        if (err && err.response && err.response.status && (404 === err.response.status || 403 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        console.log("An error has ocurred: " + err);
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        });
      });
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  handleOnSellOptionSelect = (eventKey) => {
    const selectedItem = this.state.sellOption.filter(item => item.id === eventKey);

    this.setState({
      selectedSellOption: selectedItem[0]
    }, this.filter)
  }  

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;
    let sellItem = this.state.selectedSellOption;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase())
        || elem.author.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.category === categoryItem.code);
    }

    if (sellItem.value !== null) {
      result = result.filter(elem => elem.soldByCompany === sellItem.value);
    }    

    this.setState({
      filteredArtworks: result
    })
  }

  async addArtwork() {
    let artworks = [];
    if (this.props.isAdmin) {
      artworks = await ArtworkService.getArtworksByCriteria(false, ArtworkService.artworkStatusEnum().PUBLISHED)
        .then(response => { return response });
    } else {
      artworks = await ArtworkService.getArtworksForCompany(this.props.companyId)
        .then(response => { return response });
    }

    var art = []
    var existingArtworkList = [];
    artworks.map((element, index) => {
      const existingArt = this.state.artworks.filter(item => item.artworkId === element.id);
      if (existingArt.length > 0) {
        existingArtworkList.push(existingArt[0].artworkId.toString());
      }
      art.push({
        "name": element.name,
        "artworkId": element.id,
        "category": element.category.code,
        "entityName": ApiService.getEntityName().ARTWORK,
        "gallery": this.state.gallery,
        "author": element.author,
        "image": element.image,
        "existingArt": existingArt.length > 0 ? true : false,
        "soldByCompany": element.soldByCompany,
        "id": element.id
      })
      return art;
    })

    this.setState({
      artworks: art,
      filteredArtworks: art,
      isAdd: true,
      isAddArtwork: true,
      existingArtworkList: existingArtworkList
    }, this.filter);
  }

  async addVG() {
    var virtualGalleries = [];

    if (this.props.isAdmin) {
      virtualGalleries = await VirtualGalleryService.getVirtualGalleriesByStatus(VirtualGalleryService.virtualGalleryStatusEnum().PUBLISHED, false)
        .then(response => { return response });
    } else {
      virtualGalleries = await VirtualGalleryService.getVirtualGalleriesForCompany()
        .then(response => { return response });
    }

    virtualGalleries = virtualGalleries.filter(item => item.type.name !== VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY);

    var vg = []
    var existingVGList = [];
    virtualGalleries.map((element, index) => {
      const existingVG = this.state.artworks.filter(item => item.virtualGalleryId === element.id);
      if (existingVG.length > 0) {
        existingVGList.push(existingVG[0].virtualGalleryId.toString());
      }
      vg.push({
        "name": element.name,
        "virtualGalleryId": element.id,
        "category": ArtworkService.artworkCategoryEnum().AR_PORTAL,
        "entityName": ApiService.getEntityName().VIRTUAL_GALLERY,
        "gallery": this.state.gallery,
        "author": element.author,
        "image": element.imageURL,
        "existingArt": existingVG.length > 0 ? true : false,
        "soldByCompany": element.soldByCompany,
        "id": element.id
      })
      return element;
    })

    this.setState({
      artworks: vg,
      filteredArtworks: vg,
      isAdd: true,
      isAddVG: true,
      existingVGList: existingVGList
    }, this.filter);
  }

  handleCheckChange = (id, isChecked) => {
    if (this.state.isAddArtwork) {
      let existingArtworkList = this.state.existingArtworkList;
      if (isChecked) {
        existingArtworkList.push(id)
      } else {
        let index = existingArtworkList.indexOf(id);
        if (index !== -1) {
          existingArtworkList.splice(index, 1);
        }
      }

      this.setState({
        existingArtworkList: existingArtworkList
      })
    } else if (this.state.isAddVG) {
      let existingVGList = this.state.existingVGList;
      if (isChecked) {
        existingVGList.push(id)
      } else {
        let index = existingVGList.indexOf(id);
        if (index !== -1) {
          existingVGList.splice(index, 1);
        }
      }

      this.setState({
        existingVGList: existingVGList
      })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.isAddArtwork) {
      ArtworkService.addArtworkToGallery(this.state.galleryId, this.state.existingArtworkList)
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            isAdd: false,
            isAddArtwork: false,
          }, this.getArt)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          this.setState({
            isAdd: false,
            isAddArtwork: false,
          }, this.getArt)
        })
    } else if (this.state.isAddVG) {
      GalleryService.addVGToGallery(this.state.galleryId, this.state.existingVGList)
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            isAdd: false,
            isAddVG: false
          }, this.getArt)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
          this.setState({
            isAdd: false,
            isAddVG: false
          }, this.getArt)
        })
    }
  }

  cancelAdd = () => {
    this.setState({
      isAdd: false,
      isAddArtwork: false,
      isAddVG: false
    }, this.getArt)
  }

  onShowModal = (artId, entityName) => {
    if (entityName === ApiService.getEntityName().ARTWORK) {
      ApiService.getAuthenticatedInstance().get(`/artwork/artworkDetail/${artId}`)
        .then(response => {
          this.setState({
            modalData: response.data.artwork,
            showArtworkModal: true
          })
        })
        .catch(err => {
          let errorMessage = "GENERAL.GET_DATA_ERROR";

          console.log(err)
          if (err && err.response && err.response.status === 404) {
            errorMessage = err.response.data.message;
          }

          toast.error(translatedMessage(errorMessage));
        });
    } else {
      if (entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
        VirtualGalleryService.getVirtualGallery(artId)
          .then(response => {
            this.setState({
              modalData: response,
              showVirtualGalleryModal: true
            })
          })
          .catch(err => {
            let errorMessage = "GENERAL.GET_DATA_ERROR";
  
            console.log(err)
            if (err && err.response && err.response.status === 404) {
              errorMessage = err.response.data.message;
            }
  
            toast.error(translatedMessage(errorMessage));
          });
      }      
    }
  }

  onHideModal = () => {
    this.setState({
      showArtworkModal: false,
      showVirtualGalleryModal: false
    })
  }

  render() {
    const artworks = this.state.filteredArtworks;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={this.props.icon} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERY")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.gallery.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("GENERAL.ART.MANAGE")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={this.addArtwork} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={artworkIcon} alt={translatedMessage("GENERAL.ARTWORKS.MANAGE")} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")} />
              </Button>
              <Button onClick={this.addVG} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={virtualGalleryIcon} alt={translatedMessage("VIRTUAL_GALLERY.MANAGE")} title={translatedMessage("VIRTUAL_GALLERY.MANAGE")} />
              </Button>
              <Button type="submit" form="artworkForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button onClick={this.cancelAdd} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAdd ? "ca-hide-button" : "")}>
                <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
              </Button>
              {this.props.isAdmin &&
                <Button onClick={() => this.props.history.push(this.props.mapUrl)} className="ca-dark-link ca-button-icon medium mr-1">
                  <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} title={translatedMessage("MAP.VIEW_ON_MAP")} />
                </Button>
              }
              <Button onClick={() => this.props.history.push(this.props.backUrl)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-4 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>

            <Col className="col-12 col-sm-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="col-12 col-sm-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnSellOptionSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedSellOption.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.sellOption.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>            
          </Row>

          <Form id="artworkForm" className="ca-form" onSubmit={this.handleSubmit}>
            <div className="card-columns">
              {artworks.map((element, index) => {
                let link = "";
                let id = 0;
                let selectText = "";
                let type = null;

                if (element.entityName === ApiService.getEntityName().ARTWORK) {
                  id = element.artworkId;
                  link = `/artwork/${element.artworkId}`;
                  selectText = "ARTWORK.SELECT";
                } else if (element.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                  id = element.virtualGalleryId
                  link = `/virtualGalleryDetails/${element.virtualGalleryId}`;
                  selectText = "VIRTUAL_GALLERY.SELECT";
                  type = element.virtualGalleryType
                }

                return (
                  <CardComponent
                    key={element.id}
                    id={id}
                    entity={element}
                    image={element.image}
                    title={element.name}
                    author={element.author}
                    category={element.category}
                    type={type}
                    link={link}
                    soldByCompany={element.soldByCompany}                    
                    isAdd={this.state.isAdd}
                    existingArt={!this.state.isAdd ? true : element.existingArt}
                    onCheck={this.handleCheckChange}
                    artworkCanHaveLocation={this.state.artworkCanHaveLocation}
                    locationRedirectUrl={`/gallery/artwork/${element.id}/location`}
                    selectText={selectText}
                    onClick={this.onShowModal}
                  />
                )
              })}
            </div>
          </Form>

          <ArtworkViewModalComponent
            show={this.state.showArtworkModal}            
            onHide={this.onHideModal}
            art={this.state.modalData}
          />

          <VirtualGalleyViewModalComponent
            show={this.state.showVirtualGalleryModal}            
            onHide={this.onHideModal}
            art={this.state.modalData}
          />          
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default GalleryManageArtComponent;
