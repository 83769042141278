import React from 'react';

import ArtworkEditComponent from '../../../../components/shared/Artwork/ArtworkEditComponent';

class PortfolioArtworkEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {}

  render() {
      return (
        <ArtworkEditComponent
          artworkId={this.props.match.params.artworkId}
          canEdit={true}
          isAuthor={true}
          backLink={`/my-company/${this.props.match.params.id}/portfolio`}
          viewLink={`/my-company/${this.props.match.params.id}/portfolio-artwork/${this.props.match.params.artworkId}`}
          history={this.props.history}
        />
      )
  }

}

export default PortfolioArtworkEditPage;