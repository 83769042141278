import React from 'react';

import { translatedMessage } from '../../../services/language.service';

import { Modal, Button, Form, Dropdown, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import Switch from "react-switch";
import ArtworkService from '../../../services/artwork.service';
import ApiService from '../../../services/api.service';

class PricePackageModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data
    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: prevState.data };
    }
    else return null;
  }

  validate = (price, quantity) => {
    let response = {
      isValid: true,
      errorMessage: ""
    }

    let data = this.state.data;

    if (price <= 0) {
      response.isValid = false;
      response.errorMessage = translatedMessage("ARTWORK.PACKAGE.PRICE_ZERO");

      return response;
    } 
    
    if (quantity && quantity <= 0) {
      response.isValid = false;
      response.errorMessage = translatedMessage("ARTWORK.PACKAGE.QUANTITY_ZERO");

      return response;
    }     
    
    if (quantity && data.minQuantity && data.quantity < data.minQuantity) {
      response.isValid = false;
      response.errorMessage = translatedMessage("ARTWORK.PACKAGE.MIN_QUANTITY") + data.minQuantity;

      return response;
    }

    if (data.minPrice && price < data.minPrice) {
      response.isValid = false;
      response.errorMessage = translatedMessage("ARTWORK.PACKAGE.MIN_PRICE") + " " + ApiService.getNumberSeparatedByDots(data.minPrice) + " $"

      return response;
    }

    return response;
  }

  saveChanges = (event) => {
    event.preventDefault();
    let data = this.state.data;
    let quantity = event.target.elements.formQuantity ? event.target.elements.formQuantity.value : null;
    let price = event.target.elements.formPrice.value;

    let validation = this.validate(price, quantity)

    if (validation.isValid) {
      data.price = price;
      data.quantity = quantity;

      this.props.onHide(data)
    } else {
      toast.error(validation.errorMessage);
    }
  }

  onSignatureChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        hasSignature: event
      }
    });
  }

  onInvestorPackageChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        hasInvestorPackage: event,
      }
    });
  }

  onDigitalPrintChange = (event) => {
    var digitalPrintQuantity = event
      ? (this.state.data.digitalPrintQuantity === 0 ? 1 : this.state.data.digitalPrintQuantity)
      : 0;

    this.setState({
      data: {
        ...this.state.data,
        hasDigitalPrint: event,
        hasSignature: event,
        digitalPrintQuantity: digitalPrintQuantity
      }
    });
  }

  onDigitalPrintQuantityChange = (event) => {
    var data = this.state.data;
    data.digitalPrintQuantity = parseInt(event);
    this.setState({
      data: data
    });
  }

  render() {
    var isPicture = this.props.category === ArtworkService.artworkCategoryEnum().AR_ART || this.props.category === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE;
    var isObject = this.props.category === ArtworkService.artworkCategoryEnum().OBJECT || this.props.category === ArtworkService.artworkCategoryEnum().AR_PORTAL;
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal ca-modal"
      >
        <Modal.Header className="empty-modal-header"></Modal.Header>
        <div id="contained-modal-title-vcenter" className="ca-modal-title">
          {this.state.data.name}
        </div>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Group controlId="formPrice">
              <Form.Label>
                <span>{translatedMessage("ARTWORK.PRICE")}</span>
                {this.state.data.minPrice &&
                  <span style={{ fontWeight: "400" }}>
                    {" (" + translatedMessage("ARTWORK.PRICE.INFO") + " " + ApiService.getNumberSeparatedByDots(this.state.data.minPrice) + " $ )"}
                  </span>
                }
              </Form.Label>
              <InputGroup className="ca-form-input-group">
                <Form.Control
                  required
                  type="number"
                  defaultValue={this.props.data.price}
                  className="mb-0"
                />
                <InputGroup.Append>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            {!this.state.data.isOneOfKind && !this.state.data.isFreePackage &&
              <Form.Group controlId="formQuantity">
                <Form.Label>
                  <span>{translatedMessage("GENERAL.QUANTITY")}</span>
                  {this.state.data.minQuantity &&
                    <span style={{ fontWeight: "400" }}>
                      {" (" + translatedMessage("ARTWORK.QUANTITY.INFO") + " " + this.state.data.minQuantity + ")"}
                    </span>
                  }
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  defaultValue={this.props.data.nrCopies}
                  className="mb-0"
                />
              </Form.Group>
            }
            {isObject &&
              <Form.Group controlId="formDigitalPrint">
                <div className="d-flex align-items-center">
                  <Form.Label className="mb-0 mr-1">
                    {translatedMessage("ARTWORK.DIGITAL_PRINT")}
                  </Form.Label>
                  <Switch
                    onChange={(event) => this.onDigitalPrintChange(event)}
                    checked={this.state.data.hasDigitalPrint}
                    offColor="#F00"
                  />
                </div>
              </Form.Group>
            }
            {isObject && this.state.data.hasDigitalPrint &&
              <Form.Group controlId="formDigitalPrintQuantity">
                <Form.Label>
                  {translatedMessage("ARTWORK.DIGITAL_PRINT.QUANTITY")}
                </Form.Label>
                <Dropdown className="ca-dropdown" onSelect={this.onDigitalPrintQuantityChange}>
                  <Dropdown.Toggle>
                    {this.state.data.digitalPrintQuantity}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {[1, 2, 3].map((element, index) => {
                      return (
                        <Dropdown.Item key={index} eventKey={element}>{element}</Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            }
            {(isPicture || (isObject && this.state.data.hasDigitalPrint)) &&
              <Form.Group controlId="formSignature">
                <div className="d-flex align-items-center">
                  <Form.Label className="mb-0 mr-1">
                    {translatedMessage("ARTWORK.DIGITAL_SIGNATURE")}
                  </Form.Label>
                  <Switch
                    onChange={(event) => this.onSignatureChange(event)}
                    checked={this.state.data.hasSignature}
                    disabled={this.state.data.isOneOfKind || (isObject && this.state.data.hasDigitalPrint)}
                    offColor="#F00"
                  />
                </div>
              </Form.Group>
            }
            {!this.state.data.isOneOfKind && !this.state.data.isFreePackage && !this.props.companyid &&
              <Form.Group controlId="formInvestor">
                <div className="d-flex align-items-center">
                  <Form.Label className="mb-0 mr-1">
                    {translatedMessage("ARTWORK.INVESTOR_PACKAGE")}
                  </Form.Label>
                  <Switch
                    onChange={(event) => this.onInvestorPackageChange(event)}
                    checked={this.state.data.hasInvestorPackage}
                    offColor="#F00"
                  />
                </div>
              </Form.Group>
            }
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1 ml-0" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default PricePackageModalComponent;