import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import moment from 'moment';
import { toast } from "react-toastify";

import { Map, GoogleApiWrapper } from 'google-maps-react';

import ApiService from "../../../../services/api.service";
import MapService from "../../../../services/map.service";
import { translatedMessage } from '../../../../services/language.service';

class EditLocationModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      location: this.props.location,
      polygon: null,
      hasGeofence: false,
    }

  }

  onMapReady = (props, map) => {
    let position = {
      lat: this.props.location.locationLat,
      lng: this.props.location.locationLng
    }

    let geofence = this.props.location.geofence;
    let name = this.props.location.name;
    let locationId = this.props.location.id;

    var marker = new props.google.maps.Marker();
    let polygon = MapService.getInitFencePolygon(props, map, false, true, true);

    // var infoWindow = new props.google.maps.InfoWindow({
    //   content: translatedMessage("MAP.GEOFENCE.INFO_EDIT")
    // });

    marker.setPosition(position);
    marker.setMap(map);
    marker.setTitle(name);
    marker.setIcon(this.props.icon);
    marker.setDraggable(true);

    if (geofence) {
      let geofenceCoordinates = JSON.parse(geofence)
      polygon.setPath(geofenceCoordinates)
      polygon.setVisible(true)
    }

    // infoWindow.setPosition({ lat: position.lat + 0.005, lng: position.lng });
    // infoWindow.open(map);

    map.setCenter(position);

    this.setState({
      location: {
        locationLat: marker.position.lat(),
        locationLng: marker.position.lng(),
        geofence: geofence,
        name: name,
        id: locationId
      },
      polygon: polygon,
      hasGeofence: geofence ? true : false,
    })

    marker.addListener('dragend', () => {
      this.setState({
        location: {
          ...this.state.location,
          locationLat: marker.position.lat(),
          locationLng: marker.position.lng()
        },
        marker: marker
      })
    });

    polygon.addListener('mouseover', () => {
      props.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        this.setState({
          location: {
            ...this.state.location,
            geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
          },
          polygon: polygon,
        })
      });

      props.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        this.setState({
          location: {
            ...this.state.location,
            geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
          },
          polygon: polygon,
        })
      });
    })    
  }

  addGeofence = () => {
    var location = {
      lat: this.state.location.locationLat,
      lng: this.state.location.locationLng
    };

    let initialCoordinates = MapService.getInitialPolygon(location);

    if (!(location.lat === 0 && location.lng === 0)) {
      let polygon = this.state.polygon;
      polygon.setPath(initialCoordinates)
      polygon.setVisible(true)

      this.setState({
        location: {
          ...this.state.location,
          geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
        },
        polygon: polygon,
        hasGeofence: true
      });
    }
  }

  deleteGeofence = () => {
    let polygon = this.state.polygon
    polygon.setVisible(false);

    this.setState({
      polygon: polygon,
      location: {
        ...this.state.location,
        geofence: null
      },
      hasGeofence: false
    })
  }

  validate = (location) => {
    var response = {
      isError: false,
      message: ""
    };

    if (location.locationLat) {
      const lat = location.locationLat;

      if (!isFinite(lat) || !(Math.abs(lat) <= 90)) {
        response.isError = true;
        response.message = translatedMessage("MAP.LATITUDE.VALUE_ERROR");
        return response;
      };
    }

    if (location.locationLng) {
      const lng = location.locationLng;

      if (!isFinite(lng) || !(Math.abs(lng) <= 180)) {
        response.isError = true;
        response.message = translatedMessage("MAP.LONGITUDE.VALUE_ERROR");
        return response;
      }
    }

    return response;
  }

  saveChanges = (event) => {
    event.preventDefault();
    const name = event.target.elements.locationName.value;
    const location = this.state.location;

    const validation = this.validate(location);

    if (!validation.isError) {
      new Promise((resolve, reject) => {
        if (location.id === 0) {
          resolve(ApiService.getAuthenticatedInstance().post(`/locations`, {
            name: name,
            locationLat: location.locationLat,
            locationLng: location.locationLng,
            geofence: location.geofence,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          }));
        } else {
          resolve(ApiService.getAuthenticatedInstance().patch(`/locations/${location.id}`, {
            name: name,
            locationLat: location.locationLat,
            locationLng: location.locationLng,
            geofence: location.geofence,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          }));
        }
      })
        .then((result) => {
          const parts = result.data._links.self.href.split('/');
          const id = parts[parts.length - 1];
          this.setState({
            location: {
              ...this.state.location,
              id: id
            }
          })
          if (location.id === 0) {
            return ApiService.getAuthenticatedInstance().post(`/entityLocations`, {
              location: `/locations/${id}`,
              entity: {
                entityId: this.props.entityId,
                entityName: this.props.entityName
              },
              lastUpdatedBy: ApiService.getCurrentUser().username,
              lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            });
          }
        })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.props.onHide(true);
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      toast.error(validation.message);
    }
  };

  render() {
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="ca-map-modal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("MAP.LOCATION")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Group controlId="locationName">
              <Form.Label className="editmodal-userinfo-title">{translatedMessage("LOCATION.NAME")} *</Form.Label>
              <Form.Control
                name="locationName"
                placeholder={translatedMessage("LOCATION.NAME_DESC")}
                defaultValue={this.props.location.name}
                required />
            </Form.Group>
          </Form>

          <Map google={this.props.google} zoom={14} streetViewControl={false}
            onReady={this.onMapReady} containerStyle={{ width: "calc(100% - 32px)", height: "calc(100% - 100px)" }} >
          </Map>
        </Modal.Body>
        <Modal.Footer>
          <Button className="ca-button mr-1" variant="dark" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>

          <Button className={"ca-button mr-1 ".concat(this.state.hasGeofence ? "ca-hide-button" : "")}
            onClick={this.addGeofence}>{translatedMessage("MAP.GEOFENCE.ADD")}</Button>

          <Button className={"ca-button mr-1 ".concat(!this.state.hasGeofence ? "ca-hide-button" : "")}
            onClick={this.deleteGeofence}>{translatedMessage("MAP.GEOFENCE.DELETE")}</Button>

          <Button className="ca-button ca-button-white" variant="dark" onClick={() => this.props.onHide(false)}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    )
  }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(EditLocationModal);