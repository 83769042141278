import React from 'react';

import back from '../../../components/shared/Resources/left-arrow.svg';
import extendedExperience from '../../../components/shared/Resources/homepage/extendedExperience.svg';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import { Link } from 'react-router-dom'

import { Row, Col, Dropdown, Form, InputGroup, Image } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';
import ArtworkService from '../../../services/artwork.service';
import EntityArtCardComponent from '../EntityArt/EntityArtCardComponent';
import ParkService from '../../../services/park.service';

const ENTER_KEY = 13;

class ParkArtComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      park: {},
      searchInput: '',
      artList: [],
      artworkCategoryList: [],
      filteredArtworks: [],
      selectedCategory: { id: "0", code: "GENERAL.CATEGORY.ANY" },
      selectedType: "GENERAL_TYPE.ANY",
      typeList: ["GENERAL_TYPE.ANY", "ARTWORK", "AR_EXPERIENCE", "VIRTUAL_GALLERY"],
      isLoaded: false,
      membership: {},
      errorMessage: '',
      isError: false
    };
  }

  componentDidMount = () => {
    this.getArtworks();
  }

  getArtworks = () => {
    let _artworkCategoryList = [];
    let _artList = [];
    let _park = {};
    let promise = [];

    promise.push(ArtworkService.getArtworkCategoryList()
      .then(response => {
        _artworkCategoryList = response;
        _artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
      }))

    let criteria = {};
    criteria.sortOrder = "ASC";
    criteria.sortField = "ae.name";
    criteria.pageSize = -1;
    criteria.parkId = this.props.parkId

    promise.push(ParkService.getParkArtList(criteria)
      .then(response => {
        // console.log(response.artList)
        _artList = response.artList
        _park = response.park
      })
    )

    Promise.all(promise).then(() => {
      this.setState({
        park: _park,
        artList: _artList,
        filteredArtworks: _artList,
        artworkCategoryList: _artworkCategoryList,
        isLoaded: true
      })
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  handleOnTypeSelect = (eventKey, syntheticEvent) => {
    const selectedItem = this.state.typeList.filter(item => item === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artList;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;
    let typeItem = this.state.selectedType;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artList.filter(elem => elem.artName.toLowerCase().includes(searchInput.toLowerCase())
        || (elem.author && elem.author.toLowerCase().includes(searchInput.toLowerCase())));
    }

    if (typeItem !== "GENERAL_TYPE.ANY") {
      result = result.filter(elem => elem.entityName && elem.entityName === typeItem);
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.category && elem.category === categoryItem.code);
    }

    this.setState({
      filteredArtworks: result
    })
  }

  render() {
    const _artList = this.state.filteredArtworks;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container mt-0">
          <Row className="ca-page-title-button-row align-items-center" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className="ca-page-title-container d-flex align-items-center">
              <Image src={extendedExperience} className="ca-page-title-icon" alt={this.state.park?.name} />
              <span className="ca-page-title">
                {this.state.park?.name}
              </span>
            </div>

            <div className="ca-page-title-button-row-buttons">
              <Link to={this.props.backUrl} className="ca-dark-link">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <InputGroup className="ca-form-input-group">
                <Form.Control value={this.state.searchInput} type="text"
                  onChange={this.handleSearchInput}
                  onKeyDown={this.handleKeyDown}
                  placeholder={translatedMessage("SEARCH.BY.NAME_AUTHOR")} />
                <InputGroup.Append>
                  <InputGroup.Text>&#xF002;</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element}>
                        {translatedMessage(element)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="card-columns">
            {_artList.map((element, index) => {
              let link = ""
              let id = 0
              let entity = {}

              if (element.artwork) {
                id = element.artwork.id;
                link = `/artwork/${element.artwork.id}`;
                entity = element.artwork
              } else if (element.arExperience) {
                id = element.arExperience.id
                link = `/arExperience/${element.arExperience.id}`;
                entity = element.arExperience
              } else if (element.virtualGallery) {
                id = element.virtualGallery.id
                link = `/virtualGalleryDetails/${element.virtualGallery.id}`;
                entity = element.virtualGallery
              }
              return (
                <EntityArtCardComponent
                  key={index}
                  entity={entity}
                  entityName={element.entityName}
                  cardImage={element.imageURL}
                  cardTitle={element.artName}
                  cardAuthor={element.author}
                  cardCategory={element.category}
                  cardId={id}
                  cardLink={link}
                />
              )
            })}
          </div>

        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history}/>
      )
    }
  }

}

export default ParkArtComponent;