import React from 'react';

import { Row, Col, Image, Button } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import { Link } from 'react-router-dom';
import { translatedMessage } from '../../../services/language.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import company from '../../../components/shared/Resources/icons/company/company.svg';
import users from '../../../components/shared/Resources/icons/company/team.svg';
import artists from '../../../components/shared/Resources/icons/company/artists.svg';
import map_pin from '../../../components/shared/Resources/icons/company/map_pin.svg';
import space from '../../../components/shared/Resources/icons/company/space.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import createArt from '../../../components/shared/Resources/homepage/createArt.svg';
import portfolio from '../../../components/shared/Resources/icons/portfolio.svg';

class CompanyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      isNew: false,
      errorMessage: "",
    };

  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        this.setState({
          company: response.data.company,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={company} className="ca-page-title-icon" alt={translatedMessage("ARTWORK.PORTFOLIO")} />
              <span className="ca-page-title">{this.state.company.name}</span>
            </div>
            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-companies`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="mt-5">
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
              <Link to={`/my-company/${this.state.company.id}/edit`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={company} className="ca-homepage-icon" alt={translatedMessage("COMPANY.CHANGE_DETAILS")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("COMPANY.CHANGE_DETAILS")}</div>
                </div>
              </Link>
            </Col>

            {this.state.company.status.name === "ACTIVE" &&
              <>
                <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/my-company/${this.state.company.id}/space`} className="ca-dark-link">
                    <div className="ca-homepage-icon-container">
                      <Image src={space} className="ca-homepage-icon" alt={translatedMessage("COMPANY.SPACE")} />
                      <div className="ca-homepage-icon-title">{translatedMessage("COMPANY.SPACE")}</div>
                    </div>
                  </Link>
                </Col>

                <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/my-company/${this.state.company.id}/user`} className="ca-dark-link">
                    <div className="ca-homepage-icon-container">
                      <Image src={users} className="ca-homepage-icon" alt={translatedMessage("COMPANY.TEAM")} />
                      <div className="ca-homepage-icon-title">{translatedMessage("COMPANY.TEAM")}</div>
                    </div>
                  </Link>
                </Col>

                <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/my-company/${this.state.company.id}/address`} className="ca-dark-link">
                    <div className="ca-homepage-icon-container">
                      <Image src={map_pin} className="ca-homepage-icon" alt={translatedMessage("ADDRESS.ADDRESSES")} />
                      <div className="ca-homepage-icon-title">{translatedMessage("ADDRESS.ADDRESSES")}</div>
                    </div>
                  </Link>
                </Col>

                <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/my-company/${this.state.company.id}/artist`} className="ca-dark-link">
                    <div className="ca-homepage-icon-container">
                      <Image src={artists} className="ca-homepage-icon" alt={translatedMessage("COMMUNITY.CREATIVE_COMMUNITY")} />
                      <div className="ca-homepage-icon-title">{translatedMessage("COMMUNITY.CREATIVE_COMMUNITY")}</div>
                    </div>
                  </Link>
                </Col>

                <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/my-company/${this.state.company.id}/portfolio`} className="ca-dark-link">
                    <div className="ca-homepage-icon-container">
                      <Image src={portfolio} className="ca-homepage-icon" alt={translatedMessage("ARTWORK.PORTFOLIO")} />
                      <div className="ca-homepage-icon-title">{translatedMessage("ARTWORK.PORTFOLIO")}</div>
                    </div>
                  </Link>
                </Col>

                <Col className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5">
                  <Link to={`/my-company/${this.state.company.id}/project/create`} className="ca-dark-link">
                    <div className="ca-homepage-icon-container">
                      <Image src={createArt} className="ca-homepage-icon" alt={translatedMessage("CREATE_ART")} />
                      <div className="ca-homepage-icon-title">{translatedMessage("CREATE_ART")}</div>
                    </div>
                  </Link>
                </Col>
              </>
            }
          </Row>
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyPage;