import React from 'react';

import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { translatedMessage } from '../../../services/language.service';

class GalleryViewArtworkCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  handleChange = (event) => {
    const input = event.currentTarget.querySelector('input')
    this.setState({
      checked: !input.checked
    })
    this.props.onCheck(input.id, !input.checked);
  }

  render() {
    return (
      <Card className="ca-artwork-card">
        <Link to={{pathname: this.props.link, state: {companyId: this.props.companyId}}}>
          <Card.Img variant="top" src={this.props.image} />
          <Card.Body>
            <Card.Title>
              {this.props.title}
              <span className="font-weight-normal" style={{ fontSize: "15px" }}>
                {this.props.author &&
                  " " + translatedMessage("GENERAL.BY").toLowerCase() + " " + this.props.author
                }
              </span>
            </Card.Title>
            {this.props.entity &&
              <Card.Text className="font-bold">{translatedMessage(this.props.entity.entityName)}</Card.Text>
            }
            {this.props.category &&
              <Card.Text>{translatedMessage("GENERAL.CATEGORY") + ": " + translatedMessage(this.props.category)}</Card.Text>
            }
            {this.props.type &&
              <Card.Text>{translatedMessage("GENERAL.TYPE") + ": " + translatedMessage(this.props.type)}</Card.Text>
            }            
          </Card.Body>
        </Link>
      </Card>
    )
  }

}

export default GalleryViewArtworkCardComponent;