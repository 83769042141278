import React from 'react';

import CardComponent from "./Components/CardComponent";

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import addIcon from '../../components/shared/Resources/icons/add.svg';
import calogo from '../../components/shared/Resources/logo-dark-small-with-padding.png'

import ApiService from '../../services/api.service';
import { translatedMessage } from '../../services/language.service';
import ArExperienceService from '../../services/arExperience.service';
import { hasPermission } from '../../services/api.service';
import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';
import { toast } from "react-toastify";

const ENTER_KEY = 13;

class ArExperienceListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arExperiences: null,
            statusList: [],
            categoryList: [],
            selectedStatus: { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" },
            selectedCategory: { label: "GENERAL.CATEGORY.ANY", name: "ANY" },
            filteredResults: [],
            searchInput: '',
            isLoaded: false,
            isError: false,
            errorMessage: ""
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount = () => {
        ApiService.initCurrentUser().then(result => {
            this.setState({
                canViewList: hasPermission("AR_EXPERIENCE_LIST"),
                canDeleteItem: hasPermission("AR_EXPERIENCE_DELETE")
            }, this.getData)
        });
    }

    async getData() {
        if (this.state.canViewList) {
            let arExperiences = await ApiService.getAuthenticatedInstance().get(`/arExperience/arExperienceList`)
                .then(response => {
                    let data = response.data
                    data.map((elem, i) => {
                        elem.imageURL = elem.thumbnailUuid ? ApiService.getFileByUuid(elem.thumbnailUuid) + '?' + Date.now() : calogo;
                        return elem;
                    });

                    return data
                });

            const statusList = await ArExperienceService.getArExperienceStatusList()
                .then(result => {
                    result.splice(0, 0, { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" })
                    return result
                })

            const categoryList = await ArExperienceService.getArtCategoryList()
                .then(result => {
                    result.splice(0, 0, { label: "GENERAL.CATEGORY.ANY", name: "ANY" })
                    return result;
                })

            this.setState({
                arExperiences: arExperiences,
                filteredResults: arExperiences,
                statusList: statusList,
                categoryList: categoryList,
                isLoaded: true
            })
        } else {
            this.setState({
                isError: true,
                errorMessage: "USER.SECURITY.MISSING_RIGHTS",
                isLoaded: true
            })
        }
    }

    handleSearchInput = (e) => {
        this.setState({
            searchInput: e.target.value,
        }, this.filter);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.setState({
                searchInput: e.target.value,
            }, this.filter);
        }
    }

    handleOnStatusSelect = (eventKey) => {
        const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
        this.setState({
            selectedStatus: selectedItem[0]
        }, this.filter)
    }

    handleOnCategorySelect = (eventKey) => {
        const selectedItem = this.state.categoryList.filter(item => item.name === eventKey);

        this.setState({
            selectedCategory: selectedItem[0]
        }, this.filter)
    }

    filter = () => {
        let result = this.state.arExperiences;
        let searchInput = this.state.searchInput;
        let statusItem = this.state.selectedStatus.name;
        let categoryItem = this.state.selectedCategory.name;

        if (0 !== searchInput.replace(/ /g, ";").length) {
            result = this.state.arExperiences.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
        }

        if (categoryItem !== "ANY") {
            result = result.filter(elem => elem.category.name === categoryItem);
        }

        if (statusItem !== 'ANY') {
            result = result.filter(elem => elem.status.name === statusItem);
        }

        this.setState({
            filteredResults: result
        })
    }

    deleteArExperience = (id) => {
        if (window.confirm(translatedMessage("AR_EXPERIENCE.DELETE.CONFIRM"))) {
            ApiService.getAuthenticatedInstance().post(`/arExperience/deleteArExperience/${id}`)
                .then((response) => {
                    if (response.data.status === "OK") {
                        toast.success(translatedMessage("AR_EXPERIENCE.DELETE.SUCCESS"));

                        let arExperiences = this.state.arExperiences.filter(item => item.id !== id);
                        let filteredResults = this.state.filteredResults.filter(item => item.id !== id);

                        this.setState({
                            arExperiences: arExperiences,
                            filteredResults: filteredResults,
                        })                                                
                    } else {
                        toast.error(translatedMessage(response.data.message));
                    }
                })
                .catch(err => {
                    toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                    console.log("An error has ocurred: " + err);
                });
        }
    }

    render() {
        const arExperiences = this.state.filteredResults;
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else if (!this.state.isError) {
            return (
                <div className="ca-page-padding ca-main-container">
                    <Row className="ca-page-title-button-row mb-3 pr-2">
                        <span className="ca-page-title">{translatedMessage("AR_EXPERIENCE.AR_EXPERIENCES")}</span>

                        {!hasPermission("AR_EXPERIENCE_CREATE") ? "" :
                            <Button onClick={() => this.props.history.push(`/arExperienceEdit`)} className="ca-dark-link ca-button-icon medium">
                                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} title={translatedMessage("GENERAL.ADD_NEW")} />
                            </Button>
                        }
                    </Row>

                    <Row className="d-flex align-items-center">
                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                                onChange={this.handleSearchInput}
                                onKeyDown={this.handleKeyDown} />
                        </Col>

                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedCategory.label)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.categoryList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                {translatedMessage(element.label)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedStatus.label)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.statusList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                {translatedMessage(element.label)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <div className="card-columns">
                        {arExperiences.map((element, index) => {
                            var canBeDeleted = this.state.canDeleteItem && element.status.name === ArExperienceService.statusEnum().UNPUBLISHED ? true : false;
                            return (
                                <CardComponent
                                    key={index}
                                    id={element.id}
                                    image={element.imageURL}
                                    title={element.name}
                                    category={translatedMessage(element.category.label)}
                                    status={translatedMessage(element.status.label)}
                                    canBeDeleted={canBeDeleted}
                                    onDelete={this.deleteArExperience}
                                />
                            )
                        })}
                    </div>

                </div >
            )
        } else {
            return (
                <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
            )
        }
    }
}

export default ArExperienceListPage;