import ApiService from './api.service';

import profilepic from '../components/shared/Resources/defaultProfilePicture.png';

const UserService = {
  getType: (userDetail) => {
    let types = ["artist", "investor", "buyer", "expert", "institution"];
    let result = "";
    types.forEach((type) => {
      if (userDetail[type]) {
        if (result !== "") {
          result += "; ";
        }
        result += type;
      }
    });
    return result;
  },

  getMyProfile: () => { return ApiService.getAuthenticatedInstance().get(`/user/myProfile`); },

  getMembershipList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`membership/list_all`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getMembershipListForUserCreate: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`membership/list_all`)
        .then(response => {
          var membershipList = response.data.filter(item => item.annualPrice === 0 || item.monthlyPrice === 0)
          membershipList = membershipList.sort((a, b) => {
            var result;
            a.id <= b.id ? result = -1 : result = 1
            return result;
          });

          resolve(membershipList)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getRoleList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`user/role/list`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getUserList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`user/list`)
        .then(response => {
          var users = response.data.users;
          users.sort((a, b) => {
            var result;
            a.fullName.toUpperCase() <= b.fullName.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(users)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getArtistList: (page, pageSize) => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`user/profile/list?page=${page}${pageSize ? "&size=" + pageSize : ""}&userType=ARTIST`)
        .then(response => {
          var profiles = response.data;

          profiles.map(item => {
            item.profilePic = item.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${item.photoUuid}` : profilepic;

            return item;
          })

          profiles.sort((a, b) => {
            var result;
            a.displayName.toUpperCase() <= b.displayName.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(profiles)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  validateAddress(address) {
    var isAddressValid = true;

    if (address.address !== "" || address.city !== "" || address.countryId.toString() !== '0') {
      if (address.address === "" || address.city === "" || address.countryId.toString() === '0') {
        isAddressValid = false;
      }
    }

    return isAddressValid
  },

  doSaveAddress(address) {
    return new Promise((resolve, reject) => {
      if (!this.validateAddress(address)) {
        reject();
        return;
      }

      if (address.address !== "" && address.city !== "" && address.countryId.toString() !== '0') {
        if (!address.id) {
          ApiService.getAuthenticatedInstance().post(`/addresses`, {
            address: address.address.trim(),
            country: `/countries/${address.countryId}`,
            city: address.city.trim(),
            zip: address.zip.trim(),
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: Date.now(),
          }).then(resolve)
        } else {
          ApiService.getAuthenticatedInstance().patch(`/addresses/${address.id}`, {
            address: address.address.trim(),
            country: `/countries/${address.countryId}`,
            city: address.city.trim(),
            zip: address.zip.trim(),
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: Date.now(),
          }).then(resolve)
        }
      } else if (address.address === "" && address.city === "" && address.countryId.toString() === '0' && address.zip === "") {
        resolve(-1);
      } else {
        resolve();
      }
    })
  },

  deleteAccount() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/user/delete-account`)
        .then(() => {
          resolve("USER.DELETE.SUCCESS")
        })
        .catch(err => {
          reject(err);
        });
    })
  }

};

export default UserService;