import React from 'react';

import { Row, Col, Form, Image, Button, Dropdown, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import Switch from "react-switch";

import { toast } from "react-toastify";

import createArtIconWhite from '../../components/shared/Resources/icons/artwork_white.svg';
import saveIcon from '../../components/shared/Resources/icons/save.svg';
import submitIcon from '../../components/shared/Resources/icons/submit.svg';

import ErrorComponent from '../../components/shared/ErrorComponents/GenericErrorComponents';

import { translatedMessage } from '../../services/language.service';
import ParkService from '../../services/park.service';
import ApiService from '../../services/api.service';
import ArtworkManageModalComponent from '../../components/shared/Park/ArtworkManageModalComponent';

class ParkApplyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      park: { id: 1 },
      application: {
        park: { id: 1 },
        themePark1: null,
        themePark2: null,
        themePark3: null,
        agreeUpdatedProfile: false,
        agreeJoinCompetition: false,
        agreeTc: false,
        applicationArtList: []
      },
      applicationArtList: [],
      selectedArtwork: null,
      themeParkList: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      searchInput: '',
      onlyView: false,
      showArtworkModal: false,
    };

  }

  componentDidMount = () => { this.getApplication() }

  getApplication = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ParkService.getParkApplication(this.state.park.id)
      .then(response => {
        let onlyView = response.status === 'SUBMITTED'

        let _application = response?.id ? response : this.state.application

        let artList = [{ id: null, disabled: false }, { id: null, disabled: true }, { id: null, disabled: true }]
        if (_application && _application.applicationArtList.length > 0) {
          _application.applicationArtList.forEach((item, index) => {
            artList[index] = { ...item, disabled: item.id ? false : onlyView };
            if (index + 1 < artList.length && artList[index + 1]?.id === null && !onlyView) {
              artList[index + 1] = { ...artList[index + 1], disabled: false };
            }
          })
        }

        this.setState({
          application: { ..._application, applicationArtList: artList },
          applicationArtList: artList,
          onlyView: onlyView
        }, this.getThemeParks)
      })
      .catch(err => {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  getThemeParks = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    let criteria = this.computeCriteria();

    ParkService.getParkList(criteria)
      .then(response => {
        this.setState({
          themeParkList: response.items,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  computeArtworkList = () => {
    let artList = [{ id: null, disabled: false }, { id: null, disabled: true }, { id: null, disabled: true }]
    if (this.state.application.applicationArtList.length > 0) {
      let _lastIndex = -1
      this.state.application.applicationArtList.forEach((item, index) => {
        artList[index] = { ...item, disabled: item.id ? false : true }
        _lastIndex = item.id ? index : _lastIndex
      })

      if (_lastIndex === -1 || (_lastIndex !== -1 && _lastIndex + 1 < artList.length && !this.state.onlyView)) {
        artList[_lastIndex + 1] = { ...artList[_lastIndex + 1], disabled: false }
      }
    }

    this.setState({
      applicationArtList: artList
    })
  }

  computeCriteria = () => {
    let criteria = {};
    criteria.parentId = this.state.park.id;
    return criteria;
  };

  onSwitchChange = (event, key) => {
    this.setState({
      application: {
        ...this.state.application,
        [key]: event,
      }
    });
  }

  handleOnParkSelect = (eventKey, key) => {
    const selectedItem = this.state.themeParkList.filter(item => item.id === parseInt(eventKey));

    this.setState({
      application: {
        ...this.state.application,
        [key]: selectedItem[0]
      }
    })
  }

  validate = (event, isApply = false) => {
    var response = {
      isError: false,
      message: ""
    };

    if (isApply && this.state.application.applicationArtList.length === 0) {
      response.isError = true;
      response.message = translatedMessage("PARK.APPLICATION.CALL.ART.REQUIRED");
      return response;
    }

    if (isApply && !this.state.application.agreeTc) {
      response.isError = true;
      response.message = translatedMessage("PARK.APPLICATION.CALL.AGREE.TC.REQUIRED");
      return response;
    }

    if (isApply && (this.state.application.applicationArtList.length === 0 || !this.state.application.applicationArtList[0].id)) {
      response.isError = true;
      response.message = translatedMessage("PARK.APPLICATION.CALL.ARTWORK.REQUIRED");
      return response;
    }

    return response;
  }

  handleApply = (event) => {
    if (window.confirm(translatedMessage("PARK.APPLICATION.CALL.CONFIRM"))) {
      const validation = this.validate(event, true);
      if (!validation.isError) {
        this.handleSubmit(event, true)
      } else {
        toast.error(validation.message, { autoClose: 7000, pauseOnHover: true });
      }
    }
  }

  handleSubmit = (event, isApply = false) => {
    event.preventDefault();
    const validation = this.validate(event);
    if (!validation.isError) {
      const application = {
        ...this.state.application,
        status: isApply ? 'SUBMITTED' : this.state.application.status,
        user: ApiService.getCurrentUser()
      }

      ParkService.saveParkApplication(application)
        .then((response) => {
          this.setState({
            application: response,
            onlyView: response.status === 'SUBMITTED'
          }, this.computeArtworkList)

          toast.success(translatedMessage(response.status === 'SUBMITTED' ? "PARK.APPLICATION.CALL.SUBMIT.SUCCESS" : "GENERAL.SAVE_SUCCESS"), { autoClose: 7000, pauseOnHover: true });
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })

    } else {
      toast.error(validation.message);
    }
  }

  manageArtwork = (artwork) => {
    this.setState({
      selectedArtwork: {
        ...artwork,
        storageItem: artwork?.id ? { id: artwork.storageItemId } : null,
        parkArtistApplication: this.state.application
      },
      showArtworkModal: true,
    })
  }

  onArtworkModalHide = (application, artwork, isSave = false) => {
    this.setState({
      application: isSave ? application : { ...this.state.application },
      showArtworkModal: false,
    }, this.computeArtworkList)
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <>
          <div className="ca-page-padding ca-main-container">
            <div className="d-flex align-items-center ca-page-title mb-3">
              {translatedMessage("PARK.APPLICATION.CALL.TITLE")}
            </div>
            <div>
              <Form id="application-form" className="ca-form" onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} lg="6" className="mb-0 pl-0">
                    <Form.Row>
                      <Form.Group controlId="agreeUpdatedProfile" as={Col} xs="12" className="pl-0">
                        <div className="d-flex align-items-center">
                          <Switch
                            onChange={(event) => this.onSwitchChange(event, "agreeUpdatedProfile")}
                            checked={this.state.application.agreeUpdatedProfile}
                            offColor="#F00"
                            disabled={this.state.onlyView}
                          />
                          <Form.Label className="mb-0 ml-1">
                            {translatedMessage("PARK.APPLICATION.CALL.AGREE.PROFILE_UPDATED")}
                            <OverlayTrigger
                              key='agree-profile'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-agree-profile`} className="ca-tooltip">
                                  {translatedMessage("PARK.APPLICATION.CALL.AGREE.PROFILE_UPDATED.INFO")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </Form.Label>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="agreeJoinCompetition" as={Col} xs="12" className="pl-0">
                        <div className="d-flex align-items-center">
                          <Switch
                            onChange={(event) => this.onSwitchChange(event, "agreeJoinCompetition")}
                            checked={this.state.application.agreeJoinCompetition}
                            offColor="#F00"
                            disabled={this.state.onlyView}
                          />
                          <Form.Label className="mb-0 ml-1">
                            {translatedMessage("PARK.APPLICATION.CALL.AGREE.JOIN_OPEN_CALL_CONTEST")}
                            <OverlayTrigger
                              key='agree-contest'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-agree-contest`} className="ca-tooltip">
                                  {translatedMessage("PARK.APPLICATION.CALL.AGREE.JOIN_OPEN_CALL_CONTEST.INFO")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          </Form.Label>
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} xs="12" controlId="park" className="mt-3">
                        <Form.Label>
                          {translatedMessage("PARK.APPLICATION.CALL.PARK.JOIN.LABEL")}
                          <OverlayTrigger
                              key='theme-park'
                              placement='bottom'
                              overlay={
                                <Tooltip id={`tooltip-theme-park`} className="ca-tooltip">
                                  {translatedMessage("PARK.APPLICATION.CALL.PARK.JOIN.INFO")}
                                </Tooltip>
                              }
                            >
                              <span className="ca-question-icon"></span>
                            </OverlayTrigger>
                          <div className='font-weight-normal'>{translatedMessage("PARK.APPLICATION.CALL.PARK.JOIN.LABEL.DETAILS")}</div>
                        </Form.Label>

                        <Dropdown className="ca-dropdown mb-3" onSelect={(eventKey) => this.handleOnParkSelect(eventKey, "themePark1")}>
                          <Dropdown.Toggle disabled={this.state.onlyView}>
                            {this.state.application.themePark1 ? this.state.application.themePark1.name : translatedMessage("PARK.APPLICATION.CALL.PARK.SELECT.PLACEHOLDER")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {this.state.themeParkList.map((element, index) => {
                              return (
                                <Dropdown.Item key={index} eventKey={element.id}>
                                  {element.name}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="ca-dropdown mb-3" onSelect={(eventKey) => this.handleOnParkSelect(eventKey, "themePark2")}>
                          <Dropdown.Toggle disabled={this.state.onlyView || !this.state.application.themePark1}>
                            {this.state.application.themePark2 ? this.state.application.themePark2.name : translatedMessage("PARK.APPLICATION.CALL.PARK.SELECT.PLACEHOLDER")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {this.state.themeParkList.map((element, index) => {
                              return (
                                <Dropdown.Item key={index} eventKey={element.id}>
                                  {element.name}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown className="ca-dropdown" onSelect={(eventKey) => this.handleOnParkSelect(eventKey, "themePark3")}>
                          <Dropdown.Toggle disabled={this.state.onlyView || !this.state.application.themePark2}>
                            {this.state.application.themePark3 ? this.state.application.themePark3.name : translatedMessage("PARK.APPLICATION.CALL.PARK.SELECT.PLACEHOLDER")}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {this.state.themeParkList.map((element, index) => {
                              return (
                                <Dropdown.Item key={index} eventKey={element.id}>
                                  {element.name}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                  <Form.Group as={Col} lg="6" className="mb-0 pl-0">
                    <Row className='mb-1'>
                      <Col>
                        <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.INFO")}</Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.applicationArtList.map((item, index) =>
                        <Col key={index} className="col-12 col-sm-4">
                          <Card className="ca-card park-call-apply-art-card"
                            bg={item.disabled ? 'disabled' : 'dark'}
                            text={item.disabled ? 'disabled' : 'white'}
                            onClick={() => item.disabled ? false : this.manageArtwork(item)}
                            style={{ cursor: item.disabled ? "auto" : "pointer" }}>

                            <Card.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis', minHeight: "150px" }}
                              className="d-flex flex-column align-items-center justify-content-center text-center">

                              <Image src={createArtIconWhite} className="mb-1" alt={translatedMessage("PARK.APPLICATION.CALL.ART.ADD")} />
                              <div className="w-100 text-center" style={{ fontSize: "24px" }}>
                                {item?.id ? item.name : translatedMessage("PARK.APPLICATION.CALL.ART.ADD")}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} lg="6" className="mb-0 pl-0">
                    <Form.Group controlId="agreeTV" as={Col} xs="12" className="pl-0">
                      <Form.Label>
                        {translatedMessage("PARK.APPLICATION.CALL.TC")}
                      </Form.Label>

                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <Switch
                            onChange={(event) => this.onSwitchChange(event, "agreeTc")}
                            checked={this.state.application.agreeTc}
                            offColor="#F00"
                            disabled={this.state.onlyView}
                          />

                          <div className="mb-0 ml-1">
                            {translatedMessage("PARK.APPLICATION.CALL.AGREE.TC")}
                          </div>
                        </div>

                        <div className='mt-2 ca-link_underline'>
                          <a href="https://www.connected.art/tc/tou.php" target="_blank" rel="noopener noreferrer">
                            {translatedMessage("TERMS.TOU")}
                          </a>
                        </div>
                        <div className='mt-1 ca-link_underline'>
                          <a href="https://www.connected.art/tc/tos.php" target="_blank" rel="noopener noreferrer">
                            {translatedMessage("TERMS.TOS")}
                          </a>
                        </div>
                        <div className='mt-1 ca-link_underline'>
                          <a href="https://www.connected.art/tc/tpoc.php" target="_blank" rel="noopener noreferrer">
                            {translatedMessage("TERMS.TPOC")}
                          </a>
                        </div>
                      </div>
                    </Form.Group>
                  </Form.Group>
                </Form.Row>
              </Form>

              <div className="d-flex align-items-center justify-content-start mt-3">
                <Button className={"ca-dark-link ca-button-icon mr-1 "} type="submit" form="application-form" disabled={this.state.onlyView}>
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
                </Button>
                <Button className={"ca-dark-link ca-button-icon mr-1 "} onClick={this.handleApply} disabled={this.state.onlyView}>
                  <Image src={submitIcon} alt={translatedMessage("PARK.APPLICATION.CALL.SUBMIT")} title={translatedMessage("PARK.APPLICATION.CALL.SUBMIT")} />
                </Button>
              </div>
              <div className='mt-3 ca-link_underline'>
                <span>
                  {translatedMessage("PARK.APPLICATION.CONTACT_US")} &nbsp;
                  <a href="mailTo:hello@connected.art" className='text'>{translatedMessage("GENERAL.HERE")}.</a>
                </span>
              </div>
            </div>
          </div >

          {this.state.showArtworkModal &&
            <ArtworkManageModalComponent
              show={this.state.showArtworkModal}
              onHide={this.onArtworkModalHide}
              artwork={this.state.selectedArtwork}
              onlyView={this.state.onlyView}
            />
          }
        </>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ParkApplyPage;