import ApiService from './api.service';

const EntityService = {

  getEntityAccessTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/entityAccessTypeList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },  

}

export default EntityService;