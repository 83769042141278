import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap/';

import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import TableComponent from './Components/TableComponent/TableComponent';

class CompanyListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      canList: false,
      canEdit: false,
      companyList: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      exportData: [],
      exportHeaders: []
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canList: hasPermission("COMPANY_LIST"),
        canEdit: hasPermission("COMPANY_EDIT"),
      }, this.getData)
    });
  }

  async getData() {
    if (this.state.canList) {
      const companyList = await CompanyService.getAllCompanies()
        .then(response => {
          return response
        })

      this.setState({
        companyList: companyList,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <span className="ca-page-title">{translatedMessage("COMPANY.COMPANIES")}</span>

            {!this.state.canEdit ? "" :
              <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={() => this.props.history.push(`/company-admin/edit`)}>
                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD_NEW")} />
              </Button>
            }
          </Row>

          {this.state.companyList.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("COMPANY.NO_COMPANIES")}
                  </span>
                </Col>
              </Row>
            </div>
          }

          {this.state.companyList.length > 0 &&
            <div>
              <TableComponent
                data={this.state.companyList}
                sent={true}
              />
            </div>
          }
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default CompanyListPage;