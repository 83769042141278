import React from 'react';

import { Modal, Button, Image } from 'react-bootstrap/';

import mobileIcon from '../Resources/icons/mobile.svg';

import { translatedMessage } from '../../../services/language.service';
import ApiService from '../../../services/api.service';

class ChallengeDetailsModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editorButtns: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
            notification: ""
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                            {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: this.props.details }} />

                    <div className="ca-description-value font-bold mt-5">
                        <Image src={mobileIcon} alt={translatedMessage("GENERAL.ADD_NEW")} className='ca-icon'/>
                        {translatedMessage("CHALLENGE.CHECK_MOBILE_APP")}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button ca-button-white" variant="dark" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ChallengeDetailsModalComponent