import React from 'react';

import { Card, Image } from 'react-bootstrap';
import { translatedMessage } from '../../../services/language.service';

import challengeIcon from '../../../components/shared/Resources/icons/challenge.svg';

class ChallengeImageCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  componentDidMount = () => {
    this.setState({
      checked: this.props.isWinner
    })
  }

  handleChange = (event) => {
    const input = event.currentTarget.querySelector('input')
    this.setState({
      checked: !input.checked
    })
    this.props.onCheck(input.id, !input.checked);
  }

  render() {
    return (
      <Card className="ca-artwork-card">
        <Card.Img variant="top" src={this.props.image} onClick={() => this.props.onClick()} />
        <Card.Footer style={{padding: "3px 10px 0px 10px"}}>
          {!this.props.isSelectWinner && this.props.isWinner &&
            <Image src={challengeIcon} style={{height: "30px"}}/>
          }
          {!this.props.isSelectWinner && !this.props.isWinner &&
            <div style={{height: "30px"}}></div>
          }          
          {this.props.isSelectWinner &&
            <div className='d-flex align-items-center justify-content-between'>
              <div className="ca-check-container" onClick={this.handleChange}>
                <input
                  id={this.props.id}
                  checked={this.state.checked}
                  type="checkbox"
                  className="d-flex align-items-center"
                  onChange={this.handleChange}
                />
                <span className="ca-check-label">{translatedMessage("CHALLENGE.WINNER.SELECT")}</span>
                <span className="ca-checkmark"></span>
              </div>
            </div>
          }
        </Card.Footer>
      </Card>
    )
  }

}

export default ChallengeImageCardComponent;