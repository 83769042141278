import React from 'react';

import myPortfolio from '../../components/shared/Resources/homepage/myPortfolio.svg';
import myProjects from '../../components/shared/Resources/homepage/myProjects.svg';
import back from '../../components/shared/Resources/left-arrow.svg';

import CardComponent from "../../components/shared/Artwork/ArtworkCardEditComponent";

import DisplayInPortofolioModal from './Components/DisplayInPortofolioModal/DisplayInPortofolioModal';

import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from '../../services/api.service';
import ArtworkService from '../../services/artwork.service';
import { translatedMessage } from '../../services/language.service';

import { toast } from "react-toastify";

const ENTER_KEY = 13;

class ArtworksPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connectedUser: null,
            showDisplayInPortofolioModal: false,
            cardIdInModal: null,
            artworks: [],
            statusList: [],
            categoryList: [],
            selectedStatus: { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" },
            selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
            filteredResults: [],
            searchInput: '',
            isLoaded: false
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount = () => {
        this.getArtworks();
    }

    async getArtworks() {
        let artworks = await ApiService.getAuthenticatedInstance().get(`artwork/userPortfolio`)
            .then(response => {
                let artworkList = response.data.map((element) => {
                    if (element.entityName === ApiService.getEntityName().ARTWORK) {
                        element.art = element.artwork;
                        element.category = element.artwork.category.code;
                        element.image = ApiService.getThumbnailByUrl(element.artwork.id);                        
                        element.viewLink = `/portfolio-artwork/${element.artwork.id}`;
                        element.editLink = `/portfolio-artwork-edit/${element.artwork.id}`;
                    } else if (element.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                        element.art = element.virtualGallery;
                        element.category = ArtworkService.artworkCategoryEnum().AR_PORTAL;
                        element.image = ApiService.getFileByUuid(element.virtualGallery.photoUuid);
                        element.viewLink = `/portfolio-virtual-gallery/${element.virtualGallery.id}`
                        element.editLink = `/portfolio-virtual-gallery-edit/${element.virtualGallery.id}`
                    } else {
                        element = null;
                    }

                    return element;
                });

                artworkList = artworkList.filter(item => item.art.status.name !== ArtworkService.artworkStatusEnum().PREVIEW);

                return artworkList;
            })

        let statuses = await ArtworkService.getArtworkStatusList()
            .then(response => {
                let statusList = response;
                statusList = statusList.filter(
                    item => item.name === ArtworkService.artworkStatusEnum().PUBLISHED || item.name === ArtworkService.artworkStatusEnum().UNPUBLISHED);

                statusList.splice(0, 0, { order: "0", label: "GENERAL_STATUS.ANY", name: "ANY" })

                return statusList;
            })

        let categories = await ArtworkService.getArtworkCategoryList()
            .then(response => {
                let categoryList = response;
                categoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })

                return categoryList;
            })

        this.setState({
            artworks: artworks,
            filteredResults: artworks,
            statusList: statuses,
            categoryList: categories,
            isLoaded: true
        })
    }

    handleSearchInput = (e) => {
        this.setState({
            searchInput: e.target.value,
        }, this.filter);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.setState({
                searchInput: e.target.value,
            }, this.filter);
        }
    }

    handleOnStatusSelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.statusList.filter(item => item.name === eventKey);
        this.setState({
            selectedStatus: selectedItem[0]
        }, this.filter)
    }

    handleOnCategorySelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.categoryList.filter(item => item.id === eventKey);

        this.setState({
            selectedCategory: selectedItem[0]
        }, this.filter)
    }

    filter = () => {
        let result = this.state.artworks;
        let searchInput = this.state.searchInput;
        let statusItem = this.state.selectedStatus.name;
        let categoryItem = this.state.selectedCategory;

        if (0 !== searchInput.replace(/ /g, ";").length) {
            result = this.state.artworks.filter(elem => elem.art.name.toLowerCase().includes(searchInput.toLowerCase()));
        }

        if (categoryItem.id !== "0") {
            result = result.filter(elem => elem.category === categoryItem.code);
        }

        if (statusItem !== 'ANY') {
            result = result.filter(elem => elem.art.status.name === statusItem);
        }

        this.setState({
            filteredResults: result
        })
    }

    onShowOrHideDisplayInPortofolioModal = (id = null) => {
        if (!this.state.showDisplayInPortofolioModal) {
            this.setState({
                cardIdInModal: id,
                displaystatus: this.state.artworks[this.state.artworks.indexOf(this.state.artworks.find(element => element.art.id === id))].displayedInPortfolio.toString(),
                showDisplayInPortofolioModal: true,
            })
        } else {
            if (!id) {
                this.setState({
                    showDisplayInPortofolioModal: false,
                })
            } else {
                let mockArray = this.state.artworks;
                let mockElement = mockArray[mockArray.indexOf(mockArray.find(element => element.art.id === id))];
                mockElement.displayedInPortfolio = !mockElement.displayedInPortfolio;

                this.setState({
                    artworks: mockArray,
                    showDisplayInPortofolioModal: false,
                }, () => {
                    let promise = [];
                    if (mockElement.entityName === ApiService.getEntityName().ARTWORK) {
                        promise = new Promise((resolve, reject) => {
                            ApiService.getAuthenticatedInstance().patch(`/artworkAuthors/${mockElement.id}`, {
                                displayedInPortfolio: mockElement.displayedInPortfolio,
                                lastUpdatedBy: ApiService.getCurrentUser().username,
                                lastUpdatedOn: Date.now(),
                            })
                                .then(() => resolve())
                                .catch(err => reject(err))
                        });

                        promise.then(() => {
                            toast.success(translatedMessage("ARTWORK.PREFERENCES.SAVE_SUCCESS"));
                        })
                            .catch(err => {
                                console.log("An error has ocurred: " + err);
                                toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                            })
                    } else if (mockElement.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
                        promise = new Promise((resolve, reject) => {
                            ApiService.getAuthenticatedInstance().patch(`/virtualGalleries/${mockElement.art.id}`, {
                                displayedInPortfolio: mockElement.displayedInPortfolio,
                                lastUpdatedBy: ApiService.getCurrentUser().username,
                                lastUpdatedOn: Date.now(),
                            })
                                .then(() => resolve())
                                .catch(err => reject(err))
                        });

                        promise.then(() => {
                            toast.success(translatedMessage("ARTWORK.PREFERENCES.SAVE_SUCCESS"));
                        })
                            .catch(err => {
                                console.log("An error has ocurred: " + err);
                                toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                            })
                    }
                })
            }
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <div className="ca-page-padding ca-main-container">
                    <DisplayInPortofolioModal
                        show={this.state.showDisplayInPortofolioModal}
                        onHide={this.onShowOrHideDisplayInPortofolioModal}
                        cardid={this.state.cardIdInModal}
                        displaystatus={this.state.displaystatus}
                    />

                    <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
                        <div className="d-flex align-items-center">
                            <Image src={myPortfolio} className="ca-page-title-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PORTFOLIO")} />
                            <span className="ca-page-title">{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PORTFOLIO")}</span>
                        </div>
                        <div>
                            <Link to="/projects" className="ca-dark-link w-100">
                                <Image className="ca-page-title-icon" src={myProjects} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                                    title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                            </Link>
                            <Link to="/portfolio-home" className="ca-dark-link">
                                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                                    title={translatedMessage("BUTTON.BACK")} />
                            </Link>
                        </div>
                    </Row>

                    <Row className="d-flex align-items-center">
                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                                onChange={this.handleSearchInput}
                                onKeyDown={this.handleKeyDown} />
                        </Col>

                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedCategory.code)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.categoryList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.id}>
                                                {translatedMessage(element.code)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>

                        <Col className="col-12 col-md-4 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedStatus.label)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.statusList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.name}>
                                                {translatedMessage(element.label)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <div className="card-columns">
                        {this.state.filteredResults.map((element, index) => {
                            return (
                                <CardComponent
                                    key={index}
                                    cardImage={element.image}
                                    cardTitle={element.art.name}
                                    cardCategory={translatedMessage("PROJECT.CATEGORY." + element.category)}
                                    displayedInPublicPortofolio={element.displayedInPortfolio}
                                    onShowOrHideDisplayInPortofolioModal={this.onShowOrHideDisplayInPortofolioModal}
                                    cardId={element.art.id}
                                    cardLink={element.viewLink}
                                    cardEditLink={element.editLink}
                                    cardStatus={element.art.status}
                                    cardType={element.art.type ? translatedMessage(element.art.type.label) : null}
                                    isUnpublished={element.art.status.name === ArtworkService.artworkStatusEnum().UNPUBLISHED}
                                    hasPortfolioDisplayCheck={true}
                                    hasSpaceDisplayCheck={false}
                                    canEdit={true}
                                    canManageLocation={false}
                                    canChangeStatus={false}
                                />
                            )
                        })}
                    </div>

                </div >
            )
        }
    }

}

export default ArtworksPage;
