import React from 'react';
import './SubmittedForApprovalModalComponent.css';
import { translatedMessage } from '../../../../services/language.service';

import { Modal, Button } from 'react-bootstrap'

class SubmittedForApprovalModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Modal
                    {...this.props}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="display-in-portofolio-modal"
                    style={{width: "100vw", paddingLeft:"0"}}
                >
                    <Modal.Body>
                        <div style={{ flexBasis: 0 }} className="display-in-portofolio-modal-arrange-text">
                            <Modal.Title style={{ marginTop:"1.5rem", marginBottom: "2.5rem" }} id="contained-modal-title-vcenter">
                                {translatedMessage("PROJECT.SUBMIT_SUCCESS")}
                            </Modal.Title>
                            <span style={{ textAlign: "center", fontSize: "1rem", fontWeight: "600" }}>
                                {translatedMessage(this.props.message)}
                            </span>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "60%", marginBottom: "0.5rem" }}>
                            <Button className="ca-button" variant="dark" style={{ width: "8rem" }} onClick={() => this.props.onHide()}>
                                {translatedMessage("GENERAL.CLOSE")}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

}

export default SubmittedForApprovalModalComponent;