import React from 'react';

import { Row, Col, Button, OverlayTrigger, Tooltip, Image } from 'react-bootstrap/';

import ApiService from '../../../services/api.service';
import FileService from '../../../services/file.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';


import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

import { toast } from "react-toastify";
import moment from 'moment';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import AddFileModalComponent from '../../../components/shared/AddFileModalComponent/AddFileModalComponent';
import ChangeYoutubeIdModalComponent from '../../../components/shared/ChangeYoutubeIdModalComponent/ChangeYoutubeIdModalComponent'
import VirtualGalleryService from '../../../services/virtualGallery.service';

class ArPortalPublishPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualGallery: null,
      artFile: [],
      presentationImages: [],
      videos: [],
      statusName: '',
      data: {},
      showAddFileModal: false,
      isLoaded: false,
      isError: false,
      errorMessage: '',
      fileModalData: null,
      showEditYoutubeModal: false,
      canEdit: false
    };

  }

  componentDidMount() {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("VIRTUAL_GALLERY_EDIT"),
      }, this.populateData)
    });
  }

  populateData() {
    if (this.state.canEdit) {
      VirtualGalleryService.getVirtualGalleryForManage(this.props.match.params.id)
        .then(result => {
          this.setState({
            virtualGallery: {
              ...result,
              storageItem: {
                id: result.storageItemId
              }
            },
            statusName: result.status.name,
            isLoaded: true
          }, this.getMedia);
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          this.setState({
            isError: true,
            errorMessage: err,
            isLoaded: true
          });
        });
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }

  }

  getMedia = () => {
    var promise = [];
    var presentationImages = [];
    var videos = [];

    promise.push(
      ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${this.state.virtualGallery.id}/${ApiService.getEntityName().VIRTUAL_GALLERY}`)
        .then(results => {          
          presentationImages = FileService.handleFilesForCarousel(results.data.fileList, FileService.getFileUsage().THUMBNAIL);
        })
    )

    promise.push(
      FileService.getVideoUrls(this.state.virtualGallery.id, ApiService.getEntityName().VIRTUAL_GALLERY).then(response => { videos = response })
    )

    Promise.all(promise).then(() => {
      this.setState({
        presentationImages: presentationImages,
        videos: videos
      })
    })
  }

  onShowAddFile = (event) => {
    if (event.currentTarget.id === 'btnPresImg') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.virtualGallery,
        storageitemlinked: true
      }
      this.setState({
        showAddFileModal: true,
        fileModalData: modalData
      })
    }
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getMedia();
    }
    this.setState({
      showAddFileModal: false,
      showAddVideoModal: false
    })
  }

  handleMediaListChange = () => {
    this.getMedia();
  }

  handleYoutubeIdEdit = (event) => {
    this.setState({
      showEditYoutubeModal: true,
    })
  }

  onHideYoutubeModal = (storageItemFileId, videoUrlId, videoId) => {
    if (videoId) {
      ApiService.getAuthenticatedInstance().post(`/entityVideoUrls`, {
        entity: {
          entityId: this.state.virtualGallery.id,
          entityName: ApiService.getEntityName().VIRTUAL_GALLERY
        },
        videoId: videoId,
        type: FileService.getVideoUrlType().YOUTUBE,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
          this.setState({
            showEditYoutubeModal: false
          }, this.handleMediaListChange)
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      this.setState({
        showEditYoutubeModal: false
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <>
            <div className="ca-page-padding ca-main-container">
              <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
                <div className="pr-2 d-flex align-items-center">
                  <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                  <div className="ca-page-title">{this.state.virtualGallery.name + " | " + translatedMessage("GENERAL.MEDIA_FILES")}</div>
                </div>

                <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                  <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push("/virtualGalleryList")}>
                    <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                  </Button>
                </div>
              </Row>

              <Row>
                <Col className="col-12 col-md-6 pl-0">
                  <div className="w-100 mb-3 font-weight-bold d-flex align-items-end">
                    <span>
                      {translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                      <OverlayTrigger
                        key='addImage'
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-addImage`} className="ca-tooltip">
                            {translatedMessage("ARTWORK.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                          </Tooltip>
                        }
                      >
                        <span className="ca-question-icon"></span>
                      </OverlayTrigger>
                    </span>

                    <span className="d-inline-block">
                      <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile} title={translatedMessage("GENERAL.ADD")}>
                        <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                      </Button>
                    </span>
                  </div>
                  {this.state.presentationImages.length === 0 &&
                    translatedMessage("IMAGE.FIRST_UPLOAD")
                  }
                  {this.state.presentationImages.length > 0 &&
                    <Carousel
                      id="presImg"
                      items={this.state.presentationImages}
                      controls={true}
                      indicators={false}
                      interval={null}
                      class="mb-5"
                      hasToolbar={true}
                      toolbarDelete={true}
                      canDeleteLast={false}
                      onChange={this.handleMediaListChange}
                    />
                  }
                </Col>
                <Col className="col-12 col-md-6 pl-0">
                  <div className="w-100 mb-3 font-weight-bold d-flex align-items-end">
                    <span>
                      {translatedMessage("PROJECT.VIDEOS")}
                    </span>

                    <span className="d-inline-block">
                      <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" 
                            onClick={this.handleYoutubeIdEdit} title={translatedMessage("VIDEO.ADD_YOUTUBE_ID")}>
                      <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")}/>
                    </Button>                      
                    </span>
                  </div>
                  {this.state.videos.length === 0 &&
                    translatedMessage("VIDEO.FIRST_UPLOAD")
                  }
                  {this.state.videos.length > 0 &&
                    <Carousel
                      id="video"
                      items={this.state.videos}
                      controls={true}
                      indicators={false}
                      interval={null}
                      class="mb-5"
                      hasToolbar={true}
                      toolbarDelete={true}
                      toolbarYoutubeId={true}
                      videoUrlOnly={true}
                      onChange={this.handleMediaListChange}
                    />
                  }
                </Col>
              </Row>
            </div>

            <AddFileModalComponent
              show={this.state.showAddFileModal}
              onHide={this.onHideAddFile}
              data={this.state.fileModalData}
            />

            <ChangeYoutubeIdModalComponent
              show={this.state.showEditYoutubeModal}
              onHide={this.onHideYoutubeModal}
            />
          </>
        )
      }
    }
  }
}

export default ArPortalPublishPreview;