import React from 'react';
import '../Styles/ProjectPublishPage.css';

import ApiService from '../../../services/api.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import ArtPublishPricePackage from '../Components/ArtPublishPricePackageComponent';
import ArtworkService from '../../../services/artwork.service';
import digitalPictureIcon from '../../../components/shared/Resources/icons/digital_picture_white.svg';

class ArArtPublishPricePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isError: false,
            errorMessage: '',
        };

    }

    componentDidMount() {
        let user = ApiService.getCurrentUser();
        if (!user) {
            ApiService.initCurrentUser()
                .then(response => {
                    this.setState({
                        user: response,
                        isLoaded: true
                    })
                })
        } else {
            this.setState({
                user: user,
                isLoaded: true
            })
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                return (
                    <>
                        <ArtPublishPricePackage 
                            artId={this.props.match.params.id}
                            artType={ApiService.getEntityName().ARTWORK}
                            artCategory={ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE}
                            forProject={true}
                            history={this.props.history}
                            backUrl={`/project/publish/digital-picture-art/${this.props.match.params.id}`}
                            nextUrl={`/project/publish/digital-picture-preview/${this.props.match.params.id}`}
                            pageTitle={"PROJECT.CREATE_DIGITAL_PICTURE"}
                            icon={digitalPictureIcon}
                        />                        
                    </>
                )
            }
        }
    }
}

export default ArArtPublishPricePackage;