import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { translatedMessage } from "../../../services/language.service";
import FileService from "../../../services/file.service";

class ChooseVideoTypeComponentModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  handleSelection = (event) => {
    this.props.onHide(event.target.id);
  }

  render() {
    const hasCD = this.props.data.filter(item => item.file.usage === FileService.getFileUsage().CREATE_DESTROY).length > 0 ? true : false;
    const hasAD = this.props.data.filter(item => item.file.usage === FileService.getFileUsage().ART_DESC).length > 0 ? true : false;
    const hasCP = this.props.data.filter(item => item.file.usage === FileService.getFileUsage().CREATION_PROC).length > 0 ? true : false;
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="web-resource-delete-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" style={{fontSize: "15px", lineHeight: "36px", fontWeight: "400"}}>
            {translatedMessage("PROJECT.UPLOAD_VIDEO.CHOOSE_TYPE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pb-3">
          <div className="mb-3 w-100">
            <Button variant="dark" className="ca-button" id={FileService.getFileUsage().CREATE_DESTROY} style={{ height: "40px", maxHeight: "40px" }}
              onClick={this.handleSelection}>
              {translatedMessage("PROJECT.UPLOAD_VID.CREATE_DESTROY") + " - " + (hasCD ? translatedMessage("GENERAL.CHANGE") : translatedMessage("GENERAL.ADD"))}
            </Button>
          </div>
          <div className="mb-3 w-100">
            <Button variant="dark" className="ca-button" id={FileService.getFileUsage().ART_DESC} style={{ height: "40px", maxHeight: "40px" }}
              onClick={this.handleSelection}>
              {translatedMessage("PROJECT.UPLOAD_VID.ART_DESC") + " - " + (hasAD ? translatedMessage("GENERAL.CHANGE") : translatedMessage("GENERAL.ADD"))}
            </Button>
          </div>
          <div className="mb-3 w-100">
            <Button variant="dark" className="ca-button" id={FileService.getFileUsage().CREATION_PROC} style={{ height: "40px", maxHeight: "40px" }}
                    onClick={this.handleSelection}>
              {translatedMessage("PROJECT.UPLOAD_VID.CREATION_PROC") + " - " + (hasCP ? translatedMessage("GENERAL.CHANGE") : translatedMessage("GENERAL.ADD"))}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ChooseVideoTypeComponentModal;