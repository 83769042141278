import React from 'react';

import EntityLocationListComponent from '../../../components/shared/EntityLocationListComponent/EntityLocationListComponent';
import artIcon from '../../../components/shared/Resources/icons/art.svg';

import { Row } from 'react-bootstrap';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

class ArtworkLocationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artwork: null,
      locations: [],
      pin: {},
      isLoaded: false,
      canViewList: false,
    };

  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(result => {
      this.setState({
        canViewList: hasPermission("ARTWORK_MANAGE_LOCATION")
      }, this.getData)
    });
  }

  async getData() {
    const artworkId = this.props.match.params.id;

    const artwork = await ArtworkService.getArtworkForEdit(artworkId, false);

    const locations = await ApiService.getAuthenticatedInstance().get(`artwork/artworkLocation/${artworkId}`)
      .then(response => { 
        return response.data 
    })

    const pin = await MapService.getLocationPinByType(artwork.category.code);

    this.setState({
      artwork: artwork,
      locations: locations,
      pin: pin[0] ? pin[0] : null,
      isLoaded: true
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (this.state.canViewList) {
      return (
        <>
          <EntityLocationListComponent
            entityId={this.state.artwork.id}
            entityName={ApiService.getEntityName().ARTWORK}
            entityTitle={this.state.artwork.name}
            locations={this.state.locations}
            pin={this.state.pin}
            icon={artIcon}
            endPoint={`artwork/artworkLocation/`}
            history={this.props.history}
          />


        </>
      );
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="mb-3 pr-2">
            <span className="ca-page-subtitle font-bold">{translatedMessage("USER.SECURITY.MISSING_RIGHTS")}</span>
          </Row>
        </div>
      )
    }
  }
}

export default ArtworkLocationList;