import React from 'react';

import { Modal, Button, Col, Image, Row, Form } from 'react-bootstrap/'

import { translatedMessage } from '../../../services/language.service';
import parkIcon from '../Resources/homepage/art_park.svg';

import EntityMembershipCardComponent from '../EntityMembership/EntityMembershipCardComponent';
import ParkService from '../../../services/park.service';
import EntityService from '../../../services/entity.service';
import ApiService from '../../../services/api.service';

import { toast } from "react-toastify";

class ParkAccessModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            park: { ...this.props?.park, storageItem: { id: this.props?.park?.storageItemId } },
            permissions: []
        }
    }

    async componentDidMount() {
        // console.log("componentDidMount: ", this.props.park)
        this.getData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.park !== this.props.park) {
            this.setState({
                park: this.props.park,
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.park !== prevState.park) {
            return {
                park: prevState.park,
            };
        }
        else return null;
    }

    getData = () => {
        var accessTypes = [];
        var permissions = [];
        var memberships = [];
        var promise = [];
        var isNew = false;

        const emPromise = new Promise((resolve, reject) => {
            ParkService.getParMembershipPermissions(this.state.park?.id)
                .then(response => {
                    permissions = response.filter(item => item.membership.name.toUpperCase() !== "CA");
                    resolve()
                })
                .catch(err => reject(err))
        })

        emPromise.then(() => {
            promise.push(EntityService.getEntityAccessTypeList()
                .then(response => {
                    accessTypes = response;
                    accessTypes.splice(0, 0, { label: "ENTITY_ACCESS_TYPE.NONE", name: "NONE" })
                })
            )

            if (permissions.length === 0) {
                promise.push(ApiService.getMembershipList()
                    .then(response => {
                        memberships = response;
                        memberships.map(item => {
                            const parts = item._links.self.href.split('/');
                            const id = parts[parts.length - 1];
                            item.id = id;
                            return item;
                        })
                        memberships.sort((a, b) => {
                            var result;
                            a.id <= b.id ? result = -1 : result = 1
                            return result;
                        });
                    })
                )
            }

            Promise.all(promise).then(() => {
                if (permissions.length === 0) {
                    isNew = true;
                    memberships.forEach((item, index) => {
                        permissions[index] = {
                            id: item.id,
                            entity: this.state.park,
                            membership: item,
                            hasAccess: false,
                            accessFee: 0,
                            accessType: { label: "ENTITY_ACCESS_TYPE.NONE", name: "NONE" },
                            accessDuration: '',
                            acessTypeList: accessTypes,
                            quantityDisabled: true
                        }
                    })
                } else {
                    permissions.map((item, index) => {
                        item.acessTypeList = accessTypes
                        if (item.accessType === null) {
                            item.quantityDisabled = true;
                            item.accessType = { label: "ENTITY_ACCESS_TYPE.NONE", name: "NONE" };
                        } else {
                            item.quantityDisabled = false;
                        }
                        item.accessDuration = item.accessDuration === null ? '' : item.accessDuration;
                        return item;
                    })
                    permissions.sort((a, b) => {
                        var result;
                        a.membership.id <= b.membership.id ? result = -1 : result = 1
                        return result;
                    });
                }
                this.setState({
                    accessTypes: accessTypes,
                    memberships: memberships,
                    permissions: permissions,
                    isNew: isNew,
                    isLoaded: true
                })
            })
        })
            .catch(err => {
                let errorMessage = "GENERAL.GET_DATA_ERROR";
                if (err && err.response && err.response.status === 404) {
                    errorMessage = err.response.data.message;
                }
                this.setState({
                    isError: true,
                    errorMessage: errorMessage,
                    isLoaded: true
                })
            })
    }

    handleTypeSelect = (id, name) => {
        const selectedItem = this.state.accessTypes.filter(item => item.name === name);

        let _permissions = this.state.permissions
        _permissions.map((el, index) => {
            if (el.id.toString() === id.toString()) {
                el.accessType = selectedItem[0]
                if (selectedItem[0].name === 'NONE') {
                    el.quantityDisabled = true;
                    el.accessDuration = 0;
                } else {
                    el.quantityDisabled = false;
                }
            }
            return el;
        });

        this.setState({
            permissions: _permissions,
        })
    }

    handleFeeInput = (id, value) => {
        let _permissions = this.state.permissions
        _permissions.map((el, index) => {
            if (el.id.toString() === id.toString()) {
                el.accessFee = value
            }
            return el;
        });

        this.setState({
            permissions: _permissions
        })
    }

    handleDurationInput = (id, value) => {
        let _permissions = this.state.permissions
        _permissions.map((el, index) => {
            if (el.id.toString() === id.toString()) {
                el.accessDuration = value
            }
            return el;
        });

        this.setState({
            permissions: _permissions
        })
    }

    handleSwithcEnable = (id, hasAccess) => {
        let _permissions = this.state.permissions
        _permissions.map((el, index) => {
            if (el.id.toString() === id.toString()) {
                el.hasAccess = hasAccess
            }
            return el;
        });

        this.setState({
            permissions: _permissions
        })
    }

    validate = (event) => {
        var response = {
            isError: false,
            message: ""
        };

        return response;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const validation = this.validate(event);
        if (!validation.isError) {
            const _permissions = this.state.permissions;
            let savedPermissions = [];

            _permissions.forEach(item => {
                savedPermissions.push({
                    id: this.state.isNew ? null : item.id,
                    entityId: item.entity.id,
                    membershipId: parseInt(item.membership.id),
                    hasAccess: item.hasAccess,
                    accessType: item.accessType.name === 'NONE' ? null : item.accessType.name,
                    accessFee: item.accessFee ? parseInt(item.accessFee) : 0,
                    accessDuration: parseInt(item.accessDuration),
                })
            })

            ParkService.saveParMembershipPermissions(savedPermissions)
                .then(response => {
                    toast.success(translatedMessage(response.message));
                    this.props.onHide()
                    // this.getData();
                })
                .catch(error => {
                    console.log("An error has ocurred: " + error);
                    toast.error(translatedMessage(error.response.data.message));
                    this.getData();
                });
        } else {
            toast.error(validation.message);
        }
    }

    render() {
        var park = this.state?.park;

        return (
            <Modal
                onHide={this.props?.onHide}
                show={this.props?.show}
                size="xl"
                dialogClassName="ca-modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='d-flex align-items-center'>
                            <Image src={parkIcon} className="mr-1" alt={translatedMessage("PARK.PARK")} style={{ height: "40px" }} />
                            {park?.name}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <Row>
                        <Col className="col-12">
                            <label className="form-label">
                                {translatedMessage("PARK.IMAGE.UPLOAD")}
                            </label>
                        </Col>
                        <Col className="col-12">
                            <Form id="membershipForm" className="ca-form" onSubmit={this.handleSubmit}>
                                <div className="ca-columns-3 card-columns">
                                    {this.state.permissions.map((element, index) => {
                                        return (
                                            <EntityMembershipCardComponent
                                                key={element.membership.id}
                                                data={element}
                                                accessTypes={this.state.accessTypes}
                                                onCheck={this.handleCheckChange}
                                                onTypeSelect={this.handleTypeSelect}
                                                onFeeInput={this.handleFeeInput}
                                                onQuantityInput={this.handleDurationInput}
                                                onSwitchEnable={this.handleSwithcEnable}
                                                quantityDisabled={this.state.quantityDisabled}
                                            />
                                        )
                                    })}
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" className="ca-button mr-1" form="membershipForm" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                    <Button variant="dark" className={"ca-button mr-1"}
                        onClick={this.props.onHide}>
                        {translatedMessage("GENERAL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ParkAccessModalComponent