import React, { useState } from "react";

import "./ResetPasswordPage.css"

import ApiService from "../../services/api.service";
import { toast } from 'react-toastify';
import calogo from '../../components/shared/Resources/calogo.svg'

import { Row, Col, Form, Button, Card, Image } from 'react-bootstrap'

import LanguageService from "../../services/language.service"

function FormReset(props) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {

        } else {
            const password = event.target.elements.formPassword.value;
            const confirmPassword = event.target.elements.formConfirmPassword.value;

            if (password !== confirmPassword) {
                toast.error(window.$translate(LanguageService.getCurrentLanguage(), "MESSAGE.PASSWORD_NO_MATCH.ERROR"))
                return;
            }

            const id = props.match.params.id;
            const confirmEmailToken = props.match.params.confirmEmailToken;

            if (!id || !confirmEmailToken) {
                toast.error(window.$translate(LanguageService.getCurrentLanguage(), "MESSAGE.EMAIL_TOKEN.EXPIRED"))
                return;
            }

            ApiService.instance.post('/authentication/reset', {
                id,
                password,
                confirmEmailToken,
            }).then(result => {
                toast.success(window.$translate(LanguageService.getCurrentLanguage(), "RESET.TOAST.SUCCESS"));
                props.history.push('/login');
            }).catch(err => {
                console.log('err', err);
                let errorMessage = "RESET.ERROR"
                if(err.response.data.message){
                    errorMessage = err.response.data.message;
                }
                toast.error(window.$translate(LanguageService.getCurrentLanguage(), errorMessage));
            });
        }

        setValidated(true);
    };

    return (
        <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col className="col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4">
                <Card className="reset-page-card">
                    <Card.Body className="reset-page-card-body">
                        <Card.Title className="reset-page-card-title">
                            {window.$translate(LanguageService.getCurrentLanguage(), "RESET.RESET_EMAIL.TITLE")}
                        </Card.Title>
                        <Form className="reset-page-card-form" validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="formPassword">
                                <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL:PASSWORD")}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder={window.$translate(LanguageService.getCurrentLanguage(), "GENERAL:PASSWORD")}
                                    defaultValue=""
                                    minLength="8"
                                />
                            </Form.Group>
                            <Form.Group controlId="formConfirmPassword">
                                <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.CONFIRM_NEW_PASSWORD")}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder={window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.CONFIRM_NEW_PASSWORD")}
                                    defaultValue=""
                                    minLength="8"
                                />
                            </Form.Group>
                            <div className="reset-page-card-submit-button-section">
                                <Button variant="dark" className="ca-button" type="submit">{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.SAVE")}</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userInfo: {
                password: '',
                confirmPassword: '',
            },
            validated: false,
        };

        this.confirm = this.confirm.bind(this);
    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            userInfo: {
                ...this.state.userInfo,
                [name]: value,
            }
        });
    };

    confirm(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({ validated: true });

    }

    render() {
        return (
            <div className="reset-page-align">
                <Image className="reset-page-logo" src={calogo} />
                <FormReset history={this.props.history} match={this.props.match} />
            </div>
        );
    }
}

export default ResetPasswordPage;
