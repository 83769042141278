import React from 'react';

import ArtProjectCreate from './Components/ArtProjectCreateComponent'

import ApiService from '../../services/api.service';

import arArtIcon from '../../components/shared/Resources/icons/ar_art_white.svg';


class ArArtProjectCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyId: this.props.match.params.companyId ? this.props.match.params.companyId : null,
        };
    }

    componentDidMount = () => {
        let user = ApiService.getCurrentUser();
        if (!user) {
            ApiService.initCurrentUser()
                .then(response => {
                    this.setState({ user: response })
                })
        } else {
            this.setState({ user: user })
        }
    }



    render() {
        return (
            <>
                <ArtProjectCreate
                    pageTitle={"PROJECT.CREATE_AR_ART"}
                    icon={arArtIcon}
                    projectCategory={1}
                    history={this.props.history}
                    companyId={this.state.companyId}
                    nexturl={`/project/publish/ar-art`}
                />
            </>
        )
    }
}

export default ArArtProjectCreatePage;