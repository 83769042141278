import ApiService from "./api.service";

import logoblack from '../components/shared/Resources/logo-dark-small-with-padding.png';
import calogo from '../components/shared/Resources/calogo-black.svg';

const VirtualGalleryService = {

  virtualGalleryStatusEnum: () => {
    return {
      DRAFT: "DRAFT",
      PUBLISHED: "PUBLISHED",
      UNPUBLISHED: "UNPUBLISHED",
      SUBMITTED_FOR_APPROVAL: "SUBMITTED_FOR_APPROVAL",
      PREVIEW: "PREVIEW"
    };
  },

  virtualGalleryTypeEnum: () => {
    return {
      ART_PORTAL: "ART_PORTAL",
      ARTIST_PORTAL: "ARTIST_PORTAL",
      VIRTUAL_GALLERY: "VIRTUAL_GALLERY",
      OWN_VIRTUAL_GALLERY: "OWN_VIRTUAL_GALLERY"
    };
  },

  getVirtualGalleryStatusList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/artworkStatusList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  getVirtualGalleryTypeList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/virtualGalleryTypeList`)
        .then(response => resolve(response.data))
        .catch(err => {
          reject(err);
        });
    });
  },

  async getAllArPortals() {
    return new Promise(async (resolve, reject) => {
      let arPortals = await ApiService.getAuthenticatedInstance().get('/virtualGallery/arPortalList')
        .then(response => {
          return response.data
        })

      arPortals.sort((a, b) => {
        var result;
        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
        return result;
      });

      resolve(arPortals)
    })
  },

  async getAllVirtualGalleries() {
    return new Promise(async (resolve, reject) => {
      let virtualGalleries = await ApiService.getAuthenticatedInstance().get('/virtualGallery/list/all')
        .then(response => {
          return response.data
        })

      virtualGalleries.map(item => {
        item.imageURL = item.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${item.photoUuid}` : logoblack;
        return item;
      })

      virtualGalleries.sort((a, b) => {
        var result;
        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
        return result;
      });

      resolve(virtualGalleries)
    })
  },

  async getVirtualGalleriesByStatus(status, userGallery) {
    let path = userGallery !== null ? "userGallery=" + userGallery : null;
    path = status
      ? ("?status=" + status + (path ? "&" + path : ""))
      : (path ? "?" + path : "");

    return new Promise(async (resolve, reject) => {
      let virtualGalleries = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/list${path}`)
        .then(response => {
          return response.data
        })

      virtualGalleries.map(item => {
        item.imageURL = item.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${item.photoUuid}` : "";
        return item;
      })

      virtualGalleries.sort((a, b) => {
        var result;
        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
        return result;
      });

      resolve(virtualGalleries)
    })
  },

  async getVirtualGalleriesForCompany() {
    return new Promise(async (resolve, reject) => {
      let virtualGalleries = await ApiService.getAuthenticatedInstance().get('/virtualGallery/list/for-company')
        .then(response => {
          return response.data
        })

      virtualGalleries.map(item => {
        item.imageURL = item.photoUuid ? `${ApiService.getBaseUrl()}/storage/file/${item.photoUuid}` : "";
        return item;
      })

      virtualGalleries.sort((a, b) => {
        var result;
        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
        return result;
      });

      resolve(virtualGalleries)
    })
  },

  async getUserVirtualGalleries() {
    return new Promise(async (resolve, reject) => {
      let virtualGalleries = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/user-virtual-galleries`)
        .then(response => {
          return response.data
        })

      virtualGalleries.map(item => {
        item.imageURL = item.virtualGalleryPhotoUuid ? `${ApiService.getBaseUrl()}/storage/file/${item.virtualGalleryPhotoUuid}` : "";
        return item;
      })

      virtualGalleries.sort((a, b) => {
        var result;
        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
        return result;
      });

      resolve(virtualGalleries)
    })
  },

  async getUserArtPortals() {
    return new Promise(async (resolve, reject) => {
      let virtualGalleries = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/user-art-portals`)
        .then(response => {
          return response.data
        })

      virtualGalleries.map(item => {
        item.imageURL = item.virtualGalleryPhotoUuid ? `${ApiService.getBaseUrl()}/storage/file/${item.virtualGalleryPhotoUuid}` : "";
        return item;
      })

      virtualGalleries.sort((a, b) => {
        var result;
        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
        return result;
      });

      resolve(virtualGalleries)
    })
  },  

  async getUserVirtualGalleryArtworks(userGalleryId) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().get(`/virtualGallery/userVirtualGalleryArtworks/${userGalleryId}`)
        .then(response => {
          let galleryArtworks = response.data;
          galleryArtworks.map(item => {
            item.imageURL = `${ApiService.getBaseUrl()}/storage/thumbnail/artwork/${item.artwork.id}`;

            return item;
          })

          galleryArtworks.sort((a, b) => {
            var result;
            (a.artwork.category.code + a.position).toUpperCase() <= (b.artwork.category.code + b.position).toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(galleryArtworks);
        })
        .catch(err => reject(err))
    })
  },

  async getVirtualGalleryArtworks(galleryId) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().get(`/virtualGallery/virtualGalleryArtworks/${galleryId}`)
        .then(response => {
          let galleryArtworks = response.data;
          galleryArtworks.map(item => {
            item.imageURL = `${ApiService.getBaseUrl()}/storage/thumbnail/artwork/${item.artwork.id}`;

            return item;
          })

          galleryArtworks.sort((a, b) => {
            var result;
            (a.artwork.category.code + a.position).toUpperCase() <= (b.artwork.category.code + b.position).toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(galleryArtworks);
        })
        .catch(err => reject(err))
    })
  },

  async getVirtualGalleryArt(galleryId) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().get(`/virtualGallery/virtualGalleryArt/${galleryId}`)
        .then(response => {
          let galleryArt = response.data;
          galleryArt.map(item => {
            item.imageURL = `${ApiService.getBaseUrl()}/storage/file/${item.thumbUuid}`;
            item.id = item.parentEntityId;

            return item;
          })

          galleryArt.sort((a, b) => {
            var result;
            (a.category + a.position).toUpperCase() <= (b.category + b.position).toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(galleryArt);
        })
        .catch(err => reject(err))
    })
  },

  async getEntityVirtualGalleryArt(entityId, entityName) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().get(`/virtualGallery/entity/art?entityId=${entityId}&entityName=${entityName}`)
        .then(response => {
          let galleryArt = response.data;

          galleryArt.map(item => {
            if(item.artwork){
              item.imageURL = ApiService.getThumbnailByUrl(item.artwork.id) + '?' + Date.now();
              item.category = item.artwork.category.code;
            } else if(item.arExperience){
              item.imageURL = ApiService.getFileByUuid(item.arExperience.photoUuid) + '?' + Date.now();
              item.category = item.arExperience.category;
            } else {
              item.imageURL = calogo;
            }

            return item;
          })

          galleryArt.sort((a, b) => {
            var result;
            (a.category + a.position).toUpperCase() <= (b.category + b.position).toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(galleryArt);
        })
        .catch(err => reject(err))
    })
  },

  getVirtualGalleryTypesList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/virtualGalleryTypeList`)
        .then(response => {
          var typesList = response.data;
          typesList.sort((a, b) => {
            var result;
            parseInt(a.order, 10) <= parseInt(b.order, 10) ? result = -1 : result = 1
            return result;
          });

          resolve(typesList)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getVirtualGalleryForManage(id) {
    return new Promise((resolve, reject) => {
      let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";

      ApiService.getAuthenticatedInstance().get(`/virtualGallery/${id}/manage`)
        .then(response => {
          let virtualGallery = response.data

          resolve(virtualGallery);
        })
        .catch(err => {
          if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          reject(errorMessage)
        });
    })
  },

  getVirtualGallery(id) {
    return new Promise((resolve, reject) => {
      let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";

      ApiService.getAuthenticatedInstance().get(`/virtualGallery/virtualGalleryDetail/${id}`)
        .then(response => {
          let virtualGallery = response.data

          resolve(virtualGallery);
        })
        .catch(err => {
          if (err && err.response && err.response.status && (404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          reject(errorMessage)
        });
    })
  },  

};

export default VirtualGalleryService;