import React from 'react';

import ArtworkEditComponent from '../../components/shared/Artwork/ArtworkEditComponent';

class EditPortfolioArtworkPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount = () => {}

  render() {
      return (
        <ArtworkEditComponent
          artworkId={this.props.match.params.id}
          canEdit={true}
          isAuthor={true}
          backLink={`/artworks`}
          viewLink={`/portfolio-artwork/${this.props.match.params.id}`}
          history={this.props.history}
        />
      )
  }

}

export default EditPortfolioArtworkPage;