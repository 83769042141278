
const ValidationService = {

  checkRequiredFields(form) {
    let isValid = true;

    var requiredElements = form.querySelectorAll("[required]");
    requiredElements.forEach(item => {
      if (!item.value) {
        item.classList.add("is-invalid");
        isValid = false
      } else if (item.classList.contains("is-invalid")) {
        item.classList.remove("is-invalid");
      }
    })

    return isValid;
  },

  checkValidity(form) {
    let isValid = true;

    isValid = this.checkRequiredFields(form);

    if (isValid) {
      var elements = form.getElementsByClassName('is-invalid');
      if (elements.length !== 0) {
        isValid = false;
      }
    }

    return isValid;
  },

  checkUrl(url) {
    let formatedUrl = url;    
    if (url.startsWith("http://") || url.startsWith("https://")) {
      formatedUrl = url;
    } else {
      formatedUrl = "https://" + url;
    }

    try { return new URL(formatedUrl) }
    catch (e) { return false }
  }  
};

export default ValidationService;