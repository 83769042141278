import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { hasPermission } from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import moment from 'moment';
import addIcon from '../../../components/shared/Resources/icons/add.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import TableComponent from './Components/UserTableComponent/UserTableComponent';
import EditUserModal from './Components/EditUserModalComponent';
import DeleteUserModal from './Components/DeleteUserModalComponent';
import UserService from '../../../services/user.service';

class CompanyAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      userList: [],
      allUsers: [],
      isLoaded: false,
      canEdit: false,
      canView: false,
      isError: false,
      errorMessage: "",
      showUserModal: false,
      showDeleteModal: false,
      selectedUserCompanyId: -1,
      deletedUserCompanyId: -1,
      selectedUser: null
    };

    this.editUser = this.editUser.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(() => {
      this.setState({
        canEdit: hasPermission("COMPANY_EDIT"),
        canview: hasPermission("COMPANY_VIEW"),
      }, this.getData)
    });
  }

  async getData() {
    if (this.state.canEdit || this.state.canView) {
      var errorMessage = "GENERAL.GET_DATA_ERROR";

      const userList = await CompanyService.getCompanyUsers(this.state.companyId)
        .then(response => {
          return response;
        })
        .catch(err => {
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }

          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })

      this.setState({
        userList: userList,
        isLoaded: true
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  async editUser(selectedUserCompanyId) {
    var errorMessage = "GENERAL.GET_DATA_ERROR";

    const allUsers = await UserService.getUserList()
      .then(response => {
        return response;
      }).catch(err => {
        if (err && err.response && (err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage(errorMessage));
      })

    var selectedUser = {
      user: { value: allUsers[0].id, label: allUsers[0].fullName },
      status: { label: 'COMPANY.USER.STATUS.INACTIVE', name: 'INACTIVE' },
      isAdministrator: false
    };

    if (selectedUserCompanyId) {
      var companyUser = this.state.userList.filter(item => item.id === selectedUserCompanyId)[0];

      selectedUser = {
        user: { value: companyUser.user.id, label: companyUser.user.fullName },
        status: companyUser.status,
        isAdministrator: companyUser.isAdministrator
      }
    }

    var filteredUsers = [];
    allUsers.forEach(item => {
      var exists = this.state.userList.some(element => element.user.id === item.id);
      if (!exists || item.id === selectedUser.user.value) {
        filteredUsers.push(item);
      }
    })

    this.setState({
      allUsers: filteredUsers,
      selectedUserCompanyId: selectedUserCompanyId ? selectedUserCompanyId : -1,
      selectedUser: selectedUser,
      showUserModal: true
    })
  }

  deleteUSer = (deletedUserCompanyId) => {
    this.setState({
      deletedUserCompanyId: deletedUserCompanyId,
      showDeleteModal: true
    })
  }

  async refreshData() {
    let userList = await CompanyService.getCompanyUsers(this.state.companyId)
      .then(response => { return response });

    this.setState({
      userList: userList,
    })
  }

  onHideEdit = (userId, status, isAdministrator) => {
    if (userId && status) {
      var promise;
      if (this.state.selectedUserCompanyId !== -1) {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().patch(`/userCompanies/${this.state.selectedUserCompanyId}`, {
            user: `/users/${userId}`,
            company: `/users/${this.state.companyId}`,
            status: status,
            isAdministrator: isAdministrator,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then(() => resolve())
            .catch(err => reject(err))
        });
      }
      else {
        promise = new Promise((resolve, reject) => {
          ApiService.getAuthenticatedInstance().post(`/userCompanies`, {
            user: `/users/${userId}`,
            company: `/users/${this.state.companyId}`,
            status: status,
            isAdministrator: isAdministrator,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          })
            .then(() => resolve())
            .catch(err => reject(err))
        });
      }

      promise.then(() => {
        toast.success(translatedMessage("COMPANY.USER.ADD_SUCCESS"))
        this.setState({
          showUserModal: false
        }, () => this.refreshData())
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.USER.ADD_ERROR"));

          this.setState({
            showUserModal: false
          })
        })
    } else {
      this.setState({
        showUserModal: false
      })
    }
  }

  onHideDelete = (userCompanyId) => {
    if (userCompanyId && userCompanyId !== -1) {
      ApiService.getAuthenticatedInstance().delete(`/userCompanies/${userCompanyId}`)
        .then(result => {
          toast.success(translatedMessage("COMPANY.USER.DELETE_SUCCESS"))
          this.setState({
            showDeleteModal: false
          }, () => this.refreshData())
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.USER.DELETE_ERROR"));

          this.setState({
            showDeleteModal: false
          })
        })
    } else {
      this.setState({
        showDeleteModal: false
      })
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <span className="ca-page-title">{translatedMessage("USER.USERS")}</span>

            <div>
              {!this.state.canEdit ? "" :
                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={() => this.editUser()}>
                  <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD_NEW")} />
                </Button>
              }

              <Button className="ca-dark-link ca-button-icon mr-1" variant="link"
                onClick={() => this.props.history.push(`/companies`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          {this.state.userList.length === 0 &&
            <div>
              <Row className="mb-2">
                <Col className="col-12 pl-0">
                  <span className="ca-page-subtitle">
                    {translatedMessage("COMPANY.NO_USERS")}
                  </span>
                </Col>
              </Row>
            </div>
          }

          {this.state.userList.length > 0 &&
            <div>
              <TableComponent
                data={this.state.userList}
                editUser={this.editUser}
                deleteUSer={this.deleteUSer}
              />
            </div>
          }

          <EditUserModal
            show={this.state.showUserModal}
            onHide={this.onHideEdit}
            users={this.state.allUsers}
            selectedUser={this.state.selectedUser}
          />

          <DeleteUserModal
            show={this.state.showDeleteModal}
            onHide={this.onHideDelete}
            usercompanyid={this.state.deletedUserCompanyId}
          />
        </div>
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAdminEdit;