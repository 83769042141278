import React from 'react';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { Row, Col, Button, Form, OverlayTrigger, Tooltip, Image } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';

import ApiService from '../../../services/api.service';
import LanguageService from '../../../services/language.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';
import ArtworkService from '../../../services/artwork.service';

import { toast } from "react-toastify";
import moment from 'moment';

import Carousel from "../Carousel/MediaCarouselComponent";
import AddFileModalComponent from '../AddFileModalComponent/AddFileModalComponent';
import ChooseVideoTypeComponentModal from '../ChooseVideoTypeComponentModal/ChooseVideoTypeComponentModal';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
// import DropzoneComponent from "../DropzoneComponent/DropzoneComponent";
// import ModelViewer from "../ModelViewer/ModelViewer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ArtworkEditComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      artwork: {},
      storageFiles: [],
      presentationImages: [],
      artworkVideos: [],
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),
      dropzoneEnabled: true,
      isLoaded: false,
      showAddFileModal: false,
      showVideoTypeSelectModal: false,
      isError: false,
      errorMessage: "",
      fileModalData: {},
      displayObject: false,
      modelEnabled: false
    };
  }

  async componentDidMount() {
    if (this.props.canEdit) {
      const artwork = await ArtworkService.getArtworkForEdit(this.props.artworkId, this.props.isAuthor)
        .then(response => {
          return response
        })
        .catch(err => {
          var errorMessage = "ARTWORK.GENERAL_ERROR";
          if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })

      if (!this.state.isError) {
        this.setState({
          artwork: {
            ...artwork,
            storageItem: {
              id: artwork.storageItemId
            }
          },
        }, this.getStorageItems);
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: translatedMessage("USER.SECURITY.MISSING_RIGHTS"),
        isLoaded: true
      })
    }
  }

  async getStorageItems() {
    const storageFiles = await ApiService.getAuthenticatedInstance().get(`/artwork/ownerFiles/${this.state.artwork.id}`)
      .then(results => { return results.data.files })

    var presentationImages = [];
    storageFiles[FileService.getFileUsage().THUMBNAIL]
      .map((storageImage, index) => {
        let mockImage = {};
        mockImage.file = storageImage;
        mockImage.original = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
        mockImage.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
        mockImage.type = FileService.getResourceType().IMAGE;
        mockImage.storageItemFileId = storageImage.storageItemFileId;
        mockImage.youtubeId = storageImage.youtubeId;
        mockImage.sortOrder = storageImage.defaultThumbnail ? 0 : index + 1;

        presentationImages.push(mockImage);
        return storageImage;
      });

    presentationImages.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

    let artworkVideos = [];
    storageFiles[FileService.getFileUsage().CREATE_DESTROY]
      .concat(storageFiles[FileService.getFileUsage().CREATION_PROC])
      .concat(storageFiles[FileService.getFileUsage().ART_DESC])
      .map((storageImage) => {
        let mockImage = {};
        mockImage.file = storageImage;
        mockImage.type = FileService.getResourceType().VIDEO;
        mockImage.storageItemFileId = storageImage.storageItemFileId;
        mockImage.youtubeId = storageImage.youtubeId;

        artworkVideos.push(mockImage);
        return storageImage;
      });

    // let modelFiles = [];
    // let showObjectModel = false;
    // if (this.state.artwork.category.code === ArtworkService.artworkCategoryEnum().OBJECT) {
    //   modelFiles = storageFiles[FileService.getFileUsage().OBJECT_MODEL];
    //   showObjectModel = true;
    // }

    this.setState({
      presentationImages: presentationImages,
      artworkVideos: artworkVideos,
      // modelFile: modelFiles.length > 0 ? modelFiles[0] : null,
      // modelFileObject: modelFiles.length > 0 ? ApiService.getFileByUuid(modelFiles[0].uuid) : null,
      // displayObject: modelFiles.length > 0 ? true : false,
      // showObjectModel: showObjectModel,
      isLoaded: true,
    });
  }

  onChangeArtworkEditor = (e) => {
    this.setState({
      artwork: {
        ...this.state.artwork,
        description: e,
      }
    })
  }

  onChangeAuthorEditor = (e) => {
    this.setState({
      artwork: {
        ...this.state.artwork,
        aboutAuthor: e,
      }
    });
  };

  saveChanges = (event) => {
    event.preventDefault();

    ApiService.getAuthenticatedInstance().patch(`/artworks/${this.props.artworkId}`, {
      description: this.state.artwork.description,
      aboutAuthor: this.state.artwork.aboutAuthor,
      lastUpdatedBy: ApiService.getCurrentUser().username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
      .then(() => toast.success(translatedMessage("GENERAL.SAVE_SUCCESS")))
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      })
  }

  onShowAddFile = (event) => {
    if (event.currentTarget.id === 'btnPresImg') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
        fileUsage: FileService.getFileUsage().THUMBNAIL,
        fileType: FileService.getResourceType().IMAGE,
        deletePrevious: false,
        deletedImage: null,
        deletePreviousDesc: "",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
        maxSize: 1,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: true,
        multipleFilesDesc: "DROPZONE.MULTIPLE_IMAGE",
        entity: this.state.artwork,
        storageitemlinked: true
      }
      this.setState({
        showAddFileModal: true,
        fileModalData: modalData
      })
    } else if (event.currentTarget.id === 'btnVideo') {
      const modalData = {
        modalTitle: translatedMessage("PROJECT.UPLOAD_VIDEO"),
        fileType: FileService.getResourceType().VIDEO,
        deletePrevious: true,
        deletedImage: null,
        deletePreviousDesc: "DROPZONE.DELETE_PREVIOUS_VIDEO",
        fileFormatsDesc: "DROPZONE.FILE_TYPES.VIDEO",
        maxSize: 100,
        maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
        multipleFiles: false,
        multipleFilesDesc: "",
        entity: this.state.artwork,
        storageitemlinked: true
      }
      this.setState({
        showVideoTypeSelectModal: true,
        fileModalData: modalData
      })
    }
  }

  onHideAddFile = (hasUpload) => {
    if (hasUpload && hasUpload === true) {
      this.getStorageItems();
    }
    this.setState({
      showAddFileModal: false,
      showAddVideoModal: false
    })
  }

  onHideVideoTypeSelect = (type) => {
    if (type) {
      let modalData = this.state.fileModalData;
      modalData.fileUsage = type;

      let selectedVideo = this.state.artworkVideos.filter(item => item.file.usage === type);
      if (selectedVideo[0] && selectedVideo[0].file && selectedVideo[0].file.uuid) {
        modalData.deletedImage = selectedVideo[0].file
      }

      this.setState({
        showAddFileModal: true,
        showVideoTypeSelectModal: false,
        fileModalData: modalData
      })
    } else {
      this.setState({
        showVideoTypeSelectModal: false,
      })
    }
  }

  handleMediaListChange = () => {
    this.getStorageItems();
  }

  // onUploadModelFile = (dropzoneEnabled, uploadedFile) => {
  //   if (dropzoneEnabled) {
  //     setTimeout(() => {
  //       this.setState({
  //         dropzoneEnabled: dropzoneEnabled,
  //         modelFile: uploadedFile,
  //         modelFileObject: ApiService.getFileByUuid(uploadedFile.uuid),
  //         displayObject: !this.state.displayObject,
  //       })
  //     }, 500);
  //   } else {
  //     this.setState({
  //       dropzoneEnabled: dropzoneEnabled,
  //       modelFile: uploadedFile,
  //     });
  //   }
  // }

  // changeObjectDropzone = () => {
  //   this.setState({ displayObject: !this.state.displayObject })
  // }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        // let object_class = this.state.displayObject ? "ca-form-object-container" : "ca-form-object-container-hide";
        // let dropzone_class = !this.state.displayObject ? "ca-dropzone-show" : "ca-dropzone-hide";

        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-3">
              <div className="ca-page-title-container ca-page-title">
                <span className="font-weight-bold">{this.state.artwork.name}</span>
                <div className="ca-art-date">{ApiService.parseDate(this.state.artwork.publishDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' })}</div>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" type="submit" form="artwork-form">
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} />
                </Button>                
                <Link to="#" className="ca-dark-link" onClick={() => this.props.history.push(this.props.viewLink)}>
                  <div className="d-flex align-items-center h-100">
                    <FontAwesomeIcon icon={["far", "eye"]}
                      className="ca-action-icon-30 mr-2" title={translatedMessage("GENERAL.VIEW")} />
                  </div>
                </Link>
                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(this.props.backLink)}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>                
              </div>
            </Row>

            <Row className="mb-1">
              <Col className="col-12 col-lg-12 ca-artwork-column-left">
                <Form id="artwork-form" className="ca-form" onSubmit={this.saveChanges}>
                  <Row>
                    <Col className="col-12 col-lg-6 ca-artwork-column-left">
                      <Form.Group controlId="formArtworkDescription">
                        <div className="ca-paragraph-title w-100">{translatedMessage("ARTWORK.ABOUT_ARTWORK")}</div>
                        <div className="ca-text-editor">
                          <SunEditor
                            name="about-artwork"
                            lang={LanguageService.getCurrentLanguage()}
                            placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                            setContents={this.state.artwork.description}
                            setOptions={{
                              height: 200,
                              maxWidth: "100%",
                              buttonList: this.state.editorButtns,
                              font: this.state.editorFonts,
                              defaultStyle: this.state.editorDefaultStyle
                            }}
                            onChange={this.onChangeArtworkEditor}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col className="col-12 col-lg-6 ca-artwork-column-right">
                      <Form.Group controlId="formArtworkDescription">
                        <div className="ca-paragraph-title w-100">{translatedMessage("ARTWORK.ABOUT_AUTHOR")}</div>
                        <div className="ca-text-editor">
                          <SunEditor
                            name="about-author"
                            lang={LanguageService.getCurrentLanguage()}
                            placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                            setContents={this.state.artwork.aboutAuthor}
                            setOptions={{
                              height: 200,
                              maxWidth: "100%",
                              buttonList: this.state.editorButtns,
                              font: this.state.editorFonts,
                              defaultStyle: this.state.editorDefaultStyle
                            }}
                            onChange={this.onChangeAuthorEditor}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col className="col-12 col-lg-12 ca-artwork-column-left">
                {/* {this.state.modelEnabled && this.state.showObjectModel &&
                  <Row className="mb-3">
                    <Col className="col-12 pl-0 pr-0">
                      <div className="ca-paragraph-title">
                        {translatedMessage("ARTWORK.OBJECT_MODEL")}
                      </div>

                      <div className="ca-model-viewer-panel">
                        <div className={object_class}>
                          <ModelViewer modelFile={this.state.modelFileObject} artworkName={this.state.artwork.name} styleClass="ca-model-viewer-small" />
                          <div className="ca-form-object-change-details" onClick={this.changeObjectDropzone.bind(this)}>
                            <FontAwesomeIcon icon={["fas", "cloud-upload-alt"]} style={{ fontSize: "32px" }}
                              title={translatedMessage("GENERAL.UPLOAD.FILE")} />
                          </div>
                        </div>
                      </div>
                      <div className={dropzone_class}>
                        <DropzoneComponent
                          componentId="modelFile"
                          fileUsage={FileService.getFileUsage().OBJECT_MODEL}
                          fileType={FileService.getResourceType().OBJECT_MODEL}
                          deletePrevious={true}
                          deletedImage={this.state.modelFile}
                          deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_FILE"
                          fileFormatsDesc="DROPZONE.FILE_TYPES.OBJECT"
                          maxSize={10}
                          maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                          multipleFiles={false}
                          multipleFilesDesc=""
                          entity={this.state.artwork}
                          storageitemlinked={true}
                          onupload={this.onUploadModelFile}
                        />
                        {this.state.modelFileObject &&
                          <div style={{ textAlign: "center" }}>
                            <div style={{ fontFamily: "FontAwesome", fontSize: "32px", cursor: "pointer" }} onClick={this.changeObjectDropzone.bind(this)}>&#xf021;</div>
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                } */}

                <Row className="mb-0">
                  <Col className="col-12 col-lg-6 ca-artwork-column-left">
                    <div className="ca-paragraph-title w-100 mb-3">
                      <span>
                        {translatedMessage("PROJECT.UPLOAD_PREZ_IMG")}
                        <OverlayTrigger
                          key='addImage'
                          placement='bottom'
                          overlay={
                            <Tooltip id={`tooltip-addImage`} className="ca-tooltip">
                              {translatedMessage("ARTWORK.ADD_PRESENTATION_IMAGE_TOOLTIP")}
                            </Tooltip>
                          }
                        >
                          <span className="ca-question-icon"></span>
                        </OverlayTrigger>
                      </span>

                      <span className="d-inline-block">
                        <Button id="btnPresImg" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                          <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />
                        </Button>
                      </span>
                    </div>
                    {this.state.presentationImages.length > 0 &&
                      <Carousel
                        id="presImg"
                        items={this.state.presentationImages}
                        controls={true}
                        indicators={false}
                        interval={null}
                        class="mb-5"
                        hasToolbar={true}
                        toolbarDelete={true}
                        toolbarFullScreen={true}
                        canDeleteLast={false}
                        onChange={this.handleMediaListChange}
                      />
                    }
                  </Col>
                  <Col className="col-12 col-lg-6 ca-artwork-column-right">
                    <div className="ca-paragraph-title w-100 mb-3">
                      <span>
                        {translatedMessage("PROJECT.VIDEOS")}
                      </span>

                      <span className="d-inline-block">
                        <Button id="btnVideo" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                          <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} title={translatedMessage("GENERAL.ADD")} />                          
                          {this.state.artworkVideos.length > 0 &&
                            <Image src={editIcon} alt={translatedMessage("GENERAL.CHANGE")} title={translatedMessage("GENERAL.CHANGE")} />
                          }
                        </Button>
                      </span>
                    </div>
                    {this.state.artworkVideos.length > 0 &&
                      <Carousel
                        id="video"
                        items={this.state.artworkVideos}
                        controls={true}
                        indicators={false}
                        interval={null}
                        class="mb-5"
                        hasToolbar={true}
                        toolbarDelete={true}
                        toolbarYoutubeId={true}
                        canDeleteLast={true}
                        onChange={this.handleMediaListChange}
                      />
                    }
                  </Col>
                </Row>
              </Col>              
            </Row>

            <AddFileModalComponent
              show={this.state.showAddFileModal}
              onHide={this.onHideAddFile}
              data={this.state.fileModalData}
            />

            <ChooseVideoTypeComponentModal
              show={this.state.showVideoTypeSelectModal}
              onHide={this.onHideVideoTypeSelect}
              data={this.state.artworkVideos}
            />
          </div>
        )
      }
    }
  }

}

export default ArtworkEditComponent;