import React from 'react';

import ApiService from "../../../../services/api.service";

import profilepic from '../../../../components/shared/Resources/defaultProfilePicture.png';
import ProfileEditComponent from '../../../../components/shared/Profile/ProfileEditComponent';

import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: {
                profilePicture: profilepic,
                profileName: "",
                memberSince: '',
                badges: '',
                presentationVideo: null,
                aboutMe: "",
            },
            webResources: [],
            activityFields: "",            
            isLoaded: false
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        let profile = ApiService.getCurrentProfile();
        if (!profile) {
            ApiService.initUser().then(() => this.getData());
        } else {
            this.getData();
        }
    }

    getData() {
        let profile = ApiService.getCurrentProfile();
        let user = ApiService.getCurrentUser();

        profile.profileCreatedOn = ApiService.parseDate(profile.profileCreatedOn).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' });
        profile.profilePicture = profile.photo ? `${ApiService.getBaseUrl()}/storage/file/${profile.photo.uuid}` : profilepic;
        if(profile.address){
            profile.address.countryId = profile.address.country.id;
        }

        this.setState({
            profile: profile,
            userId: user.id,
            username: user.username,
            webResources: profile.profileLinks,
            isLoaded: true
        });
    }

    componentWillUnmount() {
        ApiService.setCurrentUser(null);
        ApiService.setCurrentProfile(null);
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else if (!this.state.isError) {
            return (
                <ProfileEditComponent
                    userId={this.state.userId}
                    username={this.state.username}
                    profile={this.state.profile}
                    webResources={this.state.webResources}
                    portfolioLink={`/artworks`}
                    history={this.props.history}
                />                
            )
        } else {
            return (
                <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
            )
        }
    }
}

export default ProfilePage;
