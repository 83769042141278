const LanguageService = {
    getCurrentLanguage: () => {
        const lang = localStorage.getItem("lang");
        if(!lang){
            return "en";
        }
        return lang;
    },
    setCurrentLanguage: (lang) => {
        localStorage.setItem("lang", lang);     
    },  
};

export default LanguageService;

export function translatedMessage(key) {
    return window.$translate(LanguageService.getCurrentLanguage(), key)
};