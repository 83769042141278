import React from 'react';

import { Row, Col, Button, Form, OverlayTrigger, Tooltip, Image } from 'react-bootstrap/';

import DropzoneComponent from "../../../components/shared/DropzoneComponent/DropzoneComponent";
import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import arPortalIcon from '../../../components/shared/Resources/icons/arPortal.svg';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';
import { hasPermission } from '../../../services/api.service';

import { toast } from "react-toastify";

class ArPortalEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : null,
      arPortal: {
        name: '',
        nrArArt: '',
        nrSculpture: ''
      },
      arPortalFile: null,
      arPortalFile1: null,
      arPortalMap: null,
      arPortalPicture: null,
      dropzoneEnabled: true,
      isLoaded: false,
      isNew: false,
      isError: false,
    };

    this.onUploadArPortalMap = this.onUploadArPortalMap.bind(this);
  }

  componentDidMount() {
    ApiService.initCurrentUser().then(() => {
      const canEdit = this.props.match.params.id ? hasPermission("AR_PORTAL_EDIT") : hasPermission("AR_PORTAL_CREATE");

      this.setState({
        canEdit: canEdit,
      }, this.getData)
    });
  }

  async getData() {
    let isNew = false;
    let isError = false

    if (this.state.canEdit) {
      let arPortal = this.state.arPortal
      let arPortalFiles = null;
      let arPortalMap = null;

      if (this.state.id !== null) {
        arPortal = await ApiService.getAuthenticatedInstance().get(`/virtualGallery/arPortal/${this.state.id}`)
          .then(response => {
            return response.data;
          })
          .catch(err => {
            isError = true;
          })

        if (arPortal) {
          const storageItemFiles = await ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${arPortal.id}/${ApiService.getEntityName().AR_PORTAL}`)
            .then(response => {
              return response.data.fileList;
            })
            .catch(() => {
              isError = true;
            })

          arPortalFiles = storageItemFiles.filter(item => item.usage === FileService.getFileUsage().AR_PORTAL);
          arPortalMap = storageItemFiles.filter(item => item.usage === FileService.getFileUsage().AR_PORTAL_MAP)[0];

        } else {
          isError = true;
        }

      } else {
        isNew = true;
      }

      if (!isError) {
        let arPortalFile = null;
        let arPortalFile1 = null;
        if (!isNew) {
          arPortalFile = arPortalFiles.filter(item => item.mobilePlatform === 'IOS')[0];
          arPortalFile1 = arPortalFiles.filter(item => item.mobilePlatform === 'ANDROID')[0];
        }

        this.setState({
          arPortal: arPortal,
          arPortalFile: arPortalFile,
          arPortalFile1: arPortalFile1,
          arPortalMap: arPortalMap ? arPortalMap : null,
          mapImages: arPortalMap ? [FileService.handleFileForCarousel(arPortalMap)] : [],
          isNew: isNew,
          isLoaded: true
        })
      } else {
        this.setState({
          isError: true,
          errorMessage: translatedMessage("AR_PORTAL.GENERAL_ERROR"),
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: translatedMessage("USER.SECURITY.MISSING_RIGHTS"),
        isLoaded: true
      })
    }
  }

  validate = () => {
    var response = {
      isError: false,
      message: ""
    };

    return response;
  }

  saveChanges = (event) => {
    event.preventDefault();

    const validation = this.validate(event);
    if (!validation.isError) {
      const id = this.state.id === null ? 0 : this.state.id;

      ApiService.getAuthenticatedInstance().post(`/virtualGallery/saveArPortal`, {
        id: parseInt(id),
        name: event.target.elements.formName.value,
        nrArArt: parseInt(event.target.elements.formArArtNo.value),
        nrSculpture: parseInt(event.target.elements.formSculptureNo.value),
      })
        .then(response => {
          toast.success(translatedMessage(response.data.message));
          if (id === 0) {
            const portalId = response.data.arPortal.id;
            this.setState({
              id: response.data.arPortal.id,
            })
            this.props.history.push(`/arPortalEdit/${portalId}`)
          }
        })
        .catch(error => {
          console.log("An error has ocurred: " + error);
          toast.error(translatedMessage(error.response.data.message));
        });
    } else {
      toast.error(validation.message);
    }
  }

  onUploadArPortalFile = (dropzoneEnabled, uploadedFile) => {
    let arPortalFile = this.state.arPortalFile;
    let arPortalFile1 = this.state.arPortalFile1;

    if (uploadedFile) {
      if (uploadedFile.mobilePlatform === 'IOS') {
        arPortalFile = uploadedFile;
      } else if (uploadedFile.mobilePlatform === 'ANDROID') {
        arPortalFile1 = uploadedFile;
      }
    }

    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          arPortalFile: arPortalFile,
          arPortalFile1: arPortalFile1,
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        arPortalFile: arPortalFile,
        arPortalFile1: arPortalFile1,
      });
    }
  }

  onUploadArPortalMap = (dropzoneEnabled, uploadedFile) => {
    let mapImages = [];
    if(uploadedFile) {
      mapImages.push(FileService.handleFileForCarousel(uploadedFile));
    }

    if (dropzoneEnabled) {
      setTimeout(() => {
        this.setState({
          dropzoneEnabled: dropzoneEnabled,
          arPortalMap: uploadedFile,
          mapImages: mapImages
        })
      }, 500);
    } else {
      this.setState({
        dropzoneEnabled: dropzoneEnabled,
        arPortalMap: uploadedFile,
        mapImages: mapImages
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-1 pr-2">
              <div className="ca-page-subtitle">
                <span className="font-weight-bold">{this.state.errorMessage}</span>&nbsp;
              </div>
              <div>
                <Button onClick={() => this.props.history.go(-1)} className="ca-button ca-button-white">
                  <span>&#xf053; &nbsp;</span>
                  {translatedMessage("BUTTON.BACK")}
                </Button>
              </div>
            </Row>
          </div>
        )
      } else {
        let resourceType = this.state.arPortalFile ? this.state.arPortalFile.resourceType : { label: 'CONTENT_RESOURCE_TYPE.UNITY_SCENE', name: 'UNITY_SCENE' };

        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-3">
              <div className="d-flex align-items-center">
                <Image src={arPortalIcon} className="ca-page-title-icon" alt={translatedMessage("AR_PORTAL")} />
                <span className="ca-page-title">{translatedMessage("AR_PORTAL")}</span>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon medium" variant="dark" form="arPortal-form" type="submit" >
                  <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
                </Button>

                <Button className="ca-dark-link ca-button-icon" variant="dark" onClick={() => this.props.history.push("/arPortalList")}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            <Form id="arPortal-form" className="ca-form" onSubmit={this.saveChanges}>
              <Row>
                <Col className="col-12 col-md-6">
                  <Form.Group controlId="formName">
                    <Form.Label>{translatedMessage("GENERAL.NAME")}</Form.Label>
                    <Form.Control
                      required
                      placeholder={translatedMessage("AR_PORTAL.NAME_PLACEHOLDER")}
                      defaultValue={this.state.arPortal.name}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-12 col-md-6">
                  <Form.Group controlId="formArArtNo">
                    <Form.Label>
                      {translatedMessage("AR_PORTAL.AR_ART_NO_DESC")}
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="1"
                      min="0"
                      placeholder={translatedMessage("AR_PORTAL.AR_ART_NO_PLACEHOLDER")}
                      defaultValue={this.state.arPortal.nrArArt}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSculptureNo">
                    <Form.Label>{translatedMessage("AR_PORTAL.SCULPTURE_NO_DESC")}</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      step="1"
                      min="0"
                      placeholder={translatedMessage("AR_PORTAL.AR_ART_NO_PLACEHOLDER")}
                      defaultValue={this.state.arPortal.nrSculpture}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>

            {!this.state.isNew &&
              <>
                <Row>
                  <Col className="col-12">
                    <div className='font-bold'>
                      <Form.Label>
                        {translatedMessage("AR_PORTAL.AR_PORTAL_FILE")}
                        <OverlayTrigger
                          key='hA'
                          placement='bottom'
                          overlay={
                            <Tooltip id={`tooltip-hA`} className="ca-tooltip">
                              {translatedMessage("AR_PORTAL.AR_PORTAL_FILE_DESC")}
                            </Tooltip>
                          }
                        >
                          <span className="ca-question-icon"></span>
                        </OverlayTrigger>
                      </Form.Label>
                    </div>
                  </Col>

                  <Col className="col-12 col-md-6">
                    <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.IOS") +
                      (this.state.arPortalFile ? " (" + this.state.arPortalFile.name + ")" : "")}</div>
                    <DropzoneComponent
                      componentId="arPortalFile"
                      fileUsage={FileService.getFileUsage().AR_PORTAL}
                      fileType={FileService.getResourceType().UNITY_SCENE}
                      resourceType={resourceType && resourceType.name}
                      mobilePlatform={'IOS'}
                      deletePrevious={true}
                      deletedImage={this.state.arPortalFile}
                      deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_AR_PORTAL"
                      fileFormatsDesc="DROPZONE.FILE_TYPES.ARCHIVE"
                      maxSize={150}
                      maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                      multipleFiles={false}
                      multipleFilesDesc=""
                      entity={this.state.isNew ? null : this.state.arPortal}
                      storageitemlinked={!this.state.isNew}
                      onupload={this.onUploadArPortalFile}
                    />
                  </Col>

                  <Col className="col-12 col-md-6">
                    <div className='mb-2'>{translatedMessage("ART_CONTENT.RESOURCE.ANDROID") +
                      (this.state.arPortalFile1 ? " (" + this.state.arPortalFile1.name + ")" : "")}</div>
                    <DropzoneComponent
                      componentId="arPortalFile1"
                      fileUsage={FileService.getFileUsage().AR_PORTAL}
                      fileType={FileService.getResourceType().UNITY_SCENE}
                      resourceType={resourceType && resourceType.name}
                      mobilePlatform={'ANDROID'}
                      deletePrevious={true}
                      deletedImage={this.state.arPortalFile1}
                      deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_AR_PORTAL"
                      fileFormatsDesc="DROPZONE.FILE_TYPES.ARCHIVE"
                      maxSize={150}
                      maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                      multipleFiles={false}
                      multipleFilesDesc=""
                      entity={this.state.isNew ? null : this.state.arPortal}
                      storageitemlinked={!this.state.isNew}
                      onupload={this.onUploadArPortalFile}
                    />
                  </Col>
                </Row>


                <Row>
                  <Col className="col-12 col-md-6 d-flex align-items-center">
                    <Form.Group controlId="formArPortalMap" className="w-100">
                      <Form.Label>
                        {translatedMessage("AR_PORTAL.AR_PORTAL_MAP") +
                          (this.state.arPortalMap ? " (" + this.state.arPortalMap.name + ")" : "")}
                        <OverlayTrigger
                          key='hM'
                          placement='bottom'
                          overlay={
                            <Tooltip id={`tooltip-hM`} className="ca-tooltip">
                              {translatedMessage("AR_PORTAL.AR_PORTAL_MAP_INFO")}
                            </Tooltip>
                          }
                        >
                          <span className="ca-question-icon"></span>
                        </OverlayTrigger>
                      </Form.Label>

                      <DropzoneComponent
                        componentId="arPortalMap"
                        fileUsage={FileService.getFileUsage().AR_PORTAL_MAP}
                        fileType={FileService.getResourceType().IMAGE}
                        deletePrevious={true}
                        deletedImage={this.state.arPortalMap}
                        deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_IMAGE"
                        fileFormatsDesc="DROPZONE.FILE_TYPES.IMAGE"
                        maxSize={10}
                        maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                        multipleFiles={false}
                        multipleFilesDesc=""
                        entity={this.state.isNew ? null : this.state.arPortal}
                        storageitemlinked={!this.state.isNew}
                        onupload={this.onUploadArPortalMap}
                      />
                    </Form.Group>
                  </Col>
                  {this.state.arPortalMap &&
                    <Col className="col-12 col-md-6 text-align-center">
                      <Carousel
                        id="mapImg"
                        items={this.state.mapImages}
                        controls={true}
                        indicators={false}
                        interval={null}
                        class="mx-auto mb-5 "
                        hasToolbar={true}
                        toolbarDelete={false}
                        toolbarFullScreen={true}
                      />

                    </Col>
                  }
                </Row>
              </>
            }
          </div>
        )
      }
  }

}

export default ArPortalEditPage;