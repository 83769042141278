import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import myPortfolio from '../../components/shared/Resources/homepage/myPortfolio.svg';
import myProjects from '../../components/shared/Resources/homepage/myProjects.svg';

import { translatedMessage } from '../../services/language.service';

class PortfolioHomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <>
                    <div className="ca-page-padding ca-main-container h-100 mt-0 mb-0">
                        <Row className="h-100">
                            <Col className="col-12 col-sm-6 mb-5 d-flex align-items-center">
                                <Link to="/artworks" className="ca-dark-link w-100">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={myPortfolio} className="ca-homepage-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PORTFOLIO")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PORTFOLIO")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="col-12 col-sm-6 mb-5 d-flex align-items-center">
                                <Link to="/projects" className="ca-dark-link w-100">
                                    <div className="ca-homepage-icon-container">
                                        <Image src={myProjects} className="ca-homepage-icon" alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                                        <div className="ca-homepage-icon-title">{translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}</div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        }
    }
}

export default PortfolioHomePage;