import ApiService from "./api.service";
import { translatedMessage } from "./language.service";
import FileService from "./file.service";

import calogo from '../components/shared/Resources/calogo-black.svg';
import profilepic from '../components/shared/Resources/defaultProfilePicture.png';

import moment from 'moment';

const ArtworkService = {
  artworkStatusEnum: () => {
    return {
      DRAFT: "DRAFT",
      PUBLISHED: "PUBLISHED",
      UNPUBLISHED: "UNPUBLISHED",
      SUBMITTED_FOR_APPROVAL: "SUBMITTED_FOR_APPROVAL",
      PREVIEW: "PREVIEW"
    };
  },

  artworkCategoryEnum: () => {
    return {
      AR_ART: "AR_ART",
      OBJECT: "3D_OBJECT",
      HOLOGRAPHIC: "HOLOGRAPHIC",
      DIGITAL_PICTURE: "DIGITAL_PICTURE",
      AR_PORTAL: "AR_PORTAL"
    };
  },

  getArtworkStatusList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/artworkStatusList`)
        .then(response => {
          var statusList = response.data;
          statusList.sort((a, b) => {
            var result;
            parseInt(a.order, 10) <= parseInt(b.order, 10) ? result = -1 : result = 1
            return result;
          });

          resolve(statusList)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getProjectStatusList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/projectStatusList`)
        .then(response => {
          var statusList = response.data;
          statusList.sort((a, b) => {
            var result;
            parseInt(a.order, 10) <= parseInt(b.order, 10) ? result = -1 : result = 1
            return result;
          });

          resolve(statusList)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  addAddOnAtFileUpload(fileId, index, artworkId, uuid, experiencesCount) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post('/addOns', {
        name: translatedMessage("ADD_ON.EXPERIENCE") + "_" + index,
        description: "",
        isMain: experiencesCount === 0 ? true : false,
        file: `/files/${fileId}`,
        author: `/users/${ApiService.getCurrentUser().id}`,
        price: 0,
        status: 'DRAFT',
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        artwork: `/artworks/${artworkId}`,
      })
        .then(response => {
          const parts = response.data._links.self.href.split('/');
          const id = parts[parts.length - 1];
          ApiService.getAuthenticatedInstance().post(`/artwork/addAddOnPricePackages/${id}`)
            .then(resolve)
            .catch(err => reject(err));
        })
        .catch(err => {
          FileService.deleteFile(uuid);
          reject(err);
        });
    });
  },

  getArtworksByCriteria(fetchAll, status, categoryId, searchKey) {
    let path = "?status=" + (status ? status : "ALL");
    path = path + "&categoryId=" + (categoryId ? categoryId : "0");
    path = path + "&search=" + (searchKey ? searchKey : "");
    path = path + "&fetchAll=" + (fetchAll ? fetchAll : false);

    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/artwork/byCriteria${path}`)
        .then(response => {
          let artworks = response.data;
          artworks.map((element) => {
            element.image = element.thumbUuid === null ? calogo : ApiService.getFileByUuid(element.thumbUuid) + '?' + Date.now();
            element.author = ArtworkService.getAuthorNameFromMap(element.authorList)

            return element
          });

          artworks.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getArtworksForCompany(companyId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`/artwork/for-company/${companyId}/byCriteria`)
        .then(response => {
          let artworks = response.data;
          artworks.map((element, index) => {
            element.image = element.id === null ? calogo : ApiService.getThumbnailByUrl(element.id);
            element.author = ArtworkService.getAuthorNameFromMap(element.authorList)

            return element
          });

          artworks.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getGalleryArtwork(galleryId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/artworks/${galleryId}`)
        .then(response => {
          let artworks = response.data
          artworks.map((element, index) => {
            element.image = element.artwork.id === null ? calogo : ApiService.getThumbnailByUrl(element.artwork.id)
            element.author = ArtworkService.getAuthorNameFromMap(element.artwork.authorList)
            return element
          });

          artworks.sort((a, b) => {
            var result;
            a.artwork.name.toUpperCase() <= b.artwork.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getGalleryArt(galleryId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/art/${galleryId}`)
        .then(response => {
          let artworks = response.data
          artworks.map((element, index) => {
            element.image = element.thumbUuid === null ? calogo : ApiService.getFileByUuid(element.thumbUuid);
            return element;
          });

          artworks.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getExhibitionArtwork(exhibitionId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/exhibition/${exhibitionId}/artworks/manage?0&search=${""}`)
        .then(response => {
          let artworks = response.data
          artworks.map((element, index) => {
            element.image = element.artwork.id === null ? calogo : ApiService.getThumbnailByUrl(element.artwork.id)
            element.author = ArtworkService.getAuthorNameFromMap(element.artwork.authorList);

            return element
          });

          artworks.sort((a, b) => {
            var result;
            a.artwork.name.toUpperCase() <= b.artwork.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getExhibitionArt(exhibitionId) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`gallery/exhibition/art/${exhibitionId}`)
        .then(response => {
          let artworks = response.data

          artworks.map((element, index) => {
            element.image = element.thumbUuid === null ? calogo : ApiService.getFileByUuid(element.thumbUuid);
            return element;
          });

          artworks.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getCollection() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`artwork/collection/artwork`)
        .then(response => {
          let collection = response.data

          collection.map((element, index) => {
            element.image = element.artwork.thumbUuid
              ? ApiService.getFileByUuid(element.artwork.thumbUuid)
              : (element.artwork.id === null ? calogo : ApiService.getThumbnailByUrl(element.artwork.id));
            element.author = ArtworkService.getAuthorNameFromMap(element.artwork.authorList);

            return element
          });

          collection.sort((a, b) => {
            var result;
            a.artwork.name.toUpperCase() <= b.artwork.name.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(collection);
        })
        .catch(err => { reject(err) });
    })
  },

  getArtworkCategoryList() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`artworkCategories`)
        .then(response => {
          let categories = response.data._embedded.artworkCategories
          categories.map((element, index) => {
            const parts = element._links.self.href.split('/');
            element.id = parts[parts.length - 1];
            return element;
          });

          resolve(categories)
        })
        .catch(err => { reject(err) });
    });
  },

  getArtworkAuthor: artworkId => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`artwork/artworkAuthor/${artworkId}`)
        .then(response => resolve(response.data))
        .catch(err => { reject(err) });
    });
  },

  addArtworkToGallery(galleryId, artworks) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/gallery/addArtworkToGallery/${galleryId}?artworks=${artworks}`)
        .then(resolve)
        .catch(err => {
          reject(err);
        });
    });
  },

  addArtworkToExhibition(exhibitionId, artworks) {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().post(`/gallery/addArtworkToExhibition/${exhibitionId}?artworks=${artworks}`)
        .then(resolve)
        .catch(err => {
          reject(err);
        });
    });
  },

  getAuthorName(authors) {
    let authorName = ""
    authors.forEach((item, index) => {
      let name = item.user.profileName !== null ? item.user.profileName : item.user.firstName + " " + item.user.lastName;
      if (index === 0) {
        authorName = name
      } else {
        authorName += ", " + name;
      }
    })
    return authorName
  },

  getAuthorNameFromMap(authors) {
    let authorName = ""
    authors.forEach((item, index) => {
      if (index === 0) {
        authorName = item.name
      } else {
        authorName += ", " + item.name;
      }
    })
    return authorName
  },

  getArtworksAuthor(artworks) {
    let authors = []
    artworks.forEach(art => {
      let authorList = art.author;
      authorList.forEach((author, index) => {
        let user = author.user;
        if (!user.profileName) {
          user.profileName = user.firstName + " " + user.lastName;
        }
        user.profilePicture = user.photo !== null ? `${ApiService.getBaseUrl()}/storage/file/${user.photo.uuid}` : profilepic;
        authors.push(user)
      })
    })
    authors = authors.filter((element, index) => index === authors.findIndex(elem => elem.id === element.id))
    authors = authors.sort((a, b) => {
      var result;
      a.profileName.toUpperCase() <= b.profileName.toUpperCase() ? result = -1 : result = 1;
      return result
    })
    return authors
  },

  async getArtworkForEdit(artworkId, isAuthor) {
    let artwork = {};
    if (isAuthor) {
      artwork = await ApiService.getAuthenticatedInstance().get(`/artwork/portfolioArtwork/${artworkId}`)
        .then(response => { return response.data.artwork })
    } else {
      artwork = await ApiService.getAuthenticatedInstance().get(`/artwork/artworkManage/${artworkId}`)
        .then(response => { return response.data.artwork })
    }
    return artwork;
  },

  getArtCategoryList: () => {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`enum/artCategoryList`)
        .then(response => {
          var list = response.data;
          list.sort((a, b) => {
            var result;
            a.label.toUpperCase() <= b.label.toUpperCase() ? result = -1 : result = 1
            return result;
          });

          resolve(list)
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  hasInvestorPackage: (pricePackages) => {
    pricePackages = pricePackages.filter(item => item.investorPackage)
    if (pricePackages[0] && pricePackages[0].active) {
      return true;
    }

    return false;
  },

  hasDigitalPrint: (pricePackages) => {
    pricePackages = pricePackages.filter(item => item.hasDigitalPrint && item.active)
    if (pricePackages[0]) {
      return true;
    }

    return false;
  },

  getDigitalPrintQuantity: (pricePackages) => {
    pricePackages = pricePackages.filter(item => item.hasDigitalPrint && item.active)
    if (pricePackages[0]) {
      return pricePackages[0].digitalPrintQuantity;
    }

    return 0;
  },

  computePricePackageData: (pricePackages) => {
    return pricePackages.map(artworkPricePackage => {
      artworkPricePackage.description = translatedMessage(artworkPricePackage.description);
      artworkPricePackage.isOneOfKind = artworkPricePackage.name === 'One of a Kind';
      artworkPricePackage.hasInvestorPackage = false;
      artworkPricePackage.now = Date.now();

      return {
        ...artworkPricePackage,
      }
    })
      .filter(item => !item.investorPackage)
      .sort((a, b) => a.order - b.order);
  },

  getDemoArt() {
    return new Promise((resolve, reject) => {
      ApiService.getAuthenticatedInstance().get(`artwork/demo_art`)
        .then(response => {
          let artworks = response.data
          artworks.map((element, index) => {
            element.image = element.photoUuid === null ? calogo : ApiService.getFileByUuid(element.photoUuid)

            return element
          });

          artworks.sort((a, b) => {
            var result;
            a.artName.toUpperCase() <= b.artName.toUpperCase() ? result = -1 : result = 1
            return result;
          });
          resolve(artworks);
        })
        .catch(err => { reject(err) });
    })
  },

  getArtByIdAndType(id, type, forProject) {
    return new Promise((resolve, reject) => {
      var errorMessage = "PROJECT.GENERAL_ERROR";

      if (forProject) {
        // if for publishing process
        if (type === ApiService.getEntityName().ARTWORK) {
          ApiService.getAuthenticatedInstance().get(`/artwork/existing/${id}`)
            .then(response => {
              let artwork = response.data

              resolve(artwork);
            })
            .catch(err => {
              if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
                errorMessage = err.response.data.message;
              }
              reject(errorMessage)
            });
        } else if (type === ApiService.getEntityName().VIRTUAL_GALLERY) {
          ApiService.getAuthenticatedInstance().get(`/virtualGallery/project/${id}`)
            .then(response => {
              let virtualGallery = response.data

              resolve(virtualGallery);
            })
            .catch(err => {
              if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
                errorMessage = err.response.data.message;
              }
              reject(errorMessage)
            });
        }
      } else {
        if (type === ApiService.getEntityName().VIRTUAL_GALLERY) {
          ApiService.getAuthenticatedInstance().get(`/virtualGallery/${id}/manage`)
            .then(response => {
              let virtualGallery = response.data

              resolve(virtualGallery);
            })
            .catch(err => {
              if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
                errorMessage = err.response.data.message;
              }
              reject(errorMessage)
            });
        }
      }
    })
  },

  getPricePackagesByArtId(id, type) {
    return new Promise((resolve, reject) => {
      var errorMessage = "PROJECT.GENERAL_ERROR";

      if (type === ApiService.getEntityName().ARTWORK) {
        ApiService.getAuthenticatedInstance().get(`/artwork/${id}/artworkPricePackages`)
          .then(response => {
            let pricePackages = response.data.pricePackages

            resolve(pricePackages);
          })
          .catch(err => {
            if (err && err.response && err.response.status && 404 === err.response.status) {
              errorMessage = err.response.data.message;
            }
            reject(errorMessage)
          });
      } else if (type === ApiService.getEntityName().VIRTUAL_GALLERY) {
        ApiService.getAuthenticatedInstance().get(`/virtualGallery/${id}/artworkPricePackages`)
          .then(response => {
            let pricePackages = response.data.pricePackages

            resolve(pricePackages);
          })
          .catch(err => {
            if (err && err.response && err.response.status && 404 === err.response.status) {
              errorMessage = err.response.data.message;
            }
            reject(errorMessage)
          });
      }
    })
  },

  getArtworkDigitalPrintFiles(entityId, entityName) {
    return new Promise(async (resolve, reject) => {

      let printImages = await ApiService.getAuthenticatedInstance().get(`/storage/storageItem/files/${entityId}/${entityName}`)
        .then(results => {
          let resultFiles = FileService.handleFilesForCarousel(results.data.fileList, FileService.getFileUsage().PRINT_FILE);

          return resultFiles;
        })

      resolve(printImages);
    })
  },

  setActiveArtworkPricePackage(id) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().post(`/artwork/setActiveArtworkPricePackage/${id}`)
        .then(result => {
          resolve(result.data.pricePackages);
        })
        .catch(err => reject(err))
    })
  },

  saveArtist(artworkId, artistId) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().patch(`/artwork/${artworkId}/artist/save`, {
        artistId: artistId,
      })
        .then(resolve)
        .catch(err => {
          let errorMessage = "GENERAL.SAVE_ERROR";

          if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);

          reject(errorMessage)
        });
    })
  },

  removeArtist(artworkId) {
    return new Promise(async (resolve, reject) => {
      await ApiService.getAuthenticatedInstance().patch(`/artwork/${artworkId}/artist/remove`)
        .then(resolve)
        .catch(err => {
          let errorMessage = "GENERAL.SAVE_ERROR";

          if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);

          reject(errorMessage)
        });
    })
  },

  async artworkAddTag(chip, artworkId, artworkTagObjects) {
    return new Promise(async (resolve) => {
      ApiService.getAuthenticatedInstance().post('/tags', {
        name: chip,
        lastUpdatedBy: ApiService.getCurrentUser().username,
        lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      }).then(tagResult => {
        const parts = tagResult.data._links.self.href.split('/');
        const tagId = parts[parts.length - 1];

        ApiService.getAuthenticatedInstance().post('/artworkTags', {
          artwork: `/artworks/${artworkId}`,
          tag: `/tags/${tagId}`,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }).then(result => {
          let newArtworkTagObjects = artworkTagObjects.concat([{
            ...result.data,
            additional: { tag: chip }
          }])

          resolve(newArtworkTagObjects)
        });
      });
    });
  },

  async artworkRemoveTag(chip, artworkTagObjects) {
    const toRemove = artworkTagObjects.find(obj => obj.additional.tag === chip);
    const parts = toRemove._links.self.href.split('/');
    const toRemoveId = parts[parts.length - 1];

    return new Promise(async (resolve) => {
      await ApiService.getAuthenticatedInstance().delete(`/artworkTags/${toRemoveId}`)
        .then(() => {
          const toRemoveIndex = artworkTagObjects.findIndex(obj => obj.additional.tag === chip);
          let newArtworkTagObjects = artworkTagObjects;

          newArtworkTagObjects.splice(toRemoveIndex, 1);

          resolve(newArtworkTagObjects);
        });
    })
  }

};

export default ArtworkService;