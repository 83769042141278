import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { translatedMessage } from '../../../services/language.service';
import CompanyService from '../../../services/company.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import DisplayVideoComponent from '../../../components/shared/DisplayVideoComponent/DisplayVideoComponent';

import backIcon from '../../../components/shared/Resources/left-arrow.svg';
import exhibitionIcon from '../../../components/shared/Resources/icons/exhibition.svg';
import galleryIcon from '../../../components/shared/Resources/icons/gallery.svg';
import virtualGallertIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import mediaIcon from '../../../components/shared/Resources/icons/media.svg';
import challengeIcon from '../../../components/shared/Resources/icons/challenge.svg';
import ApiService from '../../../services/api.service';

class CompanySpacePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      presImg: null,
      hasPresMedia: false
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    CompanyService.getCompanySpace(this.state.companyId)
      .then(response => {
        let company = response;

        this.setState({
          company: company,
          hasPresMedia: company.presentationImageUuid || company.presentationVideoUrl ? true : false,
          presImg: company.presentationImageUuid ? `${ApiService.getBaseUrl()}/storage/file/${company.presentationImageUuid}` : null,
          isLoaded: true
        })
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center justify-content-end">
            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon" onClick={() => this.props.history.push(`/company/spaces`)}>
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <div className="d-flex flex-sm-row flex-column" style={{ marginBottom: "2rem" }}>
            <div className="ca-company-logo-container view d-flex align-items-center justify-content-center">
              <img
                alt="company logo"
                src={this.state.company.logo}
                className="d-inline-block ca-company-logo"
              />
            </div>

            <div className="w-100 d-flex align-content-center flex-wrap ca-company-space-detail-container">
              <div className='w-100 mb-2'>
                <span className="font-bold">
                  {translatedMessage("COMPANY.SPACE.NAME") + ": "}
                </span>
                <span>{this.state.company.spaceName}</span>
              </div>
              {this.state.company.spaceUrl &&
                <div className='w-100'>
                  <span className="font-bold">
                    {translatedMessage("COMPANY.SPACE.URL") + ": "}
                  </span>
                  <a href={this.state.company.spaceUrl} target="_blank" rel="noopener noreferrer" className="ca-link-white">{this.state.company.spaceUrl}</a>
                </div>
              }
            </div>
          </div>

          <div className='ca-profile-social-container'>
            {
              this.state.company.socialMediaAccountList.map((element, index) => {
                return (
                  <a key={index} href={element.url} target="_blank" rel="noopener noreferrer" className="ca-dark-link mr-3">
                    <FontAwesomeIcon icon={["fab", ApiService.getSocialMediaAccountIconByType(element.type.name)]} />
                  </a>
                )
              })
            }
          </div>

          <Row className='align-items-top mb-5'>
            <Col className={"pl-0 pr-0 col-12".concat(this.state.hasPresMedia ? " col-md-6 col-lg-11" : "")}>
              {this.state.company.spaceDescription &&
                <div>
                  {this.state.company.spaceDescription &&
                    <span dangerouslySetInnerHTML={{ __html: this.state.company.spaceDescription }} className='ca-description-value' />
                  }
                </div>
              }
            </Col>

            {this.state.hasPresMedia &&
              <Col className="col-12 col-md-6 col-lg-1">
                <div className="ca-profile-media">
                  {this.state.company.presentationVideoUrl &&
                    <DisplayVideoComponent youtubeId={this.state.company.presentationVideoUrl}
                      classimage=""
                      classvideo="ca-youtube-video-container" />
                  }

                  {this.state.presImg && !this.state.company.presentationVideoUrl &&
                    <Image src={this.state.presImg} className='presentation-image' />
                  }
                </div>
              </Col >
            }
          </Row >

          <Row className='mb-0'>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/company/${this.state.company.id}/gallery`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={galleryIcon} className="ca-homepage-icon" alt={translatedMessage("GALLERY.GALLERY")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("GALLERY.GALLERY")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/company/${this.state.company.id}/exhibitions`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={exhibitionIcon} className="ca-homepage-icon" alt={translatedMessage("EXHIBITION.EXHIBITIONS")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("EXHIBITION.EXHIBITIONS")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/company/${this.state.company.id}/virtual-galleries`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={virtualGallertIcon} className="ca-homepage-icon" alt={translatedMessage("VIRTUAL_GALLERY.VIRTUAL_ROOMS")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_ROOMS")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/company/${this.state.company.id}/media`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={mediaIcon} className="ca-homepage-icon" alt={translatedMessage("GENERAL.MEDIA")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("GENERAL.IMAGES_VIDEOS")}</div>
                </div>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 col-lg-3" style={{ marginBottom: "2rem" }}>
              <Link to={`/company/${this.state.company.id}/challenge`} className="ca-dark-link">
                <div className="ca-homepage-icon-container">
                  <Image src={challengeIcon} className="ca-homepage-icon" alt={translatedMessage("CHALLENGE.CHALLENGES")} />
                  <div className="ca-homepage-icon-title">{translatedMessage("CHALLENGE.CHALLENGES")}</div>
                </div>
              </Link>
            </Col>
          </Row>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanySpacePage;