import React from 'react';

import { translatedMessage } from '../../../services/language.service';
import LanguageService from '../../../services/language.service';
import ApiService from '../../../services/api.service';

import { Modal, Button, Form } from "react-bootstrap";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

class HtmlEditorModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      editorButtns: ApiService.getSunEditorButtons(),
      editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
      editorFonts: ApiService.getSunEditorFonts(),

    };

    this.saveChanges = this.saveChanges.bind(this);
  }

  onChangeEditor = (e) => {
    this.setState({
      data: {
        ...this.props.data,
        content: e,
      }
    })
  }

  saveChanges = (event) => {
    event.preventDefault();
    this.props.onHide(this.state.data)
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal"
      >
        <Modal.Header closeButton className="ca-modal-close">
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {this.props.data.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
            <Form.Group controlId="formContent">
              <div className="ca-text-editor">
                <SunEditor
                  name="html-editor"
                  lang={LanguageService.getCurrentLanguage()}
                  placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                  setContents={this.props.data.content}
                  setOptions={{
                    height: 150,
                    maxWidth: "100%",
                    buttonList: this.state.editorButtns,
                    font: this.state.editorFonts,
                    defaultStyle: this.state.editorDefaultStyle
                  }}
                  onChange={this.onChangeEditor}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button mr-1" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={this.props.onHide}>{translatedMessage("GENERAL.CLOSE")}</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

export default HtmlEditorModalComponent;