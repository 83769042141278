import React from 'react';

import { Row, Col, Button, Image, Form, OverlayTrigger, Tooltip } from 'react-bootstrap/';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import addIcon from '../../../components/shared/Resources/icons/add.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';

import { toast } from "react-toastify";
import moment from 'moment';
import Switch from "react-switch";
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";
import PricePackageCardComponent from '../../../components/shared/PricePackage/CardComponents/SimplePricePackageAdminCardComponent';
import PricePackageModalComponent from '../../../components/shared/PricePackage/PricePackageModalComponent';
import AddPrintFileModalComponent from '../../../components/shared/PricePackage/AddPrintFileModalComponent';

import ArtworkService from '../../../services/artwork.service';
import FileService from '../../../services/file.service';

class VirtualGalleryPricePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            virtualGallery: null,
            pricePackages: [],
            pricePackagesTableData: [],
            isLoaded: false,
            isError: false,
            errorMessage: '',
            showModal: false,
            modalData: {
                name: '',
                price: 0,
                nrCopies: 0,
                hasSignature: false,
                hasInvestorPackage: false,
                hasDigitalPrint: false,
                digitalPrintQuantity: 0
            },
            soldByCompanyDisabled: false,
            hasInvestorPackage: false,
            isFreePackageActive: false,
            hasDigitalPrint: false,
            digitalPrintQuantity: 0,
            showAddPrintFileModal: false,
            fileModalData: null,
            printImages: [],
            needsPackages: false
        };
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        let isError = false;
        let errorMessage = "PROJECT.GENERAL_ERROR";

        let virtualGallery = await ArtworkService.getArtByIdAndType(this.props.match.params.id, ApiService.getEntityName().VIRTUAL_GALLERY, false)
            .then(result => { return result })
            .catch(err => {
                console.log("An error has ocurred: " + err);
                errorMessage = err;
                isError = true;
            });

        if (!isError) {
            var pricePackages = await ArtworkService.getPricePackagesByArtId(virtualGallery.id, ApiService.getEntityName().VIRTUAL_GALLERY)
                .then(result => { return result })
                .catch(err => {
                    console.log("An error has ocurred: " + err);
                    errorMessage = err;
                    isError = true;
                });
        }

        if (!isError) {
            if (pricePackages.length > 0) {
                let activePackage = pricePackages.filter(item => item.active && !item.investorPackage);
                let isFreePackageActive = activePackage && activePackage[0] && activePackage[0].isFreePackage;
                let hasInvestorPackage = ArtworkService.hasInvestorPackage(pricePackages);

                this.setState({
                    virtualGallery: {
                        ...virtualGallery,
                        storageItem: {
                            id: virtualGallery.storageItemId
                        }
                    },
                    pricePackagesTableData: ArtworkService.computePricePackageData(pricePackages),
                    hasInvestorPackage: hasInvestorPackage,
                    hasDigitalPrint: ArtworkService.hasDigitalPrint(pricePackages),
                    isFreePackageActive: isFreePackageActive,
                    soldByCompanyDisabled: virtualGallery.companyPortfolio && !hasInvestorPackage ? false : true,
                    isLoaded: true
                }, this.getStorageItems);
            } else {
                this.setState({
                    virtualGallery: {
                        ...virtualGallery,
                        storageItem: {
                            id: virtualGallery.storageItemId
                        }
                    },
                    needsPackages: true,
                    isLoaded: true
                });
            }
        } else {
            this.setState({
                isError: true,
                errorMessage: errorMessage,
                isLoaded: true
            });
        }
    }

    async getStorageItems() {
        if (this.state.hasDigitalPrint) {
            let printImages = await ArtworkService.getArtworkDigitalPrintFiles(this.state.virtualGallery.id, ApiService.getEntityName().VIRTUAL_GALLERY);

            this.setState({
                printImages: printImages,
                digitalPrintQuantity: printImages.length
            })
        }
    }

    reloadPackages = (pricePackages) => {
        if (pricePackages.length > 0) {
            let activePackage = pricePackages.filter(item => item.active && !item.investorPackage);
            let isFreePackageActive = activePackage && activePackage.length > 0 && activePackage[0].isFreePackage;
            let hasInvestorPackage = ArtworkService.hasInvestorPackage(pricePackages);

            this.setState({
                pricePackagesTableData: ArtworkService.computePricePackageData(pricePackages),
                hasDigitalPrint: ArtworkService.hasDigitalPrint(pricePackages),
                hasInvestorPackage: hasInvestorPackage,
                isFreePackageActive: isFreePackageActive,
                virtualGallery: {
                    ...this.state.virtualGallery,
                    soldByCompany: isFreePackageActive ? false : this.state.virtualGallery.soldByCompany
                },
                needsPackages: false,
            }, this.saveOptions);
        }
    }

    onShowEdit = (data) => {
        var modalData = data;
        modalData.hasInvestorPackage = this.state.hasInvestorPackage;
        modalData.now = Date.now();

        this.setState({
            modalData: modalData,
            showModal: true
        })
    }

    onHideEdit = (data) => {
        if (data && data.id) {
            var pricePackage = {
                price: parseFloat(data.price),
                quantity: data.quantity ? parseInt(data.quantity) : null,
                hasSignature: data.hasSignature,
                hasInvestorPackage: data.hasInvestorPackage,
                hasDigitalPrint: data.hasDigitalPrint,
                digitalPrintQuantity: data.digitalPrintQuantity
            }

            ApiService.getAuthenticatedInstance().patch(`/artwork/saveArtworkPricePackage/${data.id}`, pricePackage)
                .then((response) => {
                    if (response.data.status === "OK") {
                        toast.success(translatedMessage(response.data.message));

                        let hasInvestorPackage = ArtworkService.hasInvestorPackage(response.data.pricePackages);

                        this.setState({
                            pricePackagesTableData: ArtworkService.computePricePackageData(response.data.pricePackages),
                            hasDigitalPrint: ArtworkService.hasDigitalPrint(response.data.pricePackages),
                            hasInvestorPackage: hasInvestorPackage,
                            soldByCompanyDisabled: this.state.virtualGallery.companyPortfolio && !hasInvestorPackage ? false : true,
                            showModal: false
                        }, this.saveOptions)
                    } else {
                        toast.error(translatedMessage(response.data.message));

                        this.setState({
                            showModal: false
                        })
                    }
                })
                .catch(err => {
                    console.log("An error has ocurred: " + err);
                    console.log(err.response)
                    toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
                    this.setState({
                        showModal: false
                    });
                })
        } else {
            this.setState({
                showModal: false,
            })
        }
    }

    onSwitchChange = (event, key) => {
        this.setState({
            virtualGallery: {
                ...this.state.virtualGallery,
                [key]: event,
            }
        }, this.saveOptions);
    }

    saveOptions = () => {
        let virtualGallery = this.state.virtualGallery;

        ApiService.getAuthenticatedInstance().patch(`/virtualGalleries/${virtualGallery.id}`, {
            caPortfolio: virtualGallery.caPortfolio,
            companyPortfolio: virtualGallery.companyPortfolio,
            soldByCompany: virtualGallery.companyPortfolio && !this.state.hasInvestorPackage ? virtualGallery.soldByCompany : false,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
            .then(() => {
                this.setState({
                    virtualGallery: {
                        ...this.state.virtualGallery,
                        soldByCompany: virtualGallery.companyPortfolio && !this.state.hasInvestorPackage ? virtualGallery.soldByCompany : false,
                    },
                    soldByCompanyDisabled: virtualGallery.companyPortfolio && !this.state.hasInvestorPackage ? false : true
                });
            }, () => {
                if (this.state.hasDigitalPrint) {
                    this.getStorageItems();
                }
            })
            .catch(err => {
                console.log("An error has ocurred: " + err);
                console.log(err.response)
                toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
            })
    }

    handleMediaListChange = () => {
        this.getStorageItems();
    }

    onShowAddFile = () => {
        const modalData = {
            modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
            fileUsage: FileService.getFileUsage().PRINT_FILE,
            fileType: FileService.getResourceType().IMAGE,
            deletePrevious: false,
            deletedImage: null,
            deletePreviousDesc: "",
            fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
            maxSize: 100,
            maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
            multipleFiles: false,
            multipleFilesDesc: "",
            entity: this.state.virtualGallery,
            storageitemlinked: true
        }

        this.setState({
            showAddPrintFileModal: true,
            fileModalData: modalData
        })
    }

    handleBack = () => {
        var digitalPrintQuantity = ArtworkService.getDigitalPrintQuantity(this.state.pricePackagesTableData);

        if (digitalPrintQuantity === 0 || digitalPrintQuantity === this.state.digitalPrintQuantity) {
            this.props.history.push("/virtualGalleryList");
        } else {
            toast.error(translatedMessage("ARTWORK.PUBLISH.PRINT_NOT_UPLOADED"));
        }
    };

    onHideAddFile = (hasUpload) => {
        if (hasUpload && hasUpload === true) {
            this.getStorageItems();
        }
        this.setState({
            showAddPrintFileModal: false
        })
    }

    addPackages = () => {
        let errorMessage = "GENERAL.GENERIC_ERROR";
        console.log(this.state.virtualGallery);

        ApiService.getAuthenticatedInstance().get(`/virtualGallery/${this.props.match.params.id}/createPricePackages`)
            .then(response => { this.reloadPackages(response.data.pricePackages) })
            .catch(err => {
                if (err && err.response && err.response.status && 404 === err.response.status) {
                    errorMessage = err.response.data.message;
                }
                toast.error(translatedMessage(errorMessage));
            });
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else if (this.state.needsPackages) {
                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
                            <div className="pr-2 d-flex align-items-center">
                                <div className="ca-page-title">{translatedMessage("PRICE_PACKAGE.NO_PACKAGES")}</div>
                            </div>

                            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={() => this.addPackages()}>
                                    <Image src={addIcon} alt={translatedMessage("GENERAL.ADD_NEW")} />
                                </Button>

                                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={this.handleBack}>
                                    <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                                </Button>                                
                            </div>
                        </Row>
                    </div>
                )
            } else {
                return (
                    <>
                        <div className="ca-page-padding ca-main-container">
                            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
                                <div className="pr-2 d-flex align-items-center">
                                    <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                                    <div className="ca-page-title">{this.state.virtualGallery.name + " | " + translatedMessage("ARTWORK.PRICING")}</div>
                                </div>

                                <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                                    <Button className="ca-dark-link ca-button-icon" variant="link" onClick={this.handleBack}>
                                        <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                                    </Button>
                                </div>
                            </Row>
                            <Row>
                                <Col className="ca-publish-section-container">
                                    <span className="ca-publish-section-title">
                                        {translatedMessage("ARTWORK.PRICING.DESCRIPTION")}
                                    </span>
                                </Col>
                            </Row>

                            <Row>
                                {this.state.pricePackagesTableData.map((element, index) => {
                                    return (
                                        <Col key={index} className="col-12 col-sm-6 col-md-4 col-xl-3">
                                            <PricePackageCardComponent
                                                data={element}
                                                onchange={this.reloadPackages}
                                                showmodal={this.onShowEdit}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>

                            <Row>
                                <Col className={this.state.hasDigitalPrint ? "col-12 col-lg-6 mb-4" : "col-12"}>
                                    <div className="ca-publish-section-title w-100 mb-3">{translatedMessage("ARTWORK.PUBLISH.OPTIONS")}</div>
                                    <Form id="editoptions-form" className="ca-form">
                                        <Form.Group controlId="caPortfolio" as={Col} xs="12" className="pl-0">
                                            <div className="d-flex align-items-center">
                                                <Switch
                                                    onChange={(event) => this.onSwitchChange(event, "caPortfolio")}
                                                    checked={this.state.virtualGallery.caPortfolio}
                                                    offColor="#F00"
                                                />
                                                <Form.Label className="mb-0 ml-1">
                                                    {translatedMessage("ARTWORK.PUBLISH.CA_PORTFOLIO")}
                                                    <OverlayTrigger
                                                        key='ca-portofolio'
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip id={`tooltip-ca-portofolio`} className="ca-tooltip">
                                                                {translatedMessage("ARTWORK.PUBLISH.CA_PORTFOLIO_INFO")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="ca-question-icon"></span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                            </div>
                                        </Form.Group>

                                        <Form.Group controlId="companyPortfolio" as={Col} xs="12" className="pl-0">
                                            <div className="d-flex align-items-center">
                                                <Switch
                                                    onChange={(event) => this.onSwitchChange(event, "companyPortfolio")}
                                                    checked={this.state.virtualGallery.companyPortfolio}
                                                    offColor="#F00"
                                                />
                                                <Form.Label className="mb-0 ml-1">
                                                    {translatedMessage("ARTWORK.PUBLISH.COMPANY_PORTFOLIO")}
                                                    <OverlayTrigger
                                                        key='company-portofolio'
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip id={`tooltip-company-portofolio`} className="ca-tooltip">
                                                                {translatedMessage("ARTWORK.PUBLISH.COMPANY_PORTFOLIO_INFO")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="ca-question-icon"></span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                            </div>
                                        </Form.Group>

                                        <Form.Group controlId="soldByCompany" as={Col} xs="12" className="pl-0">
                                            <div className="d-flex align-items-center">
                                                <Switch
                                                    onChange={(event) => this.onSwitchChange(event, "soldByCompany")}
                                                    checked={this.state.virtualGallery.soldByCompany}
                                                    offColor="#F00"
                                                    disabled={this.state.isFreePackageActive || this.state.soldByCompanyDisabled}
                                                />
                                                <Form.Label className="mb-0 ml-1">
                                                    {translatedMessage("ARTWORK.PUBLISH.COMPANY_COMM_PORTFOLIO")}
                                                    <OverlayTrigger
                                                        key='commercial'
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip id={`tooltip-commercial`} className="ca-tooltip">
                                                                {translatedMessage("ARTWORK.PUBLISH.COMPANY_COMM_PORTFOLIO.INFO")}
                                                                <br /><br />
                                                                {translatedMessage("ARTWORK.PUBLISH.COMPANY_COMM_PORTFOLIO.CONDITIONS")}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className="ca-question-icon"></span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                {this.state.hasDigitalPrint &&
                                    <Col className="ca-publish-section-container col-12 col-lg-6">
                                        <div className="ca-publish-section-title w-100 mb-3">
                                            <span>
                                                {translatedMessage("PROJECT.UPLOAD_PRINT_FILE")}
                                                <OverlayTrigger
                                                    key='addImage'
                                                    placement='bottom'
                                                    overlay={
                                                        <Tooltip id={`tooltip-addImage`}>
                                                            {translatedMessage("PROJECT.UPLOAD_PRINT_FILE.DESCRIPTION")}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ca-question-icon"></span>
                                                </OverlayTrigger>
                                            </span>

                                            <span className="d-inline-block">
                                                <Button id="btnPrinFiles" variant="dark" className="ca-button ml-2" onClick={this.onShowAddFile}>
                                                    <span>&#xf067; &nbsp;</span>
                                                    {translatedMessage("GENERAL.ADD")}
                                                </Button>
                                            </span>
                                        </div>
                                        {this.state.printImages.length > 0 &&
                                            <Carousel
                                                id="presImg"
                                                items={this.state.printImages}
                                                controls={true}
                                                indicators={false}
                                                interval={null}
                                                class="mb-5"
                                                hasToolbar={true}
                                                toolbarDelete={true}
                                                canDeleteLast={true}
                                                onChange={this.handleMediaListChange}
                                            />
                                        }
                                    </Col>
                                }
                            </Row>
                        </div>

                        <PricePackageModalComponent
                            show={this.state.showModal}
                            onHide={this.onHideEdit}
                            data={this.state.modalData}
                            category={ArtworkService.artworkCategoryEnum().AR_PORTAL}
                        />

                        <AddPrintFileModalComponent
                            show={this.state.showAddPrintFileModal}
                            onHide={this.onHideAddFile}
                            data={this.state.fileModalData}
                        />
                    </>
                )
            }
        }
    }
}

export default VirtualGalleryPricePackage;