import React from 'react';
import "./SecurityPage.css";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import LanguageService from '../../../services/language.service';
import { toast } from "react-toastify";
import ApiService from "../../../services/api.service";

class SecurityPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            securityInfo: {
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            },
            formValidity: false
        };
    }

    userInfoChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            securityInfo: {
                ...this.state.securityInfo,
                [name]: value
            }
        });
    }

    saveChanges = (event) => {
        event.preventDefault();

        let currentPassword = this.state.securityInfo.currentPassword.replace(/ /g, '')
        let newPassword = this.state.securityInfo.newPassword.replace(/ /g, '')
        let confirmNewPassword = this.state.securityInfo.confirmNewPassword.replace(/ /g, '')

        if (0 === currentPassword.length || 0 === newPassword.length || 0 === confirmNewPassword.length) {
            toast.error(window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.EMPTY_FIELDS"));
            return;
        }
        if (newPassword !== confirmNewPassword) {
            toast.error(window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.PASSWORD_MISMATCH"));
            return;
        }

        ApiService.getAuthenticatedInstance().post(`/user/change-password`, {
            oldPassword: currentPassword,
            newPassword: newPassword
        }).then(response => {
            this.setState({
                securityInfo: {
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                }
            });
            toast.success(window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.PASSWORD_CHANGE_SUCCESS"));
        }).catch(err => {
            toast.error(window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.PASSWORD_CHANGE_FAIL"));
        });

    }

    render() {
        return (
            <div className="ca-page-padding ca-main-container">
                <div className="ca-page-title w-100 mb-3">{window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.TITLE")}</div>
                <Row className="mb-2">
                    <Col className="col-12 col-lg-6 pl-0">
                        <Form id="securityForm" className="ca-form" onSubmit={this.saveChanges}>
                            <Form.Group controlId="form">
                                <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.CURRENT_PASSWORD")}</Form.Label>
                                <Form.Control
                                    name="currentPassword"
                                    type="password"
                                    placeholder={window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.CURRENT_PASSWORD_PLACEHOLDER")}
                                    value={this.state.securityInfo.currentPassword}
                                    onChange={this.userInfoChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.NEW_PASSWORD")}</Form.Label>
                                <Form.Control
                                    name="newPassword"
                                    type="password"
                                    placeholder={window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.NEW_PASSWORD_PLACEHOLDER")}
                                    value={this.state.securityInfo.newPassword}
                                    onChange={this.userInfoChange}
                                    required
                                    minLength="8"
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.CONFIRM_NEW_PASSWORD")}</Form.Label>
                                <Form.Control
                                    name="confirmNewPassword"
                                    type="password"
                                    placeholder={window.$translate(LanguageService.getCurrentLanguage(), "USER.SECURITY.CONFIRMS_NEW_PASSWORD_PLACEHOLDER")}
                                    value={this.state.securityInfo.confirmNewPassword}
                                    onChange={this.userInfoChange}
                                    required
                                    minLength="8"
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>

                <Button className="ca-button" variant="dark" type="submit" form="securityForm">
                    <span>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.SAVE_CHANGES")}</span>
                </Button>
            </div>
        )
    }
}

export default SecurityPage;