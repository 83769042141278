import React from 'react';

import { Card } from 'react-bootstrap';
import { translatedMessage } from '../../../../services/language.service';
import { toast } from "react-toastify";
import ArtworkService from '../../../../services/artwork.service';

class SimplePricePackageAdminCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: prevState.data };
    }
    else return null;
  }

  onPackageClick = (isActive, id) => {
    if (isActive) {
      if (!this.state.data.isFreePackage) {
        this.props.showmodal(this.state.data);
      }
    } else {
      ArtworkService.setActiveArtworkPricePackage(id)
        .then(result => {
          this.props.onchange(result);
          if (!this.state.data.isFreePackage) {
            this.props.showmodal(this.state.data);
          }
        })
        .catch(err => {
          var errorMessage = "PROJECT.GENERAL_ERROR";
          if (err && err.response && err.response.status && 404 === err.response.status) {
            errorMessage = err.response.data.message;
          }
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage(errorMessage));
        });
    }
  }

  render() {
    return (
      <div>
        <Card className="ca-card"
          bg={this.props.disabled ? 'disabled' : this.props.data.active ? 'dark' : 'light'}
          text={this.props.disabled ? 'disabled' : this.props.data.active ? 'white' : 'dark'}
          onClick={() => this.props.disabled || this.props.onlyView ? false : this.onPackageClick(this.state.data.active, this.state.data.id)}
          style={{ cursor: this.props.disabled || this.props.onlyView ? "auto" : "pointer" }}>

          <Card.Body style={{ overflow: 'hidden', textOverflow: 'ellipsis', minHeight: "200px" }} className="d-flex align-items-center text-center">
            <div className="w-100 text-center">
              <div className="w-100" style={{ fontSize: "24px" }}>
                {this.state.data.name}
              </div>
              {this.state.data.isFreePackage && this.props.disabled &&
                <div className="w-100 mt-2">
                  { translatedMessage("ARTWORK_PRICE_PACKAGE.FREE_DISABLED") }
                </div>
              }
            </div>
          </Card.Body>
          {/* <Card.Footer>
            <Card.Text><b>{translatedMessage("GENERAL.QUANTITY")}:</b>&nbsp;
              {this.state.data.isFreePackage ? "N/A" : this.state.data.quantity}
            </Card.Text>
            <Card.Text><b>{translatedMessage("GENERAL.PRICE")}:</b>&nbsp;
              {ApiService.getNumberSeparatedByDots(this.state.data.price)}
            </Card.Text>
          </Card.Footer> */}
        </Card>
      </div>
    )
  }
}

export default SimplePricePackageAdminCardComponent;