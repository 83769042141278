import React from 'react';

import { Modal, Button, Form, Dropdown, Col, Image } from 'react-bootstrap/'
// import Switch from "react-switch";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import LanguageService, { translatedMessage } from '../../../services/language.service';
import createArtIcon from '../Resources/icons/artwork.svg';

import ParkService from '../../../services/park.service';
import FileService from '../../../services/file.service';
import DropzoneComponent from '../DropzoneComponent/DropzoneComponent';

const newArtwork = {
    id: null,
    parkArtistApplication: null,
    artistName: null,
    name: null,
    description: null,
    arDescription: null,
    techReq: null,
    placementReq: null,
    exhibitionPreference: null,
    category: 'AR_ART',
    caSell: false,
    caExhibit: false
};

class ArtworkManageModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            artwork: this.props.artwork.id ? this.props.artwork : { ...newArtwork, parkArtistApplication: this.props.artwork.parkArtistApplication },
            categoryList: [],
            dropzoneEnabled: true,
            applicationArtFile1: null,
            applicationArtFile2: null,
            editorButtns: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
        }
    }

    async componentDidMount() {
        const categoryList = await ParkService.getApplicationArtCategoryList()
            .then(response => { return response })
        
        let _artFile1 = null
        let _artFile2 = null
        if(this.props.artwork?.artFiles) {
            _artFile1 = this.props.artwork?.artFiles[0] ? this.props.artwork?.artFiles[0] : null
            _artFile2 = this.props.artwork?.artFiles[1] ? this.props.artwork?.artFiles[1] : null
        }

        this.setState({
            categoryList: categoryList,
            applicationArtFile1: _artFile1,
            applicationArtFile2: _artFile2,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.artwork !== this.props.artwork) {            
            this.setState({
                artwork: this.props.artwork,
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.artwork !== prevState.artwork) {
            return { artwork: prevState.artwork };
        }
        else return null;
    }

    handleCategorySelect = (eventKey) => {
        const selectedItem = this.state.categoryList.filter(item => item === eventKey);
        this.setState({
            artwork: {
                ...this.state.artwork,
                category: selectedItem[0]
            }
        });
    }

    validate = () => {
        var response = {
            isError: false,
            message: ""
        };

        let description = this.state.artwork.description
            ? this.state.artwork.description.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '').replace(/<br>/g, '')
            : null;

        if (!description) {
            response.isError = true;
            response.message = translatedMessage("PARK.APPLICATION.ARTWORK.DESCRIPTION.REQUIRED");
            return response;
        }

        if (!this.state.applicationArtFile1 || !this.state.applicationArtFile1.id) {
            response.isError = true;
            response.message = translatedMessage("PARK.APPLICATION.ARTWORK.FILE.UPLOAD");
            return response;
        }

        return response;
    }

    saveChanges = (event) => {
        event.preventDefault();
        const validation = this.validate(event);
        if (!validation.isError) {
            let artwork = { ...this.state.artwork };

            artwork.name = event.target.elements.name.value;
            artwork.artistName = event.target.elements.artistName.value;
            artwork.artFileId = this.state.applicationArtFile1.id

            ParkService.saveParkApplicationArt(artwork)
            .then((response) => {          
              toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
              this.props.onHide(response.application, response.applicationArt, true);
            })
            .catch(err => {
              console.log("An error has ocurred: " + err);
              toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
            })
        } else {
            toast.error(validation.message);
        }
    };

    onChangeEditor = (e, key) => {
        this.setState({
            artwork: {
                ...this.state.artwork,
                [key]: e,
            }
        })
    }

    onSwitchChange = (event, key) => {
        this.setState({
            artwork: {
                ...this.state.artwork,
                [key]: event,
            }
        });
    }

    onUploadFile = (dropzoneEnabled, uploadedFile) => {
        if (dropzoneEnabled) {
            setTimeout(() => {
                this.setState({
                    dropzoneEnabled: dropzoneEnabled,
                    applicationArtFile1: uploadedFile,
                })
            }, 500);
        } else {
            this.setState({
                dropzoneEnabled: dropzoneEnabled,
                applicationArtFile1: uploadedFile,
            });
        }
    }

    render() {
        var artwork = this.state.artwork;

        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                // size="xl"
                dialogClassName="ca-modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal"
                scrollable>
                <Modal.Header closeButton>
                    {/* <Image src={createArtIcon} className="mb-1" alt={translatedMessage("ARTWORK.ADD")} /> */}
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='d-flex align-items-center'>
                            <Image src={createArtIcon} className="mr-1" alt={translatedMessage("ARTWORK.ARTWORK")} style={{ height: "40px" }} />
                            {translatedMessage("ARTWORK.ARTWORK")}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="name">
                                <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.NAME")}</Form.Label>
                                <Form.Control name="name" required defaultValue={artwork.name} disabled={this.props.onlyView}/>
                                <Form.Control.Feedback type="invalid">{translatedMessage("PARK.APPLICATION.ARTWORK.NAME.REQUIRED")}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="artistName">
                                <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.ARTISTNAME")}</Form.Label>
                                <Form.Control name="artistName" required defaultValue={artwork.artistName} disabled={this.props.onlyView}/>
                                <Form.Control.Feedback type="invalid">{translatedMessage("PARK.APPLICATION.ARTWORK.ARTISTNAME.REQUIRED")}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} lg="12" controlId="description">
                                <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.DESCRIPTION")}</Form.Label>
                                <div className="ca-text-editor">
                                    <SunEditor
                                        name="description-editor"
                                        lang={LanguageService.getCurrentLanguage()}
                                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                        setContents={artwork.description}
                                        setOptions={{
                                            height: 100,
                                            maxWidth: "100%",
                                            buttonList: this.state.editorButtns,
                                            font: this.state.editorFonts,
                                            defaultStyle: this.state.editorDefaultStyle
                                        }}
                                        onChange={event => this.onChangeEditor(event, "description")}
                                        required
                                        disable={this.props.onlyView}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="category">
                                <Form.Label>
                                    {translatedMessage("PARK.APPLICATION.ARTWORK.CATEGORY")}
                                </Form.Label>
                                <Dropdown className="ca-dropdown" onSelect={this.handleCategorySelect} required>
                                    <Dropdown.Toggle disabled={this.props.onlyView}>
                                        {translatedMessage(artwork.category)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {this.state.categoryList.map((element, index) => {
                                            return (
                                                <Dropdown.Item key={index} eventKey={element}>
                                                    {translatedMessage(element)}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>

                            {artwork.category === 'OTHER_CATEGORY' &&
                                <Form.Group as={Col} lg="12" controlId="arDescription">
                                    <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.AR_DESCRIPTION")}</Form.Label>
                                    <div className="ca-text-editor">
                                        <SunEditor
                                            name="arDescription-editor"
                                            lang={LanguageService.getCurrentLanguage()}
                                            placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                            setContents={artwork.arDescription}
                                            setOptions={{
                                                height: 100,
                                                maxWidth: "100%",
                                                buttonList: this.state.editorButtns,
                                                font: this.state.editorFonts,
                                                defaultStyle: this.state.editorDefaultStyle
                                            }}
                                            onChange={event => this.onChangeEditor(event, "arDescription")}
                                            disable={this.props.onlyView}
                                        />
                                    </div>
                                </Form.Group>
                            }

                            <Form.Group as={Col} md="12" controlId="file">
                                <Form.Label>
                                    {translatedMessage("PARK.APPLICATION.ARTWORK.FILE.UPLOAD") + 
                                        (this.state.applicationArtFile1 ? (" (" + this.state.applicationArtFile1?.name + ")") : "")
                                    }
                                </Form.Label>
                                <DropzoneComponent
                                    componentId="applicationArtFile1"
                                    fileUsage={FileService.getFileUsage().APPLICATION_ART}
                                    fileType={FileService.getResourceType().ARCHIVE}
                                    deletePrevious={true}
                                    deletedImage={this.state.applicationArtFile1}
                                    deletePreviousDesc="DROPZONE.DELETE_PREVIOUS_ARTWORK"
                                    fileFormatsDesc="DROPZONE.FILE_TYPES.ARCHIVE"
                                    maxSize={250}
                                    maxSizeDesc="DROPZONE.MAX_SIZE.DESC"
                                    multipleFiles={false}
                                    multipleFilesDesc=""
                                    entity={artwork?.id ? artwork : null}
                                    storageitemlinked={artwork?.id ? true : false}
                                    onupload={this.onUploadFile}
                                    disabled={this.props.onlyView}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg="12" controlId="techReq">
                                <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.TECH_REQ")}</Form.Label>
                                <div className="ca-text-editor">
                                    <SunEditor
                                        name="techReq-editor"
                                        lang={LanguageService.getCurrentLanguage()}
                                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                        setContents={artwork.techReq}
                                        setOptions={{
                                            height: 100,
                                            maxWidth: "100%",
                                            buttonList: this.state.editorButtns,
                                            font: this.state.editorFonts,
                                            defaultStyle: this.state.editorDefaultStyle
                                        }}
                                        onChange={event => this.onChangeEditor(event, "techReq")}
                                        disable={this.props.onlyView}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} lg="12" controlId="placementReq">
                                <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.PLACEMENT_REQ")}</Form.Label>
                                <div className="ca-text-editor">
                                    <SunEditor
                                        name="placementReq-editor"
                                        lang={LanguageService.getCurrentLanguage()}
                                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                        setContents={artwork.placementReq}
                                        setOptions={{
                                            height: 100,
                                            maxWidth: "100%",
                                            buttonList: this.state.editorButtns,
                                            font: this.state.editorFonts,
                                            defaultStyle: this.state.editorDefaultStyle
                                        }}
                                        onChange={event => this.onChangeEditor(event, "placementReq")}
                                        disable={this.props.onlyView}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} lg="12" controlId="exhibitionPreference">
                                <Form.Label>{translatedMessage("PARK.APPLICATION.ARTWORK.EXHIBITION_PREF")}</Form.Label>
                                <div className="ca-text-editor">
                                    <SunEditor
                                        name="exhibitionPreference-editor"
                                        lang={LanguageService.getCurrentLanguage()}
                                        placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                        setContents={artwork.exhibitionPreference}
                                        setOptions={{
                                            height: 100,
                                            maxWidth: "100%",
                                            buttonList: this.state.editorButtns,
                                            font: this.state.editorFonts,
                                            defaultStyle: this.state.editorDefaultStyle
                                        }}
                                        onChange={event => this.onChangeEditor(event, "exhibitionPreference")}
                                        disable={this.props.onlyView}
                                    />
                                </div>
                            </Form.Group>

                            {/* <Form.Group controlId="caSell" as={Col} md="12" className="pl-0">
                                <div className="d-flex align-items-center">
                                    <Switch
                                        onChange={(event) => this.onSwitchChange(event, "caSell")}
                                        checked={artwork.caSell}
                                        offColor="#F00"
                                        disabled={this.props.onlyView}
                                    />
                                    <Form.Label className="mb-0 ml-1">
                                        {translatedMessage("PARK.APPLICATION.ARTWORK.CA_SELL")}
                                    </Form.Label>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="caExhibit" as={Col} md="12" className="pl-0">
                                <div className="d-flex align-items-center">
                                    <Switch
                                        onChange={(event) => this.onSwitchChange(event, "caExhibit")}
                                        checked={artwork.caExhibit}
                                        offColor="#F00"
                                        disabled={this.props.onlyView}
                                    />
                                    <Form.Label className="mb-0 ml-1">
                                        {translatedMessage("PARK.APPLICATION.ARTWORK.CA_EXHIBIT")}
                                    </Form.Label>
                                </div>
                            </Form.Group> */}
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit" disabled={this.props.onlyView}>
                        {translatedMessage("GENERAL.SAVE_CHANGES")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ArtworkManageModalComponent