import React from 'react';
import './ProjectViewPage.css'

import { Row, Col, Image, Button } from 'react-bootstrap/'

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import back from '../../../components/shared/Resources/left-arrow.svg';

import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';

class ProjectViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: this.props.match.params.id,
            projectInformation: {
                title: '',
                id: '',
                imageURL: '',
                artworkDescription: '',
                status: '',
                categoryCode: '',
                communityDescription: '',
                displayInCommunity: false,
                companyId: null
            },
            isLoaded: false,
            isError: false,
            errorMessage: '',
        };
    }

    componentDidMount() {
        ApiService.getAuthenticatedInstance().get(`/artwork/project/${this.state.projectId}`)
            .then(result => {
                let project = result.data.project;
                this.setState({
                    projectInformation: {
                        ...this.state.projectInformation,
                        title: project.name,
                        artworkDescription: project.description,
                        communityDescription: project.communityDescription,
                        displayInCommunity: project.displayInCommunity,
                        status: project.status,
                        categoryCode: project.categoryCode,     
                        companyId: project.companyId,                   
                        imageURL: ApiService.getFileByProjectUrl(project.id) + '?' + Date.now()
                    },
                    isLoaded: true
                })
            })
            .catch(err => {
                var errorMessage = "PROJECT.GENERAL_ERROR";
                if (err && err.response && err.response.status && (403 === err.response.status || 404 === err.response.status)) {
                    errorMessage = err.response.data.message;
                }
                this.setState({
                    isError: true,
                    errorMessage: errorMessage,
                    isLoaded: true
                })
            })
    }

    editProject = () => {
        this.props.history.push(`/project/edit/${this.props.match.params.id}`)
    }

    publishProject = () => {
        if (this.state.projectInformation.categoryCode === ArtworkService.artworkCategoryEnum().AR_ART) {
            this.props.history.push(`/project/publish/ar-art/${this.props.match.params.id}`)
        } else if (this.state.projectInformation.categoryCode === ArtworkService.artworkCategoryEnum().OBJECT) {
            this.props.history.push(`/project/publish/digital-sculpture/${this.props.match.params.id}`)
        } else if (this.state.projectInformation.categoryCode === ArtworkService.artworkCategoryEnum().AR_PORTAL) {
            this.props.history.push(`/project/publish/ar-portal/${this.props.match.params.id}`)
        } else if (this.state.projectInformation.categoryCode === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE) {
            this.props.history.push(`/project/publish/digital-picture/${this.props.match.params.id}`)
        }
    }  

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history}/>
                )
            } else {
                return (
                    <div className="ca-page-padding ca-main-container mt-0">
                        <Row className="mb-2">
                            <Col className="ca-double-button-row pr-0 pl-0">
                                <Link className="ca-dark-link" 
                                        to={this.state.projectInformation.companyId ? `/my-company/${this.state.projectInformation.companyId}/portfolio` : `/projects`}>

                                    <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                                        title={translatedMessage("BUTTON.BACK")} />
                                </Link>
                            </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                            <Col className="col-12 col-sm-6 ca-project-photo-container">
                                <Image src={this.state.projectInformation.imageURL}
                                    className="d-inline-block align-center ca-project-photo" />
                            </Col>

                            <Col className="col-12 col-sm-6 ca-project-title-container">
                                <Row className="mb-4">
                                    <Col className="pl-0">
                                        <div className="ca-project-title-name"> {this.state.projectInformation.title} </div>
                                        <div className="ca-project-title-category"> {translatedMessage(this.state.projectInformation.categoryCode)} </div>
                                        <div className="ca-project-title-category"> {translatedMessage("GENERAL.STATUS")}:&nbsp;
                                        {translatedMessage(this.state.projectInformation.status.label)}
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="mb-0">
                                    <Col className="pl-0">
                                        <Button variant="dark" className="ca-button mr-1 mb-1" onClick={this.editProject}>{translatedMessage("PROJECT.EDIT")}</Button>
                                        <Button variant="dark" className="ca-button mb-1" onClick={this.publishProject}
                                            disabled={
                                                this.state.projectInformation.status.name === ArtworkService.artworkStatusEnum().PUBLISHED ||
                                                    this.state.projectInformation.status.name === ArtworkService.artworkStatusEnum().SUBMITTED_FOR_APPROVAL ? true : false}>
                                            {translatedMessage("PROJECT.PUBLISH")}
                                        </Button>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row className="ca-project-details">
                            <div className="ca-label">{translatedMessage("PROJECT.ARTWORK_DESCRIPTION")}</div>
                            <div className="ca-project-title-description">
                                {this.state.projectInformation.artworkDescription && 
                                    <div dangerouslySetInnerHTML={{ __html: this.state.projectInformation.artworkDescription }} />
                                }
                                {!this.state.projectInformation.artworkDescription && 
                                    <div>-</div>
                                }
                            </div>
                        </Row>

                        <Row className="ca-project-details">
                            <div className="ca-label">{translatedMessage("PROJECT.DISPLAY_IN_COMMUNITY")}</div>
                            <div className="ca-project-title-description">
                                {this.state.projectInformation.displayInCommunity
                                    ? translatedMessage("GENERAL.YES")
                                    : translatedMessage("GENERAL.NO")
                                }
                            </div>
                        </Row>

                        {this.state.projectInformation.displayInCommunity &&
                            <Row className="ca-project-details">
                                <div className="ca-label">{translatedMessage("PROJECT.COMMUNITY_DESCRIPTION")}</div>
                                <div className="ca-project-title-description">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.projectInformation.communityDescription }} />
                                </div>
                            </Row>
                        }
                    </div>
                )
            }
        }
    }

}

export default ProjectViewPage;