import React from 'react';

import ChallengeListComponent from '../../../components/shared/Challenge/ChallengeListComponent';
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import CompanyService from '../../../services/company.service';

class SpaceChallengeListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      challenges: [],
      company: null,
      isLoaded: false,
      canViewList: false,
    };

  }

  componentDidMount = () => {
    this.getData();
  }

  async getData() {
    let errorMessage = "GENERAL.GET_DATA_ERROR";
    let isError = false;

    let company = await CompanyService.getCompanySpace(this.state.companyId)
      .then(response => { return response })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        isError = true;
      });

    let challenges = [];

    if (!isError) {
      challenges = await CompanyService.getCompanyChallenges(this.state.companyId)
        .then(response => { return response })
        .catch(err => {
          console.log(err)
          if (err && err.response && err.response.status === 404) {
            errorMessage = err.response.data.message;
          }

          isError = true;
        });
    }

    if (!isError) {
      this.setState({
        company: company,
        challenges: challenges,
        isLoaded: true
      })      
    } else {
      this.setState({
        isError: true,
        errorMessage: errorMessage,
        isLoaded: true
      })
    }

  }


  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ChallengeListComponent
          history={this.props.history}
          challenges={this.state.challenges}
          spaceName={this.state.company.spaceName}
          backUrl={`/company/${this.state.company.id}/space`}
          exhibitionPrefixUrl={`/company/${this.state.company.id}/exhibition`}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }
}

export default SpaceChallengeListPage;