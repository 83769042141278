import React from "react";

import { Modal, Col, Row, Button } from 'react-bootstrap';

import { translatedMessage } from '../../../../services/language.service';
import ApiService from '../../../../services/api.service';
import ArtworkService from '../../../../services/artwork.service';

class InvoiceDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: this.props.invoice,
      isMembership: false,
      isArtwork: false,
      isTicket: false,
      artworks: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.invoice !== this.props.invoice) {
      this.setState({ invoice: this.props.invoice }, this.getData);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.invoice) {
      return { invoice: prevState.invoice };
    }
    else return null;
  }

  getData = () => {
    const invoice = this.state.invoice;
    if (invoice.entityName === ApiService.getEntityName().CART) {
      if (invoice.paymentType === 'ARTWORK') {
        ApiService.getAuthenticatedInstance().get(`/payment/cartItems/${invoice.cart.id}`)
          .then(response => {
            let artworks = response.data.filter(element => element.status === "PAID")

            artworks.map(element => {
              if (element.virtualGalleryId) {
                element.artworkCategory = ArtworkService.artworkCategoryEnum().AR_PORTAL;
              }

              return element;
            })

            this.setState({
              artworks: artworks,
              isArtwork: true,
            })
          })
      }
    }
  }

  getComputeAmount = (amount, exchangeRate) => {
    return (Number(amount) * Number(exchangeRate)).toFixed(2);
  }

  render() {
    const invoice = this.state.invoice
    const exchangeRate = (invoice.type === 'VIPS' && invoice.exchangeRate) ? invoice.exchangeRate : 1;

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("BILLING.PATMENT_DETAILS")}
            <div className="font-bold ca-muted-text h6">
              {translatedMessage("BILLING.ORDER") + ": " + invoice.orderNumber + " / " + invoice.date}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="font-bold h6">
            {translatedMessage("BILLING.ITEMS")}
          </div>
          <hr className="ca-hr muted" />
          {/* Display for membership and tickets */}
          {(invoice.entityName === ApiService.getEntityName().MEMBERSHIP ||
            invoice.entityName === ApiService.getEntityName().EXHIBITION ||
            invoice.pay === ApiService.getEntityName().GALLERY) &&
            <Row className="mt-2 mb-2">
              <Col className="col-8">
                {invoice.transactionDetail}
              </Col>
              <Col className="col-4 text-align-right">
                {ApiService.getNumberSeparatedByDots(invoice.amount)} {invoice.currency}
              </Col>
            </Row>
          }

          {/* Display for artworks */}
          {invoice.entityName === ApiService.getEntityName().CART && invoice.paymentType === 'ARTWORK' &&
            this.state.artworks.map((item, index) => {
              return (
                <Row key={index} className="mt-2 mb-2">
                  <Col className="col-8">
                    <span className="font-bold">{item.artworkName}</span> {translatedMessage("GENERAL.BY").toLowerCase()} {item.authorName}
                    <br />
                    <span className="ca-muted-text">
                      {translatedMessage(item.artworkCategory) + "; " + item.pricePackageName + " " + translatedMessage("ARTWORK.PACKAGE").toLowerCase()}
                    </span>
                  </Col>
                  <Col className="col-4 text-align-right">
                    {ApiService.getNumberSeparatedByDots(this.getComputeAmount(item.price, exchangeRate))} {invoice.currency}
                    <span className="ca-muted-text">
                      <br />{translatedMessage("GENERAL.QUANTITY") + ": " + item.quantity}
                    </span>
                  </Col>
                </Row>
              )
            })
          }
          {/* <hr className="ca-hr mt-3 mb-2 muted" /> */}
          <hr className="ca-hr muted" />
          <Row className="font-bold mt-1 mb-2">
            <Col className="col-8">
              <span className="h6">{translatedMessage("CART.TOTAL")}</span>
            </Col>
            <Col className="col-4 text-align-right">
              <span className="h6">{ApiService.getNumberSeparatedByDots(invoice.amount)} {invoice.currency}</span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide()}>
            {translatedMessage("GENERAL.CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default InvoiceDetailsModal;