import React from 'react';

import CardComponent from '../EntityManageArtworkCardComponent/EntityManageArtworkCardComponent';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';
import ArtworkViewModalComponent from '../Artwork/ArtworkViewModalComponent';

import { toast } from "react-toastify";

import backIcon from '../../../components/shared/Resources/icons/back.svg';
import mapIcon from '../../../components/shared/Resources/icons/map.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import cancelIcon from '../../../components/shared/Resources/icons/cancel.svg';
import artworkIcon from '../../../components/shared/Resources/icons/artwork.svg';

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';

import moment from 'moment';

const ENTER_KEY = 13;

class ExhibitionManageArtworkComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.exhibitionId,
      exhibition: {},
      searchInput: '',
      artworks: [],
      selectedCategory: { id: "0", code: "ARTWORK.CATEGORY.ANY" },
      artworkCategoryList: [],
      filteredArtworks: [],
      authors: [],
      existingArtworkList: [],
      isLoaded: false,
      isAddArtwork: false,
      artworkCanHaveLocation: false,
      canEdit: false,
      isError: false,
      errorMessage: "",
      showArtworkModal: false,
      modalData: {
        art: {}
      },
      sellOption: [
        { id: "0", code: "GENERAL.SEE_ALL", value: null },
        { id: "1", code: "GENERAL.YES", value: true },
        { id: "2", code: "GENERAL.NO", value: false }
      ],
      selectedSellOption: { id: "0", code: "GENERAL.SEE_ALL", value: null },
    };

    this.addArtwork = this.addArtwork.bind(this);
  }

  componentDidMount = () => {
    if (this.props.canEdit) {
      this.getArtworks();
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  getArtworks = () => {
    var exhibitionId = this.state.exhibitionId;
    var artworkCategoryList = [];
    var artworks = [];
    var exhibition = {};
    var promise = [];
    var artworkCanHaveLocation = false;


    ArtworkService.getExhibitionArtwork(exhibitionId)
      .then(response => {
        artworks = response;
        if (artworks.length > 0) {
          exhibition = artworks[0].exhibition
        }
      })
      .then(() => {
        promise.push(ArtworkService.getArtworkCategoryList()
          .then(response => {
            artworkCategoryList = response;
            artworkCategoryList.splice(0, 0, { id: "0", code: "ARTWORK.CATEGORY.ANY" })
          }))

        if (exhibitionId) {
          promise.push(ApiService.getAuthenticatedInstance().get(`gallery/exhibitionLocation/${exhibitionId}`)
            .then(response => {
              const locations = response.data;
              if (locations.length === 1) {
                if (locations[0].locationLat !== 0 && locations[0].locationLng !== 0) {
                  artworkCanHaveLocation = true;
                }
              }
            })
          )
        }

        Promise.all(promise).then(() => {
          this.setState({
            artworks: artworks,
            filteredArtworks: artworks,
            artworkCategoryList: artworkCategoryList,
            exhibition: exhibition,
            artworkCanHaveLocation: artworkCanHaveLocation,
            isLoaded: true
          }, this.filter)
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleOnCategorySelect = (eventKey) => {
    const selectedItem = this.state.artworkCategoryList.filter(item => item.id === eventKey);

    this.setState({
      selectedCategory: selectedItem[0]
    }, this.filter)
  }

  handleOnSellOptionSelect = (eventKey) => {
    const selectedItem = this.state.sellOption.filter(item => item.id === eventKey);

    this.setState({
      selectedSellOption: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let categoryItem = this.state.selectedCategory;
    let sellItem = this.state.selectedSellOption;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.artwork.name.toLowerCase().includes(searchInput.toLowerCase())
        || elem.author.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (categoryItem.id !== "0") {
      result = result.filter(elem => elem.artwork.category.id.toString() === categoryItem.id.toString());
    }

    if (sellItem.value !== null) {
      result = result.filter(elem => elem.artwork.soldByCompany === sellItem.value);
    }

    this.setState({
      filteredArtworks: result
    })
  }

  async addArtwork() {
    let artworks = [];
    if (this.props.isAdmin) {
      artworks = await ArtworkService.getArtworksByCriteria(false, ArtworkService.artworkStatusEnum().PUBLISHED)
        .then(response => { return response });
    } else if (this.props.companyId) {
      artworks = await ArtworkService.getArtworksForCompany(this.props.companyId)
        .then(response => { return response });
    }

    var art = []
    var existingArtworkList = [];
    artworks.map((element, index) => {
      const existingArt = this.state.artworks.filter(item => item.artwork.id === element.id);
      if (existingArt.length > 0) {
        existingArtworkList.push(existingArt[0].artwork.id.toString());
      }
      art.push({
        "artwork": element,
        "exhibition": this.state.exhibition,
        "author": element.author,
        "image": element.image,
        "existingArt": existingArt.length > 0 ? true : false,
        "soldByCompany": element.soldByCompany,
        "id": element.id
      })
      return art;
    })

    this.setState({
      artworks: art,
      filteredArtworks: art,
      isAddArtwork: true,
      existingArtworkList: existingArtworkList
    }, this.filter);
  }

  handleCheckChange = (id, isChecked) => {
    let existingArtworkList = this.state.existingArtworkList;
    if (isChecked) {
      existingArtworkList.push(id)
    } else {
      let index = existingArtworkList.indexOf(id);
      if (index !== -1) {
        existingArtworkList.splice(index, 1);
      }
    }

    this.setState({
      existingArtworkList: existingArtworkList
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    ArtworkService.addArtworkToExhibition(this.state.exhibitionId, this.state.existingArtworkList)
      .then(() => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.setState({
          isAddArtwork: false,
        }, this.getArtworks)
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);

        let errorMessage = "GENERAL.SAVE_ERROR";
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }
        toast.error(translatedMessage(errorMessage));
        this.setState({
          isAddArtwork: false,
        }, this.getArtworks)
      })
  }

  cancelAdd = () => {
    this.setState({
      isAddArtwork: false,
    }, this.getArtworks)
  }

  handleAddFreeGift = (exhibitionArtworkId, isChecked) => {
    ApiService.getAuthenticatedInstance().patch(`/exhibitionArtworks/${exhibitionArtworkId}`, {
      isGift: isChecked,
      lastUpdatedBy: ApiService.getCurrentUser().username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
      .then(response => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.getArtworks()
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      });
  }

  handleAddFreeExhibitionArt = (exhibitionArtworkId, isChecked) => {
    ApiService.getAuthenticatedInstance().patch(`/exhibitionArtworks/${exhibitionArtworkId}`, {
      isFreeForExhibition: isChecked,
      lastUpdatedBy: ApiService.getCurrentUser().username,
      lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
      .then(response => {
        toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        this.getArtworks()
      })
      .catch(err => {
        console.log("An error has ocurred: " + err);
        toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
      });
  }

  onShowModal = (artId) => {
    ApiService.getAuthenticatedInstance().get(`/artwork/artworkDetail/${artId}`)
      .then(response => {
        this.setState({
          modalData: response.data.artwork,
          showArtworkModal: true
        })
      })
      .catch(err => {
        let errorMessage = "GENERAL.GET_DATA_ERROR";

        console.log(err)
        if (err && err.response && err.response.status === 404) {
          errorMessage = err.response.data.message;
        }

        toast.error(translatedMessage(errorMessage));
      });
  }

  onHideModal = () => {
    this.setState({
      showArtworkModal: false
    })
  }

  render() {
    const artworks = this.state.filteredArtworks;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2">
            <div className="d-flex align-items-center">
              <Image src={this.props.icon} className="ca-page-title-icon" alt={translatedMessage("EXHIBITION.EXHIBITION")} />
              <div>
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>{this.state.exhibition.name}</div>
                <div className="ca-page-subtitle ca-muted-text">{translatedMessage("GENERAL.ARTWORKS.MANAGE")}</div>
              </div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex align-items-center justify-content-end">
              <Button onClick={this.addArtwork} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={artworkIcon} alt={translatedMessage("ARTWORK.MANAGE")} title={translatedMessage("ARTWORK.MANAGE")} />
              </Button>
              <Button type="submit" form="artworkForm" className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              <Button onClick={this.cancelAdd} className={"ca-dark-link ca-button-icon medium mr-1 ".concat(!this.state.isAddArtwork ? "ca-hide-button" : "")}>
                <Image src={cancelIcon} alt={translatedMessage("GENERAL.CANCEL")} title={translatedMessage("GENERAL.CANCEL")} />
              </Button>
              <Button onClick={() => this.props.history.push(this.props.mapUrl)} className="ca-dark-link ca-button-icon medium mr-1">
                <Image src={mapIcon} alt={translatedMessage("MAP.MAP")} title={translatedMessage("MAP.VIEW_ON_MAP")} />
              </Button>
              <Button onClick={() => this.props.history.push(this.props.backUrl)} className="ca-dark-link ca-button-icon">
                <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
              </Button>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-sm-4 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} onChange={this.handleSearchInput} className="ca-form-control-search"
                type="text" placeholder="&#xF002;" />
            </Col>

            <Col className="col-12 col-sm-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnCategorySelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedCategory.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.artworkCategoryList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>

            <Col className="col-12 col-sm-4 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleOnSellOptionSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedSellOption.code)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.sellOption.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.id}>
                        {translatedMessage(element.code)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Form id="artworkForm" className="ca-form" onSubmit={this.handleSubmit}>
            <div className="card-columns">
              {artworks.map((element, index) => {
                return (
                  <CardComponent
                    key={element.id}
                    id={element.artwork.id}
                    entityArtworkId={element.id}
                    cardImage={element.image}
                    cardTitle={element.artwork.name}
                    cardArtworkAuthor={element.author}
                    cardCategory={element.artwork.category.code}
                    cardId={element.artwork.id}
                    cardLink={`/artwork/${element.artwork.id}`}
                    isAddArtwork={this.state.isAddArtwork}
                    existingArt={!this.state.isAddArtwork ? true : element.existingArt}
                    onCheck={this.handleCheckChange}
                    artworkCanHaveLocation={this.state.artworkCanHaveLocation}
                    hasFreeGiftOption={this.props.hasFreeGiftOption}
                    isFreeGift={element.isGift}
                    onAddFreeGift={this.handleAddFreeGift}
                    hasFreeForEntityOption={this.props.hasFreeForEntityOption}
                    isFreeForEntity={element.isFreeForExhibition}
                    onAddFreeEntityArt={this.handleAddFreeExhibitionArt}
                    locationRedirectUrl={`/exhibition/artwork/${element.id}/location`}
                    triggerRedirectUrl={`${this.props.triggerUrl}/${element.id}`}
                    soldByCompany={this.props.companyId ? element.artwork.soldByCompany : null}
                    onClick={this.onShowModal}
                  />
                )
              })}
            </div>
          </Form>

          <ArtworkViewModalComponent
            show={this.state.showArtworkModal}
            onHide={this.onHideModal}
            art={this.state.modalData}
          />
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionManageArtworkComponent;