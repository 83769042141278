import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import LoginPage from "./containers/LoginPage/LoginPage";
import ResetPasswordRequestPage from "./containers/ResetPasswordPage/ResetPasswordRequestPage";
import ConfirmPage from "./containers/ConfirmPage/ConfirmPage";
import ConfirmEmailPage from "./containers/ConfirmPage/ConfirmEmailPage";
import ResetPasswordPage from "./containers/ResetPasswordPage/ResetPasswordPage";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const routing = (
    <Router>
        <div className="h-100">
            <Switch>
                <Route exact path="/login" component={LoginPage}></Route>
                <Route exact path="/reset" component={ResetPasswordRequestPage}></Route>
                <Route exact path="/reset/:id/:confirmEmailToken" component={ResetPasswordPage}></Route>
                <Route exact path="/confirm/:id/:confirmEmailToken" component={ConfirmPage}></Route>
                <Route exact path="/confirm-email/:id/:confirmEmailToken" component={ConfirmEmailPage}></Route>
                <Route component={App}></Route>
            </Switch>
            <ToastContainer 
                autoClose={3000}
                // className='toast'
                // toastClassName='toast'
            />
        </div>
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
