import React from 'react';

import { Modal, Button, Form } from 'react-bootstrap/'

import { translatedMessage } from '../../../services/language.service';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import LanguageService from '../../../services/language.service';

class ChallengeNotificationModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editorButtons: ApiService.getSunEditorButtons(),
            editorDefaultStyle: ApiService.getSunEditorDefaultStyle(),
            editorFonts: ApiService.getSunEditorFonts(),
            notification: ""
        }
    }

    async componentDidMount() { }

    onChangeNotification = (e) => {
        this.setState({ notification: e })
      }

    saveChanges = (event) => {
        event.preventDefault();
        let notification = ApiService.nullIfEmptyHTMLEditor(this.state.notification);

        if(notification){
            this.props.onHide(this.state.notification);
        } else {
            toast.error(translatedMessage("CHALLENGE.NOTIFICATION.MESSAGE.EMPTY"));
        }
    };

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {translatedMessage("CHALLENGE.NOTIFICATION.MESSAGE")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="notification-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Group controlId="formArtworkDescription">
                            <div className="w-100 mb-2">{translatedMessage("CHALLENGE.NOTIFICATION.INFO")}</div>
                            
                            <div className="ca-text-editor">
                                <SunEditor
                                    name="notification"
                                    lang={LanguageService.getCurrentLanguage()}
                                    placeholder={translatedMessage("EDITOR.PLACEHOLDER")}
                                    setContents={""}
                                    setOptions={{
                                        height: 200,
                                        maxWidth: "100%",
                                        buttonList: this.state.editorButtons,
                                        font: this.state.editorFonts,
                                        defaultStyle: this.state.editorDefaultStyle
                                    }}
                                    onChange={this.onChangeNotification}
                                />
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='pt-0'>
                    <div className="w-100 mb-2">{translatedMessage("CHALLENGE.NOTIFICATION.WARNING")}</div>
                    <Button className="ca-button" variant="dark" form="notification-form" type="submit">{translatedMessage("CHALLENGE.NOTIFICATION.SEND")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ChallengeNotificationModalComponent