import React from 'react';

import { Row, Col, Button, Image } from 'react-bootstrap/';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';

import CardComponent from "./ArtworkCardComponent";
import ArtworkSelectModal from "./ArtworkSelectModal";
import calogo from '../Resources/logo-dark-small-with-padding.png'
import backIcon from '../Resources/icons/back.svg';
import virtualGalleryIcon from '../Resources/icons/virtualGallery.svg';
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

import ArtworkService from '../../../services/artwork.service';

class VirtualGalleryArtworksComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualGallery: null,
      modalData: {
        content: '',
        title: '',
        dataFor: ''
      },
      galleryArtworks: [],
      artworks: [],
      dropzoneEnabled: true,
      isLoaded: false,
      isError: false,
      showModal: false,
      showFieldModal: false,
      errorMessage: "",
      selectedId: 0
    };
  }

  componentDidMount = () => { this.getData() }

  async getData() {
    let isError = false;
    let errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";
    let galleryArtworks = [];

    if (this.props.canEdit) {
      let virtualGallery = this.props.virtualGallery;

      if (this.props.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
        galleryArtworks = await VirtualGalleryService.getVirtualGalleryArt(virtualGallery.id)
          .then(response => {
            return response;
          })
          .catch(err => {
            isError = true;
            if (err && err.response && err.response.status && (404 === err.response.status)) {
              errorMessage = err.response.data.message;
            }
          })
      } else {
        galleryArtworks = await VirtualGalleryService.getEntityVirtualGalleryArt(this.props.entityId, this.props.entityName)
          .then(response => {
            return response;
          })
          .catch(err => {
            isError = true;
            if (err && err.response && err.response.status && (404 === err.response.status)) {
              errorMessage = err.response.data.message;
            }
          })
      }

      let allArtworks = [];

      // if the user is admin get artworks that are available for CA
      // if the user is admin can add AR experiences to VG
      if (this.props.isAdmin) {
        if (this.props.artEntity === ApiService.getEntityName().ARTWORK) {
          //get all artwoks for manage
          allArtworks = await ArtworkService.getArtworksByCriteria(false, ArtworkService.artworkStatusEnum().PUBLISHED)
            .then(response => { return response });
        } else if (this.props.artEntity === ApiService.getEntityName().AR_EXPERIENCE) {
          //get all ar experiences for manage
          allArtworks = await ApiService.getAuthenticatedInstance().get(`/arExperience/arExperienceList`)
            .then(response => {
              let data = response.data
              data.map((element, i) => {
                element.image = ApiService.getFileByUuid(element.thumbnailUuid) + '?' + Date.now();
                element.category.code = element.category.label;
                return element;
              });

              return data
            });
        }
      } else if (this.props.companyId) {
        // if is a company user get artworks that are available for that company
        allArtworks = await ArtworkService.getArtworksForCompany(this.props.companyId)
          .then(response => { return response });
      }

      if (!isError) {
        this.setState({
          virtualGallery: virtualGallery,
          artNo: virtualGallery.nrArArt + virtualGallery.nrSculpture,
          galleryArtworks: galleryArtworks,
          arArtArtwoks: galleryArtworks.filter(item => item.category === ArtworkService.artworkCategoryEnum().AR_ART),
          sculptureArtwoks: galleryArtworks.filter(item => item.category === "3D_OBJECT"),
          artworks: allArtworks,
          isLoaded: true
        }, this.computeGalleryArtworks)
      } else {
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      }
    } else {
      this.setState({
        isError: true,
        errorMessage: "USER.SECURITY.MISSING_RIGHTS",
        isLoaded: true
      })
    }
  }

  computeGalleryArtworks = () => {
    let galleryArtworks = this.state.galleryArtworks;
    let nrArArt = this.state.virtualGallery.nrArArt;
    let nrSculpture = this.state.virtualGallery.nrSculpture;
    let artNo = galleryArtworks.length;

    if (artNo !== nrArArt + nrSculpture) {
      let arArtNo = artNo ? galleryArtworks.filter(item => item.category === ArtworkService.artworkCategoryEnum().AR_ART).length : 0;
      let sculptureNo = artNo ? galleryArtworks.filter(item => item.category === "3D_OBJECT").length : 0;

      let arArtPositions = []
      for (let i = 0; i < nrArArt; i++) {
        arArtPositions.push(i + 1)
      }

      let sculpturePositions = []
      for (let i = 0; i < nrSculpture; i++) {
        sculpturePositions.push(i + 1)
      }

      galleryArtworks.forEach(item => {
        if (item.category === ArtworkService.artworkCategoryEnum().AR_ART) {
          arArtPositions = arArtPositions.filter(value => value !== item.position)
        } else if (item.category === "3D_OBJECT") {
          sculpturePositions = sculpturePositions.filter(value => value !== item.position)
        }
      })

      let remainingArArtNo = nrArArt - arArtNo;
      let remainingSculpturetNo = nrSculpture - sculptureNo;

      for (let i = artNo; i < artNo + remainingArArtNo; i++) {
        let galleryArtwork = {};
        galleryArtwork.id = 0;
        galleryArtwork.imageURL = calogo;
        galleryArtwork.category = ArtworkService.artworkCategoryEnum().AR_ART;
        galleryArtwork.position = arArtPositions[0];
        arArtPositions = arArtPositions.filter(value => value !== galleryArtwork.position);

        galleryArtworks.push(galleryArtwork)
      }

      for (let i = artNo + remainingArArtNo; i < artNo + remainingArArtNo + remainingSculpturetNo; i++) {
        let galleryArtwork = {};
        galleryArtwork.id = 0;
        galleryArtwork.imageURL = calogo;
        galleryArtwork.category = "3D_OBJECT";
        galleryArtwork.position = sculpturePositions[0];
        sculpturePositions = sculpturePositions.filter(value => value !== galleryArtwork.position);

        galleryArtworks.push(galleryArtwork)
      }
    }

    this.setState({
      galleryArtworks: galleryArtworks,
      arArtArtwoks: galleryArtworks.filter(item => item.category === ArtworkService.artworkCategoryEnum().AR_ART),
      sculptureArtwoks: galleryArtworks.filter(item => item.category === "3D_OBJECT"),
    })
  }

  onShowModal = (id, categoryCode, position) => {
    this.setState({
      showModal: true,
      selectedId: id,
      categoryCode: categoryCode,
      position: position
    })
  }

  onHideModal = (mustFetch) => {
    if (mustFetch) {
      if (this.props.entityName === ApiService.getEntityName().VIRTUAL_GALLERY) {
        VirtualGalleryService.getVirtualGalleryArt(this.state.virtualGallery.id)
        .then(response => {
          this.setState({
            galleryArtworks: response,
          }, this.computeGalleryArtworks)
        })
      } else {
        VirtualGalleryService.getEntityVirtualGalleryArt(this.props.entityId, this.props.entityName)
          .then(response => {
            this.setState({
              galleryArtworks: response,
            }, this.computeGalleryArtworks)
          })
      }
    }

    this.setState({
      showModal: false,
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
              <div className="pr-2 d-flex align-items-center">
                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                <div className="ca-page-title" style={{ lineHeight: "30px" }}>
                  {this.state.virtualGallery.name}
                  <br />
                  <span className="ca-page-subtitle ca-muted-text">{translatedMessage("VIRTUAL_GALLERY.MANAGE_ARTWORKS")}</span>
                </div>
              </div>

              <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(this.props.backLink)}>
                  <Image src={backIcon} alt={translatedMessage("BUTTON.BACK")} />
                </Button>
              </div>
            </Row>

            <Row>
              <Col className={this.state.virtualGallery.portalMapUuid ? "col-12 col-md-6 ca-row-left-column" : "col-12 ca-row-left-column"}>
                {this.state.virtualGallery.nrArArt > 0 &&
                  <Row className="mb-5">
                    <div className="w-100 mb-3 text-center">
                      <span className="ca-page-subtitle">
                        {translatedMessage("AR_ART") + ": " + this.state.virtualGallery.nrArArt + " " +
                          translatedMessage(this.state.virtualGallery.nrArArt === 1 ? "ARTWORK.ARTWORK" : "ARTWORK.ARTWORKS").toLowerCase()}
                      </span>
                    </div>

                    {this.state.arArtArtwoks.map((element, index) => {
                      return (
                        <Col key={index} className={this.state.virtualGallery.portalMapUuid ? "col-12 col-sm-12 col-lg-6" : "col-12 col-sm-6 col-lg-4 col-xl-3"}>
                          <CardComponent
                            cardImage={element.imageURL}
                            entity={element}
                            id={0 === element.id ? 0 : element.id}
                            entityListType={this.props.artType}
                            onClick={this.onShowModal}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                }

                {this.state.virtualGallery.nrSculpture > 0 &&
                  <Row>
                    <div className="w-100 mb-3 text-center">
                      <span className="ca-page-subtitle">
                        {translatedMessage("3D_OBJECT") + ": " + this.state.virtualGallery.nrSculpture + " " +
                          translatedMessage(this.state.virtualGallery.nrSculpture === 1 ? "ARTWORK.ARTWORK" : "ARTWORK.ARTWORKS").toLowerCase()}
                      </span>
                    </div>

                    {this.state.sculptureArtwoks.map((element, index) => {
                      return (
                        <Col key={index} className={this.state.virtualGallery.portalMapUuid ? "col-12 col-sm-12 col-lg-6" : "col-12 col-sm-6 col-lg-4 col-xl-3"}>
                          <CardComponent
                            cardImage={element.imageURL}
                            entity={element}
                            id={0 === element.id ? 0 : element.id}
                            entityListType={this.props.artType}
                            onClick={this.onShowModal}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                }
              </Col>
              {this.state.virtualGallery.portalMapUuid &&
                <Col className="col-12 col-md-6 ca-row-right-column text-center">
                  <div className="ca-page-subtitle w-100 text-center">
                    {translatedMessage("AR_PORTAL.AR_PORTAL_MAP_INFO")}
                  </div>
                  <Image src={ApiService.getBaseUrl() + '/storage/file/' + this.state.virtualGallery.portalMapUuid}
                    style={{ width: "90%", height: "auto" }} />
                </Col>
              }
            </Row>

            <ArtworkSelectModal
              show={this.state.showModal}
              onHide={this.onHideModal}
              evgId={this.props.entityId}
              evgName={this.props.entityName}
              evgaId={this.state.selectedId}
              artworks={this.state.artworks}
              categoryCode={this.state.categoryCode}
              position={this.state.position}
              artentity={this.props.artEntity}
            />
          </div>
        )
      }
  }

}

export default VirtualGalleryArtworksComponent;