import React from "react";

import { Modal, Col, Form, Button } from 'react-bootstrap';

import { translatedMessage } from '../../../services/language.service';

class GiftReceiverDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const giftReceiver = { 
      name: event.target.elements.formName.value, 
      email: event.target.elements.formEmail.value,
      sendNow: event.target.elements.sendOption1.checked,
      printFirst: event.target.elements.sendOption2.checked
    }
    this.props.onHide(this.props.giftsoldartworkid, giftReceiver);
  }

  render() {
    return (
      <Modal
        {...this.props}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
            {translatedMessage("GIFT.SEND_AS_GIFT_TO")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <Form id="giftForm" className="ca-form" onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="formName">
                <Form.Label>
                  {translatedMessage("GENERAL.NAME")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={translatedMessage("ARTWORK.SEND_GIFT.NAME_PLACEHOLDER")}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="formEmail">
                <Form.Label>
                  {translatedMessage("GENERAL.EMAIL")}
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder={translatedMessage("ARTWORK.SEND_GIFT.EMAIL_PLACEHOLDER")}
                />
              </Form.Group>

              <Form.Group as={Col} md="12" controlId="formSendOption">
                <Form.Check
                  name="sendOption"
                  type="radio"
                  label={translatedMessage("ARTWORK.SEND_GIFT.SEND_NOW")}
                  id="sendOption1"
                  required
                />
                <Form.Check
                  name="sendOption"
                  type="radio"
                  label={translatedMessage("ARTWORK.SEND_GIFT.PRINT_FIRST")}
                  id="sendOption2"
                  required
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="ca-button mr-1" type="submit" form="giftForm">
            {translatedMessage("GENERAL.SAVE_CHANGES")}
          </Button>
          <Button variant="dark" className="ca-button ca-button-white" onClick={() => this.props.onHide(0, {})}>
            {translatedMessage("GENERAL.CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default GiftReceiverDetailsModal;