import React from 'react';

import { Modal, Button, Form, Dropdown, Col } from 'react-bootstrap/'

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import Select from 'react-select';
import CompanyService from '../../../services/company.service';

const newAddress = {
    id:null,
    name: null,
    address: null,
    city: null,
    zip: null,
    countryId: null,
};

class EditAddressModalComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            companyAddress: this.props.companyAddress,
            addressStatusList: [],
            address: newAddress,
            selectedCountry: null,
            countrySelect: []
        }
    }

    async componentDidMount() {
        const addressStatusList = await CompanyService.getCompanyAddressStatusList()
            .then(response => { return response })

        const countryList = await ApiService.getAuthenticatedInstance().get('/countries?size=1000')
            .then(result => {
                var list = result.data._embedded.countries;

                list.map((country) => {
                    const parts = country._links.self.href.split('/');
                    country.id = parts[parts.length - 1];
                    return country;
                });

                return list;
            })

        var countrySelect = [];
        countryList.forEach(element => {
            var country = {};
            country.value = element.id;
            country.label = element.name

            countrySelect.push(country);
        });

        this.setState({
            addressStatusList: addressStatusList,
            countrySelect: countrySelect,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.companyAddress !== this.props.companyAddress) {
            this.setState({
                companyAddress: this.props.companyAddress,
                selectedCountry: this.props.companyAddress.address
                    ? { value: this.props.companyAddress.address.country.id, label: this.props.companyAddress.address.country.name }
                    : null
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.companyAddress !== prevState.companyAddress) {
            return { companyAddress: prevState.companyAddress };
        }
        else return null;
    }

    handleCountryChange = (eventKey) => {
        this.setState({
            selectedCountry: eventKey
        })
    }

    handleOnStatusSelect = (eventKey) => {
        const selectedItem = this.state.addressStatusList.filter(item => item.name === eventKey);
        this.setState({
            companyAddress: {
                ...this.state.companyAddress,
                status: selectedItem[0]
            }
        });
    }

    saveChanges = (event) => {
        event.preventDefault();

        var address = this.state.address;
        if (this.state.companyAddress.address) {
            address = this.state.companyAddress.address;
        }

        address.name = event.target.elements.formName.value;
        address.address = event.target.elements.formAddress.value;
        address.zip = event.target.elements.formZip.value;
        address.city = event.target.elements.formCity.value;
        address.countryId = this.state.selectedCountry.value;

        this.props.onHide(address, this.state.companyAddress.status.name);
    };

    render() {
        var companyAddress = this.state.companyAddress;

        return (
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="edit-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="editmodal-form" className="ca-form" onSubmit={this.saveChanges}>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="formName">
                                <Form.Label>{translatedMessage("GENERAL.NAME")}</Form.Label>
                                <Form.Control
                                    name="name"
                                    required
                                    placeholder={translatedMessage("GENERAL.NAME_PLACEHOLDER")}
                                    defaultValue={companyAddress.address ? companyAddress.address.name : null}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formAddress">
                                <Form.Label>{translatedMessage("PROFILE.ADDRESS")}</Form.Label>
                                <Form.Control
                                    name="address"
                                    required
                                    placeholder={translatedMessage("PROFILE.ADDRESS_PLACEHOLDER")}
                                    defaultValue={companyAddress.address ? companyAddress.address.address : null}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formCity">
                                <Form.Label>{translatedMessage("PROFILE.CITY")}</Form.Label>
                                <Form.Control
                                    name="city"
                                    required
                                    placeholder={translatedMessage("PROFILE.CITY_PLACEHOLDER")}
                                    defaultValue={companyAddress.address ? companyAddress.address.city : null}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>
                                    {translatedMessage("PROFILE.COUNTRY")}
                                </Form.Label>
                                <Select
                                    className="basic-single ca-autocomplete"
                                    classNamePrefix="select"
                                    value={this.state.selectedCountry}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="countries"
                                    options={this.state.countrySelect}
                                    onChange={this.handleCountryChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formZip">
                                <Form.Label>{translatedMessage("PROFILE.ZIP")}</Form.Label>
                                <Form.Control
                                    name="zip"
                                    placeholder={translatedMessage("PROFILE.ZIP_PLACEHOLDER")}
                                    defaultValue={companyAddress.address ? companyAddress.address.zip : null}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="formStatus">
                                <Form.Label>
                                    {translatedMessage("GENERAL.STATUS")}
                                </Form.Label>
                                <Dropdown className="ca-dropdown" onSelect={this.handleOnStatusSelect}>
                                    <Dropdown.Toggle>
                                        {translatedMessage(companyAddress.status.label)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {this.state.addressStatusList.map((element, index) => {
                                            return (
                                                <Dropdown.Item key={index} eventKey={element.name}>
                                                    {translatedMessage(element.label)}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button" variant="dark" form="editmodal-form" type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default EditAddressModalComponent