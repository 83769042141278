import React from 'react';

import GalleryViewComponent from '../../../components/shared/Gallery/GalleryViewComponent';

class GalleryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryId: this.props.match.params.id,
            isLoaded: false
        };

    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }


    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <GalleryViewComponent
                    history={this.props.history}
                    galleryId={this.state.galleryId}
                    backUrl={`/gallery`}
                />
            )
        }
    }

}

export default GalleryPage;
