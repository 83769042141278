import React from 'react';

import { Card, Button, Image } from 'react-bootstrap';
import { translatedMessage } from '../../../services/language.service';
import ApiService from '../../../services/api.service';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';

import editIcon from '../../../components/shared/Resources/icons/edit.svg';
import submitIcon from '../../../components/shared/Resources/icons/submit.svg';
import artIcon from '../../../components/shared/Resources/icons/art.svg';
import mapPinIcon from '../../../components/shared/Resources/icons/map_pin.svg';
import previewIcon from '../../../components/shared/Resources/icons/preview.svg';
import publishedIcon from '../../../components/shared/Resources/icons/published.svg';

import ChangeStatusModal from '../ChangeStatusModalComponent/ChangeStatusModalComponent';

class ArtworkCardEditComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showStatusModal: false,
            status: this.props.cardStatus
        };
    }

    changeStatus = () => {
        this.setState({
            showStatusModal: true
        })
    }

    onHide = (id, status) => {
        if (id && status) {
            ApiService.getAuthenticatedInstance().patch(`/artwork/changeStatus/${id}?status=${status}`)
                .then((response) => {
                    if (response.data.status === "OK") {
                        toast.success(translatedMessage("ARTWORK.SAVE_SUCCESS"));

                        this.setState({
                            status: response.data.artworkStatus,
                            showStatusModal: false
                        })
                    } else {
                        toast.error(translatedMessage(response.data.message));

                        this.setState({ showStatusModal: false })
                    }
                })
                .catch(err => {
                    toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                    console.log("An error has ocurred: " + err);

                    this.setState({ showStatusModal: false })
                });
        } else {
            this.setState({ showStatusModal: false });
        }
    }

    render() {
        return (
            <>
                <Card className="ca-artwork-card">
                    <Link to={this.props.cardLink} className="ca-dark-link">
                        <Card.Img variant="top" src={this.props.cardImage} />
                        <Card.Body>
                            <Card.Title>
                                {this.props.cardTitle}
                                {this.props.cardAuthor &&
                                    <span className="font-weight-normal">
                                        &nbsp;{translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                                        {this.props.cardAuthor}
                                    </span>
                                }
                            </Card.Title>
                            <Card.Text><b>{translatedMessage("GENERAL.CATEGORY")}: </b>
                                {this.props.cardCategory}</Card.Text>
                            {this.props.cardType &&
                                <Card.Text><b>{translatedMessage("GENERAL.TYPE")}: </b>
                                    {this.props.cardType}</Card.Text>
                            }
                            <Card.Text><b>{translatedMessage("GENERAL.STATUS")}: </b>
                                {translatedMessage(this.state.status.label)}</Card.Text>
                        </Card.Body>
                    </Link>
                    <Card.Footer>
                        {this.props.hasPortfolioDisplayCheck &&
                            <div onClick={() => !this.props.isUnpublished && this.props.onShowOrHideDisplayInPortofolioModal(this.props.cardId)} className="ca-check-container">
                                <input
                                    checked={this.props.displayedInPublicPortofolio}
                                    readOnly
                                    type="checkbox"
                                    className="d-flex align-items-center"
                                    disabled={this.props.isUnpublished}
                                />
                                <span className="ca-check-label">{translatedMessage("ARTWORK.DISPLAYED_PORTOFOLIO")}</span>
                                <span className="ca-checkmark"></span>
                            </div>
                        }
                        {this.props.hasSpaceDisplayCheck &&
                            <div onClick={() => !this.props.isUnpublished && this.props.onShowOrHideDisplayInCompanySpaceModal(this.props.cardId)} className="ca-check-container">
                                <input
                                    checked={this.props.displayedInCompanySpace}
                                    readOnly
                                    type="checkbox"
                                    className="d-flex align-items-center"
                                    disabled={this.props.isUnpublished}
                                />
                                <span className="ca-check-label">{translatedMessage("VIRTUAL_GALLERY.DISPLAYED_IN_COMPANY_SPACE")}</span>
                                <span className="ca-checkmark"></span>
                            </div>
                        }
                        <div className="ca-footer-controls">
                            {this.props.canEdit && this.props.cardEditLink &&
                                <Link className="ca-dark-link small" to={this.props.cardEditLink} title={translatedMessage("ARTWORK.EDIT")}>
                                    <Image src={editIcon} alt={translatedMessage("ARTWORK.EDIT")} />
                                </Link>
                            }
                            {this.props.canEdit && this.props.canChangeStatus && this.state.status.name === 'PUBLISHED' &&
                                <Button variant="link" className="ca-button-icon small ml-1" onClick={this.changeStatus} title={translatedMessage("TABLE.CHANGE_STATUS") + " - " + translatedMessage(this.state.status.label)}>
                                    <Image src={publishedIcon} alt={translatedMessage("TABLE.CHANGE_STATUS")} />
                                </Button>
                            }
                            {this.props.canEdit && this.props.canChangeStatus && this.state.status.name === 'PREVIEW' &&
                                <Button variant="link" className="ca-button-icon small ml-1" onClick={this.changeStatus} title={translatedMessage("TABLE.CHANGE_STATUS") + " - " + translatedMessage(this.state.status.label)}>
                                    <Image src={previewIcon} alt={translatedMessage("TABLE.CHANGE_STATUS")} />
                                </Button>
                            }
                            {this.props.canEdit && this.props.canChangeStatus && this.state.status.name === 'SUBMITTED_FOR_APPROVAL' &&
                                <Button variant="link" className="ca-button-icon small ml-1" onClick={this.changeStatus} title={translatedMessage("TABLE.CHANGE_STATUS") + " - " + translatedMessage(this.state.status.label)}>
                                    <Image src={submitIcon} alt={translatedMessage("TABLE.CHANGE_STATUS")} />
                                </Button>
                            }
                            {this.props.virtualGalleryArtLink &&
                                <Link className="ca-dark-link small ml-1" to={`${this.props.virtualGalleryArtLink}`} title={translatedMessage("GENERAL.ARTWORKS.MANAGE")}>
                                    <Image src={artIcon} alt={translatedMessage("GENERAL.ARTWORKS.MANAGE")} />
                                </Link>
                            }
                            {this.props.canManageLocation && this.state.status.name === 'PUBLISHED' &&
                                <Link className="ca-dark-link small ml-1" to={this.props.cardMapLink} title={translatedMessage("GENERAL.LOCATION.MANAGE")}>
                                    <Image src={mapPinIcon} alt={translatedMessage("GENERAL.LOCATION.MANAGE")} />
                                </Link>
                            }
                        </div>
                    </Card.Footer>
                </Card>

                <ChangeStatusModal
                    show={this.state.showStatusModal}
                    onHide={this.onHide}
                    id={this.props.cardId}
                    status={this.state.status.name}
                />
            </>
        )
    }

}

export default ArtworkCardEditComponent;
