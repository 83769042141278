import React from 'react';
import '../Styles/ProjectPublishPage.css';

import { Row, Col, Button, Image, Form, OverlayTrigger, Tooltip } from 'react-bootstrap/';
import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import backArrow from '../../../components/shared/Resources/icons/back_white.svg';
import nextArrow from '../../../components/shared/Resources/icons/next_white.svg';
import projectIcon from '../../../components/shared/Resources/icons/project_white.svg';
import addIcon from '../../../components/shared/Resources/icons/add.svg';

import { toast } from "react-toastify";
import moment from 'moment';
import Switch from "react-switch";
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";
import PricePackageCardComponent from '../../../components/shared/PricePackage/CardComponents/SimplePricePackageAdminCardComponent';
import PricePackageModalComponent from '../../../components/shared/PricePackage/PricePackageModalComponent';
import AddPrintFileModalComponent from '../../../components/shared/PricePackage/AddPrintFileModalComponent';

import ArtworkService from '../../../services/artwork.service';
import FileService from '../../../services/file.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';

class ArtPublishPricePackage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            artwork: null,
            pricePackages: [],
            pricePackagesTableData: [],
            isLoaded: false,
            isError: false,
            errorMessage: '',
            showModal: false,
            modalData: {
                name: '',
                price: 0,
                nrCopies: 0,
                hasSignature: false,
                hasInvestorPackage: false,
                hasDigitalPrint: false,
                digitalPrintQuantity: 0
            },
            soldByCompanyDisabled: false,
            hasInvestorPackage: false,
            isFreePackageActive: false,
            hasDigitalPrint: false,
            digitalPrintQuantity: 0,
            showAddPrintFileModal: false,
            fileModalData: null,
            printImages: [],
            onlyView: false
        };
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        let isError = false;
        let errorMessage = "PROJECT.GENERAL_ERROR";

        let artwork = await ArtworkService.getArtByIdAndType(this.props.artId, this.props.artType, this.props.forProject)
            .then(result => { return result })
            .catch(err => {
                console.log("An error has ocurred: " + err);
                errorMessage = err;
                isError = true;
            });

        if (!isError) {
            var pricePackages = await ArtworkService.getPricePackagesByArtId(artwork.id, this.props.artType)
                .then(result => { return result })
                .catch(err => {
                    console.log("An error has ocurred: " + err);
                    errorMessage = err;
                    isError = true;
                });
        }

        if (!isError) {
            let activePackage = pricePackages.filter(item => item.active && !item.investorPackage);
            let isFreePackageActive = activePackage && activePackage[0] && activePackage[0].isFreePackage;
            let hasInvestorPackage = ArtworkService.hasInvestorPackage(pricePackages);

            let pricePackagesTableData = ArtworkService.computePricePackageData(pricePackages);
            pricePackagesTableData = this.computeOwnGallery(artwork.type, pricePackagesTableData);

            this.setState({
                artwork: {
                    ...artwork,
                    storageItem: {
                        id: artwork.storageItemId
                    }
                },
                pricePackagesTableData: pricePackagesTableData,
                hasInvestorPackage: hasInvestorPackage,
                hasDigitalPrint: ArtworkService.hasDigitalPrint(pricePackages),
                isFreePackageActive: isFreePackageActive,
                soldByCompanyDisabled: artwork.companyPortfolio && !hasInvestorPackage ? false : true,
                onlyView: artwork.status.name === ArtworkService.artworkStatusEnum().DRAFT || artwork.status.name === ArtworkService.artworkStatusEnum().PREVIEW ? false : true,
                isLoaded: true
            }, this.getStorageItems);
        } else {
            this.setState({
                isError: true,
                errorMessage: errorMessage,
                isLoaded: true
            });
        }
    }

    async getStorageItems() {
        if (this.state.hasDigitalPrint) {
            let printImages = await ArtworkService.getArtworkDigitalPrintFiles(this.state.artwork.id, this.props.artType);

            this.setState({
                printImages: printImages,
                digitalPrintQuantity: printImages.length
            })
        }

        this.activateFreePackage();
    }

    computeOwnGallery = (type, pricePackages) => {
        if (type && type.name === VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY) {
            pricePackages = pricePackages.filter(item => item.isFreePackage);
        }

        return pricePackages;
    }

    async activateFreePackage() {
        if (this.state.artwork.type && this.state.artwork.type.name === VirtualGalleryService.virtualGalleryTypeEnum().OWN_VIRTUAL_GALLERY) {
            let pricePackages = this.state.pricePackagesTableData;
            if (pricePackages[0] && !pricePackages[0].active) {
                ArtworkService.setActiveArtworkPricePackage(this.state.pricePackagesTableData[0].id)
                    .then(() => {
                        pricePackages[0].active = true;

                        this.setState({
                            pricePackagesTableData: pricePackages,
                        })
                    })
                    .catch(err => {
                        var errorMessage = "PROJECT.GENERAL_ERROR";
                        if (err && err.response && err.response.status && 404 === err.response.status) {
                            errorMessage = err.response.data.message;
                        }
                        console.log("An error has ocurred: " + err);
                        toast.error(translatedMessage(errorMessage));
                    });
            }
        }
    }


    reloadPackages = (pricePackages) => {
        let activePackage = pricePackages.filter(item => item.active && !item.investorPackage);
        let isFreePackageActive = activePackage && activePackage[0] && activePackage[0].isFreePackage;
        let hasInvestorPackage = ArtworkService.hasInvestorPackage(pricePackages);

        let pricePackagesTableData = ArtworkService.computePricePackageData(pricePackages);
        pricePackagesTableData = this.computeOwnGallery(this.state.artwork.type, pricePackagesTableData);

        this.setState({
            pricePackagesTableData: pricePackagesTableData,
            hasDigitalPrint: ArtworkService.hasDigitalPrint(pricePackages),
            hasInvestorPackage: hasInvestorPackage,
            isFreePackageActive: isFreePackageActive,
            artwork: {
                ...this.state.artwork,
                soldByCompany: isFreePackageActive ? false : this.state.artwork.soldByCompany
            }
        }, this.saveOptions);
    }

    onShowEdit = (data) => {
        let modalData = data;
        modalData.hasInvestorPackage = this.state.hasInvestorPackage;
        modalData.now = Date.now();

        this.setState({
            modalData: modalData,
            showModal: true
        })
    }

    onHideEdit = (data) => {
        if (data && data.id) {
            var pricePackage = {
                price: parseFloat(data.price),
                quantity: data.quantity ? parseInt(data.quantity) : null,
                hasSignature: data.hasSignature,
                hasInvestorPackage: data.hasInvestorPackage,
                hasDigitalPrint: data.hasDigitalPrint,
                digitalPrintQuantity: data.digitalPrintQuantity
            }

            ApiService.getAuthenticatedInstance().patch(`/artwork/saveArtworkPricePackage/${data.id}`, pricePackage)
                .then((response) => {
                    if (response.data.status === "OK") {
                        toast.success(translatedMessage(response.data.message));

                        let hasInvestorPackage = ArtworkService.hasInvestorPackage(response.data.pricePackages);

                        this.setState({
                            pricePackagesTableData: ArtworkService.computePricePackageData(response.data.pricePackages),
                            hasDigitalPrint: ArtworkService.hasDigitalPrint(response.data.pricePackages),
                            hasInvestorPackage: hasInvestorPackage,
                            soldByCompanyDisabled: this.state.artwork.companyPortfolio && !hasInvestorPackage ? false : true,
                            showModal: false
                        }, this.saveOptions)
                    } else {
                        toast.error(translatedMessage(response.data.message));

                        this.setState({
                            showModal: false
                        })
                    }
                })
                .catch(err => {
                    console.log("An error has ocurred: " + err);
                    console.log(err.response)
                    toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
                    this.setState({
                        showModal: false
                    });
                })
        } else {
            this.setState({
                showModal: false,
            })
        }
    }

    onSwitchChange = (event, key) => {
        this.setState({
            artwork: {
                ...this.state.artwork,
                [key]: event,
            }
        }, this.saveOptions);
    }

    saveOptions = () => {
        if (this.props.artType === ApiService.getEntityName().ARTWORK) {
            this.saveArtworkOptions();
        } else if (this.props.artType === ApiService.getEntityName().VIRTUAL_GALLERY) {
            this.saveVirtualGalleryOptions();
        }

        if (this.state.hasDigitalPrint) {
            this.getStorageItems();
        }
    }

    saveArtworkOptions = () => {
        let artwork = this.state.artwork;

        ApiService.getAuthenticatedInstance().patch(`/artworks/${artwork.id}`, {
            caPortfolio: artwork.caPortfolio,
            companyPortfolio: artwork.companyPortfolio,
            soldByCompany: artwork.companyPortfolio && !this.state.hasInvestorPackage ? artwork.soldByCompany : false,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
            .then(() => {
                this.setState({
                    artwork: {
                        ...this.state.artwork,
                        soldByCompany: artwork.companyPortfolio && !this.state.hasInvestorPackage ? artwork.soldByCompany : false,
                    },
                    soldByCompanyDisabled: artwork.companyPortfolio && !this.state.hasInvestorPackage ? false : true
                });
            })
            .catch(err => {
                console.log("An error has ocurred: " + err);
                console.log(err.response)
                toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
            })
    }

    saveVirtualGalleryOptions = () => {
        let virtualGallery = this.state.artwork;

        ApiService.getAuthenticatedInstance().patch(`/virtualGalleries/${virtualGallery.id}`, {
            caPortfolio: virtualGallery.caPortfolio,
            companyPortfolio: virtualGallery.companyPortfolio,
            soldByCompany: virtualGallery.companyPortfolio && !this.state.hasInvestorPackage ? virtualGallery.soldByCompany : false,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
            .then(() => {
                this.setState({
                    artwork: {
                        ...this.state.artwork,
                        soldByCompany: virtualGallery.companyPortfolio && !this.state.hasInvestorPackage ? virtualGallery.soldByCompany : false,
                    },
                    soldByCompanyDisabled: virtualGallery.companyPortfolio && !this.state.hasInvestorPackage ? false : true
                });
            })
            .catch(err => {
                console.log("An error has ocurred: " + err);
                console.log(err.response)
                toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
            })
    }

    handleMediaListChange = () => {
        this.getStorageItems();
    }

    onShowAddFile = () => {
        const modalData = {
            modalTitle: translatedMessage("PROJECT.UPLOAD_IMG"),
            fileUsage: FileService.getFileUsage().PRINT_FILE,
            fileType: FileService.getResourceType().IMAGE,
            deletePrevious: false,
            deletedImage: null,
            deletePreviousDesc: "",
            fileFormatsDesc: "DROPZONE.FILE_TYPES.IMAGE",
            maxSize: 100,
            maxSizeDesc: "DROPZONE.MAX_SIZE.DESC",
            multipleFiles: false,
            multipleFilesDesc: "",
            entity: this.state.artwork,
            storageitemlinked: true
        }

        this.setState({
            showAddPrintFileModal: true,
            fileModalData: modalData
        })
    }

    handleNextStep = () => {
        let isError = false;
        let errorMessage = "";
        let isPicture = this.props.artCategory === ArtworkService.artworkCategoryEnum().AR_ART || this.props.artCategory === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE 
            ? true : false;

        if (this.state.pricePackagesTableData.filter(item => item.active).length === 0) {
            isError = true;
            errorMessage = translatedMessage("ARTWORK_PRICE_PACKAGE.NOT_SELECTED");
        }

        if (!isError) {
            if (!isPicture) {
                let digitalPrintQuantity = ArtworkService.getDigitalPrintQuantity(this.state.pricePackagesTableData);
                if (digitalPrintQuantity > 0 && digitalPrintQuantity !== this.state.digitalPrintQuantity) {
                    isError = true;
                    errorMessage = translatedMessage("ARTWORK.PUBLISH.PRINT_NOT_UPLOADED");
                }
            }
        }

        if (!isError) {
            this.props.history.push(this.props.nextUrl);
        } else {
            toast.error(errorMessage, { autoClose: 7000 });
        }
    }

    onHideAddFile = (hasUpload) => {
        if (hasUpload && hasUpload === true) {
            this.getStorageItems();
        }
        this.setState({
            showAddPrintFileModal: false
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                const homeUlr = this.state.artwork.companyId ? `/my-company/${this.state.artwork.companyId}/portfolio` : `/projects`;

                let isPicture = this.props.artCategory === ArtworkService.artworkCategoryEnum().AR_ART || this.props.artCategory === ArtworkService.artworkCategoryEnum().DIGITAL_PICTURE 
                    ? true : false;

                return (
                    <>
                        <div className="d-flex flex-column h-100">
                            <div className="ca-publish-page">
                                <div className="w-100 ca-publish-page-title d-flex align-items-center justify-content-between">
                                    <div className="flex-grow-1 d-flex align-items-center">
                                        <Image src={this.props.icon} alt="Art icon" className="icon" />
                                        <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                                            <span className="font-weight-bold mr-1">{translatedMessage(this.props.pageTitle)}</span>
                                            <span className="font-weight-bold mr-1">|</span>
                                            <span>{translatedMessage("ARTWORK.PRICING")}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <Link to={homeUlr} className="ca-dark-link w-100">
                                            <Image className="icon-right" src={projectIcon} alt={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")}
                                                title={translatedMessage("NAVBAR.MENU.ARTWORKS.MY_PROJECTS")} />
                                        </Link>
                                    </div>
                                </div>
                                <div className="ca-page-padding ca-main-container">
                                    <Row>
                                        <Col className="ca-publish-section-container">
                                            <span className="ca-publish-section-title">
                                                {translatedMessage("ARTWORK.PRICING.DESCRIPTION")}
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row>
                                        {this.state.pricePackagesTableData.map((element, index) => {
                                            return (
                                                <Col key={index} className="col-12 col-sm-6 col-md-4 col-xl-3">
                                                    <PricePackageCardComponent
                                                        data={element}
                                                        onchange={this.reloadPackages}
                                                        showmodal={this.onShowEdit}
                                                        disabled={element.isFreePackage && this.state.artwork.nrFreeArtworkAvailable <= 0}
                                                        onlyView={this.state.onlyView}
                                                    />
                                                </Col>
                                            )
                                        })}
                                    </Row>

                                    <Row>
                                        <Col className={this.state.hasDigitalPrint && !isPicture ? "col-12 col-lg-6 mb-4" : "col-12"}>
                                            <div className="ca-publish-section-title w-100 mb-3">{translatedMessage("ARTWORK.PUBLISH.OPTIONS")}</div>
                                            <Form id="editoptions-form" className="ca-form">
                                                <Form.Group controlId="caPortfolio" as={Col} xs="12" className="pl-0">
                                                    <div className="d-flex align-items-center">
                                                        <Switch
                                                            onChange={(event) => this.onSwitchChange(event, "caPortfolio")}
                                                            checked={this.state.artwork.caPortfolio}
                                                            offColor="#F00"
                                                            disabled={this.state.onlyView}
                                                        />
                                                        <Form.Label className="mb-0 ml-1">
                                                            {translatedMessage("ARTWORK.PUBLISH.CA_PORTFOLIO")}
                                                            <OverlayTrigger
                                                                key='ca-portofolio'
                                                                placement='bottom'
                                                                overlay={
                                                                    <Tooltip id={`tooltip-ca-portofolio`} className="ca-tooltip">
                                                                        {translatedMessage("ARTWORK.PUBLISH.CA_PORTFOLIO_INFO")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="ca-question-icon"></span>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="companyPortfolio" as={Col} xs="12" className="pl-0">
                                                    <div className="d-flex align-items-center">
                                                        <Switch
                                                            onChange={(event) => this.onSwitchChange(event, "companyPortfolio")}
                                                            checked={this.state.artwork.companyPortfolio}
                                                            offColor="#F00"
                                                            disabled={this.state.onlyView}
                                                        />
                                                        <Form.Label className="mb-0 ml-1">
                                                            {translatedMessage("ARTWORK.PUBLISH.COMPANY_PORTFOLIO")}
                                                            <OverlayTrigger
                                                                key='company-portofolio'
                                                                placement='bottom'
                                                                overlay={
                                                                    <Tooltip id={`tooltip-company-portofolio`} className="ca-tooltip">
                                                                        {translatedMessage("ARTWORK.PUBLISH.COMPANY_PORTFOLIO_INFO")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="ca-question-icon"></span>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                    </div>
                                                </Form.Group>

                                                {!this.state.artwork.companyId &&
                                                    <Form.Group controlId="soldByCompany" as={Col} xs="12" className="pl-0">
                                                        <div className="d-flex align-items-center">
                                                            <Switch
                                                                onChange={(event) => this.onSwitchChange(event, "soldByCompany")}
                                                                checked={this.state.artwork.soldByCompany}
                                                                offColor="#F00"
                                                                disabled={this.state.isFreePackageActive || this.state.soldByCompanyDisabled || this.state.onlyView}
                                                            />
                                                            <Form.Label className="mb-0 ml-1">
                                                                {translatedMessage("ARTWORK.PUBLISH.COMPANY_COMM_PORTFOLIO")}
                                                                <OverlayTrigger
                                                                    key='commercial'
                                                                    placement='bottom'
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-commercial`} className="ca-tooltip">
                                                                            {translatedMessage("ARTWORK.PUBLISH.COMPANY_COMM_PORTFOLIO.INFO")}
                                                                            <br /><br />
                                                                            {translatedMessage("ARTWORK.PUBLISH.COMPANY_COMM_PORTFOLIO.CONDITIONS")}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="ca-question-icon"></span>
                                                                </OverlayTrigger>
                                                            </Form.Label>
                                                        </div>
                                                    </Form.Group>
                                                }
                                            </Form>
                                        </Col>
                                        {this.state.hasDigitalPrint && !isPicture &&
                                            <Col className="ca-publish-section-container col-12 col-lg-6">
                                                <div className="ca-publish-section-title w-100 mb-3">
                                                    <span>
                                                        {translatedMessage("PROJECT.UPLOAD_PRINT_FILE")}
                                                        <OverlayTrigger
                                                            key='addImage'
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-addImage`}>
                                                                    {translatedMessage("PROJECT.UPLOAD_PRINT_FILE.DESCRIPTION")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span className="ca-question-icon"></span>
                                                        </OverlayTrigger>
                                                    </span>

                                                    <span className="d-inline-block">
                                                        {!this.state.onlyView &&
                                                            <Button id="btnPrinFiles" variant="dark" className="ca-dark-link ca-button-icon small ml-2" onClick={this.onShowAddFile}>
                                                                <Image src={addIcon} alt={translatedMessage("GENERAL.ADD")} />
                                                            </Button>
                                                        }
                                                    </span>
                                                </div>
                                                {this.state.printImages.length > 0 &&
                                                    <Carousel
                                                        id="presImg"
                                                        items={this.state.printImages}
                                                        controls={true}
                                                        indicators={false}
                                                        interval={null}
                                                        class="mb-5"
                                                        hasToolbar={true}
                                                        toolbarDelete={this.state.onlyView ? false : true}
                                                        toolbarFullScreen={true}
                                                        canDeleteLast={this.state.onlyView ? false : true}
                                                        onChange={this.handleMediaListChange}
                                                    />
                                                }
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </div>

                            <div className="w-100 ca-publish-page-footer d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Button className="ca-button-icon mr-1" variant="link"
                                        onClick={() => this.props.history.push(this.props.backUrl)}>
                                        <Image src={backArrow} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                                    </Button>
                                    <span>{translatedMessage("BUTTON.BACK")}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span>{translatedMessage("BUTTON.NEXT")}</span>
                                    <Button className="ca-button-icon" variant="link" onClick={this.handleNextStep}>
                                        <Image src={nextArrow} alt={translatedMessage("BUTTON.NEXT")} title={translatedMessage("BUTTON.NEXT")} />
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <PricePackageModalComponent
                            show={this.state.showModal}
                            onHide={this.onHideEdit}
                            data={this.state.modalData}
                            category={this.props.artCategory}
                            companyid={this.state.artwork.companyId}
                        />

                        <AddPrintFileModalComponent
                            show={this.state.showAddPrintFileModal}
                            onHide={this.onHideAddFile}
                            data={this.state.fileModalData}
                        />
                    </>
                )
            }
        }
    }
}

export default ArtPublishPricePackage;