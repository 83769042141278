import React from 'react';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import ExhibitionArtworkComponent from '../../../components/shared/Exhibition/ExhibitionArtworkComponent';

class ExhibitionArtwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exhibitionId: this.props.match.params.exhibitionId,
      companyId: this.props.match.params.id,
      isLoaded: false,
      isError: false
    };
  }

  componentDidMount = () => {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionArtworkComponent
          exhibitionId={this.state.exhibitionId}
          backUrl={`/company-admin/${this.state.companyId}/exhibition-view/${this.state.exhibitionId}`}
          history={this.props.history}
        />        
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionArtwork;