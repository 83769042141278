import React from 'react';

import { Map, GoogleApiWrapper } from 'google-maps-react';
import { Row, Col, Button, Form } from 'react-bootstrap';

import MapService from "../../../services/map.service";
import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';

import { toast } from "react-toastify";
import moment from 'moment';

class EntityLocationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entityId: this.props.entityId,
      entityName: this.props.entityName,
      hasGeofence: this.props.hasGeofence,
      location: this.props.location,
      mapProps: null,
      map: null,
      marker: null,
      isLoaded: true,
      polygon: null
    };
  }

  onMapReady = (props, map) => {
    let initialCenter = this.props.initialCenter !== null ? this.props.initialCenter : MapService.getInitialCenter();
    let marker = new props.google.maps.Marker();
    let polygon = MapService.getInitFencePolygon(props, map, false, true, true);
    var location = {
      lat: this.state.location.locationLat,
      lng: this.state.location.locationLng
    };

    this.setState({
      mapProps: props,
      map: map,
      marker: marker,
      polygon: polygon
    })

    if (!(location.lat === 0 && location.lng === 0)) {
      map.setCenter(location);
      marker.setMap(map);
      marker.setPosition(location);
      marker.setDraggable(true);

      if (this.props.pinIcon !== null) {
        const icon = {
          url: this.props.pinIcon,
          scaledSize: new props.google.maps.Size(MapService.getBigPinSize().width, MapService.getBigPinSize().height),
        }
        marker.setIcon(icon);
      }

      if (this.state.location.geofence) {
        let geofenceCoordinates = JSON.parse(this.state.location.geofence)
        polygon.setPath(geofenceCoordinates)
        polygon.setVisible(true)
      }
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          map.setCenter(pos);
        }, () => {
          //Geolocation service failed
          map.setCenter(initialCenter);
        });
      } else {
        // Browser doesn't support Geolocation
        map.setCenter(initialCenter);
      }
    }

    // set the position of the parent with it's custom pin
    if (this.props.initialCenter !== null && this.props.parentPinIcon) {
      this.setMarker(map, props, this.props.initialCenter, this.props.parentEntityTitle, this.props.parentPinIcon)
    }

    // set the other locations from the entity on the map
    if (this.props.mapLocations.length > 0) {
      this.props.mapLocations.forEach(item => {
        let position = {
          lat: item.locationLat,
          lng: item.locationLng,
        }
        let pinUrl = `${ApiService.getBaseUrl()}/storage/file/${item.pinFile.uuid}`;

        this.setMarker(map, props, position, item.name, pinUrl)
      })
    }

    // onClick move the marker (including geofence) on the map
    map.addListener('click', (e) => {
      marker.setMap(map);
      marker.setPosition(e.latLng);
      marker.setDraggable(true);
      if (this.props.pinIcon !== null) {
        const icon = {
          url: this.props.pinIcon,
          scaledSize: new props.google.maps.Size(MapService.getBigPinSize().width, MapService.getBigPinSize().height),
        }
        marker.setIcon(icon);
      }

      this.setState({
        location: {
          ...this.state.location,
          locationLat: marker.position.lat(),
          locationLng: marker.position.lng()
        },
        marker: marker
      })
    });

    // onDrag marker replace it on the map (including geofence) on the map
    marker.addListener('dragend', () => {
      this.setState({
        location: {
          ...this.state.location,
          locationLat: marker.position.lat(),
          locationLng: marker.position.lng()
        },
        marker: marker
      })
    });

    // OnMouseOver add listeners for changing the polygon shape
    polygon.addListener('mouseover', () => {
      props.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        this.setState({
          location: {
            ...this.state.location,
            geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
          },
          polygon: polygon,
        })
      });

      props.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        this.setState({
          location: {
            ...this.state.location,
            geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
          },
          polygon: polygon,
        })
      });
    })
  }

  setMarker = (map, props, position, title, icon) => {
    var marker = new props.google.maps.Marker();

    marker.setMap(map);
    marker.setPosition(position);
    marker.setDraggable(false);
    marker.setTitle(title);

    if (icon !== null) {
      const markerIcon = {
        url: icon,
        scaledSize: new props.google.maps.Size(MapService.getSmallPinSize().width, MapService.getSmallPinSize().height),
      }
      marker.setIcon(markerIcon);
    }
  }

  addGeofence = () => {
    let location = {
      lat: this.state.location.locationLat,
      lng: this.state.location.locationLng
    };

    let initialCoordinates = MapService.getInitialPolygon(location);

    if (!(location.lat === 0 && location.lng === 0)) {
      let polygon = this.state.polygon;
      polygon.setPath(initialCoordinates)
      polygon.setVisible(true)

      this.setState({
        location: {
          ...this.state.location,
          geofence: JSON.stringify(MapService.getCoordinatesFromPolygon(polygon)),
        },
        polygon: polygon,
        hasGeofence: true
      });
    }
  }

  deleteGeofence = () => {
    let polygon = this.state.polygon
    polygon.setVisible(false);

    this.setState({
      polygon: polygon,
      location: {
        ...this.state.location,
        geofence: null
      },
      hasGeofence: false
    })
  }

  validate = (event) => {
    var response = {
      isError: false,
      message: ""
    };

    if (event.target.elements.formLat) {
      const lat = event.target.elements.formLat.value;

      if (!isFinite(lat) || !(Math.abs(lat) <= 90)) {
        response.isError = true;
        response.message = translatedMessage("MAP.LATITUDE.VALUE_ERROR");
        return response;
      };
    }

    if (event.target.elements.formLng) {
      const lng = event.target.elements.formLng.value;

      if (!isFinite(lng) || !(Math.abs(lng) <= 180)) {
        response.isError = true;
        response.message = translatedMessage("MAP.LONGITUDE.VALUE_ERROR");
        return response;
      }
    }

    return response;
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const validation = this.validate(event);
    if (!validation.isError) {
      const location = this.state.location;
      new Promise((resolve, reject) => {
        if (location.id === 0) {
          resolve(ApiService.getAuthenticatedInstance().post(`/locations`, {
            name: location.name,
            locationLat: location.locationLat,
            locationLng: location.locationLng,
            geofence: location.geofence,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          }));
        } else {
          resolve(ApiService.getAuthenticatedInstance().patch(`/locations/${location.id}`, {
            name: location.name,
            locationLat: location.locationLat,
            locationLng: location.locationLng,
            geofence: location.geofence,
            lastUpdatedBy: ApiService.getCurrentUser().username,
            lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          }));
        }
      })
        .then((result) => {
          const parts = result.data._links.self.href.split('/');
          const id = parts[parts.length - 1];
          this.setState({
            location: {
              ...this.state.location,
              id: id
            }
          })
          if (location.id === 0) {
            return ApiService.getAuthenticatedInstance().post(`/entityLocations`, {
              location: `/locations/${id}`,
              entity: {
                entityId: this.state.entityId,
                entityName: this.state.entityName
              },
              lastUpdatedBy: ApiService.getCurrentUser().username,
              lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            });
          }
        })
        .then(() => {
          toast.success(translatedMessage("GENERAL.SAVE_SUCCESS"));
        })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
        })
    } else {
      toast.error(validation.message);
    }
  }

  render() {
    if (!this.props.google || !this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <>
          <Row className="mb-0">
            <Col className="col-12 col-md-6 ca-main-container">
              <div className="mb-3 pr-2">
                <span className="ca-page-title">{this.props.entityTitle} - {translatedMessage("GENERAL.LOCATION.MANAGE")}</span>
                {this.props.parentEntityTitle &&
                  <>
                    <br />
                    <span className="ca-page-subtitle">{this.props.parentEntityTitle}</span>
                  </>
                }
              </div>
              <Form className="ca-form" onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="formName">
                        <Form.Label>
                          {translatedMessage("LOCATION.NAME")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder={translatedMessage("LOCATION.NAME_DESC")}
                          value={this.state.location.name}
                          onChange={e => this.setState({
                            location: {
                              ...this.state.location,
                              name: e.target.value
                            }
                          })
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="formLat">
                        <Form.Label>
                          {translatedMessage("MAP.LATITUDE")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder={""}
                          value={this.state.location.locationLat}
                          onChange={e => this.setState({
                            location: {
                              ...this.state.location,
                              locationLat: e.target.value
                            }
                          })
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="formLng">
                        <Form.Label>
                          {translatedMessage("MAP.LONGITUDE")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          required
                          placeholder={""}
                          value={this.state.location.locationLng}
                          onChange={e => this.setState({
                            location: {
                              ...this.state.location,
                              locationLng: e.target.value
                            }
                          })
                          }
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="align-items-center">
                  <Form.Group as={Col} md="12">
                    <Button className="ca-button mr-1 "
                      type="submit">{translatedMessage("GENERAL.SAVE_CHANGES")}</Button>
                    <Button className={"ca-button mr-1 ".concat(this.state.hasGeofence ? "ca-hide-button" : "")}
                      onClick={this.addGeofence}>{translatedMessage("MAP.GEOFENCE.ADD")}</Button>
                    <Button className={"ca-button mr-1 ".concat(!this.state.hasGeofence ? "ca-hide-button" : "")}
                      onClick={this.deleteGeofence}>{translatedMessage("MAP.GEOFENCE.DELETE")}</Button>
                    <Button className="ca-button ca-button-white"
                      onClick={() => this.props.history.go(-1)}>{translatedMessage("BUTTON.BACK")}</Button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
            <Col className="col-12 col-md-6 ca-map-column pl-0 pr-0">
              <Map style={{}} google={this.props.google} zoom={this.props.zoomLevel} initialCenter={MapService.getInitialCenter()} streetViewControl={false}
                onReady={this.onMapReady}>
              </Map>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default GoogleApiWrapper({ apiKey: MapService.getGoogleMapKey() })(EntityLocationComponent);