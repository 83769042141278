import React from 'react';
import { Row, Col, Card, Image, Button } from 'react-bootstrap/';

import './Styles/ArtworkViewComponent.css'

import card1 from '../../../components/shared/Resources/calogo-black.svg';
import backIcon from '../../../components/shared/Resources/icons/back.svg';
import editIcon from '../../../components/shared/Resources/icons/edit.svg';

import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import ArtworkService from '../../../services/artwork.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';
import DownloadService from '../../../services/download.service';

import DisplayVideoComponent from '../DisplayVideoComponent/DisplayVideoComponent';
import ExperienceCardComponent from '../ExperienceCardComponent/ExperienceCardComponent';
import Carousel from "../Carousel/MediaCarouselComponent";
import ErrorComponent from '../ErrorComponents/GenericErrorComponents';

class ArtworkViewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            artwork: {},
            storageItemId: null,
            authorName: "",
            author: [],
            artworkImages: [
                {
                    original: card1,
                    thumbnail: card1,
                }
            ],
            artworkVideos: [],
            pricePackageData: [],
            extendedExperiencesData: [],
            uploadedVideos: 0,
            isLoaded: false,
            isError: false,
            errorMessage: "",
            downloading: false,
            printFileCreated: false,
        };

        this.back = props.backLink;
        this.downloadPrintFile = this.downloadPrintFile.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    async populateData() {
        if (this.props.canView) {
            ArtworkService.getArtworkForEdit(this.props.artworkId, this.props.isAuthor)
                .then(response => {
                    let artwork = response;

                    this.setState({
                        artwork: {
                            ...artwork,
                            storageItem: {
                                id: artwork.storageItemId
                            }
                        },
                        printFileCreated: artwork.printFileCreated,
                        authorName: ArtworkService.getAuthorNameFromMap(artwork.authorList),
                        pricePackageData: artwork.pricePackages.sort((a, b) => a.price - b.price)
                    }, () => {
                        ApiService.getAuthenticatedInstance().get(`/artwork/ownerFiles/${artwork.id}`)
                            .then(results => {
                                const storageFiles = results.data.files;

                                const presentationImagesArray = [];

                                let hasModel = false;
                                // let hasModel = storageFiles[FileService.getFileUsage().OBJECT_MODEL] ? true : false
                                // if (hasModel) {
                                //     storageFiles[FileService.getFileUsage().OBJECT_MODEL]
                                //         .map((storageImage, index) => {
                                //             let modelObject = {};
                                //             modelObject.file = storageImage;
                                //             modelObject.type = FileService.getResourceType().OBJECT_MODEL;
                                //             modelObject.sortOrder = 0;
                                //             modelObject.artworkName = artwork.name;
                                //             presentationImagesArray.push(modelObject);
                                //             return storageImage;
                                //         });
                                // }

                                storageFiles[FileService.getFileUsage().THUMBNAIL]
                                    .map((storageImage, index) => {
                                        let image = {};
                                        let startIndex = hasModel ? 1 : 0;
                                        image.file = storageImage;
                                        image.original = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                                        image.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + storageImage.uuid;
                                        image.type = FileService.getResourceType().IMAGE;
                                        image.youtubeId = storageImage.youtubeId;
                                        image.sortOrder = storageImage.defaultThumbnail ? startIndex : index + startIndex + 1;

                                        presentationImagesArray.push(image);
                                        return storageImage;
                                    });

                                presentationImagesArray.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

                                const artworkVideos = storageFiles[FileService.getFileUsage().CREATE_DESTROY]
                                    .concat(storageFiles[FileService.getFileUsage().CREATION_PROC])
                                    .concat(storageFiles[FileService.getFileUsage().ART_DESC]);

                                let uploadedVideos = artworkVideos.length;

                                this.setState({
                                    artworkVideos: artworkVideos,
                                    uploadedVideos: uploadedVideos,
                                    artworkImages: presentationImagesArray
                                }, () => {
                                    ApiService.getAuthenticatedInstance().get(`/artwork/${this.props.artworkId}/manage/addOns`)
                                        .then(result => {
                                            let experiences = result.data.experiences
                                            if (experiences.length === 0) {
                                                experiences.push({
                                                    name: translatedMessage("ADD_ON.NOT_AVAILABLE.NAME"),
                                                    description: translatedMessage("ADD_ON.NOT_AVAILABLE.DESCRIPTION"),
                                                    isMain: false
                                                });
                                            }

                                            this.setState({
                                                extendedExperiencesData: experiences,
                                                isLoaded: true
                                            })
                                        })
                                });

                            });
                    });
                })
                .catch(err => {
                    var errorMessage = "ARTWORK.GENERAL_ERROR";
                    if (err && err.response && err.response.status && (400 === err.response.status || 403 === err.response.status || 404 === err.response.status)) {
                        errorMessage = err.response.data.message;
                    }
                    this.setState({
                        isError: true,
                        errorMessage: errorMessage,
                        isLoaded: true
                    })
                })
        } else {
            this.setState({
                isError: true,
                errorMessage: "USER.SECURITY.MISSING_RIGHTS",
                isLoaded: true
            })
        }
    }

    downloadPrintFile() {
        if (!this.state.downloading) {
            this.setState({
                downloading: true
            }, () => {
                DownloadService.httpRequest(
                    ApiService.getBaseUrl() + '/storage/savePrintFile/' + this.state.artwork.id, 'get')
                    .then(response => response.arrayBuffer())
                    .then((blob) => {
                        //Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'print_files.zip');

                        //Append to html page
                        document.body.appendChild(link);

                        //Force download
                        link.click();

                        //Clean up and remove the link                    
                        setTimeout(function () {
                            link.parentNode.removeChild(link);
                            URL.revokeObjectURL(url);
                        }, 3000);

                        this.setState({
                            downloading: false,
                            printFileCreated: true
                        });
                    })
                    .catch((error) => {
                        var errorMessage = translatedMessage("GENERAL.GENERIC_ERROR");
                        if (error.status === 403) {
                            errorMessage = translatedMessage("GENERAL.GENERIC_RIGHTS_ERROR")
                        } else if (error.status === 404) {
                            errorMessage = translatedMessage("ARTWORK.NOT_FOUND")
                        }

                        toast.error(errorMessage, { autoClose: 7000 });
                        
                        this.setState({
                            downloading: false,
                        });
                    });
            })
        }
    }

    render() {
        let thumbnail = card1;
        if (this.state.artworkImages[0] && this.state.artworkImages[0].original !== card1) {
            thumbnail = this.state.artworkImages[0].original;
        }
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            if (this.state.isError) {
                return (
                    <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
                )
            } else {
                let artwork = this.state.artwork;
                let isARArt = artwork.category.code === ArtworkService.artworkCategoryEnum().AR_ART ? true : false;

                return (
                    <div className="ca-page-padding ca-main-container">
                        <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
                            <div className="pr-2 d-flex align-items-center">
                                <div>
                                    <div className="ca-page-title" style={{ lineHeight: "30px" }}>{artwork.name}</div>
                                    <div className="ca-page-subtitle ca-muted-text">
                                        {translatedMessage("GENERAL.BY").toLowerCase()}
                                        {" " + this.state.authorName + " - "}
                                        {ApiService.parseDate(artwork.publishDate).toLocaleDateString("en-EN", { year: 'numeric', month: 'long' })}
                                    </div>
                                </div>
                            </div>
                            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
                                <Button className="ca-dark-link ca-button-icon medium mr-1" variant="link" onClick={() => this.props.history.push(this.props.editLink)}>
                                    <Image className="ca-page-title-icon ml-2 mr-0" src={editIcon} alt={translatedMessage("GENERAL.EDIT")}
                                        title={translatedMessage("GENERAL.EDIT")} />
                                </Button>

                                <Button className="ca-dark-link ca-button-icon mr-1" variant="link" onClick={() => this.props.history.push(this.props.backLink)}>
                                    <Image className="ca-page-title-icon ml-2 mr-0" src={backIcon} alt={translatedMessage("BUTTON.BACK")}
                                        title={translatedMessage("BUTTON.BACK")} />
                                </Button>
                            </div>
                        </Row>

                        <Row>
                            <Col className="col-12 col-lg-6 ca-artwork-column-left ca-artwork-image">
                                <Row>
                                    <Carousel
                                        id="image"
                                        items={this.state.artworkImages}
                                        controls={true}
                                        indicators={false}
                                        interval={null}
                                        class={"ca-carousel-big mx-auto ".concat(this.state.downloading ? "disabled" : "")}
                                        hasToolbar={true}
                                        toolbarDelete={false}
                                        toolbarYoutubeId={false}
                                        toolbarFullScreen={true}
                                    />
                                    {this.state.artwork.hasPrintFile &&
                                        <div className="text-center w-100 mt-2">
                                            <span className="ca-dark-link font-bold" onClick={this.downloadPrintFile} style={{ cursor: this.state.downloading ? "auto" : "pointer", width: "auto" }}>
                                                <span style={{ fontFamily: "FontAwesome", fontSize: "15px" }} className="mr-1">&#xf019;</span>
                                                {translatedMessage("ARTWORK.DOWNLOAD_PRINT_FILE")}
                                            </span>
                                        </div>
                                    }
                                    {this.state.downloading &&
                                        <div className="w-100 text-center" style={{ position: "absolute", top: "6.5rem" }}>
                                            <div className="fa-5x">
                                                <i className="fa fa-spinner fa-spin" ></i>
                                            </div>
                                            <div >{this.state.printFileCreated ? translatedMessage("GENERAL.DOWNLOADING") : translatedMessage("ARTWORK.PRINT_FILE.CREATE_DOWNLOAD")}</div>
                                        </div>
                                    }
                                </Row>
                            </Col>

                            <Col className="col-12 col-lg-6 ca-artwork-column-right">
                                <Row>
                                    {this.state.pricePackageData.map((element, index) => {
                                        return (
                                            <Col key={index} className="col-12 col-sm-6">
                                                <Card className={element.investorPackage ? "ca-artwork-pricing-card black-card details-card" : "ca-artwork-pricing-card details-card"}>
                                                    <Card.Header>
                                                        <Card.Title>{element.name}</Card.Title>
                                                        <div className="w-100 ca-card-detail-text">
                                                            {element.quantity === -1
                                                                ? translatedMessage("ARTWORK.IN_COLLECTION") + ": " + (element.nrCopies - element.availableCopies)
                                                                : translatedMessage("ARTWORK.AVAILABLE") + ": " + element.availableCopies + " / " + element.nrCopies
                                                            }
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body className="">
                                                        <div className="w-100">{ApiService.getNumberSeparatedByDots(element.price)} USD</div>
                                                    </Card.Body>
                                                    <Card.Footer style={{ fontSize: 13 + 'px' }}>
                                                        {translatedMessage(element.description)}
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Col>
                        </Row>

                        <Row className="view-description-about">
                            <Col className="col-12 col-lg-6 ca-artwork-column-left">
                                <div className="ca-paragraph-title">
                                    {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                                </div>

                                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: artwork.description }} />

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("PROJECT.CATEGORY")}
                                    </div>
                                    <div className="ca-view-value">{translatedMessage(artwork.category.code)}</div>
                                </div>

                                <div className="ca-display-group">
                                    <div className="ca-label">
                                        {translatedMessage("ARTWORK.ARTWORK_SIZE")}
                                    </div>
                                    <div className="ca-view-value">{artwork.sizeWidth} W x {artwork.sizeHeight} H cm</div>
                                </div>

                                {artwork.printSizeList && artwork.printSizeList.length > 0 &&
                                    <div className="ca-display-group">
                                        <div className="ca-label">
                                            {translatedMessage("ARTWORK.PRINT.SIZE")}
                                        </div>
                                        {artwork.printSizeList.map((element, index) => {
                                            return (
                                                <div key={index} className="ca-view-value">{element}</div>
                                            )
                                        })}
                                    </div>
                                }
                            </Col>
                            <Col className="col-12 col-lg-6 ca-artwork-column-right">
                                <div className="ca-paragraph-title">
                                    {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                                </div>
                                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: artwork.aboutAuthor }} />
                            </Col>
                        </Row>

                        {this.state.uploadedVideos === 0 ? "" :
                            <Row>
                                <div className="ca-paragraph-title w-100">
                                    {translatedMessage("PROJECT.VIDEOS")}
                                </div>
                                {this.state.artworkVideos.map((element, index) => {
                                    return (
                                        <Col key={index} className="col-12 col-md-6 col-lg-4 pl-0">
                                            <DisplayVideoComponent youtubeId={element.youtubeId} width="640" height="400"
                                                classvideo="ca-youtube-video-container"
                                                classimage="ca-novideo-container-md-hide"
                                                title={translatedMessage(element.usage)} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        }

                        {isARArt &&
                            <Row className="view-extended-experiences-row">
                                <div className="ca-paragraph-title w-100">
                                    {translatedMessage("ADD_ON.ADD_ON")}
                                </div>
                                {this.state.extendedExperiencesData.map((element, index) => {
                                    return (
                                        <Col key={index} className="col-12 col-sm-6 col-lg-4 col-xl-3 pl-0">
                                            <ExperienceCardComponent
                                                data={element}
                                                thumbnail={thumbnail}
                                                showThumbnail={false}
                                                showIsMain={false}
                                                variant="dark"
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                        }
                    </div>
                );
            }
        }
    }
}

export default ArtworkViewComponent;