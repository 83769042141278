import React from 'react';

import buyArt from '../../components/shared/Resources/homepage/buyArt.svg';

import { Row, Col, Form, Dropdown, InputGroup, Image } from 'react-bootstrap';

import CardComponent from "./GalleryCardComponent/GalleryCardComponent";

import { translatedMessage } from '../../services/language.service';
import GalleryService from '../../services/gallery.service';

const ENTER_KEY = 13;

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
            allGalleries: [],
            filteredGalleries: [],
            galleryTypeList: [],
            selectedType: { id: "0", nameKey: "GENERAL_TYPE.ANY" },
            isLoaded: false
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount = () => {
        this.getGallery()
    }

    getGallery() {
        var userGalleries = [];
        var galleryTypeList = [];
        var promise = [];

        promise.push(GalleryService.getUserGalleries(true)
            .then(response => {
                userGalleries = response;
            }))

        promise.push(GalleryService.getGalleryTypeList()
            .then(response => {
                galleryTypeList = response._embedded.galleryTypes;
                galleryTypeList.map(item => {
                    const parts = item._links.self.href.split('/');
                    const id = parts[parts.length - 1];
                    item.id = id;
                    return item;
                })
                galleryTypeList.splice(0, 0, { id: "0", nameKey: "GENERAL_TYPE.ANY" })
            })
        )

        Promise.all(promise).then(() => {
            this.setState({
                allGalleries: userGalleries,
                filteredGalleries: userGalleries,
                galleryTypeList: galleryTypeList,
                isLoaded: true
            })
        })
    }

    handleSearchInput = (e) => {
        this.setState({
            searchInput: e.target.value,
        }, this.filter);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.setState({
                searchInput: e.target.value,
            }, this.filter);
        }
    }

    handleTypeSelect = (eventKey, syntheticEvent) => {
        const selectedItem = this.state.galleryTypeList.filter(item => item.id === eventKey);

        this.setState({
            selectedType: selectedItem[0]
        }, this.filter)
    }

    filter = () => {
        let result = this.state.allGalleries;
        let searchInput = this.state.searchInput;
        let typeItem = this.state.selectedType.nameKey;

        if (0 !== searchInput.replace(/ /g, ";").length) {
            result = this.state.allGalleries.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
        }

        if (typeItem !== "GENERAL_TYPE.ANY") {
            result = result.filter(elem => elem.galleryType === typeItem.toString());
        }

        this.setState({
            filteredGalleries: result
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="fa-3x w-100 text-center" style={{}}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
            )
        } else {
            return (
                <div className="ca-page-padding ca-main-container">
                    <Row className="mb-3 pr-2 align-items-center">
                        <Image src={buyArt} className="ca-page-title-icon" alt={translatedMessage("GALLERY.GALLERIES")} />
                        <span className="ca-page-title">{translatedMessage("BUY_ART")}
                            <span className="font-weight-light">{" (" + translatedMessage("GALLERY.GALLERIES") + ")"}</span>
                        </span>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
                            <InputGroup className="ca-form-input-group">
                                <Form.Control value={this.state.searchInput} type="text"
                                    onChange={this.handleSearchInput}
                                    onKeyDown={this.handleKeyDown}
                                    placeholder={translatedMessage("SEARCH.BY.NAME")} />
                                <InputGroup.Append>
                                    <InputGroup.Text>&#xF002;</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col className="col-12 col-sm-6 mb-1 pl-0 pr-2">
                            <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect}>
                                <Dropdown.Toggle>
                                    {translatedMessage(this.state.selectedType.nameKey)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {this.state.galleryTypeList.map((element, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={element.id}>
                                                {translatedMessage(element.nameKey)}
                                            </Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <Row>
                        {this.state.filteredGalleries.map((element, index) => {
                            return (
                                <Col key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 pl-0 pr-0">
                                    <CardComponent
                                        cardImage={element.imageURL}
                                        cardTitle={element.name}
                                        id={0 === element.id ? 0 : element.id}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )
        }
    }
}

export default Gallery;
