import React from 'react';

import { Button, Modal, Card } from 'react-bootstrap';

import { toast } from "react-toastify";

import ApiService from "../../../services/api.service";
import { translatedMessage } from '../../../services/language.service';

class ArExperienceSelectModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            existingARE: this.props.existingARE,
            displayedArtworks: [],
            isLoaded: false
        }
    }

    // componentDidMount() {
    //     this.computeData();
    // }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show !== this.props.show) {
            this.setState({
                existingARE: this.props.existingARE                
            }, this.computeData);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.show !== prevState.show) {
            return {
                existingARE: prevState.existingARE
            };
        }
        else return null;
    }

    async computeData() {
        var displayedArtworks = await ApiService.getAuthenticatedInstance().get(`/arExperience/arExperienceList`)
        .then(response => {
            let data = response.data
            data.map((elem, i) => {
                elem.imageURL = ApiService.getFileByUuid(elem.thumbnailUuid) + '?' + Date.now();
                return elem;
            });

            return data
        });

        displayedArtworks = displayedArtworks.filter(item => !this.state.existingARE.includes(item.id))

        displayedArtworks.sort((a, b) => {
            var result;
            a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
            return result;
        });

        this.setState({
            displayedArtworks: displayedArtworks,
            isLoaded: true
        })
    }

    saveChanges = (artworkId) => {
        ApiService.getAuthenticatedInstance().post(`/artwork/demo_art/add/${artworkId}`)
            .then((response) => {
                if (response.data.status === "OK") {
                    toast.success(translatedMessage("DEMO_ART.ADD.SUCCESS"));

                    this.props.onHide(true);
                } else {
                    toast.error(translatedMessage(response.data.message));
                }
            })
            .catch(err => {
                toast.error(translatedMessage("GENERAL.SAVE_ERROR"));
                console.log("An error has ocurred: " + err);
            }); 
    }

    render() {
        return (            
            <Modal
                onHide={this.props.onHide}
                show={this.props.show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="ca-artworks-modal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="edit-modal-title">
                        {translatedMessage("ARTWORK.ARTWORKS")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-columns">
                        {this.state.displayedArtworks.map((element, index) => {
                            return (
                                <Card key={index} className="ca-card-image-only" onClick={() => this.saveChanges(element.id)} style={{cursor: "pointer"}}>
                                    <Card.Img variant="top" src={element.imageURL} />
                                    <div style={{ position: "absolute", bottom: "10px", width: "100%", textAlign: "center" }}>
                                        <span style={{ padding: "5px 5px", backgroundColor: "#333333", color: "#333333", opacity: "0.6" }}>
                                            {element.name}
                                        </span>
                                        <div style={{ position: "absolute", color: "#ffffff", top: "0", width: "100%", textAlign: "center" }}>
                                            {element.name}
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="ca-button ca-button-white" variant="dark" onClick={() => this.props.onHide(false)}>
                        {translatedMessage("GENERAL.CLOSE")}
                    </Button>
                </Modal.Footer>
            </Modal >
        )
    }
}

export default ArExperienceSelectModal;