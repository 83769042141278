import React from 'react';
import { Row, Col, Card, Image, Button } from 'react-bootstrap/';

import back from '../../../components/shared/Resources/left-arrow.svg';
import virtualGalleryIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import soldout from '../../../components/shared/Resources/sold_out.png';
import limitedEdition from '../../../components/shared/Resources/limited_edition.png';
import caLogoBlack from '../../../components/shared/Resources/logo-dark-small-with-padding.png'
import caLogo from '../../../components/shared/Resources/calogo.png'

import { Link } from 'react-router-dom';
import { toast } from "react-toastify";

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import FileService from '../../../services/file.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';

import Carousel from "../../../components/shared/Carousel/MediaCarouselComponent";
import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import ArtworkBuyModalComponent from '../../ViewArtworkPage/ArtworkBuyModalComponent/ArtworkBuyModalComponent';
import PaymentService from '../../../services/payment.service';
import ArtworkService from '../../../services/artwork.service';

class VirtualGalleryViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      virtualGalleryId: this.props.match.params.id,
      virtualGallery: null,
      storageItemId: null,
      carouselMedia: [
        {
          file: caLogo,
          original: caLogo,
          thumbnail: caLogoBlack,
          type: FileService.getResourceType().IMAGE
        }
      ],
      pricePackageData: [],
      isLoaded: false,
      isError: false,
      errorMessage: "",
      downloading: false,
      printFileCreated: false,
      exchangeRate: 8.6,
      buyModalData: {
        name: "",
        quantity: "",
        price: "",
        exclusive: "",
        description: "",
        nrCopies: "",
        availableCopies: ""
      },
      buyModalShow: false
    };
  }

  componentDidMount() {
    ApiService.initCurrentUser().then(() => { this.populateData() });
  }

  async populateData() {
    VirtualGalleryService.getVirtualGallery(this.state.virtualGalleryId)
      .then(response => {
        let virtualGallery = response;

        let carouselMedia = [];

        if (virtualGallery.fileList) {
          virtualGallery.fileList.map((file, index) => {
            let image = {};
            let startIndex = 0;

            image.file = { uuid: file.uuid };;
            image.original = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
            image.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + file.uuid;
            image.type = FileService.getResourceType().IMAGE;
            image.youtubeId = file.youtubeId;
            image.sortOrder = file.defaultThumbnail ? startIndex : index + startIndex + 1;

            carouselMedia.push(image);
            return file;
          });
        }

        if (virtualGallery.videoUrlList) {
          virtualGallery.videoUrlList
            .map(item => carouselMedia.push({ original: null, thumbnail: null, videoId: item.videoId, type: item.type, file: null }));
        }

        if (virtualGallery.portalMapUuid) {
          let image = {};

          image.file = { uuid: virtualGallery.portalMapUuid };
          image.original = ApiService.getBaseUrl() + '/storage/file/' + virtualGallery.portalMapUuid;
          image.thumbnail = ApiService.getBaseUrl() + '/storage/file/' + virtualGallery.portalMapUuid;
          image.type = FileService.getResourceType().IMAGE;

          carouselMedia.push(image);
        }

        let pricePackages = virtualGallery.pricePackages.sort((a, b) => a.price - b.price);

        this.setState({
          virtualGallery: {
            ...virtualGallery,
            storageItem: {
              id: response.storageItemId
            }
          },
          carouselMedia: carouselMedia.length > 0 ? carouselMedia : this.state.carouselMedia,
          pricePackageData: pricePackages
        }, async () => {
          let exchangeRate = await ApiService.getAuthenticatedInstance().get(`/exchangeRate`)
            .then(result => { return result.data })
            .catch(err => console.log(err));

          this.setState({
            exchangeRate: exchangeRate === 0 ? 8.6 : exchangeRate,
            isLoaded: true
          })
        });
      })
      .catch(err => {
        var errorMessage = "VIRTUAL_GALLERY.GENERAL_ERROR";
        if (err && err.response && err.response.status && (400 === err.response.status || 403 === err.response.status || 404 === err.response.status)) {
          errorMessage = err.response.data.message;
        }
        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      })
  }

  onShowOrHideBuyModal = (element = null) => {
    if (this.state.buyModalShow === false) {
      this.setState({
        buyModalData: element,
        buyModalShow: true,
      })
    } else {
      this.setState({
        buyModalShow: false,
      })
    }
  }

  buy = (artworkPackage) => {
    ApiService.initCurrentUser().then(user => {
      let addressId = ApiService.getCurrentProfile().address ? ApiService.getCurrentProfile().address.id : null;
      let companyId = this.props.location.state && this.props.location.state.companyId ? this.props.location.state.companyId : null;

      PaymentService.getCart(user, addressId)
        .then(cartId => {
          PaymentService.addToCart(user, cartId, artworkPackage, companyId)
            .then(() => { toast.success(translatedMessage("CART.ADD_SUCCESS")); })
            .catch(() => { toast.error(translatedMessage("CART.ADD_FAIL")); });
        })
        .catch(err => { toast.error(translatedMessage(err)) })
    });
  }

  addFreeArt = (element) => {
    const packageId = element.id
    ApiService.getAuthenticatedInstance().post(`/virtualGallery/add-free/${packageId}`)
      .then(response => {
        if (response.data.status === "OK") {
          toast.success(translatedMessage(response.data.message));
        } else {
          toast.error(translatedMessage(response.data.message));
        }
      })
      .catch(err => {
        console.log(err)
        toast.error(translatedMessage("GENERAL.GENERIC_ERROR"));
      })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.isError) {
        return (
          <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
        )
      } else {
        let virtualGallery = this.state.virtualGallery;

        return (
          <div className="ca-page-padding ca-main-container">
            <ArtworkBuyModalComponent
              show={this.state.buyModalShow}
              onHide={this.onShowOrHideBuyModal}
              element={this.state.buyModalData}
              category={ArtworkService.artworkCategoryEnum().AR_PORTAL}
              exchangerate={this.state.exchangeRate}
              artworkname={virtualGallery.name}
              author={virtualGallery.author}
              buy={this.buy}
              addfreeart={this.addFreeArt}
            />

            <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
              <div className="pr-2 d-flex align-items-center">
                <Image src={virtualGalleryIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY")} />
                <div>
                  <div className="ca-page-title" style={{ lineHeight: "30px" }}>{virtualGallery.name}</div>
                  <div className="ca-page-subtitle ca-muted-text">
                    {translatedMessage("GENERAL.BY").toLowerCase()}&nbsp;
                    {this.state.virtualGallery.authorId &&
                      <Link to={`/profile/${this.state.virtualGallery.authorId}/view`}>
                        {this.state.virtualGallery.author}
                      </Link>
                    }
                    {!this.state.virtualGallery.authorId &&
                      this.state.virtualGallery.author
                    }
                  </div>
                </div>
              </div>
              <div className="ca-page-title-button-row-buttons d-flex justify-content-end">
                <Link to="#" className="ca-dark-link" onClick={() => this.props.history.go(-1)}>
                  <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                    title={translatedMessage("BUTTON.BACK")} />
                </Link>
              </div>
            </Row>

            <Row>
              <Col className="col-12 col-lg-6 ca-row-left-column ca-artwork-image mb-4">
                <Row>
                  <Carousel
                    id="image"
                    items={this.state.carouselMedia}
                    controls={true}
                    indicators={false}
                    interval={null}
                    class={"ca-carousel-big mx-auto ".concat(this.state.downloading ? "disabled" : "")}
                    hasToolbar={true}
                    toolbarDelete={false}
                    toolbarYoutubeId={false}
                    toolbarFullScreen={true}
                    videoUrlOnly={true}
                  />
                </Row>
              </Col>

              {this.state.pricePackageData.length > 0 &&
                <Col className="col-12 col-lg-6 ca-row-right-column">
                  <Row>
                    {this.state.pricePackageData.map((element, index) => {
                      return (
                        <Col key={index} className="col-12 col-sm-6">
                          <Card className={element.investorPackage ? "ca-artwork-pricing-card black-card" : "ca-artwork-pricing-card"}>
                            {element.availableCopies === 0
                              ? <Image src={soldout} className="ca-soldout-image" />
                              : element.quantity > 0
                                ? <Image src={limitedEdition} className="ca-limited-image" />
                                : ""
                            }
                            <Card.Header>
                              <Card.Title>{element.name}</Card.Title>
                              <div className="w-100 ca-card-detail-text">
                                <span>
                                  {element.quantity === -1
                                    ? ""
                                    : translatedMessage("ARTWORK.AVAILABLE") + ": " + element.availableCopies + " / " + element.nrCopies}
                                  &nbsp;
                                </span>
                              </div>
                            </Card.Header>
                            <Card.Body className="">
                              <div>
                                <div className="w-100">{ApiService.getNumberSeparatedByDots(element.price)} USD</div>
                                <div className="ca-card-detail-text w-100">
                                  {translatedMessage("ARTWORK.PRICE_EST_CURR")} {ApiService.getNumberSeparatedByDots(element.price * this.state.exchangeRate)} NOK</div>
                              </div>
                            </Card.Body>
                            <Card.Footer>
                              {element.isFreePackage
                                ?
                                <Button className={element.investorPackage ? "ca-button ca-button-white" : "ca-button"}
                                  onClick={() => this.addFreeArt(element)}
                                  disabled={element.availableCopies === 0 ? true : false}>
                                  {translatedMessage("ARTWORK.ADD_TO_COLLECTION")}
                                </Button>
                                :
                                <Button className={element.investorPackage ? "ca-button ca-button-white" : "ca-button"}
                                  onClick={() => this.buy(element)}
                                  disabled={element.availableCopies === 0 ? true : false}>
                                  {translatedMessage("GENERAL.BUY")}
                                </Button>
                              }
                              <div className="more-info-button" onClick={() => this.onShowOrHideBuyModal(element)}>
                                {translatedMessage("GENERAL.MORE_INFO")}
                              </div>
                            </Card.Footer>
                          </Card>

                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              }

              <Col className={"col-12 col-lg-6 ".concat(this.state.pricePackageData.length === 0 ? "ca-row-right-column" : "ca-row-left-column")}>
                <div className="ca-paragraph-title">
                  {translatedMessage("ARTWORK.ABOUT_ARTWORK")}
                </div>

                <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: virtualGallery.description }} />

                <div className="ca-display-group">
                  <div className="ca-label">
                    {translatedMessage("GENERAL.TYPE")}
                  </div>
                  <div className="ca-view-value">{translatedMessage(virtualGallery.type.label)}</div>
                </div>

                {(virtualGallery.nrArArt !== 0 || virtualGallery.nrSculpture !== 0) &&
                  <div className="ca-display-group mt-3">
                    <div className="ca-label">
                      {translatedMessage("VIRTUAL_GALLERY.DISPLAYED_ARTWORKS")}
                    </div>
                    <div className="ca-view-value">
                      <ul>
                        <li>
                          {translatedMessage("AR_PORTAL.AR_ART_NO") + ":  " + virtualGallery.nrArArt}
                        </li>
                        <li>
                          {translatedMessage("AR_PORTAL.SCULPTURE_NO") + ":  " + virtualGallery.nrSculpture}
                        </li>
                      </ul>
                    </div>
                  </div>
                }
              </Col>
              {virtualGallery.aboutAuthor &&
                <Col className={"col-12 col-lg-6 ".concat(this.state.pricePackageData.length === 0 ? "ca-row-left-column" : "ca-row-right-column")}>
                  <div className="ca-paragraph-title">
                    {translatedMessage("ARTWORK.ABOUT_AUTHOR")}
                  </div>
                  <div className="ca-description-value" dangerouslySetInnerHTML={{ __html: virtualGallery.aboutAuthor }} />
                </Col>
              }
            </Row>
          </div>
        );
      }
    }
  }
}

export default VirtualGalleryViewPage;