import React from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import { translatedMessage } from "../../../services/language.service";

class ChangePassePartoutColorComponentModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      color: this.props.data
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("componentDidUpdate - this.props.data: " + this.props.data)
    // console.log("componentDidUpdate - prevProps.data: " + prevProps.data)
    // console.log("componentDidUpdate - prevState.color: " + prevState.color)
    if (prevState.color !== this.props.data) {      
      this.setState({ color: this.props.data });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("getDerivedStateFromProps - nextProps.data: " + nextProps.data)
    // console.log("getDerivedStateFromProps - prevState.color: " + prevState.color)    
    if (nextProps.data !== prevState.color) {
      return { color: prevState.color };
    }
    else return null;
  }

  handleSelection = (event) => {
    var color = this.state.color;

    if (event.target.id === "white") {
      color = "255,255,255"
    } else {
      color = "0,0,0"
    }

    this.setState({
      color: color
    })
  }

  render() {
    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        className="ca-modal ca-artwork-publish-modal"
      >
        <Modal.Header className="empty-modal-header"></Modal.Header>
        <div id="contained-modal-title-vcenter" className="ca-modal-title text-center">
          {translatedMessage("ARTWORK.PRINT.CHANGE_PASSEPARTOUT_COLOR")}
        </div>
        <Modal.Body className="d-flex justify-content-around mt-4 mb-4">
          <Form.Check
            inline
            label={translatedMessage("COLOR.WHITE")}
            name="group1"
            type="radio"
            id="white"
            checked={this.state.color === '255,255,255'}
            onChange={this.handleSelection}
            className="mr-0"
          />
          <Form.Check
            inline
            label={translatedMessage("COLOR.BLACK")}
            name="group1"
            type="radio"
            id="black"
            checked={this.state.color === '0,0,0'}
            onChange={this.handleSelection}
            className="mr-0"
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-around">
          <Button className="ca-button mr-0 ml-0" variant="dark" onClick={() => this.props.onHide(this.state.color)}>
            {translatedMessage("GENERAL.SAVE_CHANGES")}
          </Button>
          <Button variant="dark" className="ca-button ca-button-white mr-0 ml-0" onClick={() => this.props.onHide()}>
            {translatedMessage("GENERAL.CLOSE")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ChangePassePartoutColorComponentModal;