import React from 'react';

import './CardComponent.css';
import { Card } from 'react-bootstrap';
import LanguageService from '../../../services/language.service';
import { Link } from 'react-router-dom';

class CardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Link to={this.props.cardLink} className="ca-dark-link">                    
                    <Card className="ca-artwork-card">
                        <Card.Img variant="top" src={this.props.cardImage} />
                        <Card.Body>
                            <Card.Title>{this.props.cardTitle}</Card.Title>
                            <Card.Text><b>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.CATEGORY")}: </b>
                                {this.props.cardCategory}</Card.Text>
                            <Card.Text><b>{window.$translate(LanguageService.getCurrentLanguage(), "GENERAL.STATUS")}: </b>
                                {this.props.cardStatus}</Card.Text>
                        </Card.Body>
                    </Card>
                </Link>
            </>
        )
    }

}

export default CardComponent;