import React from 'react';

import ExhibitionManageVirtualGalleryComponent from '../../../../components/shared/Exhibition/ExhibitionManageVirtualGalleryComponent';
import ErrorComponent from '../../../../components/shared/ErrorComponents/GenericErrorComponents';

import ApiService from '../../../../services/api.service';
import exhibitionIcon from '../../../../components/shared/Resources/icons/exhibition.svg';

class ExhibitionAdminVirtualGalleryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      exhibitionId: this.props.match.params.exhibitionId,
      isLoaded: false,
      canEdit: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
      .then(response => {
        let company = response.data.company;

        if (company.status.name === 'ACTIVE') {
          this.setState({
            company: company,
            canEdit: true,
            isLoaded: true
          })
        } else {
          this.setState({
            isError: true,
            errorMessage: "COMPANY.NOT_APPROVED",
            isLoaded: true
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      return (
        <ExhibitionManageVirtualGalleryComponent
          history={this.props.history}
          canEdit={this.state.canEdit}
          isAdmin={false}
          exhibitionId={this.state.exhibitionId}
          hasFreeForEntityOption={false}
          backUrl={`/my-company/${this.state.companyId}/exhibition`}
          mapUrl={`/exhibition/${this.state.exhibitionId}/map`}
          artworkPrefixUrl={`/my-company/${this.state.companyId}/exhibition-virtual-gallery`}
          icon={exhibitionIcon}
        />
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default ExhibitionAdminVirtualGalleryList;