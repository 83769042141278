import React from 'react';

import { Row, Col, Dropdown, Form, Button, Image } from 'react-bootstrap';
import ApiService from '../../../services/api.service';
import CompanyService from '../../../services/company.service';
import { translatedMessage } from '../../../services/language.service';

import ErrorComponent from '../../../components/shared/ErrorComponents/GenericErrorComponents';
import companyIcon from '../../../components/shared/Resources/icons/company/company.svg';
import submitIcon from '../../../components/shared/Resources/icons/submit.svg';
import saveIcon from '../../../components/shared/Resources/icons/save.svg';
import back from '../../../components/shared/Resources/left-arrow.svg';

import { toast } from "react-toastify";
import moment from 'moment';
import ValidationService from '../../../services/validation.service';

class CompanyAdminEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      company: [],
      companyTypeList: [],
      isLoaded: false,
      canEdit: false,
      isError: false,
      isNew: false,
      errorMessage: "",
      submitForApproval: false
    };
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    if (this.state.companyId) {
      ApiService.getAuthenticatedInstance().get(`/company/${this.state.companyId}`)
        .then(response => {
          this.setState({
            company: response.data.company,
            isNew: false
          }, this.getData)
        })
        .catch(err => {
          console.log(err)
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }

          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        });
    } else {
      this.setState({
        isNew: true,
        company: [],
      }, this.getData)
    }
  }

  async getData() {
    var company = this.state.company;

    var companyTypeList = await CompanyService.getCompanyTypeList()
      .then(response => { return response; });

    if (this.state.isNew) {
      const typeItem = companyTypeList.filter(item => item.name === 'GALLERY');
      company.status = { name: "DRAFT" };
      company.type = typeItem[0];
    }
    this.setState({
      companyTypeList: companyTypeList,
      company: company,
      isLoaded: true
    })
  }

  handleOnTypeSelect = (eventKey) => {
    const selectedItem = this.state.companyTypeList.filter(item => item.name === eventKey);
    this.setState({
      company: {
        ...this.state.company,
        type: selectedItem[0]
      }
    });
  }

  validate = (event, websiteUrl) => {
    var response = {
      isError: false,
      message: ""
    };

    if (!websiteUrl) {    
      response.isError = true;
      response.message = translatedMessage("COMPANY.WEBSITE.BAD_FORMAT");

      return response;
    }

    return response;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.persist();

    let successMessage = "COMPANY.SAVE_SUCCESS"
    let status = this.state.company.status.name;

    if (this.state.submitForApproval) {
      status = "PENDING_APPROVAL";
      successMessage = "COMPANY.SUBMIT_SUCCESS"
    }

    let website = event.target.elements.formWebsite.value;
    let websiteUrl = { href: null };
    if (website) {
      websiteUrl = ValidationService.checkUrl(website);    
    }

    const validation = this.validate(event, websiteUrl);
    if (!validation.isError) {
      var promise;
      promise = new Promise((resolve, reject) => {
        let path = "/company/my/";
        path = path + (this.state.company.id ? `${this.state.company.id}/save` : `save`);
        
        ApiService.getAuthenticatedInstance().post(path, {
          name: event.target.elements.formName.value,
          website: websiteUrl.href,
          phone: event.target.elements.formPhone.value,
          email: event.target.elements.formEmail.value,
          status: status,
          type: this.state.company.type.name,
          lastUpdatedBy: ApiService.getCurrentUser().username,
          lastUpdatedOn: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),          
        })
          .then((response) => {
            this.setState({
              company: response.data.company,
              isNew: false
            })
            resolve();
          })
          .catch(err => reject(err))
      });

      promise.then(() => {
        toast.success(translatedMessage(successMessage));
      })
        .catch(err => {
          console.log("An error has ocurred: " + err);
          toast.error(translatedMessage("COMPANY.SAVE_ERROR"));
        })

    } else {
      toast.error(validation.message);
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else if (!this.state.isError) {
      var company = this.state.company;
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-4 pr-2 align-items-center">
            <div className="pr-2 d-flex align-items-center">
              <Image src={companyIcon} className="ca-page-title-icon" alt={translatedMessage("COMPANY.COMPANY")} />
              <div className="ca-page-title">{translatedMessage("COMPANY.EDIT")}</div>
            </div>

            <div className="ca-page-title-button-row-buttons d-flex justify-content-end align-items-center">
              <Button className="ca-dark-link ca-button-icon medium mr-1" type="submit" variant="link" form="company-form">
                <Image src={saveIcon} alt={translatedMessage("GENERAL.SAVE_CHANGES")} title={translatedMessage("GENERAL.SAVE_CHANGES")} />
              </Button>
              {this.state.company && this.state.company.id && this.state.company.status.name === "DRAFT" &&
                <Button className="ca-dark-link ca-button-icon medium mr-1" type="submit" variant="link" form="company-form"
                  disabled={this.state.company && this.state.company.status.name === "PENDING_APPROVAL"}
                  onClick={() => this.setState({ submitForApproval: true })}>
                  <Image src={submitIcon} alt={translatedMessage("COMPANY.SUBMIT_FOR_APPROVAL")} title={translatedMessage("COMPANY.SUBMIT_FOR_APPROVAL")} />
                </Button>
              }
              {!this.state.isNew &&                
                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-company/${this.state.company.id}/home`)}>
                  <Image src={back} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                </Button>
              }
              {this.state.isNew &&
                <Button className="ca-dark-link ca-button-icon" variant="link" onClick={() => this.props.history.push(`/my-companies`)}>
                  <Image src={back} alt={translatedMessage("BUTTON.BACK")} title={translatedMessage("BUTTON.BACK")} />
                </Button>
              }
            </div>
          </Row>


          <Form id="company-form" className="ca-form" onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group controlId="formName" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("COMPANY.NAME")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={translatedMessage("COMPANY.NAME_PLACEHOLDER")}
                  defaultValue={company.name}
                />
              </Form.Group>

              <Form.Group controlId="formWebsite" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("COMPANY.WEBSITE")}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder={translatedMessage("COMPANY.WEBSITE_PLACEHOLDER")}
                  defaultValue={company.website}
                />
              </Form.Group>

              <Form.Group controlId="formPhone" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("GENERAL.PHONE")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={translatedMessage("GENERAL.PHONE_PLACEHOLDER")}
                  defaultValue={company.phone}
                />
              </Form.Group>

              <Form.Group controlId="formEmail" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("GENERAL.EMAIL")}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={translatedMessage("GENERAL.EMAIL_PLACEHOLDER")}
                  defaultValue={company.email}
                />
              </Form.Group>

              <Form.Group controlId="formType" as={Col} md="6">
                <Form.Label>
                  {translatedMessage("COMPANY.TYPE")}
                </Form.Label>
                <Dropdown className="ca-dropdown" onSelect={this.handleOnTypeSelect}>
                  <Dropdown.Toggle>
                    {translatedMessage(company.type.label)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.companyTypeList.map((element, index) => {
                      return (
                        <Dropdown.Item key={index} eventKey={element.name}>
                          {translatedMessage(element.label)}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>

              {this.state.company && this.state.company.id &&
                <Form.Group controlId="formStatus" as={Col} md="6">
                  <Form.Label>
                    {translatedMessage("COMPANY.STATUS")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    disabled={true}
                    value={translatedMessage(company.status.label)}
                  />
                </Form.Group>
              }
            </Form.Row>
          </Form>
        </div >
      )
    } else {
      return (
        <ErrorComponent errorMessage={this.state.errorMessage} history={this.props.history} />
      )
    }
  }

}

export default CompanyAdminEdit;