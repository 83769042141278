import React from 'react';

import { Row } from 'react-bootstrap';

import ApiService from '../../../services/api.service';
import ArtworkEditComponent from '../../../components/shared/Artwork/ArtworkEditComponent';

import { translatedMessage } from '../../../services/language.service';
import { hasPermission } from '../../../services/api.service';

class ArtworkEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canEdit: false,
      isLoaded: false
    };
  }

  componentDidMount = () => {
    ApiService.initCurrentUser().then(response => {
      this.setState({
        canEdit: hasPermission("ARTWORK_EDIT_ALL"),
        isLoaded: true
      })
    });
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      if (this.state.canEdit) {
        return (
          <ArtworkEditComponent
            artworkId={this.props.match.params.id}
            canEdit={this.state.canEdit}
            isAuthor={false}
            backLink={`/artworkAdminList`}
            viewLink={`/artworkAdminView/${this.props.match.params.id}`}
            history={this.props.history}
          />
        )
      } else {
        return (
          <div className="ca-page-padding ca-main-container">
            <Row className="mb-3 pr-2">
              <span className="ca-page-subtitle font-bold">{translatedMessage("USER.SECURITY.MISSING_RIGHTS")}</span>
            </Row>
          </div>
        )
      }
    }
  }
}

export default ArtworkEditPage;