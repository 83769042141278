import React from 'react';

import virtualGallertIcon from '../../../components/shared/Resources/icons/virtualGallery.svg';
import back from '../../../components/shared/Resources/left-arrow.svg';
import calogo from '../../../components/shared/Resources/logo-dark-small-with-padding.png'

import CardComponent from "../../../components/shared/Gallery/GalleryViewArtworkCardComponent";

import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ApiService from '../../../services/api.service';
import { translatedMessage } from '../../../services/language.service';
import VirtualGalleryService from '../../../services/virtualGallery.service';
import CompanyService from '../../../services/company.service';

const ENTER_KEY = 13;

class VirtualGalleryListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.match.params.id ? this.props.match.params.id : null,
      artworks: [],
      typeList: [],
      selectedType: { label: "GENERAL_TYPE.ANY", name: "ANY" },
      filteredResults: [],
      searchInput: '',
      isLoaded: false,
      company: null
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";

    CompanyService.getCompanySpace(this.state.companyId)
      .then(response => {
        let company = response;

        this.setState({
          company: company,
        }, this.getArtworks)
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
          errorMessage = err.response.data.message;
        }

        this.setState({
          isError: true,
          errorMessage: errorMessage,
          isLoaded: true
        })
      });
  }

  getArtworks = () => {
    let errorMessage = "GENERAL.GET_DATA_ERROR";
    let promise = [];
    let artworks = [];

    promise.push(
      ApiService.getAuthenticatedInstance()
        .get(`company/${this.state.company.id}/portfolio/public?entityName=${ApiService.getEntityName().VIRTUAL_GALLERY}`)
        .then(response => {
          artworks = response.data;
          artworks.map((elem, i) => {
            elem.image = elem.thumbUuid ? ApiService.getBaseUrl() + '/storage/file/' + elem.thumbUuid : calogo;
            if (elem.virtualGalleryId) {
              elem.viewLink = `/my-company/${this.state.company.id}/virtual-gallery/${elem.virtualGalleryId}`;
              elem.editLink = `/my-company/${this.state.company.id}/virtual-gallery-edit/${elem.virtualGalleryId}`;
              elem.virtualGalleryArtLink = `/my-company/${this.state.company.id}/virtual-gallery/${elem.virtualGalleryId}/artworks`;
            }

            return elem;
          });
        })
        .catch(err => {
          if (err && err.response && (err.response.status === 404 || err.response.status === 403)) {
            errorMessage = err.response.data.message;
          }

          this.setState({
            isError: true,
            errorMessage: errorMessage,
            isLoaded: true
          })
        })
    )

    let typeList = [];
    promise.push(VirtualGalleryService.getVirtualGalleryTypeList()
      .then(response => {
        typeList = response;
        typeList.splice(0, 0, { label: "GENERAL_TYPE.ANY", name: "ANY" })
      })
    )

    Promise.all(promise).then(() => {
      this.setState({
        artworks: artworks,
        filteredResults: artworks,
        typeList: typeList,
        isLoaded: true
      })
    })
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    }, this.filter);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.setState({
        searchInput: e.target.value,
      }, this.filter);
    }
  }

  handleTypeSelect = (eventKey) => {
    const selectedItem = this.state.typeList.filter(item => item.name === eventKey);

    this.setState({
      selectedType: selectedItem[0]
    }, this.filter)
  }

  filter = () => {
    let result = this.state.artworks;
    let searchInput = this.state.searchInput;
    let typeItem = this.state.selectedType.label;

    if (0 !== searchInput.replace(/ /g, ";").length) {
      result = this.state.artworks.filter(elem => elem.name.toLowerCase().includes(searchInput.toLowerCase()));
    }

    if (typeItem !== 'GENERAL_TYPE.ANY') {
      result = result.filter(elem => elem.virtualGalleryType === typeItem);
    }

    this.setState({
      filteredResults: result
    })
  }

  render() {
    const artworks = this.state.filteredResults;
    if (!this.state.isLoaded) {
      return (
        <div className="fa-3x w-100 text-center" style={{}}>
          <i className="fa fa-spinner fa-spin"></i>
        </div>
      )
    } else {
      return (
        <div className="ca-page-padding ca-main-container">
          <Row className="ca-page-title-button-row mb-3 pr-2 align-items-center">
            <div className="d-flex align-items-center">
              <Image src={virtualGallertIcon} className="ca-page-title-icon" alt={translatedMessage("VIRTUAL_GALLERY.VIRTUAL_ROOMS")} />
              <div className="ca-page-title">{translatedMessage("VIRTUAL_GALLERY.VIRTUAL_ROOMS")}</div>
            </div>
            <div>
              <Link to={`/company/${this.state.company.id}/space`} className="ca-dark-link">
                <Image className="ca-page-title-icon ml-2 mr-0" src={back} alt={translatedMessage("BUTTON.BACK")}
                  title={translatedMessage("BUTTON.BACK")} />
              </Link>
            </div>
          </Row>

          <Row className="d-flex align-items-center">
            <Col className="col-12 col-md-6 mb-1 pl-0 pr-2">
              <Form.Control value={this.state.searchInput} className="ca-form-control-search" type="text" placeholder="&#xF002;"
                onChange={this.handleSearchInput}
                onKeyDown={this.handleKeyDown} />
            </Col>

            <Col className="col-12 col-md-6 mb-1 pl-0 pr-2">
              <Dropdown className="ca-dropdown" onSelect={this.handleTypeSelect}>
                <Dropdown.Toggle>
                  {translatedMessage(this.state.selectedType.label)}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.typeList.map((element, index) => {
                    return (
                      <Dropdown.Item key={index} eventKey={element.name}>
                        {translatedMessage(element.label)}
                      </Dropdown.Item>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <div className="card-columns">
            {artworks.map((element, index) => {
              return (
                <CardComponent
                  key={index}
                  entity={element}
                  image={element.image}
                  title={element.name}
                  author={element.author}
                  type={element.virtualGalleryType}
                  id={element.virtualGalleryId}
                  link={`/virtualGalleryDetails/${element.virtualGalleryId}`}
                  companyId={this.state.companyId}
                />
              )
            })}
          </div>

        </div >
      )
    }
  }

}

export default VirtualGalleryListPage;